import { Component, inject } from '@angular/core';
import { GraphService } from '../../shared/service/graph.service';
import { PlotlyModule } from 'angular-plotly.js';
import { CardGraphComponent } from '../card-graph/card-graph.component';
import { SidenavService } from '../../shared/service/sidenav.service';

@Component({
    selector: 'app-paiement',
    imports: [PlotlyModule, CardGraphComponent],
    templateUrl: './paiement.component.html',
    styleUrl: './paiement.component.scss'
})
export class PaiementComponent {
  private grahService = inject(GraphService);
  private sidenavService = inject(SidenavService);

  private plotlyGraphDimensions = {
    width: 700,
    height: 400
  };

  plotlyConfig = { 'displaylogo': false };

  private commonLayout = {
    ...this.plotlyGraphDimensions,
    font: {
      family: 'Roboto',
      size: 16
    }
  };

  // Outstanding Payments
  waterfallOutstandingPaymentsLayout: any = {
    ...this.commonLayout,
  };
  waterfallOutstandingPaymentsData = this.grahService.waterfallOutstandingPaymentsData;
  outstandingPaymentsError = this.grahService.outstandingPaymentsError;

  // Top 10 Tenants Outstanding Payments
  outstandingPaymentsTop10TenantsLayout: any = {
    ...this.commonLayout,
    barmode: 'stack',
    xaxis: {
      tickangle: -45,
    },
    yaxis: {
      automargin: true
    },
    hoverlabel: {
      namelength: -1
    }
  };
  outstandingPaymentsTop10TenantsData = this.grahService.outstandingPaymentsTop10TenantsData;
  outstandingPaymentsTop10TenantsError = this.grahService.outstandingPaymentsTop10TenantsError;
  
  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.sidenavService.showBuildingsList.set(true);
  }

}
