import { MatDateFormats } from '@angular/material/core';

export const DATE_FORMATS_FR: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY', // Custom parsing format
    timeInput: 'HH:mm',      // Time parsing format
  },
  display: {
    dateInput: 'DD/MM/YYYY', // Input field display
    timeInput: 'HH:mm',      // Time display format
    monthYearLabel: 'MMMM YYYY', // Month-Year picker
    dateA11yLabel: 'LL',        // Accessible date format
    monthYearA11yLabel: 'MMMM YYYY', // Accessible month-year
    timeOptionLabel: 'HH:mm',   // Accessible time format
  },
};

export const DATE_FORMATS_EN: MatDateFormats = {
  parse: {
    dateInput: 'DD-MM-YYYY', // Custom parsing format
    timeInput: 'HH:mm',      // Time parsing format
  },
  display: {
    dateInput: 'DD-MM-YYYY', // Input field display
    timeInput: 'HH:mm',      // Time display format
    monthYearLabel: 'MMM YYYY', // Month-Year picker
    dateA11yLabel: 'LL',        // Accessible date format
    monthYearA11yLabel: 'MMMM YYYY', // Accessible month-year
    timeOptionLabel: 'HH:mm',   // Accessible time format
  },
};

export const DATE_FORMATS_DE: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY', // Custom parsing format
    timeInput: 'HH:mm',      // Time parsing format
  },
  display: {
    dateInput: 'DD.MM.YYYY', // Input field display
    timeInput: 'HH:mm',      // Time display format
    monthYearLabel: 'MMM YYYY', // Month-Year picker
    dateA11yLabel: 'LL',        // Accessible date format
    monthYearA11yLabel: 'MMMM YYYY', // Accessible month-year
    timeOptionLabel: 'HH:mm',   // Accessible time format
  },
};
