import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { AuthService } from './shared/service/auth.service';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { PanelLeftComponent } from './panel-left/panel-left.component';
import { SidenavService } from './shared/service/sidenav.service';
import { AppService } from './shared/service/app.service';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, NavbarComponent, CommonModule, MatSidenavModule, MatFormFieldModule, MatSelectModule, TranslateModule, PanelLeftComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  //private tokenSvc = inject(TokenService);
  private authSvc = inject(AuthService);
  private destroy$: Subject<void> = new Subject();
  private translateService = inject(TranslateService);
  private sidenavService = inject(SidenavService);
  private appService = inject(AppService);

  constructor(){
    this.initTranslation();
    this.appService.retrieveTranslations();
  }

  ngOnInit(): void {
    /* this.tokenSvc.generateToken("alhambra", "alhambra-1", "password").pipe(takeUntil(this.destroy$)).subscribe({
      next: (tokenResponse) => {
        localStorage.setItem('token', JSON.stringify(tokenResponse));
      },
      error: (error) => {
        console.error('Generate token  Error:', error);
      }}
    );
    console.log("showNav : " + this.showNavigations); */

    // At startup, clear any previous session
    this.authSvc.logout();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get showNavigations(): boolean | undefined{
    return this.authSvc.isLoggedIn();
  }

  get showSidenav(): boolean {
    return this.sidenavService.showSidenav;
  }

  private initTranslation(): void {
    const defaultLocale = localStorage.getItem('userLocale') || this.appService.getFormattedLocale(navigator.language);
    this.translateService.setDefaultLang(defaultLocale);
    this.translateService.use(defaultLocale);
  }

}
