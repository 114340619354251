import { Component, Input, Signal, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';
import { FullscreenPlotDialogComponent } from '../../dialog/fullscreen-plot-dialog/fullscreen-plot-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DynamicData } from '../../shared/model/types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { AppService } from '../../shared/service/app.service';
PlotlyModule.plotlyjs = PlotlyJS;

@Component({
    selector: 'app-card-graph',
    imports: [CommonModule, MatButtonModule, MatCardModule, MatIconModule, MatFormFieldModule, PlotlyModule, TranslateModule],
    templateUrl: './card-graph.component.html',
    styleUrl: './card-graph.component.scss'
})
export class CardGraphComponent {

  private dialog = inject(MatDialog);
  private appService = inject(AppService);

  plotlyConfig = {'displaylogo': false};

  @Input() graphTitleCode: string = '';
  @Input() graphLayout: any;
  @Input() graphData!: Signal<DynamicData[] | undefined>;
  @Input() graphError: any;

  openFullscreenPlot(plotData: any, layout: any, title: string) {
    const plotTitle = this.appService.getTranslation(title);
    this.dialog.open(FullscreenPlotDialogComponent, {
      width: '70%',
      height: '70%',
      maxWidth: '100%',
      data: { plotData, layout: { ...layout, width: '100%', height: '100%', title: `<b>${plotTitle}</b>`, titlefont: {
        size: 18,
      } }}
    });
  }

}
