import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { SidenavService } from '../../shared/service/sidenav.service';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordStrengthValidator } from '../../shared/validator/passwordStrengthValidator';
import { PasswordMatchValidator } from '../../shared/validator/passwordMatchValidator';
import { AppService } from '../../shared/service/app.service';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { DynamicData, Result, User } from '../../shared/model/types';
import { AuthService } from '../../shared/service/auth.service';
import { SuccessDialogComponent } from '../../dialog/success-dialog/success-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';


@Component({
    selector: 'app-account',
    imports: [MatCardModule, MatButtonModule, MatInputModule, ReactiveFormsModule, MatSelectModule, MatGridListModule, CommonModule, TranslateModule, MatIconModule],
    templateUrl: './account.component.html',
    styleUrl: './account.component.scss'
})
export class AccountComponent {
  private fb = inject(FormBuilder);
  private sidenavService = inject(SidenavService);
  private appService = inject(AppService);
  private authSvc = inject(AuthService);
  private dialog = inject(MatDialog);

  user?: User;

  hideCurrentPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;

  personalDetailsForm!: FormGroup;
  changePasswordForm!: FormGroup;

  surfaceUnits: DynamicData[] = [];
  currencies: DynamicData[] = [];

  constructor() { }

  ngOnInit(): void {
    // Initialisation des formulaires
    this.initializeForms();

    //Hide the sidenav for this page
    this.sidenavService.showSidenav = false;

    // Get the current user logged in
    const loggedUser = this.authSvc.loggedUser();

    if (loggedUser) {
      this.authSvc.getUserByUserName(loggedUser.UserName).subscribe({
        next: userData => {
          this.user = userData;

          // Load user preferences after checking if the user is logged in
          if (this.user) {
            this.loadUserPreferences(this.user.UserName);
          }
        },
        error: err => {
          this.appService.error('AccountComponent - Error fetching user data :', err);
        }
      });
    } else {
      this.appService.error('AccountComponent - Error :', 'No logged in user found');
    }
  }

  private initializeForms(): void {
    this.personalDetailsForm = this.fb.group({
      name: ['', Validators.required],
      firstName: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      csvSeparator: ['', Validators.required],
      surfaceUnit: ['', Validators.required],
      currency: ['', Validators.required]
    });

    this.changePasswordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, PasswordStrengthValidator()]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: PasswordMatchValidator('newPassword', 'confirmPassword')
    });
  }

  private loadUserPreferences(userName: string): void {
    forkJoin({
      lastName: this.appService.getUserPreference("LASTNAME", userName),
      firstName: this.appService.getUserPreference("FIRSTNAME", userName),
      company: this.appService.getUserPreference("COMPANY", userName),
      csvSeparator: this.appService.getUserPreference("CSV_SEPARATOR", userName),
      currency: this.appService.getUserPreference("CURRENCY_PREF", userName),
      surfaceUnit: this.appService.getUserPreference("AREA_UNIT_PREF", userName),
      listAreaUnits: this.appService.getAreaUnits(),
      listCurrency: this.appService.getCurrency(),
    }).subscribe(results => {
      this.surfaceUnits = results.listAreaUnits?.data || [];
      this.currencies = results.listCurrency?.data || [];

      this.personalDetailsForm.patchValue({
        name: this.getPreferenceValue(results.lastName),
        firstName: this.getPreferenceValue(results.firstName),
        company: this.getPreferenceValue(results.company),
        csvSeparator: this.getPreferenceValue(results.csvSeparator),
        email: this.user ? this.user.Email : '',
        surfaceUnit: this.getPreferenceValue(results.surfaceUnit),
        currency: this.getPreferenceValue(results.currency),
      });
    });
  }

  private getPreferenceValue(preference: Result<DynamicData[]>): string {
    return preference?.data && preference.data.length > 0
      ? preference.data[0]['preference_value']
      : '';
  }

  onSavePersonalDetails(): void {
    if (this.personalDetailsForm.valid) {
      const preferences = [
        { code: "LASTNAME", controlName: "name" },
        { code: "FIRSTNAME", controlName: "firstName" },
        { code: "COMPANY", controlName: "company" },
        { code: "CSV_SEPARATOR", controlName: "csvSeparator" },
        { code: "AREA_UNIT_PREF", controlName: "surfaceUnit" },
        { code: "CURRENCY_PREF", controlName: "currency" }
      ];

      // Count the number of changes to user preferences
      let updateObservables: Observable<number>[] = preferences.map(pref => {
        const value = this.personalDetailsForm.get(pref.controlName)?.value;

        if (value !== undefined && value !== null) {
          return this.appService.getUserPreference(pref.code, this.user!.UserName).pipe(
            switchMap(result => {
              if (result.data && result.data.length > 0) {
                // Preference exists, update it
                return this.appService.updateUserPreference(pref.code, value, this.user!.UserName).pipe(
                  catchError(err => {
                    return of(0); // Handle error and return 0 indicating no update
                  })
                );
              } else {
                // Preference does not exist, insert it
                return this.appService.insertUserPreference(pref.code, value, this.user!.UserName).pipe(
                  map(() => 1), // Map the result to 1 to indicate a successful insert
                  catchError(err => {
                    return of(0); // Return 0 to indicate no change due to error
                  })
                );
              }
            }),
            catchError(err => {
              return of(0); // Return 0 to indicate no change due to error in getUserPreference
            })
          );
        }

        return of(0); // Return an observable that emits 0 when there's no value to update
      });

      // Other logic for the email
      this.authSvc.getUserByUserName(this.authSvc.loggedUser()!.UserName).subscribe(user => {
        const currentEmail: string = user.Email || '';
        const newEmail = this.personalDetailsForm.get("email")?.value;
        if (currentEmail !== newEmail) {
          const emailUpdate$ = this.authSvc.updateUser(user.Id, user.UserName, null, null, newEmail, null)
            .pipe(map(result => {
              return 1; // Assuming success means one preference was updated
            }));
          updateObservables.push(emailUpdate$);
        }

        if (updateObservables.length > 0) {
          forkJoin(updateObservables).subscribe({
            next: (results: number[]) => {
              const totalUpdated = results.reduce((sum, count) => sum + count, 0);
              let dialogRef;
              if (totalUpdated > 0) {
                dialogRef = this.dialog.open(SuccessDialogComponent, {
                  data: {
                    totalUpdated: totalUpdated,
                    message:
                      'lblPreferenceUpdated',
                  },
                });
              } else {
                dialogRef = this.dialog.open(SuccessDialogComponent, {
                  data: {
                    message:
                      'lblNoPreferenceUpdated',
                  },
                });
              }
            },
            error: (err) => {
              this.appService.error('AccountComponent - Error updating preferences :', err);
            }
          });
        } else {
          this.appService.log('AccountComponent - User preferences :', 'No preferences needed updating.');
        }
      });
    }
  }


  onSavePassword(): void {
    if (this.changePasswordForm.valid) {
      let oldPasswordCheck: boolean;
      let dialogRef;
      this.authSvc.checkPassword(this.user!.UserName, this.changePasswordForm.get("currentPassword")?.value).subscribe(result => {
        oldPasswordCheck = result;
        if(oldPasswordCheck){
          let delayBeforeAllowPwdChange = environment.nbDaysBeforeAllowPwdChange;
          const passwordCreationDate = new Date(this.user!.PasswordCreationDate);
          const now = new Date();
          const timeSinceCreation = now.getTime() - passwordCreationDate.getTime();
          const daysSinceCreation = timeSinceCreation / (1000 * 60 * 60 * 24);
          if (daysSinceCreation < delayBeforeAllowPwdChange) {
            dialogRef = this.dialog.open(ErrorDialogComponent, {
              data: {
                days: delayBeforeAllowPwdChange,
                message:
                  'lblDatePasswordLeastDays',
              },
            });
          }
          else{
            const newPassword = this.changePasswordForm.get("newPassword")?.value;
            const confirmPassword = this.changePasswordForm.get("confirmPassword")?.value;
            this.authSvc.updatePassword(this.user!.Id, newPassword, confirmPassword).subscribe(resultMsg => {
              if(resultMsg === "PASSWORD ALREADY EXISTS"){
                dialogRef = this.dialog.open(ErrorDialogComponent, {
                  data: {
                    message:
                      'lblPwdExists',
                  },
                });
              }
              else if(resultMsg === "OK"){
                dialogRef = this.dialog.open(SuccessDialogComponent, {
                  data: {
                    message:
                      'lblPasswordChanged',
                  },
                });
              }
              else{
                dialogRef = this.dialog.open(ErrorDialogComponent, {
                  data: {
                    message:
                      'lblInvalidPassword',
                  },
                });
              }
            });
          }
        }
        else{
          dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
              message:
                'lblCurrentPasswordError',
            },
          });
        }
      });
    }
  }

  onCancelPersonalDetails(): void {
    this.personalDetailsForm.reset();
  }

  onCancelPassword(): void {
    this.changePasswordForm.reset();
  }
}
