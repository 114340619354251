<div class="container">
    <app-card-graph
      [graphTitleCode]="'lblEvolBalanceOutstandPayment'"
      [graphLayout]="waterfallOutstandingPaymentsLayout"
      [graphData]="waterfallOutstandingPaymentsData"
      [graphError]="outstandingPaymentsError"
      >
    </app-card-graph>
    <app-card-graph
      [graphTitleCode]="'OUTSTANDING_PAYMENTS_DETAILLED_BY_TENANT'"
      [graphLayout]="outstandingPaymentsTop10TenantsLayout"
      [graphData]="outstandingPaymentsTop10TenantsData"
      [graphError]="outstandingPaymentsTop10TenantsError"
      >
    </app-card-graph>
  </div>

