import { inject, Injectable } from '@angular/core';
import { PasswordRecoveryOption } from '../model/enums';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private appService = inject(AppService);

  constructor() { }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  getEmailBody(usernameToken: string, option: PasswordRecoveryOption, optionalProperty: string | null = null): string {
    const currentLang = localStorage.getItem('userLocale')!;

    const applicationPath = window.location.pathname === '/' ? '' : window.location.pathname.replace('/passwordRecovery', '');
    const linkToNewPasswordPage = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${applicationPath}/NewPassword?token=${encodeURIComponent(usernameToken)}&lang=${currentLang}`;
    this.appService.log("MailService - Link to the new password page :", linkToNewPasswordPage);

    const emailGreetings = this.appService.getTranslation("emailGreetingsToUser");
    const emailIntro = this.appService.getTranslation("emailPwdRecoveryIntro");
    const emailLinkToClick = this.appService.getTranslation("emailPwdRecoveryLinkClick");
    const emailTempPwd = this.appService.getTranslation("emailPwdRecoveryProvideTempPwd");
    const emailSMSCode = this.appService.getTranslation("emailPwdRecoveryProvideSMSCode");
    const emailLinkCodeExpiration = this.appService.getTranslation("emailLinkCodeExpiration");
    const emailLinkExpiration =this.appService.getTranslation("emailLinkExpiration");
    const emailRegards = this.appService.getTranslation("emailRegards");
    const emailTeamSignature = this.appService.getTranslation("emailWebAlhambraTeamSignature");


    let builder: string[] = [];

    builder.push(emailGreetings);
    builder.push('');
    builder.push(emailIntro);
    builder.push('');
    builder.push(emailLinkToClick);
    builder.push(linkToNewPasswordPage);

    // Taking recovery option into consideration
    if (option === PasswordRecoveryOption.TemporaryPassword || option === PasswordRecoveryOption.SMSDoubleAuth) {
      if (option === PasswordRecoveryOption.TemporaryPassword) {
        builder.push('');
        builder.push(`${emailTempPwd}: ${optionalProperty}`);
      } else if (option === PasswordRecoveryOption.SMSDoubleAuth) {
        builder.push('');
        builder.push(emailSMSCode);
      }
      builder.push('');
      builder.push(emailLinkCodeExpiration);
    } else {
      builder.push('');
      builder.push(emailLinkExpiration);
    }

    builder.push('');
    builder.push(emailRegards);
    builder.push(emailTeamSignature);

    const result = builder.join('\n');

    return result
  }
}
