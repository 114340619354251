import { Injectable } from '@angular/core';
import { DateAdapter, MatDateFormats } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DATE_FORMATS_DE, DATE_FORMATS_EN, DATE_FORMATS_FR } from '../model/date-formats';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  currentFormats = new BehaviorSubject<MatDateFormats>(DATE_FORMATS_EN);

  constructor(private dateAdapter: DateAdapter<any>, private translate: TranslateService) {
    // Listen for language changes
    this.translate.onLangChange.subscribe((event) => {
      this.dateAdapter.setLocale(event.lang);
      this.updateFormats(event.lang);
    });

    // Set initial locale and formats
    const lang = this.translate.currentLang || 'fr-FR';
    this.dateAdapter.setLocale(lang);
    this.updateFormats(lang);
  }

  private updateFormats(lang: string): void {
    let formats: MatDateFormats;
    switch (lang) {
      case 'en-GB':
        formats = DATE_FORMATS_EN;
        break;
      case 'de-DE':
        formats = DATE_FORMATS_DE;
        break;
      default:
        formats = DATE_FORMATS_FR;
    }

    // Update the current formats
    this.currentFormats.next(formats);
  }

  getFormats(): MatDateFormats {
    return this.currentFormats.value;
  }
}
