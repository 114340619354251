import { ChangeDetectorRef, Component, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '../shared/model/types';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '../shared/service/auth.service';
import { Subject } from 'rxjs';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppService } from '../shared/service/app.service';
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'app-login',
    imports: [
        MatInputModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        CommonModule,
        ReactiveFormsModule,
        MatSelectModule,
        TranslateModule,
        FormsModule
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
  @ViewChild('select') select!: MatSelect;
  private authSvc = inject(AuthService);
  private appService = inject(AppService);
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private translateService = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);

  loginForm: FormGroup;
  hide = true;
  errorMessage: string = '';
  backgroundLogin = "../assets/backgroungLogin.png";

  private destroy$: Subject<void> = new Subject();

  languages: string[] = [
    'fr-FR',
    'en-GB',
    'de-DE'
  ];
  selectedLocale: string = "";

  constructor() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      language: [null, Validators.required]
    });
  }

  setselectedLocale(): void {
    const navigatorLang = navigator.language.replace('-', '_');
    const isSupported = this.languages.some(lang => lang.replace('-', '_') === navigatorLang);

    if (isSupported) {
      // If navigator.language is directly supported, use it
      this.selectedLocale = navigatorLang.replace('_', '-');  // Normalize back to hyphen if needed
    } else {
      // Find a partially matching locale or default to the first in the list
      const primaryLangCode = navigatorLang.split('_')[0];
      const partialMatch = this.languages.find(lang => lang.startsWith(primaryLangCode));
      this.selectedLocale = partialMatch || this.languages[0];
    }
  }

  ngOnInit(): void {
    this.setselectedLocale();
    this.appService.log("LoginComponent - Selected locale :", this.selectedLocale)
    localStorage.setItem("userLocale", this.selectedLocale);
    this.setDateFormat(this.selectedLocale);
    this.translateService.use(this.selectedLocale);
  }

  ngAfterViewInit(): void {
    this.loginForm.get("language")?.setValue(this.selectedLocale);
    this.cdr.detectChanges()
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  login() {
    if(this.loginForm.valid){
      const username = this.loginForm.get('username')?.value;
      const password = this.loginForm.get('password')?.value;
      const language = this.loginForm.get('language')?.value
      this.authSvc.login(username, password).subscribe({
        next: (user: User | null) => {
          if (user) {
            this.errorMessage = '';
            localStorage.setItem("userLocale", language);
            this.setDateFormat(language);
            this.router.navigate(['/home']);
          } else {
            this.errorMessage = this.appService.getTranslation('CONNECTION_FAILED');
          }
        },
        error: (error) => {
          this.errorMessage = this.appService.getTranslation('CONNECTION_ERROR') + ' : ' + (error.message || this.appService.getTranslation('UNKNOWN_ERROR'));
        }
      });
    }
  }

  togglePasswordVisibility() {
    this.hide = !this.hide;
  }

  onLocalChanged(locale: string) {
    this.appService.log("LoginComponent - Language changed to :", locale);
    localStorage.setItem("userLocale", locale);
    this.setDateFormat(locale);
    this.translateService.use(locale);
  }

  setDateFormat(locale: string) {
    switch(locale) {
      case 'en-GB':
        localStorage.setItem("dateFormat", 'dd-MM-yyyy');
        break;
      case 'fr-FR':
        localStorage.setItem("dateFormat", 'dd/MM/yyyy');
        break;
      case 'de-DE':
        localStorage.setItem("dateFormat", 'dd.MM.yyyy');
        break;
      default:
        localStorage.setItem("dateFormat", 'dd/MM/yyyy');
        break;
      }
  }

  getTranslation(translation: string) : string{
    return this.appService.getTranslation(translation);
  }

  navigateToForgotPassword() {
    this.router.navigate(['/settings/account/passwordRecovery']);
  }
}
