import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { DynamicData, Result, ServerSideResponse } from '../model/types';
import { environment } from '../../../environments/environment';
import { HttpErrorService } from './http-error.service';
import { AuthService } from './auth.service';
import { ReportDatesService } from './report-dates.service';
import { AppService } from './app.service';
import { TokenService } from './token.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private http = inject(HttpClient);
  private errorService = inject(HttpErrorService);
  private authSvc = inject(AuthService);
  private reportDatesService = inject(ReportDatesService);
  private appService = inject(AppService);
  private tokenService = inject(TokenService);

  private loggedUser = this.authSvc.loggedUser;

  showSidenav: boolean = false;

  // Use of BehaviorSubject for selectedImportDate
  selectedImportDateSubject = new BehaviorSubject<Date | null>(null);
  selectedImportDate$ = this.selectedImportDateSubject.asObservable();

  selectedPortfolio = signal<string>("00000000000000000000");
  selectedPortfolioLabel = signal<string>(this.appService.getTranslation("ALL_PORTFOLIOS"));
  selectedOwners = signal<number[]>([]);
  selectedGeographicalZones = signal<string[]>([]);
  selectedBuildingNatures = signal<string[]>([]);
  showBuildingsList = signal<boolean>(false);
  selectedIdBuildingList = signal<number[]>([]);
  selectedBuildingNames = signal<string[]>([]);
  selectedBuildingsSelectType = signal<string>("'ACTIVE'");

  private waitForToken$ = this.tokenService.waitForToken().pipe(
    filter(isTokenAvailable => isTokenAvailable)
  );

  // Get Import Dates
  private importDates$ = this.waitForToken$.pipe(
    switchMap(() =>
      this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/ImportDateList/Get`, {}).pipe(
        map(response => ({ data: response.data } as Result<DynamicData[]>)),
        catchError(err => of({
          data: [],
          error: this.errorService.formatError(err)
        } as Result<DynamicData[]>))
      )
    )
  );

  private importDatesData = toSignal(this.importDates$, {
    initialValue: ({ data: [] } as Result<DynamicData[]>)
  });

  importDates = computed(() => this.importDatesData().data);
  importDatesError = computed(() => this.importDatesData().error);

  // Function to set the selected import date
  importDateSelected(selectedImportDate: Date): void {
    this.selectedImportDateSubject.next(selectedImportDate);
    this.portfolioSelected("00000000000000000000", this.appService.getTranslation("ALL_PORTFOLIOS"));
    this.reportDatesService.analysisDate.set(selectedImportDate);
    this.reportDatesService.startDate.set(this.appService.dateToUTCDate(this.selectedImportDateSubject.value!.getFullYear(), 1, 1));
    this.reportDatesService.endDate.set(selectedImportDate);
  }

  // Observables combinaison to launch API calls when the filters change
  private filters$ = combineLatest([
    this.selectedImportDate$, // Observable<Date | null>
    toObservable(this.selectedPortfolio), // Convert signal to observable
    toObservable(this.selectedOwners),
    toObservable(this.selectedGeographicalZones),
    toObservable(this.selectedBuildingNatures),
    toObservable(this.selectedBuildingsSelectType)
  ]);

  // Portfolios list
  private portfoliosList$ = this.filters$.pipe(
    filter(([importDate]) => importDate !== null),
    switchMap(([importDate]) => {
      const params = {
        Filter: {
          ImportDate: importDate,
          LanguageCode: localStorage.getItem('userLocale') || navigator.language,
          User: this.loggedUser()?.UserName,
          allPortfoliosLine: "TRUE"
        },
      };

      return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/PortfoliosList/Get`, params).pipe(
        map(response => ({ data: response.data } as Result<DynamicData[]>)),
        catchError(err => of({
          data: [],
          error: this.errorService.formatError(err)
        } as Result<DynamicData[]>))
      );
    })
  );

  private portfoliosListData = toSignal(this.portfoliosList$, {
    initialValue: ({ data: [] } as Result<DynamicData[]>)
  });

  portfoliosList = computed(() => this.portfoliosListData().data);
  portfoliosListError = computed(() => this.portfoliosListData().error);

  // Owners list
  private ownersList$ = this.filters$.pipe(
    filter(([importDate]) => importDate !== null),
    switchMap(([importDate]) => {
      const params = {
        Filter: {
          ImportDate: importDate
        }
      };

      return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/OwnersList/Get`, params).pipe(
        map(response => ({ data: response.data } as Result<DynamicData[]>)),
        catchError(err => of({
          data: [],
          error: this.errorService.formatError(err)
        } as Result<DynamicData[]>))
      );
    })
  );

  private ownersListData = toSignal(this.ownersList$, {
    initialValue: ({ data: [] } as Result<DynamicData[]>)
  });

  ownersList = computed(() => this.ownersListData().data);
  ownersListError = computed(() => this.ownersListData().error);

  // Geographical zones list
  private geographicalZonesList$ = this.filters$.pipe(
    filter(([importDate]) => importDate !== null),
    switchMap(([importDate]) => {
      const params = {
        Filter: {
          SourceTable: "D_BUILDING",
          ImportDate: importDate,
          IdSaleProgresStateList: "-1"
        }
      };

      return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/GeographicalZonesList/Get`, params).pipe(
        map(response => ({ data: response.data } as Result<DynamicData[]>)),
        catchError(err => of({
          data: [],
          error: this.errorService.formatError(err)
        } as Result<DynamicData[]>))
      );
    })
  );

  private geographicalZonesListData = toSignal(this.geographicalZonesList$, {
    initialValue: ({ data: [] } as Result<DynamicData[]>)
  });

  geographicalZonesList = computed(() => this.geographicalZonesListData().data);
  geographicalZonesListError = computed(() => this.geographicalZonesListData().error);

  // Property natures list
  private buildingNaturesList$ = this.filters$.pipe(
    filter(([importDate]) => importDate !== null),
    switchMap(([importDate]) => {
      const params = {
        Filter: {
          SourceTable: "D_BUILDING",
          ImportDate: importDate,
          LanguageCode: localStorage.getItem('userLocale') || navigator.language,
          BuildingTypeCode: "00000"
        }
      };

      return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/BuildingNaturesList/Get`, params).pipe(
        map(response => ({ data: response.data } as Result<DynamicData[]>)),
        catchError(err => of({
          data: [],
          error: this.errorService.formatError(err)
        } as Result<DynamicData[]>))
      );
    })
  );

  private buildingNaturesListData = toSignal(this.buildingNaturesList$, {
    initialValue: ({ data: [] } as Result<DynamicData[]>)
  });

  buildingNaturesList = computed(() => this.buildingNaturesListData().data);
  buildingNaturesListError = computed(() => this.buildingNaturesListData().error);

  // Properties list
  private buildingsList$ = this.filters$.pipe(
    filter(([importDate]) => importDate !== null),
    switchMap(([importDate, portfolio, owners, geographicalZones, buildingNatures, buildingsSelectType]) => {
      const params = {
        Filter: {
          ImportDate: importDate,
          StartDate: importDate,
          EndDate: importDate,
          PortfolioCode: portfolio,
          IdRepositoryClient: -1,
          IdOwnerList: owners.length === 0 ? [-1] : owners,
          BuildingNatureCodeList: buildingNatures.length === 0 ? "'00000000000000000000'" : this.appService.arrayToString(buildingNatures),
          GeographicalZonesList: geographicalZones.length === 0 ? "'00000000000000000000'" : this.appService.arrayToString(geographicalZones),
          BuildingTypeCode: '00000',
          NatureOccupancyCodeList: "'00000000000000000000'",
          IdAssetManager: 0,
          BusinessUnitCode: '00000000000000000000',
          FeeCenterCode: '00000000000000000000',
          AgencyCode: '00000000000000000000',
          IdPropManager: 0,
          ExternalFundCode: '00000000000000000000',
          SelectType: buildingsSelectType,
          NeutralizationType: 'ALL_BUILDINGS',
          User: this.loggedUser()?.UserName,
          DecryptKey: null,
        }
      };

      return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/BuildingsList/Get`, params).pipe(
        map(response => ({ data: response.data } as Result<DynamicData[]>)),
        catchError(err => of({
          data: [],
          error: this.errorService.formatError(err)
        } as Result<DynamicData[]>))
      );
    })
  );

  private buildingsListData = toSignal(this.buildingsList$, {
    initialValue: ({ data: [] } as Result<DynamicData[]>)
  });

  buildingsList = computed(() => this.buildingsListData().data);
  buildingsListError = computed(() => this.buildingsListData().error);

  // Functions to update the filters
  portfolioSelected(selectedPortfolioCode: string, selectedPortfolioLabel: string): void {
    this.selectedPortfolio.set(selectedPortfolioCode);
    this.selectedPortfolioLabel.set(selectedPortfolioLabel);
  }

  ownersSelected(selectedOwners: number[]): void {
    this.selectedOwners.set(selectedOwners);
  }

  geographicalZonesSelected(selectedGeographicalZones: string[]): void {
    this.selectedGeographicalZones.set(selectedGeographicalZones);
  }

  buildingNaturesSelected(selectedBuildingNatures: string[]): void {
    this.selectedBuildingNatures.set(selectedBuildingNatures);
  }

  buildingsSelectTypeSelected(selectType: string) {
    if (this.selectedBuildingsSelectType() != selectType) {
      this.selectedBuildingsSelectType.set(selectType);
    }
  }
}
