import { Component, Inject, OnInit, computed, inject } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportDatesService } from '../../shared/service/report-dates.service';
import { CommonModule } from '@angular/common';
import { AppService } from '../../shared/service/app.service';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { DateFormatService } from '../../shared/service/date-format-service.service';


@Component({
    selector: 'app-report-dates',
    providers: [],
    imports: [CommonModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, TranslateModule, FormsModule, ReactiveFormsModule],
    templateUrl: './report-dates.component.html',
    styleUrl: './report-dates.component.scss'
})
export class ReportDatesComponent implements OnInit {
  private reportDatesService = inject(ReportDatesService);
  private appService = inject(AppService);
  private dateFormatSvc = inject(DateFormatService)

  private analysisDate = this.reportDatesService.analysisDate;
  private startDate = this.reportDatesService.startDate;
  private endDate = this.reportDatesService.endDate;

  constructor(@Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats, private dateAdapter: DateAdapter<any>) {
  }

  analysisDateControl = computed(() => new FormControl(this.analysisDate()));

  ngOnInit(): void {
    // Force reloading formats (Angular will inject new formats dynamically)
    // MAT_DATE_FORMATS is a static injection in Angular and does not change automatically after a language change.
    this.dateFormatSvc.currentFormats.subscribe((format) => {
      Object.assign(this.dateFormats, format);
    })
  }

  analysisDateChange(event: any) {
    console.log('Valeur brute saisie analysis date:', event); // Date interprétée
    const analysisDate = this.appService.convertToUTCDAte(event.value.toDate());
    this.reportDatesService.analysisDate.set(analysisDate);
  }

  get showDateRange(): boolean {
    return this.reportDatesService.showDateRange;
  }

  startDateControl = computed(() => new FormControl(this.startDate()));

  startDateChange(event: any) {
    console.log('Valeur brute saisie start date:', event.value.toDate()); // Date interprétée
    const startDate = this.appService.convertToUTCDAte(event.value.toDate());
    this.reportDatesService.startDate.set(startDate);
  }

  endDateControl = computed(() => new FormControl(this.endDate()));

  endDateChange(event: any) {
    console.log('Valeur brute saisie end date:', event.value.toDate()); // Date interprétée
    const endDate = this.appService.convertToUTCDAte(event.value.toDate());
    this.reportDatesService.endDate.set(endDate);
  }
}
