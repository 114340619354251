import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-success-dialog',
    imports: [TranslateModule, MatButton],
    templateUrl: './success-dialog.component.html',
    styleUrl: './success-dialog.component.scss'
})
export class SuccessDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SuccessDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
