import { Component, OnInit, inject } from '@angular/core';
import { GraphService } from '../../shared/service/graph.service';
import { PlotlyModule } from 'angular-plotly.js';
import { SidenavService } from '../../shared/service/sidenav.service';
import { CardGraphComponent } from '../card-graph/card-graph.component';

@Component({
    selector: 'app-patrimony',
    imports: [PlotlyModule, CardGraphComponent],
    templateUrl: './patrimony.component.html',
    styleUrl: './patrimony.component.scss'
})
export class PatrimonyComponent implements OnInit {
  private grahService = inject(GraphService);
  private sidenavService = inject(SidenavService);

  private plotlyGraphDimensions = {
    width: 700,
    height: 400
  };

  plotlyConfig = { displaylogo: false };

  private commonLayout = {
    ...this.plotlyGraphDimensions,
    font: {
      family: 'Roboto',
      size: 16
    }
  };

  // Patrimony
  assetsAreaLayout = { ...this.commonLayout };
  assetsAreaData = this.grahService.assetsAreaData;

  buildingsCountLayout = { ...this.commonLayout };
  buildingsCountData = this.grahService.buildingsCountData;

  leasesCountLayout = { ...this.commonLayout };
  leasesCountData = this.grahService.leasesCountData;

  unitsCountLayout = { ...this.commonLayout };
  unitsCountData = this.grahService.unitsCountData;

  grossHeadlineRentLayout = { ...this.commonLayout };
  grossHeadlineRentData = this.grahService.grossHeadlineRentData;

  chartPortfolioByNatureError = this.grahService.chartPortfolioByNatureError;

  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.sidenavService.showBuildingsList.set(false);
  }

}
