import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { AuthService } from '../shared/service/auth.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-navbar',
    imports: [MatCardModule, MatIconModule, MatToolbar, CommonModule, MatButtonModule, MatMenuModule, TranslateModule, RouterModule],
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  private authSvc = inject(AuthService);

  isLoggedIn = this.authSvc.isLoggedIn;
  loggedUser = this.authSvc.loggedUser;

  onLogout(): void {
    this.authSvc.logout();
  }

}
