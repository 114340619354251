import { Component, inject, OnInit, signal } from '@angular/core';
import { ModelReportService } from '../../shared/service/model-report.service';
import { AuthService } from '../../shared/service/auth.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { DynamicData, ServerSideResponse } from '../../shared/model/types';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { FormatDynamicPipe } from '../../shared/pipe/format-dynamic.pipe';
import { DynamicTableComponent } from '../../dynamic-table/dynamic-table.component';
import { filter, map, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SidenavService } from '../../shared/service/sidenav.service';
import { ReportService } from '../../shared/service/report.service';

@Component({
  selector: 'app-manage-custom-report',
  standalone: true,
  imports: [DynamicTableComponent, MatCardModule, MatProgressSpinnerModule, MatInputModule, MatTableModule, MatIconModule, TranslateModule, MatPaginatorModule, CommonModule, FormatDynamicPipe],
  templateUrl: './manage-custom-report.component.html',
  styleUrl: './manage-custom-report.component.scss'
})
export class ManageCustomReportComponent implements OnInit {
  private modelReportService = inject(ModelReportService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private sidenavService = inject(SidenavService);
  private reportService = inject(ReportService);

  columnsToDisplay = signal<string[]>(["template_name", "report_name", "share_with_other_users", "updated_at", "nb_interfaces"]);

  ngOnInit(): void {
    this.sidenavService.showSidenav = false;
  }

  getReportTemplates = (): Observable<ServerSideResponse> => {
    return this.modelReportService.getReportTemplates(null, this.authService.getLoggedUser()?.UserName!)
      .pipe(
        // Show only reports available in Properlake
        map(response => {
          const filteredData = response.data.filter(data =>
            (String(data['report_code']).substring(0, 3) === 'TS_' ||
              String(data['report_code']).substring(0, 3) === 'OUT' ||
              String(data['report_code']).substring(0, 3) === 'BUM' ||
              String(data['report_code']).substring(0, 3) === 'GEC' ||
              String(data['report_code']).substring(0, 3) === 'GEP' ||
              String(data['report_code']).substring(0, 3) === 'SDG' ||
              String(data['report_code']).substring(0, 3) === 'OPM' ||
              String(data['report_code']).substring(0, 3) === 'VAC' ||
              String(data['report_code']).substring(0, 3) === 'EVP' ||
              String(data['report_code']).substring(0, 3) === 'PVA' ||
              String(data['report_code']).substring(0, 3) === 'VAU')
            && String(data['report_code']).substring(0, 7) !== 'OUT_COT'
            && String(data['report_code']).substring(0, 7) !== 'VAC_NAT'
          ).sort((obj1, obj2) => { // Sort templates by updated_at descending
            if (obj1['updated_at'] < obj2['updated_at']) {
              return 1;
            }
            if (obj1['updated_at'] > obj2['updated_at']) {
              return -1;
            }
            return 0;
          });
          response.data = filteredData;
          return response;
        })
      );
  }

  editFunction = (element: DynamicData): void => {
    const idReportTemplate = element['id_report_template'];
    const reportCode = element['report_code'];

    const url = `/model/${reportCode}/${idReportTemplate}`;
    this.router.navigateByUrl(url);
  }

  deleteFunction = (element: DynamicData): Observable<ServerSideResponse> => {
    return this.modelReportService.deleteReportTemplate(element["id_report_template"]);
  }

  viewFunction = (element: DynamicData): void => {
    const reportCode = element['report_code'];
    const subReportCode = this.reportService.getSubReportCode(reportCode);
    const idReportTemplate = element['id_report_template'];

    const url = `reports/report/${reportCode}/${subReportCode}/${idReportTemplate}`;
    this.router.navigateByUrl(url);
  }
}
