import { Injectable, inject } from '@angular/core';
import { DynamicData, Result, ServerSideResponse, User } from '../model/types';
import * as enTranslationsFile from '../../../assets/i18n/en-GB.json';
import * as frTranslationsFile from '../../../assets/i18n/fr-FR.json';
import * as deTranslationsFile from '../../../assets/i18n/de-DE.json';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { HttpErrorService } from './http-error.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private http = inject(HttpClient);
  private httpErrorService = inject(HttpErrorService);

  translations: { [key: string]: DynamicData } = {};

  retrieveTranslations(): void {
    this.translations["en-GB"] = enTranslationsFile;
    this.translations["fr-FR"] = frTranslationsFile;
    this.translations["de-DE"] = deTranslationsFile;
  }

  getTranslation(translation: string) : string {
    return this.getLocaleTranslation(localStorage.getItem('userLocale') || navigator.language, translation);
  }

  getLocaleTranslation(locale: string, code: string): string {
    return this.translations[locale] != null && this.translations[locale] != undefined && this.translations[locale][code] != null && this.translations[locale][code] != undefined ? this.translations[locale][code] : code;
  }

  arrayToString(array: string[]): string {
    let result = "";
    array.forEach((value, index) => {
      result += (index != 0 ? "," : "") + "'" + value + "'";
    })
    return result;
  }

  dateToUTCDate(year: number, month: number, day: number): Date {
    return new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
  }

  convertStringToUTCDate(dateString: string): Date {
    // Remove any non-numeric characters from the date string
    const cleanedDateString = dateString.replace(/[^0-9]/g, '');

    // Extract day, month, and year based on their positions
    const day = parseInt(cleanedDateString.substring(0, 2), 10);
    const month = parseInt(cleanedDateString.substring(2, 4), 10);
    const year = parseInt(cleanedDateString.substring(4), 10);

    return this.dateToUTCDate(year, month, day);
  }

  convertToUTCDAte(date: Date | null | undefined): Date | undefined {
    const strDate: string | undefined = date?.toLocaleDateString("fr-FR");
    return strDate === undefined ? undefined : new Date(
      Date.UTC(+strDate.substring(6),
        +strDate.substring(3, 5) - 1,
        +strDate.substring(0, 2),
        0,
        0,
        0
      ));
  }

  getUserPreference(preferenceType: string, userName: string): Observable<Result<DynamicData[]>> {
    const params = {
      Filter: {
        User: userName,
        Type: preferenceType
      }
    };

    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/UserPreferences/Get`, params)
      .pipe(
        // tap(x => console.log(x)),
        map(response => ({ data: response.data } as Result<DynamicData[]>)),
        // shareReplay(1),
        catchError(err => of({
          data: [],
          error: this.httpErrorService.formatError(err)
        } as Result<DynamicData[]>))
      );
  }

  deleteUserPreference(preferenceType: string, userName: string): Observable<ServerSideResponse> {
    const params = {
      Filter: {
        UserLogin: userName,
        PreferenceType: preferenceType
      }
    };

    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/DeleteUserPreferences/Invoke`, params);
  }

  insertUserPreference(preferenceType: string, preferenceValue: string, userName: string): Observable<ServerSideResponse> {
    const params = {
      Filter: {
        UserLogin: userName,
        PreferenceType: preferenceType,
        PreferenceValue: preferenceValue
      }
    };

    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/InsertUserPreferences/Invoke`, params);
  }

  updateUserPreference(preferenceType: string, preferenceValue: string, userName: string): Observable<number> {
    return new Observable(observer => {
      let userPreferenceUpdated = 0;

      this.getUserPreference(preferenceType, userName).subscribe({
        next: (currentPreferenceValue) => {
          const currentValue = currentPreferenceValue.data![0]["preference_value"];
          if (currentValue !== preferenceValue) {
            this.deleteUserPreference(preferenceType, userName).subscribe({
              next: () => {
                this.insertUserPreference(preferenceType, preferenceValue, userName).subscribe({
                  next: () => {
                    userPreferenceUpdated++;
                    observer.next(userPreferenceUpdated);
                    observer.complete();
                  },
                  error: (insertError) => {
                    observer.error(insertError);
                  }
                });
              },
              error: (deleteError) => {
                observer.error(deleteError);
              }
            });
          } else {
            observer.next(userPreferenceUpdated);
            observer.complete();
          }
        },
        error: (getError) => {
          observer.error(getError);
        }
      });
    });
  }

  getTranslationData(languageCode: string, code: string, idReportTranslation: number = 0, tablixName: string | null): Observable<ServerSideResponse> {
    const params = {
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
      filter: {
        Code: code,
        LanguageCode: languageCode,
        IdReportTemplate: idReportTranslation,
        TablixName: tablixName,
      },
    };

    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/GetTranslation/Get`, params)
  }

  exportToCsv(filename: string, data: any, csvSeparator: string, translateHeaderToLocale?: string, columnsToDisplay?: string[]) {
    if (data.length > 0) {
      const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
      const header = columnsToDisplay ? columnsToDisplay : Object.keys(data[0]);

      const csv = data.map((row: any) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(';')
      );
      if (translateHeaderToLocale) {
        let translatedHeader: string[] = [];
        header.forEach((value) => {
          translatedHeader.push(this.getLocaleTranslation(translateHeaderToLocale, value.toUpperCase()));
        });
        csv.unshift(translatedHeader.join(csvSeparator));
      }
      else {
        csv.unshift(header.join(csvSeparator));
      }
      const csvArray = csv.join('\r\n');

      const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  getFormattedLocale(language: string): string {
    const languageMap: { [key: string]: string } = {
      'fr': 'fr-FR',
      'en': 'en-GB',
      'de': 'de-DE',
    };

    if (language.includes('-')) {
      return language;
    }

    return languageMap[language] || language;
  }

  getAreaUnits(): Observable<ServerSideResponse> {
    const params = {
      LanguageCode: localStorage.getItem('userLocale') || navigator.language,
    };

    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/AreaUnitsList/Get`, params);
  }

  getCurrency(): Observable<ServerSideResponse> {
    const params = {
    };

    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/TargetCurrenciesList/Get`, params);
  }

  log(logTitle: string, logValue: any) {
    console.log("# " + logTitle);
    console.log(logValue);
  }

  error(logTitle: string, logValue: any) {
    console.error("# " + logTitle);
    console.error(logValue);
  }

  copyObject(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  isNumber(value?: string | null): boolean {
    return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
  }

}
