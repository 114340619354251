export const environment = {
    production: false,
    // apiUrl: 'https://localhost:44316'
    apiUrl:'https://alhambra.api.r7.herdia.fr',
    baseUrl: 'https://alhbnpuat.herdia.fr/',
    nbDaysBeforeAllowPwdChange: 0,
    tempPasswordValidityTime: 15,
    tokenValidityTime: 15,
    smsCodeValidtyTime: 15,
    PasswordRecoveryOption: 'TemporaryPassword',
    smtpHost: 'smtp.gmail.com',
    smtpPort: 587,
    smtpEnabledSsl: true,
    smtpTo: 'dev@herdia.fr',
    smtpUser: 'admin@herdia.fr',
    smtpPassword: 'Zk749cf:',
    smtpFrom: 'admin@herdia.fr',
    smtpAuthentication: true,
    testsReportParams: {
        draw: 1,
        columns: [
            {
                data: 'BUILDING_CODE',
                name: 'BUILDING_CODE',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false,
                },
            }
        ],
        order: [
            {
                column: 0,
                dir: 'asc',
            },
        ],
        start: null,
        length: null,
        search: {
            value: '',
            regex: false,
        },
        filter: {
            ImportDate: "2023-08-17T00:00:00.000Z",
            AnalysisDate: "2023-08-17T00:00:00.000Z",
            LanguageCode: 'fr-FR',
            IdBuildingList: [847695, 848357, 847451],
            BuildingsNames: "53017, 53118, 53119",
            TargetAreaUnitCode: 'SQM',
            TargetCurrency: 'EUR',
            CurrencyDateRate: "2023-08-17T00:00:00.000Z",
            CurrencyRateDate: "2023-08-17T00:00:00.000Z",
            ReportType: "BY_LEASE",
            // StartDate: this.reportDatesService.showDateRange ? this.startDate() : null,
            EndDate: "2023-08-17T00:00:00.000Z",
            Active: "0",
            LeaseTypeCodeList: ["'00000'"],
            FilterPeriods: '0030_0060_0090_0180_0365',
            ReferenceYear: 2023,
            BudgetTypeCode: 'OPEXCAPEX',
            TaxeTypeCode: 'HT',
            BudgetStatus: 'ALL',
            BudgetType: 'ALL',
            ExpenseType: 'ALL',
            InvoiceType: "ACCOUNTED",
            UnitTypesCodesList: ["'00000000000000000000'"],
            VacancyTypesCodesList: ["'00000000000000000000'"],
            VacancyArea: 0,
            VacancyRate: 0,
            VacancyMonths: 0,
            // HeadlineNetInitialYield	: this.selectedHeadlineNetInitYield(),
            // EffectiveNetInitialYield	: this.selectedEffectiveNetInitYield(),
            // RevisionDate: this.selectedCutOffRevisionDate(),
        }
    },
    testsReportExpectedResults: [
        { id: 0, expectedResult: '3'},
        { id: 100, expectedResult: '33'},
        { id: 150, expectedResult: '161'},
        { id: 200, expectedResult: '60'},
        { id: 300, expectedResult: '343'},
        { id: 400, expectedResult: '343'},
        { id: 420, expectedResult: '131'},
        { id: 430, expectedResult: '3'},
        { id: 440, expectedResult: '3'},
        { id: 450, expectedResult: '3'},
        { id: 460, expectedResult: '3'},
        { id: 480, expectedResult: '84'},
        { id: 500, expectedResult: '26'},
        { id: 600, expectedResult: '92'},
        { id: 700, expectedResult: '86'},
        { id: 800, expectedResult: '187'},
        { id: 880, expectedResult: '85'},
        { id: 900, expectedResult: '86'},
        { id: 1000, expectedResult: '2714'},
        { id: 1100, expectedResult: '2659'},
    ]
};
