<div>
  <form [formGroup]="ngForm" (ngSubmit)="onSubmit()">
    <mat-card class="card-style">
      <mat-card-content>
        <h4>{{ isEditMode ? ('lblUpdateReportTemplate' | translate) : ('lblCreateReportTemplate' | translate) }}</h4>
        <mat-form-field style="width: 400px;" appearance="outline">
          <mat-label>{{ 'lblSelectBaseReport' | translate }}</mat-label>
          <mat-select formControlName="selectedReport" (selectionChange)="onReportChange($event)">
          <mat-option disabled>
            <ngx-mat-select-search placeholderLabel="{{ 'SEARCH' | translate }}" noEntriesFoundLabel="{{ 'NO DATA' | translate}}" [formControl]="searchReportControl">
            </ngx-mat-select-search>
          </mat-option>
            @for (model of filteredReports | async; track model) {
            <mat-option [value]="model['report_code']">{{model['report_name']}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field style="margin-inline: 5px;" appearance="outline">
          <mat-label>{{ 'lblNameOfTemplate' | translate }}</mat-label>
          <input matInput formControlName="name" placeholder="{{'lblNameOfTemplate' | translate}}" required>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <!-- {{ 'btnSelectColumns' | translate }} -->
    <mat-tab-group>
      <mat-tab>
        <ng-template matTabLabel>
          <mat-label class="label">{{ 'btnSelectColumns' | translate }}</mat-label>
        </ng-template>
        <ng-template matTabContent>
          <mat-card class="card-style">
            <mat-card-content>
              <mat-form-field class="selectBox" style="width: 25rem;" appearance="outline">
                <mat-label>{{ 'lblModifyColumns' | translate }}</mat-label>
                  <mat-select formControlName="selectedCols" multiple #select (selectionChange)="onSelectionChange($event)">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="{{ 'SEARCH' | translate }}" noEntriesFoundLabel="{{ 'NO DATA' | translate }}" [formControl]="searchColsControl">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-select-trigger>
                      <!-- we take index 1 because index 0 is for the search option -->
                      {{ngForm.get('selectedCols')?.value?.[0] || '' | translate}}
                      @if ((ngForm.get('selectedCols')?.value?.length || 0) > 1) {
                      <span class="additional-selection">
                        <!-- remove -2 because the search option must be removed -->
                        +{{ (ngForm.get('selectedCols')?.value.length || 0) - 1 }}
                        {{ (ngForm.get('selectedCols')?.value.length === 1 ? 'lblOther' : 'lblOther') | translate }}
                      </span>
                      }
                    </mat-select-trigger>
                    <div class="select-all">
                      <mat-checkbox
                        [(ngModel)]="allSelected"
                        [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection()">{{ 'lkbSelectAll' | translate }}
                      </mat-checkbox>
                    </div>
                    @for (col of filteredItem | async; track col) {
                      @if (col['TABLIX_NAME'] === appService.getTranslation(col['TABLIX_NAME'])) {
                        <mat-option (click)="optionClick()" #option [value]="col['COLUMN_NAME']">{{col['COLUMN_NAME'] | translate}}</mat-option>
                      }
                      @else {
                        <mat-option (click)="optionClick()" #option [value]="col['COLUMN_NAME']">{{col['COLUMN_NAME'] | translate}} ({{col['TABLIX_NAME'] | translate}})</mat-option>
                      }
                    }
                  </mat-select>
                </mat-form-field>
                <div>
                  <h4>{{'lblChangeParams' | translate}}</h4>
                  <div *ngIf="getSelectedColumns() !== null" [ngClass]="getSelectedColumns().length > 0 ? 'cdk-drop-list-custom' : '' " cdkDropList
                    (cdkDropListDropped)="drop($event)" [cdkDropListData]="getSelectedColumns()">
                    @for (col of getSelectedColumns(); track col) {
                    <div cdkDrag class="field-container" style="display: flex; align-items: center; margin-bottom: 10px;">
                      @if (col['TABLIX_NAME'] === appService.getTranslation(col['TABLIX_NAME'])) {
                        <mat-label class="column-label">{{ col['COLUMN_NAME'] | translate }}</mat-label>
                      }
                      @else {
                        <mat-label class="column-label">{{ col['COLUMN_NAME'] | translate }} ({{col['TABLIX_NAME'] | translate}})</mat-label>
                      }
                      <div class="field-container-input">
                        <mat-form-field class="full-width">
                          <mat-label>{{ 'lbEnglish' | translate }}</mat-label>
                          <input matInput [(ngModel)]="col['EN_CUSTOM_LABEL']" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                      </div>

                      <div class="field-container-input">
                        <mat-form-field class="full-width">
                          <mat-label>{{ 'lbFrench' | translate }}</mat-label>
                          <input matInput [(ngModel)]="col['FR_CUSTOM_LABEL']" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                      </div>

                      <div class="field-container-input">
                        <mat-form-field class="full-width">
                          <mat-label>{{ 'lbGerman' | translate }}</mat-label>
                          <input matInput [(ngModel)]="col['DE_CUSTOM_LABEL']" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                      </div>
                      <div class="delete-button-container">
                        <button mat-mini-fab color="primary" aria-label="delete" type="button" style="margin-inline: 40px;"
                          (click)="deleteColumn(col['COLUMN_NAME'])">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                    }
                  </div>
                </div>
                <div style="display: flex; gap: 15px;">
                  <button mat-raised-button type="submit" style="margin-block: 10px;"
                    [disabled]="ngForm.invalid">
                    <mat-icon>save</mat-icon>
                    {{ 'btnSubmit' | translate }}
                  </button>
                  <button mat-raised-button type="button" style="margin-block: 10px;"
                    (click)="navigateBack()">
                    {{ "btnCancel" | translate }}
                  </button>
                </div>
              </mat-card-content>
          </mat-card>
        </ng-template>
      </mat-tab>
    <!-- <mat-tab label="{{ 'btnSelectLogo' | translate }}"> TO DO </mat-tab> -->
    </mat-tab-group>
  </form>
</div>
