import { Injectable, computed, inject, signal } from '@angular/core';
import { AuthService } from './auth.service';
import { SidenavService } from './sidenav.service';
import { Observable, catchError, filter, of, switchMap } from 'rxjs';
import { DynamicData, Result, ServerSideResponse } from '../model/types';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { toObservable } from '@angular/core/rxjs-interop';
import { ReportDatesService } from './report-dates.service';
import { SpecificCriteriaService } from './specific-criteria.service';
import { AppService } from './app.service';
import { HttpErrorService } from './http-error.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private http = inject(HttpClient);
  private authSvc = inject(AuthService);
  private sidenavService = inject(SidenavService);
  private reportDatesService = inject(ReportDatesService);
  private specificCriteriaService = inject(SpecificCriteriaService);
  private appService = inject(AppService);
  private errorService = inject(HttpErrorService);

  private loggedUser = this.authSvc.loggedUser;

  private selectedImportDate$ = this.sidenavService.selectedImportDate$;

  private analysisDate = this.reportDatesService.analysisDate;
  private startDate = this.reportDatesService.startDate;
  private endDate = this.reportDatesService.endDate;
  private selectedIdBuildingList = this.sidenavService.selectedIdBuildingList;
  private selectedBuildingNames = this.sidenavService.selectedBuildingNames;
  private selectedLeaseActiveCode = this.specificCriteriaService.selectedLeaseActiveCode;
  private selectedFullLeaseActiveCode = this.specificCriteriaService.selectedFullLeaseActiveCode;
  private selectedReferenceYear = this.specificCriteriaService.selectedReferenceYear;
  private selectedBudgetTypeCode = this.specificCriteriaService.selectedBudgetTypeCode;
  private selectedTaxTypeCode = this.specificCriteriaService.selectedTaxTypeCode;
  private selectedLeaseLitigationCode = this.specificCriteriaService.selectedLeaseLitigationCode;
  private selectedBudgetTypeCodeRGD = this.specificCriteriaService.selectedBudgetTypeCodeRGD;
  private selectedBudgetStatusCode = this.specificCriteriaService.selectedBudgetStatusCode;
  private selectedExpenseTypeCode = this.specificCriteriaService.selectedExpenseTypeCode;
  private selectedUnitTypeListCode = this.specificCriteriaService.selectedUnitTypeListCode;
  private selectedVacancyTypeListCode = this.specificCriteriaService.selectedVacancyTypeListCode;
  private selectedVacancyArea = this.specificCriteriaService.selectedVacancyArea;
  private selectedVacancyRate = this.specificCriteriaService.selectedVacancyRate;
  private selectedVacancyMonths = this.specificCriteriaService.selectedVacancyMonths;
  private selectedLeaseTypesListCode = this.specificCriteriaService.selectedLeaseTypesListCode;
  private selectedHeadlineNetInitYield = this.specificCriteriaService.selectedHeadlineNetInitYield;
  private selectedEffectiveNetInitYield = this.specificCriteriaService.selectedEffectiveNetInitYield;
  private selectedCutOffRevisionDate = this.specificCriteriaService.selectedCutOffRevisionDate;
  private selectedCutOffDate = this.specificCriteriaService.selectedCutOffDate;

  reportColumns = signal<any>([]);
  reportController = signal<string>("");
  reportCode = signal<string | null>("");
  reportType = signal<string>("BY_LEASE");

  testsParams = signal<any>({});

  reportParams = computed(() => {
    if (JSON.stringify(this.testsParams()) === "{}") {
      return {
        draw: 1,
        columns: this.reportColumns(),
        order: [
          {
            column: 0,
            dir: 'asc',
          },
        ],
        start: null,
        length: null,
        search: {
          value: '',
          regex: false,
        },
        filter: {
          ImportDate: this.sidenavService.selectedImportDateSubject.value, // Utilisation directe de la valeur du BehaviorSubject
          AnalysisDate: this.selectedCutOffDate() ? this.selectedCutOffDate() : this.analysisDate(),
          LanguageCode: localStorage.getItem('userLocale') || navigator.language,
          IdBuildingList: this.selectedIdBuildingList(),
          TargetAreaUnitCode: 'SQM',
          TargetCurrency: 'EUR',
          CurrencyDateRate: this.selectedCutOffDate() ? this.selectedCutOffDate() : this.analysisDate(),
          CurrencyRateDate: this.selectedCutOffDate() ? this.selectedCutOffDate() : this.analysisDate(),
          ReportType: this.reportType(),
          StartDate: this.reportDatesService.showDateRange ? this.startDate() : null,
          EndDate: this.reportDatesService.showDateRange ? this.endDate() : this.analysisDate(),
          Active: (this.reportController().indexOf("OutstandingPayments") > -1 || this.reportController().indexOf("OutstandPayInvoice") > -1 ? this.selectedLeaseActiveCode()
            : this.reportController().indexOf("OutstandPayMonitoring") > -1 ? this.selectedFullLeaseActiveCode() : this.selectedLeaseLitigationCode()),
          //LeaseTypeCodeList: ["'00000'"],
          FilterPeriods: '0030_0060_0090_0180_0365',
          ReferenceYear: this.selectedReferenceYear(),
          BudgetTypeCode: this.selectedBudgetTypeCode(),
          TaxeTypeCode: this.selectedTaxTypeCode(),
          BudgetStatus: this.selectedBudgetStatusCode(),
          BudgetType: this.selectedBudgetTypeCodeRGD(),
          ExpenseType: this.selectedExpenseTypeCode(),
          InvoiceType: this.reportCode() === "GEC_REPORT" ? "ACCOUNTED" : "PAID",
          UnitTypesCodesList: this.selectedUnitTypeListCode().length > 0 ? this.selectedUnitTypeListCode().filter((item) => item.id).map((item) => item.id) : ["'00000000000000000000'"],
          VacancyTypesCodesList: this.selectedVacancyTypeListCode().length > 0 ? this.selectedVacancyTypeListCode().filter((item) => item.id).map((item) => item.id) : ["'00000000000000000000'"],
          VacancyArea: this.selectedVacancyArea(),
          VacancyRate: this.selectedVacancyRate(),
          VacancyMonths: this.selectedVacancyMonths(),
          LeaseTypeCodeList: this.selectedLeaseTypesListCode().length > 0 ? this.selectedLeaseTypesListCode().filter((item) => item.id).map((item) => item.id) : ["'00000'"],
          HeadlineNetInitialYield: this.selectedHeadlineNetInitYield(),
          EffectiveNetInitialYield: this.selectedEffectiveNetInitYield(),
          RevisionDate: this.selectedCutOffRevisionDate(),
        }
      };
    } else {
      return this.testsParams();
    }

  });

  reportData$ = toObservable(this.reportParams)
    .pipe(
      filter(Boolean),
      switchMap(() => {
        if (this.selectedIdBuildingList().length > 0 || JSON.stringify(this.testsParams()) !== "{}") {
          this.appService.log("ReportService - Launch " + this.reportController() + " Report - Parameters :", this.reportParams());
          return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/${this.reportController()}/Get`, this.reportParams()).pipe(
            catchError(err => of({
              draw: 0,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
              ColSums: {},
              dataType: "",
              error: this.errorService.formatError(err)
            } as any))
          );
        } else {
          const response: ServerSideResponse = {
            draw: 0,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
            ColSums: {},
            dataType: ""
          };
          return of(response);
        }
      })
    );

  getReportExport = (reportCode: string | null, reportName: string | null, controller: string, exportType: string | null, exportFormat: string | null, idReportTemplate: number | null): Observable<Blob> => {
    const language = localStorage.getItem('userLocale') || navigator.language;
    const selectedBuildingNames: string = this.selectedBuildingNames().toString();
    const reportParameters = `${this.appService.getTranslation("lblClient")} : ${this.appService.getTranslation("ALL_CLIENTS")}\r\n`
      + `${this.appService.getTranslation("lblPortfolio")} : ${this.sidenavService.selectedPortfolioLabel()}\r\n`
      + `${this.appService.getTranslation("NEUTRALIZATION")} : ${this.appService.getTranslation("liAllBuildings")}\r\n`
      + `${this.appService.getTranslation("lblExternalFunds")} : ${this.appService.getTranslation("ALL_EXTERNAL_FUND")}\r\n`
      + `${this.appService.getTranslation("lblAssetManager")} : ${this.appService.getTranslation("ALL_ASSET_MANAGER")}\r\n`
      + `${this.appService.getTranslation("lblBusinessUnits")} : ${this.appService.getTranslation("ALL_BUSINESS_UNIT")}\r\n`
      + `${this.appService.getTranslation("lblFeeCenter")} : ${this.appService.getTranslation("ALL_FEE_CENTER")}\r\n`
      + `${this.appService.getTranslation("AGENCY")} : ${this.appService.getTranslation("ALL_AGENCY")}\r\n`
      + `${this.appService.getTranslation("lblPropertyManager")} : ${this.appService.getTranslation("ALL_MANAGER")}\r\n`
      + `${this.appService.getTranslation("lblBuildings2")} : ${selectedBuildingNames}\r\n`
      + `${this.appService.getTranslation("lblImportDate")} : ${this.sidenavService.selectedImportDateSubject.value!.toLocaleDateString()}\r\n`
      + (controller.indexOf("General") > -1 ? `` : `${this.appService.getTranslation("lblAnalysisDate")} : ${this.analysisDate()!.toLocaleDateString()}\r\n`)
      + (controller.indexOf("OutstandingPayments") > -1 || controller.indexOf("OutstandPayInvoice") > -1 ? `${this.appService.getTranslation("lblSelectLeaseDate")} : ${this.specificCriteriaService.getLeaseActiveLabel(this.selectedLeaseActiveCode())}\r\n` : ``)
      + (controller.indexOf("OutstandPayMonitoring") > -1 ? `${this.appService.getTranslation("lblSelectLeaseDate")} : ${this.specificCriteriaService.getFullLeaseActiveLabel(this.selectedFullLeaseActiveCode())}\r\n` : ``)
      + (controller.indexOf("Security") > -1 ? `${this.appService.getTranslation("lblSelectLeaseDate")} : ${this.specificCriteriaService.getLeaseLitigationLabel(this.selectedLeaseLitigationCode())}\r\n` : ``)
      + (controller.indexOf("Budget") > -1 ? `${this.appService.getTranslation("lblReferenceYear")} : ${this.selectedReferenceYear()}\r\n`
        + `${this.appService.getTranslation("lblTaxeType")} : ${this.specificCriteriaService.getTaxTypeLabel(this.selectedTaxTypeCode())}\r\n`
        + `${this.appService.getTranslation("lblBudgetType")} : ${this.specificCriteriaService.getBudgetTypeLabel(this.selectedBudgetTypeCode())}\r\n` : ``)
      + (controller.indexOf("General") > -1 ? `${this.appService.getTranslation("lblStartDate")} : ${this.startDate()!.toLocaleDateString()}\r\n`
        + `${this.appService.getTranslation("lblEndDate")} : ${this.endDate()!.toLocaleDateString()}\r\n`
        + `${this.appService.getTranslation("lblBudgetType")} : ${this.specificCriteriaService.getBudgetTypeRGDLabel(this.selectedBudgetTypeCodeRGD())}\r\n`
        + `${this.appService.getTranslation("lblBudgetStatus")} : ${this.specificCriteriaService.getBudgetStatusLabel(this.selectedBudgetStatusCode())}\r\n`
        + `${this.appService.getTranslation("lblExpenseType")} : ${this.specificCriteriaService.getExpenseTypeLabel(this.selectedExpenseTypeCode())}\r\n` : ``)
      + `Devise : € - Euro\r\n`;
    const param = {
      "UserLogin": this.loggedUser()?.UserName,
      "Route": controller,
      "Params": {
        "filter": {
          "LanguageCode": localStorage.getItem('userLocale') || navigator.language,
          "ImportDate": this.sidenavService.selectedImportDateSubject.value,
          "AnalysisDate": this.selectedCutOffDate() ? this.selectedCutOffDate() : this.analysisDate(),
          "IdBuildingList": this.selectedIdBuildingList()?.toString(),
          "TargetAreaUnitCode": "SQM",
          "TargetAreaUnitLabel": "m²",
          "TargetCurrency": "EUR",
          "TargetCurrencyLabel": "€ - Euro",
          "CurrencyDateRate": this.selectedCutOffDate() ? this.selectedCutOffDate() : this.analysisDate(),
          "CurrencyRateDate": this.selectedCutOffDate() ? this.selectedCutOffDate() : this.analysisDate(),
          "ReportType": this.reportType(),
          "StartDate": this.startDate(),
          "EndDate": this.reportDatesService.showDateRange ? this.endDate() : this.analysisDate(),
          "Active": (controller.indexOf("OutstandingPayments") > -1 || controller.indexOf("OutstandPayInvoice") > -1 ? this.selectedLeaseActiveCode()
            : controller.indexOf("OutstandPayMonitoring") > -1 ? this.selectedFullLeaseActiveCode() : this.selectedLeaseLitigationCode()),
          //"LeaseTypeCodeList": "'00000'",
          "FilterPeriods": '0030_0060_0090_0180_0365',
          "ReferenceYear": this.selectedReferenceYear(),
          "BudgetTypeCode": this.selectedBudgetTypeCode(),
          "TaxeTypeCode": this.selectedTaxTypeCode(),
          "BudgetStatus": this.selectedBudgetStatusCode(),
          "BudgetType": this.selectedBudgetTypeCodeRGD(),
          "ExpenseType": this.selectedExpenseTypeCode(),
          "InvoiceType": this.reportCode() === "GEC_REPORT" ? "ACCOUNTED" : "PAID",
          "UnitTypesCodesList": this.selectedUnitTypeListCode().length > 0 ? this.selectedUnitTypeListCode().filter((item) => item.id).map((item) => item.id) : ["'00000000000000000000'"],
          "VacancyTypesCodesList": this.selectedVacancyTypeListCode().length > 0 ? this.selectedVacancyTypeListCode().filter((item) => item.id).map((item) => item.id) : ["'00000000000000000000'"],
          "VacancyArea": this.selectedVacancyArea(),
          "VacancyRate": this.selectedVacancyRate(),
          "VacancyMonths": this.selectedVacancyMonths(),
          "LeaseTypeCodeList": this.selectedLeaseTypesListCode().length > 0 ? this.selectedLeaseTypesListCode().filter((item) => item.id).map((item) => item.id) : "'00000'",
          "HeadlineNetInitialYield": this.selectedHeadlineNetInitYield(),
          "EffectiveNetInitialYield": this.selectedEffectiveNetInitYield(),
          "RevisionDate": this.selectedCutOffRevisionDate(),
        },
        "mappings": null,
        "additionalFields": null,
        "Format": 0,
        "draw": 0,
        "columns": null,
        "order": null,
        "start": 0,
        "length": 0,
        "search": null
      },
      "OutputPath": "C:/alhambra/tmp/ProperLake_Report.xlsx",
      "BaseUrl": "https://localhost:44301/",
      "ReportCode": exportType === "RAW" ? reportCode + "_RAW" : reportCode,
      "ReportName": reportName,
      "ReportViewerFormat": exportFormat,
      "IdReportTemplate": idReportTemplate,
      "ReportParameters": reportParameters,
      "Extension": exportFormat === "EXCELOPENXML" ? "xlsx" : "pdf",
      "DeviceInfo": exportFormat === "EXCELOPENXML" ? "" : "<DeviceInfo><OutputFormat>PDF</OutputFormat><PageWidth>42cm</PageWidth><PageHeight>29.7cm</PageHeight></DeviceInfo>",
      "MimeTypeHeader": exportFormat === "EXCELOPENXML" ? "application/Excel" : "application/pdf",
      "MimeTypeResponse": exportFormat === "EXCELOPENXML" ? "application/vnd.xls" : "application/vnd.pdf",
      "UseLastImportdate": false,
      "UseCSVDoubleQuotes": true,
      "IsRecordedInFolder": false,
      "IsSentToEDM": false,
      "InterfaceName": null,
      "InterfaceTypeCode": null,
      "IdSequence": null,
      "IdInterface": 0,
      "BuildingCode": null,
      "BuildingName": null,
      "OwnerName": null,
      "OwnerCode": null,
      "PortfolioCode": null,
      "PortfolioLabel": null,
      "ReportLabel": null,
      "CustomCLROutputProcedureName": null,
      "IsActivated": null,
      "OneFile": null,
      "MultipleFiles": null,
      "Quote": null,
      "HeaderLine": null,
      "MinDateHistory": null
    };

    this.appService.log("ReportService - Launch " + controller + " Export - Parameters :", param);
    return this.http.post(`${environment.apiUrl}/api/${controller}/GetReport`, param, { responseType: 'blob' });
  }

  getSubReportCode(reportCode: string): string {
    let subReportCode: string;
    switch (reportCode) {
      case "VAC_PHY_REPORT":
      case "VAC_PHY_REPORT_RAW":
        subReportCode = "SQM";
        break;
      case "YIM_REPORT":
      case "YIM_REPORT_RAW":
        subReportCode = "YMG";
        break;
      default:        
        subReportCode = "NULL";
      break;
    }
    return subReportCode;
  }

}
