<mat-form-field *ngIf="!showDateRange" appearance="outline" class="dateFormField">
  <mat-label>{{"lblAnalysisDate" | translate}}</mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="analysisDateControl()" (dateChange)="analysisDateChange()">
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<mat-form-field *ngIf="showDateRange" appearance="outline" class="dateFormField startDateFormField">
  <mat-label>{{"lblStartDate" | translate}}</mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="startDateControl()" (dateChange)="startDateChange()">
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<mat-form-field *ngIf="showDateRange" appearance="outline" class="dateFormField">
  <mat-label>{{"lblEndDate" | translate}}</mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="endDateControl()" (dateChange)="endDateChange()">
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
