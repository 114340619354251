import { computed, inject, Injectable } from '@angular/core';
import { AppService } from './app.service';
import { catchError, combineLatest, filter, forkJoin, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { SidenavService } from './sidenav.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpErrorService } from './http-error.service';
import { DynamicData, InsertFileImportRequestModel, InsertRepositoryLease, Result, ServerSideResponse } from '../model/types';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  private appService = inject(AppService);
  private authSvc = inject(AuthService);
  private sidenavService = inject(SidenavService);
  private http = inject(HttpClient);
  private httpErrorService = inject(HttpErrorService);

  private loggedUser = this.authSvc.loggedUser;
  private selectedImportDate = this.sidenavService.selectedImportDateSubject.value!;


  private leaseRepositoryParams = computed(() => {
    return {
      filter: {
        User: this.loggedUser()?.UserName,
        ImportDate: this.selectedImportDate,
        TargetCurrency: 'EUR',
        CurrencyRateDate: this.selectedImportDate,
        DecryptKey: ''
      }
    }
  });

  private leaseRepositoryData$ = toObservable(this.leaseRepositoryParams)
    .pipe(
      filter(Boolean),
      switchMap(lease => {
        return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/RepositoryLeaseList/Get`, this.leaseRepositoryParams())
          .pipe(
            // tap(x => console.log(x)),
            map(response => ({
              data: response.data
            } as Result<DynamicData[]>)),
            catchError(err => of({
              data: [],
              error: this.httpErrorService.formatError(err)
            } as Result<DynamicData[]>))
          );
      }
      )
    );

  csvSeparator$ = this.appService.getUserPreference("CSV_SEPARATOR", this.loggedUser()!.UserName);

  getFileImportData(): Observable<ServerSideResponse> {
    const fileImportParams = {
      filter: {
        ImportType: "REPOSITORY_LEASE"
      }
    };
    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/FileImport/Get`, fileImportParams);
  }

  downloadLeaseRepositoryTemplate(withData: boolean | null) {
    // forkJoin doesn't work !?
    combineLatest([this.csvSeparator$, this.leaseRepositoryData$])
      .pipe(take(1))
      .subscribe(([csvSeparator, leaseRepository]) => {
        // console.log(csvSeparator);
        // console.log(leaseRepository);

        const language = localStorage.getItem('userLocale') || navigator.language;
        const separator: string = csvSeparator.data && csvSeparator.data.length > 0 ? csvSeparator.data[0]['preference_value'] : ";";

        if (leaseRepository.data && leaseRepository.data.length > 0 && withData) {
          this.appService.exportToCsv(this.appService.getTranslation("lblFilenameLeaseRepository"), leaseRepository.data, separator, language);
        }
        else {
          const header = [{
            [this.appService.getTranslation("BUILDING_CODE")]: "",
            [this.appService.getTranslation("BUILDING_NAME")]: "",
            [this.appService.getTranslation("LEASE_CODE")]: "",
            [this.appService.getTranslation("TENANT_NAME")]: "",
            [this.appService.getTranslation("IS_IN_LITIGATION")]: "",
            [this.appService.getTranslation("LITIGATION_INFORMATION")]: "",
            [this.appService.getTranslation("LITIGATION_AMOUNT")]: "",
            [this.appService.getTranslation("CURRENCY")]: ""
          }];
          this.appService.exportToCsv(this.appService.getTranslation("lblFilenameLeaseRepository"), header, separator, language);
        }
      });
  }

  insertFileImport(insertFileImportParams: InsertFileImportRequestModel) : Observable<number>{
    const params = {
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
      filter: {...insertFileImportParams}
    };
    return this.http.post<number>(`${environment.apiUrl}/api/InsertFileImport/Invoke`, params);
  }

  insertRepositoryLease(insertRepositoryLeaseParams: InsertRepositoryLease) : Observable<number> {
    const params = {
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
      filter: {...insertRepositoryLeaseParams}
    };
    return this.http.post<number>(`${environment.apiUrl}/api/UpdateRepositoryLease/Invoke`, params);
  }
}
