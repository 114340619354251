﻿{
  "01-BALANCE-ALL_REG_CHARGES": "Outstanding Balance - All Billing Items (€)",
  "02-BALANCE-RENTS": "Outstanding Balance - Rent (€)",
  "03-BALANCE-SERVICE_CHARGES": "Outstanding Balance - Service Charges (€)",
  "04-BALANCE-OTHER_EXPENSES": "Outstanding Balance - Other Expenses (€)",
  "05-BALANCE-OTHER_REG_CHARGES": "Outstanding Balance - Other Billing Items (€)",
  "11-COLLECT-ALL_REG_CHARGES": "Amount Received - All Billing Items (€)",
  "12-COLLECT-RENTS": "Amount Received - Rent (€)",
  "13-COLLECT-SERVICE_CHARGES": "Amount Received - Service Charges (€)",
  "14-COLLECT-OTHER_EXPENSES": "Amount Received - Other Expenses (€)",
  "15-COLLECT-OTHER_REG_CHARGES": "Amount Received - Other Billing Items (€)",
  "21-RATE-ALL_REG_CHARGES": "Amount Received vs. Outstanding Balance Ratio - All Billing Items (%)",
  "22-RATE-RENTS": "Amount Received vs. Outstanding Balance Ratio - Rent (%)",
  "23-RATE-SERVICE_CHARGES": "Amount Received vs. Outstanding Balance Ratio - Service Charges (%)",
  "24-RATE-OTHER_EXPENSES": "Amount Received vs. Outstanding Balance Ratio - Other Expenses (%)",
  "25-RATE-OTHER_REG_CHARGES": "Amount Received vs. Outstanding Balance Ratio - Other Billing Items (%)",
  "ABATEMENT 1 END DATE": "Rent Abatement Period 1 End Date",
  "ABATEMENT 1 PERIOD": "Rent Abatement Period 1 (months)",
  "ABATEMENT 1 START DATE": "Rent Abatement Period 1 Start Date",
  "ABATEMENT 2 END DATE": "Rent Abatement Period 2 End Date",
  "ABATEMENT 2 PERIOD": "Rent Abatement Period 2 (months)",
  "ABATEMENT 2 START DATE": "Rent Abatement Period 2 Start Date",
  "ABATEMENT 3 END DATE": "Rent Abatement Period 3 End Date",
  "ABATEMENT 3 PERIOD": "Rent Abatement Period 3 (months)",
  "ABATEMENT 3 START DATE": "Rent Abatement Period 3 Start Date",
  "ABATEMENT AMOUNT": "Rent Abatement Period (€)",
  "ABATEMENT AMOUNT 1": "Rent Abatement Period on Year 1 (€)",
  "ABATEMENT AMOUNT 2": "Rent Abatement Period on Year 2 (€)",
  "ABATEMENT AMOUNT 3": "Rent Abatement Period on Year 3 (€)",
  "ABATEMENT AMOUNT LEFT": "Remaining abatement (€)",
  "ABATEMENT AMOUNT TOTAL": "Total abatement (€)",
  "ABATEMENT END DATE": "Rent Abatement Period End Date",
  "ABATEMENT LEFT AMOUNT": "Remaining abatement (€)",
  "ABATEMENT LEFT NB MONTH": "Rent free left month",
  "ABATEMENT MONTHS LEFT": "Remaining abatement duration (months)",
  "ABATEMENT MONTHS TOTAL": "Abatement duration (months)",
  "ABATEMENT NB MONTH": "Rent free nb month",
  "ABATEMENT PERIOD": "Rent Abatement Period 2 (y-m-d)",
  "ABATEMENT START DATE": "Rent Abatement Period Start Date",
  "ABATEMENT TOTAL AMOUNT": "Rent free total amount (€)",
  "ABATEMENT_1_END_DATE": "Rent Abatement Period 1 End Date",
  "ABATEMENT_1_PERIOD": "Rent Abatement Period 1 (months)",
  "ABATEMENT_1_START_DATE": "Rent Abatement Period 1 Start Date",
  "ABATEMENT_2_END_DATE": "Rent Abatement Period 2 End Date",
  "ABATEMENT_2_PERIOD": "Rent Abatement Period 2 (months)",
  "ABATEMENT_2_START_DATE": "Rent Abatement Period 2 Start Date",
  "ABATEMENT_3_END_DATE": "Rent Abatement Period 3 End Date",
  "ABATEMENT_3_PERIOD": "Rent Abatement Period 3 (months)",
  "ABATEMENT_3_START_DATE": "Rent Abatement Period 3 Start Date",
  "ABATEMENT_AMOUNT": "Rent Abatement Period (€)",
  "ABATEMENT_AMOUNT_1": "Rent Abatement Period on Year 1 (€)",
  "ABATEMENT_AMOUNT_2": "Rent Abatement Period on Year 2 (€)",
  "ABATEMENT_AMOUNT_3": "Rent Abatement Period on Year 3 (€)",
  "ABATEMENT_AMOUNT_LEFT": "Remaining abatement (€)",
  "ABATEMENT_AMOUNT_TOTAL": "Total abatement (€)",
  "ABATEMENT_END_DATE": "Rent Abatement End Date",
  "ABATEMENT_LEFT_AMOUNT": "Remaining abatement (€)",
  "ABATEMENT_LEFT_NB_MONTH": "Rent free left month",
  "ABATEMENT_MONTHS_LEFT": "Remaining abatement duration (months)",
  "ABATEMENT_MONTHS_TOTAL": "Abatement duration (months)",
  "ABATEMENT_NB_MONTH": "Rent free nb month",
  "ABATEMENT_START_DATE": "Rent Abatement Start Date",
  "ABATEMENT_TOTAL_AMOUNT": "Rent free total amount (€)",
  "ACCOUNT LABEL": "Account Label",
  "ACCOUNT NUMBER": "Account Number",
  "ACCOUNT NUMBER 1": "Accounting account number",
  "ACCOUNT NUMBER LABEL": "Account Label",
  "ACCOUNT NUMBER LABEL 1": "Accounting account Label",
  "ACCOUNT_LABEL": "Account Label",
  "ACCOUNT_NUMBER": "Account Number",
  "ACCOUNT_NUMBER_1": "Accounting account number",
  "ACCOUNT_NUMBER_LABEL": "Account Label",
  "ACCOUNT_NUMBER_LABEL_1": "Accounting account Label",
  "ACCOUNTING ACCOUNT CUSTOMER": "Account",
  "ACCOUNTING BALANCE END AMOUNT": "Accounting Balance (€) at the Period’s End",
  "ACCOUNTING BALANCE START AMOUNT": "Accounting Balance (€) at the Period’s Beginning",
  "ACCOUNTING DATE": "Accounting Date",
  "ACCOUNTING DATE INVOICE": "Issue Date",
  "ACCOUNTING DATE OUTSTANDING PAYMENTS": "Accounting Date Outstanding Payments",
  "ACCOUNTING DATE PAYMENT": "Payment Date",
  "ACCOUNTING_ACCOUNT_CUSTOMER": "Account",
  "ACCOUNTING_BALANCE_END_AMOUNT": "Accounting Balance (€) at the Period’s End DD/MM/YYYY (€)",
  "ACCOUNTING_BALANCE_START_AMOUNT": "Accounting Balance (€) at the Period’s Beginning DD/MM/YYYY (€)",
  "ACCOUNTING_DATE": "Accounting Date",
  "ACCOUNTING_DATE_INVOICE": "Issue Date",
  "ACCOUNTING_DATE_PAYMENT": "Payment Date",
  "ACCOUNTING_STREAM": "Accounting",
  "ACCOUNTS TYPE": "Accounts Type",
  "ACTIVE_AS_OF_THE_END_DATE": "Active as of the End Date",
  "ACTIVE_AS_OF_THE_REPORT_DATE": "Active as of the Reporting Date",
  "ACTIVE_AS_OF_THE_START_DATE": "Active as of the Start Date",
  "ACTUAL SALE DATE": "Actual Disposal Date",
  "ACTUAL_SALE_DATE": "Actual Disposition Date",
  "ACTUAL_SALE_DATE_mismatch": "Actual Disposal Date mismatch",
  "ADDITIONAL RENT": "Additional Rent (€)",
  "ADDITIONAL RENT END DATE": "Additional Rent End Date",
  "ADDITIONAL RENT INDEXATION MODE": "Additional Rent Indexation Mode",
  "ADDITIONAL RENT PERIOD": "Additional Rent Period (months)",
  "ADDITIONAL RENT START DATE": "Additional Rent Start Date",
  "ADDITIONAL_FIELD": "Data Theme",
  "ADDITIONAL_FIELD_END_DATE": "End date",
  "ADDITIONAL_FIELD_START_DATE": "Start date",
  "ADDITIONAL_FIELD_TYPE": "Data Level",
  "ADDITIONAL_FIELD_VALUE": "Data",
  "ADDITIONAL_RENT": "Additional Rent (€)",
  "ADDITIONAL_RENT_END_DATE": "Additional Rent End Date",
  "ADDITIONAL_RENT_INDEXATION_MODE": "Additional Rent Indexation Mode",
  "ADDITIONAL_RENT_PERIOD": "Additional Rent Period (months)",
  "ADDITIONAL_RENT_START_DATE": "Additional Rent Start Date",
  "ADDRESS": "Address",
  "ADDRESS1": "Address",
  "ADDRESS2": "Other Address",
  "ADJUSTED NET BOOK VALUE": "Adjusted Net Book Value (€)",
  "ADJUSTED_NET_BOOK_VALUE": "Adjusted Net Book Value (€)",
  "ADRESSE_1": "Adresse",
  "ADRESSE_2": "Adresse (suite)",
  "ADVANCE PAYMENTS INC TAX AMNT": "Overpayments (€)",
  "ADVANCE_ON_CURRENT_ACCOUNT": "Advance on Current Account (€)",
  "ADVANCE_PAYMENTS_INC_TAX_AMNT": "Overpayments (€)",
  "AGENCY": "Agency",
  "AGING SCHEDULE": "Aging Schedule",
  "AGING SCHEDULE AS AT": "Aging Schedule as at",
  "ALERT_LEASE_EXPIRING_OTHER_UNIT": "Months",
  "ALERT_LEASE_UNIT": "Month(s)",
  "ALERT_PROPERTY_VALUE_VARIATION_UNIT": "%",
  "ALERT_RENT_VARIATION_UNIT": "%",
  "ALERT_THRESHOLD": "Alert Threshold",
  "ALERT_VACANCY_RATE_UNIT": "%",
  "ALERT_VACANT_UNIT_UNIT": "Month(s)",
  "AlhambraUserGuide": "Alhambra - User Guide.pdf",
  "ALL_AGENCY": "-- All agencies --",
  "ALL_ASSET_MANAGER": "-- All asset managers --",
  "ALL_BUSINESS_UNIT": "-- All business units --",
  "ALL_CLIENTS": "-- All clients --",
  "ALL_EXTERNAL_FUND": "-- All funds --",
  "ALL_FEE_CENTER": "-- All fee centers --",
  "ALL_LEASES": "All Leases",
  "ALL_MANAGER": "-- All property managers --",
  "ALL_PORTFOLIOS": "-- All Portfolios --",
  "ALL_USERS": "-- All Users --",
  "AllAuditAction": "All Actions",
  "ALLOCATION_CODE": "Allocation Code",
  "ALLOCATION_LABEL": "Allocation Label",
  "ALLOWANCE IMPAIR LOSS CALC": "Allowance (+) or Reversal (-) for Impairment Loss (calculated) (€)",
  "ALLOWANCE IMPAIR LOSS USER": "Allowance (+) or Reversal (-) for Impairment Loss (user-defined) (€)",
  "ALLOWANCE_IMPAIR_LOSS_CALC": "Allowance (+) or Reversal (-) for Impairment Loss (calculated) (€)",
  "ALLOWANCE_IMPAIR_LOSS_USER": "Allowance (+) or Reversal (-) for Impairment Loss (user-defined) (€)",
  "AMENDMENT DATE": "Amendment Date",
  "AMENDMENT DESCRIPTION": "Amendment Description",
  "AMENDMENT TYPE": "Amendment Type",
  "AMENDMENT_DATE": "Amendment Date",
  "AMENDMENT_DESCRIPTION": "Amendment Description",
  "AMENDMENT_TYPE": "Amendment Type",
  "AMOUNT": "Amount (€)",
  "AMOUNT OF RECOVERABLE WORKS": "Major Repairs Amount (€)",
  "AMOUNT PERIOD 1": "<= 30 days",
  "AMOUNT PERIOD 2": "31-60 days",
  "AMOUNT PERIOD 3": "61-90 days",
  "AMOUNT PERIOD 4": "91-180 days",
  "AMOUNT PERIOD 5": "180-365 days",
  "AMOUNT PERIOD 6": ">365 days",
  "AMOUNT_OF_RECOVERABLE_WORKS": "Major Repairs Amount (€)",
  "AMOUNT_PERIOD_1": "<= 30 days",
  "AMOUNT_PERIOD_2": "31-60 days",
  "AMOUNT_PERIOD_3": "61-90 days",
  "AMOUNT_PERIOD_4": "91-180 days",
  "AMOUNT_PERIOD_5": "180-365 days",
  "AMOUNT_PERIOD_6": ">365 days",
  "AMOUNTS": "Amounts",
  "ANALYSIS DATE": "Reporting Date:",
  "ANALYSIS_DATE": "Reporting Date",
  "ANNEE_DEBUT": "Année début chantier",
  "ANNEE_DEBUT_PPAT": "Année début PPAT",
  "ANNEE_FIN": "Année fin chantier",
  "ANNEE_FIN_PPAT": "Année fin PPAT",
  "ANNEE_FUTURE": "Année",
  "ANNUAL AMOUNT": "Annual Amount (€/year)",
  "ANNUAL FINANCIAL VACANCY": "Financial Vacancy (€/year)",
  "ANNUAL M2 AMOUNT": "Annual Amount by m² (€/m²/year)",
  "ANNUAL RENT AMOUNT": "Headline Rent (€/year)",
  "ANNUAL RENT AMOUNT M2": "Headline Rent by m² (€/m²/year)",
  "ANNUAL SALES CERT Y MINUS 1": "Certified Annual Sales Volume (€/year)",
  "ANNUAL SALES CERT Y MINUS 2": "Certified Annual Sales Volume (€/year)",
  "ANNUAL_AMOUNT": "Annual Amount",
  "ANNUAL_AMOUNT_PER_UNIT": "Annual Amount Per Unit",
  "ANNUAL_FINANCIAL_VACANCY": "Financial Vacancy (€/year)",
  "ANNUAL_RENT_AMOUNT": "Headline Rent (€/year)",
  "ANNUAL_RENT_AMOUNT_M2": "Headline Rent by m² (€/m²/year)",
  "ANNUAL_RENT_AMOUNT_PER_SQM": "Headline Rent by m² (€/m²/year)",
  "ANNUAL_SALES_CERT_Y_MINUS_1": "Certified Annual Sales Volume YYYY-1 (€/year)",
  "ANNUAL_SALES_CERT_Y_MINUS_2": "Certified Annual Sales Volume YYYY-2 (€/year)",
  "APERIODIC": "Aperiodic",
  "APRIL_AMOUNT": "April (€)",
  "ARBITRAGE": "Manage Disposals",
  "ARBITRAGE SALE PRICE": "Disposal Price (€)",
  "ARBITRAGE TOTAL VALUE": "Property Total Value (€)",
  "ARBITRAGE TYPE": "Disposal Type",
  "ARBITRAGE_SALE_PRICE": "Disposition Price (€)",
  "ARBITRAGE_SALE_PRICE_mismatch": "Disposal Price mismatch",
  "ARBITRAGE_SALE_PRICE_WOU": "Disposal Price",
  "ARBITRAGE_STATUS": "Disposal Status",
  "ARCHIVE AREA": "Archive Area (m²)",
  "ARCHIVE_AREA": "Archive Area (m²)",
  "AREA FOR SALE SQM": "Area for Sale (m²)",
  "AREA UNIT": "Area Unit",
  "AREA_FOR_SALE_SQM": "Area for Sale (m²)",
  "AREA_FOR_SALE_SQM_mismatch": "Area for Sale (m²) mismatch",
  "AREA_FOR_SALE_SQM_required": "Area for Sale (m²) required",
  "AREA_SQM": "Rental Unit Area (m²)",
  "AREA_UNIT": "Area Unit",
  "AREA_UNIT_CODE": "Area Unit Code",
  "AREAS PERCENT": "% of Portfolio Total Rentable Area",
  "AREAS_PERCENT": "% of Portfolio Total Rentable Area",
  "ASSET MANAGER": "Asset Manager",
  "ASSET_MANAGER": "Asset Manager",
  "ASSET_MANAGER_NAME": "Asset Manager",
  "ASSETS AREA": "Total Rentable Area (m²)",
  "ASSETS VALUE": "Net Market Value (€)",
  "ASSETS_AREA": "Total Rentable Area (m²)",
  "ASSETS_UNDER_CONSTRUCTION": "Assets Under Construction",
  "ASSETS_VALUE": "Net Market Value (€)",
  "AUDIT ACTION LABEL": "Action",
  "AUDIT TRAIL": "Audit Trail Report",
  "AUDIT_ACTION_LABEL": "Action",
  "AUGUST_AMOUNT": "August (€)",
  "AVERAGE PHYSICAL VACANCY RATE": "Average Physical Vacancy Rate (%)",
  "AVERAGE VACANCY RATE": "Average Vacancy Rate (%)",
  "AVERAGE_VACANCY_RATE": "Average Vacancy Rate (%)",
  "BALANCE": "Balance",
  "BALANCE BEFORE OVERPAYMENTS": "Outstanding Balance EoP excluding Prepaid Balance (€)",
  "BALANCE DATE": "Cut-off Date",
  "BALANCE END AMNT": "Outstanding Balance EoP including Prepaid Balance (€)",
  "BALANCE END AMOUNT": "Outstanding Balance (€) as at",
  "BALANCE INCL TAX AMOUNT": "Balance (€)",
  "BALANCE START AMNT": "Outstanding Balance BoP (€)",
  "BALANCE START AMOUNT": "Outstanding Balance (€) as at",
  "BALANCE_BEFORE_OVERPAYMENTS": "Outstanding Balance EoP excluding Prepaid Balance (€)",
  "BALANCE_DATE": "Property Balance Date",
  "BALANCE_END_AMNT": "Outstanding Balance EoP (€)",
  "BALANCE_END_AMOUNT": "Outstanding Balance as at DD/MM/YYYY (€)",
  "BALANCE_INCL_TAX_AMOUNT": "Balance (€)",
  "BALANCE_OUT_END_AMOUNT": "Outstanding Balance as at DD/MM/YYYY including Prepaid Balance (€)",
  "BALANCE_OUT_START_AMOUNT": "Outstanding Balance as at DD/MM/YYYY (€)",
  "BALANCE_START_AMNT": "Outstanding Balance BoP (€)",
  "BALANCE_START_AMOUNT": "Outstanding Balance as at DD/MM/YYYY (€)",
  "BANK GUARANTEE AMOUNT": "Bank Guarantee (€)",
  "BANK GUARANTEE END DATE": "Guarantee Expiry",
  "BANK GUARANTEE START DATE": "Guarantee Start Date",
  "BANK JOURNAL": "Paid by Bank Journal",
  "BANK_GUARANTEE_AMOUNT": "Bank Guarantee (€)",
  "BANK_GUARANTEE_END_DATE": "Guarantee Expiry",
  "BANK_GUARANTEE_START_DATE": "Guarantee Start Date",
  "BANK_JOURNAL": "Paid by Bank Journal",
  "BASE RENT": "Base Rent (€/year)",
  "BASE_RENT": "Base Rent (€/year)",
  "BATIMENT_CODE_DESTINATAIRE": "Batiment code emetteur",
  "BATIMENT_CODE_EMETTEUR": "Code bâtiment",
  "BEFORE PAYMENTS INC TAX AMNT": "Received from Previous Invoicing Periods (€)",
  "BEFORE PAYMENTS INC TAX AMOUNT": "Received from Previous Invoicing Periods (€)",
  "BEFORE TAX AMOUNT": "Invoice amount before taxes",
  "BEFORE TAX AMOUNT TOTAL": "Amount before tax",
  "BEFORE TAX AMOUNT TOTAL 1": "Amount before tax",
  "BEFORE_PAYMENTS_INC_TAX_AMNT": "Received from Previous Invoicing Periods (€)",
  "BEFORE_PAYMENTS_INC_TAX_AMOUNT": "Received from Previous Invoicing Periods (€)",
  "BEFORE_TAX_AMOUNT": "Invoice amount before taxes",
  "BEFORE_TAX_AMOUNT_TOTAL": "Amount before tax",
  "BEFORE_TAX_AMOUNT_TOTAL_1": "Amount before tax",
  "bfColumnType": "Column type",
  "BILLING_ITEMS": "Billing items",
  "BOR": "Border",
  "BREAK_OPTION": "Break Option",
  "BREAKPOINT AMOUNT 1": "Breakpoint Amount 1 (€/year)",
  "BREAKPOINT AMOUNT 2": "Breakpoint Amount 2 (€/year)",
  "BREAKPOINT RATE 1": "Breakpoint Rate 1",
  "BREAKPOINT RATE 2": "Breakpoint Rate 2",
  "BREAKPOINT_AMOUNT_1": "Breakpoint Amount 1 (€/year)",
  "BREAKPOINT_AMOUNT_2": "Breakpoint Amount 2 (€/year)",
  "BREAKPOINT_RATE_1": "Breakpoint Rate 1",
  "BREAKPOINT_RATE_2": "Breakpoint Rate 2",
  "btnAdditionalFieldDownload": "Download the import template",
  "btnBackAccount": "Back to My Account",
  "btnBackSearch": "Back to search results",
  "btnBrowse": "Browse",
  "btnBuildingPurchasing": "Pending Acquisitions",
  "btnCancel": "Cancel",
  "btnCancelModalPopup": "Close",
  "btnClearCache": "Clear Caches",
  "btnClose": "Close",
  "btnCreate": "Create",
  "btnDelete": "Delete",
  "btnDeletePreviousFundUpdate": "Delete the Selected Update",
  "btnDeletePurchase": "Delete Acquisition",
  "btnDeleteSale": "Delete Disposal",
  "btnDeleteTranscodingType": "Delete the type of data",
  "btnDeleteTranscodingTypeConfirmation": "Are you sure you want to to delete this mapping?",
  "btnDeleteTranscodingTypeConfirmationResult": "The mapping is deleted.",
  "btnDownloadDoc": "User Guide",
  "btnErvDownload": "Download ERV Template File",
  "btnExport": "Export",
  "btnFileTypeNotAllowed": "File type not allowed",
  "btnFundDates": "Critical Dates",
  "btnFundOwnershipDownload": "Download the Investor Data import file",
  "btnGeneralInformation": "General Information",
  "btnGenerate": "Generate the file",
  "btnGenerateFile": "Generate the file",
  "btnImport": "Import",
  "btnImportDownload": "Download the import template",
  "btnInsert": "Insert",
  "btnLaunch": "Launch interface",
  "btnLaunchInterface": "Launch interface",
  "btnLaunchReport": "Launch Report",
  "btnLeaseRepositoryDownload": "Download Leases Template File",
  "btnNetBookValueDownload": "Upload Net book Values",
  "btnNewBuilding": "New Property",
  "btnNewInterfaceLowCode": "New interface low code",
  "btnNewInterfaceTs": "New Tenancy Schedule Stream",
  "btnNewPurchase": "New Acquisition",
  "btnNewUser": "New User",
  "btnNext": "Next",
  "btnNormalReload": "Complete Reload",
  "btnOwnership": "Ownership",
  "btnPartialReload": "Partial Reload",
  "btnPurchasingHistory": "Historical Acquisitions",
  "btnRedirect": "Back to Login Page",
  "btnRepositoryBuildingExport": "Property Repository Export",
  "btnRepositoryFundExport": "Fund Repository Export",
  "btnReset": "Reset Parameters",
  "btnReturnListInterface": "Back to the list of interfaces",
  "btnSalesHistory": "Completed Disposals",
  "btnSave": "Save",
  "btnSearch1": "Research",
  "btnSelectColumns": "Columns",
  "btnSelectLogo": "Personalization",
  "btnSelectReportLogo": "Change the logo",
  "btnSendGED": "Check my invoices",
  "btnSubmit": "Submit",
  "btnTotalReload": "Total Reload",
  "btnUnlock": "Unlock",
  "btnUpdate": "Update",
  "btnUpdateLocation": "Generate Geocoordinates",
  "btnUpdatePurchase": "Update Acquisition",
  "btnUpload": "Import the file",
  "btnValidatePdd": "Validate Impairment Losses",
  "btnValidatePurchase": "Validate Acquisition",
  "btnValidateSale": "Validate Disposal",
  "btnValuationDownload": "Upload Valuation Data",
  "btnYieldDownload": "Upload Target Yields",
  "btSelectBuildingImage": "Change Picture",
  "btStreetView": "Street View",
  "BU MANAGER": "BU Manager",
  "BU_MANAGER": "BU Manager",
  "BUDGET 01 N": "January Budget",
  "BUDGET 02 N": "February Budget",
  "BUDGET 03 N": "March Budget",
  "BUDGET 04 N": "April Budget",
  "BUDGET 05 N": "May Budget",
  "BUDGET 06 N": "June Budget",
  "BUDGET 07 N": "July Budget",
  "BUDGET 08 N": "August Budget",
  "BUDGET 09 N": "September Budget",
  "BUDGET 10 N": "October Budget",
  "BUDGET 11 N": "November Budget",
  "BUDGET 12 N": "December Budget",
  "BUDGET AMOUNT BT": "Budget (€/year)",
  "BUDGET BEGIN DATE": "Budget Start Date",
  "BUDGET CODE": "Budget Code",
  "BUDGET END DATE": "Budget End Date",
  "BUDGET INITIAL N": "Initial budget",
  "BUDGET INITIAL N M1": "Initial budget",
  "BUDGET INITIAL N P1": "Initial budget",
  "BUDGET LINE": "Customer account number",
  "BUDGET LINE 1": "Budget line label",
  "BUDGET LINE CODE": "Budget line code",
  "BUDGET MONITORING": "Budget Monitoring Report",
  "BUDGET MONITORING AS AT": "Budget Monitoring Report as at",
  "BUDGET NAME": "Budget Label",
  "BUDGET T1 N": "Budget Q1",
  "BUDGET T2 N": "Budget Q2",
  "BUDGET T3 N": "Budget Q3",
  "BUDGET T4 N": "Budget Q4",
  "BUDGET TODATE N": "Budget as of",
  "BUDGET TYPE": "Budget Type",
  "BUDGET YEAR": "Budget Year",
  "BUDGET_01_N": "January Budget YYYY",
  "BUDGET_02_N": "February Budget YYYY",
  "BUDGET_03_N": "March Budget YYYY",
  "BUDGET_04_N": "April Budget YYYY",
  "BUDGET_05_N": "May Budget YYYY",
  "BUDGET_06_N": "June Budget YYYY",
  "BUDGET_07_N": "July Budget YYYY",
  "BUDGET_08_N": "August Budget YYYY",
  "BUDGET_09_N": "September Budget YYYY",
  "BUDGET_10_N": "October Budget YYYY",
  "BUDGET_11_N": "November Budget YYYY",
  "BUDGET_12_N": "December Budget YYYY",
  "BUDGET_BEGIN_DATE": "Budget Start Date",
  "BUDGET_CODE": "Budget Code",
  "BUDGET_END_DATE": "Budget End Date",
  "BUDGET_INITIAL_N": "Initial budget YYYY",
  "BUDGET_INITIAL_N_M1": "Initial budget YYYY-1",
  "BUDGET_INITIAL_N_P1": "Initial budget YYYY+1",
  "BUDGET_LINE": "Customer account number",
  "BUDGET_LINE_1": "Budget line label",
  "BUDGET_LINE_CODE": "Budget line code",
  "BUDGET_NAME": "Budget Label",
  "BUDGET_T1_N": "Budget Q1 YYYY",
  "BUDGET_T2_N": "Budget Q2 YYYY",
  "BUDGET_T3_N": "Budget Q3 YYYY",
  "BUDGET_T4_N": "Budget Q4 YYYY",
  "BUDGET_TODATE_N": "Budget as of DDMMYYYY",
  "BUDGET_TYPE": "Budget Type",
  "BUDGET_YEAR": "Budget Year",
  "BUDGETED N": "Budget (€/year)",
  "BUDGETED_N": "Budget YYYY (€/year)",
  "BUILDING": "Building",
  "BUILDING ADDRESS": "Address",
  "BUILDING CITY": "Location",
  "BUILDING CODE": "Property Code",
  "BUILDING COUNTRY": "Country",
  "BUILDING DELIVERY DATE": "Delivery Date",
  "BUILDING DESCRIPTION": "Property Overview",
  "BUILDING MAIN USE": "Property Type",
  "BUILDING NAME": "Property Name",
  "BUILDING NATURE": "Property Type",
  "BUILDING NATURE LABEL": "Property Type",
  "BUILDING NATURE LABEL TOTAL": "Total",
  "BUILDING NATURE LABEL TOTAL1": "Total",
  "BUILDING NUMBER": "Building Number",
  "BUILDING OTHER NATURES": "Other Property Types",
  "BUILDING OTHER USES": "Secondary Use(s)",
  "BUILDING POSTAL CODE": "Postal Code",
  "BUILDING PRICE": "Property Price (€)",
  "BUILDING PURCHASE DATE": "Date Purchased",
  "BUILDING SUMMARY": "Property Summary",
  "BUILDING SURFACE": "Building surface",
  "BUILDING TENANCY SCHEDULE": "Tenancy Schedule by Property as at",
  "BUILDING TENANCY SCHEDULE SHEET": "Tenancy Schedule by Property",
  "BUILDING TOTAL": "Total",
  "BUILDING TOTAL AREA": "Total Rentable Area (m²)",
  "BUILDING TOTAL AREA SQM": "Building surface (m²)",
  "BUILDING TOTAL AREA SQM AT DATE": "Total Rentable Area (m²) as at",
  "BUILDING TOTAL VALUE": "Net Market Value (€)",
  "BUILDING TYPE LABEL": "Property Class",
  "BUILDING_ACCUMULATED_DEPRECIATION": "Building Accumulated Depreciation",
  "BUILDING_ADDRESS": "Address",
  "BUILDING_ADDRESS1": "Address",
  "BUILDING_ADDRESS1_required": "Address required",
  "BUILDING_ADDRESS2": "Other Address",
  "BUILDING_CITY": "Location",
  "BUILDING_CITY_required": "Property Location required",
  "BUILDING_CODE": "Property Code",
  "BUILDING_CODE_required": "Property Code required",
  "BUILDING_COUNTRY": "Country",
  "BUILDING_COUNTRY_required": "Country required",
  "BUILDING_DELIVERY_DATE": "Delivery Date",
  "BUILDING_LATITUDE": "Latitude",
  "BUILDING_LONGITUDE": "Longitude",
  "BUILDING_NAME": "Property Name",
  "BUILDING_NAME_required": "Property Name required",
  "BUILDING_NATURE": "Property Type",
  "BUILDING_NATURE_CODE": "Property Type Code",
  "BUILDING_NATURE_LABEL": "Property Type",
  "BUILDING_POSTAL_CODE": "Postal Code",
  "BUILDING_POSTAL_CODE_required": "Postal Code required",
  "BUILDING_PRICE": "Property Price (€)",
  "BUILDING_PRICE_mismatch": "Property Price mismatch",
  "BUILDING_PRICE_WOU": "Property Price",
  "BUILDING_PURCHASE_DATE": "Date Purchased",
  "BUILDING_SEARCH": "Find a Property to be disposed",
  "BUILDING_SURFACE": "Building surface",
  "BUILDING_TOTAL_AREA": "Total Rentable Area (m²)",
  "BUILDING_TOTAL_AREA_SQM": "Building surface (m²)",
  "BUILDING_TOTAL_AREA_SQM_AT_DATE": "Total Rentable Area (m²) as at DD/MM/YYYY",
  "BUILDING_TOTAL_AREA_SQM_mismatch": "Total Rentable Area mismatch",
  "BUILDING_TOTAL_AREA_SQM_WOU": "Total Rentable Area",
  "BUILDING_TOTAL_VALUE": "Net Market Value (€)",
  "BUILDING_TOTAL_VALUE_mismatch": "Property Total Value mismatch",
  "BUILDING_TOTAL_VALUE_required": "Property Total Value required",
  "BUILDING_TOTAL_VALUE_SQM": "Acquisition Value by m² (€/m²)",
  "BUILDING_TOTAL_VALUE_WOU": "Property Total Value",
  "BUILDING_TYPE_CODE": "Property Class Code",
  "BUILDING_TYPE_LABEL": "Property Class",
  "BUILDING_UNIQUE_CODE": "Property Code",
  "BUILDING_VALUE": "Building Value",
  "BUILDING_VALUE_VARIATION_RATE": "Capital Growth",
  "BUILDINGS COUNT": "Property Count",
  "BUILDINGS PERCENT": "% of Total Property Count",
  "BUILDINGS_COUNT": "Number of Properties",
  "BUILDINGS_PERCENT": "% of Total Property Number",
  "BUSINESS_UNITS": "Business Units",
  "BUYER NAME": "Acquirer Name",
  "BUYER_NAME": "Acquirer Name",
  "BVARIATION RATE 01 N": "Variation rate 01/",
  "BVARIATION_RATE_01_N": "Variation rate 01/YYYY",
  "BY BUILDING NATURE": "By Property Type",
  "BY BUILDING NATURE SUMED": "By Property Type Running Total",
  "BY FUND NAME": "By Fund Entity Name",
  "BY FUND NAME SUMED": "By Fund Entity Name Running Total",
  "BY INVOICE": "By Invoice",
  "BY INVOICE SUMED": "By Invoice Running Total",
  "BY INVOICING ITEM": "By Invoice Line",
  "BY INVOICING ITEM SUMED": "By Invoice Line Running Total",
  "BY LEASE": "By Lease",
  "BY LEASE NATURE": "By Lease Type",
  "BY LEASE SUMED": "By Lease Running Total",
  "BY OWNER": "By Owner",
  "BY OWNER SUMED": "By Owner Running Total",
  "BY PORTFOLIO": "By Portfolio",
  "BY PORTFOLIO SUMED": "By Portfolio Running Total",
  "BY PROPERTY": "By Property",
  "BY PROPERTY SUMED": "By Property Running Total",
  "BY YEAR": "By Year",
  "CANCELLED_AT": "Cancelled at",
  "CAPITAL": "Capital / Issued Share Capital (€)",
  "CAPITAL CALLS": "Capital Calls (€)",
  "CAPITAL EXPENSES": "Capital Expenses (€/year)",
  "CAPITAL GAIN REINVESTMENT": "Capital Gain Reinvestment (€)",
  "CAPITAL GROWTH": "Capital Growth (€)",
  "CAPITAL GROWTH BREAKDOWN": "Capital Growth Breakdown by Property",
  "CAPITAL GROWTH RATE": "Capital Growth (%)",
  "CAPITAL RECEIPTS": "Capital Receipts (€/year)",
  "CAPITAL RETURN": "Capital Return",
  "CAPITAL RETURN FUND": "Capital Return",
  "CAPITAL STRUCTURE": "PnL + Capital Structure",
  "CAPITAL_CALLS": "Capital Calls (€)",
  "CAPITAL_EXPENSES": "Capital Expenses YYYY-1 (€/year)",
  "CAPITAL_GAIN_REINVESTMENT": "Capital Gain Reinvestment (€)",
  "CAPITAL_GROWTH": "Capital Growth (€)",
  "CAPITAL_GROWTH_RATE": "Capital Growth (%)",
  "CAPITAL_RECEIPTS": "Capital Receipts YYYY-1 (€/year)",
  "CAPITAL_RETURN": "Capital Return",
  "CASH FLOW": "Cash Flow",
  "CASH FLOW BREAKDOWN": "Opreating Cash Flow Breakdown",
  "CASH ON CASH": "Cash-on-Cash",
  "CASH ON CASH RETURN": "Cash-on-Cash Return",
  "CASH_FLOW": "Cash Flow (€)",
  "CATCH UP AMOUNT": "Catch-up Amount (€)",
  "CATCH UP END DATE": "Catch-up End Date",
  "CATCH UP PERIOD": "Catch-up Period (months)",
  "CATCH UP START DATE": "Catch-up Start Date",
  "CATCH_UP_AMOUNT": "Catch-up Amount (€)",
  "CATCH_UP_END_DATE": "Catch-up End Date",
  "CATCH_UP_PERIOD": "Catch-up Period (months)",
  "CATCH_UP_START_DATE": "Catch-up Start Date",
  "cbActivation": "Activation interface",
  "cbErvDownload": "Autopopulate file with the data at the latest value date",
  "cbFundOwnershipDownload": "Autopopulate file with the data at the latest update date",
  "cbLeaseRepositoryDownload": "Download Leases Data Import File",
  "cbNetBookValueDownload": "Autopopulate the import template with the data at the latest cut-off date",
  "cbValuationDownload": "Autopopulate the import template with the data at the latest valuation date",
  "cbYieldDownload": "Autopopulate the import template with the data at the latest valuation date",
  "CHANTIER_CODE_DESTINATAIRE": "Chantier code destinatire",
  "CHANTIER_CODE_EMETTEUR": "Chantier code emetteur",
  "CHANTIER_EMETTEUR_PREALABLE": "Chantier emetteur préalable",
  "CHARTS": "Charts",
  "CHARTS BY AREA": "Charts By Area",
  "CHARTS BY BUILDING NATURE": "Charts by Property Type",
  "CHARTS BY LEASE": "Charts By Lease",
  "CHARTS BY PROPERTY TYPE": "Charts by Property Class",
  "CHARTS BY UNIT": "Charts By Rental Unit",
  "CHRONO GED": "(en) Chrono",
  "CHRONO_GED": "(en) Chrono",
  "CITY": "Location",
  "CLASSIFICATION_ELLIGIBLE_PINEL": "Classification élligible PINEL",
  "CLASSIFICATION_ICMS_EQUIPEMENT": "Code équipement ICMS",
  "CLEAR_FILTERS": "Clear filters",
  "CLIENT ACCOUNT NUMBER": "Customer account number",
  "CLIENT ACCOUNT NUMBER LABEL": "Customer account name",
  "CLIENT_ACCOUNT_NUMBER": "Customer account number",
  "CLIENT_ACCOUNT_NUMBER_LABEL": "Customer account name",
  "CODE_EQUIPEMENT_CLASSIFICATION_SYPEMI": "Code équipement SYPEMI",
  "CODE_POSTAL": "Code postal",
  "COLLECTION MONITORING": "Rental Income Collection Report",
  "COLLECTION MONITORING BREAKDOWN": "Breakdown of Rental Income by Collection Period",
  "COLLECTION MONITORING BREAKDOWN BY BUILDING": "Breakdown of Rental Income by Collection Period and by Property",
  "COLLECTION MONITORING BREAKDOWN BY PORTFOLIO": "Breakdown of Rental Income by Collection Period and by Portfolio",
  "COLLECTION MONITORING SUM": "Running Total of Rental Income by Collection Period",
  "COLLECTION RATE": "Collection Rate",
  "COLLECTION_RATE": "Collection Rate",
  "COLOR_BACK": "Background color",
  "COLOR_FONT": "Font color",
  "COLUMN NAME": "Column Name",
  "COLUMN_NAME": "Column Name",
  "COMMENT": "Comments",
  "COMMENT N": "Comment",
  "COMMENT N M1": "Comment",
  "COMMENT N P1": "Comment",
  "COMMENT_N": "Comment YYYY",
  "COMMENT_N_M1": "Comment YYYY-1",
  "COMMENT_N_P1": "Comment YYYY+1",
  "COMMENTAIRE": "Commentaire",
  "COMMENTAIRE_VALIDATION": "Commentaire validation",
  "COMMENTS": "Comments",
  "COMMERCIAL LEASE SCHEDULE": "Commercial Lease Schedule",
  "COMMITMENT": "Commitment (€)",
  "COMMITMENT AGREEMENT DATE": "Commitment Agreement Date",
  "COMMITMENT_AGREEMENT_DATE": "Commitment Agreement Date",
  "COMMITMENT_AGREEMENT_DATE_mismatch": "Commitment Agreement Date mismatch",
  "COMMON AREA SHARE": "Common Area Share (m²)",
  "COMMON_AREA_SHARE": "Common Area Share (m²)",
  "COMPANY REGISTRATION NUMBER": "Company Registration Number",
  "COMPANY_REGISTRATION_NUMBER": "Company Registration Number",
  "Compilation Failed": "Compilation failed",
  "CONDOMINIUM UNIT CODE": "Condominium Unit Code",
  "CONDOMINIUM_UNIT_CODE": "Condominium Unit Code",
  "Conflict": "Conflict",
  "CONTACT_FIRSTNAME": "First name",
  "CONTACT_FUNCTION": "Function",
  "CONTACT_NAME": "Name",
  "CONTACT_SERVICE": "Service",
  "CONTRACT COMMAND CODE": "Contrat or Order",
  "CONTRACT COMMAND NAME": "Contrat or Order Label",
  "CONTRACT TYPE CODE": "Contract Type",
  "CONTRACT_COMMAND_CODE": "Contrat or Order",
  "CONTRACT_COMMAND_NAME": "Contrat or Order Label",
  "CONTRACT_TYPE_CODE": "Contract Type",
  "CONTRIBUTION TO PORTFOLIO TOTAL RETURN": "Contribution to Portfolio Total Return",
  "CONTROL DATE": "Date Modified",
  "CONTROL_DATE": "Date Modified",
  "COST PRICE AMOUNT": "Cost Price Amount (€)",
  "COST_PRICE_AMOUNT": "Cost Price Amount (€)",
  "COST_PRICE_AMOUNT_mismatch": "Cost Price Amount mismatch",
  "COST_PRICE_AMOUNT_WOU": "Cost Price Amount",
  "COUNTRY": "Country",
  "Creation": "Creation",
  "CREATION DATE": "Creation date",
  "CREDIT": "Credit",
  "CURRENCY": "Currency",
  "CURRENCY PER SQM PER YEAR": "€/m²/y",
  "CURRENCY PER YEAR": "€/y",
  "CURRENT ANNUAL RENT AMOUNT": "Headline Rent (€/year)",
  "CURRENT EQUIPMENT GUARANTEE": "Current equipment guarantee (€)",
  "CURRENT GUARANTEE 1": "Current guarantee 1 (€)",
  "CURRENT GUARANTEE 2": "Current guarantee 2 (€)",
  "CURRENT GUARANTEE 3": "Current guarantee 3 (€)",
  "CURRENT INDEX DATE": "Current Index Date",
  "CURRENT INDEX TYPE LABEL": "Index Current Type",
  "CURRENT INDEX VALUE": "Current Index Value",
  "CURRENT LEASE SUMMARY": "Current Lease Summary",
  "CURRENT MARKET VALUE": "Estimated Rental Value (€/m²/year)",
  "CURRENT MONTHLY RENT AMOUNT": "Monthly Headline Rent (€/month)",
  "CURRENT REAL ESTATE DISPOSITION": "Pending Disposal Report as of",
  "CURRENT REAL ESTATE PURCHASE": "Pending Acquisition Report as of",
  "CURRENT RENT AMOUNT PER SQM": "Headline Rent by m² (€/m²/year)",
  "CURRENT WORKING CAPITAL": "Current working capital (€)",
  "CURRENT_ANNUAL_RENT_AMOUNT": "Headline Rent (€/year)",
  "CURRENT_EQUIPMENT_GUARANTEE": "Current equipment guarantee (€)",
  "CURRENT_GUARANTEE_1": "Current guarantee 1 (€)",
  "CURRENT_GUARANTEE_2": "Current guarantee 2 (€)",
  "CURRENT_GUARANTEE_3": "Current guarantee 3 (€)",
  "CURRENT_INDEX_DATE": "Current Index Date",
  "CURRENT_INDEX_TYPE_LABEL": "Current Index Type",
  "CURRENT_INDEX_VALUE": "Current Index Value",
  "CURRENT_MARKET_VALUE": "Estimated Rental Value (€/m²/year)",
  "CURRENT_MONTHLY_RENT_AMOUNT": "Monthly Headline Rent (€/month)",
  "CURRENT_RENT_AMOUNT_PER_SQM": "Headline Rent by m² (€/m²/year)",
  "CURRENT_WORKING_CAPITAL": "Current working capital (€)",
  "CUSTOMER_GROUP_CODE": "Portoflio Code",
  "CUSTOMER_GROUP_NAME": "Portoflio Name",
  "CUT_OFF_DATE": "Cut-off Date",
  "DATE OF CONSTRUCTION": "Year Built",
  "DATE OF DATA": "Property Fact Sheet as at",
  "DATE OF PURCHASE": "Date Purchased",
  "DATE_DEBUT_TRAVAUX_LIEE_AU_STATUT": "Date début travaux",
  "DATE_ENVOI": "Date d'import",
  "DATE_RECEPTION_TRAVAUX_LIEE_AU_STATUT": "Date réception travaux",
  "DateFormat": "dd-MM-yyyy",
  "DE_PORTFOLIO_LABEL": "Portfolio Name (German)",
  "DE_SECTOR_LABEL": "Market Geography (German)",
  "DE1": "Cell",
  "DEBIT": "Debit",
  "DECEMBER_AMOUNT": "December (€)",
  "Deletion": "Deletion",
  "DELIVERY ACTUAL DATE": "Delivery Actual Date",
  "DELIVERY ESTIMATED DATE": "Delivery Estimated Date",
  "DELIVERY_ACTUAL_DATE": "Delivery Actual Date",
  "DELIVERY_ACTUAL_DATE_mismatch": "Delivery Actual Date mismatch",
  "DELIVERY_ESTIMATED_DATE": "Delivery Estimated Date",
  "DELIVERY_ESTIMATED_DATE_mismatch": "Delivery Estimated Date mismatch",
  "DEPRECIATION": "Depreciation (€)",
  "DEPRECIATION PROVISION AMOUNT": "Impairment Charge recorded in prior periods (€)",
  "DEPRECIATION_PROVISION_AMOUNT": "Impairment Charge recorded in prior periods (€)",
  "DESCRIPTIF_DE_LA_NATURE_DU_CHANTIER": "Descriptif de la Nature du chantier",
  "DESCRIPTION_DE_LA_ZONE_DU_CHANTIER": "Description de la Zone du chantier",
  "DESTINATAIRE": "Destinataire",
  "DESTINATION_CODE": "Destination Code",
  "DESTINATION_LABEL": "Destination Label",
  "DETAILLED AGING SCHEDULE": "Detailed Aging Schedule",
  "DETENTION STRUCTURE": "Investment Structure",
  "DIFF BUDGET N REAL N MINUS 1": "Budget vs. Actual (€/year)",
  "DIFF BUDGETED N REALISED N": "Budget vs. Actual (€/year)",
  "DIFF N M1": "Difference Realised / Accounting",
  "DIFF N M1 01": "Difference Realised",
  "DIFF N M1 02": "/Accounting",
  "DIFF REALISED N BUDGETED N": "Budget vs. Actual (€/year)",
  "DIFF REALISED N N MINUS 1": "Actual vs. Actual (€/year)",
  "DIFF_BUDGET_N_REAL_N_MINUS_1": "Budget YYYY vs. Actual YYYY-1 (€/year)",
  "DIFF_BUDGETED_N_REALISED_N": "Budget YYYY vs. Actual YYYY (€/year)",
  "DIFF_N_M1": "Difference realised YYYY-1 / Accounting YYYY-1",
  "DIFF_REALISED_N_BUDGETED_N": "Available Budget (€/year)",
  "DIFF_REALISED_N_N_MINUS_1": "Actual YYYY vs. Actual YYYY-1 (€/year)",
  "DISPLAY NAME": "Description",
  "DISPLAY_NAME": "Description",
  "DISPLAY_THE_LEASE_THAT_ARE": "Display the leases that are…",
  "DISPLAY_THE_PROP_THAT_ARE": "Display the properties that are…",
  "DISPOSITION HISTORY €": "Historical Disposals by Property Type and Net Market Value",
  "DISPOSITION HISTORY M2": "Historical Disposals by Property Type and Rental Area",
  "DISPOSITION HISTORY RECORD": "Disposal History Record",
  "DISPOSITION VALUE": "Disposal Value (€)",
  "DISPOSITION VALUE SQM": "Disposal Value by m² (€/m²)",
  "DISTRIBUTION VALORIZATION": "Distribution + Performance",
  "DIVIDEND": "Final Dividends (€)",
  "DIVIDEND ADVANCE": "Interim Dividends (€)",
  "DIVIDEND PER SHARE": "Dividend per Share",
  "DIVIDEND PROFIT DISTRIBUTIONS": "Dividend/Profit Distributions (€)",
  "DIVIDEND_ADVANCE": "Interim Dividends (€)",
  "DIVIDEND_PER_SHARE": "Dividend per Share",
  "DOCUMENT NUMBER": "Invoice Number",
  "DOCUMENT_DATE": "Document Date",
  "DOCUMENT_LINE_NUMBER": "Document line number",
  "DOCUMENT_NUMBER": "Invoice Number",
  "DOCUMENT_TYPE_LABEL": "Document Type",
  "DOOR NUMBER": "Door Number",
  "DOOR_NUMBER": "Door Number",
  "DOTATION PDDI": "Depreciation Charge (€)",
  "DOTATION_PDDI": "Depreciation Charge (€)",
  "DOUBLE NET RENT": "Net Net Rent (€)",
  "DOUBLE NET RENT WOU": "Net Net Rent",
  "DUE DATE": "Due Date",
  "DUE INVOICES BALANCE END AMOUNT": "Final balance of due invoices (€) as at",
  "DUE INVOICES BALANCE START AMOUNT": "Initial balance of due invoices (€) as at",
  "DUE OUTSTAND PAY INC TAX AMOUNT": "Outstanding payments (€)",
  "DUE_DATE": "Due Date",
  "DUE_INVOICES_BALANCE_END_AMOUNT": "Final balance of due invoices (€) as at DD/MM/YYYY",
  "DUE_INVOICES_BALANCE_START_AMOUNT": "Initial balance of due invoices (€) as at DD/MM/YYYY",
  "DUE_OUTSTAND_PAY_INC_TAX_AMOUNT": "Outstanding payments (€)",
  "DURATION": "Remaining Lease Term (y-m-d)",
  "DURATION TO BREAK DATE": "Remaining Lease Term (y-m-d)",
  "DURATION TO LEASE END": "Remaining Lease Term to Expiry (y-m-d)",
  "DURATION_TO_BREAK_DATE": "Remaining Lease Term (y-m-d)",
  "DURATION_TO_LEASE_END": "Remaining Lease Term to Expiry (y-m-d)",
  "EARNINGS BEFORE INTEREST": "Earnings Before Interest and Taxes / Operating Income (€)",
  "EARNINGS_BEFORE_INTEREST": "Earnings Before Interest and Taxes / Operating Income (€)",
  "EBIT": "Net Operating Income (NOI)",
  "ECONOMIC RENT": "Economic Rent (€/year)",
  "ECONOMIC RENT BY HEADLINE RENT": "Economic Rent / Headline Rent (%)",
  "ECONOMIC RENT BY SQM": "Economic Rent by m² (€/m²/year)",
  "ECONOMIC_RENT": "Economic Rent (€/year)",
  "ECONOMIC_RENT_BY_HEADLINE_RENT": "Economic Rent / Headline Rent (%)",
  "ECONOMIC_RENT_PER_SQM": "Economic Rent by m² (€/m²/year)",
  "EDITION TIME": "Edition time:",
  "EFFECTIVE GROSS CAPITAL RATE": "Effective Gross Cap Rate",
  "EFFECTIVE GROSS INITIAL YIELD": "Effective Gross Initial Yield",
  "EFFECTIVE NET CAPITAL RATE": "Effective Net Cap Rate",
  "EFFECTIVE NET INITIAL YIELD": "Effective Net Initial Yield",
  "EFFECTIVE RENT": "Effective Rent (€/year)",
  "EFFECTIVE_GROSS_CAPITAL_RATE": "Effective Gross Cap Rate",
  "EFFECTIVE_GROSS_INITIAL_YIELD": "Effective Gross Initial Yield",
  "EFFECTIVE_NET_CAPITAL_RATE": "Effective Net Cap Rate",
  "EFFECTIVE_NET_INITIAL_YIELD": "Effective Net Initial Yield",
  "EFFECTIVE_RENT": "Effective Rent (€/year)",
  "emailGreetingsToUser": "Dear user,",
  "emailLinkCodeExpiration": "The link and the temporary code will be available for 15 mn max. After that, you will have to renew your request",
  "emailLinkExpiration": "The link will be available for 15 mn max. After that, you will have to renew your request",
  "emailPwdRecoveryIntro": "You have requested a new password on Alhambra's Website",
  "emailPwdRecoveryLinkClick": "Please click on the following link to reset your password.",
  "emailPwdRecoveryObject": "Alhambra - Password Recovery",
  "emailPwdRecoveryProvideSMSCode": "and provide the following SMSCode",
  "emailPwdRecoveryProvideTempPwd": "and provide the following temporary code",
  "emailRegards": "Kind regards,",
  "emailWebAlhambraTeamSignature": "Alhambra's Team.",
  "EMETTEUR": "Emetteur",
  "EN_PORTFOLIO_LABEL": "Portfolio Name (English)",
  "EN_SECTOR_LABEL": "Market Geography (English)",
  "END MANAGEMENT DATE": "Date Managed to",
  "END_DATE": "End Date",
  "END_MANAGEMENT_DATE": "Date Managed to",
  "END_MANAGEMENT_DATE_mismatch": "Date Managed to mismatch",
  "END_OF_LEASE": "Lease Expiry",
  "ENTERPRISE ABATEMENT": "Rent-free Period (€)",
  "ENTERPRISE ANNUAL AMOUNT": "Annual Amount (€/year)",
  "ENTERPRISE GROSS HEADLINE RENT": "Headline Rent (€/year)",
  "ENTERPRISE GROSS HEADLINE RENT M2": "Headline Rent by m² (€/m²/year)",
  "ENTERPRISE MONTHLY AMOUNT": "Monthly Amount (€/month)",
  "ENTERPRISE REAL ESTATE": "Commercial Real Estate",
  "ENTERPRISE RENTED AREA": "Occupied Area (m²)",
  "ENTERPRISE TOTAL AREA": "Total Rentable Area (m²)",
  "ENTERPRISE VACANT AREA": "Vacant Area (m²)",
  "ENTRANCE FEES": "Entrance Fees (€)",
  "ENTRANCE_FEES": "Entrance Fees (€)",
  "ENTREPRISE MONTHLY M2 AMOUNT": "Monthly Amount by m² (€/m²/month)",
  "ENTRY": "Reference Number",
  "ENTRY_AMOUNT": "Entry Amount",
  "ENTRY_DIRECTION_CODE": "Entry Direction code",
  "ENTRY_LABEL": "Entry Label",
  "EQUIPMENT GUARANTEE CALLS": "Equipment guarantee calls (€)",
  "EQUIPMENT GUARANTEE RECEIVABLE": "Equipment guarantee receivable (€)",
  "EQUIPMENT GUARANTEE REFUNDS": "Equipment guarantee refund (€)",
  "EQUIPMENT_GUARANTEE_CALLS": "Equipment guarantee calls (€)",
  "EQUIPMENT_GUARANTEE_RECEIVABLE": "Equipment guarantee receivable (€)",
  "EQUIPMENT_GUARANTEE_REFUNDS": "Equipment guarantee refund (€)",
  "EQUITY": "Equity (€)",
  "EQUITY FINANCING": "Equity Financing",
  "ERE ABATEMENT": "Commercial Real Estate - Rent-free Period (€)",
  "ERE GROSS HEADLINE RENT": "Commercial Real Estate - Headline Rent (€/year)",
  "ERE GROSS HEADLINE RENT M2": "Commercial Real Estate - Headline Rent by m² (€/m²/year)",
  "ERE RENTED AREA": "Commercial Real Estate - Occupied Area (m²)",
  "ERE TOTAL AREA": "Commercial Real Estate - Total Area (m²)",
  "ERE VACANT AREA": "Commercial Real Estate - Vacant Area (m²)",
  "ERE_ABATEMENT": "Commercial Real Estate - Rent-free Period (€)",
  "ERE_GROSS_HEADLINE_RENT": "Commercial Real Estate - Headline Rent (€/year)",
  "ERE_GROSS_HEADLINE_RENT_M2": "Commercial Real Estate - Headline Rent by m² (€/m²/year)",
  "ERE_OCCUPIED_AREA_SQM": "Commercial Real Estate - Occupied Area (m²)",
  "ERE_TOTAL_AREA_SQM": "Commercial Real Estate - Total Area (m²)",
  "ERE_VACANT_AREA_SQM": "Commercial Real Estate - Vacant Area (m²)",
  "errorHandler": "Error Page",
  "ESTIMATED_RENTAL_VALUE": "Estimated Rental Value (€/m²/year)",
  "ESTIMATED_RENTAL_VALUE_WOU": "Estimated Rental Value",
  "ETAT_DE_LA_FONCTION_DE_L_EQUIPEMENT": "Etat de la fonction de l'équipement",
  "ETAT_VISUEL_DE_L_EQUIPEMENT": "Etat visuel de l'équipement",
  "EU VAT IDENTIFICATION NUMBER": "EU VAT Identification Number",
  "EU_VAT_IDENTIFICATION_NUMBER": "EU VAT Identification Number",
  "EVALUATION DATE": "Valuation Date",
  "EVOLUTION OF RENTAL EXPENSES": "OPEX Evolution",
  "EVOLUTION OF RENTAL INCOME": "Rental Income Evolution",
  "EVOLUTION YEAR": "Year",
  "EVOLUTION_YEAR": "Year",
  "Excel": "Excel",
  "EXPECTED_YIELD": "Target Yield",
  "EXPENSES BANK ACCOUNT": "Expenses Bank Account",
  "EXPENSES_BANK_ACCOUNT": "Expenses Bank account",
  "EXPORT DATE": "Export Date",
  "EXTERNAL DEBT": "External Debt",
  "EXTERNAL FUND NAME": "Funds",
  "EXTERNAL_BUILDING_NATURE_LABEL": "External Property Type",
  "EXTERNAL_FUND_NAME": "Funds",
  "EXTERNAL_GEOGRAPHIC_ZONE_CODE": "Customer Geographical Zone",
  "EXTRANET REF": "Extranet Reference Number",
  "EXTRANET_REF": "Extranet Reference Number",
  "EXTRAORDINARY DEPRECIATION": "Extraordinary Depreciation (€)",
  "EXTRAORDINARY ITEMS": "Extraordinary Items (€)",
  "EXTRAORDINARY RENT": "Extraordinary Rent (€)",
  "EXTRAORDINARY RENT END DATE": "Extraordinary Rent End Date",
  "EXTRAORDINARY RENT PERIOD": "Extraordinary Rent Period (€)",
  "EXTRAORDINARY RENT START DATE": "Extraordinary Rent Start Date",
  "EXTRAORDINARY RESULT": "Extraordinary Result (€)",
  "EXTRAORDINARY_DEPREC": "Extraordinary Depreciation (€)",
  "EXTRAORDINARY_DEPRECIATION": "Extraordinary Depreciation (€)",
  "EXTRAORDINARY_ITEMS": "Extraordinary Items (€)",
  "EXTRAORDINARY_RENT": "Extraordinary Rent (€)",
  "EXTRAORDINARY_RENT_END_DATE": "Extraordinary Rent End Date",
  "EXTRAORDINARY_RENT_PERIOD": "Extraordinary Rent Period (€)",
  "EXTRAORDINARY_RENT_START_DATE": "Extraordinary Rent Start Date",
  "FACADE_ACCUMULATED_DEPRECIATION": "Façade Accumulated Depreciation",
  "FACADE_VALUE": "Facade Value",
  "FAIR MARKET RENT": "Gross Market Rent (€/year)",
  "FAIR MARKET RENT WOU": "Fair Market Rent",
  "FAIR_MARKET_RENT": "Gross Market Rent (€/year)",
  "FAIR_MARKET_RENT_WOU": "Gross Market Rent",
  "FEBRUARY_AMOUNT": "February (€)",
  "FEE NAME": "Fee name",
  "FEE TYPE CODE": "Fee Type Code",
  "FEE TYPE NAME": "Fee Type",
  "FEE_CENTER_CODE": "Fee center code",
  "FEE_NAME": "Fee name",
  "FEE_TYPE_CODE": "Fee Type Code",
  "FEE_TYPE_NAME": "Fee Type",
  "FIN_VAC_CURRENT_MONTH": "Current Month Financial Vacancy",
  "FIN_VAC_MINUS_01_MONTH": "Financial Vacancy 1 Month earlier",
  "FIN_VAC_MINUS_02_MONTH": "Financial Vacancy 2 Months earlier",
  "FIN_VAC_MINUS_03_MONTH": "Financial Vacancy 3 Months earlier",
  "FIN_VAC_MINUS_04_MONTH": "Financial Vacancy 4 Months earlier",
  "FIN_VAC_MINUS_05_MONTH": "Financial Vacancy 5 Months earlier",
  "FIN_VAC_MINUS_06_MONTH": "Financial Vacancy 6 Months earlier",
  "FIN_VAC_MINUS_07_MONTH": "Financial Vacancy 7 Months earlier",
  "FIN_VAC_MINUS_08_MONTH": "Financial Vacancy 8 Months earlier",
  "FIN_VAC_MINUS_09_MONTH": "Financial Vacancy 9 Months earlier",
  "FIN_VAC_MINUS_10_MONTH": "Financial Vacancy 10 Months earlier",
  "FIN_VAC_MINUS_11_MONTH": "Financial Vacancy 11 Months earlier",
  "FINAL CAPITAL CLOSING DATE": "Final Capital Closing Date",
  "FINAL_CAPITAL_CLOSING_DATE": "Final Capital Closing Date",
  "FINANCIAL INCOME": "Financial Income (€)",
  "FINANCIAL OCCUPANCY RATE": "Financial Occupancy Rate",
  "FINANCIAL OCCUPANCY SUMMARY": "Occupancy Summary (Financial)",
  "FINANCIAL RESULT": "Financial Result (€)",
  "FINANCIAL VACANCY": "Financial Vacancy (€/year)",
  "FINANCIAL VACANCY BY SQM": "Financial Vacancy by m² (€/m²/year)",
  "FINANCIAL VACANCY BY YEAR": "Financial Vacancy (€/year)",
  "FINANCIAL VACANCY CUMULATE": "Financial Vacancy - Cumulative Total",
  "FINANCIAL VACANCY M2": "Financial Vacancy (€)",
  "FINANCIAL VACANCY PER SQM": "Financial Vacancy by m² (€/m²/year)",
  "FINANCIAL VACANCY RATE": "Financial Vacancy Rate",
  "FINANCIAL VACANCY SHEET": "Financial Vacancy",
  "FINANCIAL VACANCY WOU": "Financial Vacancy",
  "FINANCIAL_INCOME": "Financial Income (€)",
  "FINANCIAL_OCC_RATE": "Financial Occupancy Rate",
  "FINANCIAL_OCCUPANCY_RATE": "Financial Occupancy Rate",
  "FINANCIAL_VAC_RATE": "Financial Vacancy Rate",
  "FINANCIAL_VACANCY": "Financial Vacancy (€/year)",
  "FINANCIAL_VACANCY_PER_SQM": "Financial Vacancy by m² (€/m²/year)",
  "FINANCIAL_VACANCY_RATE": "Financial Vacancy Rate",
  "FITTINGS_ACCUMULATED_DEPRECIATION": "Fittings Accumulated Depreciation",
  "FITTINGS_VALUE": "Fittings Value",
  "FIXTURES_ACCUMULATED_DEPRECIATION": "Fixtures Accumulated Depreciation",
  "FIXTURES_VALUE": "Fixtures Value",
  "FLOOR": "Floor Number",
  "FLOORS": "Floor",
  "FOO": "Footer",
  "FormatFile": "File format",
  "FormatMontant": "Amount format",
  "FormatSeparatorThousands": "Thousands Separator Format",
  "FR_PORTFOLIO_LABEL": "Portfolio Name (French)",
  "FR_SECTOR_LABEL": "Market Geography (French)",
  "FREED LEASES NUMBER": "Terminated Lease Count",
  "FREED LEASES RATE": "Tenant Move-out Rate (Leases)",
  "FREED UNITS AREA": "Area of Leases terminated (m²)",
  "FREED UNITS AREA RATE": "Tenant Move-out Rate (Area)",
  "FREED UNITS NUMBER": "Vacated Rental Unit Count",
  "FREED UNITS RATE": "Tenant Move-out Rate (Rental Units)",
  "FREED_LEASES_NUMBER": "Terminated Lease Count",
  "FREED_LEASES_RATE": "Tenant Move-out Rate (Leases)",
  "FREED_UNITS_AREA": "Area of Leases terminated (m²)",
  "FREED_UNITS_AREA_RATE": "Tenant Move-out Rate (Area)",
  "FREED_UNITS_NUMBER": "Vacated Rental Unit Count",
  "FREED_UNITS_RATE": "Tenant Move-out Rate (Rental Units)",
  "FreezePanesFromRowNumber": "Freeze panes from row n°",
  "FreezePanesUntilLineNumber": "Freeze panes until line n°",
  "FREQUENCY": "Frequency (in Months)",
  "FREQUENCY CODE": "Billing Frequency",
  "FREQUENCY_CODE": "Billing Frequency (in Months)",
  "FULLSCREEN": "Fullscreen",
  "FUND ADDRESS": "Address",
  "FUND CODE": "Fund Entity Code",
  "FUND DATA END DATE": "Validity End Date",
  "FUND DATA START DATE": "Update Date",
  "FUND DATE OF DATA": "Cut-Off Date",
  "FUND ISIN CODE": "ISIN",
  "FUND LEGAL VEHICLE STRUCT": "Legal Vehicle Structure / Property Type",
  "FUND MANAGER": "Fund Manager",
  "FUND NAME": "Fund Entity Name",
  "FUND PRORATA": "Fund Entity (Prorata)",
  "FUND_CODE": "Fund Entity Code",
  "FUND_DATA_END_DATE": "Validity End Date",
  "FUND_DATA_START_DATE": "Update Date",
  "FUND_ISIN_CODE": "ISIN",
  "FUND_MANAGER": "Fund Manager",
  "FUND_NAME": "Fund Entity Name",
  "FUNDING_AMOUNT": "Funding Amount",
  "FUNDS MANAGEMENT": "Fund Repository",
  "GARBAGE COLLECTION TAX AMOUNT": "Garbage Collection Tax Amount (€)",
  "GARBAGE_COLLECTION_TAX_AMOUNT": "Garbage Collection Tax Amount (€)",
  "GEARING RATIO": "Gearing Ratio",
  "GENERAL EXPENSES REPORT": "Statement of expenditure Report",
  "GENERAL INFORMATION": "General Information",
  "generalErrorMsg": "A problem has occurred on this web site. Please try again. If this error continues, please contact support.",
  "GEOGRAPHICAL BREAKDOWN": "Market Value Breakdown by Geography",
  "GEOGRAPHICAL SUB ZONE": "Sub Geography",
  "GEOGRAPHICAL ZONE": "Geography",
  "GEOGRAPHICAL_SUB_ZONE": "Sub Geography",
  "GEOGRAPHICAL_ZONE": "Geography",
  "GLOBAL RETURNS": "Total Returns",
  "GONE": "Gone",
  "GR_HEAD_RENT_BY_GR_BOOK_VAL": "Return on Gross Book Value",
  "GRAPH TENANT TURNOVER LEASES NUMBER": "Evolution of Tenant Move-ins and Move-outs (Leases)",
  "GRAPH TENANT TURNOVER UNITS AREA": "Evolution of Tenant Move-ins and Move-outs (Area)",
  "GRAPH TENANT TURNOVER UNITS NUMBER": "Evolution of Tenant Move-ins and Move-outs (Rental Units)",
  "Graph1Attr1": "Occupied Area",
  "Graph1Attr2": "Vacant Area",
  "Graph2Attr1": "Office",
  "Graph2Attr2": "Retail",
  "Graph3Attr3": "Net Potential Rent",
  "Graph3Attr4": "Fair Market Rent",
  "Graph4Attr1": "Recovered Recoverable Expenses",
  "Graph4Attr2": "Recoverable Expenses",
  "Graph4Attr3": "Non Recoverable Expenses",
  "Graph4Attr4": "Total Operating Expenses (OPEX)",
  "Graph5Attr1": "Headline Rent",
  "Graph5Attr2": "Break Option",
  "Graph5Attr3": "Lease Expiry",
  "Graph5Attr4": "Tenant Turnover Rate",
  "Graph6Attr1": "Actual",
  "Graph6Attr2": "Budget",
  "Graph6Attr3": "Running Maintenance",
  "Graph6Attr4": "Major Refurbishment",
  "graphArbitrageHistoryArea": "Historical Disposals by Property Type and Rental Area",
  "graphArbitrageHistoryValue": "Historical Acquisitions by Property Type and Acquisition Value",
  "graphHoldoverTenancyRentAmount": "Holdover Tenancy Breakdown by Lease Type",
  "graphHoldoverTenancyRentAmountPerYear": "Holdover Tenancy Summary",
  "graphLeaseEnterpriseSchedule1": "Upcoming Lease Events (Rentable Area)",
  "graphLeaseEnterpriseSchedule2": "Upcoming Lease Events (Headline Rent)",
  "graphLeaseMonitoringAmount": "Rent Uplift and Reversionary Potential",
  "graphLeaseMonitoringByNature": "Lease Breakdown by Type",
  "graphOutstandPayBalanceBreak": "Outstanding Payment Balance Breakdown between In-place and Vacated Tenants",
  "graphPortfolioBreakdownByNatureArea": "Area Breakdown by Property Type",
  "graphPortfolioBreakdownByNatureBuildingsNumber": "Property Breakdown by Property Type",
  "graphPortfolioBreakdownByNatureLeasesNumber": "Lease Breakdown by Property Type",
  "graphPortfolioBreakdownByNatureMarketValue": "Market Value Breakdown by Property Type",
  "graphPortfolioBreakdownByNatureRent": "Gross Headline Rent Breakdown by Property Type",
  "graphPortfolioBreakdownByNatureUnitsNumber": "Unit Breakdown by Property Type",
  "graphPortfolioBreakdownByTypeArea": "Area Breakdown by Property Class",
  "graphPortfolioBreakdownByTypeBuildingsNumber": "Property Breakdown by Property Class",
  "graphPortfolioBreakdownByTypeLeasesNumber": "Lease Breakdown by Property Class",
  "graphPortfolioBreakdownByTypeMarketValue": "Market Value Breakdown by Property Class",
  "graphPortfolioBreakdownByTypeRent": "Gross Headline Rent Breakdown by Property Class",
  "graphPortfolioBreakdownByTypeUnitsNumber": "Unit Breakdown by Property Class",
  "graphPortfolioEvolutionArea": "Portfolio Evolution (Total Area)",
  "graphPortfolioEvolutionBuildNatArea": "Portfolio Evolution by Property Type (Total Area)",
  "graphPortfolioEvolutionBuildNatGrossHeadlineRent": "Portfolio Evolution by Property Type (Gross Headline Rent)",
  "graphPortfolioEvolutionBuildNatNetEffectiveRent": "Portfolio Evolution by Property Type (Net Operating Income)",
  "graphPortfolioEvolutionBuildNatValue": "Portfolio Evolution by Property Type (Net Market Value)",
  "graphPortfolioEvolutionBuildNumber": "Portfolio Evolution (Property Count)",
  "graphPortfolioEvolutionBuildNumberNat": "Portfolio Evolution by Property Type (Property Count)",
  "graphPortfolioEvolutionTotalGrossHeadlineRent": "Portfolio Evolution (Gross Headline Rent)",
  "graphPortfolioEvolutionTotalNetEffectiveRent": "Portfolio Evolution (Net Operating Income)",
  "graphPortfolioEvolutionValue": "Portfolio Evolution (Net Market Value)",
  "graphPurchaseHistoryArea": "Historical Acquisitions by Property Type and Rental Area",
  "graphPurchaseHistoryValue": "Historical Acquisitions by Property Type and Acquisition Value",
  "graphTenantTurnoverLeasesNumber": "Evolution of Tenant Move-ins and Move-outs (Leases)",
  "graphTenantTurnoverUnitsArea": "Evolution of Tenant Move-ins and Move-outs (Area)",
  "graphTenantTurnoverUnitsNumber": "Evolution of Tenant Move-ins and Move-outs (Rental Units)",
  "graphVacantUnitsByUnitType": "Breakdown of Vacant Units by Unit Type",
  "graphVacantUnitsByVacancyType": "Breakdown of Vacant Units by Vacancy Type",
  "GROSS BOOK VALUE": "Gross Book Value (€)",
  "GROSS CAPITAL RATE": "Gross Capitalization Rates",
  "GROSS DEBT": "Gross Debt (€)",
  "GROSS DEBT UNDERLYING VEHICLES": "Gross Debt (Underlying Vehicles) (€)",
  "GROSS EFFECT RENT RECEIVABLE": "Gross Effective Rent Receivable (€/year)",
  "GROSS EFFECTIVE RENT": "Gross Effective Rent (€/year)",
  "GROSS EFFECTIVE RENT WOU": "Gross Effective Rent",
  "GROSS HEADLINE RENT": "Gross Headline Rent (€/year)",
  "GROSS HEADLINE RENT BY NET MARKET VALUE": "Headline Gross Initial Yield",
  "GROSS HEADLINE RENT M2": "Gross Headline Rent by m² (€/m²/year)",
  "GROSS HEADLINE RENT PERCENT": "% of Portfolio Total Gross Headline Rent",
  "GROSS HEADLINE RENT WOU": "Gross Headline Rent",
  "GROSS INITIAL YIELD": "Gross Initial Yield",
  "GROSS INITIAL YIELDS": "Gross Initial Yields",
  "GROSS LEASING AREA": "Gross Leasing Area (GLA) (m²)",
  "GROSS LEASING AREA WOU": "Gross Leasing Area (GLA)",
  "GROSS MARKET VALUE": "Gross Market Value (€)",
  "GROSS POTENTIAL RENT": "Gross Potential Rent (€/year)",
  "GROSS POTENTIAL RENTAL INCOME": "Gross Potential Rental Income",
  "GROSS RECEIPT RENT": "Collected Rent (€)",
  "GROSS RECEIPT RENT WOU": "Collected Rent",
  "GROSS RECEIPTED RENT": "Gross Effective Rent Receivable YtD (€)",
  "GROSS RECEIPTED RENT BY GROSS BOOK VALUE": "",
  "GROSS RECEIPTED RENT BY NET MARKET VALUE": "",
  "GROSS RECEIPTED RENT FUND": "Gross Effective Rent Receivable YtD (€)",
  "GROSS RENTAL INCOME": "Gross Rental Income",
  "GROSS RENTAL INCOME FUND": "Gross Rental Income (€)",
  "GROSS RENTAL INCOME OWNED": "Rents (€)",
  "GROSS REVERS CAPITAL RATE": "Gross Reversionary Cap Rate",
  "GROSS REVERS POTENT OCCUP AREA": "On Occupied Area",
  "GROSS REVERS POTENT OCCUP AREA FULL": "Reversionary Potential (Occupied Area)",
  "GROSS REVERS POTENT TOTAL PROP": "On Total Property",
  "GROSS REVERS POTENT TOTAL PROP FULL": "Reversionary Potential (Total Area)",
  "GROSS REVERSIONARY POTENTIAL": "Reversionary Potential",
  "GROSS REVERSIONARY YIELD": "Gross Reversionary Yield",
  "GROSS_BOOK_VALUE": "Gross Book Value (€)",
  "GROSS_EFFECT_RENT_RECEIVABLE": "Gross Effective Rent Receivable YYYY-1 (€/year)",
  "GROSS_EFFECTIVE_RENT": "Gross Effective Rent (€/year)",
  "GROSS_HEADLINE_RENT": "Gross Headline Rent (€/year)",
  "GROSS_HEADLINE_RENT_M2": "Gross Headline Rent by m² (€/m²/year)",
  "GROSS_HEADLINE_RENT_PERCENT": "% of Portfolio Total Gross Headline Rent",
  "GROSS_LEASABLE_AREA_SQM": "Gross Leasing Area (GLA) (m²)",
  "GROSS_MARKET_VALUE": "Gross Market Value (€)",
  "GROSS_MARKET_VALUE_Dashboard": "Gross Market Value",
  "GROSS_POTENTIAL_RENT": "Gross Potential Rent (€/year)",
  "GROSS_POTENTIAL_RENT_WOU": "Gross Potential Rent",
  "GROSS_RECEIPTED_RENT": "Gross Effective Rent Receivable YtD (€)",
  "GROSS_REVERS_CAPITAL_RATE": "Gross Reversionary Cap Rate",
  "GROSS_REVERS_POTENT_OCCUP_AREA": "Reversionary Potential (Occupied Area)",
  "GROSS_REVERS_POTENT_TOTAL_PROP": "Reversionary Potential (Total Area)",
  "GROSS_REVERSIONARY_YIELD": "Gross Reversionary Yield",
  "GUARANTEE BANK 1": "Bank of Guarantee 1",
  "GUARANTEE BANK 2": "Bank of Guarantee 2",
  "GUARANTEE BANK 3": "Bank of Guarantee 3",
  "GUARANTEE END DATE 1": "End Date of Guarantee 1",
  "GUARANTEE END DATE 2": "End Date of Guarantee 2",
  "GUARANTEE END DATE 3": "End Date of Guarantee 3",
  "GUARANTEE START DATE 1": "Start date of Guarantee 1",
  "GUARANTEE START DATE 2": "Start date of Guarantee 2",
  "GUARANTEE START DATE 3": "Start date of Guarantee 3",
  "GUARANTEE TYPE": "Type of Guarantee",
  "GUARANTEE TYPE 1": "Type of Guarantee 1",
  "GUARANTEE TYPE 2": "Type of Guarantee 2",
  "GUARANTEE TYPE 3": "Type of Guarantee 3",
  "GUARANTEE_BANK_1": "Bank of Guarantee 1",
  "GUARANTEE_BANK_2": "Bank of Guarantee 2",
  "GUARANTEE_BANK_3": "Bank of Guarantee 3",
  "GUARANTEE_END_DATE_1": "End Date of Guarantee 1",
  "GUARANTEE_END_DATE_2": "End Date of Guarantee 2",
  "GUARANTEE_END_DATE_3": "End Date of Guarantee 3",
  "GUARANTEE_START_DATE_1": "Start date of Guarantee 1",
  "GUARANTEE_START_DATE_2": "Start date of Guarantee 2",
  "GUARANTEE_START_DATE_3": "Start date of Guarantee 3",
  "GUARANTEE_TYPE": "Type of Guarantee",
  "GUARANTEE_TYPE_1": "Type of Guarantee 1",
  "GUARANTEE_TYPE_2": "Type of Guarantee 2",
  "GUARANTEE_TYPE_3": "Type of Guarantee 3",
  "HD1": "Header 1",
  "HD2": "Header 2",
  "HD3": "Header 3",
  "HEA": "Header",
  "HEAD OFFICE": "Head Office",
  "HeaderLine": "Keep the header line",
  "HEADLINE GROSS CAPITAL RATE": "Headline Gross Cap Rate",
  "HEADLINE GROSS INITIAL YIELD": "Headline Gross Initial Yield",
  "HEADLINE NET CAPITAL RATE": "Headline Net Cap Rate",
  "HEADLINE NET INITIAL YIELD": "Headline Net Initial Yield",
  "HEADLINE_GROSS_CAPITAL_RATE": "Headline Gross Cap Rate",
  "HEADLINE_GROSS_INITIAL_YIELD": "Headline Gross Initial Yield",
  "HEADLINE_NET_CAPITAL_RATE": "Headline Net Cap Rate",
  "HEADLINE_NET_INITIAL_YIELD": "Headline Net Initial Yield",
  "HELD SHARES NUMBER": "Number of Shares held",
  "HELD_SHARES_NUMBER": "Number of Shares held",
  "HELD_SHARES_NUMBER_mismatch": "Number of Shares Held mismatch",
  "hlAlertsPage": "Alerts Page",
  "hlLoginLink": "Login",
  "hlMyAssets": "Maximize",
  "HOLDING COMPANY": "Holding Company",
  "HOLDING_COMPANY": "Holding Company",
  "HOLDOVER LEASE": "Lease Count",
  "HOLDOVER LEASE NUMBER": "Holdover Lease Count",
  "HOLDOVER LEASE SUMMARY": "Holdover Lease Summary",
  "HOLDOVER RENT": "Headline Rent (€/year)",
  "HOLDOVER RENT M2": "Headline Rent by m² (€/m²/year)",
  "HOLDOVER TENANCY": "Holdover Tenancy Report as at",
  "HOLDOVER TENANCY BREAKDOWN BY LEASE NATURE": "Holdover Tenancy Breakdown by Lease Type",
  "HOLDOVER TENANCY SHEET": "Holdover Tenancy Report",
  "HOLDOVER TENANCY SUMMARY": "Holdover Tenancy Summary",
  "HOUSING_COMPLEX": "Group of buildings",
  "httpErrorMsg": "An HTTP error occurred. Page Not found. Please try again.",
  "ID_ARBITRAGE_TYPE": "Disposition Type Identity",
  "ID_BUILDING_NATURE": "Property Type Identity",
  "ID_CURRENT_INDEX_TYPE": "Index Current Type Code",
  "ID_DOCUMENT_TYPE": "Document Type Code",
  "ID_INDEX_DIRECTION": "Rent Review Direction Code",
  "ID_INDEX_FLEXIBILITY": "Flexibility Index Code",
  "ID_INDEX_RHYTHM_UNIT_OF_ADJUSTMENT": "Rent Review Frequency Unit of Ajustment",
  "ID_INDEXATION_THRESHOLD_TYPE": "Rent Review Threshold Type Code",
  "ID_INITIAL_INDEX_TYPE": "Index Initial Type Code",
  "ID_INVESTOR": "Investor Identity",
  "ID_LEGAL_VEHICLE_STRUCTURE": "Legal Vehicle Structure Identity",
  "ID_NEXT_INDEX_TYPE": "Index Next Type Code",
  "ID_PURCH_WORK_PROGRES_STATE": "Purchase Works Status",
  "ID_PURCHASE_GOAL": "Acquisition Goal",
  "ID_PURCHASE_PROGRES_STATE": "Acquisition Status",
  "ID_PURCHASE_TYPE": "Acquisition Type",
  "ID_REINVOICING_METHOD": "Reinvoicing Method Code",
  "ID_REPOSITORY_ARBITRAGE": "Disposal Code",
  "ID_REPOSITORY_FILE_IMPORT": "Import File Identity",
  "ID_REPOSITORY_PURCHASE": "Acquisition Code",
  "ID_SALE_PROGRES_STATE": "Disposal Status",
  "ID_SALE_PROGRESSION_STATE": "Disposition Status Identity",
  "ID_SEQUENCE": "Sequence Number",
  "IDENTIFICATION": "Property Summary",
  "IMPACT_CRITERES_ESG": "Impact critères ESG",
  "IMPAIR LOSS CALC": "Impairment Loss (calculated) (€)",
  "IMPAIR_LOSS_CALC": "Impairment Loss (calculated) (€)",
  "IMPAIRMENT CHARGE": "Net Book Value after Impairment (€)",
  "IMPAIRMENT LOSS": "Impairment Loss (user-defined) (€)",
  "IMPAIRMENT RATIO": "Impairment Ratio",
  "IMPAIRMENT_CHARGE": "Net Book Value after Impairment (€)",
  "IMPAIRMENT_CHARGE_RECORDED_IN_PRIOR_PERIODS": "Impairment Charge recorded in prior periods",
  "IMPAIRMENT_LOSS": "Impairment Loss (user-defined) (€)",
  "IMPAIRMENT_RATIO": "Impairment Ratio",
  "IMPORT_DATE": "Import Date",
  "ImportParamTranscodingError": "Error when importing param_transcoding file",
  "ImportParamTranscodingErrorColumnIsEmpty": "The column field is empty",
  "ImportParamTranscodingErrorColumnNotExist": "The column does not exist",
  "ImportParamTranscodingErrorOnePortfolio": "Only one portfolio by file can be imported",
  "ImportParamTranscodingErrorOnePropertyManager": "Only one Property Manager by file can be imported",
  "ImportParamTranscodingErrorOneTranscodingType": "Only one transcoding type by file can be imported",
  "ImportParamTranscodingErrorOneTranscodingTypeFilled": "At least one of the FR, EN or DE label must be filled",
  "ImportParamTranscodingErrorPortfolioIsEmpty": "The portfolio field is empty",
  "ImportParamTranscodingErrorTableIsEmpty": "The table field is empty",
  "ImportParamTranscodingErrorTableNotExist": "The table does not exist",
  "ImportParamTranscodingErrorTableOrColumnNotSame": "Table and column fields must be identical on each line",
  "ImportParamTranscodingPortfolioNotExist": "The portfolio does not exist",
  "IMPUTATION": "Imputation",
  "IN_ADVANCE": "In Advance",
  "IN_ARREARS": "In Arrears",
  "INACTIVE_AREA": "Inactive Area",
  "INACTIVE_AS_OF_THE_END_DATE": "Inactive as of the End Date",
  "INACTIVE_AS_OF_THE_REPORT_DATE": "Inactive as of the Reporting Date",
  "INACTIVE_AS_OF_THE_START_DATE": "Inactive as of the Start Date",
  "INCENTIVE RATE": "Incentive Rate",
  "INCENTIVE_RATE": "Incentive Rate",
  "INCEPTION DATE": "Inception Date",
  "INCEPTION_DATE": "Inception Date",
  "IncludBuildingInTS": "Buildings should be included in <br> tenancy schedule",
  "INCLUDING TAX AMOUNT": "Invoice amount including taxes",
  "INCLUDING TAX AMOUNT 1": "Invoice amount",
  "INCLUDING TAX AMOUNT TOTAL": "Amount including all taxes",
  "INCLUDING TAX AMOUNT TOTAL 1": "Invoice amount",
  "INCLUDING_TAX_AMOUNT": "Invoice amount including taxes",
  "INCLUDING_TAX_AMOUNT_1": "Invoice amount",
  "INCLUDING_TAX_AMOUNT_TOTAL": "Amount including all taxes",
  "INCLUDING_TAX_AMOUNT_TOTAL_1": "Invoice amount",
  "INCOME INCREASE ON RENTED AREA": "On Occupied Area",
  "INCOME INCREASE PROPERTY WIDE": "On Total Property",
  "INCOME RETURN": "Income Return",
  "INCOME RETURN FUND": "Income Return",
  "INCOME TAX": "Corporate Income Tax (€)",
  "INCOME_RETURN": "Income Return",
  "INCOME_TAX": "Corporate Income Tax (€)",
  "INDEX FLOOR": "Indexation Floor",
  "INDEX_DELAY_OF_ADJUSTMENT": "Rent Review Ajustment Delay",
  "INDEX_FLOOR": "Indexation Floor",
  "INDEX_RHYTHM": "Rent Review Frequency",
  "INDEXATION CAP": "Indexation Cap",
  "INDEXATION_CAP": "Indexation Cap",
  "INDEXATION_THRESHOLD": "Rent Review Threshold",
  "INDUSTRIAL AREA": "Industrial Area (m²)",
  "INDUSTRIAL_AREA": "Industrial Area (m²)",
  "INIT_ANNUAL_AMOUNT": "Initial Annual Amount",
  "INIT_ANNUAL_AMOUNT_PER_UNIT": "Initial Annual Amount Per Unit",
  "INIT_BALANCE": "0000-Balance BoP",
  "INITIAL ANNUAL RENT AMOUNT": "Initial Headline Rent (€/year)",
  "INITIAL ANNUAL RENT AMOUNT M2": "Initial Headline Rent by m² (€/m²/year)",
  "INITIAL DEPOSIT VALUE": "Initial Security Deposit (€)",
  "INITIAL EQUIPMENT GUARANTEE": "Initial equipment guarantee (€)",
  "INITIAL GUARANTEE 1": "Inital guarantee 1 (€)",
  "INITIAL GUARANTEE 2": "Inital guarantee 2 (€)",
  "INITIAL GUARANTEE 3": "Inital guarantee 3 (€)",
  "INITIAL INDEX": "Initial index type",
  "INITIAL INDEX DATE": "Initial Index Date",
  "INITIAL INDEX VALUE": "Initial index",
  "INITIAL VALUE": "Initial Value",
  "INITIAL WORKING CAPITAL": "Initial working capital (€)",
  "INITIAL_ANNUAL_RENT_AMOUNT": "Initial Headline Rent (€/year)",
  "INITIAL_ANNUAL_RENT_AMOUNT_M2": "Initial Headline Rent by m² (€/m²/year)",
  "INITIAL_ANNUAL_RENT_AMOUNT_SQM": "Initial Headline Rent by m² (€/m²/year)",
  "INITIAL_DEPOSIT_VALUE": "Initial Security Deposit (€)",
  "INITIAL_EQUIPMENT_GUARANTEE": "Initial equipment guarantee (€)",
  "INITIAL_GUARANTEE_1": "Inital guarantee 1 (€)",
  "INITIAL_GUARANTEE_2": "Inital guarantee 2 (€)",
  "INITIAL_GUARANTEE_3": "Inital guarantee 3 (€)",
  "INITIAL_INDEX": "Initial index",
  "INITIAL_INDEX_DATE": "Initial Index Date",
  "INITIAL_INDEX_PERIODICITY": "Rent Review Initial Frequency",
  "INITIAL_INDEX_VALUE": "Initial Index Value",
  "INITIAL_VALUE": "Initial Value",
  "INITIAL_WORKING_CAPITAL": "Inital working capital (€)",
  "InPlaceTenants": "In-place Tenants",
  "InsertFilterLineNumber": "insert a filter line n°",
  "INSURANCE BEGIN DATE": "Insurance start date",
  "INSURANCE DUNNING DATE": "Insurance dunning date",
  "INSURANCE DUNNING LEVEL": "Insurance dunning level",
  "INSURANCE END DATE": "Insurance end date",
  "INSURANCE PERIOD": "Insurance period",
  "INSURANCE POLICY NUMBER": "Insurance Policy Number",
  "INSURANCE PREMIMS AMOUNT": "Insurance Premiums Amount (€)",
  "INSURANCE_BEGIN_DATE": "Insurance start date",
  "INSURANCE_DUNNING_DATE": "Insurance dunning date",
  "INSURANCE_DUNNING_LEVEL": "Insurance dunning level",
  "INSURANCE_END_DATE": "Insurance end date",
  "INSURANCE_PERIOD": "Duration of the insurance (months)",
  "INSURANCE_POLICY_NUMBER": "Insurance Policy Number",
  "INSURANCE_PREMIMS_AMOUNT": "Insurance Premiums Amount (€)",
  "INTEREST LETTER SENDING DATE": "Date Sent of the Letter of Interest",
  "INTEREST PAID ON CURRENT ACCOUNT": "Interest paid on Current Account (€)",
  "INTEREST_LETTER_SENDING_DATE": "Date Sent of the Letter of Interest",
  "INTEREST_LETTER_SENDING_DATE_mismatch": "Date Sent of the Letter of Interest mismatch",
  "INVESTMENT STRUCTURE": "Investment Structure",
  "INVESTOR": "Investor Name",
  "INVESTOR CODE": "Investor Code",
  "INVESTOR CONTRIBUTIONS": "Investor Contributions",
  "INVESTOR DISTRIBUTION": "Investor Distribution",
  "INVESTOR GROUP": "Group",
  "INVESTOR LEVEL NAV": "Investor-level NAV (€)",
  "INVESTOR LEVEL NAV TITLE": "Investor-level NAV",
  "INVESTOR NAME": "Investor Name",
  "INVESTOR NAV AND RETURNS": "INVESTOR NAV AND RETURNS",
  "INVESTOR RETURN": "Investor Return",
  "INVESTOR SHARE OF NAV": "Investor Share of NAV (€)",
  "INVESTOR_CODE": "Investor code",
  "INVESTOR_GROUP": "Group",
  "INVESTOR_NAME": "Investor name",
  "INVESTOR_SHARE_OF_NAV": "Investor Share of NAV (€)",
  "INVESTORS": "Investors",
  "INVOICE": "Label",
  "INVOICE DURING PERIOD INC TAX AMNT": "Invoiced (€)",
  "INVOICE NUMBER": "Invoice Number",
  "INVOICE_DURING_PERIOD_INC_TAX_AMNT": "Invoiced (€)",
  "INVOICE_NUMBER": "Invoice Number",
  "INVOICES NOT DUE INC TAX AMOUNT": "Invoices not yet due (€)",
  "INVOICES_NOT_DUE_INC_TAX_AMOUNT": "Invoices not yet due (€)",
  "INVOICING": "Invoiced (€)",
  "INVOICING BANK ACCOUNT": "Invoicing Bank Account",
  "INVOICING DATE": "Invoicing Date",
  "INVOICING FREQUENCY LABEL": "Billing Frequency",
  "INVOICING INCL TAX AMOUNT": "Amount Invoiced (€)",
  "INVOICING ITEM": "Invoice Line",
  "INVOICING_BANK_ACCOUNT": "Invoicing Bank account",
  "INVOICING_DATE": "Invoicing Date",
  "INVOICING_FREQUENCY_LABEL": "Billing Frequency",
  "INVOICING_INCL_TAX_AMOUNT": "Amount Invoiced (€)",
  "INVOICING_PERIOD": "Invoiced (€)",
  "IS BUILDING NEUTRALIZED": "Neutralized ?",
  "IS DIRECT DEBIT": "Direct debit ?",
  "IS GREEN LEASE": "Green Lease (yes/no)?",
  "IS HOLDOVER LEASE": "Holdover Lease?",
  "IS IN LITIGATION": "Ongoing Litigation?",
  "IS LEASE RENEWED": "Lease Renewal ?",
  "IS LOOKTHROUGH ENTITY": "Look-through Entity (yes/no)?",
  "IS MAIN UNIT": "Primary unit (yes/no)?",
  "IS OPERATING EXPENSES REBILLED": "Are Operating Expenses Rebilled (yes/no)?",
  "IS RENT CATCH UP": "Rent Catch-up (yes/no)?",
  "IS SERVICES CHARGES CAPPED": "Are Services Charges Capped (yes/no)?",
  "IS_BUILDING_NEUTRALIZED": "Neutralized ?",
  "IS_DIRECT_DEBIT": "Direct Debit ?",
  "IS_GREEN_LEASE": "Green Lease (yes/no)?",
  "IS_HOLDOVER_LEASE": "Holdover Lease?",
  "IS_IN_LITIGATION": "Ongoing Litigation?",
  "IS_LEASE_RENEWED": "Lease Renewal ?",
  "IS_LOOK_THROUGH_ENTITY": "Look-through Entity (1=yes/0=no)?",
  "IS_LOOKTHROUGH_ENTITY": "Look-through Entity?",
  "IS_MAIN_UNIT": "Primary unit (yes/no)?",
  "IS_OPERATING_EXPENSES_REBILLED": "Are Operating Expenses Rebilled (yes/no)?",
  "IS_OWNER": "Property Owner Fund (1=yes/0=no)?",
  "IS_REGULAR_CHARGE_REVIEWABLE": "Reviewable Billing Item?",
  "IS_RENT": "Is a Rent?",
  "IS_RENT_CATCH_UP": "Rent Catch-up (yes/no)?",
  "IS_RENTAL_FREE": "Rent Free?",
  "IS_SERVICES_CHARGES_CAPPED": "Are Services Charges Capped (yes/no)?",
  "ISIN": "ISIN",
  "isSaveDirectory": "Save local folder",
  "isSaveGED": "Save in EDM",
  "isSaveMultiple": "One file per property",
  "isSaveOne": "Only one file",
  "IsSeparatorThousands": "Thousands Separator (Yes/No)",
  "ISSUED SHARES NUMBER": "Number of Shares Outstanding",
  "ISSUED_SHARES_NUMBER": "Number of Shares Outstanding",
  "ITEM_END_DATE": "Billing Item End Date",
  "ITEM_START_DATE": "Billing Item Start Date",
  "JANUARY_AMOUNT": "January (€)",
  "JOURNAL CODE": "Journal",
  "JOURNAL_CODE": "Journal",
  "JOURNAL_LABEL": "Journal Label",
  "JULY_AMOUNT": "July (€)",
  "JUNE_AMOUNT": "June (€)",
  "KEY FIGURES": "Key Figures",
  "LAND PRICE": "Land Value (€)",
  "LAND_PRICE": "Land Value (€)",
  "LAND_PRICE_mismatch": "Land Value mismatch",
  "LAND_PRICE_WOU": "Land Value",
  "LAND_VALUE": "Land Value",
  "LANDED N": "Landed",
  "LANDED N M1": "Landed",
  "LANDED_N": "Landed YYYY",
  "LANDED_N_M1": "Landed YYYY-1",
  "LANDLORD NOTICE DATE": "Landlord Notice Date",
  "LANDLORD_NOTICE_DATE": "Landlord Notice Date",
  "LAST ANNUAL RENT AMOUNT": "Last Annual Rent Amount (€/year)",
  "LAST EDITION": "Last edition:",
  "LAST INDEXATION DATE": "Last indexation date",
  "LAST LEASE RENT AMOUNT": "Last Lease Headline Rent (€/year)",
  "LAST LEASE RENT AMOUNT M2": "Last Lease Headline Rent by m² (€/m²/year)",
  "LAST RENT REVIEW DATE": "Last Rent Review Date",
  "LAST TENANT NAME": "Previous Tenant Name",
  "LAST TENANT TRADE NAME": "Previous Lease - Trade Name",
  "LAST VALUATION DATE": "Valuation Date",
  "LAST_INDEXATION_DATE": "Last indexation date",
  "LAST_LEASE_RENT_AMOUNT": "Last Lease Headline Rent (€/year)",
  "LAST_LEASE_RENT_AMOUNT_M2": "Last Lease Headline Rent by m² (€/m²/year)",
  "LAST_RENT_REVIEW_DATE": "Last Rent Review Date",
  "LAST_TENANT_NAME": "Previous Tenant Name",
  "LAST_TENANT_TRADE_NAME": "Previous Tenant Trade Name",
  "LAST_VALUATION_DATE": "Valuation Date",
  "LAST_VALUATION_DATE_YYYY": "Valuation Date YYYY-1",
  "LATITUDE": "Latitude",
  "lbAlertAndPortfolio": "My Alerts & My Portfolio",
  "lbAlphanumeric": "alphanumeric",
  "lbAmerican": "American",
  "lbAmoutFormat": "Amount Format",
  "lbAPRAFinancialOccupancyEvolution": "EPRA Financial Occupancy Evolution",
  "lbAttribute": "Attribute",
  "lbCapitalDecrease": "Capital Decrease",
  "lbCapitalGrowth": "Capital Growth",
  "lbCapitalReturnTotalReturnEvolution": "NAV-based Income Return, Capital Return and Total Return Evolution",
  "lbCodeFormat": "Code Format",
  "lbCollectionPeriods": "Collection Period Breakdown",
  "lbCollectionPeriodsSBigError": "Please enter a value lower than the next one.",
  "lbCollectionPeriodsSmallError": "Please enter a value higher than the previous one.",
  "lbColumnFormat": "Column Format",
  "lbCulture": "Culture",
  "lbDate": "Date",
  "lbDateFormat": "Date Format",
  "lbDefault": "By Default",
  "lbDispalyThePropertiesThatAre": "Display the properties that are…",
  "lbElement": "Element",
  "lbEnglish": "English",
  "lbERV": "ERV",
  "lbExcludFollowJournal": "Exclude the following general journal categories",
  "lbFieldToBeLeft": "Field to be left",
  "lbFileNameFomatDate": "File Name - Dates and Hours Format",
  "lbFileSeparator": "Field Separator",
  "lbFinancialOccupancyEvolution": "Financial Occupancy Evolution",
  "lbFixedValue": "Fixed Value",
  "lbFrench": "French",
  "lbGerman": "German",
  "lbHeaderCollectionPerdios": "This page's settings concern only the ventilation periods of the \"Rental income collection\", \"Detailed Rental Situation\" and \"Aging Schedule\" reports.",
  "lbHeadlineRentBreakByTenant": "Headline Rent Breakdown by Tenant",
  "lbInterfaceDuplicated": "Your interface [...] [….] has been copied in [...]",
  "lbInvalidatefixedValue": "Invalid value",
  "lbInvalidateInterfaceName": "Invalid interface name",
  "lbInvalidateNameADBFile": "Invalid PM name",
  "lbInvalidateNameFile": "Invalid file name",
  "lbl10LinesByPage": "10 Lines / Page",
  "lbl20LinesByPage": "20 Lines / Page",
  "lbl48Law": "1948-Law",
  "lbl48LawEuro": "1948-Law (€)",
  "lbl50LinesByPage": "50 Lines / Page",
  "lbl5LinesByPage": "5 Lines / Page",
  "lblABEPage": "Bank Movements",
  "lblAccommodation": "Accommodation",
  "lblAccountsType": "Accounts Type",
  "lblACSAccountStream": "Accounting Stream",
  "lblActivation": "Activated",
  "lblActivationFalse": "Interface is disabled",
  "lblAddAdditionalField": "Add an Additional Data Column",
  "lblAddCustomColumn": "Add a Custom Column",
  "lblAddCustomMappingColumn": "Add a Report Column",
  "lblAdditionalField": "Themes",
  "lblAdditionalFieldCode": "Theme code",
  "lblAdditionalFieldFormatError": "One of the values is not a number",
  "lblAdditionalFieldImport": "Manage Additional Data",
  "lblAdditionalFieldImportsList": "Import History",
  "lblAdditionalFieldManagement": "Theme creation",
  "lblAdditionalFieldName": "Theme label",
  "lblAdditionalFieldType": "Level",
  "lblAdditionalFieldTypes": "Levels",
  "lblAddress": "Address",
  "lblADFPage": "Additional Data",
  "lblAdministrativeLease": "Administrative Lease",
  "lblAdministrativeLeaseEuro": "Administrative Lease (€)",
  "lblALEPage": "Manage Alerts",
  "lblAlertCancelInterfaceSequence": "Are you sure you want to delete this sequence?",
  "lblAlertDeleteAdditionalField": "Are you sure you want to delete this theme?",
  "lblAlertDeleteArbitrage": "Warning ! You are deleting an arbitrage. It will be withdrawned from current arbitrage list. It will be viewable and updatable from the arbitrages history list.",
  "lblAlertDeleteFunds": "Are you sure you want to delete this fund entity?",
  "lblAlertDeleteInterface": "Are you sure you want to delete this data stream?",
  "lblAlertDeletePreviousFundUpdate": "Are you sure you want to delete the update of this fund entity?",
  "lblAlertDeletePurchase": "Warning ! You are deleting a purchase. It will be withdrawned from current purchase list. It will be viewable and updatable from the purchases history list.",
  "lblAlertDeleteRecord": "Are you sure you want to delete this record?",
  "lblAlertDeleteReportTemplate": "Are you sure you want to delete this custom report?",
  "lblAlertDeleteUser": "Are you sure you want to delete this user?",
  "lblAlertEvolutionyear1": "Display Key Values Evolution over the last",
  "lblAlertEvolutionyear2": "Display Key Values Evolution over the last",
  "lblAlertEvolutionyear3": "Display Key Values Evolution over the last",
  "lblAlertLaunchInterface": "Do you want to lauch the interface immediately ?",
  "lblAlertLeaseBreakRetail": "A Commercial Lease has a Break Option within (months):",
  "lblAlertLeaseExpiringOther": "A Lease 'Other' is expiring within (months):",
  "lblAlertLeaseExpiringResidential": "A Residential Lease is expiring within (months):",
  "lblAlertLeaseExpiringRetail": "A Commercial Lease is expiring within (months):",
  "lblAlertPropertyValueDecreased": "A Property's Net Market Value has decreased by (%) following the Latest Valuation:",
  "lblAlertPropertyValueIncreased": "A Property's Net Market Value has increased by (%) following the Latest Valuation:",
  "lblAlertRangeError": "Range Error (must be between 1 and 999)",
  "lblAlertRangeError_0_999999999": "Range Error (must be between 0 and 999999999)",
  "lblAlertReload": "Are you sure you want to reload the data?",
  "lblAlertRentDecreasedAfterAdjustment": "A Lease's Headline Rent has decreased by (%) after Rent Review:",
  "lblAlertRentDecreasedInitialRent": "A Lease's Headline Rent has decreased by (%) compared to the Initial Rent:",
  "lblAlertRentIncreasedAfterAdjustment": "A Lease's Headline Rent has increased by (%) after Rent Review:",
  "lblAlertRentIncreasedInitialRent": "A Lease's Headline Rent has increased by (%) compared to the Initial Rent:",
  "lblAlertRequiredError": "Field required",
  "lblAlertsManagement": "Manage Alerts",
  "lblAlertSoldArbitrage": "Warning ! You are updating the building status to Sold. It will be withdrawned from current arbitrage list. It will be viewable and updatable from the arbitrages history list.",
  "lblAlertTenancySchedule": "Tenancy Schedule Alerts",
  "lblAlertTerm1": "Receive a Notification when a Lease is due to expire (Break Option) within",
  "lblAlertTerm2": "Receive a Notification when a Lease is due to expire (Break Option) within",
  "lblAlertTreshold": "Alert Trigger",
  "lblAlertVacancy": "Receive a Notification when a Property has a Vacancy Rate exceeding or equal to",
  "lblAlertVacancyMixed": "A Mixed-used Property has a Vacancy Rate exceeding or equal to (%):",
  "lblAlertVacancyOffice": "An Office Property has a Vacancy Rate exceeding or equal to (%):",
  "lblAlertVacancyOther": "A Property 'Other' has a Vacancy Rate exceeding or equal to (%):",
  "lblAlertVacancyRate": "Vacancy Rate",
  "lblAlertVacancyResidential": "A Residential Property has a Vacancy Rate exceeding or equal to (%):",
  "lblAlertVacancyRetail": "A Retail Property has a Vacancy Rate exceeding or equal to (%):",
  "lblAlertVacancyStorage": "A Logistics Property has a Vacancy Rate exceeding or equal to (%):",
  "lblAlertVacantUnit": "A unit is vacant for more than (months):",
  "lblAlertVacantUnitOptional": "If unit's area is exceeding or equal to (m²):",
  "lblAlertValidateArbitrage": "Warning ! You are validating an arbitrage. It will be withdrawned from current purchase list. It will be viewable and updatable from the arbitrages history list.",
  "lblAlertValidatePurchase": "Warning ! You are validating a purchase. It will be withdrawned from current purchase list. It will be viewable and updatable from the purchases history list.",
  "lblAnalysisDate": "Reporting Date",
  "lblAnnualFinancialVacancy": "Financial Vacancy (€/year)",
  "lblAnnualFinancialVacancyCumulate": "Financial Vacancy - Cumulative Total",
  "lblAnnualRent": "Headline Rent (€/year)",
  "lblAnnualRentSqm": "Headline Rent by m² (€/m²/year)",
  "lblApplyAllBuildings": "Apply to all the properties",
  "lblApplySelectBuildingOnly": "Apply to the selected properties and portfolio(s) only",
  "lblArbitrage": "Manage Disposals",
  "lblArbitrageAlreadyExists": "This property already exists in the arbitrages",
  "lblArbitrageHistory": "Disposal History Record",
  "lblArbitrageType": "Disposal Type",
  "lblArea": "Total Rentable Area (m²)",
  "lblAreaDashboard": "Total Rentable Area",
  "lblAreaLeaseRenewals": "Occupied Area of Leases renewed (m²)",
  "lblAreaLeasesTerminated": "Area of Leases terminated (m²)",
  "lblAreaLeaseTerminationsNotified": "Area of Leases with Termination notified (m²)",
  "lblAreaRelettings": "Re-let Area (m²)",
  "lblAreaScale": "Area",
  "lblAreasUnderManagementByBuildingType": "Areas under Management by Building Type",
  "lblAreasUnderManagementByUnitType": "Areas under Management by Unit Type",
  "lblAreaUnit": "Area Unit",
  "lblArrivalDate": "Tenant Move-in Date",
  "lblAssetManager": "Asset Manager",
  "lblAuditAction": "Audit Actions",
  "lblAuditTables": "Audit Tables",
  "lblAuditTrail": "Audit Trail Report",
  "lblAuthorizedPage": "Authorized Pages",
  "lblAuthorizedPortfolio": "Permitted Portfolios",
  "lblAUTPage": "Audit Trail Report",
  "lblAUTRawReport": "Audit Trail Report - raw",
  "lblAUTReport": "Audit Trail Report",
  "lblAverageVacancyRate": "Average Physical Vacancy Rate (%)",
  "lblBackDashoard": "Back to Dashboard",
  "lblBalanceBeforeOverpayments": "Balance Before Overpayments (€)",
  "lblBalanceSheetRevisionDate": "Balance Sheet Reception Date",
  "lblBaseReportRequired": "Please select a custom report",
  "lblBetweenOneAndTwoMonth": "1-2 Months",
  "lblBetweenTwoAndThreeMonth": "2-3 Months",
  "lblBlokingLogin": "Your account is locked out. Please contact your administrator.",
  "lblBranches": "Branches",
  "lblBreakdownByPropertyType": "Market Value Breakdown by Property Type",
  "lblBreakRentIncomeByCollectionPreiod": "Breakdown of Rental Income by Collection Period",
  "lblBudgetMonitoring": "Budget Monitoring Report",
  "lblBudgetStatus": "Budget Status",
  "lblBudgetStatusAll": "All",
  "lblBudgetStatusClosed": "Closed",
  "lblBudgetStatusOpen": "Open",
  "lblBudgetType": "Budget Type",
  "lblBudgetTypeAll": "Opex and Capex",
  "lblBudgetTypeCapex": "Capex",
  "lblBudgetTypeList": "Budget Type",
  "lblBudgetTypeListCapex": "Capex",
  "lblBudgetTypeListOpex": "Opex",
  "lblBudgetTypeListOpexCapex": "Opex and Capex",
  "lblBudgetTypeListOpexProduct": "Opex and Product",
  "lblBudgetTypeListProduct": "Product",
  "lblBudgetTypeOpex": "Opex",
  "lblBuilding": "Building",
  "lblBuildingBadElement": "The type {2} with Value {3} is not relevant to the Property {1 {0} rejected lines.",
  "lblBuildingId": "Property ID",
  "lblBuildingLease": "Long Building Lease",
  "lblBuildingLeaseEuro": "Long Building Lease (€)",
  "lblBuildingName": "Property Name",
  "lblBuildingNatures": "Property Types",
  "lblBuildingPhysicalVacancyRate": "",
  "lblBuildingPlural": "{0} {1} Properties",
  "lblBuildingPurchaseValue": "Acquisition Value (€)",
  "lblBuildingReference": "Property Repository",
  "lblBuildings": "Property(ies)",
  "lblBuildings2": "Properties",
  "lblBuildingSaleValue": "Disposal Value (€)",
  "lblBuildingSaleValueSqm": "Disposal Value by m² (€/m²)",
  "lblBuildingSaleValueTotal": "Total Disposal Value (€)",
  "lblBuildingsFound": "Property(ies) found",
  "lblBuildingSingular": "{0} {1} Property",
  "lblBuildingsMatching": "match selected criteria",
  "lblBuildingToInclude": "Properties to Include",
  "lblBuildingTotalAreaSqm": "Total Rentable Area (m²)",
  "lblBuildingTotalValue": "Total Value (€)",
  "lblBuildingTotalValueSqm": "Acquisition Value by m² (€/m²)",
  "lblBuildingType": "Property Type",
  "lblBuildingUnknown": "The Property code {1} does not exist, {0} rejected lines.",
  "lblBuildingUpdatedOn": "Property Fact Sheet as at",
  "lblBuildingValue": "Net Market Value (€)",
  "lblBuildingValueDashboard": "Net Market Value",
  "lblBuildingValueDecreasedPlural": "Market Values have decreased",
  "lblBuildingValueDecreasedSingular": "Market Value has decreased",
  "lblBuildingValueIncreasedPlural": "Market Values have increased",
  "lblBuildingValueIncreasedSingular": "Market Value has increased",
  "lblBUIPage": "Property Fact Sheet Export",
  "lblBUIReport": "Property Fact Sheet",
  "lblBUMPage": "Budget Monitoring Report",
  "lblBUMRawReport": "Budget Monitoring Report - raw",
  "lblBUMReport": "Budget Monitoring Report",
  "lblBusinessMixedPremises": "Light Industrial and Mixed Premises",
  "lblBusinessUnits": "Business Units",
  "lblBuyer": "Buyer",
  "lblCacheInfosValidityCheck": "Recovery Delay expired",
  "lblCapiGross": "Gross Capitalization Rates",
  "lblCapiNet": "Net Capitalization Rates",
  "lblCapitalEmployed": "Capital Employed (€)",
  "lblCapitalGrowthBreakdown": "Capital Growth Breakdown by Property",
  "lblCapitalReturn": "Capital Return",
  "lblCapitalReturnEquityFairValue": "Capital Return on Equity at Fair Value",
  "lblCapitalReturnOnNAV": "Capital Return on NAV",
  "lblCapitalStructure": "Capital Structure",
  "lblCarPark": "Car Park",
  "lblCarParkEuro": "Car Park (€)",
  "lblCarParkLease": "Car Park Lease",
  "lblCarParkLeaseEuro": "Car Park Lease (€)",
  "lblCashFlowBreakdown": "Operating Cash Flow Breakdown",
  "lblCashOnCash": "Cash-on-Cash",
  "lblCashOnCashPerShare": "Cash-on-Cash per Share (€)",
  "lblCashOnCashReturn": "Cash-on-Cash Return",
  "lblCBPPage": "Manage Collection Period Breakdown",
  "lblChangeParams": "Change Criteria",
  "lblChangePassword": "Change Password",
  "lblChangePwdLegend": "PASSWORD RENEWAL FORM",
  "lblChangesRecorded": "The changes have been taken into account",
  "lblCity": "Location",
  "lblCivilLease": "Civil Lease",
  "lblCivilLeaseEuro": "Civil Lease (€)",
  "lblClient": "Client",
  "lblCMOBreakdownRawReport": "Breakdown of Rental Income by Collection Period - raw",
  "lblCMOBreakdownReport": "Breakdown of Rental Income by Collection Period",
  "lblCMOBreakdownSumRawReport": "Breakdown of Rental Income by Collection Period Running Total - raw",
  "lblCMOBreakdownSumReport": "Breakdown of Rental Income by Collection Period Running Total",
  "lblCMOPage": "Rental Income Collection Report",
  "lblCMORawReport": "Rental Income Collection Report - raw",
  "lblCMOReport": "Rental Income Collection Report",
  "lblCollectionMonitoring": "Rental Income Collection Report",
  "lblCollectionMonitoringBreakdown": "Breakdown of Rental Income by Collection Period",
  "lblCollectionMonitoringDate": "Rental Income Collection",
  "lblCollectionMonitoringSum": "Running Total of Rental Income by Collection Period",
  "lblCollectionPeriods": "Manage Collection Period Breakdown",
  "lblCollectMonitBreakdownByBuilding": "Running Total of Rental Income by Collection Period and by Property",
  "lblCollectMonitBreakdownByPortfolio": "Running Total of Rental Income by Collection Period and by Portfolio",
  "lblColumnsSelectedRequired": "Please select at least 2 columns !",
  "lblCommercialLease": "Commercial Lease",
  "lblCommercialLeaseEuro": "Commercial Lease (€)",
  "lblCommercialLeasePlural": "Commercial Leases",
  "lblCommercialLeaseSingular": "Commercial Lease",
  "lblcompanie": "Funds / Companies",
  "lblCompanies": "Vehicles",
  "lblCompany": "Vehicle",
  "lblCompanyAccomodation": "Company Accommodation",
  "lblCompanyAccomodationEuro": "Company Accommodation (€)",
  "lblCompanyRegistrationNumber": "Company Registration Number",
  "lblComparisonYear": "Comparison Year",
  "lblConcentrationAndExposure": "Concentration Risks and Exposure (Area)",
  "lblConcentrationRiskAndExposure": "Concentration Risks and Exposure (Net Market Value and Headline Rent)",
  "lblConcernedPortfolio": "Relevant Portfolios",
  "lblConfirmNewPassword": "Confirm New Password",
  "lblConfirmNewPasswordMismatch": "New and confirm password must be the same",
  "lblConfirmNewPasswordRequired": "Confirm new password required",
  "lblConfirmPassword": "Confirm password",
  "lblConfirmPasswordRequired": "Confirm password required",
  "lblConsolidatedAccountsType": "Consolidated",
  "lblConstantPatrimony": "Like-for-Like Portfolio",
  "lblCostPriceFinancialInstruments": "Cost Price Financial Instruments (€)",
  "lblCountry": "Country",
  "lblCoworkingSpacesIdFKML": "Coworking Spaces (Île-de-France)",
  "lblCreateAdditionalField": "Theme creation",
  "lblCreateFund": "New Fund Entity",
  "lblCreateReportTemplate": "Custom Report",
  "lblCreateTheme": "Theme Creation",
  "lblCreateUser": "New User",
  "lblCSVSeparator": "CSV File Field Separator",
  "lblCurrency": "Currency",
  "lblCurrentAccountAlreadyExists": "The account number selected has already been used.",
  "lblCurrentAccountAndLoanAccountAlreadyExists": "",
  "lblCurrentPassword": "Current Password",
  "lblCurrentPasswordRequired": "Current Password required",
  "lblCurrentSales": "Pending Disposals",
  "lblCurrentYield": "Income Return",
  "lblCutOffDate": "Cut-off Date",
  "lblCutOffDateLocked": "Net Book Values cannot be uploaded as at this cut-off date: adjusted unrealized gains and losses have already been validated at this particular date.",
  "lblCutOffDateNotLocked": "Adjusted unrealized gains and losses have not yet been validated at this particular date.",
  "lblCutOffRevisionDate": "Date de révision des VNC",
  "lblCVAPage": "Net Asset Value Report",
  "lblCVARawReport": "Net Asset Value Report - raw",
  "lblCVAReport": "Net Asset Value Report",
  "lblDashboard": "Welcome",
  "lblDashboardAlerts": "Dashboard Alerts",
  "lblDataReloaded": "Hello,      The last data of property manager {0} have been successfully reloaded.      Best regards,    The Alhambra Team",
  "lblDataReloading": "The last data of property manager {0} are being reloaded. You will receive an email when the work will be finished.",
  "lblDataReloadSubject": "Alhambra - Data reloading",
  "lblDate": "Spot",
  "lblDateFollow": "Values must follow one another, {0} rejected lines.",
  "lblDateFormat": "dd-mm-yyyy",
  "lblDateFormatDropDown": "{0:dd-MM-yyyy}",
  "lblDatePasswordLeast2Days": "You can only change your password {0} days after it has been changed.",
  "lblDay": "D",
  "lblDefaultUserError": "An unknown error occurred. Please verify your entry and try again. If the problem persists, please contact your system administrator.",
  "lblDEKPage": "Deka Reporting",
  "lblDepreciation": "Depreciation (€)",
  "lblDestinationCode": "Destination Code",
  "lblDestinationCode_required": "Destination Code Required",
  "lblDestinationDe": "Destination Label (German)",
  "lblDestinationDe_required": "Destination Label (German) required",
  "lblDestinationEn": "Destination Label (English)",
  "lblDestinationEn_required": "Destination Label (English) required",
  "lblDestinationFr": "Destination Label (French)",
  "lblDestinationFr_required": "Destination Label (French) required",
  "lblDestinationLabel": "Destination Label",
  "lblDetailledError": "Detailed Error :",
  "lblDeTranscodingTypeLabel": "German Label Of the List",
  "lblDevelopmentPropertiesIdFKML": "Development Properties (Île-de-France)",
  "lblDirectBuildingOutstandingPayments": "Outstanding Payments by Property",
  "lblDirectBuildingOutstandingPaymentsRaw": "Outstanding Payments by Property - raw",
  "lblDirectLeaseOutstandingPayments": "Outstanding Payments by Lease",
  "lblDirectLeaseOutstandingPaymentsRaw": "Outstanding Payments by Lease - raw",
  "lblDisplayAlert": "Display Alert on Dashboard",
  "lblDistributions": "Distributions (€)",
  "lblDividendProfitDistributions": "Dividend/Profit Distributions (€)",
  "lblDividensPerShare": "Dividends per Share (€)",
  "lblDontSendBefore": "No booking entry prior to",
  "lblDOPPage": "Outstanding payments",
  "lblDoubleErvFormatError": "One ERV is not a number",
  "lblDoubleFundOwnershipFormatError": "One of the values is not a number",
  "lblDoubleLeaseRepositoryFormatError": "One of the values is not a number",
  "lblDoubleNetBookValueFormatError": "One of the values is not a number",
  "lblDoubleValuationFormatError": "One of the values is not a number",
  "lblDoubleYieldFormatError": "One Expected Gross Reversionary Yield is not a number",
  "lblDownloadReport": "Report Download",
  "lblDTZPage": "Rental Situation",
  "lblDuplicateEmail": "A username for that email address already exists. Please enter a different e-mail address.",
  "lblDuplicateUserName": "Username already exists. Please enter a different user name.",
  "lblDuration": "WALB (y-m-d)",
  "lblDuration_WOU": "WALB",
  "lblDurationToLeaseEnd": "WALT (y-m-d)",
  "lblDurationToLeaseEnd_WOU": "WALT",
  "lblEarningPerShar": "Earnings per Share (€)",
  "lblEarningsPerShareDividPerShareCashSharEvol": "Earnings per Share, Dividends per Share and Total Distribution per Share Evolution",
  "lblEditTemplate": "Edit Template",
  "lblEffectiveInitYield": "Effective Initial Yield",
  "lblEffectiveNetInitialYield_YOU": "Effective Net Initial Yield",
  "lblEffectiveNetInitYield": "Effective Net Initial Yield from (%):",
  "lblEmail": "Email",
  "lblEmailRequired": "Email required",
  "lblEmailRequiredError": "Email is required",
  "lblEmailSent": "Email successfully sent",
  "lblEmailUnknown": "Unknown Email address",
  "lblEmphyteuticLease": "Emphyteutic Lease",
  "lblEmphyteuticLeaseEuro": "Emphyteutic Lease (€)",
  "lblEmptyLabelRow": "One or more rows have an empty label.",
  "lblEmptyLabelValueRow": "One or more lines have an empty label and an empty base expression.",
  "lblEmptyValueRow": "One or more lines have an empty base expression.",
  "lblEndDate": "To",
  "lblEnTranscodingTypeLabel": "English Label Of the List",
  "lblEquity": "Equity (€)",
  "lblEquityAtFairValue": "Equity at Fair Value (€)",
  "lblEquityContributed": "Equity",
  "lblEquityFairValuePerShare": "Equity at Fair Value per Share (€)",
  "lblEquityFinancing": "Equity Financing",
  "lblERENonOperating": "Commercial Real Estate (Non-Operating)",
  "lblERPAFinancialOccupancyRate": "EPRA Financial Occupancy Rate",
  "lblERPAFinancialOccupancySum": "EPRA Financial Occupancy Summary",
  "lblERPAFinancialVacancyRate": "EPRA Financial Vacancy Rate",
  "lblError": "Error :",
  "lblErrorHandler": "Error Handler :",
  "lblErrorMessage": "Error Message",
  "lblErrorPositive": "Only positive numbers",
  "lblErrorRaport": "You cannot run an empty report",
  "lblErrorSendGED": "Error when sending the invoices email",
  "lblErvDownloadBuildingUnit": "Import ERVs at rental unit-level for each individual property",
  "lblErvDownloadBuildingUnitType": "Import ERVs at rental unit type-level for each individual property",
  "lblErvDownloadBuildingUnitTypeRoom": "Import ERVs at rental unit type-level accounting for unit-level room counts for each individual property",
  "lblErvDownloadSectorUnitType": "Import ERVs at rental unit type-level for each individual market geography",
  "lblERVImportsList": "Import History",
  "lblEuro": "€",
  "lblEuroSqm": "€/m²",
  "lblEuroSqmYear": "€/m²/year",
  "lblEuroYear": "€/year",
  "lblEvolBalanceOutstandPayment": "Outstanding Balance Bridge",
  "lblEvolution": "Evolution",
  "lblEVPPage": "RE Portfolio Evolution",
  "lblEVPRawReport": "RE Portfolio Evolution Report - raw",
  "lblEVPReport": "RE Portfolio Evolution Report",
  "lblExpensesBankAccount": "Expenses Bank Account",
  "lblExpenseType": "Expense Type",
  "lblExpenseTypeAll": "All",
  "lblExpenseTypeNonRecoverable": "Non Recoverable",
  "lblExpenseTypeRecoverable": "Recoverable",
  "lblExpiringLeasesLegend": "* Lease expiring within the period defined in the Manage Alerts menu",
  "lblExternalDebt": "External Debt",
  "lblExternalFunds": "Funds",
  "lblExtraCondition": "Extra Condition (optional)",
  "lblExtraordinaryResult": "Extraordinary Result (€)",
  "lblFalse": "FAUX",
  "lblFeeCenter": "Fee center",
  "lblFees": "Fees (€)",
  "lblFieldNamingConvention": "Field Naming Conventions",
  "lblFileError": "Wrong File Format. Please use the Template you can download on this page.",
  "lblFileFormatError": "Wrong File Format. Please use the Template you can download on this page.",
  "lblFileFormatErrorOnlyAlpha": "The LIST_CODE column must not be empty and must contain only alphanumeric characters, - and _",
  "lblFilename": "File Name",
  "lblFilenameAdditionalField": "AdditionalData.csv",
  "lblFilenameErvBuildingUnit": "erv_unit.csv",
  "lblFilenameErvBuildingUnitType": "erv_unit_type.csv",
  "lblFilenameErvBuildingUnitTypeRoom": "erv_unit_type_room_count.csv",
  "lblFilenameErvSectorUnitType": "erv_market_unit_type.csv",
  "lblFilenameFundOwnership": "investor_data.csv",
  "lblFilenameLeaseRepository": "leases.csv",
  "lblFilenameNetBookValue": "nbv.csv",
  "lblFilenameParamTranscoding": "List_To_Be_Set_",
  "lblFilenameValuation": "valuation_data.csv",
  "lblFilenameYield": "target_yield.csv",
  "lblFilterBy": "Filter by",
  "lblFinalCapitalClosingDate": "Final Capital Closing Date (if applicable)",
  "lblFinancialIncome": "Financial Income (€)",
  "lblFinancialOccupancyRate": "Financial Occupancy Rate",
  "lblFinancialOccupancyRate_WOU": "Financial Occupancy Rate",
  "lblFinancialOccupancySum": "Financial Occupancy Summary",
  "lblFinancialOccupancySummary": "Occupancy Summary (Financial)",
  "lblFinancialResult": "Financial Result (€)",
  "lblFinancialResultBridge": "Financial Result Bridge",
  "lblFinancialVacancy": "Financial Vacancy (€/year)",
  "lblFinancialVacancy_WOU": "Financial Vacancy",
  "lblFinancialVacancyRate": "Financial Vacancy Rate",
  "lblFinancialVacancyRate_WOU": "Financial Vacancy Rate",
  "lblFinancialVacancySqm": "Financial Vacancy by m² (€/m²/year)",
  "lblFincancialExpenses": "Financial Expenses (€)",
  "lblFirstName": "First Name",
  "lblFonts": "Fonts",
  "lblFormatDateIssue": "Wrong Data Format « {1} » on line {0}.",
  "lblFormIntro": "Please fill the form",
  "lblFOSPage": "Manage Investor Data",
  "lblFrame": "Frame columns with quotation mark",
  "lblFriday": "Friday",
  "lblFrTranscodingTypeLabel": "French Label Of the List",
  "lblFundCode": "Fund Entity Code",
  "lblFundCodeAlreadyExists": "A Fund Entity by this Code already exists",
  "lblFundCodeRequired": "Fund Entity Code required",
  "lblFundDataStartDate": "Update Date",
  "lblFundingAmount": "Funding",
  "lblFundIsinCode": "ISIN",
  "lblFundManager": "Fund Manager",
  "lblFundName": "Fund Entity Name",
  "lblFundNameRequired": "Fund Entity Name required",
  "lblFundOwnership": "Manage Investor Data",
  "lblFundOwnershipFund": "Investor Data",
  "lblFundOwnershipImportsList": "Import History",
  "lblFundOwnershipUnderlyingVehicles": "Investor Data",
  "lblFundProrata": "Fund Entity (Prorata)",
  "lblFundRepositoryExplanations": "The Fund Repository menu allows to model fund and investment structure within Alhambra. This menu should be used to:<BR />  - Update existing entities holding directly owned investment properties with additional information.  These entities are direct property owners and are therefore automatically created during the integration of Property Management data.<BR />  - Create and update higher-level fund and investment vehicles that do not hold direct investment properties (Special Purpose Vehicles, Holding Companies, funds, investors).<BR />  - Create and update investment relationships between any of the aforementioned entities.<BR />  <BR />  All of the data are being historicized based on the mandatory Date Updated field.",
  "lblFundSaved": "Your fund entity [...] has been successfully saved.",
  "lblFundsManagement": "Fund Repository",
  "lblFundUpdated": "Your fund entity [...] has been successfully updated.",
  "lblFundValuation": "Key Figures",
  "lblFUNPage": "Fund Fact Sheet Export",
  "lblFurnishedRental": "Furnished Rental",
  "lblFurnishedRentalEuro": "Furnished Rental (€)",
  "lblFURPropertiesIdFKML": "Follow-up and Rehabilitation Care (Île-de-France)",
  "lblGearingRatio": "Gearing Ratio",
  "lblGECPage": "Statement of expenditure - recorded invoices",
  "lblGECRawReport": "Statement of expenditure - recorded invoices raw",
  "lblGECReport": "Statement of expenditure - recorded invoices",
  "lblGeneral": "General",
  "lblGeneralInformations": "General Informations",
  "lblGeneralSettings": "General Settings",
  "lblGenerateInterfaceACS": "Manage Accounting Data Stream",
  "lblGenerateInterfaceLRC": "Manage Billing items interface",
  "lblGenerateInterfaceTS": "Manage Tenancy Schedule Data Stream",
  "lblGeographicalBreakdown": "Market Value Breakdown by Geography",
  "lblGeoZones": "Geographies",
  "lblGEPPage": "Statement of expenditure - paid invoices",
  "lblGEPRawReport": "Statement of expenditure - paid invoices raw",
  "lblGEPReport": "Statement of expenditure - paid invoices",
  "lblGERPage": "General expenses report",
  "lblGERRawReport": "Statement of expenditure report - raw",
  "lblGERReport": "Statement of expenditure report",
  "lblGlobalParemetersPage": "Global Parameters",
  "lblGlobalPortfolio": "Global Portfolio",
  "lblGlobalReturns": "Total Returns",
  "lblGlobalReturnsBreakdown": "Total Return Breakdown",
  "lblGovernmentRegulatedResidentialLease": "Government-regulated Residential Lease",
  "lblGovernmentRegulatedResidentialLeaseEuro": "Government-regulated Residential Lease (€)",
  "lblGrandParisZoning": "Grand Paris Zoning",
  "lblGross": "Gross Initial Yields",
  "lblGrossAndNetCapitalRates": "Gross and Net Capitalization Rates",
  "lblGrossAndNetInitYields": "Gross and Net Initial Yields",
  "lblGrossCapitalizationRateEvol": "Gross Capitalization Rate Evolution",
  "lblGrossDebt": "Gross Debt (€)",
  "lblGrossDebtUnderlyingVehicles": "Gross Debt (Underlying Vehicles) (€)",
  "lblGrossEffectiveRent": "Gross Effective Rent (€/year)",
  "lblGrossEffectiveRent_WOU": "Gross Effective Rent",
  "lblGrossEffectiveRentRate": "Gross Effective Rent",
  "lblGrossHeadlineRent_WOU": "Gross Headline Rent",
  "lblGrossHeadlineRentBreakByGeo": "Gross Headline Rent Breakdown by Geography",
  "lblGrossHeadlineRentBreakByPropType": "Gross Headline Rent Breakdown by Property Type",
  "lblGrossHeadlineRentBreakBySector": "Gross Headline Rent Breakdown by Sector",
  "lblGrossHeadlineRentBreakByTenant": "Headline Rent Breakdown by Tenant",
  "lblGrossHeadlineRentBreakByTenantIndustry": "Headline Rent Breakdown by Tenant Industry",
  "lblGrossHeadlineRentBridge": "Gross Headline Rent Bridge",
  "lblGrossInitilaYieldEvol": "Gross Initial Yield Evolution",
  "lblGrossReceiptedRent": "Gross Effective Rent Receivable YtD (€)",
  "lblGrossReceiptedRentByGrossBookValue": "Return on Gross Book Value",
  "lblGrossReceiptedRentByNetMarketValue": "Income Return",
  "lblGrossRent": "Gross Headline Rent (€/year)",
  "lblGrossRentalIncome": "Gross Rental Income (€)",
  "lblGrossRentalIncomeEvol": "Gross Rental Income Evolution",
  "lblGrossRentSqm": "Gross Headline Rent by m² (€/m²/year)",
  "lblGrossRentSqm_WOU": "Gross Headline Rent by m²",
  "lblGrossReversPotentOccupArea_WOU": "On Occupied Area",
  "lblGrossReversPotentTotalProp_WOU": "On Total Rentable Area",
  "lblHeaderLine": "Keep the header line",
  "lblHeadlineGrossInitialYield": "Headline Gross Initial Yield",
  "lblHeadlineInitYield": "Headline Initial Yield",
  "lblHeadlineNetInitialYield_YOU": "Headline Net Initial Yield",
  "lblHeadlineNetInitYield": "Headline Net Initial Yield (%):",
  "lblHeadlineRentAs": "Headline Rent as of DD/MM/YYYY (€)",
  "lblHeadlineRentLeaseRenewals": "Headline Rent of Leases renewed (€)",
  "lblHeadlineRentLeasesTerminated": "Headline Rent of Leases terminated (€)",
  "lblHeadlineRentLeaseTerminationsNotified": "Headline Rent of Leases with Termination notified (€)",
  "lblHeadlineRentRelettings": "Headline Rent of New Leases (€)",
  "lblHeadOffice": "Head Office",
  "lblHealthcare": "Healthcare",
  "lblHideFilters": "Hide Filters",
  "lblHideGraph": "Hide Header",
  "lblHideMap": "Hide Header",
  "lblHideParams": "Hide Parameters",
  "lblHoldoverLeasesLegend": "* Holdover Leases",
  "lblHoldoverTenancy": "Holdover Tenancy Report",
  "lblHOTPage": "Holdover Tenancy Report",
  "lblHOTRawReport": "Holdover Tenancy Report - raw",
  "lblHOTReport": "Holdover Tenancy Report",
  "lblIdExists": "Property ID already exists",
  "lblImmoStatMarketGeograhies": "ImmoStat Market Geographies",
  "lblImportDate": "Data as at",
  "lblImportedBy": "Imported By",
  "lblInceptiondate": "Inception or Acquisition Date",
  "lblIncomeReturn": "Income Return",
  "lblIncomeReturnEquityFairValBridgs": "Equity at Fair Value-based Income Return, Capital Return and Total Return Evolution",
  "lblIncomeReturnEquityFairValue": "Income Return on Equity at Fair Value",
  "lblIncomeReturnOnNAV": "Income Return on NAV",
  "lblIndustrialPremises": "Industrial Premises",
  "lblInfOneMonth": "< 1 Month",
  "lblInfThreeMonth": "< 3 Months",
  "lblInfTwoMonth": "< 2 Months",
  "lblInnerMsgError": "Detailed Error Message :",
  "lblInsertNewNeutralizationPeriod": "Do you want to add the new neutralization period ?",
  "lblInterestPaidCurrentAccount": "Interest paid on Current Account (€)",
  "lblInterestPaidOnCurrentAccount": "Interest paid on Current Account (€)",
  "lblinterface_name": "Interface name",
  "lblinterface_type": "Interface type",
  "lblInterfaceInsertAccounting": "Your accounting stream [...] has been successfully saved and added to the list of available accounting streams.",
  "lblInterfaceInsertAS": "Your data stream - Accounting Stream [...] has been successfully saved and added to the list of available data streams.",
  "lblInterfaceInsertTS": "Your data stream - Tenancy Schedule [...] has been successfully saved and added to the list of available data streams.",
  "lblInterfaceMappingExport": "Export the Data Type",
  "lblInterfaceMappingWarningMessage": "Beware, importing settings for a data type will suppress the existing settings on this data type.",
  "lblInterfaceName": "Interface Name",
  "lblInterfaceNameAlreadyExists": "An Interface by this Name already exists",
  "lblInterfaceSequencesHistory": "Sendings history of the Accounting Stream",
  "lblInterfaceSequencesLRCHistory": "Sendings history of the Billing items interface",
  "lblInterfaceSequencesTSHistory": "Sendings history of the Tenancy Schedule",
  "lblInterfaceUpdateAccounting": "Your accounting stream - [...] has been successfully updated.",
  "lblInterfaceUpdateAS": "Your data stream - Accounting Stream [...] has been successfully updated.",
  "lblInterfaceUpdateSI": "Your data stream - Specific Interface [...] has been successfully updated.",
  "lblInterfaceUpdateTS": "Your data stream - Tenancy Schedule [...] has been successfully updated.",
  "lblInvalidAnswer": "The password retrieval answer provided is invalid. Please check the value and try again.",
  "lblInvalidBuildingCode": "Invalid Property Code",
  "lblInvalidBuildingName": "Invalid Property Name",
  "lblInvalidBuildingNature": "Invalid Property Type",
  "lblInvalidBuildingUniqueCode": "Invalid Property Manager's Property Code",
  "lblInvalidEmail": "The email address provided is invalid. Please check the value and try again.",
  "lblInvalidPassword": "The password provided is invalid. Please enter a valid password value",
  "lblInvalidPMName": "Invalid Property Manager Name",
  "lblInvalidQuestion": "The password retrieval question provided is invalid. Please check the value and try again.",
  "lblInvalidstartManagementDate": "Invalid Start Management Date",
  "lblInvalidUserName": "The user name provided is invalid. Please check the value and try again.",
  "lblInverstorDistribution": "Investor Distribution",
  "lblInvestmentStructure": "Investment Structure",
  "lblInvestorContributions": "Investor Contributions",
  "lblInvestorDistribution": "Investor Distribution",
  "lblInvestorLevelNAV": "Investor-level NAV",
  "lblInvestorNavAndReturns": "INVESTOR NAV AND RETURNS",
  "lblInvestorReturn": "Investor Return",
  "lblInvestors": "Investors",
  "lblInvestorShareOfNAV": "Investor Share of NAV (€)",
  "lblInvoicingAmount": "Amount Invoiced (€)",
  "lblInvoicingBankAccount": "Invoicing Bank Account",
  "lblIsBuildingNeutralized": "The property is marqued as neutralized if the last import date is included in one of the neutralized periods of the property",
  "lblIsLookThroughEntity": "Look-through Entity?",
  "lblIsProviderActive": "Active provider",
  "lblIssuedSharesNumber": "Number of Shares Outstanding",
  "lblJournalFamilies": "Journal Families",
  "lblKeyFigures": "Key Figures",
  "lblLASPage": "LASALLE Interface",
  "lblLastAnnualRent": "Headline Rent (€)",
  "lbllastName": "Last Name",
  "lblLatitude": "Latitude",
  "lblLatitudeIncorrectFormat": "Latitude: Numeric format expected (ex: -18,4587 or 27,5893)",
  "lblLatitudeIncorrectRange": "Latitude: Must comprised be between -90 and +90",
  "lblLaunch": "Would you like to launch the interface immediately ?",
  "lblLaunchValidation": "We start the launch of the interface",
  "lblLaw1948Lease": "Residential Lease (1948-Law)",
  "lblLaw1948LeaseEuro": "Residential Lease (1948-Law) (€)",
  "lblLease": "Lease Code",
  "lblLeaseBreakByReversionPotential": "Lease Breakdown by Reversionary Potential",
  "lblLeaseCount": "Lease Count",
  "lblLeaseEndAnnualRentAmount": "Headline Rent of Leases Expiring",
  "lblLeaseEndCount": "Lease Expiring Count",
  "lblLeaseEndDate": "Lease Expiry",
  "lblLeaseEndRentArea": "Occupied Area of Leases Expiring",
  "lblLeaseEntrepriseSchedule": "Commercial Lease Schedule",
  "lblLeaseExpiring6M": "Lease Expiring in less than 6 Months",
  "lblLeaseExpiryProfileArea": "Lease Expiry Profile (Area)",
  "lblLEaseExpiryProfileHeadRent": "Lease Expiry Profile (Headline Rent)",
  "lblLeaseHeadlineRentExceeding": "If lease's headline rent is exceeding or equal to (€):",
  "lblLeaseMonitoring": "Lease Monitoring Report",
  "lblLeaseNatures": "Lease Nature",
  "lblLeaseNumber": "Lease Count",
  "lblLeasePlural": "leases",
  "lblLeaseRegularChargesLevel": "Data at level",
  "lblLeaseRegularChargesLevelLease": "Lease",
  "lblLeaseRegularChargesLevelUnit": "Unit",
  "lblLeaseRegularChargesStatus": "Include elements",
  "lblLeaseRegularChargesStatusAll": "All",
  "lblLeaseRegularChargesStatusFuture": "Applicable in the future",
  "lblLeaseRegularChargesStatusPast": "Applicable the past",
  "lblLeaseRegularChargesStatusPresent": "Applicable in the present",
  "lblLeaseRenewals": "Lease Renewals (€)",
  "lblLeaseRepository": "Manage Leases Data",
  "lblLeaseRepositoryImportsList": "Import History",
  "lblLeaseSingular": "lease",
  "lblLeaseStartDate": "Lease Start Date",
  "lblLeaseTermination": "Lease Terminations (€)",
  "lblLeaseTypes": "Lease Types",
  "lblLegalVehicleStructure": "Legal Vehicle Structure",
  "lblLEMPage": "Lease Monitoring Report",
  "lblLEMRawReport": "Lease Monitoring Report - raw",
  "lblLEMReport": "Lease Monitoring Report",
  "lblLESPage": "Commercial Lease Schedule",
  "lblLESRawReport": "Commercial Lease Schedule - raw",
  "lblLESReport": "Commercial Lease Schedule",
  "lblLevel": "Level",
  "lblLevels": "Levels",
  "lblLikeForLikeCapitalDecrease": "Like-for-Like Capital Decrease (€)",
  "lblLikeForLikeCapitalGrowth": "Like-for-Like Capital Growth (€)",
  "lblLinkGED": "Hello  Following the request you just made on Alhambra, here is the link to the documents:",
  "lblLoanAccountAlreadyExists": "The shareholder loan account has already been used",
  "lblLoanToValue": "Loan-To-Value (LTV)",
  "lblLocationConcentration": "Location Concentration (Top 5 Net Market Value)",
  "lblLocationConcentrationRentArea": "Location Concentration (Top 5 Rentable Area)",
  "lblLogIn": "Log in",
  "lblLoginError": "Login or Password Error",
  "lblLoginForm": "Login Form",
  "lblLoginLastAttemptWarning": "Another wrong password and your account will be closed",
  "lblLoginName": "Login",
  "lblLoginRequired": "Login required",
  "lblLoginTooShort": "The login must have at least {0} characters",
  "lblLoginWithLocalAccount": "Log in with a local account",
  "lblLongitude": "Longitude",
  "lblLongitudeIncorrectFormat": "Longitude: Numeric format expected (ex: -118,4587 or 127,5893)",
  "lblLongitudeIncorrectRange": "Longitude: Must be comprised between -180 and +180",
  "lblLRCBillingItems": "Billing items interface",
  "lblMAFPage": "Manage theme",
  "lblManageAdditionalFieldList": "Manage themes",
  "lblManageDataMapping": "Manage Input Data Mapping",
  "lblManageInterfaceList": "Manage Interfaces",
  "lblManageInterfaceMapping": "Manage Output Data Mapping",
  "lblManageSpecificInterfaceList": "Manage specific interfaces",
  "lblManagingDirector": "Managing Director",
  "lblMarketToMarketFinancialInstruments": "MtM Financial Instruments (€)",
  "lblMarketValue": "Manage ERVs",
  "lblMaxCharLength": "Maximum Character Length",
  "lblMCOPropertiesIdFKML": "Medicine Chirurgy Obstetrics (Île-de-France)",
  "lblMDMPage": "Manage Input Data Mapping",
  "lblMIMPage": "Manage Output Data Mapping",
  "lblMITPage": "Manage Interfaces",
  "lblMixed": "Mixed",
  "lblMixedEuro": "Mixed (€)",
  "lblMixedLease": "Mixed Lease",
  "lblMixedLeaseEuro": "Mixed Lease (€)",
  "lblModifyColumns": "Select Columns",
  "lblMonday": "Monday",
  "lblMsgNbColsDashboard": "You can't select more than 4 KPIs",
  "lblMSIPage": "Manage Specific Interfaces",
  "lblMsqErrorPassword": "The password must contain at least {0} characters including an upper case letter, a lower case letter, a number and a special character !*@#$%^+=",
  "lblMultiPurpose": "Multi-purpose",
  "lblMyAccount": "My Account",
  "lblMyAssets": "My Portfolio",
  "lblNameOfTemplate": "Custom Report Name",
  "lblNameRequiredError": "Last Name is required",
  "lblNature": "Nature",
  "lblNaturesOfOccupancy": "Do not select any property…",
  "lblNaturesOfOccupancyTitle": "Do not select any property…",
  "lblNe": "Net",
  "lblNegativeRentReviews": "Negative Rent Reviews (€)",
  "lblNet": "Net Initial Yields",
  "lblNetAssetVal": "Net Asset Value (€)",
  "lblNetAssetValEquitFairValBridge": "Net Asset Value and Equity at Fair Value Bridge",
  "lblNetAssetValEquitFairValEvol": "Net Asset Value and Equity at Fair Value Evolution",
  "lblNetAssetValPerSharEquitFairValPerSharEvol": "Net Asset Value per Share and Equity at Fair Value per Share Evolution",
  "lblNetAssetValue": "Net Asset Value (NAV)",
  "lblNetAssetValueEquitySecurities": "Net Asset Value of Equity Securities (€)",
  "lblNetAssetValueFund": "Net Asset Value (NAV) (€)",
  "lblNetAssetValuePerShare": "Net Asset Value per Share (€)",
  "lblNetAssetValueUnderlyingVehicles": "Net Asset Value of Underlying Vehicles (€)",
  "lblNetBookValue": "Manage Net Book Values",
  "lblNetBookValueEquitySecurities": "Net Book Value of Equity Securities (€)",
  "lblNetBookValueFund": "Net Book Value (€)",
  "lblNetBookValueImportsList": "Import History",
  "lblNetBookValueUnderlyingVehicles": "Net Book Value of Underlying Vehicles (€)",
  "lblNetCapitalizationRateEvol": "Net Capitalization Rate Evolution",
  "lblNetChangeInCurrentAccount": "Net Change in Current Account (€)",
  "lblNetChangeInShareCapital": "Net Change in Share Capital (€)",
  "lblNetChangeInSharePremium": "Net Change in Share Premium (€)",
  "lblNetEffectiveRent_WOU": "Net Operating Income (NOI)",
  "lblNetHeadlineRent_WOU": "Net Operating Income (NOI) excluding Free Rent",
  "lblNetInitialYieldEvol": "Net Initial Yield Evolution",
  "lblNetMarketRent_WOU": "Fair Market Rent",
  "lblNetMarketValBreakByGeo": "Net Market Value Breakdown by Geography",
  "lblNetMarketValBreakByPropType": "Net Market Value Breakdown by Property Type",
  "lblNetMarketValue": "Net Market Value (€)",
  "lblNetMarketValueAsAt": "Net Market Value as at DD/MM/YYYY (€)",
  "lblNetMarketValueBridge": "Net Market Value Bridge",
  "lblNetOperatingIncome": "Net Operating Income (€)",
  "lblNetOperatingIncomeBridge": "Net Operating Income Bridge",
  "lblNetPotentialRent_WOU": "Net Potential Rent",
  "lblNetRentalIncomeEvol": "Net Rental Income Evolution",
  "lblNetResult": "Net Result (€)",
  "lblNetResultAfterTax": "Net Result After Tax (€)",
  "lblNetResultBeforeTax": "Net Result Before Tax (€)",
  "lblNetResultBridge": "Net Result Bridge",
  "lblNetResultReturnOnGrossBookOutSessionValue": "Net Result Return excl. Disposals (on Gross Book Value)",
  "lblNetResultReturnOnGrossBookValue": "Net Result Return (on Gross Book Value)",
  "lblNetResultTax": "Tax (€)",
  "lblNetReversionaryYield_WOU": "Net Reversionary Yield",
  "lblNeutEndDateInNextPeriod": "The property neutralization end date is set after or is part of the next period !",
  "lblNeutralizationEndDateEmpty": "The property neutralization end date is empty !",
  "lblNeutralizationEndDateNotADate": "The property neutralization end date is not a valid date !",
  "lblNeutralizationStartDateEmpty": "The property neutralization start date is empty !",
  "lblNeutralizationStartDateNotADate": "The property neutralization start date is not a valid date !",
  "lblNeutralizationType": "Neutralization",
  "lblNeutStartDateGreaterThanNeutEndDate": "The property neutralization start date is set after the neutralization end date !",
  "lblNeutStartDateInPreviousPeriod": "The property neutralization start date is set before or is part of the previous period !",
  "lblNeutStartDateLowerThanStartManagementDate": "The property neutralization start date is lower than the start management date !",
  "lblNewBuildingRepository": "Add a New Property in the Repository",
  "lblNewERVImport": "Manage ERVs",
  "lblNewFund": "New Fund Entity",
  "lblNewFundOwnershipImport": "Manage Investor Data",
  "lblNewLeaseRepositoryImport": "Manage Leases Data",
  "lblNewNetBookValueImport": "Manage Net Book Values",
  "lblNewPassword": "New Password",
  "lblNewPasswordRequired": "New Password required",
  "lblNewPurchase": "New Acquisition",
  "lblNewSale": "New Disposal",
  "lblNewTemplate": "Custom Report",
  "lblNewUserMailBodyEnd": "Best regards,  The Alhambra Team",
  "lblNewUserMailBodyMiddle": "You can log in using this link:",
  "lblNewUserMailBodyMiddle1": "- Password:",
  "lblNewUserMailBodyMiddle2": "You can log in using this link:",
  "lblNewUserMailBodyStart": "Hello,  Please find below your login informations for Alhambra:  - Login:",
  "lblNewUserMailBodyStart1": "Hello,  Please find below your login information for Alhambra:  - Login:",
  "lblNewUserMailBodyStart2": "Hello,  Please find below your password information for Alhambra:  - Temporary password:",
  "lblNewUserMailSubject": "ALHAMBRA - New User",
  "lblNewValuationImport": "Manage Valuation Data",
  "lblNewYieldImport": "Manage Target Yields",
  "lblNextLeaseBreakDate": "Next Lease Break Date",
  "lblNextMonths": "month(s)",
  "lblNo": "No",
  "lblNoBuildingProviderSelected": "No property or provider selected. Please select one or more properties or providers.",
  "lblNoBuildingSelected": "No property selected. Please select one or more properties.",
  "lblNoColumn": "Please select one or more columns.",
  "lblNoCompanySelected": "No vehicle selected. Please select one or more vehicles.",
  "lblNoData": "No Data",
  "lblNoDataCharts": "No data available for this chart",
  "lblNoDatasetSelected": "No Dataset Selected",
  "lblNoFileSelected": "No File selected",
  "lblNoInvestorSelected": "No investor selected. Please select one or more investor.",
  "lblNoNewTranscodingType": "You cannot create a new data type.",
  "lblNoNewTypeXLinesRejected": "You cannot create a new data type. {0} lines have been rejected.",
  "lblNoRssArticles": "No Articles found",
  "lblNoTemplate": "No Template",
  "lblNumberBuildings": "Number of Properties",
  "lblNumberLeaseRenewals": "Lease Renewals Count",
  "lblNumberLeases": "Lease Count",
  "lblNumberLeasesTerminated": "Terminated Lease Count",
  "lblNumberLeaseTerminationsNotified": "Notice Count",
  "lblNumberOfBuildingsUnderManagementByBuildingType": "Number of Buildings Under Management by Building Type",
  "lblNumberOfHousings": "Number of Housings",
  "lblNumberOfInterfaces": "Number of Linked Data Streams",
  "lblNumberOfOwnedFunds": "Number of Owned Fund Entities",
  "lblNumberOfSharesUnallocated": "Number of Shares Unallocated (calculated)",
  "lblNumberOfSharesUnallocatedIncorrect": "The number of outstanding shares or the numbers of held shares are incorrect",
  "lblNumberRelettings": "Re-letting Count",
  "lblNumberRents": "Rent Count",
  "lblNumberTenants": "Tenant Count",
  "lblOccupancy": "Occupancy",
  "lblOccupiedAreaBreakBySector": "Occupied Area Breakdown by Sector",
  "lblOccupiedAreaErv": "Occupied Area ERV (€/year)",
  "lblOfficeRetailOutlet": "Office / Retail",
  "lblOfficeRetailOutletEuro": "Office / Retail (€)",
  "lblOffices": "Office",
  "lblOPBPage": "Outstanding Balance and Amount Received Evolution",
  "lblOPBRawReport": "Outstanding Balance and Amount Received Evolution - raw",
  "lblOPBReport": "Outstanding Balance and Amount Received Evolution",
  "lblOperatingExpenses": "Operating Expenses (€)",
  "lblOperatingProperties": "Operating Properties",
  "lblOperatingPropertiesSqm": "Operating Real Estate (m²)",
  "lblOperatingRealEstate": "Operating Real Estate (ERE + RRE)",
  "lblOperatingResult": "Operating Result (€)",
  "lblOperatingReturnOnGrossBookValue": "Operating Return (on Gross Book Value)",
  "lblOPMPage": "Accounting Date Outstanding Payments",
  "lblOPMRawReport": "Accounting Date Outstanding Payments - raw",
  "lblOPMReport": "Accounting Date Outstanding Payments",
  "lblOPPBULRawReport": "Property aging schedule by provider and invoice Report - raw",
  "lblOPPBULReport": "Property aging schedule by provider and invoice Report",
  "lblOPPPage": "Provider aging schedule",
  "lblOPPPRORawReport": "Provider aging schedule by property and invoice - raw",
  "lblOPPPROReport": "Provider aging schedule by property and invoice Report",
  "lblOPPRawReport": "Property aging schedule by provider Report - raw",
  "lblOPPReport": "Property aging schedule by provider Report",
  "lblOther": "Other",
  "lblOtherBreakMonths": "Highlight the Line in red when a Lease 'Other' is due to expire (Break Option) within",
  "lblOtherEuro": "Other (€)",
  "lblOtherIncome": "Other Income (€)",
  "lblOtherLease": "Other Lease",
  "lblOtherLeaseendMonths": "Highlight the Line in red when a Lease 'Other' is due to expire (Lease Expiry) within",
  "lblOtherLeasePlural": "Other Leases",
  "lblOtherLeaseSingular": "Other Lease",
  "lblOUTCOTRawReport": "Outstanding Payments - raw",
  "lblOUTCOTReport": "Outstanding Payments",
  "lblOUTINVRawReport": "Aging Schedule Details by Invoice - raw",
  "lblOUTINVRCIRawReport": "Aging Schedule Details by Invoice and Billing Item - raw",
  "lblOUTINVRCIReport": "Aging Schedule Details by Invoice and Billing Item",
  "lblOUTINVReport": "Aging Schedule Details by Invoice",
  "lblOUTLEARCIRawReport": "Aging Schedule Details by Lease and Billing Item - raw",
  "lblOUTLEARCIReport": "Aging Schedule Details by Lease and Billing Item",
  "lblOUTPage": "Aging Schedule",
  "lblOUTRawReport": "Aging Schedule Details by Lease - raw",
  "lblOUTReport": "Aging Schedule Details by Lease",
  "lblOutstandingDebt": "Outstanding Debt (€)",
  "lblOutstandingPayment": "Aging Schedule",
  "lblOutstandingPayments": "Aging Schedule Details by Lease and Billing Item",
  "lblOutstandingPayProvider": "Provider aging schedule",
  "lblOutstandPayInvoice": "Aging Schedule Details by Invoice",
  "lblOutstandPayInvoiceBillItem": "Aging Schedule Details by Invoice and Billing Item",
  "lblOutstandPaymentOfThePeriod": "Outstanding Payment of the Period (€)",
  "lblOutstandPaymentRateOfThePeriod": "Outstanding Payment Rate",
  "lblOutstandPayMonitoring": "Accounting Date Outstanding Payments",
  "lblOutstandPayProvider": "Provider aging schedule",
  "lblOutstandPaySummary": "Aging Schedule Details by Lease",
  "lblOutstdingRateAmountBalance": "Outstanding Balance / Amount Received Ratio Evolution",
  "lblOverPaymentAmount": "Overpayments Amount",
  "lblOverRented": "Over-rented",
  "lblOwnedBuildings": "PROPERTIES IN PORTFOLIO",
  "lblOwners": "Property Owners",
  "lblParams": "Parameters",
  "lblParamTranscoding": "ParamTranscoding",
  "lblPassRecoveryLinkTimeCheck": "Recovery Link expired",
  "lblPassword": "Password",
  "lblPasswordAlreadyUsed": "Password already used",
  "lblPasswordChanged": "Your password has been successfully changed",
  "lblPasswordChangedMailBodyEnd": "Best regards,  The Alhambra team",
  "lblPasswordChangedMailBodyStart": "Hello,  Your new password for Alhambra is :",
  "lblPasswordChangedMailSubject": "ALHAMBRA - Password successfully changed",
  "lblPasswordChangeError": "Unable to change password. Either the old password is incorrect or the new password isn't at least 8 characters long and doesn't have at least one alphabetical character, one number and one special character.",
  "lblPasswordCreateError": "Incorrect password. The password must be at least 8 characters long and have at least one alphabetical character, one number and one special character.",
  "lblPasswordExpired": "Your password has expired and must be changed.",
  "lblPasswordMustAtLeasrEightCharLong": "Your password must be at least eight characters long",
  "lblPasswordMustContainDigit": "Your password must contain a digit »",
  "lblPasswordMustContainSpecialChar": "Your password must contain a special character",
  "lblPasswordRequired": "Password required",
  "lblPATPage": "Pending Arbitrage Report",
  "lblPATRawReport": "Pending Arbitrages Report - raw",
  "lblPATReport": "Pending Arbitrage Report",
  "lblPatrimony": "Real Estate Portfolio",
  "lblPaymentAmount": "Payment Amount",
  "lblPBAPage": "Property Balance",
  "lblPBARawReport": "Property Balance - raw",
  "lblPBAReport": "Property Balance",
  "lblPendingArbitrageTransactions": "Pending Disposal Report",
  "lblPendingPurchaseTransactions": "Pending Acquisition Report",
  "lblPercent": "%",
  "lblPercentOfTotalOutstandingPayment": "% of Total Outstanding Payment",
  "lblPerfImmo": "Performance",
  "lblPeriod1": "Upper limit Term 1",
  "lblPeriod2": "Upper limit Term 2",
  "lblPeriod3": "Upper limit Term 3",
  "lblPeriod4": "Upper limit Term 4",
  "lblPeriod5": "Upper limit Term 5",
  "lblPhysicalOccRate": "Physical Occupancy Rate",
  "lblPhysicalOccupancySummary": "Occupancy Summary (Physical)",
  "lblPhysicalOccupancySummaryEvolution": "Occupancy Summary Evolution (Physical)",
  "lblPhysicalVacancy": "Physical Vacancy Rate",
  "lblPhysicalVacRate": "Physical Vacancy Rate",
  "lblPICPage": "Recorded provider invoices",
  "lblPICRawReport": "Recorded provider invoices - raw",
  "lblPICReport": "Recorded provider invoices",
  "lblPIPPage": "Paid provider invoices",
  "lblPIPRawReport": "Paid provider invoices - raw",
  "lblPIPReport": "Paid provider invoices",
  "lblPleaseWait": "Please wait...",
  "lblPMBuildingCode": "Property Manager's Property Code",
  "lblPMRPage": "PM dashboard",
  "lblPMRReport": "PM dashboard",
  "lblPMRReportEREBuildingType": "Commercial Real Estate",
  "lblPMRReportEREBuildingTypeSqm": "Commercial Real Estate (m²)",
  "lblPMRReportRREBuildingType": "Residential Real Estate",
  "lblPMRReportRREBuildingTypeSqm": "Residential Real Estate (m²)",
  "lblPOBPage": "RE Portfolio Breakdown Report",
  "lblPOBRawReport": "RE Portfolio Breakdown Report - raw",
  "lblPOBReport": "RE Portfolio Breakdown Report",
  "lblPortfolio": "Portfolio",
  "lblPortfolioBreakByGeography": "Portfolio Breakdown by Geography",
  "lblPortfolioBreakByMarketGeo": "Portfolio Breakdown by Market Geography",
  "lblPortfolioBreakByPropertyType": "Portfolio Breakdown by Property Type",
  "lblPortfolioBreakByTenant": "Portfolio Breakdown by Tenant",
  "lblPortfolioBreakByTenantIndustry": "Portfolio Breakdown by Tenant Industry",
  "lblPortfolioBreakdown": "RE Portfolio Breakdown Report",
  "lblPortfolioBreakdownByBuildingNature": "Breakdown by Property Type",
  "lblPortfolioBreakdownByBuildingType": "Breakdown by Property Class",
  "lblPortfolioCode": "Portfolio Code",
  "lblPortfolioCode_required": "Portfolio Code required",
  "lblPortfolioEvolution": "RE Portfolio Evolution",
  "lblPortfolioLabel": "EN_PORTFOLIO_LABEL",
  "lblPortfolioName": "Portfolio Name",
  "lblPortfolioNameDe": "Portfolio Name (German)",
  "lblPortfolioNameDe_required": "Portfolio Name (German) required",
  "lblPortfolioNameEn": "Portfolio Name",
  "lblPortfolioNameEn_required": "Portfolio Name (English) required",
  "lblPortfolioNameFr": "Portfolio Name (French)",
  "lblPortfolioNameFr_required": "Portfolio Name (French) required",
  "lblPortfolios": "Portfolios",
  "lblPortfolioSize": "Portfolio Size",
  "lblPortfolioYieldsAndReturns": "Portfolio-level Yields and Returns",
  "lblPositiveRentReviews": "Positive Rent Reviews (€)",
  "lblPostalCode": "Postal Code",
  "lblPotentialInitYield": "Potential Initial Yield",
  "lblPotentialNetInitialYield_YOU": "Potential Net Initial Yield",
  "lblPPTPage": "Pending Acquisition Report",
  "lblPPTRawReport": "Pending Acquisition Report - raw",
  "lblPPTReport": "Pending Acquisition Report",
  "lblPreferredPortfolio": "Favorite Portfolio",
  "lblPreviousDate": "The start date must be before the end date, {0} rejected lines.",
  "lblPreviousFundUpdateDeleted": "The update of your fund entity [...] has been successfully deleted.",
  "lblPreviouslyUpdatedOn": "Previously Updated on",
  "lblPreviousNeutEndDateNotSet": "The property neutralization end date is not set for the previous period !",
  "lblProfessional": "Professional",
  "lblProfessionalEuro": "Professional (€)",
  "lblProfessionalLease": "Professional Lease",
  "lblProfessionalLeaseEuro": "Professional Lease (€)",
  "lblProfile": "Profile",
  "lblProperty": "Property",
  "lblPropertyAcquisitions": "Property Acquisitions (€)",
  "lblPropertyAreaExceeding": "If property's total area is exceeding or equal to (m²):",
  "lblPropertyBalance": "Property Balance",
  "lblPropertyBalanceSummary": "Property Balance Summary",
  "lblPropertyBreakByGeo": "Property Breakdown by Geography",
  "lblPropertyBreakByPropertyType": "Property Breakdown by Property Type",
  "lblPropertyDisposals": "Property Disposals (€)",
  "lblPropertyDistribution": "Property Distribution by Income Return and Capital Return",
  "lblPropertyManagementActivities": "Property Management Activities",
  "lblPropertyManager": "Property Manager",
  "lblPropertyManagerCode": "Property Manager Code",
  "lblPropertyManagerCode_required": "Property Manager Code required",
  "lblPropertyManagerName": "Property Manager Name",
  "lblPropertyManagerName_required": "Property Manager Name required",
  "lblPropertyNetMarketValueExceeding": "If the Net Market Value of the Property is exceeding or equal to (€):",
  "lblPropertyValuation": "Property Impairment Report",
  "lblProviderError": "The authentication provider returned an error. Please verify your entry and try again. If the problem persists, please contact your system administrator.",
  "lblProviderInvoices": "Providers invoices",
  "lblProviderInvoicesAccounted": "Recorded provider invoices",
  "lblProviderInvoicesPaid": "Paid provider invoices",
  "lblPurchase": "Manage Acquisitions",
  "lblPurchaseAgreementDate": "Sale Agreement Date",
  "lblPurchaseHistory": "Acquisition History Record",
  "lblPurchaseManagement": "Manage Acquisitions",
  "lblPurchaseSearch": "Find a Newly Acquired Property",
  "lblPurchaseTitle": "Pending Acquisitions",
  "lblPurchaseType": "Acquisition Type",
  "lblPVAPage": "Property Impairment Report",
  "lblPVARawReport": "Property Impairment Report - raw",
  "lblPVAReport": "Property Impairment Report",
  "lblPwdExists": "You cannot reuse an old password",
  "lblPwdExpired": "Your password has expired, please click on the \"Change password\" link to renew it",
  "lblPwdRecorded": "Password recorded",
  "lblPwdRecoveryLegend": "PASSWORD RECOVERY",
  "LblPwdSpecialChar": "The password must contain at least {0} characters including an upper case letter, a lower case letter, a number and a special character !*@#$%^+=",
  "lblRackRented": "Rack-rented",
  "lblRangeErrorYield": "Target Yield must be comprised between 0 and 1",
  "lblRaw": "Gross",
  "lblRealEstatePortfolio": "Real Estate Portfolio",
  "lblRebillings": "Rebillings (€)",
  "lblReceiveNotification": "Receive a notification when:",
  "lblReconciliationSettlement": "OPEX/CAM Reconciliation/Settlement (€)",
  "lblReferenceYear": "Reference Year",
  "lblRegularCharges": "Billing Items",
  "lblRegularChargesCode": "Billing Item Code",
  "lblRegularChargesExpenses": "Operating Expenses to Exclude",
  "lblRegularChargesExpensesExcluded": "Operating Expenses to Exclude",
  "lblRegularChargesLabel": "Billing Item",
  "lblRegularChargesOtherIncomes": "Other Incomes to Exclude",
  "lblRegularChargesOtherIncomesExcluded": "Other Incomes to Exclude",
  "lblRegularChargesRents": "Rents to Exclude",
  "lblRegularChargesRentsExcluded": "Rents to Exclude",
  "lblRegularChargesWorks": "Works to Exclude",
  "lblRegularChargesWorksExcluded": "Works to Exclude",
  "lblRelettings": "Re-lettings (€)",
  "lblReloadExplanations": "The Reload data section allows the end user to manually trigger:<BR/>  - a partial reload of data taking into account manually imported data (valuation data, estimated rental values, net book values)<BR/>  - a complete reload of data taking into account changes in the Property Repository (property type, portfolio, market segmentation, etc.) or changes in leases data (litigations).<BR/>  <BR/>  Reloading data may temporarily affect application performance (application slow down, failure to export sizeable reports). It is therefore recommended to trigger partial or complete reload during off-peak periods or to give user advance notice of any scheduled data reload.",
  "lblReloadPage": "Reload last data",
  "lblRELPage": "Reload last data",
  "lblRememberPassword": "Remember the password?",
  "lblRentalIncome": "Rental Income",
  "lblRentalIncomeMonitoring": "Rental Income Monitoring Report",
  "lblRentalIncomeMonitoringCompare": "Year-to-Year Invoicing Comparison Report",
  "lblRentalIncomeMonitoringDetailed": "Year-to-Date Invoicing Report",
  "lblRentalSituation": "Detailed Rental Situation",
  "lblRentalVacantArea": "Vacant Area Available for Letting (m²)",
  "lblRentalVacantAreaRate": "% of Total Vacancy",
  "lblRentDecreasedPlural": "Rents have decreased",
  "lblRentDecreasedSingular": "Rent has decreased",
  "lblRentedArea": "Occupied Area (m²)",
  "lblRentedArea_WOU": "Occupied Area",
  "lblRentEvolution1Y": "Rent Evolution on 1 Year",
  "lblRentIncreasedPlural": "Rents have increased",
  "lblRentIncreasedSingular": "Rent has increased",
  "lblRents": "Headline Rent (€/year)",
  "lblRents_WOU": "Gross Headline Rent",
  "lblRentVariationRate": "Rent Uplift",
  "lblReportGenerationFailed": "Alhambra - Report Generation Failed : {0}",
  "lblReportGenerationFailedBody": "Hello,      Unfortunately the generation of your report {0} failed.  Please retry a little later.      Best regards,    The Alhambra Team",
  "lblReportInterfaceSequencesHistory": "Sendings history of the report interface",
  "lblReportLaunchInBackgroundMessage": "Once your download is ready, an email will be sent with the download link to the email address listed in your Alhambra account!",
  "lblReportName": "Associated Standard Report",
  "lblReportReadyToDownload": "Alhambra - Report ready for download: {0}",
  "lblReportReadyToDownloadBody": "Hello,    In order to download your report :    1) Please make sure Alhambra is still open  2) Copy-paste the following link in your browser's  address bar :  {0}    Beware: Once downloaded, the report will be deleted from the server.    Best regards, The Alhambra Team",
  "lblReportTemplateManagement": "Manage Custom Reports",
  "lblReportTemplateSaved": "Your custom report [...] has been successfully saved and added to the list of available reports. It can be updated or deleted in the Manage Custom Reports menu.",
  "lblReportTemplateUpdated": "Your custom report [...] has been successfully updated.",
  "lblRepositoryArbitrageSaved": "Your acquisition [...] has been successfully saved.",
  "lblRepositoryArbitrageUpdated": "Your acquisition [...] has been successfully updated.",
  "lblRepositoryBuildingReport": "Property Repository",
  "lblRepositoryBuildingSaved": "Your property [...] has been successfully saved.",
  "lblRepositoryBuildingUpdated": "Your property [...] has been successfully updated.",
  "lblRepositoryFundReport": "Fund Repository",
  "lblRepositoryListing": "Property Repository",
  "lblRepositoryPurchaseSaved": "Your acquisition [...] has been successfully saved.",
  "lblRepositoryPurchaseUpdated": "Your acquisition [...] has been successfully updated.",
  "lblResidential": "Residential",
  "lblResidentialBreakMonths": "Highlight the Line in red when a Residential Lease is due to expire (Break Option) within",
  "lblResidentialCareIdFKML": "Residential Care (Île-de-France)",
  "lblResidentialEuro": "Residential (€)",
  "lblResidentialLease": "Residential Lease (1989-Law)",
  "lblResidentialLeaseendMonths": "Highlight the Line in red when a Residential Lease is due to expire (Lease Expiry) within",
  "lblResidentialLeaseEuro": "Residential Lease (1989-Law) (€)",
  "lblResidentialLeasePlural": "Residential Leases",
  "lblResidentialLeaseSingular": "Residential Lease",
  "lblRESPage": "Detailed Rental Situation",
  "lblRESRawReport": "Detailed Rental Situation - raw",
  "lblRESReport": "Detailed Rental Situation",
  "lblRestartEmailBody": "Hello,      this is the last day of the mailing interruption.      Sendings from the interface will resume tomorrow.      Sincerely,      The Alhambra team",
  "lblRestartEmailSubject": "Alhambra - Notice of resumption of shipments",
  "lblResults": "Results",
  "lblRetailBreakMonths": "Highlight the Line in red when a Commercial Lease is due to expire (Break Option) within",
  "lblRetailLease": "Commercial Lease (€)",
  "lblRetailLeaseendMonths": "Highlight the Line in red when a Commercial Lease is due to expire (Lease Expiry) within",
  "lblRetailOutlets": "Retail",
  "lblReturnOnEquityLevered": "Return on Equity (levered)",
  "lblReturnOnEquityUnlevered": "Return on Equity (unlevered)",
  "lblReversionaryYield": "Reversionary Yield",
  "lblRIMDetailledRawReport": "Year-to-Date Invoicing Report - raw",
  "lblRIMDetailledReport": "Year-to-Date Invoicing Report",
  "lblRIMPage": "Rental Income Monitoring Report",
  "lblRIMRawReport": "Year-to-Year Invoicing Comparison Report - raw",
  "lblRIMReport": "Year-to-Year Invoicing Comparison Report",
  "lblRisk": "Risks",
  "lblRLEPage": "Manage Leases Data",
  "lblRotationRate": "Tenant Turnover Rate",
  "lblRPAPage": "Manage Disposals",
  "lblRPBPage": "Property Repository",
  "lblRPFPage": "Fund Repository",
  "lblRPMPage": "Manage ERVs",
  "lblRPNPage": "Manage Net Book Values",
  "lblRPPPage": "Manage Acquisitions",
  "lblRPTPage": "Manage Custom Reports",
  "lblRPVPage": "Manage Valuation Data",
  "lblRPYPage": "Manage Yields",
  "lblRRENonOperating": "Residential Real Estate (Non-Operating)",
  "lblRunningTotalOfRentIncomByCollectionPeriod": "Running Total of Rental Income by Collection Period",
  "lblSales": "Manage Disposals",
  "lblSaleTitle": "Pending Disposals",
  "lblSaleVacantArea": "Vacant Area for Disposal Purposes (m²)",
  "lblSaleVacantAreaRate": "% of Total Vacancy",
  "lblSamePassword": "The new password can't be the same as the current password",
  "lblSaturday": "Saturday",
  "lblSDGPage": "Security Deposits Guarantees Report",
  "lblSDGRawReport": "Security Deposits Guarantees Report - raw",
  "lblSDGReport": "Security Deposits Guarantees Report",
  "lblSeachInTheGridColumns": "Search for the following content in the tab below (text only)",
  "lblSector": "Market Geography",
  "lblSectorCode": "Market Geography Code",
  "lblSectorCode_required": "Market Geography Code required",
  "lblSectorConcentration": "Sector Concentration (Top 5 Net Market Value)",
  "lblSectorConentrationRentArea": "Sector Concentration (Top 5 Rentable Area)",
  "lblSectorErv": "Real Estate Market Geography (required for ERV Processing)",
  "lblSectorLabel": "EN_SECTOR_LABEL",
  "lblSectorNameDe": "Market Geography Name (German)",
  "lblSectorNameDe_required": "Market Geography Name (German) required",
  "lblSectorNameEn": "Market Geography Name (English)",
  "lblSectorNameEn_required": "Market Geography Name (English) required",
  "lblSectorNameFr": "Market Geography Name (French)",
  "lblSectorNameFr_required": "Market Geography Name (French) required",
  "lblSecuritDepositsGuarantees": "Security Deposits Guarantees",
  "lblSecurityDepositBankAccount": "Security Deposit Bank Account",
  "lblSecurityDepositsGuarantees": "Security Deposits Guarantees",
  "lblSeeBuilding": "Go To Property Summary",
  "lblSelect4Indicators": "Select up to 4 KPIs",
  "lblSelectAccount": "Select an Account",
  "lblSelectACode": "Select a Code",
  "lblSelectActivesLeases": "Current Only",
  "lblSelectAdvancePayAmount": "Overpayments Amount from (€):",
  "lblSelectAllLeases": "Current and Terminated",
  "lblSelectAMapping": "No mapping",
  "lblSelectAreaSup": "Occupied Area from (m²):",
  "lblSelectBaseReport": "Select a standard report",
  "lblSelectedParams": "Selected Criteria",
  "lblSelectedRaport": "You must select a report",
  "lblSelectEffectiveNetInitYield": "Effective Net Initial Yield from (%):",
  "lblSelectHeadlineNetInitYield": "Headline Net Initial Yield (%):",
  "lblSelectInactivesLeases": "Terminated Only",
  "lblSelectInLitigationLeases": "Under Litigation Only",
  "lblSelectKML": "Display Additional Data",
  "lblSelectLeaseActiveAll": "Current and Terminated with or without Outstanding Payments",
  "lblSelectLeaseActiveWithOutstandingPayments": "Current with Outstanding Payments",
  "lblSelectLeaseAllWithOutstandingPayments": "Current and Terminated with Outstanding Payments",
  "lblSelectLeaseDate": "Display Leases",
  "lblSelectLeaseDateBreak": "With a Break Option",
  "lblSelectLeaseDateEnd": "Expiring only",
  "lblSelectLeaseInactiveWithOutstandingPayments": "Terminated with Outstanding Payments",
  "lblSelectLeaseInLitigation": "Under Litigation with Outstanding Payments",
  "lblSelectLeaseWithoutTenantExitDate": "With Tenant not Moving-out",
  "lblSelectLeaseWithTenantExitDate": "With Tenant Moving-out",
  "lblSelectOneYear": "Selected period can't be more then one year",
  "lblSelectPaymentsAmount": "Outstanding Payment Amount from (K€):",
  "lblSelectPaymentsMonths": "Outstanding Payment Period from (months):",
  "lblSelectPortfolio": "Select a portfolio",
  "lblSelectPortfolios": "Select Portfolios",
  "lblSelectRawReportTemplateColumns": "Select the columns to be displayed and drag-and-drop to change their order",
  "lblSelectRentAmountSup": "Headline Rent from (€):",
  "lblSelectRentVariationRate": "Rent Uplift from (%):",
  "lblSelectReport": "Select Report",
  "lblSelectReportTemplateColumns": "Select the columns to be displayed",
  "lblSelectTwoYear": "Selected period can't be more then two year",
  "lblSelectVacancyAreaSup": "Vacant Area from (m²):",
  "lblSelectVacancyMonths": "Vacancy Duration from (Months):",
  "lblSelectVacancyRateSup": "Physical Vacancy Rate from (%):",
  "lblSeller": "Seller",
  "lblSendGED": "You will soon receive an email with the links to the EDM",
  "lblServiceCharge": "Service Charges (€)",
  "lblShareAcquisitionPrice": "Share Acquisition Price (€)",
  "lblShareCapital": "Share Capital (€)",
  "lblShareholderCurrentAccount": "Shareholder Current Account (if applicable)",
  "lblShareholderCurrentAccountEquityFinancing": "Shareholder Current Account (€)",
  "lblShareholderLoanAccount": "Shareholder Loan Account (if applicable)",
  "lblShareholderLoansContributed": "Shareholder Loans Contributed (€)",
  "lblSharePremium": "Share Premium (€)",
  "lblSharePremiumDistributed": "Share Premium Distributed (€)",
  "lblShareWithOtherUsers": "Share With Other Users",
  "lblShortTermLease": "Short-term Lease",
  "lblShortTermLeaseEuro": "Short-term Lease (€)",
  "lblShowFilters": "Display Filters",
  "lblShowGraph": "Display Header",
  "lblShowList": "Display Property List",
  "lblShowMap": "Display Header",
  "lblShowParams": "Display Parameters",
  "lblSigningDate": "Date Signed of Conveyancing Document",
  "lblSimplifiedPAndL": "Simplified P&L",
  "lblSocialAccountsType": "Social",
  "lblSourceCode": "Source Code",
  "lblSourceLabel": "Source Label",
  "lblSqm": "m²",
  "lblStartDate": "From",
  "lblStartDateEmpty": "Empty start date, {0} lignes rejetées.",
  "lblStatus": "Status",
  "lblStatutoryInterests": "Statutory Interests (€)",
  "lblStopSendingBetween": "to",
  "lblStopSendingEnd": "included",
  "lblStopSendingStart": "Stop sending from",
  "lblStorageDistribution": "Logistics",
  "lblStudentHousingIdFKML": "Student Housing since 2004 (Île-de-France)",
  "lblSubsidiaryOfGroup": "Subsidiary of the group",
  "lblSubsidiaryOfGroup_required": "Group required",
  "lblSunday": "Sunday",
  "lblSupThreeMonth": "> 3 Months",
  "lblTax": "Tax",
  "lblTaxeType": "Display of Amounts",
  "lblTaxeTypeHT": "Before tax",
  "lblTaxeTypeTTC": "Tax Included",
  "lblTEMPage": "Tenancy Monitoring Report",
  "lblTemplateName": "Enter a name for the custom report",
  "lblTemplateNameAlreadyExists": "A Template by this Name already exists",
  "lblTemplateNameRequired": "Please enter a name",
  "lblTemporaryOccupancyAuthorisation": "Temporary Occupancy Authorisation",
  "lblTemporaryOccupancyAuthorisationEuro": "Temporary Occupancy Autorisation (€)",
  "lblTempPwd": "Temporary code",
  "lblTempPwdExpired": "Temporary code expired",
  "lblTempPwdRecorded": "Password recorded",
  "lblTempPwdRequired": "Please provide the temporary code",
  "lblTempPwdWrongFormat": "Wrong temporary code",
  "lblTEMRawReport": "Tenancy Monitoring Report - raw",
  "lblTEMReport": "Tenancy Monitoring Report",
  "lblTenancyAtWillLease": "Tenancy-at-will Lease",
  "lblTenancyAtWillLeaseEuro": "Tenancy-at-will Lease (€)",
  "lblTenancyMonitoring": "Tenancy Monitoring Report",
  "lblTenancyMonitoringEnd": "Lease Termination Report",
  "lblTenancyMonitoringEndRaw": "Lease Termination Report - Raw",
  "lblTenancyMonitoringNotice": "Lease Termination Notice Report",
  "lblTenancyMonitoringNoticeRaw": "Lease Termination Notice Report - Raw",
  "lblTenancyMonitoringReletting": "Reletting Report",
  "lblTenancyMonitoringRelettingRaw": "Reletting Report - Raw",
  "lblTenancyMonitoringRenewal": "Lease Renewal Report",
  "lblTenancyMonitoringRenewalRaw": "Lease Renewal Report - Raw",
  "lblTenancySchedule": "Tenancy Schedule",
  "lblTenancyScheduleAlerts": "Tenancy Schedule Alerts",
  "lblTenant": "Tenant Name",
  "lblTenantConcentration": "Tenant Concentration (Top 5 Headline Rent)",
  "lblTenantConentrationRentArea": "Tenant Concentration (Top 5 Occupied Area)",
  "lblTenantExitAnnualRentAmount": "Headline Rent of Leases with Tenant Moving-out",
  "lblTenantExitCount": "Tenant Move-out Count",
  "lblTenantExitRentArea": "Occupied Area of Leases with Tenant Moving-out",
  "lblTenantIndustryConcentration": "Tenant Industry Concentration (Top 5 Headline Rent)",
  "lblTenantIndustryConecentrationRentArea": "Tenant Industry Concentration (Top 5 Occupied Area)",
  "lblTenantTurnover": "Tenant Churn Report",
  "lblTerminatedLeasesLegend": "* Terminated Leases",
  "lblTerminationDate": "Termination or Disposal Date",
  "lblTETPage": "Tenant Churn Report",
  "lblTETRawReport": "Tenant Churn Report - raw",
  "lblTETReport": "Tenant Churn Report",
  "lblThemeBadElement": "The theme {2} is not relevant to the element {1 {0} rejected lines.",
  "lblThemeBadLevel": "The theme « {1} » ist on level {2 {0} rejected lines.",
  "lblThemeNotExist": "The Theme « {1} » does not exist, {0} rejected lines.",
  "lblThursday": "Thursday",
  "lblTotalArea": "Total Rentable Area (m²)",
  "lblTotalArea_WOU": "Total Rentable Area",
  "lblTotalCashDistributed": "Total Cash Distributed (€)",
  "lblTotalDataReloading": "The data of property manager {0} will be reloaded this night at {1}.",
  "lblTotalEquityContributed": "Total Equity Contributed (€)",
  "lblTotalEquityFinancing": "Total Equity Financing (€)",
  "lblTotalGrossDebt": "Total Gross Debt (€)",
  "lblTotalInverstorDistributions": "Total Investor Distributions (€)",
  "lblTotalInvestmentPrice": "Total Investment Price (€)",
  "lblTotalInvestmentValue": "Total Investment Value (€)",
  "lblTotalInvestorDistributions": "Total Investor Distributions (€)",
  "lblTotalRentAreaBreakByGeo": "Rentable Area Breakdown by Geography",
  "lblTotalRentAreaBreakByPropType": "Rentable Area Breakdown by Property Type",
  "lblTotalRentAreaBreakByTenant": "Total Rentable Area Breakdown by Tenant",
  "lblTotalRentAreaBreakByTenantIndustry": "Occupied Area Breakdown by Tenant",
  "lblTotalReturn": "Total Return",
  "lblTotalReturnEquityFairValue": "Total Return on Equity at Fair Value",
  "lblTotalReturnNAVBridge": "Total Return on NAV",
  "lblTotals": "Total",
  "lblTransactionsHistory": "Transaction History Report",
  "lblTranscoding_type_data": "Type of data to be set",
  "lblTranscodingType": "List Code",
  "lblTRHArbRawReport": "Disposal History Record - raw",
  "lblTRHArbReport": "Disposal History Record",
  "lblTRHPage": "Transaction History Report",
  "lblTRHPurchRawReport": "Acquisition History Record - raw",
  "lblTRHPurchReport": "Acquisition History Record",
  "lblTrue": "VRAI",
  "lblTSBuildingRawReport": "Tenancy Schedule by Property - raw",
  "lblTSBuildingReport": "Tenancy Schedule by Property",
  "lblTSLeaseRawReport": "Tenancy Schedule by Lease - raw",
  "lblTSLeaseReport": "Tenancy Schedule by Lease",
  "lblTSMixedRawReport": "Tenancy Schedule Mixed - raw",
  "lblTSMixedReport": "Tenancy Schedule Mixed",
  "lblTSPage": "Tenancy Schedule",
  "lblTSUnitDetailedRawReport": "Tenancy Schedule Detailed by Rental Unit - raw",
  "lblTSUnitDetailedReport": "Tenancy Schedule Detailed by Rental Unit",
  "lblTSUnitRawReport": "Tenancy Schedule by Rental Unit - raw",
  "lblTSUnitReport": "Tenancy Schedule by Rental Unit",
  "lblTSUnitTypeRawReport": "Tenancy Schedule by Rental Unit Type - raw",
  "lblTSUnitTypeReport": "Tenancy Schedule by Rental Unit Type",
  "lblTuesday": "Tuesday",
  "lblTypeInterface": "Type interface",
  "lblUnderlyingVehicles": "Underlying Vehicles",
  "lblUnderRented": "Under-rented",
  "lblUnit": "Unit",
  "lblUnitNatures": "Rental Unit Types",
  "lblUnitNumber": "Rental Unit Count",
  "lblUnitTypes": "Unit Type",
  "lblUnlawfulOccupancy": "Unlawful Occupancy",
  "lblUnlawfulOccupancyEuro": "Unlawful Occupancy (€)",
  "lblUnrealCapitalGainLossDirectInvestProp": "Unrealized Capital Gain/Loss on Direct Investment Properties (€)",
  "lblUnrealCapitalGainLossEquitySecur": "Unrealized Capital Gain/Loss on Equity Securities (€)",
  "lblUnrealCapitalGainLossFinancialInstruments": "Unrealized Capital Gain/Loss on Financial Instruments (€)",
  "lblUnrealizedGainsLossesOnShares": "Unrealized Gains and Losses on Shares (€)",
  "lblUnrealizedInvestmentPropertyGainLoss": "Unrealized Investment Property Gain/Loss (€)",
  "lblUnrealizedNonPropertyGainLoss": "Unrealized Non-Property Gain/Loss (€)",
  "lblUpcomingLeaseEvents": "Upcoming Lease Events",
  "lblUpdateAdditionalField": "Theme update",
  "lblUpdateBuilding": "Update Property",
  "lblUpdateBuildingRepository": "Update a Property in the Repository",
  "lblUpdatedAt": "Date Updated",
  "lblUpdatedAt2": "Date Updated",
  "lblUpdateErvError": "Error while updating Estimated Rental Values",
  "lblUpdateErvOk": "ERV successfully updated",
  "lblUpdateFund": "Update Fund Entity",
  "lblUpdateFundOwnershipError": "Error while updating investor data",
  "lblUpdateLeaseRepositoryError": "Error while updating leases value-related data",
  "lblUpdateNetBookValueError": "Error while updating net book value-related data",
  "lblUpdatePurchase": "Update Acquisition",
  "lblUpdateReportTemplate": "Update Custom Report",
  "lblUpdateSales": "Update Disposal",
  "lblUpdateUser": "Update User",
  "lblUpdateValuationError": "Error while updating valuation value-related data",
  "lblUpdateValuationOk": "Valuation Data successfully updated",
  "lblUpdateYieldError": "Error while updating Expected Gross Reversionary Yields",
  "lblUpdateYieldOk": "Target Yields successfully updated",
  "lblUserLocked": "Locked",
  "lblUserLogin": "Login",
  "lblUserMail": "Mail",
  "lblUserManagement": "Manage Users",
  "lblUserName": "User Name",
  "lblUserNameRequired": "User Name required",
  "lblUserRejected": "The user creation request has been canceled. Please verify your entry and try again. If the problem persists, please contact your system administrator.",
  "lblUSLPage": "Manage Users",
  "lblVACAnaRawReport": "Occupancy Summary - raw",
  "lblVACAnaReport": "Occupancy Summary",
  "lblVacancy": "Vacancy Monitoring Report",
  "lblVacancyAnalysis": "Occupancy Summary",
  "lblVacancyArea": "Vacant Area",
  "lblVacancyBreakByPeriod": "Vacancy Breakdown by Period",
  "lblVacancyBreakdownByPeriod": "Vacancy Breakdown by Period",
  "lblVacancyCost": "Financial Vacancy",
  "lblVacancyEvolution": "Quarterly Occupancy Evolution",
  "lblVacancyEvolutionOneYear": "Vacancy Evolution over 1 year",
  "lblVacancyFinancial": "€",
  "lblVacancyNature": "Vacancy Breakdown by Type",
  "lblVacancyOccupEvolution": "Vacancy Breakdown by Vacancy Reason",
  "lblVacancyPhysicalPercent": "%",
  "lblVacancyPhysicalSqm": "m²",
  "lblVacancyRate": "Physical Vacancy Rate",
  "lblVacancyTypes": "Vacancy Type",
  "lblVacancyUnit": "Vacant Unit Count",
  "lblVacantArea": "Vacant Area (m²)",
  "lblVacantArea_WOU": "Vacant Area",
  "lblVacantArea2M": "Vacant Area <= 2 Months (m²)",
  "lblVacantArea4M": "Vacant Area <= 4 Months (m²)",
  "lblVacantAreaSup4M": "Vacant Area > 4 Months (m²)",
  "lblVacantTenantCode": "--VACANT--",
  "lblVacantUnits": "Vacant Unit Summary",
  "lblVacantUnitSingular": "Vacant Rental Unit",
  "lblVacantUnitsLegend": "* Vacant or inactive rental units",
  "lblVacantUnitsPlural": "Vacant Rental Units",
  "lblVacArea2-4M": "Vacant Area 2-4 Months (m²)",
  "lblVacArea2M": "Vacant Area < 2 Months (m²)",
  "lblVacArea4M": "Vacant Area > 4 Months (m²)",
  "lblVACNatRawReport": "Vacancy Breakdown by Type - raw",
  "lblVACNatReport": "Vacancy Breakdown by Type",
  "lblVACPage": "Vacancy Monitoring Report",
  "lblVACPhyRawReport": "Monthly Monitoring Report of Vacancy - raw",
  "lblVACPhyReport": "Monthly Monitoring Report of Vacancy",
  "lblValuation": "Manage Valuation Data",
  "lblValuationDashboard": "Fund Performance",
  "lblValuationImportsList": "Import history",
  "lblValue": "Net Market Value (€)",
  "lblValueDate": "Value Date",
  "lblValuePurchase": "Gross Acquisition Value (€)",
  "lblVAUPage": "Vacant Unit Summary",
  "lblVAURawReport": "Vacant Unit Summary - raw",
  "lblVAUReport": "Vacant Unit Summary",
  "lblVehicleNetAssetValue": "VEHICLE NET ASSET VALUE (100%)",
  "lblVehicleTerm": "Vehicle Term (in years)",
  "lblVehiculeFormationDate": "Vehicle Formation Date (if applicable)",
  "lblWALB": "WALB",
  "lblWALBAndWALT": "WALB / WALT",
  "lblWALBInYears": "WALB (in years)",
  "lblWALT": "WALT",
  "lblWALTInYears": "WALT (in years)",
  "lblWednesday": "Wednesday",
  "lblWelcomeTitle": "Welcome to Alhambra",
  "lblWorksVacantArea": "Vacant Area for Works Purposes (m²)",
  "lblWorksVacantAreaRate": "% of Total Vacancy",
  "lblWrongCurrentPassword": "The current password is not correct",
  "lblXLinesInserted": "{0} lines have been added.",
  "lblXLinesRejected": "{0} lines have been rejected.",
  "lblXLinesUpdated": "{0} lines have been updated.",
  "lblXValuesInserted": "{0} values have been added.",
  "lblXValuesRejected": "{0} values have been rejected.",
  "lblYearPeriod1": "Year(s) (Reference Year 1)",
  "lblYearPeriod2": "Year(s) (Reference Year 2)",
  "lblYearPeriod3": "Year(s) (Reference Year 3)",
  "lblYearToDateInvoicingRunningTotal": "Year-to-Date Invoicing Running Total",
  "lblYes": "Yes",
  "lblYield": "Manage Yields",
  "lblYieldImportsList": "Import History",
  "lblYieldMonitoring": "Yield Monitoring Report",
  "lblYIMPage": "Yield Monitoring Report",
  "lblYIMRawReport": "Yield Monitoring Report - raw",
  "lblYIMReport": "Yield Monitoring Report",
  "lblyyyyMMdd": "YYYYMMDD",
  "lblyyyyMMdd_hhmmss": "YYYYMMDD_HHMMSS",
  "lblZACIdFKML": "Special Planning Districts (ZAC)",
  "lbNewAccountStream": "New Accounting Stream",
  "lbNewAdditionalField": "New theme",
  "lbnumeric": "numeric",
  "lbOccupiedAreaBreakByTenant": "Occupied Area Breakdown by Tenant",
  "lbOutstandingAmountEvolution": "Outstanding amount evolution",
  "lbOutstandingBalanceEvolution": "Outstanding balance evolution",
  "lbPage": "Page",
  "lbPositional": "Positional",
  "lbPurchaseConfirmCode": "Please confirm the building code",
  "lbReportTemplateDuplicated": "Your report model [...] has been copied in [...]",
  "lbSearchCriteria": "Search Criteria",
  "lbSeparatorDecimal": "Decimal Separator",
  "lbSpace": "Space",
  "lbTotalDistribution": "Total Distribution",
  "lbTtResetCriteria": "Reset Criteria",
  "lbTtUpdateCriteria": "Update Criteria",
  "LEASE": "Lease",
  "LEASE BREAK DATE 1": "Lease Break Date 1",
  "LEASE BREAK DATE 2": "Lease Break Date 2",
  "LEASE BREAK DATE 3": "Lease Break Date 3",
  "LEASE BREAK PERIOD 1": "Notice Period 1 (Months)",
  "LEASE BREAK PERIOD 2": "Notice Period 2 (Months)",
  "LEASE BREAK PERIOD 3": "Notice Period 3 (Months)",
  "LEASE BREAKDOWN BY NATURE": "Lease Breakdown by Type",
  "LEASE CODE": "Lease Code",
  "LEASE COMMENTS": "Comments",
  "LEASE COMMITMENT TERM": "Lease Commitment Term",
  "LEASE DURATION DD/MM/YYYY": "Lease Term (y-m-d)",
  "LEASE END DATE": "Lease Expiry",
  "LEASE ENTERPRISE SCHEDULE": "Commercial Lease Schedule as at",
  "LEASE FLOORS": "Floor Number(s)",
  "LEASE MONITORING": "Lease Monitoring Report as at",
  "LEASE MONITORING SHEET": "Lease Monitoring",
  "LEASE NATURE LABEL": "Lease Nature",
  "LEASE PAYMENT MODE LABEL": "Payment mode",
  "LEASE RENEWAL REPORT": "Lease Renewal Report",
  "LEASE RENEWAL REPORT RAW": "Lease Renewal Report Raw",
  "LEASE SIGNED DATE": "Lease Signed Date",
  "LEASE START DATE": "Lease Start Date",
  "LEASE SUMMARY": "Lease Summary",
  "LEASE TENANCY SCHEDULE": "Tenancy Schedule by Lease as at",
  "LEASE TENANCY SCHEDULE SHEET": "Tenancy Schedule by Lease",
  "LEASE TERM": "Lease Term (y-m-d)",
  "LEASE TERMINATION NOTICE REPORT": "Lease Termination Notice Report",
  "LEASE TERMINATION NOTICE REPORT RAW": "Lease Termination Notice Report Raw",
  "LEASE TERMINATION REPORT": "Lease Termination Report",
  "LEASE TERMINATION REPORT RAW": "Lease Termination Report Raw",
  "LEASE TOTAL": "Total Lease",
  "LEASE TURNOVER RATE": "Tenant Churn Rate (Leases)",
  "LEASE TYPE LABEL": "Lease Type",
  "LEASE UNITS": "Rental Unit Code(s)",
  "LEASE_BREAK_DATE_1": "Lease Break Date 1",
  "LEASE_BREAK_DATE_2": "Lease Break Date 2",
  "LEASE_BREAK_DATE_3": "Lease Break Date 3",
  "LEASE_BREAK_PERIOD_1": "Notice Period 1 (Months)",
  "LEASE_BREAK_PERIOD_2": "Notice Period 2 (Months)",
  "LEASE_BREAK_PERIOD_3": "Notice Period 3 (Months)",
  "LEASE_CODE": "Lease Code",
  "LEASE_COMMENTS": "Comments",
  "LEASE_COMMITMENT_TERM": "Lease Commitment Term",
  "LEASE_DURATION": "Lease Term (y-m-d)",
  "LEASE_END_DATE": "Lease Expiry",
  "LEASE_FLOORS": "Floor Number(s)",
  "LEASE_NATURE_CODE": "Lease Nature Code",
  "LEASE_NATURE_LABEL": "Lease Nature",
  "EN_LABEL": "Payment method",
  "LEASE_SIGNED_DATE": "Lease Signed Date",
  "LEASE_START_DATE": "Lease Start Date",
  "LEASE_TURNOVER_RATE": "Tenant Churn Rate (Leases)",
  "LEASE_TYPE_CODE": "Lease Type Code",
  "LEASE_TYPE_LABEL": "Lease Type",
  "LEASE_UNITS": "Rental Unit Code(s)",
  "LEASES NUMBER": "Lease Count",
  "LEASES PERCENT": "% of Portfolio Total Lease Count",
  "LEASES_ACTIVE": "Active Leases",
  "LEASES_COUNT": "Lease Count",
  "LEASES_INACTIVE": "Inactive Leases",
  "LEASES_LITIGATION": "Leases under Litigation",
  "LEASES_NUMBER": "Lease Count",
  "LEASES_PERCENT": "% of Portfolio Total Lease Number",
  "LEGAL PERSON": "Legal Person",
  "LEGAL RESERVES": "Legal Reserves (€)",
  "LEGAL VEHICLE STRUCTURE": "Legal Vehicle Structure",
  "LEGAL_PERSON": "Legal Person",
  "LEGAL_RESERVES": "Legal Reserves (€)",
  "LEGAL_VEHICLE_STRUCTURE": "Legal Vehicle Structure",
  "LEVEL": "Level",
  "LEVEL1 BUDGET SECTION": "Budget Line",
  "LEVEL1 BUDGET SECTION BRUT": "Budget Level 1",
  "LEVEL1 BUDGET SECTION CODE": "Budget Line Code",
  "LEVEL1_BUDGET_SECTION": "Budget Item",
  "LEVEL1_BUDGET_SECTION_CODE": "Budget Item Code",
  "LEVEL2 BUDGET SECTION": "Budget Level 2",
  "LEVEL2 BUDGET SECTION 1": "Budget label level 2",
  "LEVEL2 BUDGET SECTION CODE": "Budget Category",
  "LEVEL2 BUDGET SECTION CODE 1": "Budget code level 2",
  "LEVEL2_BUDGET_SECTION": "Budget Level 2",
  "LEVEL2_BUDGET_SECTION_1": "Budget label level 2",
  "LEVEL2_BUDGET_SECTION_CODE": "Budget Category Code",
  "LEVEL2_BUDGET_SECTION_CODE_1": "Budget code level 2",
  "LEVEL3 BUDGET SECTION": "Budget Level 3",
  "LEVEL3 BUDGET SECTION 1": "Budget label level 3",
  "LEVEL3 BUDGET SECTION CODE": "Budget Level 3 Code",
  "LEVEL3_BUDGET_SECTION": "Budget Level 3",
  "LEVEL3_BUDGET_SECTION_1": "Budget label level 3",
  "LEVEL3_BUDGET_SECTION_CODE": "Budget Level 3 Code",
  "LEVEL4 BUDGET SECTION": "Budget Level 4",
  "LEVEL4 BUDGET SECTION CODE": "Budget Level 4 Code",
  "LEVEL4_BUDGET_SECTION": "Budget Level 4",
  "LEVEL4_BUDGET_SECTION_CODE": "Budget Level 4 Code",
  "liAbe": "Bank Movements",
  "liAllBuildings": "-- All The Properties --",
  "Line2H3Title1": "Property Overview",
  "Line3H3Title1": "Occupancy Summary",
  "Line3H3Title2": "Occupancy Breakdown by Use",
  "Line4H3Title2": "Operating Expenses (OPEX)",
  "Line5H3Title1": "KPIs",
  "Line5H3Title2": "Net Initial Yields",
  "Line5H3Title3": "Reversionary Potential",
  "Line7H3Title1": "Rental Income Evolution",
  "Line7H3Title2": "OPEX Evolution",
  "Line8H3Title1": "Upcoming Lease Events",
  "Line8H3Title2": "Refurbishment & Maintenance Monitoring",
  "liNeutralizedBuildings": "Neutralized Properties",
  "linkChangePwd": "Change password",
  "linkForgottenPwd": "Forgot your password ?",
  "liNotNeutralizedBuildings": "Not Neutralized Properties",
  "LITIGATION AMOUNT": "Litigation Amount (€)",
  "LITIGATION INFORMATION": "Litigation Comments",
  "LITIGATION SITUATION LABEL": "litigation situation",
  "LITIGATION STATUS LABEL": "Litigation Status",
  "LITIGATION TYPE LABEL": "Litigation Type",
  "LITIGATION_AMOUNT": "Litigation Amount (€)",
  "LITIGATION_INFORMATION": "Litigation Comments",
  "LITIGATION_SITUATION_LABEL": "litigation situation",
  "LITIGATION_STATUS_LABEL": "Litigation Status",
  "LITIGATION_TYPE_LABEL": "Litigation Type",
  "LIVING AREA": "Net Living Area (m²)",
  "LIVING AREA WOU": "Net Living Area",
  "LIVING_AREA_SQM": "Net Living Area (m²)",
  "lkb1Year": "1 year",
  "lkb3Year": "3 years",
  "lkb6Year": "6 years",
  "lkbDeSelectAll": "Deselect All",
  "lkbEditPortfolio": "Edit Portfolios",
  "lkbModAlerts": "Modify Alerts",
  "lkbSelectAll": "Select All",
  "lnkClear": "Clear Filters",
  "lnLoginNameFormatString": "{0}",
  "LOAN TO VALUE": "Loan-To-Value (LTV)",
  "locAlertBuildingValueVariation": "significantly following the Latest Valuation",
  "locAlertLeaseBreak": "with a Break Option coming soon",
  "locAlertLeaseExpiring": "expiring soon",
  "locAlertRentVariationInitialRent": "significantly compared to the Initial Rent",
  "locAlertRentVariationRentAdjustment": "significantly following the latest Rent Review",
  "locAlerts": "Alerts",
  "locAlertVacancy": "with a Significant Vacancy Rate",
  "locAlertVacantUnit": "for several months",
  "locEvolution": "Evolution over the last",
  "locKeyValues": "Portfolio Overview as at",
  "locMyAssets": "My Portfolio",
  "locNews": "News",
  "locSeeMore": "See More",
  "LONGITUDE": "Longitude",
  "LOSS AFTER PDDI": "URGL after Depreciation Charge (€)",
  "LOSS AFTER RESERVE": "URGL after Impairment (€)",
  "LOSS BEFORE RESERVE": "URGL before Impairment (€)",
  "LOSS_AFTER_PDDI": "URGL after Depreciation Charge (€)",
  "LOSS_AFTER_RESERVE": "URGL after Impairment (€)",
  "LOSS_BEFORE_RESERVE": "URGL before Impairment (€)",
  "lsLoginStatusLogoutText": "Log out",
  "MAIN DEPOSIT VALUE": "Security Deposit (€)",
  "MAIN MANAGER NAME": "Property Manager Name",
  "MAIN UNIT CODE": "Main Rental Unit Code",
  "MAIN UNIT TOTAL NUMBER": "Rental Unit Count",
  "MAIN UNIT TYPE LABEL": "Main Rental Unit Type",
  "MAIN_DEPOSIT_VALUE": "Security Deposit (€)",
  "MAIN_MANAGER_NAME": "Property Manager Name",
  "MAIN_UNIT": "Main Rental Unit Code",
  "MAIN_UNIT_BUILDING": "Building Number",
  "MAIN_UNIT_FLOOR": "Floor Number",
  "MAIN_UNIT_NATURE_CODE": "Main Rental Unit Nature Code",
  "MAIN_UNIT_NATURE_LABEL": "Main Rental Unit Nature",
  "MAIN_UNIT_STAIRCASE": "Staircase Number",
  "MAIN_UNIT_TOTAL_NUMBER": "Total Number of Units",
  "MAIN_UNIT_TOTAL_NUMBER_mismatch": "Total Number of Units mismatch",
  "MAIN_UNIT_TYPE_CODE": "Main Rental Unit Type Code",
  "MAIN_UNIT_TYPE_LABEL": "Main Rental Unit Type",
  "MAJOR REFURBISHMENT AMOUNT": "Major refurbishment amount",
  "MAJOR_REFURBISHMENT_AMOUNT": "Major refurbishment amount (€)",
  "MANAGER NAME": "Manager name",
  "MANAGER_NAME": "Manager name",
  "MANAGING DIRECTOR": "Managing Director",
  "MANAGING_DIRECTOR": "Managing Director",
  "MARCH_AMOUNT": "March (€)",
  "MARK_VALUE_EXCL_DUTIES_VAT_SQM": "Net Market Value by m² (€/m²)",
  "MARKET VALUE": "Market Value",
  "MARKET VALUE EXCL DUTIES AND FEES": "Net Market Value",
  "MARKET VALUE EXCL DUTIES VAT": "Net Market Value (€)",
  "MARKET VALUE INCL DUTIES AND FEES": "Gross Market Value",
  "MARKET_VALUE_EXCL_DUTIES_VAT": "Net Market Value (€)",
  "MAX_NUMBER_OF_LEVELS": "Number of Stories",
  "MAX_NUMBER_OF_LEVELS_mismatch": "Number of Stories mismatch",
  "MAXIMUM NUMBER OF FLOORS": "Number of Stories",
  "MAXIMUM NUMBER OF LEVELS": "Number of Stories",
  "MAY_AMOUNT": "May (€)",
  "menuAbe": "Bank Movements",
  "menuAuditTrail": "Audit Trail Report",
  "menuBudgetMonitoring": "Budget Monitoring Report",
  "menuCollectionMonitoring": "Rental Income Collection Report",
  "menuDeka": "Deka reporting",
  "menuDirectOutstandingPayments": "Outstanding payments",
  "menuDtz": "Rental Situation",
  "menuGeneralExpensesReportAccounted": "Statement of Expenditure - Recorded Invoices",
  "menuGeneralExpensesReportPaid": "Statement of Expenditure - Paid Invoices",
  "menuGER": "Statement of Expenditure Report",
  "menuHoldoverTenancy": "Holdover Tenancy Report",
  "menuLasalleInterface": "LASALLE Interface",
  "menuLeaseEntrepriseSchedule": "Commercial Lease Schedule",
  "menuLeaseMonitoring": "Lease Monitoring Report",
  "menuMyAccount": "My Account",
  "menuMyAssets": "My Portfolio",
  "menuMyReports": "My Reports",
  "menuOutstandingPaymentProvider": "Provider Aging Schedule",
  "menuOutstandingPayments": "Aging Schedule",
  "menuOutstandPayBalances": "Outstanding Balance and Amount Received Evolution",
  "menuOutstandPayMonitoring": "Accounting Date Outstanding Payments",
  "MenuParam": "Settings",
  "menuPendingArbitrageTransactions": "Pending Arbitrage Report",
  "menuPendingPurchaseTransactions": "Pending Acquisition Report",
  "menuPendingTransactions": "Pending Transaction Report",
  "menuPMR": "PM dashboard",
  "menuPortfolioBreakdown": "RE Portfolio Breakdown Report",
  "menuPortfolioEvolution": "RE Portfolio Evolution Report",
  "menuPropertyBalance": "Property Balance",
  "menuPropertyValuation": "Property Impairment Report",
  "menuProviderInvoices": "Providers Invoices",
  "menuProviderInvoicesAccounted": "Recorded Provider Invoices",
  "menuProviderInvoicesPaid": "Paid Provider Invoices",
  "menuReload": "Reload last data",
  "menuRentalIncomeMonitoring": "Rental Income Monitoring Report",
  "menuRentalSituation": "Detailed Rental Situation",
  "menuSecuritDepositsGuarantees": "Security Deposits Guarantees",
  "menuSecurityDepositsGuarantees": "Security Deposits Guarantees",
  "menuTenancyMonitoring": "Tenancy Monitoring Report",
  "menuTenancySchedule": "Tenancy Schedule",
  "menuTenantTurnover": "Tenant Churn Report",
  "menuTransactionsHistory": "Transaction History Report",
  "menuUsersList": "Manage Users",
  "menuVacancyAnalysis": "Vacancy Monitoring Report",
  "menuVacantUnits": "Vacant Unit Summary",
  "menuYieldMonitoring": "Yield Monitoring Report",
  "MGMT FEES OF GARB COLLECT TAX": "Garbage Collection Tax Management Fees (€)",
  "MGMT_FEES_OF_GARB_COLLECT_TAX": "Garbage Collection Tax Management Fees (€)",
  "MinDateHistory": "No booking entry prior to",
  "MISCELLANEOUS AREA": "Miscellaneous Area (m²)",
  "MISCELLANEOUS RESERVE": "Miscellaneous Reserve (€)",
  "MISCELLANEOUS_AREA": "Miscellaneous Area (m²)",
  "MISCELLANEOUS_RESERVE": "Miscellaneous Reserve (€)",
  "Modification": "Modification",
  "MONTANT_DEJA_ENGAGE": "Montant déjà engagé (€)",
  "MONTANT_DEJA_FACTURE_REALISE": "Montant déjà facturé / réalisé",
  "MONTANT_PREVISIONNEL_DES_ENGAGEMENTS_EMIS_PAR_ANNEE": "Montant engagé",
  "MONTANT_PREVISIONNEL_DES_FACTURES_A_RECEVOIR_PAR_ANNEE": "Montant facturé",
  "MONTANT_TOTAL_PREVISIONNEL_CHANTIER": "Montant total prévisionnel chantier (€)",
  "MONTH ON MONTH SALES GROWTH": "Month-on-Month Sales Growth",
  "MONTH ROLLING SALES VOLUME": "12-Month Rolling Sales Volume (€)",
  "MONTH_01_AMOUNT": "1st Column Amount (€)",
  "MONTH_01_YYYY": "January YYYY (€)",
  "MONTH_02_AMOUNT": "2nd Column Amount (€)",
  "MONTH_02_YYYY": "February YYYY (€)",
  "MONTH_03_AMOUNT": "3rd Column Amount (€)",
  "MONTH_03_YYYY": "March YYYY (€)",
  "MONTH_04_AMOUNT": "4th Column Amount (€)",
  "MONTH_04_YYYY": "April YYYY (€)",
  "MONTH_05_AMOUNT": "5th Column Amount (€)",
  "MONTH_05_YYYY": "May YYYY (€)",
  "MONTH_06_AMOUNT": "6th Column Amount (€)",
  "MONTH_06_YYYY": "June YYYY (€)",
  "MONTH_07_AMOUNT": "7th Column Amount (€)",
  "MONTH_07_YYYY": "July YYYY (€)",
  "MONTH_08_AMOUNT": "8th Column Amount (€)",
  "MONTH_08_YYYY": "August YYYY (€)",
  "MONTH_09_AMOUNT": "9th Column Amount (€)",
  "MONTH_09_YYYY": "September YYYY (€)",
  "MONTH_10_AMOUNT": "10th Column Amount (€)",
  "MONTH_10_YYYY": "October YYYY (€)",
  "MONTH_11_AMOUNT": "11th Column Amount (€)",
  "MONTH_11_YYYY": "November YYYY (€)",
  "MONTH_12_AMOUNT": "12th Column Amount (€)",
  "MONTH_12_YYYY": "December YYYY (€)",
  "MONTH_13_AMOUNT": "13th Column Amount (€)",
  "MONTH_14_AMOUNT": "14th Column Amount (€)",
  "MONTH_15_AMOUNT": "15th Column Amount (€)",
  "MONTH_16_AMOUNT": "16th Column Amount (€)",
  "MONTH_17_AMOUNT": "17th Column Amount (€)",
  "MONTH_18_AMOUNT": "18th Column Amount (€)",
  "MONTH_19_AMOUNT": "19th Column Amount (€)",
  "MONTH_20_AMOUNT": "20th Column Amount (€)",
  "MONTH_21_AMOUNT": "21th Column Amount (€)",
  "MONTH_22_AMOUNT": "22th Column Amount (€)",
  "MONTH_23_AMOUNT": "23th Column Amount (€)",
  "MONTH_24_AMOUNT": "24th Column Amount (€)",
  "MONTH_ON_MONTH_SALES_GROWTH": "Month-on-Month Sales Growth MM/YYYY vs. MM-1/YYYY",
  "MONTH_ROLLING_SALES_VOLUME": "12-Month Rolling Sales Volume (€)",
  "MONTHLY AMOUNT": "Monthly Amount (€/Month)",
  "MONTHLY MONITORING VACANCY": "Monthly Monitoring Report of Vacancy as at",
  "MONTHLY OUTSTAND PAY RATE": "Outstanding Payment Rate of the Month",
  "MONTHLY SALES VOLUME": "Monthly Sales Volume (€/month)",
  "MONTHLY SALES VOLUME M MINUS 1": "Monthly Sales Volume (€/month)",
  "MONTHLY SALES VOLUME Y MINUS 1": "Monthly Sales Volume (€/month)",
  "MONTHLY_OUTSTAND_PAY_RATE": "Outstanding Payment Rate of the Month",
  "MONTHLY_SALES_VOLUME": "Monthly Sales Volume MM/YYYY (€/month)",
  "MONTHLY_SALES_VOLUME_M_MINUS_1": "Monthly Sales Volume MM-1/YYYY (€/month)",
  "MONTHLY_SALES_VOLUME_Y_MINUS_1": "Monthly Sales Volume MM/YYYY-1 (€/month)",
  "MOTIF_DE_L_INTERVENTION_DE_MAINTENANCE": "Motif de l'intervention de maintenance",
  "NameFile": "File name",
  "NameFileAndADB": "File name -PM name",
  "NATURE": "Property Type",
  "NATURE TOTAL": "Total",
  "NB_FLAGGED_RECORDS": "Number of Flagged Records",
  "NET ASSET VALUE": "Net Asset Value (NAV) (€)",
  "NET ASSET VALUE ON SPV AND HOLDCO": "Net Asset Value on SPV and HoldCo (€)",
  "NET ASSET VALUE PER SHARE": "Net Asset Value per Share (€)",
  "NET ASSET VALUE REPORT": "Net Asset Value Report",
  "NET ASSET VALUE TITLE": "Net Asset Value (NAV)",
  "NET ASSET VALUE UNDERLYING VEHICLES": "Net Asset Value of Underlying Vehicles (€)",
  "NET BOOK VALUE": "Net Book Value (€)",
  "NET BOOK VALUE AFTER IMPAIR": "Unrealized Gains and Losses after Impairment (€)",
  "NET BOOK VALUE AFTER PDDI": "Net Book Value after Depreciation Charge (€)",
  "NET BOOK VALUE AFTER RESERVE": "Net Book Value after Impairment (€)",
  "NET BOOK VALUE BEFORE RESERVE": "Net Book Value before Impairment (€)",
  "NET BOOK VALUE ON SPV AND HOLDCO": "Net Book Value on SPV and HoldCo (€)",
  "NET BOOK VALUE UNDERLYING VEHICLES": "Net Book Value of Underlying Vehicles (€)",
  "NET CAPITAL RATE": "Net Capitalization Rates",
  "NET CHANGE IN CURRENT ACCOUNT": "Net Change in Current Account (€)",
  "NET CHANGE IN SHARE CAPITAL": "Net Change in Share Capital (€)",
  "NET CHANGE IN SHARE PREMIUM": "Net Change in Share Premium (€)",
  "NET EFFECTIVE RENT": "Net Operating Income (NOI) (€/year)",
  "NET EFFECTIVE RENT WOU": "Net Operating Income (NOI)",
  "NET HEADLINE RENT": "Net Operating Income (NOI) excluding Free Rent (€/year)",
  "NET HEADLINE RENT WOU": "Net Operating Income (NOI) excluding Free Rent",
  "NET INCOME": "Net Income (€)",
  "NET INCOME AFTER TAX": "Net Result after CIT (€)",
  "NET INCOME BEFORE TAX": "Net Result before CIT (€)",
  "NET INITIAL YIELD": "Net Initial Yields",
  "NET INITIAL YIELDS": "Net Initial Yields",
  "NET INITIAL YIELDS SHEET": "Net Initial Yields",
  "NET MARKET RENT": "Net Market Rent (€/year)",
  "NET MARKET VALUE": "Net Market Value (€)",
  "NET OPERATING INCOME": "Net Operating Income (NOI) (€/year)",
  "NET POTENTIAL RENT": "Net Potential Rent (€/year)",
  "NET POTENTIAL RENTAL INCOME": "Net Potential Rental Income",
  "NET RENTAL INCOME": "Net Rental Income",
  "NET RESULT": "Net Result (€)",
  "NET RESULT AFTER TAX": "Net Result After Tax (€)",
  "NET RESULT BEFORE TAX": "Net Result Before Tax (€)",
  "NET RESULT RETURN ON GROSS BOOK VALUE": "Net Result Return (on Gross Book Value)",
  "NET RESULT RETURN ON GROSS BOOK VALUE OUT": "Net Result Return excl. Disposals (on Gross Book Value)",
  "NET RESULT TAX": "Tax (€)",
  "NET REVERS CAPITAL RATE": "Net Reversionary Cap Rate",
  "NET REVERS POTENT OCCUP AREA": "On Occupied Area",
  "NET REVERS POTENT OCCUP AREA FULL": "Reversionary Potential (Occupied Area)",
  "NET REVERS POTENT TOTAL PROP": "On Total Property",
  "NET REVERS POTENT TOTAL PROP FULL": "Reversionary Potential (Total Area)",
  "NET REVERSIONARY POTENTIAL": "Reversionary Potential",
  "NET REVERSIONARY YIELD": "Net Reversionary Yield",
  "NET_ASSET_VALUE": "Net Asset Value (NAV) (€)",
  "NET_ASSET_VALUE_ON_SPV": "Net Asset Value on SPV and HoldCo (€)",
  "NET_ASSET_VALUE_ON_SPV_AND_HOLDCO": "Net Asset Value on SPV and HoldCo (€)",
  "NET_ASSET_VALUE_PER_SHARE": "Net Asset Value per Share (€)",
  "NET_ASSET_VALUE_REPORT": "Net Asset Value Report",
  "NET_BOOK_VALUE_AFTER_IMPAIR": "Unrealized Gains and Losses after Impairment (€)",
  "NET_BOOK_VALUE_AFTER_PDDI": "Net Book Value after Depreciation Charge (€)",
  "NET_BOOK_VALUE_AFTER_RESERVE": "Net Book Value after Impairment (€)",
  "NET_BOOK_VALUE_BEFORE_RESERVE": "Net Book Value before Impairment (€)",
  "NET_BOOK_VALUE_ON_SPV": "Net Book Value on SPV and HoldCo (€)",
  "NET_BOOK_VALUE_ON_SPV_AND_HOLDCO": "Net Book Value on SPV and HoldCo (€)",
  "NET_BOOK_VALUE-RELATED_DATA_SUCCESSFULLY_UPDATED": "Net Book Value-related data successfully updated",
  "NET_EFFECTIVE_RENT": "Net Operating Income (NOI) (€/year)",
  "NET_FLOOR_AREA_SQM": "Net Floor Area (NFA) (m²)",
  "NET_HEADLINE_RENT": "Net Operating Income (NOI) excluding Free Rent (€/year)",
  "NET_INCOME": "Net Income (€)",
  "NET_INCOME_AFTER_TAX": "Net Result After Tax (€)",
  "NET_INCOME_BEFORE_TAX": "Net Result before CIT (€)",
  "NET_MARKET_RENT": "Net Market Rent (€/year)",
  "NET_MARKET_RENT_WOU": "Net Market Rent",
  "NET_MARKET_VALUE": "Net Market Value (€)",
  "NET_MARKET_VALUE_SQM": "Net Market Value by m² (€/m²)",
  "NET_MARKET_VALUE_WOU": "Net Market Value",
  "NET_MARKET_VALUE_YYYY": "Net Market Value YYYY-1 (€)",
  "NET_OPERATING_INCOME": "Net Operating Income (NOI) (€/year)",
  "NET_POTENTIAL_RENT": "Net Potential Rent (€/year)",
  "NET_POTENTIAL_RENT_WOU": "Net Potential Rent",
  "NET_REVERS_CAPITAL_RATE": "Net Reversionary Cap Rate",
  "NET_REVERS_POTENT_OCCUP_AREA": "Reversionary Potential (Occupied Area)",
  "NET_REVERS_POTENT_TOTAL_PROP": "Reversionary Potential (Total Area)",
  "NET_REVERSIONARY_YIELD": "Net Reversionary Yield",
  "NEUTRALIZATION": "Neutralization",
  "NEUTRALIZATION END DATE": "Neutralization End Date",
  "NEUTRALIZATION START DATE": "Neutralization Start Date",
  "NEUTRALIZATION_END_DATE": "Neutralization End Date",
  "NEUTRALIZATION_START_DATE": "Neutralization Start Date",
  "NEW LEASES NUMBER": "Re-letting Count",
  "NEW LEASES RATE": "Tenant Move-in Rate (Leases)",
  "NEW UNITS AREA": "Re-let Area (m²)",
  "NEW UNITS AREA RATE": "Tenant Move-in Rate (Area)",
  "NEW UNITS NUMBER": "Re-let Unit Count",
  "NEW UNITS RATE": "Tenant Move-in Rate (Rental Units)",
  "NEW VALUE": "New Value",
  "NEW_LEASES_NUMBER": "Re-letting Count",
  "NEW_LEASES_RATE": "Tenant Move-in Rate (Leases)",
  "NEW_UNITS_AREA": "Re-let Area (m²)",
  "NEW_UNITS_AREA_RATE": "Tenant Move-in Rate (Area)",
  "NEW_UNITS_NUMBER": "Re-let Unit Count",
  "NEW_UNITS_RATE": "Tenant Move-in Rate (Rental Units)",
  "NEW_VALUE": "New Value",
  "NewSendReportExcel": "Sent PDF/Excel reporting again",
  "NEXT INDEX DATE": "Next Indexation Date",
  "NEXT LEASE BREAK DATE": "Break Option",
  "NEXT LEASE HEADLINE RENT": "Next Lease - Headline Rent (€/year)",
  "NEXT LEASE HEADLINE RENT M2": "Next Lease - Headline Rent (€/year/m²)",
  "NEXT REVISION DATE": "Next Rent Review Date",
  "NEXT RISK DATE": "Notice to be received by",
  "NEXT TENANT CODE": "Next Tenant Code",
  "NEXT TENANT MOVE IN DATE": "Next Tenant Move-in Date",
  "NEXT TENANT MOVE OUT DATE": "Next Tenant Move-out Date",
  "NEXT TENANT NAME": "Next Tenant Name",
  "NEXT_INDEX_DATE": "Next Indexation Date",
  "NEXT_INDEX_VALUE": "Next Index Value",
  "NEXT_LEASE_BREAK_DATE": "Next Lease Break Date",
  "NEXT_LEASE_HEADLINE_RENT": "Next Lease - Headline Rent (€/year)",
  "NEXT_LEASE_HEADLINE_RENT_M2": "Next Lease - Headline Rent (€/year/m²)",
  "NEXT_REVISION_DATE": "Next Rent Review Date",
  "NEXT_RISK_DATE": "Notice to be received by",
  "NEXT_TENANT_CODE": "Next Tenant Code",
  "NEXT_TENANT_MOVE_IN_DATE": "Next Tenant Move-in Date",
  "NEXT_TENANT_MOVE_OUT_DATE": "Next Tenant Move-out Date",
  "NEXT_TENANT_NAME": "Next Tenant Name",
  "NIV": "Level",
  "NO": "No",
  "NO DATA": "No Data Available",
  "NOM_BATÎMENT": "Nom bâtiment",
  "NON RECOV OPERATING EXPENSES": "Non Recoverable Operating Expenses (€/year)",
  "NON RECOVERABLE EXPENSES": "Non Recoverable Expenses (€/year)",
  "NON RECOVERABLE EXPENSES WOU": "Non Recoverable Expenses",
  "NON_RECOV_OPERATING_EXPENSES": "Non Recoverable Operating Expenses YYYY-1 (€/year)",
  "NON_RECOVERABLE_EXPENSES": "Non Recoverable Expenses (€/year)",
  "NON_RECOVERABLE_EXPENSES_WOU": "Non Recoverable Expenses",
  "NOT DUE INC TAX AMOUNT": "Amounts Invoiced Not Due (€)",
  "NOT RECOVERABLE": "Not Recoverable",
  "NOT_DUE_INC_TAX_AMOUNT": "Amounts Invoiced Not Due (€)",
  "NOTICE DATE": "Lease Termination Notice Date",
  "NOTICE PERIOD": "Notice Period (Months)",
  "NOTICE TYPE LABEL": "Lease Termination Notice Type",
  "NOTICE_DATE": "Lease Termination Notice Date",
  "NOTICE_PERIOD": "Notice Period (Months)",
  "NOTICE_TYPE_CODE": "Lease Termination Notice Type Code",
  "NOTICE_TYPE_LABEL": "Lease Termination Notice Type",
  "NOUVELLE_REGLE_MISE_EN_CONFORMITE_DE_L_OBJET": "Nouvelle règle de mise en conformité",
  "NOVEMBER_AMOUNT": "November (€)",
  "NULL": "Unknown",
  "NUMBER": "Number",
  "NUMBER OF BUILDINGS": "Property Count",
  "NUMBER OF CAR PARKS": "Car Parks Count",
  "NUMBER OF DOUBLE CAR PARKS": "Double Car Parks Count",
  "NUMBER OF LEASE ENDED": "Lease Expiry",
  "NUMBER OF LEASE IN RISK": "Break Option",
  "NUMBER OF LEASE RENEWALS": "Lease Amendment Count",
  "NUMBER OF LEASES": "Lease Count",
  "NUMBER OF LEASES AT DATE": "Lease Count as at",
  "NUMBER OF OCCUPIED UNITS": "Occupied Unit Count",
  "NUMBER OF OWNERS": "Number of Owners",
  "NUMBER OF PARKING PLACES": "Parking Spaces Count",
  "NUMBER OF PARKING SPACES": "Parking Spaces Count",
  "NUMBER OF PROPERTIES": "Portfolio Evolution (Property Count)",
  "NUMBER OF PROPERTIES BY NATURE": "Portfolio Evolution by Property Type (Property Count)",
  "NUMBER OF ROOMS": "Room Count",
  "NUMBER OF SECOND UNIT PERCENT": "% of Portfolio Total Number of Parking Spaces",
  "NUMBER OF SECONDARY UNIT": "Parking Spaces Count",
  "NUMBER OF SIMPLE CAR PARKS": "Simple Car Parks Count",
  "NUMBER OF TENANTS": "Tenant Count",
  "NUMBER OF UNITS": "Rental Unit Count",
  "NUMBER OF UNITS FOR SALE": "Unit for Sale Count",
  "NUMBER OF VACANT UNITS": "Vacant Unit Count",
  "NUMBER PARKING": "Parking Count",
  "NUMBER_LINE": "Number of lines in the file",
  "NUMBER_OF_BUILDINGS": "Number of Properties",
  "NUMBER_OF_BUILDINGS_mismatch": "Building Count mismatch",
  "NUMBER_OF_DOUBLE_CAR_PARKS": "Double Car Parks Count",
  "NUMBER_OF_LEASE_RENEWALS": "Number of Lease Renewals",
  "NUMBER_OF_LEASES": "Lease Count",
  "NUMBER_OF_LEASES_AT_DATE": "Lease Count as at DD/MM/YYYY",
  "NUMBER_OF_OCCUPIED_UNITS": "Occupied Unit Count",
  "NUMBER_OF_PARKING_PLACES": "Parking Spaces Count",
  "NUMBER_OF_PARKING_SPACES": "Parking Spaces Count",
  "NUMBER_OF_ROOMS": "Room Count",
  "NUMBER_OF_SECOND_UNIT_PERCENT": "% of Portfolio Total Number of Parking Spaces",
  "NUMBER_OF_SECONDARY_UNIT": "Parking Spaces Count",
  "NUMBER_OF_SIMPLE_CAR_PARKS": "Simple Car Parks Count",
  "NUMBER_OF_TENANTS": "Tenant Count",
  "NUMBER_OF_UNITS": "Rental Unit Count",
  "NUMBER_OF_UNITS_FOR_SALE": "Number of Units for Sale",
  "NUMBER_OF_UNITS_FOR_SALE_mismatch": "Unit for Sale Count mismatch",
  "NUMBER_OF_UNITS_FOR_SALE_required": "Unit for Sale Count required",
  "NUMBER_OF_VACANT_UNITS": "Vacant Unit Count",
  "NUMBER_PARKING_SPACES": "Number of Parking Spaces",
  "OBJET": "Objet du chantier",
  "OCCUPANCY": "Occupancy",
  "OCCUPANCY COST RATIO": "Occupancy Cost Ratio",
  "OCCUPANCY SUMMARY": "Occupancy Summary",
  "OCCUPANCY_COST_RATIO": "Occupancy Cost Ratio",
  "OCCUPIED AREA": "Occupied Area (m²)",
  "OCCUPIED AREA SUMMARY": "Occupancy Breakdown by Use",
  "OCCUPIED_AREA_SQM": "Occupied Area (m²)",
  "OCTOBER_AMOUNT": "October (€)",
  "OFFICE AREA": "Office Area (m²)",
  "OFFICE TAX AMOUNT": "Office Tax Amount (€)",
  "OFFICE_AREA": "Office Area (m²)",
  "OFFICE_TAX_AMOUNT": "Office Tax Amount (€)",
  "ON LITIGATION": "On-going Litigation (yes/no)?",
  "ON THE": "as at",
  "ON_LITIGATION": "On-going Litigation (yes/no)?",
  "OPERATING EXPENSES": "Operating Expenses (€)",
  "OPERATING RESULT": "Operating Result (€)",
  "OPERATING RETURN ON GROSS BOOK VALUE": "Operating Return (on Gross Book Value)",
  "OPERATION": "Operation",
  "OPERATION NATURE CODE": "Code nature of the operation",
  "OPERATION NATURE LABEL": "Label nature of the operation",
  "OPERATION_NATURE_CODE": "Code nature of the operation",
  "OPERATION_NATURE_LABEL": "Label nature of the operation",
  "OperationalManagementInterfaceTitle": "Operational management interface",
  "OTHER INCOME": "Other Income (€)",
  "OTHER OCCUPIED AREA": "Other - Occupied Area (m²)",
  "OTHER REVENUE RESERVES": "Other Revenue Reserves",
  "OTHER TOTAL AREA": "Other - Total Area (m²)",
  "OTHER VACANT AREA": "Other - Vacant Area (m²)",
  "OTHER_REVENUE_RESERVES": "Other Revenue Reserves",
  "OUT COT AT": "Outstanding Payments as at",
  "OUT COT RAW": "Outstanding Payments",
  "OUTSTAND PAY BALANCES": "Outstanding Balance and Amount Received Evolution",
  "OUTSTAND PAY BALANCES RAW": "Outstanding Balance and Amount Received Evolution - raw",
  "OUTSTAND PAY DAY 180": "91-180 Days (€)",
  "OUTSTAND PAY DAY 30": "< 30 Days (€)",
  "OUTSTAND PAY DAY 365": "6 Months-1 Year (€)",
  "OUTSTAND PAY DAY 60": "30-60 Days (€)",
  "OUTSTAND PAY DAY 90": "61-90 Days (€)",
  "OUTSTAND PAY DAY 999": "> 1 Year (€)",
  "OUTSTAND PAY DEPOSIT": "Deposit (€)",
  "OUTSTAND PAY EVOLUTION": "Quarterly Evolution of Outstanding Payments",
  "OUTSTAND PAY INC TAX AMOUNT": "Outstanding Payments (€)",
  "OUTSTAND PAY INCL TAX AMOUNT": "Tenant Debit Balance",
  "OUTSTAND PAY INSURANCES": "Insurance and Miscellaneous (€)",
  "OUTSTAND PAY OPERATING EXP": "Expenses (€)",
  "OUTSTAND PAY OTHER": "Unallocated (€)",
  "OUTSTAND PAY RENT": "Rent (€)",
  "OUTSTAND PAY TAXES": "Taxes (€)",
  "OUTSTAND PAY TERM EQUIV": "Number of Outstanding Terms",
  "OUTSTAND PAY VAT AMOUNT": "VAT or CRL (€)",
  "OUTSTAND PAYMENT INC TAX AMOUNT": "Outstanding Payments",
  "OUTSTAND RATE": "Outstanding Payment Rate",
  "OUTSTAND_PAY_DAY_1": "<= x Days",
  "OUTSTAND_PAY_DAY_180": "91-180 Days",
  "OUTSTAND_PAY_DAY_180_SHORT": "91-180 Days",
  "OUTSTAND_PAY_DAY_2": "x-y Days",
  "OUTSTAND_PAY_DAY_3": "x-y Days",
  "OUTSTAND_PAY_DAY_30": "< 30 Days",
  "OUTSTAND_PAY_DAY_30_SHORT": "< 30 Days",
  "OUTSTAND_PAY_DAY_365": "6 Months-1 Year",
  "OUTSTAND_PAY_DAY_365_SHORT": "6 Months-1 Year",
  "OUTSTAND_PAY_DAY_4": "x-y Days",
  "OUTSTAND_PAY_DAY_5": "x-y Days",
  "OUTSTAND_PAY_DAY_6": "> x Days",
  "OUTSTAND_PAY_DAY_60": "30-60 Days",
  "OUTSTAND_PAY_DAY_60_SHORT": "30-60 Days",
  "OUTSTAND_PAY_DAY_90": "61-90 Days",
  "OUTSTAND_PAY_DAY_90_SHORT": "61-90 Days",
  "OUTSTAND_PAY_DAY_999": "> 1 Year",
  "OUTSTAND_PAY_DAY_999_SHORT": "> 1 Year",
  "OUTSTAND_PAY_DEPOSIT": "Deposit (€)",
  "OUTSTAND_PAY_EVOLUTION": "Quarterly Evolution of Outstanding Payments",
  "OUTSTAND_PAY_INC_TAX_AMOUNT": "Outstanding Payments (€)",
  "OUTSTAND_PAY_INSURANCES": "Insurance and Miscellaneous (€)",
  "OUTSTAND_PAY_OPERATING_EXP": "Expenses (€)",
  "OUTSTAND_PAY_OTHER": "Unallocated (€)",
  "OUTSTAND_PAY_RENT": "Rent (€)",
  "OUTSTAND_PAY_TAXES": "Taxes",
  "OUTSTAND_PAY_TERM_EQUIV": "Number of Outstanding Terms",
  "OUTSTAND_PAY_VAT_AMOUNT": "VAT or CRL (€)",
  "OUTSTAND_PAYMENT_INC_TAX_AMOUNT": "Outstanding Payments",
  "OUTSTAND_RATE": "Outstanding Payment Rate",
  "OUTSTANDING AMOUNT EVOLUTION": "Outstanding amount evolution",
  "OUTSTANDING BALANCE EVOLUTION": "Outstanding balance evolution",
  "OUTSTANDING PAY PROVIDER": "Property aging schedule by provider Report",
  "OUTSTANDING PAY PROVIDER BUILDING": "Property aging schedule by provider and invoice Report",
  "OUTSTANDING PAY PROVIDER PROVIDER": "Provider aging schedule by property and invoice Report",
  "OUTSTANDING PAYMENT": "Outstanding Payments",
  "OUTSTANDING PAYMENTS": "Outstanding Payment Report",
  "OUTSTANDING PAYMENTS AMOUNT": "Outstanding Payments (€)",
  "OUTSTANDING PAYMENTS AS AT": "Outstanding Payment Report as at",
  "OUTSTANDING PAYMENTS BY PERIOD": "Outstanding Payment Balance by Aging Period",
  "OUTSTANDING PAYMENTS DETAILLED BY TENANT": "Top 10 Tenants with Outstanding Payment",
  "OUTSTANDING RATE AMOUNT BALANCE": "Outstanding Balance / Amount Received Ratio Evolution",
  "OUTSTANDING RENT": "Outstanding Rent (€)",
  "OUTSTANDING RENT WOU": "Outstanding Rent",
  "OUTSTANDING_PAYMENTS": "Outstanding Payments",
  "OUTSTANDING_PAYMENTS_BY_PERIOD": "Outstanding Payment Balance by Aging Period",
  "OUTSTANDING_PAYMENTS_DETAILLED_BY_TENANT": "Top 10 Tenants with Outstanding Payment",
  "OVERPAYMENTS INC TAX AMNT": "Overpayments or non-affected receipt (€)",
  "OVERPAYMENTS INC TAX AMOUNT": "Overpayments (€)",
  "OVERPAYMENTS RATE": "Overpayment Rate",
  "OVERPAYMENTS_INC_TAX_AMNT": "Overpayments or non-affected receipt (€)",
  "OVERPAYMENTS_INC_TAX_AMOUNT": "Overpayments (€)",
  "OWNED BUILDINGS": "Properties in Portfolio",
  "OWNER": "Property Owner",
  "OWNER CODE": "Property Owner Code",
  "OWNER EQUITY": "Owner's Equity / Equity (€)",
  "OWNER FINANCIAL DATA": "Owner Financial Data",
  "OWNER NAME": "Property Owner Name",
  "OWNER TOTAL": "Total",
  "OWNER_CODE": "Property Owner Code",
  "OWNER_CODE1": "Owner Code",
  "OWNER_EQUITY": "Owner's Equity / Equity (€)",
  "OWNER_FINANCIAL_DATA": "Owner Financial Data",
  "OWNER_NAME": "Property Owner Name",
  "OWNER_NAME_ARBITRAGE": "Acquirer Name",
  "OWNER_NAME_required": "Acquirer Name required",
  "OWNER_NAME1": "Owner Name",
  "OWNERSHIP DATA END DATE": "To",
  "OWNERSHIP DATA START DATE": "Starting From",
  "OWNERSHIP RATE": "Ownership Rate",
  "OWNERSHIP_DATA_END_DATE": "To",
  "OWNERSHIP_DATA_START_DATE": "Starting From",
  "OWNERSHIP_RATE": "Ownership Rate",
  "pageBuildingTitle": "Property Fact Sheet",
  "pageExportBuildingTitle": "Property Fact Sheet Export",
  "pageExportFundTitle": "Fund Fact Sheet Export",
  "pageFundTitle": "Fund Fact Sheet",
  "PageManageAdditionalFieldListTitle": "MANAGE THEMES",
  "PageManageInterfaceListTitle": "Manage Data Streams",
  "PageManageSpecificInterfaceListTitle": "Manage Specific Interfaces",
  "pageManageTitle": "My Account",
  "pageRepositoryBuildingTitle": "Property Repository",
  "PAR": "Parameters",
  "PARTIAL PAYMENT INCL TAX AMOUNT": "Partial Payments (€)",
  "PARTIAL_PAYMENT_INCL_TAX_AMOUNT": "Partial Payments (€)",
  "PASSING RENT": "Year-to-Date Invoicing (€)",
  "PAY INCL TAX AMOUNT SUM PERCENT": "% of Amount Collected",
  "PAY INCL TAX AMOUNT SUM RATE": "Amount Collected (€)",
  "PAYMENT 11 TO 20 DAYS": "11-20 Days (€)",
  "PAYMENT 11 TO 20 RATE": "11-20 Days (%)",
  "PAYMENT 21 TO 31 DAYS": "21-31 Days (€)",
  "PAYMENT 21 TO 31 RATE": "21-31 Days (%)",
  "PAYMENT 5 TO 10 DAYS": "5-10 Days (€)",
  "PAYMENT 5 TO 10 RATE": "5-10 Days (%)",
  "PAYMENT AMOUNT": "Paid Amount",
  "PAYMENT BEFORE PERIOD INC TAX AMNT": "Receipts on invoices due before the period (€)",
  "PAYMENT DATE": "Payment Date",
  "PAYMENT DAYS": "Days (€)",
  "PAYMENT DURING PERIOD INC TAX AMNT": "Receipts during the Period (€)",
  "PAYMENT GREATER 31 DAYS": "> 31 Days (€)",
  "PAYMENT GREATER 31 RATE": "> 31 Days (%)",
  "PAYMENT INCL TAX AMOUNT": "Received due for the period (€)",
  "PAYMENT INCL TAX AMOUNT PERCENT": "% of Amount Collected",
  "PAYMENT INCL TAX AMOUNT RATE": "Amount Collected (€)",
  "PAYMENT LESS 5 DAYS": "< 5 Days (€)",
  "PAYMENT LESS 5 RATE": "< 5 Days (%)",
  "PAYMENT MODE": "Payment Mode",
  "PAYMENT PERIOD 1": "< 30 Days (€)",
  "PAYMENT PERIOD 1 RATE": "< 30 Days (%)",
  "PAYMENT PERIOD 2": "30-60 Days (€)",
  "PAYMENT PERIOD 2 RATE": "30-60 Days (%)",
  "PAYMENT PERIOD 2 SUM": "30-60 Days Running Total (€)",
  "PAYMENT PERIOD 2 SUM RATE": "30-60 Days Running Total (%)",
  "PAYMENT PERIOD 3": "60-90 Days (€)",
  "PAYMENT PERIOD 3 RATE": "60-90 Days (%)",
  "PAYMENT PERIOD 3 SUM": "60-90 Days Running Total (€)",
  "PAYMENT PERIOD 3 SUM RATE": "60-90 Days Running Total (%)",
  "PAYMENT PERIOD 4": "90-180 Days (€)",
  "PAYMENT PERIOD 4 RATE": "90-180 Days (%)",
  "PAYMENT PERIOD 4 SUM": "90-180 Days Running Total (€)",
  "PAYMENT PERIOD 4 SUM RATE": "90-180 Days Running Total (%)",
  "PAYMENT PERIOD 5": "180-365 Days (€)",
  "PAYMENT PERIOD 5 RATE": "180-365 Days (%)",
  "PAYMENT PERIOD 5 SUM": "180-365 Days Running Total (€)",
  "PAYMENT PERIOD 5 SUM RATE": "180-365 Days Running Total (%)",
  "PAYMENT PERIOD 6": "> 365 Days (€)",
  "PAYMENT PERIOD 6 RATE": "> 365 Days (%)",
  "PAYMENT PERIOD 6 SUM": "> 365 Days Running Total (€)",
  "PAYMENT PERIOD 6 SUM RATE": "> 365 Days Running Total (%)",
  "PAYMENT PERIOD LESS 0": "< 0 Days (€)",
  "PAYMENT RATE": "Days (%)",
  "PAYMENT SUM": "Days Running Total (€)",
  "PAYMENT SUM RATE": "Days Running Total (%)",
  "PAYMENT_11_20_DAYS": "11-20 Days",
  "PAYMENT_21_31_DAYS": "21-31 Days",
  "PAYMENT_5_10_DAYS": "5-10 Days",
  "PAYMENT_AMOUNT": "Paid Amount",
  "PAYMENT_BEFORE_PERIOD_INC_TAX_AMNT": "Receipts on invoices due before the period (€)",
  "PAYMENT_DATE": "Payment Date",
  "PAYMENT_DURING_PERIOD_INC_TAX_AMNT": "Receipts during the Period (€)",
  "PAYMENT_INCL_TAX_AMNT": "Received (€)",
  "PAYMENT_INCL_TAX_AMOUNT": "Amount Collected (€)",
  "PAYMENT_INCL_TAX_AMOUNT_RATE": "\\% of Amount Collected",
  "PAYMENT_INCL_TAX_AMOUNT_SUM": "Amount Collected (€)",
  "PAYMENT_INCL_TAX_AMOUNT_SUM_RATE": "\\% of Amount Collected",
  "PAYMENT_LESS_5_DAYS": "< 5 Days",
  "PAYMENT_METHOD_CODE": "Payment Method Code",
  "PAYMENT_MODE": "Payment Mode",
  "PAYMENT_MORE_31_DAYS": "> 31 Days",
  "PAYMENT_PERIOD_1": "< {0} Days (€)",
  "PAYMENT_PERIOD_1_RATE": "< {0} Days (%)",
  "PAYMENT_PERIOD_2": "{0}-{1} Days (€)",
  "PAYMENT_PERIOD_2_RATE": "{0}-{1} Days (%)",
  "PAYMENT_PERIOD_2_SUM": "{0}-{1} Days Running Total (€)",
  "PAYMENT_PERIOD_2_SUM_RATE": "{0}-{1} Days Running Total (%)",
  "PAYMENT_PERIOD_3": "{0}-{1} Days (€)",
  "PAYMENT_PERIOD_3_RATE": "{0}-{1} Days (%)",
  "PAYMENT_PERIOD_3_SUM": "{0}-{1} Days Running Total (€)",
  "PAYMENT_PERIOD_3_SUM_RATE": "{0}-{1} Days Running Total (%)",
  "PAYMENT_PERIOD_4": "{0}-{1} Days (€)",
  "PAYMENT_PERIOD_4_RATE": "{0}-{1} Days (%)",
  "PAYMENT_PERIOD_4_SUM": "{0}-{1} Days Running Total (€)",
  "PAYMENT_PERIOD_4_SUM_RATE": "{0}-{1} Days Running Total (%)",
  "PAYMENT_PERIOD_5": "{0}-{1} Days (€)",
  "PAYMENT_PERIOD_5_RATE": "{0}-{1} Days (%)",
  "PAYMENT_PERIOD_5_SUM": "{0}-{1} Days Running Total (€)",
  "PAYMENT_PERIOD_5_SUM_RATE": "{0}-{1} Days Running Total (%)",
  "PAYMENT_PERIOD_6": "> {0} Days (€)",
  "PAYMENT_PERIOD_6_RATE": "> {0} Days (%)",
  "PAYMENT_PERIOD_6_SUM": "> {0} Days Running Total (€)",
  "PAYMENT_PERIOD_6_SUM_RATE": "> {0} Days Running Total (%)",
  "PAYMENT_PERIOD_LESS_0": "< 0 Days (€)",
  "PAYS": "Pays",
  "PC EXPECTED YIELD": "Target Yield",
  "PC MGMT FEES GARB COLLECT TAX": "Garbage Collection Tax Management Fees (%)",
  "PC PORTFOLIO NET MARKET VALUE": "% of Portfolio Net Market Value",
  "PC PORTFOLIO TOTAL RETURN": "% of Portfolio Total Return",
  "PC PROPERTY TAX MGMT FEES": "Property Tax Management Fees (%)",
  "PC RECOV PROP MANAGT FEES ADM": "Recoverable % of Administrative Property Management Fees",
  "PC RECOV PROP MANAGT FEES TEN": "Recoverable % of Property Management Fees",
  "PC RECOVERABLE 606": "Recoverable % of Major Refurbishment",
  "PC RECOVERABLE INSURANCE": "Recoverable % of Property Insurance",
  "PC RECOVERABLE OFFICE TAX": "Recoverable % of Office Tax",
  "PC RECOVERABLE PROPERTY TAX": "Recoverable % of Property Tax",
  "PC RECOVERABLE REFURB EXCL 606": "% Recoverable - Major Refurbishment",
  "PC STEP RENT 1 OF GHL RENT": "Stepped Rent 1 as a % of Headline Rent",
  "PC STEP RENT 2 OF GHL RENT": "Stepped Rent 2 as a % of Headline Rent",
  "PC STEP RENT 3 OF GHL RENT": "Stepped Rent 3 as a % of Headline Rent",
  "PC STEP RENT 4 OF GHL RENT": "Stepped Rent 4 as a % of Headline Rent",
  "PC STEP RENT 5 OF GHL RENT": "Stepped Rent 5 as a % of Headline Rent",
  "PC STEP RENT 6 OF GHL RENT": "Stepped Rent 6 as a % of Headline Rent",
  "PC_EXPECTED_YIELD": "Target Yield",
  "PC_INDEX_LEVERAGE": "Rent Review Leverage",
  "PC_MGMT_FEES_GARB_COLLECT_TAX": "Garbage Collection Tax Management Fees (%)",
  "PC_PORTFOLIO_NET_MARKET_VALUE": "% of Portfolio Net Market Value",
  "PC_PORTFOLIO_TOTAL_RETURN": "% of Portfolio Total Return",
  "PC_PROPERTY_TAX_MGMT_FEES": "Property Tax Management Fees (%)",
  "PC_RECOV_PROP_MANAGT_FEES_ADM": "Recoverable % of Administrative Property Management Fees",
  "PC_RECOV_PROP_MANAGT_FEES_TEN": "Recoverable % of Property Management Fees",
  "PC_RECOVERABLE_606": "Recoverable % of Major Repairs",
  "PC_RECOVERABLE_INSURANCE": "Recoverable % of Insurance Premiums",
  "PC_RECOVERABLE_OFFICE_TAX": "Recoverable % of Office Tax",
  "PC_RECOVERABLE_PROPERTY_TAX": "Recoverable % of Property Tax",
  "PC_RECOVERABLE_REFURB_EXCL_606": "% Recoverable - Major Refurbishment",
  "PC_STEP_RENT_1_OF_GHL_RENT": "Stepped Rent 1 as a % of Headline Rent",
  "PC_STEP_RENT_2_OF_GHL_RENT": "Stepped Rent 2 as a % of Headline Rent",
  "PC_STEP_RENT_3_OF_GHL_RENT": "Stepped Rent 3 as a % of Headline Rent",
  "PC_STEP_RENT_4_OF_GHL_RENT": "Stepped Rent 4 as a % of Headline Rent",
  "PC_STEP_RENT_5_OF_GHL_RENT": "Stepped Rent 5 as a % of Headline Rent",
  "PC_STEP_RENT_6_OF_GHL_RENT": "Stepped Rent 6 as a % of Headline Rent",
  "PCT RENT AMOUNT": "% of Property Headline Rent",
  "PCT RENTED AREA": "% of Total Property Area",
  "PCT_RENT_AMOUNT": "% of Property Headline Rent",
  "PCT_RENTED_AREA": "% of Total Property Area",
  "PDDI": "Impairment Loss (€)",
  "PDF": "PDF",
  "PENDING RE DISPOSITIONS": "Pending Disposals",
  "PENDING RE PURCHASES": "Pending Acquisitions",
  "PER SHARE ACQUISITION PRICE": "Per-Share Acquisition Price (€)",
  "PER SHARE UNREALIZED GAINS AND LOSSES": "Unrealized Gains and Losses per Share (€)",
  "PER_SHARE_ACQUISITION_PRICE": "Per-Share Acquisition Price (€)",
  "PER_SHARE_UNREAL_GAINS_LOSSES": "Unrealized Gains and Losses per Share (€)",
  "PER_SHARE_UNREALIZED_GAINS_AND_LOSSES": "Unrealized Gains and Losses per Share (€)",
  "PERCENT TOTAL PROPERTY AREA": "% of Total Property Area",
  "PERCENT TOTAL RENT AMOUNT": "% of Property Headline Rent",
  "PERCENT_OF_TOTAL_AREA": "% of Total Area",
  "PERCENT_TOTAL_PROPERTY_AREA": "% of Total Property Area",
  "PERCENT_TOTAL_RENT_AMOUNT": "% of Property Headline Rent",
  "PERCENTAGE RENT": "Percentage Rent (€/year)",
  "PERCENTAGE RENT Y N": "Percentage Rent (yes/no)?",
  "PERCENTAGE_RENT": "Percentage Rent (€/year)",
  "PERCENTAGE_RENT Y N": "Percentage Rent (yes/no)?",
  "PERCENTAGE_RENT_Y_N": "Percentage Rent (yes/no)?",
  "PERFORMANCE INDICATORS": "Analytics",
  "PERIOD": "Period",
  "PERIOD AMOUNT": "Period Transaction",
  "PERIOD INVOICES DUE INC TAX AMNT": "Invoices due for the period (€)",
  "PERIOD_AMOUNT": "Period amount",
  "PERIOD_INVOICES_DUE_INC_TAX_AMNT": "Invoices due for the period (€)",
  "PHYSICAL OCCUPANCY RATE": "Physical Occupancy Rate",
  "PHYSICAL OCCUPANCY SUMMARY": "Occupancy Summary (Physical)",
  "PHYSICAL OCCUPATION RATE": "Physical Occupancy Rate",
  "PHYSICAL VACANCY M2": "Vacant Area (m²)",
  "PHYSICAL VACANCY PC": "Physical Vacancy Rate (%)",
  "PHYSICAL VACANCY RATE": "Physical Vacancy Rate",
  "PHYSICAL_OCCUPANCY_RATE": "Physical Occupancy Rate",
  "PHYSICAL_OCCUPATION_RATE": "Physical Occupancy Rate",
  "PHYSICAL_VACANCY_RATE": "Physical Vacancy Rate",
  "PM BUILDING CODE": "Property Manager's Property Code",
  "PORTFOLIO": "Portfolio",
  "PORTFOLIO BREAKDOWN ASSET NATURE AREAS": "Area Breakdown by Property Type",
  "PORTFOLIO BREAKDOWN ASSET NATURE BUILDINGS": "Property Breakdown by Property Type",
  "PORTFOLIO BREAKDOWN ASSET NATURE GROSS HEADLINE RENT": "Gross Headline Rent Breakdown by Property Type",
  "PORTFOLIO BREAKDOWN ASSET NATURE LEASES": "Lease Breakdown by Property Type",
  "PORTFOLIO BREAKDOWN ASSET NATURE UNITS": "Unit Breakdown by Property Type",
  "PORTFOLIO BREAKDOWN ASSET NATURE VALUES": "Market Value Breakdown by Property Type",
  "PORTFOLIO BREAKDOWN ASSET TYPE AREAS": "Area Breakdown by Property Class",
  "PORTFOLIO BREAKDOWN ASSET TYPE BUILDINGS": "Property Breakdown by Property Class",
  "PORTFOLIO BREAKDOWN ASSET TYPE GROSS HEADLINE RENT": "Gross Headline Rent Breakdown by Property Class",
  "PORTFOLIO BREAKDOWN ASSET TYPE LEASES": "Lease Breakdown by Property Class",
  "PORTFOLIO BREAKDOWN ASSET TYPE UNITS": "Unit Breakdown by Property Class",
  "PORTFOLIO BREAKDOWN ASSET TYPE VALUES": "Market Value Breakdown by Property Class",
  "PORTFOLIO CODE": "Portfolio Code",
  "PORTFOLIO DETAIL": "Portfolio Details",
  "PORTFOLIO EVOLUTION €": "Portfolio Evolution (Net Market Value)",
  "PORTFOLIO EVOLUTION ASSET NATURE €": "Portfolio Evolution by Property Type (Net Market Value)",
  "PORTFOLIO EVOLUTION ASSET NATURE M2": "Portfolio Evolution by Property Type (Total Area)",
  "PORTFOLIO EVOLUTION GROSS HEADLINE RENT": "Portfolio Evolution (Gross Headline Rent)",
  "PORTFOLIO EVOLUTION GROSS HEADLINE RENT BY NATURE": "Portfolio Evolution by Property Type (Gross Headline Rent)",
  "PORTFOLIO EVOLUTION M2": "Portfolio Evolution (Total Area)",
  "PORTFOLIO EVOLUTION NET EFFECTIVE RENT": "Portfolio Evolution (Net Operating Income)",
  "PORTFOLIO EVOLUTION NET EFFECTIVE RENT BY NATURE": "Portfolio Evolution by Property Type (Net Operating Income)",
  "PORTFOLIO LABEL": "Portfolio label",
  "PORTFOLIO SIZE": "Portfolio Size",
  "PORTFOLIO YIELD AND RETURN": "Portfolio-level Returns",
  "PORTFOLIO_CODE": "Portfolio Code",
  "PORTFOLIO_CODE_required": "Portfolio Code required",
  "PORTFOLIO_LABEL": "Portfolio label",
  "POSTAL CODE": "Postal Code",
  "POSTAL_CODE": "Postal Code",
  "POTENTIAL GROSS CAPITAL RATE": "Potential Gross Cap Rate",
  "POTENTIAL GROSS INITIAL YIELD": "Potential Gross Initial Yield",
  "POTENTIAL INCOME INCREASE": "Reversionary Potential (€)",
  "POTENTIAL INCOME INCREASE WOU": "Reversionary Potential",
  "POTENTIAL NET CAPITAL RATE": "Potential Net Cap Rate",
  "POTENTIAL NET INITIAL YIELD": "Potential Net Initial Yield",
  "POTENTIAL NET RENTAL INCOME": "Net Potential Rent (€)",
  "POTENTIAL NET RENTAL INCOME WOU": "Net Potential Rent",
  "POTENTIAL_GROSS_CAPITAL_RATE": "Potential Gross Cap Rate",
  "POTENTIAL_GROSS_INITIAL_YIELD": "Potential Gross Initial Yield",
  "POTENTIAL_NET_CAPITAL_RATE": "Potential Net Cap Rate",
  "POTENTIAL_NET_INITIAL_YIELD": "Potential Net Initial Yield",
  "POURCENTAGE_D_AVANCEMENT_DU_CHANTIER": "Avancement du chantier (%)",
  "POURCENTAGE_RECUPERATION": "Récupération (%)",
  "PRESENT": "Present",
  "PREV ANNUAL M2 AMOUNT": "Previous Lease - Headline Rent by m² (€/m²/year)",
  "PREV GROSS HEADLINE RENT": "Previous Lease - Headline Rent (€/year)",
  "PREV LEASE ANNUAL RENT AMOUNT": "Previous Lease - Headline Rent (€/year)",
  "PREV RENTED AREA": "Previous Lease - Occupied Area (m²)",
  "PREV_ANNUAL_RENT_AMOUNT_SQM": "Headline Rent before Rent Review by m² (€/m²/year)",
  "PREV_LEASE_ANNUAL_RENT_AMOUNT": "Previous Lease - Headline Rent (€/year)",
  "PREV_VALUE_EXCL_DUTIES_VAT": "Previous Net Market Value (€)",
  "PREV_VALUE_EXCL_DUTIES_VAT_SQM": "Previous Net Market Value by m² (€/m²)",
  "Preview": "Preview",
  "PREVIOUS LEASE AREA SQM": "Previous Lease - Occupied Area (m²)",
  "PREVIOUS LEASE HEADLINE RENT": "Previous Lease - Headline Rent (€/year)",
  "PREVIOUS LEASE HEADLINE RENT M2": "Previous Lease - Headline Rent (€/year/m²)",
  "PREVIOUS LEASE SUMMARY": "Previous Lease Summary",
  "PREVIOUS LEASE TENANT NAME": "Previous Lease - Tenant Name",
  "PREVIOUS LEASE TRADE NAME": "Previous Lease - Trade Name",
  "PREVIOUS RENT AMOUNT PER SQM": "Previous Lease - Headline Rent by m² (€/m²/year)",
  "PREVIOUS TENANT CODE": "Previous Tenant Code",
  "PREVIOUS TENANT MOVE OUT DATE": "Previous Tenant Move-out Date",
  "PREVIOUS TENANT NAME": "Previous Tenant Name",
  "PREVIOUS_ANNUAL_RENT_AMOUNT": "Headline Rent before Rent Review (€/year)",
  "PREVIOUS_LAST_VALUATION_DATE": "Valuation Date YYYY-2",
  "PREVIOUS_LEASE_AREA_SQM": "Previous Lease - Occupied Area (m²)",
  "PREVIOUS_LEASE_HEADLINE_RENT": "Previous Lease - Headline Rent (€/year)",
  "PREVIOUS_LEASE_HEADLINE_RENT_M2": "Previous Lease - Headline Rent (€/year/m²)",
  "PREVIOUS_LEASE_TENANT_NAME": "Previous Tenant Name",
  "PREVIOUS_LEASE_TRADE_NAME": "Previous Tenant Trade Name",
  "PREVIOUS_NET_MARKET_VALUE": "Net Market Value YYYY-2 (€)",
  "PREVIOUS_RENT_AMOUNT_PER_SQM": "Previous Lease - Headline Rent by m² (€/m²/year)",
  "PREVIOUS_REVISION_DATE": "Previous Rent Review Date",
  "PREVIOUS_TENANT_CODE": "Previous Tenant Code",
  "PREVIOUS_TENANT_MOVE_OUT_DATE": "Previous Tenant Move-out Date",
  "PREVIOUS_TENANT_NAME": "Previous Tenant Name",
  "PRIMARY UNIT": "Primary unit (yes/no)?",
  "PRIVATE UNIT AREA": "Private Unit Area (m²)",
  "PRIVATE_UNIT_AREA": "Private Unit Area (m²)",
  "PRO RATED FINANCIAL VACANCY": "Pro-rated Financial Vacancy (€)",
  "PRO_RATED_FINANCIAL_VACANCY": "Pro-rated Financial Vacancy (€)",
  "PROPERTY": "Property",
  "PROPERTY BALANCE": "Property Balance",
  "PROPERTY BALANCE SUMMARY": "Consolidated Property Balance",
  "PROPERTY DISTRIBUTION": "Property Distribution by Gross Income, Total Return, Net Market Value and Property Type",
  "PROPERTY MANAGER": "Property Manager",
  "PROPERTY MANAGER CODE": "Property Manager Code",
  "PROPERTY MANAGER REPORT": "PM dashboard",
  "PROPERTY MANAGER REPORT TRIMESTER": "PM dashboard - trimester",
  "PROPERTY SUMMARY": "Property Summary",
  "PROPERTY TAX AMOUNT": "Property Tax Amount (€)",
  "PROPERTY TAX MANAGEMENT FEES": "Property Tax Management Fees (€)",
  "PROPERTY TOTAL": "Property Total",
  "PROPERTY TYPE BREAKDOWN": "Market Value Breakdown by Property Type",
  "PROPERTY VALUATION": "Property Impairment Report as at",
  "PROPERTY VALUATION SHEET": "Property Impairment Report",
  "PROPERTY_MANAGER": "Property Manager",
  "PROPERTY_MANAGER_CODE": "Property Manager Code",
  "PROPERTY_MANAGER_NAME": "Property Manager",
  "PROPERTY_TAX_AMOUNT": "Property Tax Amount (€)",
  "PROPERTY_TAX_MANAGEMENT_FEES": "Property Tax Management Fees (€)",
  "PROVIDER": "Provider",
  "PROVIDER CODE": "Provider Code",
  "PROVIDER INVOICES ACCOUNTED": "Recorded provider invoices",
  "PROVIDER INVOICES PAID": "Paid provider invoices",
  "PROVIDER NAME": "Provider Name",
  "PROVIDER TOTAL": "Total Provider",
  "PROVIDER TYPE CODE": "Provider Type Code",
  "PROVIDER TYPE NAME": "Provider Type",
  "PROVIDER_CODE": "Provider Code",
  "PROVIDER_NAME": "Provider Name",
  "PROVIDER_TOTAL": "Total Provider",
  "PROVIDER_TYPE_CODE": "Provider Type Code",
  "PROVIDER_TYPE_NAME": "Provider Type",
  "PROVISIONS FOR EXPENSES": "Service Charges (€)",
  "PROVISIONS FOR EXPENSES WOU": "Service Charges",
  "PURCH WORK PROGRES STATE": "Purchase Works Status",
  "PURCHASE": "Manage Acquisitions",
  "PURCHASE ACT SIGNING DATE": "Date Signed of Conveyancing Document",
  "PURCHASE ACT SIGNING DT": "Date Signed of Conveyancing Document",
  "PURCHASE AGREEMENT DATE": "Purchase Agreement Date",
  "PURCHASE FEES AMOUNT": "Purchase Fees Amount (€)",
  "PURCHASE GOAL": "Acquisition Goal",
  "PURCHASE HISTORY €": "Historical Acquisitions by Property Type and Net Market Value",
  "PURCHASE HISTORY M2": "Historical Acquisitions by Property Type and Rental Area",
  "PURCHASE HISTORY RECORD": "Acquisition History Record",
  "PURCHASE PROGRES STATE": "Acquisition Status",
  "PURCHASE STASUS": "Acquisition Status",
  "PURCHASE TOT VALUE": "Property Total Value",
  "PURCHASE TOTAL VALUE": "Acquisition Value (€)",
  "PURCHASE TOTAL VALUE SQM": "Acquisition Value by m² (€/m²)",
  "PURCHASE TYPE": "Acquisition Type",
  "PURCHASE_ACT_SIGNING_DATE": "Date Signed of Conveyancing Document",
  "PURCHASE_ACT_SIGNING_DATE_mismatch": "Date Signed of Conveyancing Document mismatch",
  "PURCHASE_AGREEMENT_DATE": "Purchase Agreement Date",
  "PURCHASE_AGREEMENT_DATE_mismatch": "Purchase Agreement Date mismatch",
  "PURCHASE_AGREEMENT_DATE_required": "Purchase Agreement Date required",
  "PURCHASE_FEES_AMOUNT": "Purchase Fees Amount (€)",
  "PURCHASE_FEES_AMOUNT_mismatch": "Purchase Fees Amount mismatch",
  "PURCHASE_FEES_AMOUNT_WOU": "Purchase Fees Amount",
  "PURCHASE_STATUS": "Acquisition Status",
  "PURCHASE_TYPE": "Acquisition Type",
  "PURCHASER NAME": "Acquirer Name",
  "PURCHASER_NAME": "Acquirer Name",
  "PwdNotContaintFollowSpecialChar": "The password should not contain the following special characters : \">\" and \"<",
  "QUARTER": "Q",
  "QUARTERLY OCCUPANCY EVOLUTION": "Quarterly Occupancy Evolution",
  "QuoteColumns": "Frame columns with quotation mark",
  "RATE BUDGET N REAL N MINUS 1": "Budget vs. Actual (%)",
  "RATE REALISED N BUDGETED N": "Actual vs. Budget (%)",
  "RATE REALISED N N MINUS 1": "Actual vs. Actual (%)",
  "RATE_BUDGET_N_REAL_N_MINUS_1": "Budget YYYY vs. Actual YYYY-1 (%)",
  "RATE_REALISED_N_BUDGETED_N": "Actual YYYY vs. Budget YYYY (%)",
  "RATE_REALISED_N_N_MINUS_1": "Actual YYYY vs. Actual YYYY-1 (%)",
  "RE LETTING PERIOD": "Re-letting Period (days)",
  "RE_LETTING_PERIOD": "Re-letting Period (days)",
  "REAL ESTATE CASH FLOW": "Operating Cash Flow (€/year)",
  "REAL ESTATE PORTFOLIO": "Real Estate Portfolio",
  "REAL ESTATE PORTFOLIO BREAKDOWN": "RE Portfolio Breakdown Report as at",
  "REAL ESTATE PORTFOLIO EVOLUTION": "RE Portfolio Evolution as of",
  "REAL ESTATE PORTFOLIO EVOLUTION SHEET": "RE Portfolio Evolution",
  "REAL_ESTATE_CASH_FLOW": "Operating Cash Flow YYYY-1 (€/year)",
  "REALISED 01 N": "Realised January",
  "REALISED 02 N": "Realised February",
  "REALISED 03 N": "Realised March",
  "REALISED 04 N": "Realised April",
  "REALISED 05 N": "Realised May",
  "REALISED 06 N": "Realised June",
  "REALISED 07 N": "Realised July",
  "REALISED 08 N": "Realised August",
  "REALISED 09 N": "Realised September",
  "REALISED 10 N": "Realised October",
  "REALISED 11 N": "Realised November",
  "REALISED 12 N": "Realised December",
  "REALISED ACCOUNTING N M1": "Realised accounting",
  "REALISED AMOUNT BT": "Actual (€/year)",
  "REALISED N": "Realised",
  "REALISED N M1": "Realised",
  "REALISED N MINUS 1": "Actual (€/year)",
  "REALISED N MINUS 2": "Actual (€/year)",
  "REALISED N TODATE M1": "Realised",
  "REALISED T1 N": "Realised Q1",
  "REALISED T2 N": "Realised Q2",
  "REALISED T3 N": "Realised Q3",
  "REALISED T4 N": "Realised Q4",
  "REALISED TODATE N": "Realised",
  "REALISED_01_N": "Realised January YYYY",
  "REALISED_02_N": "Realised February YYYY",
  "REALISED_03_N": "Realised March YYYY",
  "REALISED_04_N": "Realised April YYYY",
  "REALISED_05_N": "Realised May YYYY",
  "REALISED_06_N": "Realised June YYYY",
  "REALISED_07_N": "Realised July YYYY",
  "REALISED_08_N": "Realised August YYYY",
  "REALISED_09_N": "Realised September YYYY",
  "REALISED_10_N": "Realised October YYYY",
  "REALISED_11_N": "Realised November YYYY",
  "REALISED_12_N": "Realised December YYYY",
  "REALISED_ACCOUNTING_N_M1": "Realised accounting DDMMYYYY-1",
  "REALISED_N": "Realised YYYY",
  "REALISED_N_M1": "Realised YYYY-1",
  "REALISED_N_MINUS_1": "Actual YYYY-1 (€/year)",
  "REALISED_N_MINUS_2": "Actual YYYY-2 (€/year)",
  "REALISED_N_TODATE_M1": "Realised DDMMYYYY-1",
  "REALISED_T1_N": "Realised Q1 YYYY",
  "REALISED_T2_N": "Realised Q2 YYYY",
  "REALISED_T3_N": "Realised Q3 YYYY",
  "REALISED_T4_N": "Realised Q4 YYYY",
  "REALISED_TODATE_N": "Realised DDMMYYYY",
  "RECONSTITUTION VALUE": "Reconstitution Value (€)",
  "RECONSTITUTION_VALUE": "Reconstitution Value (€)",
  "RECOV OPERATING EXPENSES": "Recoverable Operating Expenses (€/year)",
  "RECOV_OPERATING_EXPENSES": "Recoverable Operating Expenses YYYY-1 (€/year)",
  "RECOVERABLE": "Recoverable",
  "RECOVERABLE 1": "Recoverable / Not recoverable",
  "RECOVERABLE AMOUNT": "Recoverable Amount (€)",
  "RECOVERABLE EXPENSES": "Recoverable Expenses (€/year)",
  "RECOVERABLE EXPENSES WOU": "Recoverable Expenses",
  "RECOVERABLE GARB COLLECT TAX": "% Recoverable - Garbage Collection Tax",
  "RECOVERABLE MARKETING FEES": "% Recoverable - Marketing Fees",
  "RECOVERABLE NOT RECOVERABLE": "Recoverable / Not Recoverable",
  "RECOVERABLE_1": "Recoverable / Not recoverable",
  "RECOVERABLE_AMOUNT": "Recoverable Amount (€)",
  "RECOVERABLE_EXPENSES": "Recoverable Expenses (€/year)",
  "RECOVERABLE_EXPENSES_WOU": "Recoverable Expenses",
  "RECOVERABLE_GARB_COLLECT_TAX": "% Recoverable - Garbage Collection Tax",
  "RECOVERABLE_MARKETING_FEES": "% Recoverable - Marketing Fees",
  "RECOVERED EXPENSES": "Recovered Expenses (€/year)",
  "RECOVERED EXPENSES WOU": "Recovered Expenses",
  "RECUPERABLE": "Récupérable ?",
  "REFERENCE VALUE": "Reference Value (€)",
  "REFERENCE_NUMBER": "Reference Number",
  "REFURBISHMENT AND MAINTENANCE MONITORING": "Refurbishment & Maintenance Monitoring",
  "REFURBISHMENT CLAUSE": "Refurbishment Clause",
  "REFURBISHMENT_CLAUSE": "Refurbishment Clause",
  "REGISTRATION FEES AMOUNT": "Recording Fees (€)",
  "REGISTRATION_FEES_AMOUNT": "Recording Fees (€)",
  "REGISTRATION_FEES_AMOUNT_mismatch": "Recording Fees mismatch",
  "REGISTRATION_FEES_AMOUNT_WOU": "Recording Fees",
  "REGUL_CHARGE_SUB_ITEM": "Sub-Billing Item",
  "REGULAR CHARGES ITEM CODE": "Billing Element Code",
  "REGULAR CHARGES ITEM LABEL": "Billing Element Label",
  "REGULAR_CHARGES_ITEM_CODE": "Billing Item Code",
  "REGULAR_CHARGES_ITEM_LABEL": "Billing Item",
  "RELETTING REPORT": "Reletting Report",
  "RELETTING REPORT RAW": "Reletting Report Raw",
  "REMAINING TERM TO THE LEASE TERMINATION": "Remaining Term to the Lease Termination (y-m-d)",
  "REMAINING_TERM_TO_THE_LEASE_TERMINATION": "Remaining Term to the Lease Termination (y-m-d)",
  "REMAINING TERM TO THE TERMINATION": "Remaining Term to the Lease Termination (y-m-d)",
  "REMAINING_TERM_TO_THE_TERMINATION": "Remaining Term to the Lease Termination (y-m-d)",
  "RENT ADJUSTMENTS": "Rent Incentives (€)",
  "RENT ADJUSTMENTS WOU": "Rent Incentives",
  "RENT FREE 1 END DATE": "Rent-free Period 1 End Date",
  "RENT FREE 1 PERIOD": "Rent free period 1 duration (months)",
  "RENT FREE 1 START DATE": "Rent-free Period 1 Start Date",
  "RENT FREE 2 END DATE": "Rent-free Period 2 End Date",
  "RENT FREE 2 PERIOD": "Rent free period 2 duration (months)",
  "RENT FREE 2 START DATE": "Rent-free Period 2 Start Date",
  "RENT FREE 3 END DATE": "Rent-free Period 3 End Date",
  "RENT FREE 3 PERIOD": "Rent free period 3 duration (months)",
  "RENT FREE 3 START DATE": "Rent-free Period 3 Start Date",
  "RENT FREE AMOUNT 1": "Annual amount covered by the rent free period 1",
  "RENT FREE AMOUNT 2": "Annual amount covered by the rent free period 2",
  "RENT FREE AMOUNT 3": "Annual amount covered by the rent free period 3",
  "RENT FREE END DATE": "General rent free end date",
  "RENT FREE LEFT AMOUNT": "Remaining deductible amount (€)",
  "RENT FREE LEFT NB MONTH": "Remaining deductible (months)",
  "RENT FREE NB MONTH": "Total deductible (months)",
  "RENT FREE PERIOD": "Rent-free Period (y-m-d)",
  "RENT FREE TOTAL AMOUNT": "Total deductible amount (€)",
  "RENT MARKET VALUE": "Estimated Rental Value (€/m²/year)",
  "RENT RECEIVABLE CUL BIL TERM": "Rent Receivable Current Billing Term (€)",
  "RENT RECEIVABLE CUR BIL TERM": "Rent Receivable Current Billing Term (€)",
  "RENT RECEIVABLE YEAR TO DATE": "Rent Receivable Year-to-Date (€)",
  "RENT REVIEW FREQUENCY": "Rent Review Frequency",
  "RENT REVIEW LEVERAGE": "Rent Review Leverage",
  "RENT TO SALES RATIO": "Rent-to-Sales Ratio",
  "RENT UPLIFT": "²% Rent Uplift since Lease Start",
  "RENT VARIATION": "Rent Uplift",
  "RENT VARIATION AND REVERSIONARY POTENTIAL": "Rent Uplift and Reversionary Potential",
  "RENT VARIATION RATE": "Rent Uplift",
  "RENT_FREE 1 END DATE": "Rent-free Period 1 End Date",
  "RENT_FREE 1 PERIOD": "Rent-free Period 1 (months)",
  "RENT_FREE 1 START DATE": "Rent-free Period 1 Start Date",
  "RENT_FREE 2 END DATE": "Rent-free Period 2 End Date",
  "RENT_FREE 2 PERIOD": "Rent-free Period 2 (months)",
  "RENT_FREE 2 START DATE": "Rent-free Period 2 Start Date",
  "RENT_FREE 3 END DATE": "Rent-free Period 3 End Date",
  "RENT_FREE 3 PERIOD": "Rent-free Period 3 (months)",
  "RENT_FREE 3 START DATE": "Rent-free Period 3 Start Date",
  "RENT_FREE AMOUNT": "Rent-free Period (€)",
  "RENT_FREE AMOUNT 1": "Rent-free Period on Year 1 (€)",
  "RENT_FREE AMOUNT 2": "Rent-free Period on Year 2 (€)",
  "RENT_FREE AMOUNT 3": "Rent-free Period on Year 3 (€)",
  "RENT_FREE END DATE": "Rent-free Period End Date",
  "RENT_FREE PERIOD": "Rent-free Period 2 (y-m-d)",
  "RENT_FREE START DATE": "Rent-free Period Start Date",
  "RENT_FREE_1_END_DATE": "Rent-free Period 1 End Date",
  "RENT_FREE_1_PERIOD": "Rent free period 1 duration (months)",
  "RENT_FREE_1_START_DATE": "Rent-free Period 1 Start Date",
  "RENT_FREE_2_END_DATE": "Rent-free Period 2 End Date",
  "RENT_FREE_2_PERIOD": "Rent free period 2 duration (months)",
  "RENT_FREE_2_START_DATE": "Rent-free Period 2 Start Date",
  "RENT_FREE_3_END_DATE": "Rent-free Period 3 End Date",
  "RENT_FREE_3_PERIOD": "Rent free period 3 duration (months)",
  "RENT_FREE_3_START_DATE": "Rent-free Period 3 Start Date",
  "RENT_FREE_AMOUNT": "Rent-free Period (€)",
  "RENT_FREE_AMOUNT_1": "Annual amount covered by the rent free period 1",
  "RENT_FREE_AMOUNT_2": "Annual amount covered by the rent free period 2",
  "RENT_FREE_AMOUNT_3": "Annual amount covered by the rent free period 3",
  "RENT_FREE_END_DATE": "General rent free end date",
  "RENT_FREE_LEFT_AMOUNT": "Remaining deductible amount (€)",
  "RENT_FREE_LEFT_NB_MONTH": "Remaining deductible (months)",
  "RENT_FREE_NB_MONTH": "Total deductible (months)",
  "RENT_FREE_PERIOD": "Rent-free Period (y-m-d)",
  "RENT_FREE_START_DATE": "Rent-free Start Date",
  "RENT_FREE_TOTAL_AMOUNT": "Total deductible amount (€)",
  "RENT_INIT_VARIATION_RATE": "Rent Uplift Compared To Initial Rent",
  "RENT_MARKET_VALUE": "Estimated Rental Value (€/m²/year)",
  "RENT_RECEIVABLE_CUL_BIL_TERM": "Rent Receivable Current Billing Term (€)",
  "RENT_RECEIVABLE_CUR_BIL_TERM": "Rent Receivable Current Billing Term (€)",
  "RENT_RECEIVABLE_YEAR_TO_DATE": "Rent Receivable Year-to-Date (€)",
  "RENT_REVIEW_FREQUENCY": "Rent Review Frequency",
  "RENT_REVIEW_LEVERAGE": "Rent Review Leverage",
  "RENT_TO_SALES_RATIO": "Rent-to-Sales Ratio",
  "RENT_UPLIFT": "% Rent Uplift since Lease Start",
  "RENT_VARIATION": "Rent Uplift",
  "RENT_VARIATION_RATE": "Rent Uplift",
  "RENTAL EXPENSES": "Operating Expenses (OPEX)",
  "RENTAL EXPENSES WOU": "Operating Expenses (OPEX)",
  "RENTAL INCOME": "Rental Income (€)",
  "RENTAL INCOME MONITORING": "Rental Income Monitoring Report as at",
  "RENTAL INCOME MONITORING COMPARE": "Year-to-Year Invoicing Comparison Report as at",
  "RENTAL INCOME MONITORING COMPARE RAW": "Year-to-Year Invoicing Comparison Report Raw as at",
  "RENTAL INCOME MONITORING RAW": "Rental Income Monitoring Report Raw as at",
  "RENTAL INCOME MONITORING SHEET": "Rental Income Monitoring Report",
  "RENTAL INCOME WOU": "Rental Income",
  "RENTAL SITUATION": "Rental Situation",
  "RENTAL TERM LABEL": "Rental Term",
  "RENTAL VACANCY": "Temporary Vacancy",
  "RENTAL VACANT AREA": "Vacant Area Available for Letting (m²)",
  "RENTAL VACANT AREA RATE": "% of Total Vacancy",
  "RENTAL_TERM_LABEL": "Rental Term",
  "RENTAL_VACANT_AREA": "Rental Vacant Area (m²)",
  "RENTAL_VACANT_AREA_RATE": "% of Total Vacancy (%)",
  "RENTED AREA": "Occupied Area (m²)",
  "RENTED_AREA": "Occupied Area (m²)",
  "REPAIR CLAUSE": "Repair Clause",
  "REPAIR_CLAUSE": "Repair Clause",
  "REPARTITION CODE": "Allocation code",
  "REPARTITION LABEL": "Allocation label",
  "REPARTITION_CODE": "Allocation code",
  "REPARTITION_LABEL": "Allocation label",
  "REPORT COMMENTS": "Comments",
  "REPORT DATE": "Reporting Date",
  "REPORT_COMMENTS": "Comments",
  "REPORT_EXPORT_ABE": "Reporting Bank Movements",
  "REPORT_EXPORT_ALE": "Reporting Manage Alerts",
  "REPORT_EXPORT_AUT": "Reporting Audit Trail Report",
  "REPORT_EXPORT_BUI": "Reporting Property Fact Sheet Export",
  "REPORT_EXPORT_BUM": "Reporting Budget Monitoring Report",
  "REPORT_EXPORT_CBP": "Reporting Manage Collection Period Breakdown",
  "REPORT_EXPORT_CMO": "Reporting Rental Income Collection Report",
  "REPORT_EXPORT_CVA": "Reporting Net Asset Value Report",
  "REPORT_EXPORT_DEK": "Reporting Deka Reporting",
  "REPORT_EXPORT_DOP": "Reporting Outstanding payments",
  "REPORT_EXPORT_DTZ": "Reporting Rental Situation",
  "REPORT_EXPORT_EVP": "Reporting RE Portfolio Evolution",
  "REPORT_EXPORT_FOS": "Reporting Manage Investor Data",
  "REPORT_EXPORT_FUN": "Reporting Fund Fact Sheet Export",
  "REPORT_EXPORT_Glo": "Reporting Global Parameters",
  "REPORT_EXPORT_HOT": "Reporting Holdover Tenancy Report",
  "REPORT_EXPORT_LEM": "Reporting Lease Monitoring Report",
  "REPORT_EXPORT_LES": "Reporting Commercial Lease Schedule",
  "REPORT_EXPORT_MDM": "Reporting Manage Data Mapping",
  "REPORT_EXPORT_MIT": "Reporting Manage Interfaces",
  "REPORT_EXPORT_OPB": "Reporting Outstanding Balance and Amount Received Evolution",
  "REPORT_EXPORT_OPM": "Reporting Outstanding Payment Report",
  "REPORT_EXPORT_OUT": "Reporting Aging Schedule",
  "REPORT_EXPORT_PAT": "Reporting Pending Arbitrage Report",
  "REPORT_EXPORT_PBA": "Reporting Property Balance",
  "REPORT_EXPORT_POB": "Reporting RE Portfolio Breakdown Report",
  "REPORT_EXPORT_PPT": "Reporting Pending Acquisition Report",
  "REPORT_EXPORT_PVA": "Reporting Property Impairment Report",
  "REPORT_EXPORT_RES": "Reporting Detailed Rental Situation",
  "REPORT_EXPORT_RIM": "Reporting Rental Income Monitoring Report",
  "REPORT_EXPORT_RLE": "Reporting Manage Leases Data",
  "REPORT_EXPORT_RPA": "Reporting Manage Disposals",
  "REPORT_EXPORT_RPB": "Reporting Property Repository",
  "REPORT_EXPORT_RPF": "Reporting Fund Repository",
  "REPORT_EXPORT_RPM": "Reporting Manage ERVs",
  "REPORT_EXPORT_RPN": "Reporting Manage Net Book Values",
  "REPORT_EXPORT_RPP": "Reporting Manage Acquisitions",
  "REPORT_EXPORT_RPT": "Reporting Manage Custom Reports",
  "REPORT_EXPORT_RPV": "Reporting Manage Valuation Data",
  "REPORT_EXPORT_RPY": "Reporting Manage Yields",
  "REPORT_EXPORT_SDG": "Reporting Security Deposits Guarantees",
  "REPORT_EXPORT_TEM": "Reporting Tenancy Monitoring Report",
  "REPORT_EXPORT_TET": "Reporting Tenant Churn Report",
  "REPORT_EXPORT_TRH": "Reporting Transaction History Report",
  "REPORT_EXPORT_TS": "Reporting Tenancy Schedule",
  "REPORT_EXPORT_USL": "Reporting Manage Users",
  "REPORT_EXPORT_VAC": "Reporting Vacancy Monitoring Report",
  "REPORT_EXPORT_VAU": "Reporting Vacant Unit Summary",
  "REPORT_EXPORT_YIM": "Reporting Yield Monitoring Report",
  "REPORT_INTERFACE": "Tenancy Schedule",
  "REPOSITORY ARBITRAGE": "Disposal Code",
  "REPOSITORY BUILDING": "Property Repository",
  "REPOSITORY FUND": "Fund Repository",
  "REPOSITORY PORTFOLIO": "Repository Portfolio",
  "REPOSITORY PURCHASE": "Acquisition Repository",
  "REPOSITORY SECTOR": "Market Geography Repository",
  "REPOSITORY_ARBITRAGE": "Disposition Repository",
  "REPOSITORY_BUILDING": "Property Repository",
  "REPOSITORY_FUND": "Fund Repository",
  "REPOSITORY_PORTFOLIO": "Repository Portfolio",
  "REPOSITORY_PURCHASE": "Acquisition Repository",
  "REPOSITORY_SECTOR": "Market Geography Repository",
  "RESERVE FOR DEPRECIATION": "Depreciation Reserve (€)",
  "RESERVE_FOR_DEPRECIATION": "Depreciation Reserve (€)",
  "RESIDENTIAL ABATEMENT": "Rent-free Period (€)",
  "RESIDENTIAL ANNUAL AMOUNT": "Annual Amount (€/year)",
  "RESIDENTIAL AREA": "Residential Area (m²)",
  "RESIDENTIAL GROSS HEADLINE RENT": "Headline Rent (€/year)",
  "RESIDENTIAL GROSS HEADLINE RENT M2": "Headline Rent by m² (€/m²/year)",
  "RESIDENTIAL REAL ESTATE": "Residential Real Estate",
  "RESIDENTIAL RENTED AREA": "Occupied Area (m²)",
  "RESIDENTIAL TOTAL AREA": "Total Rentable Area (m²)",
  "RESIDENTIAL VACANT AREA": "Vacant Area (m²)",
  "RESIDENTIAL_AREA": "Residential Area (m²)",
  "RESULT PENDING ASSIGN BENEFIT": "Unallocated Profit (€)",
  "RESULT PENDING ASSIGN DEFICIT": "Unallocated Losses (€)",
  "RESULT_PENDING_ASSIGN_BENEFIT": "Unallocated Profit (€)",
  "RESULT_PENDING_ASSIGN_DEFICIT": "Unallocated Losses (€)",
  "RETAIL AREA": "Retail Area (m²)",
  "RETAIL_AREA": "Retail Area (m²)",
  "RETAINED EARNINGS": "Retained Earnings (€)",
  "RETAINED_EARNINGS": "Retained Earnings (€)",
  "RETURN ON EQUITY LEVERED": "Return on Equity (levered)",
  "RETURN ON EQUITY UNLEVERED": "Return on Equity (unlevered)",
  "REVERSION TO RENTAL MARKET VALUE": "Reversion to Estimated Rental Value",
  "REVERSIONARY POTENTIAL": "Reversionary Potential",
  "REVERSIONARY YIELD": "Net Reversionary Yield",
  "REVERSIONARY_POTENTIAL": "Reversionary Potential",
  "REVISION PERIODICITY CODE": "Rent Review Frequency",
  "REVISION_PERIODICITY_CODE": "Rent Review Frequency Code",
  "RISK NEXT DATE": "Next Lease Break Date",
  "ROW LABEL": "Row label",
  "ROW_CODE": "Row code",
  "ROW_LABEL": "Row label",
  "RRE ABATEMENT": "Residential Real Estate - Rent-free Period (€)",
  "RRE GROSS HEADLINE RENT": "Residential Real Estate - Headline Rent (€/year)",
  "RRE GROSS HEADLINE RENT M2": "Residential Real Estate - Headline Rent by m² (€/m²/year)",
  "RRE RENTED AREA": "Residential Real Estate - Occupied Area (m²)",
  "RRE TOTAL AREA": "Residential Real Estate - Total Area (m²)",
  "RRE VACANT AREA": "Residential Real Estate - Vacant Area (m²)",
  "RRE_ABATEMENT": "Residential Real Estate - Rent-free Period (€)",
  "RRE_GROSS_HEADLINE_RENT": "Residential Real Estate - Headline Rent (€/year)",
  "RRE_GROSS_HEADLINE_RENT_M2": "Residential Real Estate - Headline Rent by m² (€/m²/year)",
  "RRE_OCCUPIED_AREA_SQM": "Residential Real Estate - Occupied Area (m²)",
  "RRE_TOTAL_AREA_SQM": "Residential Real Estate - Total Area (m²)",
  "RRE_VACANT_AREA_SQM": "Residential Real Estate - Vacant Area (m²)",
  "SALE ACT SIGNING DATE": "Date Signed of Conveyancing Document",
  "SALE AGREEMENT DATE": "Sale Agreement Date",
  "SALE DECISION DATE": "Sale Decision Date",
  "SALE EXPECTED DATE": "Sale Expected Date",
  "SALE EXPECTED PRICE": "Sale Expected Price (€)",
  "SALE PRICE AMOUNT": "Sale Price Amount (€)",
  "SALE PROGRES STATE": "Disposal Status",
  "SALE VACANT AREA": "Vacant Area for Disposal Purposes (m²)",
  "SALE VACANT AREA RATE": "% of Total Vacancy",
  "SALE_ACT_SIGNING_DATE": "Date Signed of Conveyancing Document",
  "SALE_ACT_SIGNING_DATE_mismatch": "Date Signed of Conveyancing Document mismatch",
  "SALE_ACT_SIGNING_DATE_required": "Date Signed of Conveyancing Document required",
  "SALE_AGREEMENT_DATE": "Sale Agreement Date",
  "SALE_AGREEMENT_DATE_mismatch": "Sale Agreement Date mismatch",
  "SALE_AGREEMENT_DATE_required": "Sale Agreement Date required",
  "SALE_COMMITMENT_DATE": "Commitment Agreement Date",
  "SALE_COMMITMENT_DATE_mismatch": "Commitment Agreement Date mismatch",
  "SALE_DECISION_DATE": "Sale Decision Date",
  "SALE_DECISION_DATE_mismatch": "Sale Decision Date mismatch",
  "SALE_EXPECTED_DATE": "Sale Expected Date",
  "SALE_EXPECTED_PRICE": "Sale Expected Price (€)",
  "SALE_EXPECTED_PRICE_mismatch": "Sale Expected Price mismatch",
  "SALE_EXPECTED_PRICE_WOU": "Sale Expected Price",
  "SALE_PRICE_AMOUNT": "Sale Price Amount (€)",
  "SALE_PRICE_AMOUNT_mismatch": "Sale Price Amount mismatch",
  "SALE_PRICE_AMOUNT_WOU": "Sale Price Amount",
  "SALE_VACANT_AREA": "Vacant for Sale Area (m²)",
  "SALE_VACANT_AREA_RATE": "% of Total Vacancy (%)",
  "SEARCH": "Search",
  "SEARCH CRITERIA": "Search Criteria",
  "SECTOR CODE": "Market Geography Code",
  "SECTOR LABEL": "Market Geography",
  "SECTOR_CODE": "Market Geography Code",
  "SECURIT DEPOSIT IN MONTH": "Security deposit in month",
  "SECURIT_DEPOSIT_IN_MONTH": "Security deposit in month",
  "SECURITY DEPOSIT BANK ACCOUNT": "Security Deposit Bank Account",
  "SECURITY DEPOSIT CALLS": "Security deposit call (€)",
  "SECURITY DEPOSIT RECEIVABLE": "Security deposit receivable (€)",
  "SECURITY DEPOSIT REFUNDS": "Security deposit refunds (€)",
  "SECURITY DEPOSITS GUARANTEES": "Security Deposits Guarantees",
  "SECURITY_DEPOSIT_CALLS": "Security deposit call (€)",
  "SECURITY_DEPOSIT_RECEIVABLE": "Security deposit receivable (€)",
  "SECURITY_DEPOSIT_REFUNDS": "Security deposit refunds (€)",
  "SelectLaunchDate": "Select a launch date for the specific interface",
  "SelectReportModel": "Select a standard report model",
  "SELLER CHARGED NET PRICE": "Seller's Net Price (€)",
  "SELLER NAME": "Seller",
  "SELLER_CHARGED_NET_PRICE": "Seller's Net Price (€)",
  "SELLER_CHARGED_NET_PRICE_mismatch": "Seller's Net Price mismatch",
  "SELLER_CHARGED_NET_PRICE_WOU": "Seller's Net Price",
  "SELLER_NAME": "Seller",
  "SEPTEMBER_AMOUNT": "September (€)",
  "SERVICE CHARGES": "Service Charges (€/year)",
  "SERVICE CHARGES CAPS": "Service Charges Caps (€/year)",
  "SERVICE CHARGES CAPS M2": "Service Charges Caps per m² (€/m²/year)",
  "SERVICE CHARGES M2": "Service Charges by m² (€/m²/year)",
  "SERVICE LABEL": "Service Label",
  "SERVICE_CHARGES": "Service Charges (€/year)",
  "SERVICE_CHARGES_CAPS": "Service Charges Caps (€/year)",
  "SERVICE_CHARGES_CAPS_M2": "Service Charges Caps per m² (€/m²/year)",
  "SERVICE_CHARGES_M2": "Service Charges by m² (€/m²/year)",
  "SERVICE_CODE": "Service code",
  "SERVICE_LABEL": "Service Label",
  "SHARE ACQUISITION PRICE": "Share Acquisition Price (€)",
  "SHARE CAPITAL": "Share Capital (€)",
  "SHARE PREMIUM": "Share Premium (€)",
  "SHARE PREMIUM DISTRIBUTED": "Share Premium Distributed (€)",
  "SHARE_PREMIUM": "Share Premium (€)",
  "SHARE_WITH_OTHER_USERS": "Shared With The Other Users",
  "SHAREHOLDER CURRENT ACCOUNT": "Shareholder Current Account (€)",
  "SHAREHOLDER CURRENT ACNT": "Shareholder Current Account (if applicable)",
  "SHAREHOLDER LOANS CONTRIBUT": "Shareholder Loan Account (if applicable)",
  "SHAREHOLDER LOANS CONTRIBUTED": "Shareholder Loans Contributed (€)",
  "SHAREHOLDER_CURRENT_ACCOUNT": "Shareholder Current Account (€)",
  "SHAREHOLDER_LOAN_ACCOUNT": "Shareholder Loans Contributed (€)",
  "SHAREHOLDER_LOANS_CONTRIBUTE": "Shareholder Loans Contributed (€)",
  "SHAREHOLDER_LOANS_CONTRIBUTED": "Shareholder Loans Contributed (€)",
  "SHOW_ALL": "All",
  "SIMPLIFIED P AND L": "Simplified P&L",
  "SIRET NUMBER": "Business Identification Number",
  "SIRET_NUMBER": "Business Identification Number",
  "SITE NAME": "Site name",
  "SITE_CODE": "Site Code",
  "SITE_NAME": "Site name",
  "SOCIAL DATA": "Social Data",
  "SOCIAL_DATA": "Social Data",
  "SOLD_OWNER_CODE": "Sold Owner Code",
  "SOLD_OWNER_NAME": "Sold Owner Name",
  "SPACE PRODUCTIVITY": "Space Productivity (€/m²/year)",
  "SPACE_PRODUCTIVITY": "Space Productivity (€/m²/year)",
  "SQM": "m²",
  "ST1": "Sub Total 1",
  "ST2": "Sub Total 2",
  "ST3": "Sub Total 3",
  "STAIRCASE": "Staircase Number",
  "START MANAGEMENT DATE": "Date Managed from",
  "START_DATE": "Start Date",
  "START_MANAGEMENT_DATE": "Date Managed from",
  "START_MANAGEMENT_DATE_mismatch": "Date Managed from mismatch",
  "STATUORY RESERVES": "Statuory Reserves (€)",
  "STATUORY_RESERVES": "Statuory Reserves (€)",
  "STATUS LEASE": "Lease Status",
  "STATUS PURCHASE": "Status",
  "STATUS SALE": "Acquisition Status",
  "STATUS_LEASE": "Lease Status",
  "STATUT": "Acquisition Status",
  "STATUTORY INTERESTS": "Statutory Interests (€)",
  "STEP 1 START DATE": "Step 1 Start Date",
  "STEP 2 START DATE": "Step 2 Start Date",
  "STEP 3 START DATE": "Step 3 Start Date",
  "STEP 4 START DATE": "Step 4 Start Date",
  "STEP 5 START DATE": "Step 5 Start Date",
  "STEP 6 START DATE": "Step 6 Start Date",
  "STEP_1_START_DATE": "Step 1 Start Date",
  "STEP_2_START_DATE": "Step 2 Start Date",
  "STEP_3_START_DATE": "Step 3 Start Date",
  "STEP_4_START_DATE": "Step 4 Start Date",
  "STEP_5_START_DATE": "Step 5 Start Date",
  "STEP_6_START_DATE": "Step 6 Start Date",
  "STEPPED RENT": "Stepped Rent (yes/no)?",
  "STEPPED RENT 1": "Stepped Rent 1 (€)",
  "STEPPED RENT 2": "Stepped Rent 2 (€)",
  "STEPPED RENT 3": "Stepped Rent 3 (€)",
  "STEPPED RENT 4": "Stepped Rent 4 (€)",
  "STEPPED RENT 5": "Stepped Rent 5 (€)",
  "STEPPED RENT 6": "Stepped Rent 6 (€)",
  "STEPPED_RENT": "Stepped Rent (yes/no)?",
  "STEPPED_RENT_1": "Stepped Rent 1 (€)",
  "STEPPED_RENT_2": "Stepped Rent 2 (€)",
  "STEPPED_RENT_3": "Stepped Rent 3 (€)",
  "STEPPED_RENT_4": "Stepped Rent 4 (€)",
  "STEPPED_RENT_5": "Stepped Rent 5 (€)",
  "STEPPED_RENT_6": "Stepped Rent 6 (€)",
  "STORAGE AREA": "Storage Area (m²)",
  "STORAGE_AREA": "Storage Area (m²)",
  "STRUCTURE CAPITAL": "Capital Structure",
  "SUB_BUDGET_CODE": "Sub-Budget Code",
  "SUB_BUDGET_LABEL": "Sub-Budget Label",
  "SUB_DESTINATION_CODE": "Sub-destination Code",
  "SUB_DESTINATION_LABEL": "Sub-destination Label",
  "SUBMISSION_DATE": "Sending Date",
  "SubmissionDate": "Submission Date",
  "SubmissionDate1": "Submission Date 1",
  "SubmissionDate2": "Submission Date 2",
  "SUBSIDIARY OF GROUP": "Subsidiary of the group",
  "SUBSIDIARY_OF_GROUP": "Subsidiary of the group",
  "SUSPENSE ACCOUNT": "Suspense Account (€)",
  "SUSPENSE_ACCOUNT": "Suspense Account (€)",
  "tabCollectionMonitoringBuilding": "By Property",
  "tabCollectionMonitoringBuildingNature": "By Property",
  "tabCollectionMonitoringBuildingNatureSum": "By Property Type Running Total",
  "tabCollectionMonitoringBuildingSum": "By Property Running Total",
  "tabCollectionMonitoringFund": "By Fund Entity Name",
  "tabCollectionMonitoringFundSum": "By Fund Entity Name Running Total",
  "tabCollectionMonitoringInvoice": "By Invoice",
  "tabCollectionMonitoringInvoiceSum": "By Invoice Running Total",
  "tabCollectionMonitoringInvoicingItem": "By Invoice Line",
  "tabCollectionMonitoringInvoicingItemSum": "By Invoice Line Running Total",
  "tabCollectionMonitoringLease": "By Lease",
  "tabCollectionMonitoringLeaseSum": "By Lease Running Total",
  "tabCollectionMonitoringOwner": "By Owner",
  "tabCollectionMonitoringOwnerSum": "By Owner Running Total",
  "tabCollectionMonitoringPortfolio": "By Portfolio",
  "tabCollectionMonitoringPortfolioSum": "By Portfolio Running Total",
  "tabHoldoverTenancyBuildingNature": "By Property Type",
  "tabHoldoverTenancyLeaseNature": "By Lease Type",
  "TABLE BY BUILDING NATURE": "Table by Property Type",
  "TABLE BY PROPERTY TYPE": "Table by Property Class",
  "TABLE NAME": "Table Name",
  "TABLE_NAME": "Table Name",
  "Table1Attr1": "Property Name",
  "Table1Attr2": "Address",
  "Table1Attr3": "Portfolio",
  "Table1Attr4": "Property Summary as at",
  "Table1Attr5": "Property Code",
  "Table2Attr1": "Property Type",
  "Table2Attr10": "Total Net Floor Area (NFA)",
  "Table2Attr11": "Gross Market Value",
  "Table2Attr12": "Net Living Area",
  "Table2Attr13": "Tenant Count",
  "Table2Attr14": "Usable Floor Area (UFA)",
  "Table2Attr15": "Lease Count",
  "Table2Attr16": "Weighted Usable Area (WUA)",
  "Table2Attr17": "Rental Unit Count",
  "Table2Attr18": "Gross Leasing Area (GLA)",
  "Table2Attr19": "Parking Spaces Count",
  "Table2Attr2": "Year Built",
  "Table2Attr3": "Secondary Use(s)",
  "Table2Attr4": "Date Purchased",
  "Table2Attr5": "Building Count",
  "Table2Attr6": "Tenure",
  "Table2Attr7": "Number of Stories",
  "Table2Attr8": "Net Market Value",
  "Table2Attr9": "as at",
  "Table3Attr2": "Rent Incentives",
  "Table3Attr4": "Outstanding Rent",
  "Table3Attr5": "Collected Rent",
  "Table3Attr8": "Net Net Rent",
  "Table4Attr1": "Non Recoverable Expenses",
  "Table4Attr2": "Recoverable Expenses",
  "Table4Attr3": "Of which: Recovered",
  "Table4Attr4": "Of which: Not recovered",
  "Table4Attr5": "Total Operating Expenses (OPEX)",
  "Table4Attr6": "Service Charges",
  "tabLeaseMonitoringBuildingNature": "By Property Type",
  "tabLeaseMonitoringLeaseNature": "By Lease Type",
  "tabPhysicalVacancyByM2Financial": "Financial Vacancy",
  "tabPhysicalVacancyByM2M2": "Vacant Area",
  "tabRealEstatePortfolioBreakdownByNature": "Table by Property Type",
  "tabRealEstatePortfolioBreakdownByType": "Table by Property Class",
  "tabRealEstatePortfolioEvolutionBuildingNature": "By Property Type",
  "tabRealEstatePortfolioEvolutionYear": "By Year",
  "tabRegularChargesMonitoringBuilding": "By Property",
  "tabRegularChargesMonitoringBuildingNature": "By Property Type",
  "tabRegularChargesMonitoringInvoicingItem": "By Invoice Line",
  "tabRegularChargesMonitoringLease": "By Lease",
  "tabRentalIncomeMonitoringBuildingNature": "By Property Type",
  "tabRentalIncomeMonitoringYear": "By Year",
  "tabYieldMonitoring": "Yield Monitoring Report",
  "tabYieldMonitoringCapiGross": "Gross Capitalization Rates",
  "tabYieldMonitoringGlobalReturns": "Total Returns",
  "tabYieldMonitoringGross": "Gross Initial Yields",
  "tabYieldMonitoringNet": "Net Initial Yields",
  "TARGET YIELD": "Target Yield",
  "TAX": "Tax",
  "TAX EXEMPTION": "Tax Exemption (yes/no)?",
  "TAX_EXEMPTION": "Tax Exemption (yes/no)?",
  "tbFundDataStartDate_mismatch": "Update Date is incorrect",
  "tbFundDataStartDate_required": "Update Date required",
  "tbInceptionDate_mismatch": "Inception Date is incorrect",
  "tbInceptionDate_required": "Inception Date required",
  "TEN GONE NO LIT OUT PAY RATE": "Outstanding Payment Rate - Other Moved-out Tenants",
  "TEN PRES NO LIT OUT PAY RATE": "Outstanding Payment Rate - Other In-place Tenants",
  "TEN PRESENT LIT OUT PAY RATE": "Outstanding Payment Rate - In-place Tenants in Litigation",
  "TEN_GONE_NO_LIT_OUT_PAY_RATE": "Outstanding Payment Rate - Other Moved-out Tenants",
  "TEN_PRES_NO_LIT_OUT_PAY_RATE": "Outstanding Payment Rate - Other In-place Tenants",
  "TEN_PRESENT_LIT_OUT_PAY_RATE": "Outstanding Payment Rate - In-place Tenants in Litigation",
  "TENANCY MONITORING": "Tenancy Monitoring Report",
  "TENANT": "Tenant",
  "TENANT ARRIVAL DATE": "Tenant Move-in Date",
  "TENANT CODE": "Tenant Code",
  "TENANT EXIT DATE": "Tenant Move-out Date",
  "TENANT GONE LIT OUT PAY RATE": "Outstanding Payment Rate - Moved-out Tenants in Litigation",
  "TENANT GONE OUT PAY RATE": "Outstanding Payment Rate - Moved-out Tenants",
  "TENANT INDUSTRY": "Tenant Industry",
  "TENANT NAME": "Tenant Name",
  "TENANT PRESENCE": "Tenant Presence",
  "TENANT PRESENT OUT PAY RATE": "Outstanding Payment Rate - In-place Tenants",
  "TENANT TOTAL": "Total",
  "TENANT TURNOVER": "Tenant Churn Report",
  "TENANT_ADDRESS1": "Tenant Address",
  "TENANT_ADDRESS2": "Tenant Other Address",
  "TENANT_ARRIVAL_DATE": "Tenant Move-in Date",
  "TENANT_CITY": "Tenant Location",
  "TENANT_CODE": "Tenant Code",
  "TENANT_COUNTRY": "Tenant Country",
  "TENANT_EXIT_DATE": "Tenant Move-out Date",
  "TENANT_GONE_LIT_OUT_PAY_RATE": "Outstanding Payment Rate - Moved-out Tenants in Litigation",
  "TENANT_GONE_OUT_PAY_RATE": "Outstanding Payment Rate - Moved-out Tenants",
  "TENANT_HOLDING_COMPANY": "Tenant Holding Company",
  "TENANT_IMPROVEMENTS": "Tenant Improvements",
  "TENANT_IMPROVEMENTS_ACCUMULATED_DEPRECIATION": "Tenant Improvements Accumulated Depreciation",
  "TENANT_INDUSTRY": "Tenant Industry",
  "TENANT_INDUSTRY_CODE": "Tenant Industry Code",
  "TENANT_NAME": "Tenant Name",
  "TENANT_POSTAL_CODE": "Tenant Postal Code",
  "TENANT_PRESENCE": "Tenant Presence",
  "TENANT_PRESENT_OUT_PAY_RATE": "Outstanding Payment Rate - In-place Tenants",
  "TENANTS": "Tenants",
  "TENURE": "Tenure",
  "TERM": "Invoiced period",
  "TERM DATE": "Term Date",
  "TERM_DATE": "Term Date",
  "TERMINATION DATE": "Termination or Disposal Date",
  "TERMINATION_DATE": "Termination or Disposal Date",
  "tfColumnLabel": "Column label",
  "tfColumnName": "Column name",
  "tfExpressionVal": "Basic expression",
  "tfMappingVal": "Mapping",
  "THIRD PARTY FEES AMOUNT": "Other Fees (Broker, etc.) (€)",
  "THIRD_PARTY_FEES_AMOUNT": "Other Fees (Broker, etc.) (€)",
  "THIRD_PARTY_FEES_AMOUNT_mismatch": "Other Fees mismatch",
  "THIRD_PARTY_FEES_AMOUNT_WOU": "Other Fees (Broker, etc.)",
  "THOUSANDS": "Thousands",
  "titleCreatUpdateReportExcel": "Create / modify the sending of an Excel/PDF report",
  "titleInterfaceAccounting": "Create or update the data stream - Accounting",
  "titleInterfaceLowCode": "Create or update the data stream",
  "titleInterfaceSpecific": "Update specific interface",
  "titleInterfaceTenancySchedule": "Create or update the data stream - Tenancy Schedule",
  "TOP ADVANCE PAY": "Overpayments Ranking (K€) >",
  "TOP LEVEL BUDGET SECTION": "Budget Category",
  "TOP OUTSTAND PAY": "Outstanding Payment Ranking (K€) >",
  "TOP_ADVANCE_PAY": "Overpayments Ranking > {0} K€",
  "TOP_LEVEL_BUDGET_SECTION": "Budget Category",
  "TOP_OUTSTAND_PAY": "Outstanding Payment Ranking > {0} K€",
  "TOTAL": "Total",
  "TOTAL ACCUMULATED DEPRECIATION": "Total Accumulated Depreciation (€)",
  "TOTAL AREA PERCENT": "% of Total Area",
  "TOTAL CASH DISTRIBUTED": "Total Cash Distributed (€)",
  "TOTAL EQUITY CONTRIBUTED": "Total Equity Contributed (€)",
  "TOTAL EQUITY FINANCING": "Total Equity Financing (€)",
  "TOTAL GROSS DEBT": "Total Gross Debt (€)",
  "TOTAL INVESTMENT PRICE": "Total Investment Price (€)",
  "TOTAL INVESTMENT VALUE": "Total Investment Value (€)",
  "TOTAL INVESTOR DISTRIBUTIONS": "Total Investor Distributions (€)",
  "TOTAL LEASES NUMBER PERCENT": "% of Total Lease Count",
  "TOTAL NET FLOOR AREA": "Total Net Floor Area (NFA) (m²)",
  "TOTAL NET FLOOR AREA WOU": "Total Net Floor Area (NFA)",
  "TOTAL PORTFOLIO": "Total Portfolio",
  "TOTAL RENT": "Total Rent (€/year)",
  "TOTAL RENT M2": "Total Rent per m² (€/m²/year)",
  "TOTAL RENTAL EXPENSES": "Total OPEX (€/year)",
  "TOTAL RENTAL EXPENSES WOU": "Total Operating Expenses (OPEX)",
  "TOTAL RETURN": "Total Return",
  "TOTAL RETURN BREAKDOWN": "Total Return Breakdown",
  "TOTAL RETURN FUND": "Total Return",
  "TOTAL UNITS NUMBER PERCENT": "% of Total Rental Unit Count",
  "TOTAL UNREALIZED GAINS AND LOSSES": "Total Unrealized Gains and Losses (€)",
  "TOTAL VACANCY": "Total Vacancy",
  "TOTAL_ACCUMULATED_DEPRECIATION": "Total Accumulated Depreciation (€)",
  "TOTAL_AMOUNT": "Total (€)",
  "TOTAL_AREA_PERCENT": "% of Total Area",
  "TOTAL_INVESTMENT_VALUE": "Total Investment Value (€)",
  "TOTAL_LEASES_NUMBER_PERCENT": "% of Total Lease Number",
  "TOTAL_RENT": "Total Rent (€/year)",
  "TOTAL_RENT_M2": "Total Rent per m² (€/m²/year)",
  "TOTAL_RENTAL_EXPENSES": "Total OPEX (€/year)",
  "TOTAL_RENTAL_EXPENSES_WOU": "Total OPEX",
  "TOTAL_RETURN": "Total Return",
  "TOTAL_SQM": "Total (m²)",
  "TOTAL_UNITS_NUMBER_PERCENT": "% of Total Unit Number",
  "TOTAL_UNREAL_GAINS_LOSSES": "Total Unrealized Gains and Losses (€)",
  "TOTAL_UNREALIZED_GAINS_AND_LOSSES": "Total Unrealized Gains and Losses (€)",
  "TOTAL_USABLE_FLOOR_AREA_SQM": "Total Usable Floor Area (UFA) (m²)",
  "TRADE NAME": "Trade Name",
  "TRADE REGISTER NUMBER": "Trade Register Number",
  "TRADE_NAME": "Trade Name",
  "TRADE_REGISTER_NUMBER": "Trade Register Number",
  "TRANSFERED SHARES NUMBER": "Number of Shares Transfered",
  "TRANSFERED SHARES PERCENTAGE": "% of Shares Transfered",
  "TRANSFERED_SHARES_NUMBER": "Number of Shares Transfered",
  "TRANSFERED_SHARES_NUMBER_mismatch": "Number of Shares Transfered mismatch",
  "TRANSFERED_SHARES_PERCENTAGE": "% of Shares Transfered",
  "TRANSFERED_SHARES_PERCENTAGE_mismatch": "% of Shares Transfered mismatch",
  "trend": "Trend",
  "tt_DB_BUILDING_TOTAL_AREA_SQM": "The total area of the properties held within the portfolio as at the dashboard date",
  "tt_DB_BUILDINGSNUMBER": "Total number of properties held within the portfolio",
  "tt_DB_DURATION_TO_LEASE_END": "Average lease term remaining to the lease expiry weighted by each lease's headline rent in proportion to the total headline rent, expressed as years-months-days",
  "tt_DB_DURATION_TO_NEXT_BREAK": "Average lease term remaining to the next break option date weighted by each lease's headline rent in proportion to the total headline rent, expressed as years-months-days",
  "tt_DB_EFFECTIVE_GROSS_INITIAL_YIELD": "The gross effective rent at portfolio-level expressed as a % of the gross market value at the report date",
  "tt_DB_EFFECTIVE_NET_INITIAL_YIELD": "The net effective rent at portfolio-level expressed as a % of the gross market value at the report date",
  "tt_DB_FAIR_MARKET_RENT": "The total rent achievable at portfolio-level should each individual rental unit be let at ERV, gross of non recoverable operating expenses",
  "tt_DB_FINANCIAL_OCCUPANCY_RATE": "The ratio of the total rent net of any tenant allowance applicable and non recoverable operating expenses to the total rent achievable at portfolio-level should each vacant unit at the dashboard date be let at ERV",
  "tt_DB_FINANCIAL_VACANCY": "The total ERV of the current vacant rental units as at the dashboard date",
  "tt_DB_FINANCIAL_VACANCY_RATE": "The total ERV of the current vacant rental units as at the report date expressed as a % of the total rent achievable at portfolio-level should each vacant unit at the dashboard date be let at ERV",
  "tt_DB_GROSS_EFFECTIVE_RENT": "The total rent at portfolio-level net of any tenant allowance applicable, gross of non recoverable operating expenses",
  "tt_DB_GROSS_HEADLINE_RENT": "The total rent achievable at portfolio-level after all the tenant allowances have ended, gross of non recoverable operating expenses",
  "tt_DB_GROSS_MARKET_VALUE": "Sum of the gross market values of the properties held within the portfolio as at the dashboard date",
  "tt_DB_GROSS_POTENTIAL_RENT": "The total rent achievable at portfolio-level should each vacant unit at the report date be let at ERV, gross of non recoverable operating expenses",
  "tt_DB_GROSS_REVERS_POTENT_OCCUP_AREA": "The portfolio-level ratio of ERV on occupied area to the gross headline rent, expressed as a % of the latter",
  "tt_DB_GROSS_REVERS_POTENT_TOTAL_PROP": "The portfolio-level ratio of ERV on total area to the gross headline rent, expressed as a % of the latter",
  "tt_DB_GROSS_REVERSIONARY_YIELD": "The gross market rent at portfolio-level expressed as a % of the gross market value at the report date",
  "tt_DB_HEADLINE_GROSS_INITIAL_YIELD": "The gross headline rent at portfolio-level expressed as a % of the gross market value at the report date",
  "tt_DB_HEADLINE_NET_INITIAL_YIELD": "The net headline rent at portfolio-level expressed as a % of the gross market value at the report date",
  "tt_DB_MARKET_VALUE_EXCL_DUTIES_VAT": "Sum of the net market values of the properties held within the portfolio as at the dashboard date",
  "tt_DB_NET_EFFECTIVE_RENT": "The total portfolio-level rent net of any tenant allowance applicable, net of non recoverable operating expenses",
  "tt_DB_NET_HEADLINE_RENT": "The total rent achievable at portfolio-level after all the tenant allowances have ended, net of non recoverable operating expenses",
  "tt_DB_NET_MARKET_RENT": "The total rent achievable at portfolio-level should each individual rental unit be let at ERV, net of non recoverable operating expenses",
  "tt_DB_NET_POTENTIAL_RENT": "The total rent achievable at portfolio-level should each vacant unit at the report date be let at ERV, net of non recoverable operating expenses",
  "tt_DB_NET_REVERSIONARY_YIELD": "The net market rent at portfolio-level expressed as a % of the gross market value at the report date",
  "tt_DB_NUMBER_OF_VACANT_UNITS": "Total count of vacant rental units from the properties held within the portfolio as at the dashboard date",
  "tt_DB_PHYSICAL_OCCUPANCY_RATE": "The occupied area expressed as a % of the total area of the properties held within the portfolio as at the dashboard date",
  "tt_DB_POTENTIAL_GROSS_INITIAL_YIELD": "The gross potential rent at portfolio-level expressed as a % of the gross market value at the report date",
  "tt_DB_POTENTIAL_NET_INITIAL_YIELD": "The net potential rent at portfolio-level expressed as a % of the gross market value at the report date",
  "tt_DB_RENTED_AREA": "Total occupied area of the properties held within the portfolio as at the dashboard date",
  "tt_DB_VACANCYRATE": "The vacant area expressed as a % of the total area of the properties held within the portfolio as at the dashboard date",
  "tt_DB_VACANT_AREA_SQM": "Total vacant area of the properties held within the portfolio as at the dashboard date",
  "tt_FUND_GROSS_HEADLINE_RENT": "The total gross headline rent of the properties held within the selected company and underlying vehicles (at share)",
  "tt_OUT_INVOICING_INCL_TAX_AMOUNT": "All the amounts invoiced for which both the accounting date and the due date are prior or equal to the reporting date",
  "tt_OUT_OUTSTAND_PAY_INC_TAX_AMOUNT": "The difference between the amount invoiced and the amount received",
  "tt_OUT_OVERPAYMENTS_INC_TAX_AMOUNT": "The amounts received for which the due date is after the end of the period and the accounting date is prior or equal to the reporting date",
  "tt_OUT_PAYMENT_INCL_TAX_AMOUNT": "All the amounts received for which the accounting date is prior or equal to the reporting date",
  "tt_PVA_GROSS_BOOK_VALUE": "The acquisition price of the property added with cumulated CAPEX amounts until the cut-off date",
  "tt_TEMEND_CURRENT_ANNUAL_RENT_AMOUNT": "The annual contractual rent receivable from the latest lease as at the date the tenant moved out",
  "tt_TEMEND_RENTED_AREA": "The area from the latest lease as at the date the tenant moved out",
  "tt_TEMRELETTING_PREV_LEASE_ANNUAL_RENT_AMOUNT": "The annual contractual rent receivable from the previous lease as at the date the tenant moved out",
  "tt_TEMRELETTING_PREVIOUS_LEASE_AREA_SQM": "The area from the previous lease as at the date the tenant moved out",
  "tt_TEMRENEWAL_PREV_LEASE_ANNUAL_RENT_AMOUNT": "The annual contractual rent receivable from the previous lease as at the lease expiry",
  "tt_TEMRENEWAL_PREVIOUS_LEASE_AREA_SQM": "The area from the previous lease as at the lease expiry",
  "tt_YIM_FAIR_MARKET_RENT": "The total rent achievable at property-level should each individual rental unit be let at ERV, gross of non recoverable operating expenses",
  "ttADJUSTED_NET_BOOK_VALUE": "The net book value before impairment net of the user-defined impairment loss",
  "ttANNUAL_FINANCIAL_VACANCY": "The total ERV of the current vacant rental units as at the report date",
  "ttANNUAL_RENT_AMOUNT": "The annual contractual rent receivable gross of any tenant allowance applicable at the report date",
  "ttAVERAGE_VACANCY_RATE": "Average physical vacancy rate over the last 12 months from the report date",
  "ttBreakdownByPropertyType": "The total net market value of all the properties held within the selected company and underlying vehicles at share, broken down by property types",
  "ttBUILDING_TOTAL_AREA": "The total rentable area of the properties held within the selected company and underlying vehicles (at share)",
  "ttBUILDING_TOTAL_AREA_SQM": "The total area of the property as at the report start date",
  "ttBUILDINGS_COUNT": "Total number of properties held within the selected company and underlying vehicles (irrespective of shares held)",
  "ttCAPITAL_RETURN": "The change in asset capital value expressed as a % of the start-period net market value and the CAPEX over the period",
  "ttCashOnCashReturn": "Total cash distributed by the company expressed as a % of the total equity invested in the company",
  "ttCOLLECTION_RATE": "The amount received expressed as a % of the amount invoiced",
  "ttCURRENT_ANNUAL_RENT_AMOUNT": "The annual contractual rent receivable gross of any tenant allowance applicable at the report date",
  "ttDividendProfitDistributions": "If look-through company, net result after tax, if not, dividend distributed by the company",
  "ttDURATION": "The difference between the next lease break option date and the report date, expressed as years-months-days",
  "ttDURATION_TO_BREAK_DATE": "The difference between the next lease break option date and the report date, expressed as years-months-days",
  "ttDURATION_TO_LEASE_END": "The difference between the lease expiry and the report date, expressed as years-months-days",
  "ttECONOMIC_RENT": "The economic value of the lease calculated by straight lining the effective rent over the lease term to the next break option date accounting for any tenant allowance applicable",
  "ttECONOMIC_RENT_BY_HEADLINE_RENT": "The ratio of the economic rent to the headline rent of the lease",
  "ttEFFECTIVE_GROSS_CAPITAL_RATE": "The gross effective rent at property-level expressed as a % of the net market value",
  "ttEFFECTIVE_GROSS_INITIAL_YIELD": "The gross effective rent at property-level expressed as a % of the gross market value at the report date",
  "ttEFFECTIVE_NET_CAPITAL_RATE": "The net effective rent at property-level expressed as a % of the net market value",
  "ttEFFECTIVE_NET_INITIAL_YIELD": "The net effective rent at property-level expressed as a % of the gross market value at the report date",
  "ttEFFECTIVE_RENT": "The annual contractual rent receivable net of any tenant allowance applicable at the report date",
  "ttFAIR_MARKET_RENT": "The rent achievable should every leased rental unit be let at ERV",
  "ttFINANCIAL_OCCUPANCY_RATE": "The ratio of the total rent net of any tenant allowance applicable and non recoverable operating expenses to the total rent achievable at property-level should each vacant unit at the report date be let at ERV",
  "ttFINANCIAL_VACANCY": "The total ERV of the current vacant rental units as at the report date",
  "ttFINANCIAL_VACANCY_RATE": "The total ERV of the current vacant rental units as at the report date expressed as a % of the total rent achievable at property-level should each vacant unit at the report date be let at ERV",
  "ttFinancialOccupancySummary": "The financial occupancy summary of the properties held within the selected company and underlying vehicles (at share)",
  "ttFREED_LEASES_RATE": "The number of leases terminated during the report period expressed as a % of the total number of leases as at the report start date",
  "ttFREED_UNITS_AREA_RATE": "The area from the rental units vacated during the period expressed as a % of the total area as at the report start date",
  "ttFREED_UNITS_RATE": "The number of rental units vacated during the period expressed as a % of the total number of rental units as at the report start date",
  "ttFundPhysicalOccRate": "The occupied area expressed as a % of the total area of the properties held within the selected company and underlying vehicles (at share)",
  "ttGearingRatio": "The outstanding debt from external loans secured by the company expressed as a % of the total capital structure of the company",
  "ttGeographicalBreakdown": "The total net market value of all the properties held within the selected company and underlying vehicles at share, broken down by geographies",
  "ttGR_HEAD_RENT_BY_GR_BOOK_VAL": "The gross effective rent receivable expressed as a % of the gross book value of the properties held by the selected company",
  "ttGROSS_BOOK_VALUE": "The total gross book value of the properties held within the selected company and underlying vehicles (at share)",
  "ttGROSS_EFFECTIVE_RENT": "The total rent at property-level net of any tenant allowance applicable, gross of non recoverable operating expenses",
  "ttGROSS_HEADLINE_RENT": "The total rent achievable at property-level after all the tenant allowances have ended, gross of non recoverable operating expenses",
  "ttGROSS_POTENTIAL_RENT": "The total rent achievable at property-level should each vacant unit at the report date be let at ERV, gross of non recoverable operating expenses",
  "ttGROSS_REVERS_CAPITAL_RATE": "The gross market rent at property-level expressed as a % of the net market value",
  "ttGROSS_REVERS_POTENT_OCCUP_AREA": "The property-level ratio of ERV on occupied area to the gross headline rent, expressed as a % of the latter",
  "ttGROSS_REVERS_POTENT_TOTAL_PROP": "The property-level ratio of ERV on total area to the gross headline rent, expressed as a % of the latter",
  "ttGROSS_REVERSIONARY_YIELD": "The gross market rent at property-level expressed as a % of the gross market value at the report date",
  "ttGrossDebt": "The outstanding debt from external loans secured by the company",
  "ttGrossDebtUnderlyingVehicles": "The outstanding debt from external loans secured by the company's underlying vehicles",
  "ttGrossReceiptedRent": "The total effectif rent receivable year-to-date of the properties held within the selected company and underlying vehicles (at share)",
  "ttGrossReceiptedRentByGrossBookValue": "The gross effective rent receivable expressed as a % of the gross book value of the properties held by the selected company",
  "ttGrossReceiptedRentByNetMarketValue": "The gross effective rent receivable expressed as a % of the net market value of the properties held by the selected company",
  "ttHEADLINE_GROSS_CAPITAL_RATE": "The gross headline rent at property-level expressed as a % of the net market value",
  "ttHEADLINE_GROSS_INITIAL_YIELD": "The gross headline rent at property-level expressed as a % of the gross market value at the report date",
  "ttHEADLINE_NET_CAPITAL_RATE": "The net headline rent at property-level expressed as a % of the net market value",
  "ttHEADLINE_NET_INITIAL_YIELD": "The net headline rent at property-level expressed as a % of the gross market value at the report date",
  "ttHeadlineGrossInitialYield": "The gross headline rent expressed as a % of the net market value of the properties held by the selected company",
  "ttIMPAIRMENT_CHARGE": "The gross book value of each individual property net of the total accumulated depreciation amount and the impairment charge recorded in prior periods as at the cut-off date",
  "ttIMPAIRMENT_RATIO": "Unrealized losses on direct investment properties before impairment expressed as a % of the net book value",
  "ttINCENTIVE_RATE": "The ratio of tenant allowances straight lined over the period from the lease start date to the next break option date to the headline rent projected to the next break option date",
  "ttINCOME_RETURN": "The net operating income receivable over a period expressed as a % of the start-period net market value and the CAPEX over the period",
  "ttIncomeReturn": "Total income distributed by the company expressed as a % of the total equity invested in the company",
  "ttINITIAL_ANNUAL_RENT_AMOUNT": "The annual contractual rent receivable gross of any tenant allowance applicable as at the lease start date",
  "ttINVESTOR_SHARE_OF_NAV": "The net asset value of all the shares held by the selected investor",
  "ttInvestorShareOfNAV": "The net asset value of the total number of shares outstanding or all the shares held by the selected investor",
  "ttINVOICING_INCL_TAX_AMOUNT": "All the amounts invoiced for which both the accounting date and the due date are comprised within the reporting period",
  "ttLEASE_DURATION": "The difference between the lease expiry and the lease start date, expressed as years-months-days",
  "ttLEASE_TURNOVER_RATE": "The sum of the lease-based tenant move-in and tenant move-out rates divided in 2",
  "ttLEASES_NUMBER": "The total number of leases as at the report start date",
  "ttlGenerateInterfaceACS": "Manage Accounting Data Stream",
  "ttlGenerateInterfaceLRC": "Manage Billing items interface",
  "ttlGenerateInterfaceTS": "Manage Tenancy Schedule Data Stream",
  "ttLoanToValue": "The outstanding debt from external loans secured by the company and its subsidiaries (at share) expressed as a % of the total market value of all the properties held within the company and underlying vehicles (at share)",
  "ttLOSS_BEFORE_RESERVE": "The difference between the net market value and the net book value before impairment of each individual property",
  "ttNET_ASSET_VALUE": "The total equity from shareholders adjusted with unrealized gains-and-losses on direct investment properties and equity securities",
  "ttNET_ASSET_VALUE_ON_SPV": "The market value of equity securities held by the company in other investment vehicles, is equal to the sum of the net asset values at share of all the companies held",
  "ttNET_ASSET_VALUE_PER_SHARE": "The net asset value divided by the total number of shares outstanding",
  "ttNET_BOOK_VALUE_AFTER_IMPAIR": "The difference between the net market value and the net book value after impairment of each individual property",
  "ttNET_BOOK_VALUE_BEFORE_RESERVE": "The gross book value of each individual property net of the total accumulated depreciation amount as at the cut-off date",
  "ttNET_BOOK_VALUE_ON_SPV": "The historical cost of all the equity securities held by the company in other investment vehicles",
  "ttNET_EFFECTIVE_RENT": "The total property-level rent net of any tenant allowance applicable, net of non recoverable operating expenses",
  "ttNET_HEADLINE_RENT": "The total rent achievable at property-level after all the tenant allowances have ended, net of non recoverable operating expenses",
  "ttNET_MARKET_RENT": "The total rent achievable at property-level should each individual rental unit be let at ERV, net of non recoverable operating expenses",
  "ttNET_POTENTIAL_RENT": "The total rent achievable at property-level should each vacant unit at the report date be let at ERV, net of non recoverable operating expenses",
  "ttNET_REVERS_CAPITAL_RATE": "The net market rent at property-level expressed as a % of the net market value",
  "ttNET_REVERSIONARY_YIELD": "The net market rent at property-level expressed as a % of the gross market value at the report date",
  "ttNetAssetValuePerShare": "The net asset value divided by the total number of shares outstanding",
  "ttNetMarketValue": "The total net market value of the properties held within the selected company and underlying vehicles (at share)",
  "ttNetResultReturnOnGrossBookValue": "The net result after tax of the selected company expressed as a % of the gross book value of its direct investment properties",
  "ttNEW_LEASES_RATE": "The number of new leases from re-lettings during the report period expressed as a % of the total number of leases as at the report start date",
  "ttNEW_UNITS_AREA_RATE": "The area from the rental units re-let during the period expressed as a % of the total area as at the report start date",
  "ttNEW_UNITS_RATE": "The number of rental units re-let during the period expressed as a % of the total number of rental units as at the report start date",
  "ttNUMBER_OF_LEASES": "The total number of leases of the properties held within the selected company and underlying vehicles (irrespective of shares held)",
  "ttNUMBER_OF_TENANTS": "The total number of tenants of the properties held within the selected company and underlying vehicles (irrespective of shares held)",
  "ttNUMBER_OF_UNITS": "The total number of rental units of the properties held within the selected company and underlying vehicles (irrespective of shares held)",
  "ttNUMBER_PARKING_SPACES": "The total number of parking spaces of the properties held within the selected company and underlying vehicles (irrespective of shares held",
  "ttOperatingReturnOnGrossBookValue": "The net operating income of the selected company expressed as a % of the gross book value of its direct investment properties",
  "ttOUTSTAND_PAY_TERM_EQUIV": "The outstanding amount expressed as a number of months or quarters",
  "ttOUTSTAND_RATE": "The amount received expressed as a % of the amount invoiced during the reporting period",
  "ttOVERPAYMENTS_INC_TAX_AMOUNT": "The amounts received for which the accounting date is comprised within the reporting period while the due date is not",
  "ttOwnedBuildings": "The key figures and metrics underneath factor in all the direct investment properties held by the selected company and underlying vehicles irrespective of shares held",
  "ttOWNER_EQUITY": "The sum of share capital, share premium, reserves, retained earnings, uncalled capital, suspense account, capital gain reinvestment, extraordinary depreciation and - if look-through company - the net result",
  "ttPAYMENT_INCL_TAX_AMOUNT": "All the amounts received for which the accounting date is comprised within the reporting period",
  "ttPC_PORTFOLIO_NET_MARKET_VALUE": "The net market value of each individual property expressed as a % of the total net market value of the properties in scope",
  "ttPC_PORTFOLIO_TOTAL_RETURN": "The total return of the property prorated to its weight in the portfolio",
  "ttPER_SHARE_UNREAL_GAINS_LOSSES": "The difference between the NAV per share of the company and the per-share acquisition price of the selected investor",
  "ttPHYSICAL_OCCUPANCY_RATE": "The occupied area expressed as a % of the total area of the property",
  "ttPHYSICAL_OCCUPATION_RATE": "The occupied area expressed as a % of the total area of the property",
  "ttPHYSICAL_VACANCY_RATE": "The vacant area expressed as a % of the total area of the property",
  "ttPhysicalOccRate": "The occupied area expressed as a % of the total area of the property",
  "ttPhysicalOccupancySummary": "The physical occupancy summary of the properties held within the selected company and underlying vehicles (at share)",
  "ttPortfolioSize": "The key figures and metrics underneath factor in all the direct investment properties held by the selected company and underlying vehicles at share and are further pro rated should a particular investor be selected",
  "ttPortfolioYieldsAndReturns": "The key figures and metrics underneath factor in data related to the selected company only",
  "ttPOTENTIAL_GROSS_CAPITAL_RATE": "The gross potential rent at property-level expressed as a % of the net market value",
  "ttPOTENTIAL_GROSS_INITIAL_YIELD": "The gross potential rent at property-level expressed as a % of the gross market value at the report date",
  "ttPOTENTIAL_NET_CAPITAL_RATE": "The net potential rent at property-level expressed as a % of the net market value",
  "ttPOTENTIAL_NET_INITIAL_YIELD": "The net potential rent at property-level expressed as a % of the gross market value at the report date",
  "ttPRO_RATED_FINANCIAL_VACANCY": "The total ERV of the current vacant rental units as at the report date in proportion to the vacancy duration as at the report date",
  "ttRE_LETTING_PERIOD": "The difference between the start date of the lease and the vacancy start date of the re-let unit, expressed as a number of days",
  "ttRealEstatePortfolio": "The key figures and metrics underneath factor in all the direct investment properties held by the selected company and underlying vehicles at share, further pro rated should a particular investor be selected",
  "ttRECOVERABLE_AMOUNT": "The higher of the property's net market value and its value in use",
  "ttRENT_VARIATION": "The ratio of the headline rent per SQM of the lease to the headline rent per SQM of the previous lease, expressed as a % of the latter",
  "ttRENTED_AREA": "Total area from occupied rental units as at the report date",
  "ttReturnOnEquityLevered": "The net result after tax of the selected company expressed as a % of the total capital structure (equity and external debt)",
  "ttReturnOnEquityUnlevered": "The net result after tax of the selected company expressed as a % of the total equity invested in the company",
  "ttREVERSIONARY_POTENTIAL": "The lease-level ratio of ERV to headline rent, expressed as a % of the headline rent",
  "ttShareAcquisitionPrice": "The total share acquisition price of all the investors or only the particular investor selected",
  "ttSimplifiedPAndL": "A simplified P&L Statement of the selected company and underlying vehicles (at share), further pro rated should a particular investor be selected",
  "ttTOTAL_INVESTMENT_VALUE": "The sum of the net asset value of all the shares held by the selected investor and the shareholder loans",
  "ttTOTAL_RETURN": "The sum of income return and capital return",
  "ttTOTAL_UNREAL_GAINS_LOSSES": "The sum of the unrealized gains-and-losses on direct investment properties and equity securities",
  "ttTotalInvestmentValue": "The sum of the investor share of NAV and shareholder loans, should a particular investor be selected",
  "ttUNIT_TURNOVER_RATE": "The sum of the rental unit-based tenant move-in and tenant move-out rates divided in 2",
  "ttUNITS_AREA_TURNOVER_RATE": "The sum of the area-based tenant move-in and tenant move-out rates divided in 2",
  "ttUNITS_NUMBER": "The total number of rental units as at the report start date",
  "ttUNREAL_GAINS_LOSSES_ON_PROP": "The difference between the recoverable amount and the adjusted unrealized gains and losses on direct investment properties calculated in the Property Impairment Report",
  "ttUNREAL_GAINS_LOSSES_ON_SHARES": "The total unrealized gains and losses on all shares held by the selected investor",
  "ttUNREAL_GAINS_LOSSES_ON_SPV": "The difference between the net asset value and the historical cost of the equity securities held by the company",
  "ttUNREALIZED_GAINS_AND_LOSSES": "The difference between the recoverable amount and the adjusted net book value",
  "ttUnrealizedGainsLossesOnShares": "The difference between the net asset value and the historical cost of the equity securities held by the company",
  "ttUnrealizedInvestmentPropertyGainLoss": "The difference between the net market value and the net book value of all the properties held within the company",
  "ttVACANCY_DURATION": "The difference between the report date and the vacancy start date of the vacant rental units, expressed as years-months-days",
  "ttVACANT_AREA": "Total area from vacant rental units as at the report date",
  "ttVALUE_IN_USE": "The income capitalization of the valuer-supplied gross market rent and the yield expected for the property type and market segment",
  "ttWEIGHTED_CONTRIB_TOTAL_RETURN": "The contribution of each individual property to the total return of the properties in scope expressed as a % of the total return of the properties in scope",
  "txbAnalysisDate_mismatch": "Analysis Date is incorrect",
  "txbAnalysisDate_required": "Analysis Date is required",
  "txbDateEnd_mismatch": "End Date is incorrect",
  "txbDateEnd_required": "End Date is required",
  "txbDateErv": "Enter the Value Date",
  "txbDateErv_mismatch": "Value Date is incorrect",
  "txbDateErv_required": "Value Date is required",
  "txbDateFundOwnership": "Enter the Update Date",
  "txbDateFundOwnership_mismatch": "Update Date is incorrect",
  "txbDateFundOwnership_required": "Update Date is required",
  "txbDateNetBookValue": "Enter the Cut-off Date",
  "txbDateNetBookValue_mismatch": "Cut-off Date is incorrect",
  "txbDateNetBookValue_required": "Cut-off Date is required",
  "txbDateStart_mismatch": "Start Date is incorrect",
  "txbDateStart_required": "Start Date is required",
  "txbDateStartEnd_mismatch": "End Date must be greater than Start Date",
  "txbDateValuation": "Enter the valuation date",
  "txbDateValuation_mismatch": "Valuation Date is incorrect",
  "txbDateValuation_required": "Valuation Date is required",
  "txbDateYield": "Enter the Value Date",
  "txbDateYield_mismatch": "Value Date is incorrect",
  "txbDateYield_required": "Value Date is required",
  "txtCacheCleared": "The cache of the browser is empty",
  "txtCacheNotCleared": "The cache of the browser is not empty",
  "txtChooseReportModel": "Choose a custom report",
  "txtMenuAdditionalField": "This section allows you to upload and update data in Alhambra at the property, building, unit or lease level. Data themes will be freely defined in the \"manage themes\" menu.",
  "txtMenuErv": "This section allows you to upload, update and manage Estimated Rental Values (ERVs) at different levels, depending on the granularity of available data. Imported ERVs are then inherited from the import level to the lowest, rental unit-level.<BR/>  <BR/>  ERVs can be imported:<BR/>  - At rental unit-level for each individual property<BR/>  - At rental unit type- and room count-level for each individual property<BR/>  - At rental unit type-level for each individual property<BR/>  - At rental unit type-level for each market geography<BR/>",
  "txtMenuFundOwnership": "This section allows you to upload, update and manage investor-related data in Alhambra. An investor in Alhambra can be any entity, company or vehicle holding equity securities in at least one other entity.",
  "txtMenuLeaseRepository": "This section allows you to upload, update and manage litigations of the leases.<BR/>  BEWARE : The litigations informations are limited to 2000 characters.",
  "txtMenuNetBookValue": "This section allows you to upload, update and manage property data used in the calculation of the net book value for each individual property at any given cut-off date.",
  "txtMenuValuation": "This section allows you to upload, update and manage market values and market rents based on Real Estate property valuation.",
  "txtMenuYield": "This section allows you to upload, update and manage investor-expected yields. Target yields are used to calculate the value in use in the Property Impairment Report. It is recommended to import and update them in the portfolio database on an annual basis.",
  "txtModelDateImportFile": "on which the data are valid.",
  "txtModelFileAdditionalField": "Enter the values and their start dates; if there are no values, leave the line empty or delete it.",
  "txtModelFileErvBuildingUnit": "If there is no value, please enter '0' or delete the lines.",
  "txtModelFileErvBuildingUnitType": "If there is no value, please enter '0' or delete the lines.",
  "txtModelFileErvBuildingUnitTypeRoom": "If there is no value, please enter '0' or delete the lines.",
  "txtModelFileErvSectorUnitType": "If there is no value, please enter '0' or delete the lines.",
  "txtModelFileFundOwnership": "The template file below shall be used to enter, update or correct data pertaining to investors, such as:<BR/>  - Per-Share Acquisition Price<BR/>  - Commitment<BR/>  - Capital Calls<BR/>  <BR/>  It also includes the following non-editable data points for information and double-checking purposes only:<BR/>  - ISIN of the Issuer (Held Company)<BR/>  - Number of shares held by the investor<BR/>  - Start Date of the Investment Relationship<BR/>  - End Date of the Investment Relationship<BR/>  <BR/>  Caution: data related to companies and investors that are considered active as at the cut-off date only shall be updated. The lines related to inactive companies and/or investors shall be rejected.",
  "txtModelFileLeaseRepository": "If there is no value, please enter '0' or delete the lines.",
  "txtModelFileNetBookValue": "Enter values; If there is no value, please enter '0' or delete the lines.",
  "txtModelFileValuation": "Enter values; if there is no value, please enter '0' or delete the lines.",
  "txtModelFileYield": "Enter values; if there is no value, please enter '0' or delete the lines.",
  "txtModelImportFile": "In order for this data to be taken into account:</br> • Go to Parameters / Reload Latest Data </br> • Click on <i>Partial Reload</i>.",
  "txtNextInterface": "No changes will be saved, are you sure ?",
  "txtNotWithCents": "No cents",
  "txtSearch": "Search for a property",
  "txtSearchBuildingOrFund": "Property or Vehicle ?",
  "txtSearchCodeOrLabel": "Search for a code or a label",
  "txtSearchFunds": "Search for a fund entity",
  "txtSearchProvider": "Search for a provider",
  "txtSearchReportTemplate": "Search for a report",
  "txtServerCacheCleared": "The server cache has been cleared",
  "txtServerCacheNotCleared": "The server cache has not been cleared",
  "txtUpdateAdditionalFieldOk": "Additional Data successfully updated.",
  "txtUpdateErvOk": "ERV successfully updated.",
  "txtUpdateFundOwnershipOk": "Investor-related data successfully updated.",
  "txtUpdateLeaseRepositoryOk": "Leases Data successfully updated.",
  "txtUpdateNetBookValueOk": "Net Book Value-related data successfully updated.",
  "txtUpdateValuationOk": "Valuation Data successfully updated.",
  "txtUpdateYieldOk": "Target Yields successfully updated.",
  "txtVacancyUnit": "Monthly Vacancy Monitoring in:",
  "txtWithCents": "With cents",
  "TYPOLOGIE_VENTILATION_COMPTABLE": "Typologie ventilation comptable",
  "UNALLOCATED SHARES": "Number of Shares Unallocated (calculated)",
  "UNAVAILABLE_FOR_LETTING_AREA": "Unavaillable for Letting Area",
  "UNCALLED CAPITAL": "Uncalled Capital (€)",
  "UNCALLED_CAPITAL": "Uncalled Capital (€)",
  "UNDEFINED": "Undefined",
  "UNDER_LITIGATION": "Under litigation",
  "UNDERLYING IMMOBILIER": "Real Estate Portfolio",
  "UNDERLYING VEHICLES": "Underlying Vehicles",
  "unhandledErrorMsg": "The error was unhandled by application code.",
  "UNIT": "Unit",
  "UNIT AREA SQM": "Rental Unit Area (m²)",
  "UNIT DETAILED TENANCY SCHEDULE": "Tenancy Schedule detailed by Rental Unit as at",
  "UNIT DETAILED TENANCY SCHEDULE SHEET": "Tenancy Schedule detailed by Unit",
  "UNIT NATURE LABEL": "Unit Nature",
  "UNIT NUMBER": "Rental Unit Number",
  "UNIT TENANCY SCHEDULE": "Tenancy Schedule by Rental Unit as at",
  "UNIT TENANCY SCHEDULE SHEET": "Tenancy Schedule by Unit",
  "UNIT TURNOVER RATE": "Tenant Churn Rate (Rental Units)",
  "UNIT TYPE CODE": "Rental Unit Type Code",
  "UNIT TYPE LABEL": "Unit Type",
  "UNIT TYPE TENANCY SCHEDULE": "Tenancy Schedule by Rental Unit Type as at",
  "UNIT TYPE TENANCY SCHEDULE SHEET": "Tenancy Schedule by Unit Type",
  "UNIT_AREA_SQM": "Unit Area (m²)",
  "UNIT_CODE": "Unit",
  "UNIT_END_MANAGEMENT_DATE": "Unit Date Managed to",
  "UNIT_LABEL": "Unit label",
  "UNIT_LEASE": "Unit Lease Link",
  "UNIT_NATURE_LABEL": "Unit Nature",
  "UNIT_NUMBER": "Rental Unit Number",
  "UNIT_START_MANAGEMENT_DATE": "Unit Date Managed from",
  "UNIT_TURNOVER_RATE": "Tenant Churn Rate (Rental Units)",
  "UNIT_TYPE_CODE": "Unit Type Code",
  "UNIT_TYPE_LABEL": "Unit Type",
  "UNIT_UTILISATION_END_DATE": "Unit Date Used to",
  "UNIT_UTILISATION_START_DATE": "Unit Date Used from",
  "Unit1": "€/year",
  "Unit2": "m²/year",
  "Unit3": "€/m²/year",
  "Unit4": "€",
  "Unit5": "m²",
  "UNITS AREA TURNOVER RATE": "Tenant Churn Rate (Area)",
  "UNITS COUNT": "Rental Unit Count",
  "UNITS NUMBER AT DATE": "Rental Unit Count as at",
  "UNITS PERCENT": "% of Portfolio Total Rental Unit Count",
  "UNITS TOTAL AREA SQM": "Units Total Area (m²)",
  "UNITS_AREA_TURNOVER_RATE": "Tenant Churn Rate (Area)",
  "UNITS_COUNT": "Rental Unit Count",
  "UNITS_NUMBER": "Rental Unit Count",
  "UNITS_NUMBER_AT_DATE": "Number of Rental Units as at DD/MM/YYYY",
  "UNITS_PERCENT": "% of Portfolio Total Unit Number",
  "UNITS_TOTAL_AREA_SQM": "Units Total Area (m²)",
  "UNPAID INVOICED INCL TAX AMOUNT": "Unpaid invoiced amount (€)",
  "UNPAID_INVOICED_INCL_TAX_AMOUNT": "Unpaid invoiced amount (€)",
  "UNREAL_GAINS_LOSSES_ON_PROP": "Unrealized Gains and Losses on Properties (€)",
  "UNREAL_GAINS_LOSSES_ON_SHARES": "Unrealized Gains and Losses on Shares (€)",
  "UNREAL_GAINS_LOSSES_ON_SPV": "Unrealized Gains and Losses on SPV and HoldCo (€)",
  "UNREALIZED GAINS AND LOSSES": "Adjusted Unrealized Gains and Losses (€)",
  "UNREALIZED GAINS AND LOSSES ON PROPERTIES": "Unrealized Gains and Losses on Properties (€)",
  "UNREALIZED GAINS AND LOSSES ON SHARES": "Unrealized Gains and Losses on shares (€)",
  "UNREALIZED GAINS AND LOSSES ON SPV AND HOLDCO": "Unrealized Gains and Losses on SPV and HoldCo (€)",
  "UNREALIZED GAINS LOSSES ON SHARES": "Unrealized Gains and Losses on Shares (€)",
  "UNREALIZED INVESTMENT PROPERTY GAIN LOSS": "Unrealized Investment Property Gain/Loss (€)",
  "UNREALIZED NON PROPERTY GAIN LOSS": "Unrealized Non-Property Gain/Loss (€)",
  "UNREALIZED_GAINS_AND_LOSSES": "Adjusted Unrealized Gains and Losses (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_PROPERTIES": "Unrealized Gains and Losses on Properties (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_SHARES": "Unrealized Gains and Losses on Shares (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_SPV_AND_HOLDCO": "Unrealized Gains and Losses on SPV and HoldCo (€)",
  "UNRECOVERABLE": "Not Recoverable",
  "UNRECOVERED EXPENSES": "Not Recovered Expenses (€)",
  "UNRECOVERED EXPENSES WOU": "Not Recovered Expenses",
  "UPCOMING LEASE EVENTS": "Upcoming Lease Events",
  "UploadStatusError": "Upload status : The file could not be uploaded. The following error occured:",
  "UploadStatusFileTooBig": "Upload Status : The file has to be less than 1 Mb!",
  "UploadStatusOnlyJPEG": "Upload Status : Only JPEG files are accepted!",
  "UploadStatusPropertyNotFound": "Upload Status : Unable to find the Property!",
  "USABLE FLOOR AREA": "Usable Floor Area (UFA) (m²)",
  "USABLE FLOOR AREA WOU": "Usable Floor Area (UFA)",
  "VACANCY": "Vacancy",
  "VACANCY AREA": "Vacant Area (m²)",
  "VACANCY AREA SHEET": "Vacant Area",
  "VACANCY BREAKDOWN": "Vacancy Breakdown by Period",
  "VACANCY BREAKDOWN BY NATURE": "Vacancy Breakdown by Type as at",
  "VACANCY BREAKDOWN BY NATURE SHEET": "Vacancy Breakdown by Type",
  "VACANCY DURATION": "Vacancy Duration (y-m-d)",
  "VACANCY FOR SALE": "Structural Vacancy (for Disposal Purposes)",
  "VACANCY FOR WORKS": "Structural Vacancy (for Works Purposes)",
  "VACANCY START DATE": "Vacancy Start Date",
  "VACANCY SUMMARY": "Occupancy Summary as at",
  "VACANCY TYPE": "Vacancy Type",
  "VACANCY_DURATION": "Vacancy Duration (y-m-d)",
  "VACANCY_RATE_CURRENT_MONTH": "Current Month Physical Vacancy Rate",
  "VACANCY_RATE_MINUS_01_MONTHS": "Physical Vacancy Rate 1 Month earlier",
  "VACANCY_RATE_MINUS_02_MONTHS": "Physical Vacancy Rate 2 Months earlier",
  "VACANCY_RATE_MINUS_03_MONTHS": "Physical Vacancy Rate 3 Months earlier",
  "VACANCY_RATE_MINUS_04_MONTHS": "Physical Vacancy Rate 4 Months earlier",
  "VACANCY_RATE_MINUS_05_MONTHS": "Physical Vacancy Rate 5 Months earlier",
  "VACANCY_RATE_MINUS_06_MONTHS": "Physical Vacancy Rate 6 Months earlier",
  "VACANCY_RATE_MINUS_07_MONTHS": "Physical Vacancy Rate 7 Months earlier",
  "VACANCY_RATE_MINUS_08_MONTHS": "Physical Vacancy Rate 8 Months earlier",
  "VACANCY_RATE_MINUS_09_MONTHS": "Physical Vacancy Rate 9 Months earlier",
  "VACANCY_RATE_MINUS_10_MONTHS": "Physical Vacancy Rate 10 Months earlier",
  "VACANCY_RATE_MINUS_11_MONTHS": "Physical Vacancy Rate 11 Months earlier",
  "VACANCY_START_DATE": "Vacancy Start Date",
  "VACANCY_TYPE": "Vacancy Type",
  "VACANT": "--VACANT--",
  "VACANT AREA": "Vacant Area (m²)",
  "VACANT AREA 2 4 MONTHS": "Vacant Area 2-4 Months (m²)",
  "VACANT AREA INF 2MONTHS": "Vacant Area < 2 Months (m²)",
  "VACANT AREA SUP 4MONTHS": "Vacant Area > 4 Months (m²)",
  "VACANT UNITS": "Vacant Unit Summary as at",
  "VACANT UNITS BY UNIT TYPE": "Breakdown of Vacant Units by Unit Type",
  "VACANT UNITS BY VACANCY TYPE": "Breakdown of Vacant Units by Vacancy Type",
  "VACANT UNITS SHEET": "Vacant Unit Summary",
  "VACANT_AREA": "Vacant Area (m²)",
  "VACANT_AREA_1_3_MONTHS": "1-3 months",
  "VACANT_AREA_2_4_MONTHS": "Vacant Area 2-4 Months (m²)",
  "VACANT_AREA_3_6_MONTHS": "3-6 months",
  "VACANT_AREA_CURRENT_MONTH": "Current Month Vacant Area",
  "VACANT_AREA_INF_1_MONTH": "< 1 month",
  "VACANT_AREA_INF_2MONTHS": "Vacant Area < 2 Months (m²)",
  "VACANT_AREA_MINUS_01_MONTHS": "Vacant Area 1 Month earlier",
  "VACANT_AREA_MINUS_02_MONTHS": "Vacant Area 2 Months earlier",
  "VACANT_AREA_MINUS_03_MONTHS": "Vacant Area 3 Months earlier",
  "VACANT_AREA_MINUS_04_MONTHS": "Vacant Area 4 Months earlier",
  "VACANT_AREA_MINUS_05_MONTHS": "Vacant Area 5 Months earlier",
  "VACANT_AREA_MINUS_06_MONTHS": "Vacant Area 6 Months earlier",
  "VACANT_AREA_MINUS_07_MONTHS": "Vacant Area 7 Months earlier",
  "VACANT_AREA_MINUS_08_MONTHS": "Vacant Area 8 Months earlier",
  "VACANT_AREA_MINUS_09_MONTHS": "Vacant Area 9 Months earlier",
  "VACANT_AREA_MINUS_10_MONTHS": "Vacant Area 10 Months earlier",
  "VACANT_AREA_MINUS_11_MONTHS": "Vacant Area 11 Months earlier",
  "VACANT_AREA_SQM": "Vacant Area (m²)",
  "VACANT_AREA_SUP_4MONTHS": "Vacant Area > 4 Months (m²)",
  "VACANT_AREA_SUP_6_MONTHS": "> 6 months",
  "VACANT_DISPOSITION_AREA": "Vacant for Disposal Purpose",
  "VACANT_INACTIVE_AREA": "Inactive",
  "VACANT_LETTING_AREA": "Vacant available for letting",
  "VACANT_UNAVAILABLE_AREA": "Unavailable for letting (Commun area)",
  "VACANT_UNDEFINED_AREA": "Vacant",
  "VACANT_WORKS_AREA": "Vacant for work purpose",
  "vacatedTenants": "Vacated Tenants",
  "VALIDATION_DU_CHANTIER": "Validation du chantier",
  "VALUATION": "Valuation",
  "VALUATION MARKET RENT": "Valuation Market Rent (€)",
  "VALUATION_MARKET_RENT": "Valuation Market Rent",
  "VALUE IN USE": "Value In Use (€)",
  "VALUE_IN_USE": "Value In Use (€)",
  "VALUES PERCENT": "% of Portfolio Total Net Market Value",
  "VALUES_PERCENT": "% of Portfolio Total Net Market Value",
  "VARIATION 01 N": "Variation January",
  "VARIATION 02 N": "Variation February",
  "VARIATION 03 N": "Variation March",
  "VARIATION 04 N": "Variation April",
  "VARIATION 05 N": "Variation May",
  "VARIATION 06 N": "Variation June",
  "VARIATION 07 N": "Variation July",
  "VARIATION 08 N": "Variation August",
  "VARIATION 09 N": "Variation September",
  "VARIATION 10 N": "Variation October",
  "VARIATION 11 N": "Variation November",
  "VARIATION 12 N": "Variation December",
  "VARIATION BUDGET": "Variation budget",
  "VARIATION BUDGET RATE": "Variation budget rate",
  "VARIATION N": "Variation € budget to date to actual to date",
  "VARIATION RATE 01 N": "Realized vs budget ratio January",
  "VARIATION RATE 02 N": "Realized vs budget ratio February",
  "VARIATION RATE 03 N": "Realized vs budget ratio March",
  "VARIATION RATE 04 N": "Realized vs budget ratio April",
  "VARIATION RATE 05 N": "Realized vs budget ratio May",
  "VARIATION RATE 06 N": "Realized vs budget ratio June",
  "VARIATION RATE 07 N": "Realized vs budget ratio July",
  "VARIATION RATE 08 N": "Realized vs budget ratio August",
  "VARIATION RATE 09 N": "Realized vs budget ratio September",
  "VARIATION RATE 10 N": "Realized vs budget ratio October",
  "VARIATION RATE 11 N": "Realized vs budget ratio November",
  "VARIATION RATE 12 N": "Realized vs budget ratio December",
  "VARIATION RATE N": "Realized vs budget ratio",
  "VARIATION RATE T1 N": "Realized vs budget ratio Q1",
  "VARIATION RATE T2 N": "Realized vs budget ratio Q2",
  "VARIATION RATE T3 N": "Realized vs budget ratio Q3",
  "VARIATION RATE T4 N": "Realized vs budget ratio Q4",
  "VARIATION RATE TODATE N": "Variation rate",
  "VARIATION T1 N": "Variation Q1",
  "VARIATION T2 N": "Variation Q2",
  "VARIATION T3 N": "Variation Q3",
  "VARIATION T4 N": "Variation Q4",
  "VARIATION TODATE N": "Variation",
  "VARIATION_01_N": "Variation January YYYY",
  "VARIATION_02_N": "Variation February YYYY",
  "VARIATION_03_N": "Variation March YYYY",
  "VARIATION_04_N": "Variation April YYYY",
  "VARIATION_05_N": "Variation May YYYY",
  "VARIATION_06_N": "Variation June YYYY",
  "VARIATION_07_N": "Variation July YYYY",
  "VARIATION_08_N": "Variation August YYYY",
  "VARIATION_09_N": "Variation September YYYY",
  "VARIATION_10_N": "Variation October YYYY",
  "VARIATION_11_N": "Variation November YYYY",
  "VARIATION_12_N": "Variation December YYYY",
  "VARIATION_BUDGET": "Variation budget",
  "VARIATION_BUDGET_RATE": "Variation budget rate",
  "VARIATION_N": "Variation € budget to date to actual to date",
  "VARIATION_RATE_01_N": "Realized vs budget ratio January YYYY",
  "VARIATION_RATE_02_N": "Realized vs budget ratio February YYYY",
  "VARIATION_RATE_03_N": "Realized vs budget ratio March YYYY",
  "VARIATION_RATE_04_N": "Realized vs budget ratio April YYYY",
  "VARIATION_RATE_05_N": "Realized vs budget ratio May YYYY",
  "VARIATION_RATE_06_N": "Realized vs budget ratio June YYYY",
  "VARIATION_RATE_07_N": "Realized vs budget ratio July YYYY",
  "VARIATION_RATE_08_N": "Realized vs budget ratio August YYYY",
  "VARIATION_RATE_09_N": "Realized vs budget ratio September YYYY",
  "VARIATION_RATE_10_N": "Realized vs budget ratio October YYYY",
  "VARIATION_RATE_11_N": "Realized vs budget ratio November YYYY",
  "VARIATION_RATE_12_N": "Realized vs budget ratio December YYYY",
  "VARIATION_RATE_N": "Realized vs budget ratio YYYY",
  "VARIATION_RATE_T1_N": "Realized vs budget ratio Q1 YYYY",
  "VARIATION_RATE_T2_N": "Realized vs budget ratio Q2 YYYY",
  "VARIATION_RATE_T3_N": "Realized vs budget ratio Q3 YYYY",
  "VARIATION_RATE_T4_N": "Realized vs budget ratio Q4 YYYY",
  "VARIATION_RATE_TODATE_N": "Variation rate DDMMYYYY",
  "VARIATION_T1_N": "Variation Q1 YYYY",
  "VARIATION_T2_N": "Variation Q2 YYYY",
  "VARIATION_T3_N": "Variation Q3 YYYY",
  "VARIATION_T4_N": "Variation Q4 YYYY",
  "VARIATION_TODATE_N": "Variation DDMMYYYY",
  "VAT": "VAT",
  "VAT AMOUNT": "VAT amount",
  "VAT AMOUNT TOTAL": "Total VAT amount",
  "VAT_AMOUNT": "VAT amount",
  "VAT_AMOUNT_TOTAL": "Total VAT amount",
  "VAT_RATE_CODE": "VAT Rate Code",
  "VEHICLE FORMATION DATE": "Vehicle Formation Date",
  "VEHICLE NET ASSET VALUE": "VEHICLE NET ASSET VALUE (100%)",
  "VEHICLE TERM": "Vehicle Term",
  "VEHICLE_FORMATION_DATE": "Vehicle Formation Date",
  "VEHICLE_TERM": "Vehicle Term",
  "VERSIONS_DU_PPAT": "Versions du PPAT",
  "VILLE": "Ville",
  "WEIGHTED CONTRIB TOTAL RETURN": "Weighted Contribution to Portfolio Total Return",
  "WEIGHTED USABLE AREA": "Weighted Usable Area (WUA) (m²)",
  "WEIGHTED USABLE AREA WOU": "Weighted Usable Area (WUA)",
  "WEIGHTED_CONTRIB_TOTAL_RETURN": "Weighted Contribution to Portfolio Total Return",
  "WEIGHTED_USABLE_AREA_SQM": "Weighted Usable Area (WUA) (m²)",
  "WORK CAMPAIGN CODE": "Work Campaign Code",
  "WORK CAMPAIGN LABEL": "Work Campaign Label",
  "WORK_CAMPAIGN_CODE": "Work Campaign Code",
  "WORK_CAMPAIGN_LABEL": "Work Campaign Label",
  "WORKING CAPITAL CALLS": "Working capital calls (€)",
  "WORKING CAPITAL RECEIVABLE": "Working capital receivable (€)",
  "WORKING CAPITAL REFUNDS": "Working capital refunds (€)",
  "WORKING_CAPITAL_CALLS": "Working capital calls (€)",
  "WORKING_CAPITAL_RECEIVABLE": "Working capital receivable (€)",
  "WORKING_CAPITAL_REFUNDS": "Working capital refunds (€)",
  "WORKS END DATE": "Works End Date",
  "WORKS VACANT AREA": "Vacant Area for Works Purposes (m²)",
  "WORKS VACANT AREA RATE": "% of Total Vacancy",
  "WORKS_END_DATE": "Works End Date",
  "WORKS_END_DATE_mismatch": "Works End Date mismatch",
  "WORKS_VACANT_AREA": "Vacant for Works Area (m²)",
  "WORKS_VACANT_AREA_RATE": "% of Total Vacancy (%)",
  "WORKSHOP AREA": "Workshop Area (m²)",
  "WORKSHOP_AREA": "Workshop Area (m²)",
  "YEAR MONTH": "Year / Month",
  "YEAR TO DATE SALES VOLUME": "Sales Volume Year-to-Date (€)",
  "YEAR_MONTH": "Year / Month",
  "YEAR_NUMBER": "Year",
  "YEAR_TO_DATE_SALES_VOLUME": "Sales Volume Year-to-Date (€)",
  "YEARS": "Years",
  "YES": "Yes",
  "YIELD MONITORING": "Yield Monitoring Report as at",
  "YIELD MONITORING REPORT": "Yield Monitoring Report",
  "YOY ANNUAL SALES GROWTH": "Year-on-Year Annual Sales Growth",
  "YOY MONTHLY SALES GROWTH": "Year-on-Year Monthly Sales Growth",
  "YOY_ANNUAL_SALES_GROWTH": "Year-on-Year Annual Sales Growth YYYY-1 vs. YYYY-2",
  "YOY_MONTHLY_SALES_GROWTH": "Year-on-Year Monthly Sales Growth MM/YYYY vs. MM/YYYY-1",
  "lbl-connection": "Log in",
  "lbl-language": "Language",
  "lbl-username": "Username",
  "lbl-password": "Password",
  "lbl-login": "Log in",
  "en-GB": "English",
  "fr-FR": "French",
  "de-DE": "German",
  "lblFixedLeftColumns": "Number of fixed columns on the left",
  "lblFixedRightColumns": "Number of fixed columns on the right",
  "id_report_template": "Model identifier",
  "report_code": "Report Code",
  "report_name": "Standard Report Corresponding",
  "template_name": "Model Name",
  "share_with_other_users": "Shared with Other Users",
  "updated_at": "Modified On",
  "nb_interfaces": "Number of Linked Interfaces",
  "is_access_managed_by_building": "Apply to All Properties",
  "Actions": "Actions",
  "lblNoDataAvailable": "No data is available",
  "lblViewReport": "View the standard report",
  "lblPreferenceUpdated": "All preferences have been processed. {{params1}} preferences updated successfully.",
  "lblNoPreferenceUpdated": "No preferences have been changed.",
  "lblMsqErrorPassword2": "The password must contain at least {{params1}} characters, including an upper case letter, a lower case letter, a number and a special character !*@#$%^+=",
  "lblDatePasswordLeastDays": "You can only change your password {{params1}} days after it has been changed.",
  "lblCurrentPasswordError": "Unable to change the password. The old password is incorrect.",
  "ITEMS_PER_PAGE": "Items per page:",
  "NEXT_PAGE": "Next",
  "PREVIOUS_PAGE": "Previous",
  "FIRST_PAGE": "First",
  "LAST_PAGE": "Last",
  "RANGE_PAGE_LABEL": "{{startIndex}} - {{endIndex}} of {{length}}",
  "RANGE_PAGE_LABEL_ZERO": "0 of {{length}}",
  "menuAssetMonitoring": "Asset monitoring",
  "NO_FILTER": "No Filter",
  "TENANCY_SCHEDULES": "Tenancy Schedules",
  "RENTAL_MONITORING": "Rental Monitoring",
  "EXPENSES_MONITORING": "Expenses Monitoring",
  "LEASE_PAYMENT_MODE_LABEL": "Payment method",
  "lblReportLaunch": "The report was launched",
  "DETAILED": "Formatted",
  "CONNECTION_FAILED": "Connection failed. Please check your login details.",
  "CONNECTION_ERROR": "Connection error",
  "UNKNOWN_ERROR": "Unknown error",
  "SESSION_EXPIRED": "Your session has expired. Please log in again.",
  "OTHER_OCCUPIED_AREA_SQM": "Other - Occupied Area (m²)",
  "OTHER_TOTAL_AREA_SQM": "Other - Total Area (m²)",
  "OTHER_VACANT_AREA_SQM": "Other - Vacant Area (m²)"
}
