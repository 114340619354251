{
  "01-BALANCE-ALL_REG_CHARGES": "Solde locataires - Toutes rubriques (€)",
  "02-BALANCE-RENTS": "Solde locataires - Loyer (€)",
  "03-BALANCE-SERVICE_CHARGES": "Solde locataires - Charges (€)",
  "04-BALANCE-OTHER_EXPENSES": "Solde locataires - Prestations conventionnelles (€)",
  "05-BALANCE-OTHER_REG_CHARGES": "Solde locataires - Autres (€)",
  "11-COLLECT-ALL_REG_CHARGES": "Encaissements - Toutes rubriques (€)",
  "12-COLLECT-RENTS": "Encaissements - Loyer (€)",
  "13-COLLECT-SERVICE_CHARGES": "Encaissements - Charges (€)",
  "14-COLLECT-OTHER_EXPENSES": "Encaissements - Prestations conventionnelles (€)",
  "15-COLLECT-OTHER_REG_CHARGES": "Encaissements - Autres (€)",
  "21-RATE-ALL_REG_CHARGES": "Ratio encaissements / solde locataires - Toutes rubriques (%)",
  "22-RATE-RENTS": "Ratio encaissements / solde locataires - Loyer (%)",
  "23-RATE-SERVICE_CHARGES": "Ratio encaissements / solde locataires - Charges (%)",
  "24-RATE-OTHER_EXPENSES": "Ratio encaissements / solde locataires - Prestations conventionnelles (%)",
  "25-RATE-OTHER_REG_CHARGES": "Ratio encaissements / solde locataires - Autres (%)",
  "ABATEMENT 1 END DATE": "Date de fin de l'abattement 1",
  "ABATEMENT 1 PERIOD": "Durée de l'abattement 1 (mois)",
  "ABATEMENT 1 START DATE": "Date de début de l'abattement 1",
  "ABATEMENT 2 END DATE": "Date de fin de l'abattement 2",
  "ABATEMENT 2 PERIOD": "Durée de l'abattement 2 (mois)",
  "ABATEMENT 2 START DATE": "Date de début de l'abattement 2",
  "ABATEMENT 3 END DATE": "Date de fin de l'abattement 3",
  "ABATEMENT 3 PERIOD": "Durée de l'abattement 3 (mois)",
  "ABATEMENT 3 START DATE": "Date de début de l'abattement 3",
  "ABATEMENT AMOUNT": "Abattement en cours (€)",
  "ABATEMENT AMOUNT 1": "Abattement en année 1 (€)",
  "ABATEMENT AMOUNT 2": "Abattement en année 2 (€)",
  "ABATEMENT AMOUNT 3": "Abattement en année 3 (€)",
  "ABATEMENT AMOUNT LEFT": "Montant de l'abattement restant (€)",
  "ABATEMENT AMOUNT TOTAL": "Montant total de l'abattement (€)",
  "ABATEMENT END DATE": "Date de fin de l'abattement",
  "ABATEMENT LEFT AMOUNT": "Franchise restante en montant (€)",
  "ABATEMENT LEFT NB MONTH": "Franchise restante en nombre de mois",
  "ABATEMENT MONTHS LEFT": "Mois d'abattement restants",
  "ABATEMENT MONTHS TOTAL": "Total des mois d'abattement",
  "ABATEMENT NB MONTH": "Franchise totale en nombre de mois",
  "ABATEMENT PERIOD": "Durée de l'abattement 2 (a-m-j)",
  "ABATEMENT START DATE": "Date de début de l'abattement",
  "ABATEMENT TOTAL AMOUNT": "Franchise totale du bail en montant (€)",
  "ABATEMENT_1_END_DATE": "Date de fin de l'abattement 1",
  "ABATEMENT_1_PERIOD": "Durée de l'abattement 1 (mois)",
  "ABATEMENT_1_START_DATE": "Date de début de l'abattement 1",
  "ABATEMENT_2_END_DATE": "Date de fin de l'abattement 2",
  "ABATEMENT_2_PERIOD": "Durée de l'abattement 2 (mois)",
  "ABATEMENT_2_START_DATE": "Date de début de l'abattement 2",
  "ABATEMENT_3_END_DATE": "Date de fin de l'abattement 3",
  "ABATEMENT_3_PERIOD": "Durée de l'abattement 3 (mois)",
  "ABATEMENT_3_START_DATE": "Date de début de l'abattement 3",
  "ABATEMENT_AMOUNT": "Abattement en cours (€)",
  "ABATEMENT_AMOUNT_1": "Abattement en année 1 (€)",
  "ABATEMENT_AMOUNT_2": "Abattement en année 2 (€)",
  "ABATEMENT_AMOUNT_3": "Abattement en année 3 (€)",
  "ABATEMENT_AMOUNT_LEFT": "Montant de l'abattement restant (€)",
  "ABATEMENT_AMOUNT_TOTAL": "Montant total de l'abattement (€)",
  "ABATEMENT_END_DATE": "Date de fin de l'abattement",
  "ABATEMENT_LEFT_AMOUNT": "Franchise restante en montant (€)",
  "ABATEMENT_LEFT_NB_MONTH": "Franchise restante en nombre de mois",
  "ABATEMENT_MONTHS_LEFT": "Mois d'abattement restants",
  "ABATEMENT_MONTHS_TOTAL": "Total des mois d'abattement",
  "ABATEMENT_NB_MONTH": "Franchise totale en nombre de mois",
  "ABATEMENT_START_DATE": "Date de début de l'abattement",
  "ABATEMENT_TOTAL_AMOUNT": "Franchise totale du bail en montant (€)",
  "ACCOUNT LABEL": "Libellé du compte",
  "ACCOUNT NUMBER": "Numéro de compte",
  "ACCOUNT NUMBER 1": "Numéro de compte comptable",
  "ACCOUNT NUMBER LABEL": "Libellé du compte",
  "ACCOUNT NUMBER LABEL 1": "Libellé du compte comptable",
  "ACCOUNT_LABEL": "Libellé du compte",
  "ACCOUNT_NUMBER": "Numéro de compte",
  "ACCOUNT_NUMBER_1": "Numéro de compte comptable",
  "ACCOUNT_NUMBER_LABEL": "Libellé du compte",
  "ACCOUNT_NUMBER_LABEL_1": "Libellé du compte comptable",
  "ACCOUNTING ACCOUNT CUSTOMER": "Compte Comptable",
  "ACCOUNTING BALANCE END AMOUNT": "Solde comptable (€) en fin de période",
  "ACCOUNTING BALANCE START AMOUNT": "Solde comptable (€) en début de période",
  "ACCOUNTING DATE": "Date de comptabilisation",
  "ACCOUNTING DATE INVOICE": "Date d'émission",
  "ACCOUNTING DATE OUTSTANDING PAYMENTS": "Suivi des impayés en date comptable",
  "ACCOUNTING DATE PAYMENT": "Date de règlement",
  "ACCOUNTING_ACCOUNT_CUSTOMER": "Compte Comptable",
  "ACCOUNTING_BALANCE_END_AMOUNT": "Solde comptable (€) en fin de période DD/MM/YYYY (€)",
  "ACCOUNTING_BALANCE_START_AMOUNT": "Solde comptable (€) en début de période DD/MM/YYYY",
  "ACCOUNTING_DATE": "Date de comptabilisation",
  "ACCOUNTING_DATE_INVOICE": "Date d'émission",
  "ACCOUNTING_DATE_PAYMENT": "Date de règlement",
  "ACCOUNTING_STREAM": "Comptable",
  "ACCOUNTS TYPE": "Type de comptes",
  "ACTIVE_AS_OF_THE_END_DATE": "Actifs à la date de fin",
  "ACTIVE_AS_OF_THE_REPORT_DATE": "Actifs à la date de situation",
  "ACTIVE_AS_OF_THE_START_DATE": "Actifs à la date de début",
  "ACTUAL SALE DATE": "Date de vente effective",
  "ACTUAL_SALE_DATE": "Date de vente effective",
  "ACTUAL_SALE_DATE_mismatch": "Date de vente effective format incorrect",
  "ADDITIONAL RENT": "Surloyer (€)",
  "ADDITIONAL RENT END DATE": "Date de fin du surloyer",
  "ADDITIONAL RENT INDEXATION MODE": "Mode révision du surloyer",
  "ADDITIONAL RENT PERIOD": "Durée du surloyer (mois)",
  "ADDITIONAL RENT START DATE": "Date de début du surloyer",
  "ADDITIONAL_FIELD": "Thème de la donnée",
  "ADDITIONAL_FIELD_END_DATE": "Date de fin",
  "ADDITIONAL_FIELD_START_DATE": "Date de début",
  "ADDITIONAL_FIELD_TYPE": "Niveau de la donnée",
  "ADDITIONAL_FIELD_VALUE": "Donnée",
  "ADDITIONAL_RENT": "Surloyer (€)",
  "ADDITIONAL_RENT_END_DATE": "Date de fin du surloyer",
  "ADDITIONAL_RENT_INDEXATION_MODE": "Mode révision du surloyer",
  "ADDITIONAL_RENT_PERIOD": "Durée du surloyer (mois)",
  "ADDITIONAL_RENT_START_DATE": "Date de début du surloyer",
  "ADDRESS": "Adresse",
  "ADDRESS1": "Adresse",
  "ADDRESS2": "Autre adresse",
  "ADJUSTED NET BOOK VALUE": "Valeur Nette Comptable après PDD (€)",
  "ADJUSTED_NET_BOOK_VALUE": "Valeur Nette Comptable après PDD (€)",
  "ADRESSE_1": "Adresse",
  "ADRESSE_2": "Adresse (suite)",
  "ADVANCE PAYMENTS INC TAX AMNT": "Trop perçu (€)",
  "ADVANCE_ON_CURRENT_ACCOUNT": "Avances en compte courant (€)",
  "ADVANCE_PAYMENTS_INC_TAX_AMNT": "Trop perçu (€)",
  "AGENCY": "Agence",
  "AGING SCHEDULE": "Balance agée",
  "AGING SCHEDULE AS AT": "Balance agée au",
  "ALERT_LEASE_EXPIRING_OTHER_UNIT": "Mois",
  "ALERT_LEASE_UNIT": "Mois",
  "ALERT_PROPERTY_VALUE_VARIATION_UNIT": "%",
  "ALERT_RENT_VARIATION_UNIT": "%",
  "ALERT_THRESHOLD": "Seuil de l'alerte",
  "ALERT_VACANCY_RATE_UNIT": "%",
  "ALERT_VACANT_UNIT_UNIT": "Mois",
  "AlhambraUserGuide": "Alhambra - Documentation utilisateur.pdf",
  "ALL_AGENCY": "-- Toutes les agences --",
  "ALL_ASSET_MANAGER": "-- Tous les asset managers --",
  "ALL_BUSINESS_UNIT": "-- Tous les sites de gestion --",
  "ALL_CLIENTS": "-- Tous les clients --",
  "ALL_EXTERNAL_FUND": "-- Tous les fonds --",
  "ALL_FEE_CENTER": "-- Tous les centres d'honoraire --",
  "ALL_LEASES": "Tous les baux",
  "ALL_MANAGER": "-- Tous les property managers --",
  "ALL_PORTFOLIOS": "-- Tous les portefeuilles --",
  "ALL_USERS": "-- Tous les Utilisateurs --",
  "AllAuditAction": "Toutes les actions",
  "ALLOCATION_CODE": "Code allocation",
  "ALLOCATION_LABEL": "Libellé allocation",
  "ALLOWANCE IMPAIR LOSS CALC": "Dotation (+) ou reprise (-) calculée (€)",
  "ALLOWANCE IMPAIR LOSS USER": "Dotation (+) ou reprise (-) validée (€)",
  "ALLOWANCE_IMPAIR_LOSS_CALC": "Dotation (+) ou reprise (-) calculée (€)",
  "ALLOWANCE_IMPAIR_LOSS_USER": "Dotation (+) ou reprise (-) validée (€)",
  "AMENDMENT DATE": "Date d'effet de l'avenant",
  "AMENDMENT DESCRIPTION": "Description de l'avenant",
  "AMENDMENT TYPE": "Type d'avenant",
  "AMENDMENT_DATE": "Date d'effet de l'avenant",
  "AMENDMENT_DESCRIPTION": "Description de l'avenant",
  "AMENDMENT_TYPE": "Type d'avenant",
  "AMOUNT": "Montant (€)",
  "AMOUNT OF RECOVERABLE WORKS": "Montant des travaux de grosses réparations (€)",
  "AMOUNT PERIOD 1": "<= 30 jours",
  "AMOUNT PERIOD 2": "31-60 jours",
  "AMOUNT PERIOD 3": "61-90 jours",
  "AMOUNT PERIOD 4": "91-180 jours",
  "AMOUNT PERIOD 5": "180-365 jours",
  "AMOUNT PERIOD 6": ">365 jours",
  "AMOUNT_OF_RECOVERABLE_WORKS": "Montant des travaux de grosses réparations (€)",
  "AMOUNT_PERIOD_1": "<= 30 jours",
  "AMOUNT_PERIOD_2": "31-60 jours",
  "AMOUNT_PERIOD_3": "61-90 jours",
  "AMOUNT_PERIOD_4": "91-180 jours",
  "AMOUNT_PERIOD_5": "180-365 jours",
  "AMOUNT_PERIOD_6": ">365 jours",
  "AMOUNTS": "Montants",
  "ANALYSIS DATE": "Date de situation :",
  "ANALYSIS_DATE": "Date de situation",
  "ANNEE_DEBUT": "Année début chantier",
  "ANNEE_DEBUT_PPAT": "Année début PPAT",
  "ANNEE_FIN": "Année fin chantier",
  "ANNEE_FIN_PPAT": "Année fin PPAT",
  "ANNEE_FUTURE": "Année",
  "ANNUAL AMOUNT": "Montant annuel (€/an)",
  "ANNUAL FINANCIAL VACANCY": "Vacance financière (€/an)",
  "ANNUAL M2 AMOUNT": "Montant annuel par m² (€/m²/an)",
  "ANNUAL RENT AMOUNT": "Loyer facial (€/an)",
  "ANNUAL RENT AMOUNT M2": "Loyer facial par m² (€/m²/an)",
  "ANNUAL SALES CERT Y MINUS 1": "Chiffre d'affaires annuel certifié (€/an)",
  "ANNUAL SALES CERT Y MINUS 2": "Chiffre d'affaires annuel certifié (€/an)",
  "ANNUAL_AMOUNT": "Montant annuel",
  "ANNUAL_AMOUNT_PER_UNIT": "Montant annuel par lot",
  "ANNUAL_FINANCIAL_VACANCY": "Vacance financière (€/an)",
  "ANNUAL_RENT_AMOUNT": "Loyer facial (€/an)",
  "ANNUAL_RENT_AMOUNT_M2": "Loyer facial par m² (€/m²/an)",
  "ANNUAL_RENT_AMOUNT_PER_SQM": "Loyer facial par m² (€/m²/an)",
  "ANNUAL_SALES_CERT_Y_MINUS_1": "Chiffre d'affaires annuel certifié YYYY-1 (€/an)",
  "ANNUAL_SALES_CERT_Y_MINUS_2": "Chiffre d'affaires annuel certifié YYYY-2 (€/an)",
  "APERIODIC": "Aperiodique",
  "APRIL_AMOUNT": "Avril (€)",
  "ARBITRAGE": "Gestion des cessions",
  "ARBITRAGE SALE PRICE": "Prix de vente arbitré (€)",
  "ARBITRAGE TOTAL VALUE": "Prix total de l'immeuble (€)",
  "ARBITRAGE TYPE": "Type d'arbitrage",
  "ARBITRAGE_SALE_PRICE": "Prix de vente arbitré (€)",
  "ARBITRAGE_SALE_PRICE_mismatch": "Prix de vente arbitré format incorrect",
  "ARBITRAGE_SALE_PRICE_WOU": "Prix de vente arbitré",
  "ARBITRAGE_STATUS": "Statut de l'arbitrage",
  "ARCHIVE AREA": "Surface à usage d'archives (m²)",
  "ARCHIVE_AREA": "Surface à usage d'archives (m²)",
  "AREA FOR SALE SQM": "Surface à céder",
  "AREA UNIT": "Unité de surface",
  "AREA_FOR_SALE_SQM": "Surface à céder",
  "AREA_FOR_SALE_SQM_mismatch": "Surface à céder format incorrect",
  "AREA_FOR_SALE_SQM_required": "Surface à céder obligatoire",
  "AREA_SQM": "Surface du lot (m²)",
  "AREA_UNIT": "Unité de surface",
  "AREA_UNIT_CODE": "Code de l'unité de surface",
  "AREAS PERCENT": "% de la surface totale",
  "AREAS_PERCENT": "% de la surface totale",
  "ASSET MANAGER": "Asset Manager",
  "ASSET_MANAGER": "Asset Manager",
  "ASSET_MANAGER_NAME": "Asset Manager",
  "ASSETS AREA": "Surface totale (m²)",
  "ASSETS VALUE": "Valeur vénale nette (€)",
  "ASSETS_AREA": "Surface totale (m²)",
  "ASSETS_UNDER_CONSTRUCTION": "Immobilisations en cours",
  "ASSETS_VALUE": "Valeur vénale nette (€)",
  "AUDIT ACTION LABEL": "Action",
  "AUDIT TRAIL": "Piste d'audit",
  "AUDIT_ACTION_LABEL": "Action",
  "AUGUST_AMOUNT": "Août (€)",
  "AVERAGE PHYSICAL VACANCY RATE": "Taux de vacance physique moyen",
  "AVERAGE VACANCY RATE": "Taux de vacance moyen (%)",
  "AVERAGE_VACANCY_RATE": "Taux de vacance moyen (%)",
  "BALANCE": "Solde",
  "BALANCE BEFORE OVERPAYMENTS": "Solde d'impayés en fin de période avant trop perçu (€)",
  "BALANCE DATE": "Date de la balance",
  "BALANCE END AMNT": "Solde d'impayés en fin de période aprés trop perçu (€)",
  "BALANCE END AMOUNT": "Solde (€) au",
  "BALANCE INCL TAX AMOUNT": "Solde (€)",
  "BALANCE START AMNT": "Solde d'impayés en début de période (€)",
  "BALANCE START AMOUNT": "Solde (€) au",
  "BALANCE_BEFORE_OVERPAYMENTS": "Soldes d'impayés en fin de période avant trop perçu (€)",
  "BALANCE_DATE": "Date de la balance",
  "BALANCE_END_AMNT": "Solde en fin de période (€)",
  "BALANCE_END_AMOUNT": "Solde au DD/MM/YYYY (€)",
  "BALANCE_INCL_TAX_AMOUNT": "Solde (€)",
  "BALANCE_OUT_END_AMOUNT": "Solde d'impayés au DD/MM/YYYY après trop perçu (€)",
  "BALANCE_OUT_START_AMOUNT": "Solde d'impayés au DD/MM/YYYY (€)",
  "BALANCE_START_AMNT": "Solde en début de Période (€)",
  "BALANCE_START_AMOUNT": "Solde au DD/MM/YYYY (€)",
  "BANK GUARANTEE AMOUNT": "Caution bancaire (€)",
  "BANK GUARANTEE END DATE": "Date de fin de la caution",
  "BANK GUARANTEE START DATE": "Date de début de la caution",
  "BANK JOURNAL": "Payé par journal banque",
  "BANK_GUARANTEE_AMOUNT": "Caution bancaire (€)",
  "BANK_GUARANTEE_END_DATE": "Date de fin de la caution",
  "BANK_GUARANTEE_START_DATE": "Date de début de la caution",
  "BANK_JOURNAL": "Payé par journal banque",
  "BASE RENT": "Loyer minimum garanti (€/an)",
  "BASE_RENT": "Loyer minimum garanti (€/an)",
  "BATIMENT_CODE_DESTINATAIRE": "Batiment code emetteur",
  "BATIMENT_CODE_EMETTEUR": "Code bâtiment",
  "BEFORE PAYMENTS INC TAX AMNT": "Encaissements sur antériorité (€)",
  "BEFORE PAYMENTS INC TAX AMOUNT": "Réglements sur période antérieure (€)",
  "BEFORE TAX AMOUNT": "Montant HT de la facture",
  "BEFORE TAX AMOUNT TOTAL": "Montant hors taxe total",
  "BEFORE TAX AMOUNT TOTAL 1": "Montant HT total",
  "BEFORE_PAYMENTS_INC_TAX_AMNT": "Encaissements sur antériorité (€)",
  "BEFORE_PAYMENTS_INC_TAX_AMOUNT": "Réglements sur période antérieure (€)",
  "BEFORE_TAX_AMOUNT": "Montant HT de la facture",
  "BEFORE_TAX_AMOUNT_TOTAL": "Montant hors taxe total",
  "BEFORE_TAX_AMOUNT_TOTAL_1": "Montant HT total",
  "bfColumnType": "Type de colonne",
  "BILLING_ITEMS": "Eléments de facturation",
  "BOR": "Bordure",
  "BREAK_OPTION": "Option de break",
  "BREAKPOINT AMOUNT 1": "Palier 1 sur chiffre d'affaires (€/an)",
  "BREAKPOINT AMOUNT 2": "Palier 2 sur chiffre d'affaires (€/an)",
  "BREAKPOINT RATE 1": "Pourcentage de la tranche 1",
  "BREAKPOINT RATE 2": "Pourcentage de la tranche 2",
  "BREAKPOINT_AMOUNT_1": "Palier 1 sur chiffre d'affaires (€/an)",
  "BREAKPOINT_AMOUNT_2": "Palier 2 sur chiffre d'affaires (€/an)",
  "BREAKPOINT_RATE_1": "Pourcentage de la tranche 1",
  "BREAKPOINT_RATE_2": "Pourcentage de la tranche 2",
  "btnAdditionalFieldDownload": "Télécharger le fichier modèle",
  "btnBackAccount": "Retour à mon compte",
  "btnBackSearch": "Retour aux résultats de recherche",
  "btnBrowse": "Parcourir",
  "btnBuildingPurchasing": "Immeubles en cours d'acquisition",
  "btnCancel": "Annuler",
  "btnCancelModalPopup": "Fermer",
  "btnClearCache": "Vider les caches",
  "btnClose": "Fermer",
  "btnCreate": "Créer",
  "btnDelete": "Supprimer",
  "btnDeletePreviousFundUpdate": "Supprimer la mise à jour sélectionnée",
  "btnDeletePurchase": "Supprimer l'acquisition",
  "btnDeleteSale": "Supprimer la cession",
  "btnDeleteTranscodingType": "Supprimer le type de données",
  "btnDeleteTranscodingTypeConfirmation": "Êtes-vous sûrs de vouloir supprimer ce mapping ?",
  "btnDeleteTranscodingTypeConfirmationResult": "Le mapping est supprimé.",
  "btnDownloadDoc": "Documentation utilisateur",
  "btnErvDownload": "Télécharger le fichier modèle des VLM",
  "btnExport": "Exporter",
  "btnFileTypeNotAllowed": "Type de fichier non autorisé",
  "btnFundDates": "Dates",
  "btnFundOwnershipDownload": "Télécharger le fichier d'import des données détenteurs",
  "btnGeneralInformation": "Informations générales",
  "btnGenerate": "Générer le fichier",
  "btnGenerateFile": "Générer le fichier",
  "btnImport": "Importer",
  "btnImportDownload": "Télécharger le fichier modèle",
  "btnInsert": "Ajouter",
  "btnLaunch": "Lancer l'interface",
  "btnLaunchInterface": "Lancer l’interface",
  "btnLaunchReport": "Lancer le rapport",
  "btnLeaseRepositoryDownload": "Télécharger le fichier modèle",
  "btnNetBookValueDownload": "Importer les VNC",
  "btnNewBuilding": "Nouvel immeuble",
  "btnNewInterfaceLowCode": "Nouvelle interface low code",
  "btnNewInterfaceTs": "Nouvelle interface Etat locatif",
  "btnNewPurchase": "Nouvelle acquisition",
  "btnNewUser": "Nouvel utilisateur",
  "btnNext": "Suivant",
  "btnNormalReload": "Rechargement du Référentiel immeubles et des données du bail",
  "btnOwnership": "Détention",
  "btnPartialReload": "Rechargement des VNC, données d'expertises, VLM et taux de rendement",
  "btnPurchasingHistory": "Historique des acquisitions",
  "btnRedirect": "Retour vers page login",
  "btnRepositoryBuildingExport": "Export du référentiel immeubles",
  "btnRepositoryFundExport": "Export du référentiel société",
  "btnReset": "Réinitialisation des paramètres",
  "btnReturnListInterface": "Retour à la liste des interfaces",
  "btnSalesHistory": "Cessions archivées",
  "btnSave": "Enregistrer",
  "btnSearch1": "Lancer la recherche",
  "btnSelectColumns": "Colonnes",
  "btnSelectLogo": "Personnalisation",
  "btnSelectReportLogo": "Changer le logo",
  "btnSendGED": "Voir mes factures",
  "btnSubmit": "Valider",
  "btnTotalReload": "Rechargement total",
  "btnUnlock": "Déverrouiller",
  "btnUpdate": "Modifier",
  "btnUpdateLocation": "Générer les coordonnées géographiques",
  "btnUpdatePurchase": "Mise à jour acquisition",
  "btnUpload": "Importer le fichier",
  "btnValidatePdd": "Valider les PDD",
  "btnValidatePurchase": "Valider l'acquisition",
  "btnValidateSale": "Valider la cession",
  "btnValuationDownload": "Importer les données d'expertise",
  "btnYieldDownload": "Importer les taux de rendement",
  "btSelectBuildingImage": "Changer la photo",
  "btStreetView": "Street View",
  "BU MANAGER": "Directeur de BU",
  "BU_MANAGER": "Directeur de BU",
  "BUDGET 01 N": "Budget Janvier",
  "BUDGET 02 N": "Budget Février",
  "BUDGET 03 N": "Budget Mars",
  "BUDGET 04 N": "Budget Avril",
  "BUDGET 05 N": "Budget Mai",
  "BUDGET 06 N": "Budget Juin",
  "BUDGET 07 N": "Budget Juillet",
  "BUDGET 08 N": "Budget Aout",
  "BUDGET 09 N": "Budget Septembre",
  "BUDGET 10 N": "Budget Octobre",
  "BUDGET 11 N": "Budget Novembre",
  "BUDGET 12 N": "Budget Décembre",
  "BUDGET AMOUNT BT": "Budget",
  "BUDGET BEGIN DATE": "Date début de budget",
  "BUDGET CODE": "Code Budget",
  "BUDGET END DATE": "Date fin de budget",
  "BUDGET INITIAL N": "Budget initial",
  "BUDGET INITIAL N M1": "Budget initial",
  "BUDGET INITIAL N P1": "Budget initial",
  "BUDGET LINE": "Numéro de compte client",
  "BUDGET LINE 1": "Libellé de la ligne budgétaire",
  "BUDGET LINE CODE": "Code de la ligne budgétaire",
  "BUDGET MONITORING": "Fiche budgétaire",
  "BUDGET MONITORING AS AT": "Fiche budgétaire au",
  "BUDGET NAME": "Libellé budget",
  "BUDGET T1 N": "Budget 1T",
  "BUDGET T2 N": "Budget 2T",
  "BUDGET T3 N": "Budget 3T",
  "BUDGET T4 N": "Budget 4T",
  "BUDGET TODATE N": "Budget au",
  "BUDGET TYPE": "Type de budget",
  "BUDGET YEAR": "Année budgetaire",
  "BUDGET_01_N": "Budget Janvier YYYY",
  "BUDGET_02_N": "Budget Février YYYY",
  "BUDGET_03_N": "Budget Mars YYYY",
  "BUDGET_04_N": "Budget Avril YYYY",
  "BUDGET_05_N": "Budget Mai YYYY",
  "BUDGET_06_N": "Budget Juin YYYY",
  "BUDGET_07_N": "Budget Juillet YYYY",
  "BUDGET_08_N": "Budget Aout YYYY",
  "BUDGET_09_N": "Budget Septembre YYYY",
  "BUDGET_10_N": "Budget Octobre YYYY",
  "BUDGET_11_N": "Budget Novembre YYYY",
  "BUDGET_12_N": "Budget Décembre YYYY",
  "BUDGET_BEGIN_DATE": "Date début de budget",
  "BUDGET_CODE": "Code Budget",
  "BUDGET_END_DATE": "Date fin de budget",
  "BUDGET_INITIAL_N": "Budget initial YYYY",
  "BUDGET_INITIAL_N_M1": "Budget initial YYYY-1",
  "BUDGET_INITIAL_N_P1": "Budget initial YYYY+1",
  "BUDGET_LINE": "Numéro de compte client",
  "BUDGET_LINE_1": "Libellé de la ligne budgétaire",
  "BUDGET_LINE_CODE": "Code de la ligne budgétaire",
  "BUDGET_NAME": "Libellé budget",
  "BUDGET_T1_N": "Budget 1T YYYY",
  "BUDGET_T2_N": "Budget 2T YYYY",
  "BUDGET_T3_N": "Budget 3T YYYY",
  "BUDGET_T4_N": "Budget 4T YYYY",
  "BUDGET_TODATE_N": "Budget au DDMMYYYY",
  "BUDGET_TYPE": "Type de budget",
  "BUDGET_YEAR": "Année budgetaire",
  "BUDGETED N": "Budget (€/an)",
  "BUDGETED_N": "Budget HT YYYY (€/an)",
  "BUILDING": "Bâtiment",
  "BUILDING ADDRESS": "Adresse",
  "BUILDING CITY": "Ville",
  "BUILDING CODE": "Code de l'immeuble",
  "BUILDING COUNTRY": "Pays",
  "BUILDING DELIVERY DATE": "Date de livraison",
  "BUILDING DESCRIPTION": "Description immeuble",
  "BUILDING MAIN USE": "Type d'immeuble",
  "BUILDING NAME": "Nom de l'immeuble",
  "BUILDING NATURE": "Type d'immeuble",
  "BUILDING NATURE LABEL": "Type d'immeuble",
  "BUILDING NATURE LABEL TOTAL": "Total",
  "BUILDING NATURE LABEL TOTAL1": "total",
  "BUILDING NUMBER": "N° du bâtiment",
  "BUILDING OTHER NATURES": "Autres types d'immeuble",
  "BUILDING OTHER USES": "Usage(s) secondaire(s)",
  "BUILDING POSTAL CODE": "Code postal",
  "BUILDING PRICE": "Prix de l'immeuble (€)",
  "BUILDING PURCHASE DATE": "Date d'acquisition",
  "BUILDING SUMMARY": "Résumé de l'immeuble",
  "BUILDING SURFACE": "Surface de l'immeuble",
  "BUILDING TENANCY SCHEDULE": "Etat locatif par immeuble au",
  "BUILDING TENANCY SCHEDULE SHEET": "Etat locatif par immeuble",
  "BUILDING TOTAL": "Total",
  "BUILDING TOTAL AREA": "Surface totale (m²)",
  "BUILDING TOTAL AREA SQM": "Surface de l'immeuble (m²)",
  "BUILDING TOTAL AREA SQM AT DATE": "Surface totale (m²) au",
  "BUILDING TOTAL VALUE": "Valeur vénale nette par classe d'actifs (€)",
  "BUILDING TYPE LABEL": "Typologie d'immeuble",
  "BUILDING_ACCUMULATED_DEPRECIATION": "Amortissement cumulé du gros œuvre",
  "BUILDING_ADDRESS": "Adresse",
  "BUILDING_ADDRESS1": "Adresse",
  "BUILDING_ADDRESS1_required": "Adresse obligatoire",
  "BUILDING_ADDRESS2": "Autre adresse",
  "BUILDING_CITY": "Ville",
  "BUILDING_CITY_required": "Ville obligatoire",
  "BUILDING_CODE": "Code de l'immeuble",
  "BUILDING_CODE_required": "Code de l'immeuble obligatoire",
  "BUILDING_COUNTRY": "Pays",
  "BUILDING_COUNTRY_required": "Pays obligatoire",
  "BUILDING_DELIVERY_DATE": "Date de livraison",
  "BUILDING_LATITUDE": "Latitude",
  "BUILDING_LONGITUDE": "Longitude",
  "BUILDING_NAME": "Nom de l'immeuble",
  "BUILDING_NAME_required": "Nom de l'immeuble obligatoire",
  "BUILDING_NATURE": "Type d'immeuble",
  "BUILDING_NATURE_CODE": "Code type d'immeuble",
  "BUILDING_NATURE_LABEL": "Type d'immeuble",
  "BUILDING_POSTAL_CODE": "Code postal",
  "BUILDING_POSTAL_CODE_required": "Code postal obligatoire",
  "BUILDING_PRICE": "Prix de l'immeuble (€)",
  "BUILDING_PRICE_mismatch": "Prix de l'immeuble format incorrect",
  "BUILDING_PRICE_WOU": "Prix de l'immeuble",
  "BUILDING_PURCHASE_DATE": "Date d'acquisition",
  "BUILDING_SEARCH": "Rechercher un immeuble à céder",
  "BUILDING_SURFACE": "Surface de l'immeuble",
  "BUILDING_TOTAL_AREA": "Surface totale (m²)",
  "BUILDING_TOTAL_AREA_SQM": "Surface de l'immeuble (m²)",
  "BUILDING_TOTAL_AREA_SQM_AT_DATE": "Surface totale (m²) au DD/MM/YYYY",
  "BUILDING_TOTAL_AREA_SQM_mismatch": "Surface totale format incorrect",
  "BUILDING_TOTAL_AREA_SQM_WOU": "Surface totale",
  "BUILDING_TOTAL_VALUE": "Valeur vénale nette par classe d'actifs (€)",
  "BUILDING_TOTAL_VALUE_mismatch": "Prix total format incorrect",
  "BUILDING_TOTAL_VALUE_required": "Prix total obligatoire",
  "BUILDING_TOTAL_VALUE_SQM": "Prix total par m² (€/m²)",
  "BUILDING_TOTAL_VALUE_WOU": "Prix total de l'immeuble",
  "BUILDING_TYPE_CODE": "Code typologie d'immeuble",
  "BUILDING_TYPE_LABEL": "Typologie d'immeuble",
  "BUILDING_UNIQUE_CODE": "Code de l'immeuble",
  "BUILDING_VALUE": "Montant du gros œuvre",
  "BUILDING_VALUE_VARIATION_RATE": "Taux de variation",
  "BUILDINGS COUNT": "Nombre d'immeubles",
  "BUILDINGS PERCENT": "% du nombre total d'immeubles",
  "BUILDINGS_COUNT": "Nombre d'immeubles",
  "BUILDINGS_PERCENT": "% du nombre total d'immeubles",
  "BUSINESS_UNITS": "Sites de gestion",
  "BUYER NAME": "Nom de l'acquéreur",
  "BUYER_NAME": "Nom de l'acquéreur",
  "BVARIATION RATE 01 N": "Taux de variation 01/",
  "BVARIATION_RATE_01_N": "Taux de variation 01/YYYY",
  "BY BUILDING NATURE": "Par type d'immeuble",
  "BY BUILDING NATURE SUMED": "Par type d'immeuble cumulé",
  "BY FUND NAME": "Par société",
  "BY FUND NAME SUMED": "Par société cumulé",
  "BY INVOICE": "Par facture",
  "BY INVOICE SUMED": "Par facture cumulé",
  "BY INVOICING ITEM": "Par rubrique de facturation",
  "BY INVOICING ITEM SUMED": "Par rubrique de fact. cumulé",
  "BY LEASE": "Par bail",
  "BY LEASE NATURE": "Par type de bail",
  "BY LEASE SUMED": "Par bail cumulé",
  "BY OWNER": "Par propriétaire",
  "BY OWNER SUMED": "Par propriétaire cumulé",
  "BY PORTFOLIO": "Par portefeuille",
  "BY PORTFOLIO SUMED": "Par portefeuille cumulé",
  "BY PROPERTY": "Par immeuble",
  "BY PROPERTY SUMED": "Par immeuble cumulé",
  "BY YEAR": "Par année",
  "CANCELLED_AT": "Annuler le",
  "CAPITAL": "Capital (€)",
  "CAPITAL CALLS": "Capitaux appelés (€)",
  "CAPITAL EXPENSES": "Dépenses en capital (€/an)",
  "CAPITAL GAIN REINVESTMENT": "Plus-values réinvesties (€)",
  "CAPITAL GROWTH": "Croissance de la valeur vénale (€)",
  "CAPITAL GROWTH BREAKDOWN": "Décomposition de l'évolution de la valeur vénale nette par immeuble",
  "CAPITAL GROWTH RATE": "Croissance de la valeur vénale (%)",
  "CAPITAL RECEIPTS": "Recettes en capital (€/an)",
  "CAPITAL RETURN": "Rendement en capital",
  "CAPITAL RETURN FUND": "Rendement latent sur titre",
  "CAPITAL STRUCTURE": "CR + Structure du capital",
  "CAPITAL_CALLS": "Capitaux appelés (€)",
  "CAPITAL_EXPENSES": "Dépenses en capital YYYY-1 (€/an)",
  "CAPITAL_GAIN_REINVESTMENT": "Plus-values réinvesties (€)",
  "CAPITAL_GROWTH": "Croissance de la valeur vénale (€)",
  "CAPITAL_GROWTH_RATE": "Croissance de la valeur vénale (%)",
  "CAPITAL_RECEIPTS": "Recettes en capital YYYY-1 (€/an)",
  "CAPITAL_RETURN": "Rendement en capital",
  "CASH FLOW": "Cash flow immobilier",
  "CASH FLOW BREAKDOWN": "Décomposition du cash flow immobilier",
  "CASH ON CASH": "Cash-on-Cash",
  "CASH ON CASH RETURN": "Rendement Cash-on-Cash",
  "CASH_FLOW": "Cash flow immobilier (€)",
  "CATCH UP AMOUNT": "Montant du rattrapage (€)",
  "CATCH UP END DATE": "Date de fin du rattrapage",
  "CATCH UP PERIOD": "Durée du rattrapage (mois)",
  "CATCH UP START DATE": "Date de début du rattrapage",
  "CATCH_UP_AMOUNT": "Montant du rattrapage (€)",
  "CATCH_UP_END_DATE": "Date de fin du rattrapage",
  "CATCH_UP_PERIOD": "Durée du rattrapage (mois)",
  "CATCH_UP_START_DATE": "Date de début du rattrapage",
  "cbActivation": "Activation interface",
  "cbErvDownload": "Télécharger le fichier pré-rempli avec les dernières données chargées",
  "cbFundOwnershipDownload": "Télécharger le fichier pré-rempli avec les données à la dernière date de mise à jour",
  "cbLeaseRepositoryDownload": "Télécharger le fichier pré-rempli avec les dernières données chargées",
  "cbNetBookValueDownload": "Télécharger le fichier pré-rempli avec les données à la dernière date d'arrêté",
  "cbValuationDownload": "Télécharger le fichier pré-rempli avec les données à la dernière date de valeur",
  "cbYieldDownload": "Télécharger le fichier pré-rempli avec les données à la dernière date de valeur",
  "CHANTIER_CODE_DESTINATAIRE": "Chantier code destinatire",
  "CHANTIER_CODE_EMETTEUR": "Chantier code emetteur",
  "CHANTIER_EMETTEUR_PREALABLE": "Chantier emetteur préalable",
  "CHARTS": "Graphiques",
  "CHARTS BY AREA": "Graphiques par surface",
  "CHARTS BY BUILDING NATURE": "Graphiques par type d'immeuble",
  "CHARTS BY LEASE": "Graphiques par bail",
  "CHARTS BY PROPERTY TYPE": "Graphiques par typologie d'immeuble",
  "CHARTS BY UNIT": "Graphiques par lot",
  "CHRONO GED": "Chrono",
  "CHRONO_GED": "Chrono",
  "CITY": "Ville",
  "CLASSIFICATION_ELLIGIBLE_PINEL": "Classification élligible PINEL",
  "CLASSIFICATION_ICMS_EQUIPEMENT": "Code équipement ICMS",
  "CLEAR_FILTERS": "Effacer les filtres",
  "CLIENT ACCOUNT NUMBER": "Numéro de compte client",
  "CLIENT ACCOUNT NUMBER LABEL": "Libellé du compte client",
  "CLIENT_ACCOUNT_NUMBER": "Numéro de compte client",
  "CLIENT_ACCOUNT_NUMBER_LABEL": "Libellé du compte client",
  "CODE_EQUIPEMENT_CLASSIFICATION_SYPEMI": "Code équipement SYPEMI",
  "CODE_POSTAL": "Code postal",
  "COLLECTION MONITORING": "Suivi des encaissements",
  "COLLECTION MONITORING BREAKDOWN": "Répartition des encaissements par délai d'encaissement",
  "COLLECTION MONITORING BREAKDOWN BY BUILDING": "Répartition des encaissements par délai cumulé d'encaissement et par immeuble",
  "COLLECTION MONITORING BREAKDOWN BY PORTFOLIO": "Répartition des encaissements par délai cumulé d'encaissement et par portefeuille",
  "COLLECTION MONITORING SUM": "Cumul des encaissements par délai d'encaissement",
  "COLLECTION RATE": "Taux de recouvrement",
  "COLLECTION_RATE": "Taux de recouvrement",
  "COLOR_BACK": "Couleur de fond",
  "COLOR_FONT": "Couleur de police",
  "COLUMN NAME": "Nom de la colonne",
  "COLUMN_NAME": "Nom de la colonne",
  "COMMENT": "Commentaires",
  "COMMENT N": "Commentaire",
  "COMMENT N M1": "Commentaire",
  "COMMENT N P1": "Commentaire",
  "COMMENT_N": "Commentaire YYYY",
  "COMMENT_N_M1": "Commentaire YYYY-1",
  "COMMENT_N_P1": "Commentaire YYYY+1",
  "COMMENTAIRE": "Commentaire",
  "COMMENTAIRE_VALIDATION": "Commentaire validation",
  "COMMENTS": "Commentaires",
  "COMMERCIAL LEASE SCHEDULE": "Echéancier des baux commerciaux",
  "COMMITMENT": "Engagement (€)",
  "COMMITMENT AGREEMENT DATE": "Date de l'accord d'engagement",
  "COMMITMENT_AGREEMENT_DATE": "Date de l'accord d'engagement",
  "COMMITMENT_AGREEMENT_DATE_mismatch": "Date de l'accord d'engagement format incorrect",
  "COMMON AREA SHARE": "Quote-part de parties communes (m²)",
  "COMMON_AREA_SHARE": "Quote-part de parties communes (m²)",
  "COMPANY REGISTRATION NUMBER": "N° de RCS",
  "COMPANY_REGISTRATION_NUMBER": "N° de RCS",
  "Compilation Failed": "La compilation a échoué",
  "CONDOMINIUM UNIT CODE": "N° du lot de copropriété",
  "CONDOMINIUM_UNIT_CODE": "N° du lot de copropriété",
  "Conflict": "Conflit",
  "CONTACT_FIRSTNAME": "Nom",
  "CONTACT_FUNCTION": "Fonction",
  "CONTACT_NAME": "Prénom",
  "CONTACT_SERVICE": "Service",
  "CONTRACT COMMAND CODE": "Contrat ou commande",
  "CONTRACT COMMAND NAME": "Libellé de la commande ou du contrat",
  "CONTRACT TYPE CODE": "Type de contrat",
  "CONTRACT_COMMAND_CODE": "Contrat ou commande",
  "CONTRACT_COMMAND_NAME": "Libellé de la commande ou du contrat",
  "CONTRACT_TYPE_CODE": "Type de contrat",
  "CONTRIBUTION TO PORTFOLIO TOTAL RETURN": "Contribution au rendement global du portefeuille",
  "CONTROL DATE": "Date du contrôle",
  "CONTROL_DATE": "Date du contrôle",
  "COST PRICE AMOUNT": "Montant du prix de revient (€)",
  "COST_PRICE_AMOUNT": "Montant du prix de revient (€)",
  "COST_PRICE_AMOUNT_mismatch": "Montant du prix de revient format incorrect",
  "COST_PRICE_AMOUNT_WOU": "Montant du prix de revient",
  "COUNTRY": "Pays",
  "Creation": "Création",
  "CREATION DATE": "Date de création",
  "CREDIT": "Crédit",
  "CURRENCY": "Devise",
  "CURRENCY PER SQM PER YEAR": "€/m²/a",
  "CURRENCY PER YEAR": "€/a",
  "CURRENT ANNUAL RENT AMOUNT": "Loyer facial (€/an)",
  "CURRENT EQUIPMENT GUARANTEE": "Caution Equipement actuelle (€)",
  "CURRENT GUARANTEE 1": "Caution actuelle 1 (€)",
  "CURRENT GUARANTEE 2": "Caution actuelle 2 (€)",
  "CURRENT GUARANTEE 3": "Caution actuelle 3 (€)",
  "CURRENT INDEX DATE": "Date de l'indice actuel",
  "CURRENT INDEX TYPE LABEL": "Type d'indice actuel",
  "CURRENT INDEX VALUE": "Indice actuel",
  "CURRENT LEASE SUMMARY": "Résumé du bail en cours",
  "CURRENT MARKET VALUE": "Valeur locative de marché (€/m²/an)",
  "CURRENT MONTHLY RENT AMOUNT": "Loyer facial mensuel (€/mois)",
  "CURRENT REAL ESTATE DISPOSITION": "Cessions en cours au",
  "CURRENT REAL ESTATE PURCHASE": "Acquisitions en cours au",
  "CURRENT RENT AMOUNT PER SQM": "Loyer facial par m² (€/m²/an)",
  "CURRENT WORKING CAPITAL": "Fond roulement actuel (€)",
  "CURRENT_ANNUAL_RENT_AMOUNT": "Loyer facial (€/an)",
  "CURRENT_EQUIPMENT_GUARANTEE": "Caution Equipement actuelle (€)",
  "CURRENT_GUARANTEE_1": "Caution actuelle 1 (€)",
  "CURRENT_GUARANTEE_2": "Caution actuelle 2 (€)",
  "CURRENT_GUARANTEE_3": "Caution actuelle 3 (€)",
  "CURRENT_INDEX_DATE": "Date de l'indice actuel",
  "CURRENT_INDEX_TYPE_LABEL": "Type d'indice actuel",
  "CURRENT_INDEX_VALUE": "Indice actuel",
  "CURRENT_MARKET_VALUE": "Valeur locative de marché (€/m²/an)",
  "CURRENT_MONTHLY_RENT_AMOUNT": "Loyer facial mensuel (€/mois)",
  "CURRENT_RENT_AMOUNT_PER_SQM": "Loyer facial par m² (€/m²/an)",
  "CURRENT_WORKING_CAPITAL": "Fond roulement actuel (€)",
  "CUSTOMER_GROUP_CODE": "Code portefeuille",
  "CUSTOMER_GROUP_NAME": "Nom portefeuille",
  "CUT_OFF_DATE": "Date d'arrêté comptable",
  "DATE OF CONSTRUCTION": "Date de construction",
  "DATE OF DATA": "Fiche immeuble au",
  "DATE OF PURCHASE": "Date d'acquisition",
  "DATE_DEBUT_TRAVAUX_LIEE_AU_STATUT": "Date début travaux",
  "DATE_ENVOI": "Date d'import",
  "DATE_RECEPTION_TRAVAUX_LIEE_AU_STATUT": "Date réception travaux",
  "DateFormat": "dd/MM/yyyy",
  "DE_PORTFOLIO_LABEL": "Nom du portefeuille (Allemand)",
  "DE_SECTOR_LABEL": "Secteur (Allemand)",
  "DE1": "Cellule",
  "DEBIT": "Débit",
  "DECEMBER_AMOUNT": "Décembre (€)",
  "Deletion": "Suppression",
  "DELIVERY ACTUAL DATE": "Date effective de livraison",
  "DELIVERY ESTIMATED DATE": "Date prévisionnelle de livraison",
  "DELIVERY_ACTUAL_DATE": "Date effective de livraison",
  "DELIVERY_ACTUAL_DATE_mismatch": "Date effective de livraison format incorrect",
  "DELIVERY_ESTIMATED_DATE": "Date prévisionnelle de livraison",
  "DELIVERY_ESTIMATED_DATE_mismatch": "Date prévisionnelle de livraison format incorrect",
  "DEPRECIATION": "Amortissements (€)",
  "DEPRECIATION PROVISION AMOUNT": "Provisions pour dépréciation comptabilisées (€)",
  "DEPRECIATION_PROVISION_AMOUNT": "Provisions pour dépréciation comptabilisées (€)",
  "DESCRIPTIF_DE_LA_NATURE_DU_CHANTIER": "Descriptif de la Nature du chantier",
  "DESCRIPTION_DE_LA_ZONE_DU_CHANTIER": "Description de la Zone du chantier",
  "DESTINATAIRE": "Destinataire",
  "DESTINATION_CODE": "Code destination",
  "DESTINATION_LABEL": "Libellé destination",
  "DETAILLED AGING SCHEDULE": "Balance agée détaillée",
  "DETENTION STRUCTURE": "Structure de détention",
  "DIFF BUDGET N REAL N MINUS 1": "Variation Budget/Réel (€/an)",
  "DIFF BUDGETED N REALISED N": "Ecart Réel/Budget (€/an)",
  "DIFF N M1": "Différence Réalisé / Comptable",
  "DIFF N M1 01": "Différence Réalisé",
  "DIFF N M1 02": "/comptable",
  "DIFF REALISED N BUDGETED N": "Budget disponible (€/an)",
  "DIFF REALISED N N MINUS 1": "Variation Réel (€/an)",
  "DIFF_BUDGET_N_REAL_N_MINUS_1": "Variation Budget HT YYYY vs Réel YYYY-1 (€/an)",
  "DIFF_BUDGETED_N_REALISED_N": "Ecart Réel/Budget HT YYYY (€/an)",
  "DIFF_N_M1": "Différence Réalisé YYYY-1 /comptable YYYY-1",
  "DIFF_REALISED_N_BUDGETED_N": "Budget disponible YYYY (€/an)",
  "DIFF_REALISED_N_N_MINUS_1": "Variation Réel HT YYYY vs YYYY-1 (€/an)",
  "DISPLAY NAME": "Description",
  "DISPLAY_NAME": "Description",
  "DISPLAY_THE_LEASE_THAT_ARE": "Sélectionner les baux…",
  "DISPLAY_THE_PROP_THAT_ARE": "Sélectionner les immeubles…",
  "DISPOSITION HISTORY €": "Historique des cessions en nombre d'immeubles et valeur vénale nette par type d'immeuble",
  "DISPOSITION HISTORY M2": "Historique des cessions en nombre d'immeubles et surface par type d'immeuble",
  "DISPOSITION HISTORY RECORD": "Historique des cessions",
  "DISPOSITION VALUE": "Valeur de cession (€)",
  "DISPOSITION VALUE SQM": "Valeur de cession par m² (€/m²)",
  "DISTRIBUTION VALORIZATION": "Distribution + Valorisation",
  "DIVIDEND": "Dividendes (€)",
  "DIVIDEND ADVANCE": "Acomptes sur dividende (€)",
  "DIVIDEND PER SHARE": "Dividende unitaire",
  "DIVIDEND PROFIT DISTRIBUTIONS": "Résultat ou dividende (€)",
  "DIVIDEND_ADVANCE": "Acomptes sur dividende (€)",
  "DIVIDEND_PER_SHARE": "Dividende unitaire",
  "DOCUMENT NUMBER": "Numéro de la facture",
  "DOCUMENT_DATE": "Date de la pièce",
  "DOCUMENT_LINE_NUMBER": "N° de ligne du document",
  "DOCUMENT_NUMBER": "Numéro de document",
  "DOCUMENT_TYPE_LABEL": "Type de document",
  "DOOR NUMBER": "N° de la porte",
  "DOOR_NUMBER": "N° de la porte",
  "DOTATION PDDI": "Dotation aux PDD (€)",
  "DOTATION_PDDI": "Dotation aux PDD (€)",
  "DOUBLE NET RENT": "Loyer double net (€)",
  "DOUBLE NET RENT WOU": "Loyer double net",
  "DUE DATE": "Date d'exigibilité",
  "DUE INVOICES BALANCE END AMOUNT": "Solde final des factures exigibles (€) au",
  "DUE INVOICES BALANCE START AMOUNT": "Solde initial des factures exigibles (€) au",
  "DUE OUTSTAND PAY INC TAX AMOUNT": "Impayés exigibles (€)",
  "DUE_DATE": "Date d'exigibilité",
  "DUE_INVOICES_BALANCE_END_AMOUNT": "Solde final des factures exigibles (€) au DD/MM/YYYY",
  "DUE_INVOICES_BALANCE_START_AMOUNT": "Solde initial des factures exigibles (€) au DD/MM/YYYY",
  "DUE_OUTSTAND_PAY_INC_TAX_AMOUNT": "Impayés exigibles (€)",
  "DURATION": "Durée ferme résiduelle (a-m-j)",
  "DURATION TO BREAK DATE": "Durée ferme résiduelle (a-m-j)",
  "DURATION TO LEASE END": "Durée théorique résiduelle (a-m-j)",
  "DURATION_TO_BREAK_DATE": "Durée ferme résiduelle (a-m-j)",
  "DURATION_TO_LEASE_END": "Durée théorique résiduelle (a-m-j)",
  "EARNINGS BEFORE INTEREST": "Résultat d'exploitation (€)",
  "EARNINGS_BEFORE_INTEREST": "Résultat d'exploitation (€)",
  "EBIT": "Résultat d'exploitation",
  "ECONOMIC RENT": "Loyer économique (€/an)",
  "ECONOMIC RENT BY HEADLINE RENT": "Loyer économique / Loyer facial (%)",
  "ECONOMIC RENT BY SQM": "Loyer économique par m² (€/m²/an)",
  "ECONOMIC_RENT": "Loyer économique (€/an)",
  "ECONOMIC_RENT_BY_HEADLINE_RENT": "Loyer économique / Loyer facial (%)",
  "ECONOMIC_RENT_PER_SQM": "Loyer économique par m² (€/m²/an)",
  "EDITION TIME": "Heure d'édition :",
  "EFFECTIVE GROSS CAPITAL RATE": "Taux de capitalisation effectif brut",
  "EFFECTIVE GROSS INITIAL YIELD": "Taux de rendement effectif brut",
  "EFFECTIVE NET CAPITAL RATE": "Taux de capitalisation effectif net",
  "EFFECTIVE NET INITIAL YIELD": "Taux de rendement effectif net",
  "EFFECTIVE RENT": "Loyer effectif (€/an)",
  "EFFECTIVE_GROSS_CAPITAL_RATE": "Taux de capitalisation effectif brut",
  "EFFECTIVE_GROSS_INITIAL_YIELD": "Taux de rendement effectif brut",
  "EFFECTIVE_NET_CAPITAL_RATE": "Taux de capitalisation effectif net",
  "EFFECTIVE_NET_INITIAL_YIELD": "Taux de rendement effectif net",
  "EFFECTIVE_RENT": "Loyer effectif (€/an)",
  "emailGreetingsToUser": "Cher utilisateur,",
  "emailLinkCodeExpiration": "Ce lien et ce code seront valides 15mn, passé ce délai, vous devrez renouveler votre demande de récupération du mot de passe",
  "emailLinkExpiration": "Ce lien sera valide 15mn, passé ce délai, vous devrez renouveler votre demande de récupération du mot de passe",
  "emailPwdRecoveryIntro": "Vous avez effectué une demande de réinitialisation de mot de passe à partir du site Alhambra.,",
  "emailPwdRecoveryLinkClick": "Merci de cliquer sur le lien ci-dessous afin de réinitialiser votre mot de passe.",
  "emailPwdRecoveryObject": "Alhambra - Récupération de mot de passe",
  "emailPwdRecoveryProvideSMSCode": "et de renseigner le mot de passe reçu sur votre téléphone portable.",
  "emailPwdRecoveryProvideTempPwd": "et de renseigner le code temporaire suivant",
  "emailRegards": "Sincères salutations,",
  "emailWebAlhambraTeamSignature": "L'équipe Alhambra.",
  "EMETTEUR": "Emetteur",
  "EN_PORTFOLIO_LABEL": "Nom du portefeuille (Anglais)",
  "EN_SECTOR_LABEL": "Secteur (Anglais)",
  "END MANAGEMENT DATE": "Date de fin de gestion",
  "END_DATE": "Date de fin",
  "END_MANAGEMENT_DATE": "Date de fin de gestion",
  "END_MANAGEMENT_DATE_mismatch": "Date de fin de gestion format incorrect",
  "END_OF_LEASE": "Fin de bail",
  "ENTERPRISE ABATEMENT": "Franchise en cours (€)",
  "ENTERPRISE ANNUAL AMOUNT": "Montant annuel (€/an)",
  "ENTERPRISE GROSS HEADLINE RENT": "Loyer facial brut (€/an)",
  "ENTERPRISE GROSS HEADLINE RENT M2": "Loyer facial brut par m² (€/m²/an)",
  "ENTERPRISE MONTHLY AMOUNT": "Montant mensuel immobilier entreprise (€/mois)",
  "ENTERPRISE REAL ESTATE": "Immobilier d'entreprise",
  "ENTERPRISE RENTED AREA": "Surface occupée (m²)",
  "ENTERPRISE TOTAL AREA": "Surface totale (m²)",
  "ENTERPRISE VACANT AREA": "Surface vacante (m²)",
  "ENTRANCE FEES": "Montant du droit d'entrée (€)",
  "ENTRANCE_FEES": "Montant du droit d'entrée (€)",
  "ENTREPRISE MONTHLY M2 AMOUNT": "Montant mensuel par m² (€/m²/mois)",
  "ENTRY": "Pièce",
  "ENTRY_AMOUNT": "Montant de l'écriture",
  "ENTRY_DIRECTION_CODE": "Code sens de l'écriture",
  "ENTRY_LABEL": "Libellé de l'écriture",
  "EQUIPMENT GUARANTEE CALLS": "Caution Equipement Appelée (€)",
  "EQUIPMENT GUARANTEE RECEIVABLE": "Caution Equipement à encaisser (€)",
  "EQUIPMENT GUARANTEE REFUNDS": "Caution Equipement reversée (€)",
  "EQUIPMENT_GUARANTEE_CALLS": "Caution Equipement Appelée (€)",
  "EQUIPMENT_GUARANTEE_RECEIVABLE": "Caution Equipement à encaisser (€)",
  "EQUIPMENT_GUARANTEE_REFUNDS": "Caution Equipement reversée (€)",
  "EQUITY": "Capitaux propres (€)",
  "EQUITY FINANCING": "Investissement",
  "ERE ABATEMENT": "Immobilier d'entreprise - Franchise en cours (€)",
  "ERE GROSS HEADLINE RENT": "Immobilier d'entreprise - Loyer facial brut (€/an)",
  "ERE GROSS HEADLINE RENT M2": "Immobilier d'entreprise - Loyer facial brut par m² (€/m²/an)",
  "ERE RENTED AREA": "Immobilier d'entreprise - Surface occupée (m²)",
  "ERE TOTAL AREA": "Immobilier d'entreprise - Surface totale (m²)",
  "ERE VACANT AREA": "Immobilier d'entreprise - Surface vacante (m²)",
  "ERE_ABATEMENT": "Immobilier d'entreprise - Franchise en cours (€)",
  "ERE_GROSS_HEADLINE_RENT": "Immobilier d'entreprise - Loyer facial brut (€/an)",
  "ERE_GROSS_HEADLINE_RENT_M2": "Immobilier d'entreprise - Loyer facial brut par m² (€/m²/an)",
  "ERE_OCCUPIED_AREA_SQM": "Immobilier d'entreprise - Surface occupée (m²)",
  "ERE_TOTAL_AREA_SQM": "Immobilier d'entreprise - Surface totale (m²)",
  "ERE_VACANT_AREA_SQM": "Immobilier d'entreprise - Surface vacante (m²)",
  "errorHandler": "Page Erreur",
  "ESTIMATED_RENTAL_VALUE": "Valeur locative de marché (€/m²/an)",
  "ESTIMATED_RENTAL_VALUE_WOU": "Valeur locative de marché",
  "ETAT_DE_LA_FONCTION_DE_L_EQUIPEMENT": "Etat de la fonction de l'équipement",
  "ETAT_VISUEL_DE_L_EQUIPEMENT": "Etat visuel de l'équipement",
  "EU VAT IDENTIFICATION NUMBER": "N° de TVA intracommunautaire",
  "EU_VAT_IDENTIFICATION_NUMBER": "N° de TVA intracommunautaire",
  "EVALUATION DATE": "Date d'expertise",
  "EVOLUTION OF RENTAL EXPENSES": "Evolution des charges d'exploitation (OPEX)",
  "EVOLUTION OF RENTAL INCOME": "Evolution du loyer",
  "EVOLUTION YEAR": "Année",
  "EVOLUTION_YEAR": "Année",
  "Excel": "Excel",
  "EXPECTED_YIELD": "Taux de rendement attendu",
  "EXPENSES BANK ACCOUNT": "Compte bancaire Dépenses",
  "EXPENSES_BANK_ACCOUNT": "Compte bancaire Dépenses",
  "EXPORT DATE": "Date d'extraction",
  "EXTERNAL DEBT": "Dette bancaire",
  "EXTERNAL FUND NAME": "Nom du fonds",
  "EXTERNAL_BUILDING_NATURE_LABEL": "Type d'immeuble externe",
  "EXTERNAL_FUND_NAME": "Fonds",
  "EXTERNAL_GEOGRAPHIC_ZONE_CODE": "Zone géographique client",
  "EXTRANET REF": "Réf extranet",
  "EXTRANET_REF": "Réf extranet",
  "EXTRAORDINARY DEPRECIATION": "Amortissement dérogatoire (€)",
  "EXTRAORDINARY ITEMS": "Résultat exceptionnel (€)",
  "EXTRAORDINARY RENT": "Loyer exceptionnel (€)",
  "EXTRAORDINARY RENT END DATE": "Date de fin du loyer exceptionnel",
  "EXTRAORDINARY RENT PERIOD": "Durée du loyer exceptionnel",
  "EXTRAORDINARY RENT START DATE": "Date de début du loyer exceptionnel",
  "EXTRAORDINARY RESULT": "Résultat exceptionnel (€)",
  "EXTRAORDINARY_DEPREC": "Amortissement dérogatoire (€)",
  "EXTRAORDINARY_DEPRECIATION": "Amortissement dérogatoire (€)",
  "EXTRAORDINARY_ITEMS": "Résultat exceptionnel (€)",
  "EXTRAORDINARY_RENT": "Loyer exceptionnel (€)",
  "EXTRAORDINARY_RENT_END_DATE": "Date de fin du loyer exceptionnel",
  "EXTRAORDINARY_RENT_PERIOD": "Durée du loyer exceptionnel",
  "EXTRAORDINARY_RENT_START_DATE": "Date de début du loyer exceptionnel",
  "FACADE_ACCUMULATED_DEPRECIATION": "Amortissement cumulé du second œuvre",
  "FACADE_VALUE": "Montant du second œuvre",
  "FAIR MARKET RENT": "Loyer brut de marché (€/an)",
  "FAIR MARKET RENT WOU": "Loyer de marché",
  "FAIR_MARKET_RENT": "Loyer brut de marché (€/an)",
  "FAIR_MARKET_RENT_WOU": "Loyer brut de marché",
  "FEBRUARY_AMOUNT": "Février (€)",
  "FEE NAME": "Centre d'honoraire",
  "FEE TYPE CODE": "Code Typologie Honoraire",
  "FEE TYPE NAME": "Typologie Honoraire",
  "FEE_CENTER_CODE": "Code centre honoraire",
  "FEE_NAME": "Centre d'honoraire",
  "FEE_TYPE_CODE": "Code Typologie Honoraire",
  "FEE_TYPE_NAME": "Typologie Honoraire",
  "FIN_VAC_CURRENT_MONTH": "Vacance financière mois courant",
  "FIN_VAC_MINUS_01_MONTH": "Vacance financière 1 mois plus tôt",
  "FIN_VAC_MINUS_02_MONTH": "Vacance financière 2 mois plus tôt",
  "FIN_VAC_MINUS_03_MONTH": "Vacance financière 3 mois plus tôt",
  "FIN_VAC_MINUS_04_MONTH": "Vacance financière 4 mois plus tôt",
  "FIN_VAC_MINUS_05_MONTH": "Vacance financière 5 mois plus tôt",
  "FIN_VAC_MINUS_06_MONTH": "Vacance financière 6 mois plus tôt",
  "FIN_VAC_MINUS_07_MONTH": "Vacance financière 7 mois plus tôt",
  "FIN_VAC_MINUS_08_MONTH": "Vacance financière 8 mois plus tôt",
  "FIN_VAC_MINUS_09_MONTH": "Vacance financière 9 mois plus tôt",
  "FIN_VAC_MINUS_10_MONTH": "Vacance financière 10 mois plus tôt",
  "FIN_VAC_MINUS_11_MONTH": "Vacance financière 11 mois plus tôt",
  "FINAL CAPITAL CLOSING DATE": "Date de clôture",
  "FINAL_CAPITAL_CLOSING_DATE": "Date de clôture",
  "FINANCIAL INCOME": "Résultat financier (€)",
  "FINANCIAL OCCUPANCY RATE": "Taux d'occupation financier",
  "FINANCIAL OCCUPANCY SUMMARY": "Etat d'occupation financier",
  "FINANCIAL RESULT": "Résultat financier (€)",
  "FINANCIAL VACANCY": "Vacance financière (€/an)",
  "FINANCIAL VACANCY BY SQM": "Vacance financière par m² (€/m²/an)",
  "FINANCIAL VACANCY BY YEAR": "Vacance financière (€/an)",
  "FINANCIAL VACANCY CUMULATE": "Vacance financière cumulée (€)",
  "FINANCIAL VACANCY M2": "Vacance financière (€)",
  "FINANCIAL VACANCY PER SQM": "Vacance financière par m² (€/m²/an)",
  "FINANCIAL VACANCY RATE": "Taux de vacance financier",
  "FINANCIAL VACANCY SHEET": "Vacance financière",
  "FINANCIAL VACANCY WOU": "Vacance financière",
  "FINANCIAL_INCOME": "Résultat financier (€)",
  "FINANCIAL_OCC_RATE": "Taux d occupation financier",
  "FINANCIAL_OCCUPANCY_RATE": "Taux d'occupation financier",
  "FINANCIAL_VAC_RATE": "Taux de vacance financier",
  "FINANCIAL_VACANCY": "Vacance financière (€/an)",
  "FINANCIAL_VACANCY_PER_SQM": "Vacance financière par m² (€/m²/an)",
  "FINANCIAL_VACANCY_RATE": "Taux de vacance financier",
  "FITTINGS_ACCUMULATED_DEPRECIATION": "Amortissement cumulé des agencements et installations",
  "FITTINGS_VALUE": "Montant des agencements et installations",
  "FIXTURES_ACCUMULATED_DEPRECIATION": "Amortissement cumulé des installations techniques",
  "FIXTURES_VALUE": "Montant des installations techniques",
  "FLOOR": "N° de l'étage",
  "FLOORS": "Etages",
  "FOO": "Pied de page",
  "FormatFile": "Format fichier",
  "FormatMontant": "Format des montants",
  "FormatSeparatorThousands": "Format du séparateur de milliers",
  "FR_PORTFOLIO_LABEL": "Nom du portefeuille (Français)",
  "FR_SECTOR_LABEL": "Secteur (Français)",
  "FREED LEASES NUMBER": "Nombre de baux sortis",
  "FREED LEASES RATE": "Taux de sortie (sur les baux)",
  "FREED UNITS AREA": "Surface libérée (m²)",
  "FREED UNITS AREA RATE": "Taux de sortie (sur les surfaces)",
  "FREED UNITS NUMBER": "Nombre de lots libérés",
  "FREED UNITS RATE": "Taux de sortie (sur les lots)",
  "FREED_LEASES_NUMBER": "Nombre de baux sortis",
  "FREED_LEASES_RATE": "Taux de sortie (sur les baux)",
  "FREED_UNITS_AREA": "Surface libérée (m²)",
  "FREED_UNITS_AREA_RATE": "Taux de sortie (sur les surfaces)",
  "FREED_UNITS_NUMBER": "Nombre de lots libérés",
  "FREED_UNITS_RATE": "Taux de sortie (sur les lots)",
  "FreezePanesFromRowNumber": "Figer les volets jusqu'à la colonne n°",
  "FreezePanesUntilLineNumber": "Figer les volets jusqu'à la ligne n°",
  "FREQUENCY": "Périodicité (en mois)",
  "FREQUENCY CODE": "Périodicité de facturation",
  "FREQUENCY_CODE": "Périodicité de facturation (en mois)",
  "FULLSCREEN": "Plein écran",
  "FUND ADDRESS": "Adresse",
  "FUND CODE": "Code de la société",
  "FUND DATA END DATE": "Date de fin de validité",
  "FUND DATA START DATE": "Date de mise à jour",
  "FUND DATE OF DATA": "Date d'arrêté",
  "FUND ISIN CODE": "Code ISIN",
  "FUND LEGAL VEHICLE STRUCT": "Forme juridique / Type d'immeuble",
  "FUND MANAGER": "Fund Manager",
  "FUND NAME": "Nom de la société",
  "FUND PRORATA": "Société (au prorata)",
  "FUND_CODE": "Code de la société",
  "FUND_DATA_END_DATE": "Date de fin de validité",
  "FUND_DATA_START_DATE": "Date de mise à jour",
  "FUND_ISIN_CODE": "Code ISIN",
  "FUND_MANAGER": "Fund Manager",
  "FUND_NAME": "Nom de la société",
  "FUNDING_AMOUNT": "Montant du financement",
  "FUNDS MANAGEMENT": "Référentiel fonds / sociétés",
  "GARBAGE COLLECTION TAX AMOUNT": "Montant de la taxe d'enlèvement des ordures ménagères (€)",
  "GARBAGE_COLLECTION_TAX_AMOUNT": "Montant de la taxe d'enlèvement des ordures ménagères (€)",
  "GEARING RATIO": "Ratio de financement par dette",
  "GENERAL EXPENSES REPORT": "Relevé général de dépenses",
  "GENERAL INFORMATION": "Informations générales",
  "generalErrorMsg": "Un problème a eu lieu sur ce site web. Réessayez SVP. Si cette erreur persiste, contactez le support.",
  "GEOGRAPHICAL BREAKDOWN": "Répartition géographique (en valeur d'expertise HD)",
  "GEOGRAPHICAL SUB ZONE": "Sous-zone géographique",
  "GEOGRAPHICAL ZONE": "Zone géographique",
  "GEOGRAPHICAL_SUB_ZONE": "Sous-zone géographique",
  "GEOGRAPHICAL_ZONE": "Zone géographique",
  "GLOBAL RETURNS": "Rendements périodiques",
  "GONE": "Parti",
  "GR_HEAD_RENT_BY_GR_BOOK_VAL": "Rendement brut sur ABI",
  "GRAPH TENANT TURNOVER LEASES NUMBER": "Evolution des relocations et des baux sortis",
  "GRAPH TENANT TURNOVER UNITS AREA": "Evolution des lots reloués et des lots libérés",
  "GRAPH TENANT TURNOVER UNITS NUMBER": "Evolution des surfaces relouées et des surfaces libérées",
  "Graph1Attr1": "Surface occupée",
  "Graph1Attr2": "Surface vacante",
  "Graph2Attr1": "Bureaux",
  "Graph2Attr2": "Locaux commerciaux",
  "Graph3Attr3": "Loyer potentiel net",
  "Graph3Attr4": "Loyer de marché",
  "Graph4Attr1": "Charges récupérables récupérées",
  "Graph4Attr2": "Charges récupérables",
  "Graph4Attr3": "Charges non récupérables",
  "Graph4Attr4": "Charges d'exploitation totales",
  "Graph5Attr1": "Loyer facial",
  "Graph5Attr2": "Option de break",
  "Graph5Attr3": "Fin de bail",
  "Graph5Attr4": "Taux de rotation",
  "Graph6Attr1": "Réel",
  "Graph6Attr2": "Budget",
  "Graph6Attr3": "Entretien courant",
  "Graph6Attr4": "Gros travaux",
  "graphArbitrageHistoryArea": "Historique des cessions en nombre d'immeubles et surface par type d'immeuble",
  "graphArbitrageHistoryValue": "Historique des cessions en nombre d'immeubles et valeur de cession par type d'immeuble",
  "graphHoldoverTenancyRentAmount": "Répartition des baux non renouvelés par type de bail",
  "graphHoldoverTenancyRentAmountPerYear": "Baux non renouvelés",
  "graphLeaseEnterpriseSchedule1": "Baux à échéance (surface)",
  "graphLeaseEnterpriseSchedule2": "Baux à échéance (loyers)",
  "graphLeaseMonitoringAmount": "Revalorisation des loyers et potentiel de réversion",
  "graphLeaseMonitoringByNature": "Répartition des baux par type",
  "graphOutstandPayBalanceBreak": "Répartition des impayés entre locataires présents et sortis",
  "graphPortfolioBreakdownByNatureArea": "Répartition de la surface par type d'immeuble",
  "graphPortfolioBreakdownByNatureBuildingsNumber": "Répartition des immeubles par type d'immeuble",
  "graphPortfolioBreakdownByNatureLeasesNumber": "Répartition du nombre de baux par type d'immeuble",
  "graphPortfolioBreakdownByNatureMarketValue": "Répartition de la valeur vénale par type d'immeuble",
  "graphPortfolioBreakdownByNatureRent": "Répartition du loyer facial brut par type d'immeuble",
  "graphPortfolioBreakdownByNatureUnitsNumber": "Répartition du nombre de lots par type d'immeuble",
  "graphPortfolioBreakdownByTypeArea": "Répartition de la surface par typologie d'immeuble",
  "graphPortfolioBreakdownByTypeBuildingsNumber": "Répartition des immeubles par typologie d'immeuble",
  "graphPortfolioBreakdownByTypeLeasesNumber": "Répartition du nombre de baux par typologie d'immeuble",
  "graphPortfolioBreakdownByTypeMarketValue": "Répartition de la valeur vénale par typologie d'immeuble",
  "graphPortfolioBreakdownByTypeRent": "Répartition du loyer facial brut par typologie d'immeuble",
  "graphPortfolioBreakdownByTypeUnitsNumber": "Répartition du nombre lots par typologie d'immeuble",
  "graphPortfolioEvolutionArea": "Evolution du patrimoine en surface",
  "graphPortfolioEvolutionBuildNatArea": "Evolution du patrimoine en surface par type d'immeuble",
  "graphPortfolioEvolutionBuildNatGrossHeadlineRent": "Evolution du patrimoine en loyer facial brut par type d'immeuble",
  "graphPortfolioEvolutionBuildNatNetEffectiveRent": "Evolution du patrimoine en loyer effectif net par type d'immeuble",
  "graphPortfolioEvolutionBuildNatValue": "Evolution du patrimoine en valeur vénale nette par type d'immeuble",
  "graphPortfolioEvolutionBuildNumber": "Evolution du patrimoine en nombre d'immeubles",
  "graphPortfolioEvolutionBuildNumberNat": "Evolution du patrimoine en nombre d'immeubles par type d'immeuble",
  "graphPortfolioEvolutionTotalGrossHeadlineRent": "Evolution du patrimoine en loyer facial brut",
  "graphPortfolioEvolutionTotalNetEffectiveRent": "Evolution du patrimoine en loyer effectif net",
  "graphPortfolioEvolutionValue": "Evolution du patrimoine en valeur vénale nette",
  "graphPurchaseHistoryArea": "Historique des acquisitions en nombre d'immeubles et surface par type d'immeuble",
  "graphPurchaseHistoryValue": "Historique des acquisitions en nombre d'immeubles et valeur d'acquisition par type d'immeuble",
  "graphTenantTurnoverLeasesNumber": "Evolution des relocations et des baux sortis",
  "graphTenantTurnoverUnitsArea": "Evolution des surfaces relouées et des surfaces libérées",
  "graphTenantTurnoverUnitsNumber": "Evolution des lots reloués et des lots libérés",
  "graphVacantUnitsByUnitType": "Répartition des lots vacants par type de lot",
  "graphVacantUnitsByVacancyType": "Répartition des lots vacants par type de vacance",
  "GROSS BOOK VALUE": "Actif brut immobilisé (€)",
  "GROSS CAPITAL RATE": "Taux de capitalisation bruts",
  "GROSS DEBT": "Capital restant dû (€)",
  "GROSS DEBT UNDERLYING VEHICLES": "Capital restant dû (fille(s)) (€)",
  "GROSS EFFECT RENT RECEIVABLE": "Loyer effectif quittancé (€/an)",
  "GROSS EFFECTIVE RENT": "Loyer effectif brut (€/an)",
  "GROSS EFFECTIVE RENT WOU": "Loyer effectif brut",
  "GROSS HEADLINE RENT": "Loyer facial brut (€/an)",
  "GROSS HEADLINE RENT BY NET MARKET VALUE": "Taux de capitalisation initial facial brut",
  "GROSS HEADLINE RENT M2": "Loyer facial brut par m² (€/m²/an)",
  "GROSS HEADLINE RENT PERCENT": "% du loyer facial brut total",
  "GROSS HEADLINE RENT WOU": "Loyer facial brut",
  "GROSS INITIAL YIELD": "Taux de rendement bruts",
  "GROSS INITIAL YIELDS": "Taux de rendement bruts",
  "GROSS LEASING AREA": "Surface commerciale locative (m²)",
  "GROSS LEASING AREA WOU": "Surface commerciale locative",
  "GROSS MARKET VALUE": "Valeur vénale brute (€)",
  "GROSS POTENTIAL RENT": "Loyer potentiel brut (€/an)",
  "GROSS POTENTIAL RENTAL INCOME": "Revenus locatifs potentiels bruts",
  "GROSS RECEIPT RENT": "Loyer encaissé (€)",
  "GROSS RECEIPT RENT WOU": "Loyer encaissé",
  "GROSS RECEIPTED RENT": "Loyer quittancé à date (€)",
  "GROSS RECEIPTED RENT BY GROSS BOOK VALUE": "Rendement brut sur ABI",
  "GROSS RECEIPTED RENT BY NET MARKET VALUE": "Rendement locatif",
  "GROSS RECEIPTED RENT FUND": "Loyer comptabilisé à date (€)",
  "GROSS RENTAL INCOME": "Revenus locatifs bruts",
  "GROSS RENTAL INCOME FUND": "Loyers (€)",
  "GROSS RENTAL INCOME OWNED": "Loyers (€)",
  "GROSS REVERS CAPITAL RATE": "Taux de capitalisation théorique brut",
  "GROSS REVERS POTENT OCCUP AREA": "Sur la surface occupée",
  "GROSS REVERS POTENT OCCUP AREA FULL": "Potentiel de réversion sur la surface occupée",
  "GROSS REVERS POTENT TOTAL PROP": "Sur la surface totale",
  "GROSS REVERS POTENT TOTAL PROP FULL": "Potentiel de réversion sur la surface totale",
  "GROSS REVERSIONARY POTENTIAL": "Potentiel de réversion",
  "GROSS REVERSIONARY YIELD": "Taux théorique brut",
  "GROSS_BOOK_VALUE": "Actif brut immobilisé (€)",
  "GROSS_EFFECT_RENT_RECEIVABLE": "Loyer effectif quittancé YYYY-1 (€/an)",
  "GROSS_EFFECTIVE_RENT": "Loyer effectif brut (€/an)",
  "GROSS_HEADLINE_RENT": "Loyer facial brut (€/an)",
  "GROSS_HEADLINE_RENT_M2": "Loyer facial brut par m² (€/m²/an)",
  "GROSS_HEADLINE_RENT_PERCENT": "% du loyer facial brut total",
  "GROSS_LEASABLE_AREA_SQM": "Surface commerciale locative",
  "GROSS_MARKET_VALUE": "Valeur vénale brute (€)",
  "GROSS_MARKET_VALUE_Dashboard": "Valeur vénale brute",
  "GROSS_POTENTIAL_RENT": "Loyer potentiel brut (€/an)",
  "GROSS_POTENTIAL_RENT_WOU": "Loyer potentiel brut",
  "GROSS_RECEIPTED_RENT": "Loyer quittancé à date (€)",
  "GROSS_REVERS_CAPITAL_RATE": "Taux de capitalisation théorique brut",
  "GROSS_REVERS_POTENT_OCCUP_AREA": "Potentiel de réversion sur la surface occupée",
  "GROSS_REVERS_POTENT_TOTAL_PROP": "Potentiel de réversion sur la surface totale",
  "GROSS_REVERSIONARY_YIELD": "Taux théorique brut",
  "GUARANTEE BANK 1": "Banque de la caution 1",
  "GUARANTEE BANK 2": "Banque de la caution 2",
  "GUARANTEE BANK 3": "Banque de la caution 3",
  "GUARANTEE END DATE 1": "Date de fin de la caution 1",
  "GUARANTEE END DATE 2": "Date de fin de la caution 2",
  "GUARANTEE END DATE 3": "Date de fin de la caution 3",
  "GUARANTEE START DATE 1": "Date de début de la caution 1",
  "GUARANTEE START DATE 2": "Date de début de la caution 2",
  "GUARANTEE START DATE 3": "Date de début de la caution 3",
  "GUARANTEE TYPE": "Type de caution",
  "GUARANTEE TYPE 1": "Type de la caution 1",
  "GUARANTEE TYPE 2": "Type de la caution 2",
  "GUARANTEE TYPE 3": "Type de la caution 3",
  "GUARANTEE_BANK_1": "Banque de la caution 1",
  "GUARANTEE_BANK_2": "Banque de la caution 2",
  "GUARANTEE_BANK_3": "Banque de la caution 3",
  "GUARANTEE_END_DATE_1": "Date de fin de la caution 1",
  "GUARANTEE_END_DATE_2": "Date de fin de la caution 2",
  "GUARANTEE_END_DATE_3": "Date de fin de la caution 3",
  "GUARANTEE_START_DATE_1": "Date de début de la caution 1",
  "GUARANTEE_START_DATE_2": "Date de début de la caution 2",
  "GUARANTEE_START_DATE_3": "Date de début de la caution 3",
  "GUARANTEE_TYPE": "Type de caution",
  "GUARANTEE_TYPE_1": "Type de la caution 1",
  "GUARANTEE_TYPE_2": "Type de la caution 2",
  "GUARANTEE_TYPE_3": "Type de la caution 3",
  "HD1": "Entête 1",
  "HD2": "Entête 2",
  "HD3": "Entête 3",
  "HEA": "Entête de page",
  "HEAD OFFICE": "Siège social",
  "HeaderLine": "Présence de la ligne d’en-tête",
  "HEADLINE GROSS CAPITAL RATE": "Taux de capitalisation facial brut",
  "HEADLINE GROSS INITIAL YIELD": "Taux de rendement facial brut",
  "HEADLINE NET CAPITAL RATE": "Taux de capitalisation facial net",
  "HEADLINE NET INITIAL YIELD": "Taux de rendement facial net",
  "HEADLINE_GROSS_CAPITAL_RATE": "Taux de capitalisation facial brut",
  "HEADLINE_GROSS_INITIAL_YIELD": "Taux de rendement facial brut",
  "HEADLINE_NET_CAPITAL_RATE": "Taux de capitalisation facial net",
  "HEADLINE_NET_INITIAL_YIELD": "Taux de rendement facial net",
  "HELD SHARES NUMBER": "Nombre de parts détenues",
  "HELD_SHARES_NUMBER": "Nombre de parts détenues",
  "HELD_SHARES_NUMBER_mismatch": "Nombre de parts détenues format incorrect",
  "hlAlertsPage": "Page d'alertes",
  "hlLoginLink": "Connexion",
  "hlMyAssets": "Maximiser",
  "HOLDING COMPANY": "Groupe de rattachement",
  "HOLDING_COMPANY": "Groupe de rattachement",
  "HOLDOVER LEASE": "Nombre de baux",
  "HOLDOVER LEASE NUMBER": "Nombre de baux renouvelés",
  "HOLDOVER LEASE SUMMARY": "Résumé du bail non renouvelé",
  "HOLDOVER RENT": "Loyer facial (€/an)",
  "HOLDOVER RENT M2": "Loyer facial par m² (€/m²/an)",
  "HOLDOVER TENANCY": "Suivi des baux non renouvelés au",
  "HOLDOVER TENANCY BREAKDOWN BY LEASE NATURE": "Répartition des baux non renouvelés par nature de bail",
  "HOLDOVER TENANCY SHEET": "Suivi des baux non renouvelés",
  "HOLDOVER TENANCY SUMMARY": "Baux non renouvelés",
  "HOUSING_COMPLEX": "Groupe d'immeubles",
  "httpErrorMsg": "Une erreur HTTP a eu lieu. Page introuvable. Réessayez SVP.",
  "ID_ARBITRAGE_TYPE": "Identifiant du type d'arbitrage",
  "ID_BUILDING_NATURE": "Identifiant du type d'immeuble",
  "ID_CURRENT_INDEX_TYPE": "Code type de l'indice actuel",
  "ID_DOCUMENT_TYPE": "Code type de document",
  "ID_INDEX_DIRECTION": "Code sens de l'indexation",
  "ID_INDEX_FLEXIBILITY": "Code flexibilité de l'indice",
  "ID_INDEX_RHYTHM_UNIT_OF_ADJUSTMENT": "Code unité d'ajustement de la périodicité d'indexation",
  "ID_INDEXATION_THRESHOLD_TYPE": "Code type de seuil d'indexation",
  "ID_INITIAL_INDEX_TYPE": "Code type de l'indice initial",
  "ID_INVESTOR": "Identifiant de l'investisseur",
  "ID_LEGAL_VEHICLE_STRUCTURE": "Identifiant de la forme juridique",
  "ID_NEXT_INDEX_TYPE": "Code type du prochain indice",
  "ID_PURCH_WORK_PROGRES_STATE": "Etat d'avancement des travaux",
  "ID_PURCHASE_GOAL": "Objectif de l'acquisition",
  "ID_PURCHASE_PROGRES_STATE": "Etat d'avancement de l'acquisition",
  "ID_PURCHASE_TYPE": "Type d'acquisition",
  "ID_REINVOICING_METHOD": "Code méthode de refacturation",
  "ID_REPOSITORY_ARBITRAGE": "Code de la cession",
  "ID_REPOSITORY_FILE_IMPORT": "Identifiant du fichier d'import",
  "ID_REPOSITORY_PURCHASE": "Code de l'acquisition",
  "ID_SALE_PROGRES_STATE": "Etat d'avancement de la cession",
  "ID_SALE_PROGRESSION_STATE": "Identifiant d'état d'avancement de la cession",
  "ID_SEQUENCE": "N° Séquence",
  "IDENTIFICATION": "Résumé de l'immeuble",
  "IMPACT_CRITERES_ESG": "Impact critères ESG",
  "IMPAIR LOSS CALC": "PDD immeuble calculée (€)",
  "IMPAIR_LOSS_CALC": "PDD immeuble calculée (€)",
  "IMPAIRMENT CHARGE": "Valeur nette comptable après provisions (€)",
  "IMPAIRMENT LOSS": "PDD immeuble validée (€)",
  "IMPAIRMENT RATIO": "Taux de dépréciation",
  "IMPAIRMENT_CHARGE": "Valeur nette comptable après provisions (€)",
  "IMPAIRMENT_CHARGE_RECORDED_IN_PRIOR_PERIODS": "Provisions pour dépréciation comptabilisées",
  "IMPAIRMENT_LOSS": "PDD immeuble validée (€)",
  "IMPAIRMENT_RATIO": "Taux de dépréciation",
  "IMPORT_DATE": "Date d'import",
  "ImportParamTranscodingError": "Erreur lors de l'import du fichier param_transcoding",
  "ImportParamTranscodingErrorColumnIsEmpty": "Le champ colonne est vide",
  "ImportParamTranscodingErrorColumnNotExist": "La colonne n’existe pas",
  "ImportParamTranscodingErrorOnePortfolio": "Un seul portefeuille peut être importé par fichier",
  "ImportParamTranscodingErrorOnePropertyManager": "Un seul Property Manager peut être importé par fichier",
  "ImportParamTranscodingErrorOneTranscodingType": "Un seul type de transco peut être importé par fichier",
  "ImportParamTranscodingErrorOneTranscodingTypeFilled": "Au moins un des libellés FR, EN ou DE doit être renseigné",
  "ImportParamTranscodingErrorPortfolioIsEmpty": "Le champ portfeuille est vide",
  "ImportParamTranscodingErrorTableIsEmpty": "Le champ table est vide",
  "ImportParamTranscodingErrorTableNotExist": "La table n’existe pas",
  "ImportParamTranscodingErrorTableOrColumnNotSame": "Les champs table et colonne doivent être identiques sur chaque ligne.",
  "ImportParamTranscodingPortfolioNotExist": "Le portfeuille n’existe pas",
  "IMPUTATION": "Imputation",
  "IN_ADVANCE": "A échoir",
  "IN_ARREARS": "Echu",
  "INACTIVE_AREA": "Surface inactive",
  "INACTIVE_AS_OF_THE_END_DATE": "Inactifs à la date de fin",
  "INACTIVE_AS_OF_THE_REPORT_DATE": "Inactifs à la date de situation",
  "INACTIVE_AS_OF_THE_START_DATE": "Inactifs à la date de début",
  "INCENTIVE RATE": "Taux d'accompagnement",
  "INCENTIVE_RATE": "Taux d'accompagnement",
  "INCEPTION DATE": "Date de création",
  "INCEPTION_DATE": "Date de création",
  "IncludBuildingInTS": "Immeubles à inclure <br>Etat Locatif",
  "INCLUDING TAX AMOUNT": "Montant TTC de la facture",
  "INCLUDING TAX AMOUNT 1": "Montant TTC",
  "INCLUDING TAX AMOUNT TOTAL": "Montant toutes taxes comprises",
  "INCLUDING TAX AMOUNT TOTAL 1": "Montant TTC",
  "INCLUDING_TAX_AMOUNT": "Montant TTC de la facture",
  "INCLUDING_TAX_AMOUNT_1": "Montant TTC",
  "INCLUDING_TAX_AMOUNT_TOTAL": "Montant toutes taxes comprises",
  "INCLUDING_TAX_AMOUNT_TOTAL_1": "Montant TTC",
  "INCOME INCREASE ON RENTED AREA": "Sur la surface occupée",
  "INCOME INCREASE PROPERTY WIDE": "Sur l'ensemble de l'immeuble",
  "INCOME RETURN": "Rendement locatif",
  "INCOME RETURN FUND": "Rendement courant",
  "INCOME TAX": "Impôts sur les sociétés (€)",
  "INCOME_RETURN": "Rendement locatif",
  "INCOME_TAX": "Impôts sur les sociétés (€)",
  "INDEX FLOOR": "Plancher d'indexation",
  "INDEX_DELAY_OF_ADJUSTMENT": "Délai d'ajustement de l'indice",
  "INDEX_FLOOR": "Plancher d'indexation",
  "INDEX_RHYTHM": "Périodicité d'indexation",
  "INDEXATION CAP": "Plafond d'indexation",
  "INDEXATION_CAP": "Plafond d'indexation",
  "INDEXATION_THRESHOLD": "Seuil d'indexation",
  "INDUSTRIAL AREA": "Surface à usage de locaux industriels (m²)",
  "INDUSTRIAL_AREA": "Surface à usage de locaux industriels (m²)",
  "INIT_ANNUAL_AMOUNT": "Montant annuel initial",
  "INIT_ANNUAL_AMOUNT_PER_UNIT": "Montant annuel initial par lot",
  "INIT_BALANCE": "0000-Solde Initial",
  "INITIAL ANNUAL RENT AMOUNT": "Loyer facial initial (€/an)",
  "INITIAL ANNUAL RENT AMOUNT M2": "Loyer facial initial par m² (€/m²/an)",
  "INITIAL DEPOSIT VALUE": "Dépôt de garantie initial (€)",
  "INITIAL EQUIPMENT GUARANTEE": "Caution Equipement initial (€)",
  "INITIAL GUARANTEE 1": "Caution initiale 1 (€)",
  "INITIAL GUARANTEE 2": "Caution initiale 2 (€)",
  "INITIAL GUARANTEE 3": "Caution initiale 3 (€)",
  "INITIAL INDEX": "Type d'indice initial",
  "INITIAL INDEX DATE": "Date de l'indice initial",
  "INITIAL INDEX VALUE": "Indice initial",
  "INITIAL VALUE": "Valeur initiale",
  "INITIAL WORKING CAPITAL": "Fond roulement initial (€)",
  "INITIAL_ANNUAL_RENT_AMOUNT": "Loyer facial initial (€/an)",
  "INITIAL_ANNUAL_RENT_AMOUNT_M2": "Loyer facial initial par m² (€/m²/an)",
  "INITIAL_ANNUAL_RENT_AMOUNT_SQM": "Loyer facial initial par m² (€/m²/an)",
  "INITIAL_DEPOSIT_VALUE": "Dépôt de garantie initial (€)",
  "INITIAL_EQUIPMENT_GUARANTEE": "Caution Equipement initial (€)",
  "INITIAL_GUARANTEE_1": "Caution initiale 1 (€)",
  "INITIAL_GUARANTEE_2": "Caution initiale 2 (€)",
  "INITIAL_GUARANTEE_3": "Caution initiale 3 (€)",
  "INITIAL_INDEX": "Type d'indice initial",
  "INITIAL_INDEX_DATE": "Date de l'indice initial",
  "INITIAL_INDEX_PERIODICITY": "Fréquence initiale d'indexation",
  "INITIAL_INDEX_VALUE": "Indice initial",
  "INITIAL_VALUE": "Valeur initiale",
  "INITIAL_WORKING_CAPITAL": "Fond roulement initial (€)",
  "InPlaceTenants": "Locataires présents",
  "InsertFilterLineNumber": "Filtrer la ligne n°",
  "INSURANCE BEGIN DATE": "Date de début de l'assurance",
  "INSURANCE DUNNING DATE": "Date de relance de l'assurance",
  "INSURANCE DUNNING LEVEL": "Niveau de relance de l'assurance",
  "INSURANCE END DATE": "Date de fin de l'assurance",
  "INSURANCE PERIOD": "Période de l'assurance",
  "INSURANCE POLICY NUMBER": "N° de police d'assurance",
  "INSURANCE PREMIMS AMOUNT": "Montant des primes d'assurance (€)",
  "INSURANCE_BEGIN_DATE": "Date de début de l'assurance",
  "INSURANCE_DUNNING_DATE": "Date de relance de l'assurance",
  "INSURANCE_DUNNING_LEVEL": "Niveau de relance de l'assurance",
  "INSURANCE_END_DATE": "Date de fin de l'assurance",
  "INSURANCE_PERIOD": "Durée de l'assurance (mois)",
  "INSURANCE_POLICY_NUMBER": "N° de police d'assurance",
  "INSURANCE_PREMIMS_AMOUNT": "Montant des primes d'assurance (€)",
  "INTEREST LETTER SENDING DATE": "Date d'envoi de la lettre d'intérêt",
  "INTEREST PAID ON CURRENT ACCOUNT": "Intérêts de compte courant (€)",
  "INTEREST_LETTER_SENDING_DATE": "Date d'envoi de la lettre d'intérêt",
  "INTEREST_LETTER_SENDING_DATE_mismatch": "Date d'envoi de la lettre d'intérêt format incorrect",
  "INVESTMENT STRUCTURE": "Pyramide de détention",
  "INVESTOR": "Nom du détenteur",
  "INVESTOR CODE": "Code détenteur",
  "INVESTOR CONTRIBUTIONS": "Fonds propres investis",
  "INVESTOR DISTRIBUTION": "Revenus",
  "INVESTOR GROUP": "Groupe",
  "INVESTOR LEVEL NAV": "Valorisation des titres (€)",
  "INVESTOR LEVEL NAV TITLE": "Valorisation des titres",
  "INVESTOR NAME": "Nom du détenteur",
  "INVESTOR NAV AND RETURNS": "Valorisation des titres",
  "INVESTOR RETURN": "Rendements sur fonds propres",
  "INVESTOR SHARE OF NAV": "Valeur liquidative des titres (€)",
  "INVESTOR_CODE": "Code du détenteur",
  "INVESTOR_GROUP": "Groupe",
  "INVESTOR_NAME": "Nom du détenteur",
  "INVESTOR_SHARE_OF_NAV": "Valeur liquidative des parts détenues (€)",
  "INVESTORS": "Détenteurs",
  "INVOICE": "Libellé",
  "INVOICE DURING PERIOD INC TAX AMNT": "Quittancement de la période (€)",
  "INVOICE NUMBER": "Numéro de la facture",
  "INVOICE_DURING_PERIOD_INC_TAX_AMNT": "Quittancement de la période (€)",
  "INVOICE_NUMBER": "Numéro de la facture",
  "INVOICES NOT DUE INC TAX AMOUNT": "Factures non encore exigibles (€)",
  "INVOICES_NOT_DUE_INC_TAX_AMOUNT": "Factures non encore exigibles (€)",
  "INVOICING": "Quittancement de la période (€)",
  "INVOICING BANK ACCOUNT": "Compte bancaire Facturation",
  "INVOICING DATE": "Date de facture",
  "INVOICING FREQUENCY LABEL": "Périodicité de facturation",
  "INVOICING INCL TAX AMOUNT": "Montant quittancé (€)",
  "INVOICING ITEM": "Rubrique de facturation",
  "INVOICING_BANK_ACCOUNT": "Compte bancaire Facturation",
  "INVOICING_DATE": "Date de facture",
  "INVOICING_FREQUENCY_LABEL": "Périodicité de facturation",
  "INVOICING_INCL_TAX_AMOUNT": "Montant quittancé (€)",
  "INVOICING_PERIOD": "Quittancement de la période (€)",
  "IS BUILDING NEUTRALIZED": "Neutralisé ?",
  "IS DIRECT DEBIT": "Règlement par prélèvement?",
  "IS GREEN LEASE": "Annexe environnementale (oui/non) ?",
  "IS HOLDOVER LEASE": "Bail en tacite reconduction ?",
  "IS IN LITIGATION": "Contentieux en cours ?",
  "IS LEASE RENEWED": "Bail renouvelé ?",
  "IS LOOKTHROUGH ENTITY": "Société fiscalement transparente (oui/non) ?",
  "IS MAIN UNIT": "Lot principal (oui/non) ?",
  "IS OPERATING EXPENSES REBILLED": "Facturation des prestations conventionnelles (oui/non) ?",
  "IS RENT CATCH UP": "Rattrapage de loyer (oui/non) ?",
  "IS SERVICES CHARGES CAPPED": "Forfait de charges (oui/non) ?",
  "IS_BUILDING_NEUTRALIZED": "Neutralisé ?",
  "IS_DIRECT_DEBIT": "Règlement par prélèvement ?",
  "IS_GREEN_LEASE": "Annexe environnementale (oui/non) ?",
  "IS_HOLDOVER_LEASE": "Bail en tacite reconduction ?",
  "IS_IN_LITIGATION": "Contentieux en cours ?",
  "IS_LEASE_RENEWED": "Bail renouvelé ?",
  "IS_LOOK_THROUGH_ENTITY": "Société fiscalement transparente (1=oui/0=non) ?",
  "IS_LOOKTHROUGH_ENTITY": "Société fiscalement transparente ?",
  "IS_MAIN_UNIT": "Lot principal (oui/non) ?",
  "IS_OPERATING_EXPENSES_REBILLED": "Facturation des prestations conventionnelles (oui/non) ?",
  "IS_OWNER": "Société propriétaire (1=oui/0=non) ?",
  "IS_REGULAR_CHARGE_REVIEWABLE": "Rubrique de facturation révisable ?",
  "IS_RENT": "Est un loyer ?",
  "IS_RENT_CATCH_UP": "Rattrapage de loyer (oui/non) ?",
  "IS_RENTAL_FREE": "Exonéré de loyer ?",
  "IS_SERVICES_CHARGES_CAPPED": "Forfait de charges (oui/non) ?",
  "ISIN": "Code ISIN",
  "isSaveDirectory": "Sauvegarder sur un répertoire local",
  "isSaveGED": "Sauvegarder en GED",
  "isSaveMultiple": "Un fichier par immeuble",
  "isSaveOne": "Un seul fichier",
  "IsSeparatorThousands": "Séparateur de milliers (Oui/Non)",
  "ISSUED SHARES NUMBER": "Nombre total de parts",
  "ISSUED_SHARES_NUMBER": "Nombre total de parts",
  "ITEM_END_DATE": "Date de fin de la rubrique de facturation",
  "ITEM_START_DATE": "Date de début de la rubrique de facturation",
  "JANUARY_AMOUNT": "Janvier (€)",
  "JOURNAL CODE": "Journal",
  "JOURNAL_CODE": "Journal",
  "JOURNAL_LABEL": "Libellé du journal",
  "JULY_AMOUNT": "Juillet (€)",
  "JUNE_AMOUNT": "Juin (€)",
  "KEY FIGURES": "Chiffres clés",
  "LAND PRICE": "Prix du terrain (€)",
  "LAND_PRICE": "Prix du terrain (€)",
  "LAND_PRICE_mismatch": "Prix du terrain format incorrect",
  "LAND_PRICE_WOU": "Prix du terrain",
  "LAND_VALUE": "Montant du terrain",
  "LANDED N": "Atterrissage",
  "LANDED N M1": "Atterrissage",
  "LANDED_N": "Atterrissage YYYY",
  "LANDED_N_M1": "Atterrissage YYYY-1",
  "LANDLORD NOTICE DATE": "Date de congé propriétaire",
  "LANDLORD_NOTICE_DATE": "Date de congé propriétaire",
  "LAST ANNUAL RENT AMOUNT": "Dernier loyers annuels (€/an)",
  "LAST EDITION": "Dernière édition :",
  "LAST INDEXATION DATE": "Date de la dernière indexation",
  "LAST LEASE RENT AMOUNT": "Loyer facial du dernier bail (€/an)",
  "LAST LEASE RENT AMOUNT M2": "Loyer facial par m² du dernier bail (€/m²/an)",
  "LAST RENT REVIEW DATE": "Dernière date de révision",
  "LAST TENANT NAME": "Nom du dernier locataire",
  "LAST TENANT TRADE NAME": "Enseigne du dernier locataire",
  "LAST VALUATION DATE": "Date de valeur",
  "LAST_INDEXATION_DATE": "Date de la dernière indexation",
  "LAST_LEASE_RENT_AMOUNT": "Loyer facial du dernier bail (€/an)",
  "LAST_LEASE_RENT_AMOUNT_M2": "Loyer facial par m² du dernier bail (€/m²/an)",
  "LAST_RENT_REVIEW_DATE": "Dernière date de révision",
  "LAST_TENANT_NAME": "Nom du locataire précédent",
  "LAST_TENANT_TRADE_NAME": "Enseigne du locataire précédent",
  "LAST_VALUATION_DATE": "Date de valeur",
  "LAST_VALUATION_DATE_YYYY": "Date de valeur YYYY-1",
  "LATITUDE": "Latitude",
  "lbAlertAndPortfolio": "Mes alertes & Mon portefeuille",
  "lbAlphanumeric": "Alphanumérique",
  "lbAmerican": "Américain",
  "lbAmoutFormat": "Format des montants dans le fichier",
  "lbAPRAFinancialOccupancyEvolution": "Evolution de l'état d'occupation financier EPRA",
  "lbAttribute": "Attribut",
  "lbCapitalDecrease": "Diminution de valeur",
  "lbCapitalGrowth": "Augmentation de valeur",
  "lbCapitalReturnTotalReturnEvolution": "Evolution du rendement courant, en capital et global sur actif net réévalué",
  "lbCodeFormat": "Format des codes",
  "lbCollectionPeriods": "Périodes de ventilation",
  "lbCollectionPeriodsSBigError": "Veuillez saisir une valeur inférieure à la suivante",
  "lbCollectionPeriodsSmallError": "Veuillez saisir une valeur supérieure à la précédente",
  "lbColumnFormat": "Format des colonnes",
  "lbCulture": "Culture",
  "lbDate": "Date",
  "lbDateFormat": "Format des dates dans le fichier",
  "lbDefault": "Par defaut",
  "lbDispalyThePropertiesThatAre": "Sélectionner les immeubles…",
  "lbElement": "Element",
  "lbEnglish": "Anglais",
  "lbERV": "A la VLM",
  "lbExcludFollowJournal": "Omission familles de journaux",
  "lbFieldToBeLeft": "Champ vide",
  "lbFileNameFomatDate": "Nom du fichier - Format dates et heures",
  "lbFileSeparator": "Séparateur des champs",
  "lbFinancialOccupancyEvolution": "Evolution de l'état d'occupation financier ASPIM",
  "lbFixedValue": "Valeur fixe",
  "lbFrench": "Français",
  "lbGerman": "Allemand",
  "lbHeaderCollectionPerdios": "Le paramétrage réalisé dans cette page s'applique uniquement aux périodes de ventilation des rapports \"Suivi des encaissements\":  \"Situation locative détaillée\" et \"Balance âgée",
  "lbHeadlineRentBreakByTenant": "Répartition du loyer facial par locataire",
  "lbInterfaceDuplicated": "Votre interface [...] [....] a bien été dupliquée en [.....]",
  "lbInvalidatefixedValue": "La valeur fixe n'est pas valide",
  "lbInvalidateInterfaceName": "Le nom de l'interface n'est pas valide",
  "lbInvalidateNameADBFile": "Le nom de l'ADB n'est pas valide",
  "lbInvalidateNameFile": "Le nom du fichier n'est pas valide",
  "lbl10LinesByPage": "10 Lignes / Page",
  "lbl20LinesByPage": "20 Lignes / Page",
  "lbl48Law": "Loi de 1948",
  "lbl48LawEuro": "Loi de 1948 (€)",
  "lbl50LinesByPage": "50 Lignes / Page",
  "lbl5LinesByPage": "5 Lignes / Page",
  "lblABEPage": "Mouvements bancaires",
  "lblAccommodation": "Hébergement",
  "lblAccountsType": "Type de comptes",
  "lblACSAccountStream": "Interface Comptable",
  "lblActivation": "Activée",
  "lblActivationFalse": "L'Interface est désactivée",
  "lblAddAdditionalField": "Ajouter une colonne de données libre",
  "lblAddCustomColumn": "Ajouter une colonne personnalisée",
  "lblAddCustomMappingColumn": "Ajouter une colonne de rapport",
  "lblAdditionalField": "Thèmes",
  "lblAdditionalFieldCode": "Code du thème",
  "lblAdditionalFieldFormatError": "Une des valeurs n'est pas un nombre",
  "lblAdditionalFieldImport": "Gestion des données libres",
  "lblAdditionalFieldImportsList": "Historique des imports",
  "lblAdditionalFieldManagement": "Creation d'un thème",
  "lblAdditionalFieldName": "Nom du thème",
  "lblAdditionalFieldType": "Niveau",
  "lblAdditionalFieldTypes": "Niveaux",
  "lblAddress": "Adresse",
  "lblADFPage": "Donnée additionelle",
  "lblAdministrativeLease": "Bail administratif",
  "lblAdministrativeLeaseEuro": "Bail administratif (€)",
  "lblALEPage": "Gestion des alertes",
  "lblAlertCancelInterfaceSequence": "Etes-vous sûr de vouloir supprimer cette séquence ?",
  "lblAlertDeleteAdditionalField": "Etes-vous sûr de vouloir supprimer ce thème ?",
  "lblAlertDeleteArbitrage": "Attention ! Vous allez supprimer la cession d'un immeuble. Cet immeuble sera retiré de la liste des cessions en cours. Il pourra être visualisé et mis à jour à partir de l'onglet Archives des cessions.",
  "lblAlertDeleteFunds": "Etes-vous sûr de vouloir supprimer cette société ?",
  "lblAlertDeleteInterface": "Etes-vous sûr de vouloir supprimer cette interface ?",
  "lblAlertDeletePreviousFundUpdate": "Etes-vous sûr de vouloir supprimer la mise à jour de cette société ?",
  "lblAlertDeletePurchase": "Attention ! Vous allez supprimer l'acquisition d'un immeuble.Cet immeuble sera retiré de la liste des acquisitions en cours. Il pourra être visualisé et mis à jour à partir de l'onglet Archives des acquisitions.",
  "lblAlertDeleteRecord": "Etes-vous sûr de vouloir supprimer cet enregistrement ?",
  "lblAlertDeleteReportTemplate": "Etes-vous sûr de vouloir supprimer ce modèle de rapport ?",
  "lblAlertDeleteUser": "Etes-vous sûr de vouloir supprimer cet utilisateur ?",
  "lblAlertEvolutionyear1": "Afficher l'évolution des valeurs clés sur",
  "lblAlertEvolutionyear2": "Afficher l'évolution des valeurs clés sur",
  "lblAlertEvolutionyear3": "Afficher l'évolution des valeurs clés sur",
  "lblAlertLaunchInterface": "Souhaitez-vous lancer l’interface immédiatement ?",
  "lblAlertLeaseBreakRetail": "Un bail commercial arrive à échéance (option de sortie) dans moins de (mois) :",
  "lblAlertLeaseExpiringOther": "Un bail \"autre\" expire dans moins de (mois) :",
  "lblAlertLeaseExpiringResidential": "Un bail résidentiel expire dans moins de (mois) :",
  "lblAlertLeaseExpiringRetail": "Un bail commercial expire dans moins de (mois) :",
  "lblAlertPropertyValueDecreased": "La valeur vénale nette d'un immeuble a diminué de (%) lors de la dernière évaluation :",
  "lblAlertPropertyValueIncreased": "La valeur vénale nette d'un immeuble a augmenté de (%) lors de la dernière évaluation :",
  "lblAlertRangeError": "Erreur de valeur (doit être compris entre 1 et 999)",
  "lblAlertRangeError_0_999999999": "Erreur de valeur (doit être compris entre 0 et 999999999)",
  "lblAlertReload": "Etes-vous sûr de vouloir recharger les données ?",
  "lblAlertRentDecreasedAfterAdjustment": "Le loyer d'un bail après révision a diminué de (%) :",
  "lblAlertRentDecreasedInitialRent": "Le loyer d'un bail a diminué de (%) par rapport au loyer initial :",
  "lblAlertRentIncreasedAfterAdjustment": "Le loyer d'un bail après révision a augmenté de (%) :",
  "lblAlertRentIncreasedInitialRent": "Le loyer d'un bail a augmenté de (%) par rapport au loyer initial :",
  "lblAlertRequiredError": "Champ obligatoire",
  "lblAlertsManagement": "Gestion des alertes",
  "lblAlertSoldArbitrage": "Attention ! Vous allez changer le statut d'un immeuble en Cession réalisée. Cet immeuble sera retiré de la liste des cessions en cours. Il pourra être visualisé et mis à jour à partir de l'onglet Archives des cessions.",
  "lblAlertTenancySchedule": "Seuils d'alerte sur l'Etat locatif",
  "lblAlertTerm1": "Recevoir une alerte lorsqu'un bail arrive à échéance dans les",
  "lblAlertTerm2": "Recevoir une alerte lorsqu'un bail arrive à échéance dans les",
  "lblAlertTreshold": "Seuil de l'alerte",
  "lblAlertVacancy": "Recevoir une alerte lorsque le taux de vacance d'un immeuble est supérieur ou égal à",
  "lblAlertVacancyMixed": "Locaux d'activités est supérieur ou égal à (%) :",
  "lblAlertVacancyOffice": "Bureaux est supérieur ou égal à (%) :",
  "lblAlertVacancyOther": "Autres classes d'actifs est supérieur ou égal à (%) :",
  "lblAlertVacancyRate": "Taux de vacance",
  "lblAlertVacancyResidential": "Résidentiel est supérieur ou égal à (%) :",
  "lblAlertVacancyRetail": "Commerce est supérieur ou égal à (%) :",
  "lblAlertVacancyStorage": "Logistique est supérieur ou égal à (%) :",
  "lblAlertVacantUnit": "Un lot est vacant depuis plus de (mois) :",
  "lblAlertVacantUnitOptional": "Si la surface du lot est supérieure ou égale à (m²) :",
  "lblAlertValidateArbitrage": "Attention ! Vous allez valider la cession d'un immeuble. Cet immeuble sera retiré de la liste des cessions en cours. Il pourra être visualisé et mis à jour à partir de l'onglet Archives des cessions.",
  "lblAlertValidatePurchase": "Attention ! Vous allez valider l'acquisition d'un immeuble. Cet immeuble sera retiré de la liste des acquisitions en cours. Il pourra être visualisé et mis à jour à partir de l'onglet Archives des acquisitions.",
  "lblAnalysisDate": "Date de situation",
  "lblAnnualFinancialVacancy": "Vacance financière (€/an)",
  "lblAnnualFinancialVacancyCumulate": "Vacance financière cumulée (€)",
  "lblAnnualRent": "Loyer facial (€/an)",
  "lblAnnualRentSqm": "Loyer facial par m² (€/m²/an)",
  "lblApplyAllBuildings": "Appliquer à tous les immeubles",
  "lblApplySelectBuildingOnly": "Appliquer uniquement aux immeubles et portefeuille(s) sélectionnés",
  "lblArbitrage": "Gestion des cessions",
  "lblArbitrageAlreadyExists": "Cet immeuble existe déjà dans les cessions",
  "lblArbitrageHistory": "Historique des cessions",
  "lblArbitrageType": "Type d'arbitrage",
  "lblArea": "Surface totale (m²)",
  "lblAreaDashboard": "Surface totale",
  "lblAreaLeaseRenewals": "Surface des baux renouvelés (m²)",
  "lblAreaLeasesTerminated": "Surface des baux sortis (m²)",
  "lblAreaLeaseTerminationsNotified": "Surface des baux en congés (m²)",
  "lblAreaRelettings": "Surface relouée (m²)",
  "lblAreaScale": "Surface",
  "lblAreasUnderManagementByBuildingType": "Superficies en gestion par type d'immeuble",
  "lblAreasUnderManagementByUnitType": "Superficies en gestion par type de lot",
  "lblAreaUnit": "Unité de surface",
  "lblArrivalDate": "Date d'entrée du locataire",
  "lblAssetManager": "Asset Manager",
  "lblAuditAction": "Actions d'audit",
  "lblAuditTables": "Tables d'audit",
  "lblAuditTrail": "Piste d'audit",
  "lblAuthorizedPage": "Pages autorisées",
  "lblAuthorizedPortfolio": "Portefeuilles autorisés",
  "lblAUTPage": "Piste d'audit",
  "lblAUTRawReport": "Piste d'audit - brut",
  "lblAUTReport": "Piste d'audit",
  "lblAverageVacancyRate": "Taux de vacance physique moyen (%)",
  "lblBackDashoard": "Retour à l'écran d'accueil",
  "lblBalanceBeforeOverpayments": "Solde avant trop perçu (€)",
  "lblBalanceSheetRevisionDate": "Date de réception de la balance",
  "lblBaseReportRequired": "Veuillez sélectionner un modèle de rapport",
  "lblBetweenOneAndTwoMonth": "1-2 mois",
  "lblBetweenTwoAndThreeMonth": "2-3 mois",
  "lblBlokingLogin": "Votre compte est bloqué, veuillez contacter votre administrateur",
  "lblBranches": "Agences",
  "lblBreakdownByPropertyType": "Répartition sectorielle (en valeur d'expertise HD)",
  "lblBreakRentIncomeByCollectionPreiod": "Répartition des encaissements par délai de recouvrement",
  "lblBudgetMonitoring": "Fiche budgétaire",
  "lblBudgetStatus": "Statut du budget",
  "lblBudgetStatusAll": "Tous",
  "lblBudgetStatusClosed": "Fermé",
  "lblBudgetStatusOpen": "Ouvert",
  "lblBudgetType": "Type de budget",
  "lblBudgetTypeAll": "Opex et Capex",
  "lblBudgetTypeCapex": "Capex",
  "lblBudgetTypeList": "Type de Budget",
  "lblBudgetTypeListCapex": "Capex",
  "lblBudgetTypeListOpex": "Opex",
  "lblBudgetTypeListOpexCapex": "Opex et Capex",
  "lblBudgetTypeListOpexProduct": "Opex et Produit",
  "lblBudgetTypeListProduct": "Produit",
  "lblBudgetTypeOpex": "Opex",
  "lblBuilding": "Bâtiment",
  "lblBuildingBadElement": "L’immeuble {1} n’est pas concerné par le type 2} de valeur 3 {0} lignes rejetées.",
  "lblBuildingId": "Identifiant de l'immeuble",
  "lblBuildingLease": "Bail à construction",
  "lblBuildingLeaseEuro": "Bail à construction (€)",
  "lblBuildingName": "Nom de l'immeuble",
  "lblBuildingNatures": "Types d'immeuble",
  "lblBuildingPhysicalVacancyRate": "Le taux de vacance physique d'un immeuble à usage de…",
  "lblBuildingPlural": "{0} immeubles à usage de {1}",
  "lblBuildingPurchaseValue": "Valeur d'acquisition (€)",
  "lblBuildingReference": "Référentiel immeubles",
  "lblBuildings": "immeuble(s)",
  "lblBuildings2": "Immeubles",
  "lblBuildingSaleValue": "Valeur de cession (€)",
  "lblBuildingSaleValueSqm": "Valeur de cession par m² (€/m²)",
  "lblBuildingSaleValueTotal": "Valeur de cession totale (€)",
  "lblBuildingsFound": "Immeuble(s) trouvé(s)",
  "lblBuildingSingular": "{0} immeuble à usage de {1}",
  "lblBuildingsMatching": "correspond(ent) aux critères sélectionnés",
  "lblBuildingToInclude": "Immeubles à inclure",
  "lblBuildingTotalAreaSqm": "Surface totale (m²)",
  "lblBuildingTotalValue": "Valeur totale (€)",
  "lblBuildingTotalValueSqm": "Valeur d'acquisition par m² (€/m²)",
  "lblBuildingType": "Type d'immeuble",
  "lblBuildingUnknown": "Le code d’immeuble {1} est inconnu, {0} lignes rejetées.",
  "lblBuildingUpdatedOn": "Fiche immeuble au",
  "lblBuildingValue": "Valeur vénale nette (€)",
  "lblBuildingValueDashboard": "Valeur vénale nette",
  "lblBuildingValueDecreasedPlural": "valeurs vénales ont diminué",
  "lblBuildingValueDecreasedSingular": "valeur vénale a diminué",
  "lblBuildingValueIncreasedPlural": "valeurs vénales ont augmenté",
  "lblBuildingValueIncreasedSingular": "valeur vénale a augmenté",
  "lblBUIPage": "Export fiche immeuble",
  "lblBUIReport": "Fiche immeuble",
  "lblBUMPage": "Fiche budgétaire",
  "lblBUMRawReport": "Fiche budgétaire - brut",
  "lblBUMReport": "Fiche budgétaire",
  "lblBusinessMixedPremises": "Locaux d’activités et mixtes",
  "lblBusinessUnits": "Sites de gestion",
  "lblBuyer": "Acquéreur",
  "lblCacheInfosValidityCheck": "Delai de récupération expiré",
  "lblCapiGross": "Taux de capitalisation bruts",
  "lblCapiNet": "Taux de capitalisation nets",
  "lblCapitalEmployed": "Capitaux employés (€)",
  "lblCapitalGrowthBreakdown": "Décomposition de l'évolution de la valeur vénale nette par immeuble",
  "lblCapitalReturn": "Rendement latent sur titre",
  "lblCapitalReturnEquityFairValue": "Rendement en capital sur fonds propres réévalués",
  "lblCapitalReturnOnNAV": "Rendement en capital sur ANR",
  "lblCapitalStructure": "Structure du capital",
  "lblCarPark": "Parking",
  "lblCarParkEuro": "Parking (€)",
  "lblCarParkLease": "Bail parking",
  "lblCarParkLeaseEuro": "Bail parking (€)",
  "lblCashFlowBreakdown": "Décomposition du cash flow immobilier",
  "lblCashOnCash": "Cash-on-Cash",
  "lblCashOnCashPerShare": "Cash-on-Cash par part (€)",
  "lblCashOnCashReturn": "Rendement Cash-on-Cash",
  "lblCBPPage": "Gestion des périodes de ventilation",
  "lblChangeParams": "Modifier les critères",
  "lblChangePassword": "Modifier le mot de passe",
  "lblChangePwdLegend": "CHANGEMENT DE MOT DE PASSE",
  "lblChangesRecorded": "Les modifications ont bien été prises en compte",
  "lblCity": "Ville",
  "lblCivilLease": "Bail civil",
  "lblCivilLeaseEuro": "Bail civil (€)",
  "lblClient": "Client",
  "lblCMOBreakdownRawReport": "Répartition des encaissements - brut",
  "lblCMOBreakdownReport": "Répartition des encaissements",
  "lblCMOBreakdownSumRawReport": "Répartition des encaissements cumulé - brut",
  "lblCMOBreakdownSumReport": "Répartition des encaissements cumulé",
  "lblCMOPage": "Suivi des encaissements",
  "lblCMORawReport": "Suivi des encaissements - brut",
  "lblCMOReport": "Suivi des encaissements",
  "lblCollectionMonitoring": "Suivi des encaissements",
  "lblCollectionMonitoringBreakdown": "Répartition des encaissements par délai d'encaissement",
  "lblCollectionMonitoringDate": "Suivi des encaissements",
  "lblCollectionMonitoringSum": "Cumul des encaissements par délai d'encaissement",
  "lblCollectionPeriods": "Gestion des périodes de ventilation",
  "lblCollectMonitBreakdownByBuilding": "Répartition des encaissements par délai cumulé d'encaissement et par immeuble",
  "lblCollectMonitBreakdownByPortfolio": "Répartition des encaissements par délai cumulé d'encaissement et par portefeuille",
  "lblColumnsSelectedRequired": "Veuillez sélectionner au moins 2 colonnes !",
  "lblCommercialLease": "Bail commercial",
  "lblCommercialLeaseEuro": "Bail commercial (€)",
  "lblCommercialLeasePlural": "baux commerciaux",
  "lblCommercialLeaseSingular": "bail commercial",
  "lblcompanie": "Fonds / Sociétés",
  "lblCompanies": "Sociétés",
  "lblCompany": "Société",
  "lblCompanyAccomodation": "Logement de fonction",
  "lblCompanyAccomodationEuro": "Logement de fonction (€)",
  "lblCompanyRegistrationNumber": "N° de RCS",
  "lblComparisonYear": "Année de comparaison",
  "lblConcentrationAndExposure": "Risques de concentration (en surface)",
  "lblConcentrationRiskAndExposure": "Risques de concentration (en valeur vénale et loyer facial)",
  "lblConcernedPortfolio": "Portefeuilles concernés",
  "lblConfirmNewPassword": "Confirmer le nouveau mot de passe",
  "lblConfirmNewPasswordMismatch": "Le nouveau mot de passe et celui de confirmation doivent être identiques",
  "lblConfirmNewPasswordRequired": "Nouveau mot de passe de confirmation obligatoire",
  "lblConfirmPassword": "Confirmer le mot de passe",
  "lblConfirmPasswordRequired": "Mot de passe de confirmation requis",
  "lblConsolidatedAccountsType": "Consolidés",
  "lblConstantPatrimony": "Patrimoine constant",
  "lblCostPriceFinancialInstruments": "Prix de revient des instruments financiers (€)",
  "lblCountry": "Pays",
  "lblCoworkingSpacesIdFKML": "Espaces de travail partagés (Île-de-France)",
  "lblCreateAdditionalField": "Création du thème",
  "lblCreateFund": "Nouvelle société",
  "lblCreateReportTemplate": "Nouveau modèle de rapport",
  "lblCreateTheme": "Creation d'un thème",
  "lblCreateUser": "Nouvel utilisateur",
  "lblCSVSeparator": "Caractère séparateur de champs de fichier CSV",
  "lblCurrency": "Devise",
  "lblCurrentAccountAlreadyExists": "Le n° de compte sélectionné est déjà attribué",
  "lblCurrentAccountAndLoanAccountAlreadyExists": "",
  "lblCurrentPassword": "Mot de passe actuel",
  "lblCurrentPasswordRequired": "Mot de passe actuel obligatoire",
  "lblCurrentSales": "Cessions en cours",
  "lblCurrentYield": "Rendement courant",
  "lblCutOffDate": "Date d'arrêté comptable",
  "lblCutOffDateLocked": "Impossible de charger des VNC à cette date d'arrêté : les PDD immeubles ont déjà été validées.",
  "lblCutOffDateNotLocked": "Les PDDs immeubles n'ont pas encore été validées à cette date d'arrêté",
  "lblCutOffRevisionDate": "Date de révision",
  "lblCVAPage": "Valorisation des sociétés",
  "lblCVARawReport": "Valorisation des sociétés - brut",
  "lblCVAReport": "Valorisation des sociétés",
  "lblDashboard": "Bienvenue",
  "lblDashboardAlerts": "Alertes tableau de bord",
  "lblDataReloaded": "Bonjour,      Les dernières données du property manager {0} ont bien été rechargées.      Cordialement,    L'équipe Alhambra",
  "lblDataReloading": "Les dernières données du property manager {0} sont en train d'être rechargées. Vous recevrez un mail lorsque le traitement sera terminé.",
  "lblDataReloadSubject": "Alhambra - Rechargement des données",
  "lblDate": "À date",
  "lblDateFollow": "Les valeurs doivent se succéder, {0} lignes rejetées.",
  "lblDateFormat": "jj/mm/aaaa",
  "lblDateFormatDropDown": "0:dd/MM/yyyy}",
  "lblDatePasswordLeast2Days": "Vous ne pouvez changer votre mot de passe que {0} jours après sa dernière modification",
  "lblDay": "J",
  "lblDefaultUserError": "Une erreur inconnue est survenue. Vérifiez votre saisie et essayez de nouveau. Si le problème persiste, veuillez contacter votre administrateur.",
  "lblDEKPage": "Reporting Deka",
  "lblDepreciation": "Amortissements (€)",
  "lblDestinationCode": "Code destination",
  "lblDestinationCode_required": "Code destination obligatoire",
  "lblDestinationDe": "Libellé de destination (Allemand)",
  "lblDestinationDe_required": "Libellé de destination (Allemand) obligatoire",
  "lblDestinationEn": "Libellé de destination (Anglais)",
  "lblDestinationEn_required": "Libellé de destination (Anglais) obligatoire",
  "lblDestinationFr": "Libellé de destination (Français)",
  "lblDestinationFr_required": "Libellé de destination (Français) obligatoire",
  "lblDestinationLabel": "Libellé destination",
  "lblDetailledError": "Erreur détaillée :",
  "lblDeTranscodingTypeLabel": "Libellé Allemand de la Liste",
  "lblDevelopmentPropertiesIdFKML": "Immeubles en développement (Île-de-France)",
  "lblDirectBuildingOutstandingPayments": "Impayés par immeuble",
  "lblDirectBuildingOutstandingPaymentsRaw": "Impayés par immeuble - brut",
  "lblDirectLeaseOutstandingPayments": "Impayés par bail",
  "lblDirectLeaseOutstandingPaymentsRaw": "Impayés par bail - brut",
  "lblDisplayAlert": "Afficher l'alerte sur le tableau de bord",
  "lblDistributions": "Résultat comptable distribué (€)",
  "lblDividendProfitDistributions": "Résultat ou dividende (€)",
  "lblDividensPerShare": "Revenus par part (€)",
  "lblDontSendBefore": "Ne pas envoyer d’écriture antérieure au",
  "lblDOPPage": "Impayés",
  "lblDoubleErvFormatError": "Une des VLM n'est pas un nombre",
  "lblDoubleFundOwnershipFormatError": "Une des valeurs n'est pas un nombre",
  "lblDoubleLeaseRepositoryFormatError": "Une des valeurs n'est pas un nombre",
  "lblDoubleNetBookValueFormatError": "Une des valeurs n'est pas un nombre",
  "lblDoubleValuationFormatError": "Une des valeurs n'est pas un nombre",
  "lblDoubleYieldFormatError": "Un des taux de rendement attendus n'est pas un nombre",
  "lblDownloadReport": "Téléchargement de rapport",
  "lblDTZPage": "Situation locative",
  "lblDuplicateEmail": "Cette adresse mail est déjà utilisé par un autre utilisateur",
  "lblDuplicateUserName": "Ce login est déjà utilisé",
  "lblDuration": "WALB (a-m-j)",
  "lblDuration_WOU": "WALB",
  "lblDurationToLeaseEnd": "WALT (a-m-j)",
  "lblDurationToLeaseEnd_WOU": "WALT",
  "lblEarningPerShar": "Résultat ou dividende par part (€)",
  "lblEarningsPerShareDividPerShareCashSharEvol": "Evolution du résultat, des revenus et de la distribution totale par part",
  "lblEditTemplate": "Modifier modèle",
  "lblEffectiveInitYield": "Taux initial effectif",
  "lblEffectiveNetInitialYield_YOU": "Taux initial effectif net",
  "lblEffectiveNetInitYield": "Taux initial effectif net >= (%) :",
  "lblEmail": "Email",
  "lblEmailRequired": "Email obligatoire",
  "lblEmailRequiredError": "Email obligatoire",
  "lblEmailSent": "Email envoyé",
  "lblEmailUnknown": "Email inconnu",
  "lblEmphyteuticLease": "Bail emphytéotique",
  "lblEmphyteuticLeaseEuro": "Bail emphytéotique (€)",
  "lblEmptyLabelRow": "Une ligne ou plus dispose d'un libellé vide.",
  "lblEmptyLabelValueRow": "Une ou plusieurs lignes ont un libellé vide et une expression de base vide.",
  "lblEmptyValueRow": "Une ou plusieurs lignes ont une expression de base vide.",
  "lblEndDate": "Date de fin",
  "lblEnTranscodingTypeLabel": "Libellé Anglais de la Liste",
  "lblEquity": "Capitaux propres (€)",
  "lblEquityAtFairValue": "Fonds propres réévalués (€)",
  "lblEquityContributed": "Fonds propres",
  "lblEquityFairValuePerShare": "Fonds propres réévalués par part (€)",
  "lblEquityFinancing": "Investissement",
  "lblERENonOperating": "Immobilier d'entreprise (hors exploitation)",
  "lblERPAFinancialOccupancyRate": "Taux d'occupation financier EPRA",
  "lblERPAFinancialOccupancySum": "Etat d'occupation financier EPRA",
  "lblERPAFinancialVacancyRate": "Taux de vacance financier EPRA",
  "lblError": "Erreur :",
  "lblErrorHandler": "Gestionnaire de l erreur :",
  "lblErrorMessage": "Message d'erreur",
  "lblErrorPositive": "Montants positifs uniquement",
  "lblErrorRaport": "Vous ne pouvez pas lancer un rapport vide",
  "lblErrorSendGED": "Erreur à l'envoi du mail des factures",
  "lblErvDownloadBuildingUnit": "Importer les VLM par immeuble et par lot unitaire",
  "lblErvDownloadBuildingUnitType": "Importer les VLM par immeuble et par type de lot",
  "lblErvDownloadBuildingUnitTypeRoom": "Importer les VLM par immeuble, par type de lot et par nombre de pièces",
  "lblErvDownloadSectorUnitType": "Importer les VLM par segment de marché et par type de lot",
  "lblERVImportsList": "Historique des imports",
  "lblEuro": "€",
  "lblEuroSqm": "€/m²",
  "lblEuroSqmYear": "€/m²/an",
  "lblEuroYear": "€/an",
  "lblEvolBalanceOutstandPayment": "Evolution du solde d'impayés",
  "lblEvolution": "Evolution",
  "lblEVPPage": "Evolution du patrimoine immobilier",
  "lblEVPRawReport": "Evolution du patrimoine immobilier - brut",
  "lblEVPReport": "Evolution du patrimoine immobilier",
  "lblExpensesBankAccount": "Compte bancaire Dépenses",
  "lblExpenseType": "Type de dépense",
  "lblExpenseTypeAll": "Tous",
  "lblExpenseTypeNonRecoverable": "Non Récupérable",
  "lblExpenseTypeRecoverable": "Récupérable",
  "lblExpiringLeasesLegend": "* Baux à échéance dans la période définie dans le menu Gestion des alertes",
  "lblExternalDebt": "Dette bancaire",
  "lblExternalFunds": "Fonds",
  "lblExtraCondition": "Condition supplémentaire (facultative)",
  "lblExtraordinaryResult": "Résultat exceptionnel (€)",
  "lblFalse": "Non",
  "lblFeeCenter": "Centres d'honoraire",
  "lblFees": "Honoraires (€)",
  "lblFieldNamingConvention": "Nommage spécifique des champs",
  "lblFileError": "Mauvais format de fichier. Utilisez le modèle que vous pouvez télécharger ci-dessous.",
  "lblFileFormatError": "Mauvais format de fichier. Utilisez le modèle que vous pouvez télécharger ci-dessus.",
  "lblFileFormatErrorOnlyAlpha": "Le CODE DE LA LISTE ne doit pas être vide et ne doit contenir que des caractères aphanumériques, - et _",
  "lblFilename": "Nom du fichier",
  "lblFilenameAdditionalField": "DonneeAdditionelle.csv",
  "lblFilenameErvBuildingUnit": "vlm_lot.csv",
  "lblFilenameErvBuildingUnitType": "vlm_type_de_lot.csv",
  "lblFilenameErvBuildingUnitTypeRoom": "vlm_type_de_lot_nb_pieces.csv",
  "lblFilenameErvSectorUnitType": "vlm_marche_type_de_lot.csv",
  "lblFilenameFundOwnership": "donnees_detenteurs.csv",
  "lblFilenameLeaseRepository": "baux.csv",
  "lblFilenameNetBookValue": "vnc.csv",
  "lblFilenameParamTranscoding": "Liste_A_Parametrer_",
  "lblFilenameValuation": "donnees_expertise.csv",
  "lblFilenameYield": "taux_attendus.csv",
  "lblFilterBy": "Filtrer par",
  "lblFinalCapitalClosingDate": "Date de closing",
  "lblFinancialIncome": "Produits financiers (€)",
  "lblFinancialOccupancyRate": "Taux d'occupation financier ASPIM",
  "lblFinancialOccupancyRate_WOU": "Taux d'occupation financier",
  "lblFinancialOccupancySum": "Etat d'occupation financier ASPIM",
  "lblFinancialOccupancySummary": "Etat d'occupation financier",
  "lblFinancialResult": "Résultat financier (€)",
  "lblFinancialResultBridge": "Décomposition du résultat financier",
  "lblFinancialVacancy": "Vacance financière (€/an)",
  "lblFinancialVacancy_WOU": "Vacance financière",
  "lblFinancialVacancyRate": "Taux de vacance financier ASPIM",
  "lblFinancialVacancyRate_WOU": "Taux de vacance financier",
  "lblFinancialVacancySqm": "Vacance financière par m² (€/m²/an)",
  "lblFincancialExpenses": "Charges financières (€)",
  "lblFirstName": "Prénom",
  "lblFonts": "Polices",
  "lblFormatDateIssue": "Problème de format de date « {1} » en ligne {0}.",
  "lblFormIntro": "Veuillez remplir le formulaire",
  "lblFOSPage": "Gestion des données détenteurs",
  "lblFrame": "Encadrement des colonnes par des guillemets",
  "lblFriday": "Vendredi",
  "lblFrTranscodingTypeLabel": "Libellé Français de la Liste",
  "lblFundCode": "Code de la société",
  "lblFundCodeAlreadyExists": "Le code de la société est déjà attribué",
  "lblFundCodeRequired": "Code de la société obligatoire",
  "lblFundDataStartDate": "Date de mise à jour",
  "lblFundingAmount": "Financement",
  "lblFundIsinCode": "Code ISIN",
  "lblFundManager": "Fund Manager",
  "lblFundName": "Nom de la société",
  "lblFundNameRequired": "Nom de la société obligatoire",
  "lblFundOwnership": "Gestion des données détenteurs",
  "lblFundOwnershipFund": "Données détenteurs",
  "lblFundOwnershipImportsList": "Historique des imports",
  "lblFundOwnershipUnderlyingVehicles": "Données détenteurs",
  "lblFundProrata": "Société (au prorata)",
  "lblFundRepositoryExplanations": "Le Référentiel fonds / sociétés permet de modéliser la pyramide de détention des fonds et des sociétés d'investissement dans Alhambra.  Ce menu permet notamment de :<BR />  - Mettre à jour les sociétés existantes qui détiennent des immeubles en direct en saisissant des données complémentaires.<BR />  Ces sociétés correspondent aux propriétaires directs des immeubles et sont donc initialisées à partir des données de gestion lors de l'alimentation d'alhambra.<BR />  - Créer et mettre à jour des sociétés de niveaux supérieurs non détentrices d'immeubles en direct (sociétés supports, véhicules d'investissement, holdings, fonds, investisseurs).<BR />  - Créer et mettre à jour les liens de relation (quote-part de détention) entre les différentes sociétés d'un même fond.<BR />  <BR />  Toutes les données saisies sont historisées en fonction de leur date de mise à jour (champ obligatoire).",
  "lblFundSaved": "Votre société [...] a bien été créée.",
  "lblFundsManagement": "Référentiel fonds / sociétés",
  "lblFundUpdated": "Votre société [...] a bien été modifiée.",
  "lblFundValuation": "Données clés",
  "lblFUNPage": "Export fiche société",
  "lblFurnishedRental": "Location meublée",
  "lblFurnishedRentalEuro": "Location meublée (€)",
  "lblFURPropertiesIdFKML": "Etablissements SSR (Île-de-France)",
  "lblGearingRatio": "Ratio de financement par dette",
  "lblGECPage": "RGD - Factures comptabilisées",
  "lblGECRawReport": "RGD - Factures comptabilisées brut",
  "lblGECReport": "RGD - Factures comptabilisées",
  "lblGeneral": "Général",
  "lblGeneralInformations": "Informations générales",
  "lblGeneralSettings": "Paramètres généraux",
  "lblGenerateInterfaceACS": "Gestion l'interface Comptable",
  "lblGenerateInterfaceLRC": "Gestion de l'interface éléments de facturation",
  "lblGenerateInterfaceTS": "Gestion l'interface Etat Locatif",
  "lblGeographicalBreakdown": "Répartition géographique (en valeur d'expertise HD)",
  "lblGeoZones": "Zones géographiques",
  "lblGEPPage": "RGD - Factures payées",
  "lblGEPRawReport": "RGD - Factures payées brut",
  "lblGEPReport": "RGD - Factures payées",
  "lblGERPage": "Rapport général de dépenses",
  "lblGERRawReport": "Rapport général de dépenses - brut",
  "lblGERReport": "Rapport général de dépenses",
  "lblGlobalParemetersPage": "Paramètres globaux",
  "lblGlobalPortfolio": "Portefeuille global",
  "lblGlobalReturns": "Rendements périodiques",
  "lblGlobalReturnsBreakdown": "Décomposition du rendement global",
  "lblGovernmentRegulatedResidentialLease": "Bail habitation conv",
  "lblGovernmentRegulatedResidentialLeaseEuro": "Bail habitation conv (€)",
  "lblGrandParisZoning": "Territoires du Grand Paris",
  "lblGross": "Taux de rendement bruts",
  "lblGrossAndNetCapitalRates": "Taux de capitalisation bruts et nets",
  "lblGrossAndNetInitYields": "Taux de rendement bruts et nets",
  "lblGrossCapitalizationRateEvol": "Evolution des taux de capitalisation bruts",
  "lblGrossDebt": "Capital restant dû (€)",
  "lblGrossDebtUnderlyingVehicles": "Capital restant dû (fille(s)) (€)",
  "lblGrossEffectiveRent": "Loyer effectif brut (€/an)",
  "lblGrossEffectiveRent_WOU": "Loyer effectif brut",
  "lblGrossEffectiveRentRate": "Loyer effectif brut",
  "lblGrossHeadlineRent_WOU": "Loyer facial brut",
  "lblGrossHeadlineRentBreakByGeo": "Répartition du loyer facial brut par zone géographique",
  "lblGrossHeadlineRentBreakByPropType": "Répartition du loyer facial brut par type d'immeuble",
  "lblGrossHeadlineRentBreakBySector": "Répartition du loyer facial brut par segment de marché",
  "lblGrossHeadlineRentBreakByTenant": "Répartition du loyer facial par locataire",
  "lblGrossHeadlineRentBreakByTenantIndustry": "Répartition du loyer facial par secteur d'activité",
  "lblGrossHeadlineRentBridge": "Evolution du loyer facial",
  "lblGrossInitilaYieldEvol": "Evolution des taux de rendement bruts",
  "lblGrossReceiptedRent": "Loyer comptabilisé à date (€)",
  "lblGrossReceiptedRentByGrossBookValue": "Rendement brut sur ABI",
  "lblGrossReceiptedRentByNetMarketValue": "Rendement brut sur valeur vénale nette",
  "lblGrossRent": "Loyer facial brut (€/an)",
  "lblGrossRentalIncome": "Loyer (€)",
  "lblGrossRentalIncomeEvol": "Evolution des revenus locatifs bruts",
  "lblGrossRentSqm": "Loyer facial par m² (€/m²/an)",
  "lblGrossRentSqm_WOU": "Loyer facial brut par m²",
  "lblGrossReversPotentOccupArea_WOU": "Sur la surface occupée",
  "lblGrossReversPotentTotalProp_WOU": "Sur la surface totale",
  "lblHeaderLine": "Présence de la ligne d’en-tête",
  "lblHeadlineGrossInitialYield": "Taux de capitalisation initial facial brut",
  "lblHeadlineInitYield": "Taux initial facial",
  "lblHeadlineNetInitialYield_YOU": "Taux initial facial net",
  "lblHeadlineNetInitYield": "Taux initial facial net >= (%) :",
  "lblHeadlineRentAs": "Loyer facial au DD/MM/YYYY (€)",
  "lblHeadlineRentLeaseRenewals": "Loyer facial des baux renouvelés (€)",
  "lblHeadlineRentLeasesTerminated": "Loyer facial des baux sortis (€)",
  "lblHeadlineRentLeaseTerminationsNotified": "Loyer facial des baux en congés (€)",
  "lblHeadlineRentRelettings": "Loyer facial des nouveaux baux (€)",
  "lblHeadOffice": "Siège social",
  "lblHealthcare": "Santé",
  "lblHideFilters": "Cacher les filtres",
  "lblHideGraph": "Cacher l'entête",
  "lblHideMap": "Cacher l'entête",
  "lblHideParams": "Cacher les paramètres",
  "lblHoldoverLeasesLegend": "* Baux non renouvelés",
  "lblHoldoverTenancy": "Suivi des baux non renouvelés",
  "lblHOTPage": "Suivi des baux non renouvelés",
  "lblHOTRawReport": "Suivi des baux non renouvelés - brut",
  "lblHOTReport": "Suivi des baux non renouvelés",
  "lblIdExists": "Cet identifiant existe déjà",
  "lblImmoStatMarketGeograhies": "Segmentation ImmoStat",
  "lblImportDate": "Date d'import",
  "lblImportedBy": "Importé par",
  "lblInceptiondate": "Date de création ou d'acquisition",
  "lblIncomeReturn": "Rendement courant",
  "lblIncomeReturnEquityFairValBridgs": "Evolution du rendement courant, en capital et global sur fonds propres réévalués",
  "lblIncomeReturnEquityFairValue": "Rendement courant sur fonds propres réévalués",
  "lblIncomeReturnOnNAV": "Rendement courant sur ANR",
  "lblIndustrialPremises": "Locaux industriels",
  "lblInfOneMonth": "< 1 mois",
  "lblInfThreeMonth": "< 3 mois",
  "lblInfTwoMonth": "< 2 mois",
  "lblInnerMsgError": "Détail de l erreur :",
  "lblInsertNewNeutralizationPeriod": "Voulez-vous ajouter la nouvelle période de neutralisation ?",
  "lblInterestPaidCurrentAccount": "Intérêts de compte courant (€)",
  "lblInterestPaidOnCurrentAccount": "Intérêts de compte courant (€)",
  "lblinterface_name": "Nom de l’interface",
  "lblinterface_type": "Type de l’interface",
  "lblInterfaceInsertAccounting": "Votre interface Comptable [...] a bien été créée et ajoutée à la liste des interfaces disponibles.",
  "lblInterfaceInsertAS": "Votre interface Comptable [...] a bien été créée et ajoutée à la liste des interfaces disponibles.",
  "lblInterfaceInsertTS": "Votre interface Etat Locatif [...] a bien été créée et ajoutée à la liste des interfaces disponibles.",
  "lblInterfaceMappingExport": "Exporter le type de données",
  "lblInterfaceMappingWarningMessage": "Attention, tout import d'un type de donnée écrase les paramétrages existants pour ce type de données.",
  "lblInterfaceName": "Nom de l'interface",
  "lblInterfaceNameAlreadyExists": "Le nom de l'interface est déjà attribué",
  "lblInterfaceSequencesHistory": "Historique des envois de l'interface Comptable",
  "lblInterfaceSequencesLRCHistory": "Historique des envois de l'interface Eléments de facturation",
  "lblInterfaceSequencesTSHistory": "Historique des envois de l'interface Etat locatif",
  "lblInterfaceUpdateAccounting": "Votre interface Comptable [...] a bien été modifiée.",
  "lblInterfaceUpdateAS": "Votre interface Comptable [...] a bien été modifiée.",
  "lblInterfaceUpdateSI": "Votre interface Spécifique [...] a bien été modifiée.",
  "lblInterfaceUpdateTS": "Votre interface Etat Locatif [...] a bien été modifiée.",
  "lblInvalidAnswer": "La réponse à la question de récupération du mot de passe est incorrecte",
  "lblInvalidBuildingCode": "Code immeuble du property manager invalide",
  "lblInvalidBuildingName": "Nom de l'immeuble invalide",
  "lblInvalidBuildingNature": "Type d'immeuble invalide",
  "lblInvalidBuildingUniqueCode": "Code immeuble invalide",
  "lblInvalidEmail": "Adresse mail invalide",
  "lblInvalidPassword": "Mot de passe invalide",
  "lblInvalidPMName": "Nom du property manager invalide",
  "lblInvalidQuestion": "Question de récupération invalide",
  "lblInvalidstartManagementDate": "Date du début de gestion invalide",
  "lblInvalidUserName": "Login invalide",
  "lblInverstorDistribution": "Revenus distribués",
  "lblInvestmentStructure": "Pyramide de détention",
  "lblInvestorContributions": "Fonds propres investis",
  "lblInvestorDistribution": "Revenus",
  "lblInvestorLevelNAV": "Valorisation des titres",
  "lblInvestorNavAndReturns": "VALORISATION DES TITRES",
  "lblInvestorReturn": "Rendements sur fonds propres",
  "lblInvestors": "Détenteurs",
  "lblInvestorShareOfNAV": "Valeur liquidative des titres (€)",
  "lblInvoicingAmount": "Montant de la facture",
  "lblInvoicingBankAccount": "Compte bancaire Facturation",
  "lblIsBuildingNeutralized": "L'immeuble est indiqué comme neutralisé si la date du dernier import est incluse dans une des période de neutralisation de l'immeuble",
  "lblIsLookThroughEntity": "Société fiscalement transparente ?",
  "lblIsProviderActive": "Fournisseur actif",
  "lblIssuedSharesNumber": "Nombre total de parts",
  "lblJournalFamilies": "Familles de journaux",
  "lblKeyFigures": "Chiffres clés",
  "lblLASPage": "Interface LASALLE",
  "lblLastAnnualRent": "Loyer facial (€)",
  "lbllastName": "Nom",
  "lblLatitude": "Latitude",
  "lblLatitudeIncorrectFormat": "Latitude : Format numérique attendu (ex: -18,4587 ou 27,5893)",
  "lblLatitudeIncorrectRange": "Latitude : Valeur incorrecte entre -90 et +90",
  "lblLaunch": "Souhaitez-vous lancer l'interface immédiatement ?",
  "lblLaunchValidation": "Nous démarrons le lancement de l’interface",
  "lblLaw1948Lease": "Bail d'habitation (Loi de 1948)",
  "lblLaw1948LeaseEuro": "Bail d'habitation (Loi de 1948) (€)",
  "lblLease": "Code du bail",
  "lblLeaseBreakByReversionPotential": "Répartition des baux par potentiel de réversion",
  "lblLeaseCount": "Nombre de baux",
  "lblLeaseEndAnnualRentAmount": "Loyer facial des baux à échéance",
  "lblLeaseEndCount": "Nombre de baux à échéance",
  "lblLeaseEndDate": "Date de fin du bail",
  "lblLeaseEndRentArea": "Surface des baux à échéance",
  "lblLeaseEntrepriseSchedule": "Echéancier des baux commerciaux",
  "lblLeaseExpiring6M": "Baux arrivant à échéance dans moins de 6 mois",
  "lblLeaseExpiryProfileArea": "Echéancier des baux commerciaux (surface)",
  "lblLEaseExpiryProfileHeadRent": "Echéancier des baux commerciaux (loyer facial)",
  "lblLeaseHeadlineRentExceeding": "Si le loyer facial du bail est supérieur ou égal à (€) :",
  "lblLeaseMonitoring": "Suivi des baux",
  "lblLeaseNatures": "Natures de baux",
  "lblLeaseNumber": "Nombre de baux",
  "lblLeasePlural": "baux",
  "lblLeaseRegularChargesLevel": "Données au niveau",
  "lblLeaseRegularChargesLevelLease": "Bail",
  "lblLeaseRegularChargesLevelUnit": "Lot",
  "lblLeaseRegularChargesStatus": "Inclure les éléments",
  "lblLeaseRegularChargesStatusAll": "Tous",
  "lblLeaseRegularChargesStatusFuture": "Appliquables dans le futur",
  "lblLeaseRegularChargesStatusPast": "Appliquables dans le passé",
  "lblLeaseRegularChargesStatusPresent": "Appliquables dans le présent",
  "lblLeaseRenewals": "Renouvellements (€)",
  "lblLeaseRepository": "Gestion des données du bail",
  "lblLeaseRepositoryImportsList": "Historique des imports",
  "lblLeaseSingular": "bail",
  "lblLeaseStartDate": "Date de début du bail",
  "lblLeaseTermination": "Sorties (€)",
  "lblLeaseTypes": "Types de bail",
  "lblLegalVehicleStructure": "Forme juridique",
  "lblLEMPage": "Suivi des baux",
  "lblLEMRawReport": "Suivi des baux - brut",
  "lblLEMReport": "Suivi des baux",
  "lblLESPage": "Echéancier des baux commerciaux",
  "lblLESRawReport": "Echéancier des baux commerciaux - brut",
  "lblLESReport": "Echéancier des baux commerciaux",
  "lblLevel": "Niveau",
  "lblLevels": "Niveaux",
  "lblLikeForLikeCapitalDecrease": "Diminution à périmètre constant (€)",
  "lblLikeForLikeCapitalGrowth": "Augmentation à périmètre constant (€)",
  "lblLinkGED": "Bonjour  Suite à la demande que vous venez de faire sur Alhambra, voici le lien vers les documents :",
  "lblLoanAccountAlreadyExists": "Le n° de compte de prêt d'associé est déjà attribué",
  "lblLoanToValue": "Loan-To-Value (LTV)",
  "lblLocationConcentration": "Concentration géographique (top 5 valeur vénale)",
  "lblLocationConcentrationRentArea": "Concentration géographique (top 5 surface totale)",
  "lblLogIn": "Se connecter",
  "lblLoginError": "Erreur de login ou de mot de passe",
  "lblLoginForm": "Formulaire de connexion",
  "lblLoginLastAttemptWarning": "Au prochain mot de passe erroné, votre compte sera désactivé",
  "lblLoginName": "Login",
  "lblLoginRequired": "Login obligatoire",
  "lblLoginTooShort": "Le login doit faire au moins {0} caractères",
  "lblLoginWithLocalAccount": "Connectez-vous à l'aide d'un compte local",
  "lblLongitude": "Longitude",
  "lblLongitudeIncorrectFormat": "Longitude : Format numérique attendu (ex: -118,4587 ou 127,5893)",
  "lblLongitudeIncorrectRange": "Longitude : Valeur incorrecte entre -180 et +180",
  "lblLRCBillingItems": "Interface Eléments de facturation",
  "lblMAFPage": "Gestion des thèmes",
  "lblManageAdditionalFieldList": "Gestion des thèmes",
  "lblManageDataMapping": "Paramétrage des données entrantes",
  "lblManageInterfaceList": "Gestion des interfaces",
  "lblManageInterfaceMapping": "Paramétrage des données sortantes",
  "lblManageSpecificInterfaceList": "Gestion des interfaces spécifiques",
  "lblManagingDirector": "Gérant",
  "lblMarketToMarketFinancialInstruments": "MtM des instruments financiers (€)",
  "lblMarketValue": "Gestion des VLM",
  "lblMaxCharLength": "Longueur maximum des champs de caractères",
  "lblMCOPropertiesIdFKML": "Etablissements MCO (Île-de-France)",
  "lblMDMPage": "Paramétrage des données entrantes",
  "lblMIMPage": "Paramétrage des données sortantes",
  "lblMITPage": "Gestion des interfaces",
  "lblMixed": "Mixte",
  "lblMixedEuro": "Mixte (€)",
  "lblMixedLease": "Bail mixte",
  "lblMixedLeaseEuro": "Bail mixte (€)",
  "lblModifyColumns": "Sélectionner les colonnes",
  "lblMonday": "Lundi",
  "lblMsgNbColsDashboard": "Vous ne pouvez pas sélectionner plus de 4 indicateurs",
  "lblMSIPage": "Gestion des interfaces spécifiques",
  "lblMsqErrorPassword": "Le mot de passe doit contenir a minima {0} caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial !*@#$%^+=",
  "lblMultiPurpose": "Multi-affectations",
  "lblMyAccount": "Mon compte",
  "lblMyAssets": "Mon portefeuille",
  "lblNameOfTemplate": "Nom du modèle",
  "lblNameRequiredError": "Nom obligatoire",
  "lblNature": "Nature",
  "lblNaturesOfOccupancy": "Exclure de la sélection les immeubles dont tous les lots ont pour nature d'occupation :",
  "lblNaturesOfOccupancyTitle": "Retirer de la liste tout immeuble…",
  "lblNe": "Net",
  "lblNegativeRentReviews": "Indexations négatives (€)",
  "lblNet": "Taux de rendement nets",
  "lblNetAssetVal": "Actif net réévalué (€)",
  "lblNetAssetValEquitFairValBridge": "Actif net réévalué et fonds propres réévalués",
  "lblNetAssetValEquitFairValEvol": "Evolution de l'actif net réévalué et des fonds propres réévalués",
  "lblNetAssetValPerSharEquitFairValPerSharEvol": "Evolution de la valeur liquidative et des fonds propres réévalués par part",
  "lblNetAssetValue": "Actif net réévalué",
  "lblNetAssetValueEquitySecurities": "ANR des titres de participation (€)",
  "lblNetAssetValueFund": "Actif net réévalué (ANR) (€)",
  "lblNetAssetValuePerShare": "Valeur liquidative (€/part)",
  "lblNetAssetValueUnderlyingVehicles": "Actif net réévalué des titres (€)",
  "lblNetBookValue": "Gestion des VNC",
  "lblNetBookValueEquitySecurities": "VNC des titres de participation (€)",
  "lblNetBookValueFund": "Valeur nette comptable (€)",
  "lblNetBookValueImportsList": "Historique des imports",
  "lblNetBookValueUnderlyingVehicles": "Valeur nette comptable des titres (€)",
  "lblNetCapitalizationRateEvol": "Evolution des taux de capitalisation nets",
  "lblNetChangeInCurrentAccount": "Variation nette de compte courant (€)",
  "lblNetChangeInShareCapital": "Variation nette de capital (€)",
  "lblNetChangeInSharePremium": "Variation nette de prime d'émission (€)",
  "lblNetEffectiveRent_WOU": "Loyer effectif net",
  "lblNetHeadlineRent_WOU": "Loyer facial net",
  "lblNetInitialYieldEvol": "Evolution des taux de rendement nets",
  "lblNetMarketRent_WOU": "Loyer net de marché",
  "lblNetMarketValBreakByGeo": "Répartition de la valeur vénale nette par zone géographique",
  "lblNetMarketValBreakByPropType": "Répartition de la valeur vénale nette par type d'immeuble",
  "lblNetMarketValue": "Valeur vénale nette (€)",
  "lblNetMarketValueAsAt": "Valeur vénale nette au DD/MM/YYYY (€)",
  "lblNetMarketValueBridge": "Variation de la valeur vénale nette",
  "lblNetOperatingIncome": "Résultat d'exploitation (€)",
  "lblNetOperatingIncomeBridge": "Décomposition du résultat d'exploitation",
  "lblNetPotentialRent_WOU": "Loyer potentiel net",
  "lblNetRentalIncomeEvol": "Evolution des revenus locatifs nets",
  "lblNetResult": "Résultat comptable (€)",
  "lblNetResultAfterTax": "Résultat comptable après IS (€)",
  "lblNetResultBeforeTax": "Résultat comptable avant IS (€)",
  "lblNetResultBridge": "Décomposition du résultat comptable",
  "lblNetResultReturnOnGrossBookOutSessionValue": "Rendement comptable hors cession sur ABI",
  "lblNetResultReturnOnGrossBookValue": "Rendement comptable sur ABI",
  "lblNetResultTax": "Impôt sur les sociétés (€)",
  "lblNetReversionaryYield_WOU": "Taux théorique net",
  "lblNeutEndDateInNextPeriod": "La date de début de neutralisation de l'immeuble est postérieure ou fait partie de la période suivante !",
  "lblNeutralizationEndDateEmpty": "La date de fin de neutralisation de l'immeuble est vide !",
  "lblNeutralizationEndDateNotADate": "La date de fin de neutralisation de l'immeuble n'est pas une date valide !",
  "lblNeutralizationStartDateEmpty": "La date de début de neutralisation de l'immeuble est vide !",
  "lblNeutralizationStartDateNotADate": "La date de début de neutralisation de l'immeuble n'est pas une date valide !",
  "lblNeutralizationType": "Neutralisation",
  "lblNeutStartDateGreaterThanNeutEndDate": "La date de début de neutralisation de l'immeuble est postérieure ou égale à la date de fin !",
  "lblNeutStartDateInPreviousPeriod": "La date de début de neutralisation de l'immeuble est antérieure ou fait partie de la période précédente !",
  "lblNeutStartDateLowerThanStartManagementDate": "La date de début de neutralisation de l'immeuble est inférieure à la date de début de gestion !",
  "lblNewBuildingRepository": "Créer un nouvel immeuble dans le référentiel",
  "lblNewERVImport": "Gestion des VLM",
  "lblNewFund": "Nouvelle société",
  "lblNewFundOwnershipImport": "Gestion des données détenteurs",
  "lblNewLeaseRepositoryImport": "Gestion des données du bail",
  "lblNewNetBookValueImport": "Gestion des VNC",
  "lblNewPassword": "Nouveau mot de passe",
  "lblNewPasswordRequired": "Nouveau mot de passe obligatoire",
  "lblNewPurchase": "Nouvelle acquisition",
  "lblNewSale": "Nouvelle cession",
  "lblNewTemplate": "Nouveau modèle",
  "lblNewUserMailBodyEnd": "Cordialement,  L'équipe Alhambra",
  "lblNewUserMailBodyMiddle": "Vous pouvez vous y connecter à l'adresse suivante :",
  "lblNewUserMailBodyMiddle1": "- Mot de passe :",
  "lblNewUserMailBodyMiddle2": "Vous pouvez vous y connecter à l'adresse suivante :",
  "lblNewUserMailBodyStart": "Bonjour,  Veuillez trouver ci-dessous vos identifiants de connexion à Alhambra :  - Utilisateur :",
  "lblNewUserMailBodyStart1": "Bonjour,  Veuillez trouver ci-dessous votre identifiant de connexion à Alhambra :  - Utilisateur :",
  "lblNewUserMailBodyStart2": "Bonjour,  Veuillez trouver ci-dessous votre mot de passe de connexion à Alhambra :  - Mot de passe provisoire :",
  "lblNewUserMailSubject": "ALHAMBRA - Nouvel utilisateur",
  "lblNewValuationImport": "Gestion des données d'expertise",
  "lblNewYieldImport": "Gestion des taux de rendement",
  "lblNextLeaseBreakDate": "Prochaine date de break",
  "lblNextMonths": "prochain(s) mois",
  "lblNo": "Non",
  "lblNoBuildingProviderSelected": "Aucun immeuble ou fournisseur sélectionné. Veuillez sélectionner au moins un immeuble ou un fournisseur.",
  "lblNoBuildingSelected": "Aucun immeuble sélectionné. Veuillez sélectionner au moins un immeuble.",
  "lblNoColumn": "Veuillez sélectionner une ou plusieurs colonnes.",
  "lblNoCompanySelected": "Pas de société sélectionnée. Vous devez sélectionner au moins une société.",
  "lblNoData": "Pas de données",
  "lblNoDataCharts": "Aucune donnée disponible pour ce graphique",
  "lblNoDatasetSelected": "Aucun jeu de données sélectionné",
  "lblNoFileSelected": "Aucun fichier sélectionné",
  "lblNoInvestorSelected": "Aucun détenteur sélectionné. Veuillez sélectionner au moins un détenteur.",
  "lblNoNewTranscodingType": "Vous ne pouvez pas créer de nouveau type de données.",
  "lblNoNewTypeXLinesRejected": "Vous ne pouvez pas créer de nouveau type de données. {0} lignes ont été rejetées.",
  "lblNoRssArticles": "Aucun article trouvé",
  "lblNoTemplate": "Pas de modèle",
  "lblNumberBuildings": "Nombre d'immeubles",
  "lblNumberLeaseRenewals": "Nombre de baux renouvelés",
  "lblNumberLeases": "Nombre de baux",
  "lblNumberLeasesTerminated": "Nombre de baux sortis",
  "lblNumberLeaseTerminationsNotified": "Nombre de congés signifiés",
  "lblNumberOfBuildingsUnderManagementByBuildingType": "Nombre d'immeubles en gestion par type d'immeuble",
  "lblNumberOfHousings": "Nombre de logements",
  "lblNumberOfInterfaces": "Nombre d'interfaces liées",
  "lblNumberOfOwnedFunds": "Société-filles",
  "lblNumberOfSharesUnallocated": "Nombre de parts non attribuées (calculé)",
  "lblNumberOfSharesUnallocatedIncorrect": "Le nombre total de parts ou les nombres de parts détenues sont incorrects",
  "lblNumberRelettings": "Nombre de relocations",
  "lblNumberRents": "Nombre de loyers",
  "lblNumberTenants": "Nombre de locataires",
  "lblOccupancy": "Occupation",
  "lblOccupiedAreaBreakBySector": "Répartition de la surface occupée par segment de marché",
  "lblOccupiedAreaErv": "VLM de la surface occupée (€/an)",
  "lblOfficeRetailOutlet": "Bureau / Commerce",
  "lblOfficeRetailOutletEuro": "Bureau / Commerce (€)",
  "lblOffices": "Bureaux",
  "lblOPBPage": "Analyse des impayés",
  "lblOPBRawReport": "Analyse des impayés - brut",
  "lblOPBReport": "Analyse des impayés",
  "lblOperatingExpenses": "Charges d'exploitation (€)",
  "lblOperatingProperties": "Immeubles d'exploitation",
  "lblOperatingPropertiesSqm": "Immobilier d'exploitation (m²)",
  "lblOperatingRealEstate": "Immobilier d'exploitation (IE + IR)",
  "lblOperatingResult": "Résultat d'exploitation (€)",
  "lblOperatingReturnOnGrossBookValue": "Rendement d'exploitation sur ABI",
  "lblOPMPage": "Suivi des impayés en date comptable",
  "lblOPMRawReport": "Suivi des impayés en date comptable - brut",
  "lblOPMReport": "Suivi des impayés en date comptable",
  "lblOPPBULRawReport": "Balance agée immeuble par fournisseur et par facture - brut",
  "lblOPPBULReport": "Balance agée immeuble par fournisseur et par facture",
  "lblOPPPage": "Balance agée fournisseur",
  "lblOPPPRORawReport": "Balance agée fournisseur par immeuble et par facture - brut",
  "lblOPPPROReport": "Balance agée fournisseur par immeuble et par facture",
  "lblOPPRawReport": "Balance agée immeuble par fournisseur - brut",
  "lblOPPReport": "Balance agée immeuble par fournisseur",
  "lblOther": "Autre",
  "lblOtherBreakMonths": "Afficher en surbrillance rouge la ligne lorsqu'un bail \"autre\" arrive à échéance dans les",
  "lblOtherEuro": "Autre (€)",
  "lblOtherIncome": "Autres produits d'exploitation (€)",
  "lblOtherLease": "Autre bail",
  "lblOtherLeaseendMonths": "Afficher en surbrillance rouge la ligne lorsqu'un bail \"autre\" expire dans les",
  "lblOtherLeasePlural": "baux autres",
  "lblOtherLeaseSingular": "bail autre",
  "lblOUTCOTRawReport": "Impayés - brut",
  "lblOUTCOTReport": "Impayés",
  "lblOUTINVRawReport": "Balance agée par facture - brut",
  "lblOUTINVRCIRawReport": "Balance agée par facture et rubrique de facturation - brut",
  "lblOUTINVRCIReport": "Balance agée par facture et rubrique de facturation",
  "lblOUTINVReport": "Balance agée par facture",
  "lblOUTLEARCIRawReport": "Balance agée par bail et rubrique de facturation - brut",
  "lblOUTLEARCIReport": "Balance agée par bail et rubrique de facturation",
  "lblOUTPage": "Balance agée",
  "lblOUTRawReport": "Balance agée par bail - brut",
  "lblOUTReport": "Balance agée par bail",
  "lblOutstandingDebt": "Capital restant dû (€)",
  "lblOutstandingPayment": "Balance agée",
  "lblOutstandingPayments": "Balance agée par bail et par rubrique de facturation",
  "lblOutstandingPayProvider": "Balance agée fournisseur",
  "lblOutstandPayInvoice": "Balance agée par facture",
  "lblOutstandPayInvoiceBillItem": "Balance agée par facture et rubrique de facturation",
  "lblOutstandPaymentOfThePeriod": "Impayés de la période (€)",
  "lblOutstandPaymentRateOfThePeriod": "Taux d'impayés de la période",
  "lblOutstandPayMonitoring": "Suivi des impayés en date comptable",
  "lblOutstandPayProvider": "Balance agée fournisseur",
  "lblOutstandPaySummary": "Balance agée par bail",
  "lblOutstdingRateAmountBalance": "Evolution du ratio encaissements / solde locataires",
  "lblOverPaymentAmount": "Montant des trop-perçus",
  "lblOverRented": "Réversion positive",
  "lblOwnedBuildings": "IMMEUBLES DETENUS DIRECTEMENT ET INDIRECTEMENT (100 %)",
  "lblOwners": "Propriétaires",
  "lblParams": "Paramètres",
  "lblParamTranscoding": "ParamTranscoding",
  "lblPassRecoveryLinkTimeCheck": "Lien de récupération expiré",
  "lblPassword": "Mot de passe",
  "lblPasswordAlreadyUsed": "Mot de passe déjà utilisé",
  "lblPasswordChanged": "Le changement de votre mot de passe a bien été pris en compte",
  "lblPasswordChangedMailBodyEnd": "Cordialement,  L'équipe Alhambra",
  "lblPasswordChangedMailBodyStart": "Bonjour,  Votre nouveau mot de passe pour Alhambra est :",
  "lblPasswordChangedMailSubject": "ALHAMBRA - Mot de passe modifié avec succès",
  "lblPasswordChangeError": "Impossible de changer le mot de passe. Soit l'ancien mot de passe est incorrect,   soit le nouveau mot de passe ne contient pas au moins 8 caractères dont au moins   un caractère alphabétique, un nombre et un caractère spécial.",
  "lblPasswordCreateError": "Mot de passe incorrect. Le mot de passe doit contenir au moins 8 caractères dont au moins   un caractère alphabétique, un nombre et un caractère spécial.",
  "lblPasswordExpired": "Votre mot de passe a expiré. Vous devez le changer pour continuer",
  "lblPasswordMustAtLeasrEightCharLong": "Le mot de passe doit comporter au moins huit caractères",
  "lblPasswordMustContainDigit": "Le mot de passe doit comporter un chiffre",
  "lblPasswordMustContainSpecialChar": "Le mot de passe doit comporter un caractère spécial",
  "lblPasswordRequired": "Mot de passe obligatoire",
  "lblPATPage": "Cessions en cours",
  "lblPATRawReport": "Cessions en cours - brut",
  "lblPATReport": "Cessions en cours",
  "lblPatrimony": "Patrimoine",
  "lblPaymentAmount": "Montant du paiement",
  "lblPBAPage": "Balance immeuble",
  "lblPBARawReport": "Balance immeuble - brut",
  "lblPBAReport": "Balance immeuble",
  "lblPendingArbitrageTransactions": "Cessions en cours",
  "lblPendingPurchaseTransactions": "Acquisitions en cours",
  "lblPercent": "%",
  "lblPercentOfTotalOutstandingPayment": "% du total des impayés",
  "lblPerfImmo": "Performance",
  "lblPeriod1": "Borne supérieure Période 1",
  "lblPeriod2": "Borne supérieure Période 2",
  "lblPeriod3": "Borne supérieure Période 3",
  "lblPeriod4": "Borne supérieure Période 4",
  "lblPeriod5": "Borne supérieure Période 5",
  "lblPhysicalOccRate": "Taux d'occupation physique",
  "lblPhysicalOccupancySummary": "Etat d'occupation physique",
  "lblPhysicalOccupancySummaryEvolution": "Evolution de l'état d'occupation physique",
  "lblPhysicalVacancy": "Taux de vacance physique",
  "lblPhysicalVacRate": "Taux de vacance physique",
  "lblPICPage": "Factures fournisseurs comptabilisées",
  "lblPICRawReport": "Factures fournisseurs comptabilisées - brut",
  "lblPICReport": "Factures fournisseurs comptabilisées",
  "lblPIPPage": "Factures fournisseurs payées",
  "lblPIPRawReport": "Factures fournisseurs payées - brut",
  "lblPIPReport": "Factures fournisseurs payées",
  "lblPleaseWait": "Veuillez patienter...",
  "lblPMBuildingCode": "Code immeuble du Property Manager",
  "lblPMRPage": "Tableau de bord ADB",
  "lblPMRReport": "Tableau de bord ADB",
  "lblPMRReportEREBuildingType": "Tertiaire",
  "lblPMRReportEREBuildingTypeSqm": "Tertiaire (m²)",
  "lblPMRReportRREBuildingType": "Résidentiel",
  "lblPMRReportRREBuildingTypeSqm": "Résidentiel (m²)",
  "lblPOBPage": "Répartition du patrimoine",
  "lblPOBRawReport": "Répartition du patrimoine - brut",
  "lblPOBReport": "Répartition du patrimoine",
  "lblPortfolio": "Portefeuille",
  "lblPortfolioBreakByGeography": "Répartition géographique du patrimoine",
  "lblPortfolioBreakByMarketGeo": "Répartition du patrimoine par segment de marché",
  "lblPortfolioBreakByPropertyType": "Répartition sectorielle du patrimoine",
  "lblPortfolioBreakByTenant": "Répartition du patrimoine par locataire",
  "lblPortfolioBreakByTenantIndustry": "Répartition du patrimoine par secteur d'activité",
  "lblPortfolioBreakdown": "Répartition du patrimoine",
  "lblPortfolioBreakdownByBuildingNature": "Répartition par type d'immeuble",
  "lblPortfolioBreakdownByBuildingType": "Répartition par typologie d'immeuble",
  "lblPortfolioCode": "Code du portefeuille",
  "lblPortfolioCode_required": "Code du portefuille obligatoire",
  "lblPortfolioEvolution": "Evolution du patrimoine immobilier",
  "lblPortfolioLabel": "FR_PORTFOLIO_LABEL",
  "lblPortfolioName": "Nom du portefeuille",
  "lblPortfolioNameDe": "Nom du portefeuille (allemand)",
  "lblPortfolioNameDe_required": "Nom du portefuille (allemand) obligatoire",
  "lblPortfolioNameEn": "Nom du portefeuille (anglais)",
  "lblPortfolioNameEn_required": "Nom du portefuille (anglais) obligatoire",
  "lblPortfolioNameFr": "Nom du portefeuille (français)",
  "lblPortfolioNameFr_required": "Nom du portefuille (français) obligatoire",
  "lblPortfolios": "Portefeuilles",
  "lblPortfolioSize": "Volumétrie du portefeuille (Société-mère et société-filles)",
  "lblPortfolioYieldsAndReturns": "Rendements portefeuille (Société-mère)",
  "lblPositiveRentReviews": "Indexations positives (€)",
  "lblPostalCode": "Code postal",
  "lblPotentialInitYield": "Taux effectif potentiel",
  "lblPotentialNetInitialYield_YOU": "Taux effectif potentiel net",
  "lblPPTPage": "Acquisitions en cours",
  "lblPPTRawReport": "Acquisitions en cours - brut",
  "lblPPTReport": "Acquisitions en cours",
  "lblPreferredPortfolio": "Portefeuille préféré",
  "lblPreviousDate": "La date de début doit être antérieure à la date de fin, {0} lignes rejetées.",
  "lblPreviousFundUpdateDeleted": "La mise à jour de la société [...] a bien été supprimée.",
  "lblPreviouslyUpdatedOn": "Dates de mise à jour précédentes",
  "lblPreviousNeutEndDateNotSet": "La date de fin de neutralisation de l'immeuble n'est pas renseignée pour la période précédente !",
  "lblProfessional": "Professionnel",
  "lblProfessionalEuro": "Professionnel (€)",
  "lblProfessionalLease": "Bail professionnel",
  "lblProfessionalLeaseEuro": "Bail professionnel (€)",
  "lblProfile": "Profil",
  "lblProperty": "Immeuble",
  "lblPropertyAcquisitions": "Acquisitions (€)",
  "lblPropertyAreaExceeding": "Si la surface totale de l'immeuble est supérieure ou égale à (m²) :",
  "lblPropertyBalance": "Balance immeuble",
  "lblPropertyBalanceSummary": "Balance immeuble agrégée",
  "lblPropertyBreakByGeo": "Répartition des immeubles par zone géographique",
  "lblPropertyBreakByPropertyType": "Répartition des immeubles par type d'immeuble",
  "lblPropertyDisposals": "Cessions (€)",
  "lblPropertyDistribution": "Répartition des immeubles par rendement locatif et rendement en capital",
  "lblPropertyManagementActivities": "Activités gestion immobilière",
  "lblPropertyManager": "Property Manager",
  "lblPropertyManagerCode": "Code Property Manager",
  "lblPropertyManagerCode_required": "Code Property Manager obligatoire",
  "lblPropertyManagerName": "Nom du Property Manager",
  "lblPropertyManagerName_required": "Nom du Property Manager obligatoire",
  "lblPropertyNetMarketValueExceeding": "Si la valeur vénale nette de l'immeuble est supérieure ou égale à (€) :",
  "lblPropertyValuation": "Valorisation des immeubles",
  "lblProviderError": "Le système d'authentification trouve une erreur. Vérifiez votre saisie et essayez de nouveau. Si le problème persiste, veuillez contacter votre administrateur.",
  "lblProviderInvoices": "Factures fournisseurs",
  "lblProviderInvoicesAccounted": "Factures fournisseurs comptabilisées",
  "lblProviderInvoicesPaid": "Factures fournisseurs payées",
  "lblPurchase": "Gestion des acquisitions",
  "lblPurchaseAgreementDate": "Date de promesse de vente",
  "lblPurchaseHistory": "Historique des acquisitions",
  "lblPurchaseManagement": "Gestion des acquisitions",
  "lblPurchaseSearch": "Rechercher dans les acquisitions",
  "lblPurchaseTitle": "Acquisitions en cours",
  "lblPurchaseType": "Type d'acquisition",
  "lblPVAPage": "Valorisation des immeubles",
  "lblPVARawReport": "Valorisation des immeubles - brut",
  "lblPVAReport": "Valorisation des immeubles",
  "lblPwdExists": "Vous ne pouvez pas réutiliser un ancien mot de passe",
  "lblPwdExpired": "Votre mot de passe est expiré, veuillez le renouveler en cliquant sur le lien \"Changer de mot de passe",
  "lblPwdRecorded": "Mot de passe enregistré",
  "lblPwdRecoveryLegend": "RECUPERATION DE MOT DE PASSE",
  "LblPwdSpecialChar": "Le mot de passe doit contenir a minima {0} caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial !*@#$%^+=",
  "lblRackRented": "Valeur locative de marché",
  "lblRangeErrorYield": "Le taux de rendement attendu doit être entre 0 et 1",
  "lblRaw": "Brut",
  "lblRealEstatePortfolio": "Sous-jacent immobilier (Société-mère et société-filles)",
  "lblRebillings": "Refacturations (€)",
  "lblReceiveNotification": "Recevoir une alerte lorsque :",
  "lblReconciliationSettlement": "Régularisation de charges (€)",
  "lblReferenceYear": "Année de référence",
  "lblRegularCharges": "Eléments de facturation",
  "lblRegularChargesCode": "Code élément de facturation",
  "lblRegularChargesExpenses": "Charges à exclure",
  "lblRegularChargesExpensesExcluded": "Charges à exclure",
  "lblRegularChargesLabel": "Libellé élément de facturation",
  "lblRegularChargesOtherIncomes": "Autres revenus à exclure",
  "lblRegularChargesOtherIncomesExcluded": "Autres revenus à exclure",
  "lblRegularChargesRents": "Loyers à exclure",
  "lblRegularChargesRentsExcluded": "Loyers à exclure",
  "lblRegularChargesWorks": "Travaux à exclure",
  "lblRegularChargesWorksExcluded": "Travaux à exclure",
  "lblRelettings": "Relocations (€)",
  "lblReloadExplanations": "L’écran Rechargement des données est un écran permettant de lancer manuellement :<BR/>  - un rechargement partiel des données de gestion afin de tenir compte de l’import manuel de données (données d’expertise, valeurs locatives de marché, valeurs nettes comptables)<BR/>  - un rechargement total des données de gestion afin de tenir compte de modifications apportées au Référentiel immeubles (modification du type d’immeuble, du portefeuille, du segment de marché immobilier, etc.) ou de modifications des données du bail (modification des contentieux).<BR/>  <BR/>  Le rechargement des données peut réduire temporairement la performance de l’application (lenteur de la navigation, échec des exports volumineux). Il est par conséquent recommandé de lancer le rechargement partiel ou total en dehors des périodes d’utilisation de l’application ou de prévenir à l’avance l’ensemble des utilisateurs.",
  "lblReloadPage": "Rechargement des données",
  "lblRELPage": "Rechargement des données",
  "lblRememberPassword": "Mémoriser le mot de passe ?",
  "lblRentalIncome": "Revenus locatifs",
  "lblRentalIncomeMonitoring": "Suivi du quittancement",
  "lblRentalIncomeMonitoringCompare": "Comparaison du quittancement",
  "lblRentalIncomeMonitoringDetailed": "Suivi du quittancement",
  "lblRentalSituation": "Situation locative détaillée",
  "lblRentalVacantArea": "Surface vacante disponible (m²)",
  "lblRentalVacantAreaRate": "% de la surface vacante totale",
  "lblRentDecreasedPlural": "loyers ont diminué",
  "lblRentDecreasedSingular": "loyer a diminué",
  "lblRentedArea": "Surface occupée (m²)",
  "lblRentedArea_WOU": "Surface occupée",
  "lblRentEvolution1Y": "Evolution des loyers sur un an",
  "lblRentIncreasedPlural": "loyers ont augmenté",
  "lblRentIncreasedSingular": "loyer a augmenté",
  "lblRents": "Loyers",
  "lblRents_WOU": "Loyer facial brut",
  "lblRentVariationRate": "Taux de revalorisation du loyer",
  "lblReportGenerationFailed": "Alhambra - Echec de la génération du rapport : {0}",
  "lblReportGenerationFailedBody": "Bonjour,      Malheureusement la génération de votre rapport {0} n'a pas pu aboutir.  Veuillez réessayer un peu plus tard.      Cordialement,    L'équipe Alhambra",
  "lblReportInterfaceSequencesHistory": "Historique des envois de l'interface rapport",
  "lblReportLaunchInBackgroundMessage": "Un mail vous sera envoyé quand le rapport sera prêt à être téléchargé !",
  "lblReportName": "Rapport standard correspondant",
  "lblReportReadyToDownload": "Alhambra - Rapport prêt à télécharger : {0}",
  "lblReportReadyToDownloadBody": "Bonjour,    Pour télécharger votre rapport :  1) Vérifier qu'Alhambra est toujours ouvert  2) Copier-coller le lien suivant dans la barre d'adresse de votre navigateur :  {0}    Attention : Une fois téléchargé, le rapport sera effacé sur le serveur.    Cordialement,    L'équipe Alhambra",
  "lblReportTemplateManagement": "Gestion des modèles de rapport",
  "lblReportTemplateSaved": "<P>Votre modèle de rapport [...] a bien été créé et ajouté à la liste des rapports disponibles.</P><P>Il pourra être mis à jour ou supprimé à partir du menu Gestion des modèles.</P>",
  "lblReportTemplateUpdated": "Votre modèle de rapport [...] a bien été modifié.",
  "lblRepositoryArbitrageSaved": "Votre cession [...] a bien été créé dans le référentiel.",
  "lblRepositoryArbitrageUpdated": "Votre cession [...] a bien été modifié dans le référentiel.",
  "lblRepositoryBuildingReport": "Référentiel immeubles",
  "lblRepositoryBuildingSaved": "Votre immeuble [...] a bien été créé dans le référentiel.",
  "lblRepositoryBuildingUpdated": "Votre immeuble [...] a bien été modifié dans le référentiel.",
  "lblRepositoryFundReport": "Référentiel société",
  "lblRepositoryListing": "Référentiel immeubles",
  "lblRepositoryPurchaseSaved": "Votre acquisition [...] a bien été créé dans le référentiel.",
  "lblRepositoryPurchaseUpdated": "Votre acquisition [...] a bien été modifié dans le référentiel.",
  "lblResidential": "Résidentiel",
  "lblResidentialBreakMonths": "Afficher en surbrillance rouge la ligne lorsqu'un bail résidentiel arrive à échéance dans les",
  "lblResidentialCareIdFKML": "Etablissements pour personnes âgées (Île-de-France)",
  "lblResidentialEuro": "Résidentiel (€)",
  "lblResidentialLease": "Bail d'habitation (Loi de 1989)",
  "lblResidentialLeaseendMonths": "Afficher en surbrillance rouge la ligne lorsqu'un bail résidentiel expire dans les",
  "lblResidentialLeaseEuro": "Bail d'habitation (Loi de 1989) (€)",
  "lblResidentialLeasePlural": "baux residentiels",
  "lblResidentialLeaseSingular": "bail résidentiel",
  "lblRESPage": "Situation locative détaillée",
  "lblRESRawReport": "Situation locative détaillée - brut",
  "lblRESReport": "Situation locative détaillée",
  "lblRestartEmailBody": "Bonjour,      Il s’agit du dernier jour de l’interruption des envois.       Les envois de l’interface reprendront donc demain.      Cordialement,      L'équipe Alhambra",
  "lblRestartEmailSubject": "Alhambra - Avertissement de la reprise des envois",
  "lblResults": "Résultat",
  "lblRetailBreakMonths": "Afficher en surbrillance rouge la ligne lorsqu'un bail commercial arrive à échéance dans les",
  "lblRetailLease": "Bail commercial",
  "lblRetailLeaseendMonths": "Afficher en surbrillance rouge la ligne lorsqu'un bail commercial expire dans les",
  "lblRetailOutlets": "Commerces",
  "lblReturnOnEquityLevered": "Retour sur capitaux employés (ROCE)",
  "lblReturnOnEquityUnlevered": "Retour sur fonds propres (ROE)",
  "lblReversionaryYield": "Taux théorique",
  "lblRIMDetailledRawReport": "Suivi du quittancement - brut",
  "lblRIMDetailledReport": "Suivi du quittancement",
  "lblRIMPage": "Suivi du quittancement",
  "lblRIMRawReport": "Comparaison du quittancement - brut",
  "lblRIMReport": "Comparaison du quittancement",
  "lblRisk": "Risques",
  "lblRLEPage": "Gestion des données du bail",
  "lblRotationRate": "Taux de rotation",
  "lblRPAPage": "Gestion des cessions",
  "lblRPBPage": "Référentiel immeubles",
  "lblRPFPage": "Référentiel fonds / sociétés",
  "lblRPMPage": "Gestion des VLM",
  "lblRPNPage": "Gestion des VNC",
  "lblRPPPage": "Gestion des acquisitions",
  "lblRPTPage": "Gestion des modèles de rapport",
  "lblRPVPage": "Gestion des données d'expertise",
  "lblRPYPage": "Gestion des taux de rendement",
  "lblRRENonOperating": "Immobilier résidentiel (hors exploitation)",
  "lblRunningTotalOfRentIncomByCollectionPeriod": "Répartition des encaissements par délai cumulé de recouvrement",
  "lblSales": "Cessions",
  "lblSaleTitle": "Cessions en cours",
  "lblSaleVacantArea": "Surface vacante pour vente (m²)",
  "lblSaleVacantAreaRate": "% de la surface vacante totale",
  "lblSamePassword": "Le nouveau mot de passe doit être différent du mot de passe actuel",
  "lblSaturday": "Samedi",
  "lblSDGPage": "Etat des DG et des cautions",
  "lblSDGRawReport": "Etat des DG et des cautions - brut",
  "lblSDGReport": "Etat des DG et des cautions",
  "lblSeachInTheGridColumns": "Rechercher le contenu suivant dans les colonnes ci-dessous (texte uniquement)",
  "lblSector": "Marché immobilier",
  "lblSectorCode": "Code du secteur",
  "lblSectorCode_required": "Code du secteur obligatoire",
  "lblSectorConcentration": "Concentration sectorielle (top 5 valeur vénale)",
  "lblSectorConentrationRentArea": "Concentration sectorielle (top 5 surface totale)",
  "lblSectorErv": "Marché immobilier (requis pour l'utilisation de la VLM)",
  "lblSectorLabel": "FR_SECTOR_LABEL",
  "lblSectorNameDe": "Nom du secteur (allemand)",
  "lblSectorNameDe_required": "Nom du secteur (allemand) obligatoire",
  "lblSectorNameEn": "Nom du secteur (anglais)",
  "lblSectorNameEn_required": "Nom du secteur (anglais) obligatoire",
  "lblSectorNameFr": "Nom du secteur (français)",
  "lblSectorNameFr_required": "Nom du secteur (français) obligatoire",
  "lblSecuritDepositsGuarantees": "Etat des DG et des cautions",
  "lblSecurityDepositBankAccount": "Compte bancaire Dépôt de garantie",
  "lblSecurityDepositsGuarantees": "Etat des DG et des cautions",
  "lblSeeBuilding": "Voir l'immeuble",
  "lblSelect4Indicators": "Sélectionner jusqu'à 4 indicateurs",
  "lblSelectAccount": "Sélectionner un compte",
  "lblSelectACode": "Sélectionner un code",
  "lblSelectActivesLeases": "Actifs seulement",
  "lblSelectAdvancePayAmount": "Montant des trop perçus >= (K€) :",
  "lblSelectAllLeases": "Actifs et sortis",
  "lblSelectAMapping": "Aucun mapping",
  "lblSelectAreaSup": "Surface occupée >= (m²) :",
  "lblSelectBaseReport": "Sélectionner un modèle de rapport standard",
  "lblSelectedParams": "Critères sélectionnés",
  "lblSelectedRaport": "Vous devez selectionner un rapport",
  "lblSelectEffectiveNetInitYield": "Taux initial effectif net >= (%) :",
  "lblSelectHeadlineNetInitYield": "Taux initial facial net >= (%) :",
  "lblSelectInactivesLeases": "Sortis seulement",
  "lblSelectInLitigationLeases": "En contentieux seulement",
  "lblSelectKML": "Afficher des données spatiales supplémentaires :",
  "lblSelectLeaseActiveAll": "Actifs et sortis soldés et non soldés",
  "lblSelectLeaseActiveWithOutstandingPayments": "Actifs non soldés",
  "lblSelectLeaseAllWithOutstandingPayments": "Actifs et sortis non soldés",
  "lblSelectLeaseDate": "Afficher les baux",
  "lblSelectLeaseDateBreak": "A échéance (échéance triennale et expiration)",
  "lblSelectLeaseDateEnd": "A expiration uniquement",
  "lblSelectLeaseInactiveWithOutstandingPayments": "Sortis non soldés",
  "lblSelectLeaseInLitigation": "Non soldés en contentieux",
  "lblSelectLeaseWithoutTenantExitDate": "Les baux non sortants uniquement",
  "lblSelectLeaseWithTenantExitDate": "Les baux sortants uniquement",
  "lblSelectOneYear": "La période sélectionnée ne doit pas dépasser une année.",
  "lblSelectPaymentsAmount": "Montant d'impayés >= (K€) :",
  "lblSelectPaymentsMonths": "Ancienneté des impayés >= (mois) :",
  "lblSelectPortfolio": "Sélectionner un portefeuille",
  "lblSelectPortfolios": "Sélectionner les portefeuilles",
  "lblSelectRawReportTemplateColumns": "Sélectionner les colonnes du modèle et modifier leur ordre par glisser-déposer",
  "lblSelectRentAmountSup": "Loyer facial >= (€/an) :",
  "lblSelectRentVariationRate": "Taux de revalorisation >= (%) :",
  "lblSelectReport": "Choisissez un rapport",
  "lblSelectReportTemplateColumns": "Sélectionner les colonnes du modèle",
  "lblSelectTwoYear": "La période sélectionnée ne doit pas dépasser deux ans.",
  "lblSelectVacancyAreaSup": "Surface vacante >= (m²) :",
  "lblSelectVacancyMonths": "Durée de la vacance >= (mois) :",
  "lblSelectVacancyRateSup": "Taux de vacance >= (%) :",
  "lblSeller": "Vendeur",
  "lblSendGED": "Vous allez bientôt recevoir un mail avec les liens vers la GED",
  "lblServiceCharge": "Provisions pour charges (€)",
  "lblShareAcquisitionPrice": "Prix de revient des titres (€)",
  "lblShareCapital": "Capital (€)",
  "lblShareholderCurrentAccount": "N° du compte courant (si détenteur)",
  "lblShareholderCurrentAccountEquityFinancing": "Compte courant (€)",
  "lblShareholderLoanAccount": "N° du compte de prêt d'associé (si détenteur)",
  "lblShareholderLoansContributed": "Prêts d'associés (€)",
  "lblSharePremium": "Prime d'émission (€)",
  "lblSharePremiumDistributed": "Distribution de prime d'émission (€)",
  "lblShareWithOtherUsers": "Partager avec les autres utilisateurs",
  "lblShortTermLease": "Bail dérogatoire",
  "lblShortTermLeaseEuro": "Bail dérogatoire (€)",
  "lblShowFilters": "Afficher les filtres",
  "lblShowGraph": "Afficher l'entête",
  "lblShowList": "Afficher la liste",
  "lblShowMap": "Afficher l'entête",
  "lblShowParams": "Montrer les paramètres",
  "lblSigningDate": "Date de signature de l'acte de vente",
  "lblSimplifiedPAndL": "Compte de résultat simplifié",
  "lblSocialAccountsType": "Sociaux",
  "lblSourceCode": "Code source",
  "lblSourceLabel": "Libellé source",
  "lblSqm": "m²",
  "lblStartDate": "Date de début",
  "lblStartDateEmpty": "Date de début vide, {0} lignes rejetées.",
  "lblStatus": "Statut",
  "lblStatutoryInterests": "Intérêts statutaires (€)",
  "lblStopSendingBetween": "au",
  "lblStopSendingEnd": "inclus",
  "lblStopSendingStart": "Interrompre les envois du",
  "lblStorageDistribution": "Logistique",
  "lblStudentHousingIdFKML": "Résidences étudiantes depuis 2004 (Île-de-France)",
  "lblSubsidiaryOfGroup": "Filiale du groupe",
  "lblSubsidiaryOfGroup_required": "Groupe obligatoire",
  "lblSunday": "Dimanche",
  "lblSupThreeMonth": "> 3 mois",
  "lblTax": "Fiscalité",
  "lblTaxeType": "Affichage des montants",
  "lblTaxeTypeHT": "HT",
  "lblTaxeTypeTTC": "TTC",
  "lblTEMPage": "Etat des mouvements locataires",
  "lblTemplateName": "Saisir le nom du modèle",
  "lblTemplateNameAlreadyExists": "Le nom du modèle est déjà attribué",
  "lblTemplateNameRequired": "Veuillez saisir un nom",
  "lblTemporaryOccupancyAuthorisation": "Autorisation d'occupation temporaire",
  "lblTemporaryOccupancyAuthorisationEuro": "Autorisation d'occupation temporaire (€)",
  "lblTempPwd": "Code temporaire",
  "lblTempPwdExpired": "Code temporaire expiré",
  "lblTempPwdRecorded": "Mot de passe enregistré",
  "lblTempPwdRequired": "Veuillez remplir le code temporaire",
  "lblTempPwdWrongFormat": "Code temporaire incorrect",
  "lblTEMRawReport": "Etat des mouvements locataires - brut",
  "lblTEMReport": "Etat des mouvements locataires",
  "lblTenancyAtWillLease": "Bail précaire",
  "lblTenancyAtWillLeaseEuro": "Bail précaire (€)",
  "lblTenancyMonitoring": "Etat des mouvements locataires",
  "lblTenancyMonitoringEnd": "Etat des sorties",
  "lblTenancyMonitoringEndRaw": "Etat des sorties - brut",
  "lblTenancyMonitoringNotice": "Etat des congés",
  "lblTenancyMonitoringNoticeRaw": "Etat des congés - brut",
  "lblTenancyMonitoringReletting": "Etat des relocations",
  "lblTenancyMonitoringRelettingRaw": "Etat des relocations - brut",
  "lblTenancyMonitoringRenewal": "Etat des renouvellements",
  "lblTenancyMonitoringRenewalRaw": "Etat des renouvellements - brut",
  "lblTenancySchedule": "Etat locatif",
  "lblTenancyScheduleAlerts": "Alertes état locatif",
  "lblTenant": "Nom du locataire",
  "lblTenantConcentration": "Concentration des locataires (top 5 loyer facial)",
  "lblTenantConentrationRentArea": "Concentration des locataires (top 5 surface occupée)",
  "lblTenantExitAnnualRentAmount": "Loyer facial des baux sortants",
  "lblTenantExitCount": "Nombre de baux sortants",
  "lblTenantExitRentArea": "Surface des baux sortants",
  "lblTenantIndustryConcentration": "Concentration des secteurs d'activité (top 5 loyer facial)",
  "lblTenantIndustryConecentrationRentArea": "Concentration des secteurs d'activité (top 5 surface occupée)",
  "lblTenantTurnover": "Suivi du taux de rotation",
  "lblTerminatedLeasesLegend": "* Baux sortis",
  "lblTerminationDate": "Date de dissolution ou de cession",
  "lblTETPage": "Suivi du taux de rotation",
  "lblTETRawReport": "Suivi du taux de rotation brut",
  "lblTETReport": "Suivi du taux de rotation",
  "lblThemeBadElement": "L’élément {1} n’est pas concerné par le thème 2 {0} lignes rejetées.",
  "lblThemeBadLevel": "Le thème « {1} » doit être de niveau 2 {0} lignes rejetées.",
  "lblThemeNotExist": "Le thème « {1} » n’existe pas, {0} lignes rejetées.",
  "lblThursday": "Jeudi",
  "lblTotalArea": "Surface totale (m²)",
  "lblTotalArea_WOU": "Surface totale",
  "lblTotalCashDistributed": "Total du cash distribué (€)",
  "lblTotalDataReloading": "Les données du property manager {0} seront rechargées cette nuit à {1}.",
  "lblTotalEquityContributed": "Total fonds propres investis (€)",
  "lblTotalEquityFinancing": "Total des capitaux employés (€)",
  "lblTotalGrossDebt": "Total capital restant dû (€)",
  "lblTotalInverstorDistributions": "Total des revenus distribués (€)",
  "lblTotalInvestmentPrice": "Fonds propres investis (€)",
  "lblTotalInvestmentValue": "Fonds propres réévalués (€)",
  "lblTotalInvestorDistributions": "Total des revenus (€)",
  "lblTotalRentAreaBreakByGeo": "Répartition de la surface totale par zone géographique",
  "lblTotalRentAreaBreakByPropType": "Répartition de la surface totale par type d'immeuble",
  "lblTotalRentAreaBreakByTenant": "Répartition du patrimoine par locataire (en surface occupée)",
  "lblTotalRentAreaBreakByTenantIndustry": "Répartition de la surface occupée par locataire",
  "lblTotalReturn": "Rendement global investisseur",
  "lblTotalReturnEquityFairValue": "Rendement global sur fonds propres réévalués",
  "lblTotalReturnNAVBridge": "Rendement global sur ANR",
  "lblTotals": "Total",
  "lblTransactionsHistory": "Historique des transactions",
  "lblTranscoding_type_data": "Type de données à paramétrer",
  "lblTranscodingType": "Code de la Liste",
  "lblTRHArbRawReport": "Historique des cessions - brut",
  "lblTRHArbReport": "Historique des cessions",
  "lblTRHPage": "Historique des transactions",
  "lblTRHPurchRawReport": "Historique des acquisitions - brut",
  "lblTRHPurchReport": "Historique des acquisitions",
  "lblTrue": "Oui",
  "lblTSBuildingRawReport": "Etat locatif immeuble - brut",
  "lblTSBuildingReport": "Etat locatif immeuble",
  "lblTSLeaseRawReport": "Etat locatif bail - brut",
  "lblTSLeaseReport": "Etat locatif bail",
  "lblTSMixedRawReport": "Etat locatif mixte - brut",
  "lblTSMixedReport": "Etat locatif mixte",
  "lblTSPage": "Etat locatif",
  "lblTSUnitDetailedRawReport": "Etat locatif ventilé par lot - brut",
  "lblTSUnitDetailedReport": "Etat locatif ventilé par lot",
  "lblTSUnitRawReport": "Etat locatif lot - brut",
  "lblTSUnitReport": "Etat locatif lot",
  "lblTSUnitTypeRawReport": "Etat locatif type de lot - brut",
  "lblTSUnitTypeReport": "Etat locatif type de lot",
  "lblTuesday": "Mardi",
  "lblTypeInterface": "Type d'interface",
  "lblUnderlyingVehicles": "Société(s)-fille(s)",
  "lblUnderRented": "Réversion négative",
  "lblUnit": "Lot",
  "lblUnitNatures": "Type de lots",
  "lblUnitNumber": "Nombre de lots",
  "lblUnitTypes": "Type de lot",
  "lblUnlawfulOccupancy": "Occupation sans titre",
  "lblUnlawfulOccupancyEuro": "Occupation sans titre (€)",
  "lblUnrealCapitalGainLossDirectInvestProp": "Plus-ou-moins-values latentes sur les immeubles (€)",
  "lblUnrealCapitalGainLossEquitySecur": "Plus-ou-moins-values latentes sur les titres de participation (€)",
  "lblUnrealCapitalGainLossFinancialInstruments": "Plus-ou-moins-values latentes sur les instruments financiers",
  "lblUnrealizedGainsLossesOnShares": "PMV latentes sur les titres (€)",
  "lblUnrealizedInvestmentPropertyGainLoss": "PMV latentes sur les immeubles (€)",
  "lblUnrealizedNonPropertyGainLoss": "PMV latentes sur les titres (€)",
  "lblUpcomingLeaseEvents": "Baux à échéance",
  "lblUpdateAdditionalField": "Mise à jour du thème",
  "lblUpdateBuilding": "Mettre à jour un immeuble",
  "lblUpdateBuildingRepository": "Modifier un immeuble dans le référentiel",
  "lblUpdatedAt": "Modifié le",
  "lblUpdatedAt2": "Modifiée le",
  "lblUpdateErvError": "Erreur pendant la mise à jour des VLM",
  "lblUpdateErvOk": "VLM mises à jour avec succès",
  "lblUpdateFund": "Modifier la société",
  "lblUpdateFundOwnershipError": "Erreur pendant la mise à jour des données détenteurs",
  "lblUpdateLeaseRepositoryError": "Erreur pendant la mise à jour des baux",
  "lblUpdateNetBookValueError": "Erreur pendant la mise à jour des VNC",
  "lblUpdatePurchase": "Modifier l'acquisition",
  "lblUpdateReportTemplate": "Modifier le modèle de rapport",
  "lblUpdateSales": "Mettre à jour la vente",
  "lblUpdateUser": "Mise à jour d'un utilisateur",
  "lblUpdateValuationError": "Erreur pendant la mise à jour des expertises",
  "lblUpdateValuationOk": "Valeurs d'expertise mises à jour",
  "lblUpdateYieldError": "Erreur pendant la mise à jour des taux de rendements attendus",
  "lblUpdateYieldOk": "Taux de rendements attendus mis à jour",
  "lblUserLocked": "Verrouillé",
  "lblUserLogin": "Login",
  "lblUserMail": "Mail",
  "lblUserManagement": "Gestion des utilisateurs",
  "lblUserName": "Nom d'utilisateur",
  "lblUserNameRequired": "Nom d'utilisateur obligatoire",
  "lblUserRejected": "La création de l'utilisateur a été annulée. Vérifiez votre saisie et essayer de nouveau. Si le problème persiste, veuillez contacter votre administrateur.",
  "lblUSLPage": "Gestion des utilisateurs",
  "lblVACAnaRawReport": "Etat d'occupation - brut",
  "lblVACAnaReport": "Etat d'occupation",
  "lblVacancy": "Analyse de la vacance",
  "lblVacancyAnalysis": "Etat d'occupation",
  "lblVacancyArea": "Surface vacante",
  "lblVacancyBreakByPeriod": "Répartition de la surface vacante par durée de vacance",
  "lblVacancyBreakdownByPeriod": "Répartition de la vacance par période",
  "lblVacancyCost": "Vacance financière",
  "lblVacancyEvolution": "Evolution trimestrielle de l'état d'occupation",
  "lblVacancyEvolutionOneYear": "Evolution de la vacance sur un an",
  "lblVacancyFinancial": "€",
  "lblVacancyNature": "Répartition de la vacance par type",
  "lblVacancyOccupEvolution": "Répartition de la vacance par origine de la vacance",
  "lblVacancyPhysicalPercent": "%",
  "lblVacancyPhysicalSqm": "m²",
  "lblVacancyRate": "Taux de vacance physique",
  "lblVacancyTypes": "Type de vacance",
  "lblVacancyUnit": "Nombre de lots vacants",
  "lblVacantArea": "Surface vacante (m²)",
  "lblVacantArea_WOU": "Surface vacante",
  "lblVacantArea2M": "Surface vacante <=2 mois (m²)",
  "lblVacantArea4M": "Surface vacante <=4 mois (m²)",
  "lblVacantAreaSup4M": "Surface vacante > 4 mois (m²)",
  "lblVacantTenantCode": "--VACANT--",
  "lblVacantUnits": "Etat des lots vacants",
  "lblVacantUnitSingular": "lot vacant",
  "lblVacantUnitsLegend": "* Lots vacants ou inactifs",
  "lblVacantUnitsPlural": "lots vacants",
  "lblVacArea2-4M": "Surface vacante 2-4 mois (m²)",
  "lblVacArea2M": "Surface vacante < 2 mois (m²)",
  "lblVacArea4M": "Surface vacante > 4 mois (m²)",
  "lblVACNatRawReport": "Répartition de la vacance par type - brut",
  "lblVACNatReport": "Répartition de la vacance par type",
  "lblVACPage": "Analyse de la vacance",
  "lblVACPhyRawReport": "Suivi mensuel de la vacance - brut",
  "lblVACPhyReport": "Suivi mensuel de la vacance",
  "lblValuation": "Gestion des données d'expertise",
  "lblValuationDashboard": "Valorisation",
  "lblValuationImportsList": "Historique des imports",
  "lblValue": "Valeur vénale nette (€)",
  "lblValueDate": "Date de valeur",
  "lblValuePurchase": "Valeur d'acquisition totale (€)",
  "lblVAUPage": "Etat des lots vacants",
  "lblVAURawReport": "Etat des lots vacants - brut",
  "lblVAUReport": "Etat des lots vacants",
  "lblVehicleNetAssetValue": "VALORISATION DE LA SOCIETE (100%)",
  "lblVehicleTerm": "Durée de la société (en années)",
  "lblVehiculeFormationDate": "Date du pacte d'associés",
  "lblWALB": "WALB",
  "lblWALBAndWALT": "WALB / WALT",
  "lblWALBInYears": "WALB (en années)",
  "lblWALT": "WALT",
  "lblWALTInYears": "WALT (en années)",
  "lblWednesday": "Mercredi",
  "lblWelcomeTitle": "Bienvenue sur Alhambra",
  "lblWorksVacantArea": "Surface vacante pour travaux (m²)",
  "lblWorksVacantAreaRate": "% de la surface vacante totale",
  "lblWrongCurrentPassword": "Mot de passe actuel incorrect",
  "lblXLinesInserted": "{0} lignes ont été ajoutées.",
  "lblXLinesRejected": "{0} lignes ont été rejetées.",
  "lblXLinesUpdated": "{0} lignes ont été mises à jour.",
  "lblXValuesInserted": "{0} valeurs ont été ajoutées.",
  "lblXValuesRejected": "{0} valeurs ont été rejetées.",
  "lblYearPeriod1": "an(s) (période 1)",
  "lblYearPeriod2": "an(s) (période 2)",
  "lblYearPeriod3": "an(s) (période 3)",
  "lblYearToDateInvoicingRunningTotal": "Quittancement cumulé Year-to-Date",
  "lblYes": "Oui",
  "lblYield": "Gestion des taux de rendement",
  "lblYieldImportsList": "Historique des imports",
  "lblYieldMonitoring": "Suivi des rendements",
  "lblYIMPage": "Suivi des rendements",
  "lblYIMRawReport": "Suivi des rendements - brut",
  "lblYIMReport": "Suivi des rendements",
  "lblyyyyMMdd": "AAAAMMDD",
  "lblyyyyMMdd_hhmmss": "AAAAMMDD_HHMMSS",
  "lblZACIdFKML": "Zones d'aménagement concerté (ZAC)",
  "lbNewAccountStream": "Nouvelle interface Comptable",
  "lbNewAdditionalField": "Nouveau thème",
  "lbnumeric": "Numérique",
  "lbOccupiedAreaBreakByTenant": "Répartition de la surface occupée par locataire",
  "lbOutstandingAmountEvolution": "Evolution des encaissements locataires",
  "lbOutstandingBalanceEvolution": "Evolution du solde locataires",
  "lbPage": "Page",
  "lbPositional": "Positionnel",
  "lbPurchaseConfirmCode": "Please confirm the building code",
  "lbReportTemplateDuplicated": "Votre modéle de rapport [...] a bien été dupliquée en [....]",
  "lbSearchCriteria": "Critères de recherche",
  "lbSeparatorDecimal": "Séparateur décimal",
  "lbSpace": "Espace",
  "lbTotalDistribution": "Distribution totale",
  "lbTtResetCriteria": "Réinitialiser les critères",
  "lbTtUpdateCriteria": "Actualiser les critères",
  "LEASE": "Bail",
  "LEASE BREAK DATE 1": "Date de break 1",
  "LEASE BREAK DATE 2": "Date de break 2",
  "LEASE BREAK DATE 3": "Date de break 3",
  "LEASE BREAK PERIOD 1": "Durée du préavis 1 (mois)",
  "LEASE BREAK PERIOD 2": "Durée du préavis 2 (mois)",
  "LEASE BREAK PERIOD 3": "Durée du préavis 3 (mois)",
  "LEASE BREAKDOWN BY NATURE": "Répartition des baux par type",
  "LEASE CODE": "Code bail",
  "LEASE COMMENTS": "Commentaires",
  "LEASE COMMITMENT TERM": "Durée ferme d'engagement",
  "LEASE DURATION DD/MM/YYYY": "Durée du bail (a-m-j)",
  "LEASE END DATE": "Date de fin du bail",
  "LEASE ENTERPRISE SCHEDULE": "Echéancier des baux commerciaux au",
  "LEASE FLOORS": "N° du ou des étages",
  "LEASE MONITORING": "Suivi des baux au",
  "LEASE MONITORING SHEET": "Suivi des baux",
  "LEASE NATURE LABEL": "Nature de bail",
  "LEASE PAYMENT MODE LABEL": "Mode de règlement",
  "LEASE RENEWAL REPORT": "Etat des renouvellements",
  "LEASE RENEWAL REPORT RAW": "Etat des renouvellements brut",
  "LEASE SIGNED DATE": "Date de signature du bail",
  "LEASE START DATE": "Date de début du bail",
  "LEASE SUMMARY": "Résumé du bail",
  "LEASE TENANCY SCHEDULE": "Etat locatif par bail au",
  "LEASE TENANCY SCHEDULE SHEET": "Etat locatif par bail",
  "LEASE TERM": "Durée du bail (a-m-j)",
  "LEASE TERMINATION NOTICE REPORT": "Etat des congés",
  "LEASE TERMINATION NOTICE REPORT RAW": "Etat des congés brut",
  "LEASE TERMINATION REPORT": "Etat des sorties",
  "LEASE TERMINATION REPORT RAW": "Etat des sorties brut",
  "LEASE TOTAL": "Total bail",
  "LEASE TURNOVER RATE": "Taux de rotation (sur les baux)",
  "LEASE TYPE LABEL": "Type de bail",
  "LEASE UNITS": "N° du ou des lots",
  "LEASE_BREAK_DATE_1": "Date de break 1",
  "LEASE_BREAK_DATE_2": "Date de break 2",
  "LEASE_BREAK_DATE_3": "Date de break 3",
  "LEASE_BREAK_PERIOD_1": "Durée du préavis 1 (mois)",
  "LEASE_BREAK_PERIOD_2": "Durée du préavis 2 (mois)",
  "LEASE_BREAK_PERIOD_3": "Durée du préavis 3 (mois)",
  "LEASE_CODE": "Code du bail",
  "LEASE_COMMENTS": "Commentaires",
  "LEASE_COMMITMENT_TERM": "Durée ferme d'engagement",
  "LEASE_DURATION": "Durée du bail (a-m-j)",
  "LEASE_END_DATE": "Date de fin du bail",
  "LEASE_FLOORS": "N° du ou des étages",
  "LEASE_NATURE_CODE": "Code nature du bail",
  "LEASE_NATURE_LABEL": "Nature du bail",
  "LEASE_PAYMENT_MODE_LABEL": "Mode de règlement",
  "LEASE_SIGNED_DATE": "Date de signature du bail",
  "LEASE_START_DATE": "Date de début du bail",
  "LEASE_TURNOVER_RATE": "Taux de rotation (sur les baux)",
  "LEASE_TYPE_CODE": "Code type de bail",
  "LEASE_TYPE_LABEL": "Type de bail",
  "LEASE_UNITS": "N° du ou des lots",
  "LEASES NUMBER": "Nombre de baux",
  "LEASES PERCENT": "% du nombre total de baux",
  "LEASES_ACTIVE": "Baux actifs",
  "LEASES_COUNT": "Nombre de baux",
  "LEASES_INACTIVE": "Baux inactifs",
  "LEASES_LITIGATION": "Baux en contentieux",
  "LEASES_NUMBER": "Nombre de baux",
  "LEASES_PERCENT": "% du nombre total de baux",
  "LEGAL PERSON": "Personnalité juridique",
  "LEGAL RESERVES": "Réserve légale (€)",
  "LEGAL VEHICLE STRUCTURE": "Forme juridique",
  "LEGAL_PERSON": "Personnalité juridique",
  "LEGAL_RESERVES": "Réserve légale (€)",
  "LEGAL_VEHICLE_STRUCTURE": "Forme juridique",
  "LEVEL": "Niveau",
  "LEVEL1 BUDGET SECTION": "Libellé de la rubrique budgétaire",
  "LEVEL1 BUDGET SECTION BRUT": "Budget niveau 1",
  "LEVEL1 BUDGET SECTION CODE": "Code de la rubrique budgétaire",
  "LEVEL1_BUDGET_SECTION": "Rubrique budgétaire",
  "LEVEL1_BUDGET_SECTION_CODE": "Code de la rubrique budgétaire",
  "LEVEL2 BUDGET SECTION": "Budget niveau 2",
  "LEVEL2 BUDGET SECTION 1": "Libellé budget niveau 2",
  "LEVEL2 BUDGET SECTION CODE": "Catégorie budgétaire",
  "LEVEL2 BUDGET SECTION CODE 1": "Code budget niveau 2",
  "LEVEL2_BUDGET_SECTION": "Budget niveau 2",
  "LEVEL2_BUDGET_SECTION_1": "Libellé budget niveau 2",
  "LEVEL2_BUDGET_SECTION_CODE": "Code catégorie budgétaire",
  "LEVEL2_BUDGET_SECTION_CODE_1": "Code budget niveau 2",
  "LEVEL3 BUDGET SECTION": "Budget niveau 3",
  "LEVEL3 BUDGET SECTION 1": "Libellé budget niveau 3",
  "LEVEL3 BUDGET SECTION CODE": "Code budget niveau 3",
  "LEVEL3_BUDGET_SECTION": "Budget niveau 3",
  "LEVEL3_BUDGET_SECTION_1": "Libellé budget niveau 3",
  "LEVEL3_BUDGET_SECTION_CODE": "Code budget niveau 3",
  "LEVEL4 BUDGET SECTION": "Budget niveau 4",
  "LEVEL4 BUDGET SECTION CODE": "Code budget niveau 4",
  "LEVEL4_BUDGET_SECTION": "Budget niveau 4",
  "LEVEL4_BUDGET_SECTION_CODE": "Code budget niveau 4",
  "liAbe": "Mouvements bancaires",
  "liAllBuildings": "-- Tous les immeubles --",
  "Line2H3Title1": "Description immeuble",
  "Line3H3Title1": "Etat d'occupation",
  "Line3H3Title2": "Répartition des surfaces occupées par destination",
  "Line4H3Title2": "Charges d'exploitation (OPEX)",
  "Line5H3Title1": "Indicateurs de performance",
  "Line5H3Title2": "Taux de rendement",
  "Line5H3Title3": "Potentiel de réversion",
  "Line7H3Title1": "Evolution des revenus locatifs",
  "Line7H3Title2": "Evolution des charges d'exploitation (OPEX)",
  "Line8H3Title1": "Baux à échéance",
  "Line8H3Title2": "Suivi des travaux",
  "liNeutralizedBuildings": "Immeubles neutralisés",
  "linkChangePwd": "Changer le mot de passe",
  "linkForgottenPwd": "Mot de passe oublié ?",
  "liNotNeutralizedBuildings": "Immeubles non neutralisés",
  "LITIGATION AMOUNT": "Montant du contentieux (€)",
  "LITIGATION INFORMATION": "Commentaires sur le contentieux",
  "LITIGATION SITUATION LABEL": "Qualité individu",
  "LITIGATION STATUS LABEL": "Statut du contentieux",
  "LITIGATION TYPE LABEL": "Type de contentieux",
  "LITIGATION_AMOUNT": "Montant du contentieux (€)",
  "LITIGATION_INFORMATION": "Commentaires sur le contentieux",
  "LITIGATION_SITUATION_LABEL": "Qualité individu",
  "LITIGATION_STATUS_LABEL": "Statut du contentieux",
  "LITIGATION_TYPE_LABEL": "Type de contentieux",
  "LIVING AREA": "Surface habitable (m²)",
  "LIVING AREA WOU": "Surface habitable",
  "LIVING_AREA_SQM": "Surface utile m²",
  "lkb1Year": "1 an",
  "lkb3Year": "3 ans",
  "lkb6Year": "6 ans",
  "lkbDeSelectAll": "Tout désélectionner",
  "lkbEditPortfolio": "Editer les portefeuilles",
  "lkbModAlerts": "Modifier les alertes",
  "lkbSelectAll": "Tout sélectionner",
  "lnkClear": "Réinitialiser les critères",
  "lnLoginNameFormatString": "{0}",
  "LOAN TO VALUE": "Loan-To-Value (LTV)",
  "locAlertBuildingValueVariation": "significativement suite à la dernière évaluation",
  "locAlertLeaseBreak": "à échéance (option de break) prochainement",
  "locAlertLeaseExpiring": "arrivant prochainement à échéance (fin de bail)",
  "locAlertRentVariationInitialRent": "significativement par rapport au loyer initial",
  "locAlertRentVariationRentAdjustment": "significativement suite à la dernière révision",
  "locAlerts": "Alertes",
  "locAlertVacancy": "avec un taux de vacance significatif",
  "locAlertVacantUnit": "depuis plusieurs mois",
  "locEvolution": "Evolution sur",
  "locKeyValues": "Valeurs clés au",
  "locMyAssets": "Mon portefeuille",
  "locNews": "News",
  "locSeeMore": "Voir +",
  "LONGITUDE": "Longitude",
  "LOSS AFTER PDDI": "PMV après PDD (€)",
  "LOSS AFTER RESERVE": "PMV après provisions (€)",
  "LOSS BEFORE RESERVE": "PMV avant provisions (€)",
  "LOSS_AFTER_PDDI": "PMV après PDD (€)",
  "LOSS_AFTER_RESERVE": "PMV après provisions (€)",
  "LOSS_BEFORE_RESERVE": "PMV avant provisions (€)",
  "lsLoginStatusLogoutText": "Se déconnecter",
  "MAIN DEPOSIT VALUE": "Dépôt de garantie (€)",
  "MAIN MANAGER NAME": "Nom du gestionnaire",
  "MAIN UNIT CODE": "N° du lot principal",
  "MAIN UNIT TOTAL NUMBER": "Nombre de lots",
  "MAIN UNIT TYPE LABEL": "Type du lot principal",
  "MAIN_DEPOSIT_VALUE": "Dépôt de garantie (€)",
  "MAIN_MANAGER_NAME": "Nom du gestionnaire",
  "MAIN_UNIT": "N° du lot principal",
  "MAIN_UNIT_BUILDING": "N° du bâtiment",
  "MAIN_UNIT_FLOOR": "N° de l'étage",
  "MAIN_UNIT_NATURE_CODE": "Code de la nature du lot principal",
  "MAIN_UNIT_NATURE_LABEL": "Nature du lot principal",
  "MAIN_UNIT_STAIRCASE": "N° de l'escalier",
  "MAIN_UNIT_TOTAL_NUMBER": "Nombre de lots",
  "MAIN_UNIT_TOTAL_NUMBER_mismatch": "Nombre de lots format incorrect",
  "MAIN_UNIT_TYPE_CODE": "Code du type du lot principal",
  "MAIN_UNIT_TYPE_LABEL": "Type du lot principal",
  "MAJOR REFURBISHMENT AMOUNT": "Montant des gros travaux (hors article 606) (€)",
  "MAJOR_REFURBISHMENT_AMOUNT": "Montant des gros travaux (hors article 606) (€)",
  "MANAGER NAME": "Asset manager",
  "MANAGER_NAME": "Asset manager",
  "MANAGING DIRECTOR": "Gérant",
  "MANAGING_DIRECTOR": "Gérant",
  "MARCH_AMOUNT": "Mars (€)",
  "MARK_VALUE_EXCL_DUTIES_VAT_SQM": "Valeur vénale nette par m² (€/m²)",
  "MARKET VALUE": "Valeur vénale",
  "MARKET VALUE EXCL DUTIES AND FEES": "Valeur vénale nette",
  "MARKET VALUE EXCL DUTIES VAT": "Valeur vénale nette (€)",
  "MARKET VALUE INCL DUTIES AND FEES": "Valeur vénale brute",
  "MARKET_VALUE_EXCL_DUTIES_VAT": "Valeur vénale nette (€)",
  "MAX_NUMBER_OF_LEVELS": "Nombre maximum de niveaux",
  "MAX_NUMBER_OF_LEVELS_mismatch": "Nombre d'étages max format incorrect",
  "MAXIMUM NUMBER OF FLOORS": "Nombre d'étages max",
  "MAXIMUM NUMBER OF LEVELS": "Nombre maximum de niveaux",
  "MAY_AMOUNT": "Mai (€)",
  "menuAbe": "Mouvements bancaires",
  "menuAuditTrail": "Piste d'audit",
  "menuBudgetMonitoring": "Fiche budgétaire",
  "menuCollectionMonitoring": "Suivi des encaissements",
  "menuDeka": "Reporting Deka",
  "menuDirectOutstandingPayments": "Impayés",
  "menuDtz": "Situation locative",
  "menuGeneralExpensesReportAccounted": "RGD - Factures comptabilisées",
  "menuGeneralExpensesReportPaid": "RGD - Factures payées",
  "menuGER": "Rapport général de dépenses",
  "menuHoldoverTenancy": "Suivi des baux non renouvelés",
  "menuLasalleInterface": "Interface LASALLE",
  "menuLeaseEntrepriseSchedule": "Echéancier des baux commerciaux",
  "menuLeaseMonitoring": "Suivi des baux",
  "menuMyAccount": "Mon compte",
  "menuMyAssets": "Mon portefeuille",
  "menuMyReports": "Mes rapports",
  "menuOutstandingPaymentProvider": "Balance agée fournisseur",
  "menuOutstandingPayments": "Balance agée",
  "menuOutstandPayBalances": "Analyse des impayés",
  "menuOutstandPayMonitoring": "Suivi des impayés en date comptable",
  "MenuParam": "Paramètres",
  "menuPendingArbitrageTransactions": "Cessions en cours",
  "menuPendingPurchaseTransactions": "Acquisitions en cours",
  "menuPendingTransactions": "Transactions en cours",
  "menuPMR": "Tableau de bord ADB",
  "menuPortfolioBreakdown": "Répartition du patrimoine",
  "menuPortfolioEvolution": "Evolution du patrimoine immobilier",
  "menuPropertyBalance": "Balance immeuble",
  "menuPropertyValuation": "Valorisation des immeubles",
  "menuProviderInvoices": "Factures fournisseurs",
  "menuProviderInvoicesAccounted": "Factures fournisseurs comptabilisées",
  "menuProviderInvoicesPaid": "Factures fournisseurs payées",
  "menuReload": "Rechargement des données",
  "menuRentalIncomeMonitoring": "Suivi du quittancement",
  "menuRentalSituation": "Situation locative détaillée",
  "menuSecuritDepositsGuarantees": "Etat des DG et des cautions",
  "menuSecurityDepositsGuarantees": "Etat des DG et des cautions",
  "menuTenancyMonitoring": "Etat des mouvements locataires",
  "menuTenancySchedule": "Etat locatif",
  "menuTenantTurnover": "Suivi du taux de rotation",
  "menuTransactionsHistory": "Historique des transactions",
  "menuUsersList": "Gestion des utilisateurs",
  "menuVacancyAnalysis": "Analyse de la vacance",
  "menuVacantUnits": "Etat des lots vacants",
  "menuYieldMonitoring": "Suivi des rendements",
  "MGMT FEES OF GARB COLLECT TAX": "Frais de gestion de TOM (€)",
  "MGMT_FEES_OF_GARB_COLLECT_TAX": "Frais de gestion de TOM (€)",
  "MinDateHistory": "Ne pas envoyer d’écriture antérieure au",
  "MISCELLANEOUS AREA": "Surfaces diverses (m²)",
  "MISCELLANEOUS RESERVE": "Autres réserves (€)",
  "MISCELLANEOUS_AREA": "Surfaces diverses (m²)",
  "MISCELLANEOUS_RESERVE": "Autres réserves (€)",
  "Modification": "Modification",
  "MONTANT_DEJA_ENGAGE": "Montant déjà engagé (€)",
  "MONTANT_DEJA_FACTURE_REALISE": "Montant déjà facturé / réalisé",
  "MONTANT_PREVISIONNEL_DES_ENGAGEMENTS_EMIS_PAR_ANNEE": "Montant engagé",
  "MONTANT_PREVISIONNEL_DES_FACTURES_A_RECEVOIR_PAR_ANNEE": "Montant facturé",
  "MONTANT_TOTAL_PREVISIONNEL_CHANTIER": "Montant total prévisionnel chantier (€)",
  "MONTH ON MONTH SALES GROWTH": "Croissance du chiffre d'affaires mensuel",
  "MONTH ROLLING SALES VOLUME": "Chiffre d'affaires annuel glissant (€)",
  "MONTH_01_AMOUNT": "Montant mois 1ère colonne (€)",
  "MONTH_01_YYYY": "Janvier YYYY (€)",
  "MONTH_02_AMOUNT": "Montant mois 2ème colonne (€)",
  "MONTH_02_YYYY": "Février YYYY (€)",
  "MONTH_03_AMOUNT": "Montant mois 3ème colonne (€)",
  "MONTH_03_YYYY": "Mars YYYY (€)",
  "MONTH_04_AMOUNT": "Montant mois 4ème colonne (€)",
  "MONTH_04_YYYY": "Avril YYYY (€)",
  "MONTH_05_AMOUNT": "Montant mois 5ème colonne (€)",
  "MONTH_05_YYYY": "Mai YYYY (€)",
  "MONTH_06_AMOUNT": "Montant mois 6ème colonne (€)",
  "MONTH_06_YYYY": "Juin YYYY (€)",
  "MONTH_07_AMOUNT": "Montant mois 7ème colonne (€)",
  "MONTH_07_YYYY": "Juillet YYYY (€)",
  "MONTH_08_AMOUNT": "Montant mois 8ème colonne (€)",
  "MONTH_08_YYYY": "Août YYYY (€)",
  "MONTH_09_AMOUNT": "Montant mois 9ème colonne (€)",
  "MONTH_09_YYYY": "Septembre YYYY (€)",
  "MONTH_10_AMOUNT": "Montant mois 10ème colonne (€)",
  "MONTH_10_YYYY": "Octobre YYYY (€)",
  "MONTH_11_AMOUNT": "Montant mois 11ème colonne (€)",
  "MONTH_11_YYYY": "Novembre YYYY (€)",
  "MONTH_12_AMOUNT": "Montant mois 12ème colonne (€)",
  "MONTH_12_YYYY": "Décembre YYYY (€)",
  "MONTH_13_AMOUNT": "Montant mois 13ème colonne (€)",
  "MONTH_14_AMOUNT": "Montant mois 14ème colonne (€)",
  "MONTH_15_AMOUNT": "Montant mois 15ème colonne (€)",
  "MONTH_16_AMOUNT": "Montant mois 16ème colonne (€)",
  "MONTH_17_AMOUNT": "Montant mois 17ème colonne (€)",
  "MONTH_18_AMOUNT": "Montant mois 18ème colonne (€)",
  "MONTH_19_AMOUNT": "Montant mois 19ème colonne (€)",
  "MONTH_20_AMOUNT": "Montant mois 20ème colonne (€)",
  "MONTH_21_AMOUNT": "Montant mois 21ème colonne (€)",
  "MONTH_22_AMOUNT": "Montant mois 22ème colonne (€)",
  "MONTH_23_AMOUNT": "Montant mois 23ème colonne (€)",
  "MONTH_24_AMOUNT": "Montant mois 24ème colonne (€)",
  "MONTH_ON_MONTH_SALES_GROWTH": "Croissance du chiffre d'affaires mensuel MM/YYYY vs MM-1/YYYY",
  "MONTH_ROLLING_SALES_VOLUME": "Chiffre d'affaires annuel glissant (€)",
  "MONTHLY AMOUNT": "Montant mensuel (€/mois)",
  "MONTHLY MONITORING VACANCY": "Suivi mensuel de la vacance au",
  "MONTHLY OUTSTAND PAY RATE": "Taux d'impayés du mois",
  "MONTHLY SALES VOLUME": "Chiffre d'affaires mensuel (€/mois)",
  "MONTHLY SALES VOLUME M MINUS 1": "Chiffre d'affaires mensuel (€/mois)",
  "MONTHLY SALES VOLUME Y MINUS 1": "Chiffre d'affaires mensuel (€/mois)",
  "MONTHLY_OUTSTAND_PAY_RATE": "Taux d'impayés du mois",
  "MONTHLY_SALES_VOLUME": "Chiffre d'affaires mensuel MM/YYYY (€/mois)",
  "MONTHLY_SALES_VOLUME_M_MINUS_1": "Chiffre d'affaires mensuel MM-1/YYYY (€/mois)",
  "MONTHLY_SALES_VOLUME_Y_MINUS_1": "Chiffre d'affaires mensuel MM/YYYY-1 (€/mois)",
  "MOTIF_DE_L_INTERVENTION_DE_MAINTENANCE": "Motif de l'intervention de maintenance",
  "NameFile": "Nom du fichier",
  "NameFileAndADB": "Nom du fichier <br>-Nom de l’ADB",
  "NATURE": "Type d'immeuble",
  "NATURE TOTAL": "Total",
  "NB_FLAGGED_RECORDS": "Nombre d'enregistrements toppés",
  "NET ASSET VALUE": "Actif net réévalué (ANR) (€)",
  "NET ASSET VALUE ON SPV AND HOLDCO": "Actif net réévalué des titres (€)",
  "NET ASSET VALUE PER SHARE": "Valeur liquidative (€/part)",
  "NET ASSET VALUE REPORT": "Valorisation des sociétés",
  "NET ASSET VALUE TITLE": "Actif net réévalué (ANR)",
  "NET ASSET VALUE UNDERLYING VEHICLES": "Actif net réévalué des titres (€)",
  "NET BOOK VALUE": "Valeur nette comptable (€)",
  "NET BOOK VALUE AFTER IMPAIR": "PMV après provisions (€)",
  "NET BOOK VALUE AFTER PDDI": "VNC après dotation aux PDD (€)",
  "NET BOOK VALUE AFTER RESERVE": "VNC après provisions (€)",
  "NET BOOK VALUE BEFORE RESERVE": "VNC avant provisions (€)",
  "NET BOOK VALUE ON SPV AND HOLDCO": "Valeur nette comptable des titres (€)",
  "NET BOOK VALUE UNDERLYING VEHICLES": "Valeur nette comptable des titres (€)",
  "NET CAPITAL RATE": "Taux de capitalisation nets",
  "NET CHANGE IN CURRENT ACCOUNT": "Variation nette de compte courant (€)",
  "NET CHANGE IN SHARE CAPITAL": "Variation nette de capital (€)",
  "NET CHANGE IN SHARE PREMIUM": "Variation nette de prime d'émission (€)",
  "NET EFFECTIVE RENT": "Loyer effectif net (€/an)",
  "NET EFFECTIVE RENT WOU": "Loyer effectif net",
  "NET HEADLINE RENT": "Loyer facial net (€/an)",
  "NET HEADLINE RENT WOU": "Loyer facial net",
  "NET INCOME": "Résultat comptable (€)",
  "NET INCOME AFTER TAX": "Résultat comptable après IS (€)",
  "NET INCOME BEFORE TAX": "Résultat comptable avant IS (€)",
  "NET INITIAL YIELD": "Taux de rendement nets",
  "NET INITIAL YIELDS": "Taux de rendement",
  "NET INITIAL YIELDS SHEET": "Taux de rendement nets",
  "NET MARKET RENT": "Loyer net de marché (€/an)",
  "NET MARKET VALUE": "Valeur vénale nette (€)",
  "NET OPERATING INCOME": "Résultat d'exploitation (€/an)",
  "NET POTENTIAL RENT": "Loyer potentiel net (€/an)",
  "NET POTENTIAL RENTAL INCOME": "Revenus locatifs potentiels nets",
  "NET RENTAL INCOME": "Revenus locatifs nets",
  "NET RESULT": "Résultat comptable (€)",
  "NET RESULT AFTER TAX": "Résultat comptable après IS (€)",
  "NET RESULT BEFORE TAX": "Résultat comptable avant IS (€)",
  "NET RESULT RETURN ON GROSS BOOK VALUE": "Rendement comptable sur ABI",
  "NET RESULT RETURN ON GROSS BOOK VALUE OUT": "Rendement comptable hors cession sur ABI",
  "NET RESULT TAX": "Impôt sur les sociétés (€)",
  "NET REVERS CAPITAL RATE": "Taux de capitalisation théorique net",
  "NET REVERS POTENT OCCUP AREA": "Sur la surface occupée",
  "NET REVERS POTENT OCCUP AREA FULL": "Potentiel de réversion net sur la surface occupée",
  "NET REVERS POTENT TOTAL PROP": "Sur la surface totale",
  "NET REVERS POTENT TOTAL PROP FULL": "Potentiel de réversion net sur la surface totale",
  "NET REVERSIONARY POTENTIAL": "Potentiel de réversion",
  "NET REVERSIONARY YIELD": "Taux théorique net",
  "NET_ASSET_VALUE": "Actif net réévalué (ANR) (€)",
  "NET_ASSET_VALUE_ON_SPV": "Actif net réévalué des titres (€)",
  "NET_ASSET_VALUE_ON_SPV_AND_HOLDCO": "Actif net réévalué des titres (€)",
  "NET_ASSET_VALUE_PER_SHARE": "Valeur liquidative (€)",
  "NET_ASSET_VALUE_REPORT": "Valorisation des sociétés",
  "NET_BOOK_VALUE_AFTER_IMPAIR": "PMV après provisions (€)",
  "NET_BOOK_VALUE_AFTER_PDDI": "VNC après dotation aux PDD (€)",
  "NET_BOOK_VALUE_AFTER_RESERVE": "VNC après provisions (€)",
  "NET_BOOK_VALUE_BEFORE_RESERVE": "VNC avant provisions (€)",
  "NET_BOOK_VALUE_ON_SPV": "Valeur nette comptable des titres (€)",
  "NET_BOOK_VALUE_ON_SPV_AND_HOLDCO": "Valeur nette comptable des titres (€)",
  "NET_BOOK_VALUE-RELATED_DATA_SUCCESSFULLY_UPDATED": "Données relatives aux VNC mises à jour avec succès",
  "NET_EFFECTIVE_RENT": "Loyer effectif net (€/an)",
  "NET_FLOOR_AREA_SQM": "Surface au sol nette m²",
  "NET_HEADLINE_RENT": "Loyer facial net (€/an)",
  "NET_INCOME": "Résultat comptable (€)",
  "NET_INCOME_AFTER_TAX": "Résultat comptable après IS (€)",
  "NET_INCOME_BEFORE_TAX": "Résultat comptable avant IS (€)",
  "NET_MARKET_RENT": "Loyer net de marché (€/an)",
  "NET_MARKET_RENT_WOU": "Loyer net de marché",
  "NET_MARKET_VALUE": "Valeur vénale nette (€)",
  "NET_MARKET_VALUE_SQM": "Valeur vénale nette par m² (€/m²)",
  "NET_MARKET_VALUE_WOU": "Valeur vénale nette",
  "NET_MARKET_VALUE_YYYY": "Valeur vénale nette YYYY-1 (€)",
  "NET_OPERATING_INCOME": "Résultat d'exploitation YYYY-1 (€/an)",
  "NET_POTENTIAL_RENT": "Loyer potentiel net (€/an)",
  "NET_POTENTIAL_RENT_WOU": "Loyer potentiel net",
  "NET_REVERS_CAPITAL_RATE": "Taux de capitalisation théorique net",
  "NET_REVERS_POTENT_OCCUP_AREA": "Potentiel de réversion sur la surface occupée",
  "NET_REVERS_POTENT_TOTAL_PROP": "Potentiel de réversion sur la surface totale",
  "NET_REVERSIONARY_YIELD": "Taux théorique net",
  "NEUTRALIZATION": "Neutralisation",
  "NEUTRALIZATION END DATE": "Fin de neutralisation",
  "NEUTRALIZATION START DATE": "Début de neutralisation",
  "NEUTRALIZATION_END_DATE": "Fin de neutralisation",
  "NEUTRALIZATION_START_DATE": "Début de neutralisation",
  "NEW LEASES NUMBER": "Nombre de relocations",
  "NEW LEASES RATE": "Taux d'entrée (sur les baux)",
  "NEW UNITS AREA": "Surface relouée (m²)",
  "NEW UNITS AREA RATE": "Taux d'entrée (sur les surfaces)",
  "NEW UNITS NUMBER": "Nombre de lots reloués",
  "NEW UNITS RATE": "Taux d'entrée (sur les lots)",
  "NEW VALUE": "Nouvelle valeur",
  "NEW_LEASES_NUMBER": "Nombre de relocations",
  "NEW_LEASES_RATE": "Taux d'entrée (sur les baux)",
  "NEW_UNITS_AREA": "Surface relouée (m²)",
  "NEW_UNITS_AREA_RATE": "Taux d'entrée (sur les surfaces)",
  "NEW_UNITS_NUMBER": "Nombre de lots reloués",
  "NEW_UNITS_RATE": "Taux d'entrée (sur les lots)",
  "NEW_VALUE": "Nouvelle valeur",
  "NewSendReportExcel": "Nouvel Envoi Reporting PDF/Excel",
  "NEXT INDEX DATE": "Date du prochain indice",
  "NEXT LEASE BREAK DATE": "Prochaine date de break",
  "NEXT LEASE HEADLINE RENT": "Loyer facial du bail suivant (€/an)",
  "NEXT LEASE HEADLINE RENT M2": "Loyer facial du bail suivant (€/an/m²)",
  "NEXT REVISION DATE": "Prochaine date de révision",
  "NEXT RISK DATE": "Prochaine date de risque",
  "NEXT TENANT CODE": "Code du locataire suivant",
  "NEXT TENANT MOVE IN DATE": "Date d'entrée du locataire suivant",
  "NEXT TENANT MOVE OUT DATE": "Date de sortie du locataire suivant",
  "NEXT TENANT NAME": "Nom du locataire suivant",
  "NEXT_INDEX_DATE": "Date du prochain indice",
  "NEXT_INDEX_VALUE": "Valeur du prochain indice",
  "NEXT_LEASE_BREAK_DATE": "Prochaine date de break",
  "NEXT_LEASE_HEADLINE_RENT": "Loyer facial du bail suivant (€/an)",
  "NEXT_LEASE_HEADLINE_RENT_M2": "Loyer facial du bail suivant (€/an/m²)",
  "NEXT_REVISION_DATE": "Prochaine date de révision",
  "NEXT_RISK_DATE": "Prochaine date de risque",
  "NEXT_TENANT_CODE": "Code du locataire suivant",
  "NEXT_TENANT_MOVE_IN_DATE": "Date d'entrée du locataire suivant",
  "NEXT_TENANT_MOVE_OUT_DATE": "Date de sortie du locataire suivant",
  "NEXT_TENANT_NAME": "Nom du locataire suivant",
  "NIV": "Niveau",
  "NO": "Non",
  "NO DATA": "Aucune données disponible",
  "NOM_BATÎMENT": "Nom bâtiment",
  "NON RECOV OPERATING EXPENSES": "Charges non récupérables (€/an)",
  "NON RECOVERABLE EXPENSES": "Charges non récupérables (€/an)",
  "NON RECOVERABLE EXPENSES WOU": "Charges non récupérables",
  "NON_RECOV_OPERATING_EXPENSES": "Charges non récupérables YYYY-1 (€/an)",
  "NON_RECOVERABLE_EXPENSES": "Charges non récupérables (€/an)",
  "NON_RECOVERABLE_EXPENSES_WOU": "Charges non récupérables",
  "NOT DUE INC TAX AMOUNT": "Quittancement non exigible (€)",
  "NOT RECOVERABLE": "Non Récupérable",
  "NOT_DUE_INC_TAX_AMOUNT": "Quittancement non exigible (€)",
  "NOTICE DATE": "Date de congé du locataire",
  "NOTICE PERIOD": "Durée du préavis (mois)",
  "NOTICE TYPE LABEL": "Type de congé",
  "NOTICE_DATE": "Date de congé du locataire",
  "NOTICE_PERIOD": "Durée de préavis (mois)",
  "NOTICE_TYPE_CODE": "Code type de congé",
  "NOTICE_TYPE_LABEL": "Type de congé",
  "NOUVELLE_REGLE_MISE_EN_CONFORMITE_DE_L_OBJET": "Nouvelle règle de mise en conformité",
  "NOVEMBER_AMOUNT": "Novembre (€)",
  "NULL": "Inconnu",
  "NUMBER": "Nombre",
  "NUMBER OF BUILDINGS": "Nombre de bâtiments",
  "NUMBER OF CAR PARKS": "Nombre de parkings",
  "NUMBER OF DOUBLE CAR PARKS": "Nombre de parkings doubles",
  "NUMBER OF LEASE ENDED": "Fin de bail",
  "NUMBER OF LEASE IN RISK": "Option de break",
  "NUMBER OF LEASE RENEWALS": "Nombre d'avenants au bail",
  "NUMBER OF LEASES": "Nombre de baux",
  "NUMBER OF LEASES AT DATE": "Nombre de baux au",
  "NUMBER OF OCCUPIED UNITS": "Nombre de lots loués",
  "NUMBER OF OWNERS": "Nombre de propriétaires",
  "NUMBER OF PARKING PLACES": "Nombre de places de parking",
  "NUMBER OF PARKING SPACES": "Nombre de places de parking",
  "NUMBER OF PROPERTIES": "Evolution du patrimoine en nombre d'immeubles",
  "NUMBER OF PROPERTIES BY NATURE": "Evolution du patrimoine en nombre d'immeubles par type d'immeuble",
  "NUMBER OF ROOMS": "Nombre de pièces",
  "NUMBER OF SECOND UNIT PERCENT": "% du nombre total de places de parking",
  "NUMBER OF SECONDARY UNIT": "Nombre de places de parking",
  "NUMBER OF SIMPLE CAR PARKS": "Nombre de parkings simples",
  "NUMBER OF TENANTS": "Nombre de locataires",
  "NUMBER OF UNITS": "Nombre de lots",
  "NUMBER OF UNITS FOR SALE": "Nombre de lots à céder",
  "NUMBER OF VACANT UNITS": "Nombre de lots vacants",
  "NUMBER PARKING": "Nombre de parkings",
  "NUMBER_LINE": "Nombre de lignes du fichier",
  "NUMBER_OF_BUILDINGS": "Nombre de bâtiments",
  "NUMBER_OF_BUILDINGS_mismatch": "Nombre de bâtiments format incorrect",
  "NUMBER_OF_DOUBLE_CAR_PARKS": "Nombre de parkings doubles",
  "NUMBER_OF_LEASE_RENEWALS": "Nombre d'avenants au bail",
  "NUMBER_OF_LEASES": "Nombre de baux",
  "NUMBER_OF_LEASES_AT_DATE": "Nombre de baux au DD/MM/YYYY",
  "NUMBER_OF_OCCUPIED_UNITS": "Nombre de lots occupés",
  "NUMBER_OF_PARKING_PLACES": "Nombre de places de parking",
  "NUMBER_OF_PARKING_SPACES": "Nombre de places de parking",
  "NUMBER_OF_ROOMS": "Nombre de pièces",
  "NUMBER_OF_SECOND_UNIT_PERCENT": "% du nombre total de places de parking",
  "NUMBER_OF_SECONDARY_UNIT": "Nombre de places de parking",
  "NUMBER_OF_SIMPLE_CAR_PARKS": "Nombre de parkings simples",
  "NUMBER_OF_TENANTS": "Nombre de locataires",
  "NUMBER_OF_UNITS": "Nombre de lots",
  "NUMBER_OF_UNITS_FOR_SALE": "Nombre de lots à céder",
  "NUMBER_OF_UNITS_FOR_SALE_mismatch": "Nombre de lots à céder format incorrect",
  "NUMBER_OF_UNITS_FOR_SALE_required": "Nombre de lots à céder obligatoire",
  "NUMBER_OF_VACANT_UNITS": "Nombre de lots vacants",
  "NUMBER_PARKING_SPACES": "Nombre de parkings",
  "OBJET": "Objet du chantier",
  "OCCUPANCY": "Occupation",
  "OCCUPANCY COST RATIO": "Taux d'effort sur le loyer total TTC",
  "OCCUPANCY SUMMARY": "Etat d'occupation",
  "OCCUPANCY_COST_RATIO": "Taux d'effort sur le loyer total TTC",
  "OCCUPIED AREA": "Surface occupée (m²)",
  "OCCUPIED AREA SUMMARY": "Répartition des surfaces occupées par destination",
  "OCCUPIED_AREA_SQM": "Surface occupée (m²)",
  "OCTOBER_AMOUNT": "Octobre (€)",
  "OFFICE AREA": "Surface à usage de bureaux (m²)",
  "OFFICE TAX AMOUNT": "Montant de la taxe bureau (€)",
  "OFFICE_AREA": "Surface à usage de bureaux (m²)",
  "OFFICE_TAX_AMOUNT": "Montant de la taxe bureau (€)",
  "ON LITIGATION": "Contentieux en cours (oui/non) ?",
  "ON THE": "au",
  "ON_LITIGATION": "Contentieux en cours (oui/non) ?",
  "OPERATING EXPENSES": "Charges d'exploitation (€)",
  "OPERATING RESULT": "Résultat d'exploitation (€)",
  "OPERATING RETURN ON GROSS BOOK VALUE": "Rendement d'exploitation sur ABI",
  "OPERATION": "Opération",
  "OPERATION NATURE CODE": "Code nature de l'opération",
  "OPERATION NATURE LABEL": "Libellé nature de l'opération",
  "OPERATION_NATURE_CODE": "Code nature de l'opération",
  "OPERATION_NATURE_LABEL": "Libellé nature de l'opération",
  "OperationalManagementInterfaceTitle": "Gestion opérationnelle interface",
  "OTHER INCOME": "Autres produits d'exploitation (€)",
  "OTHER OCCUPIED AREA": "Autre - Surface occupée (m²)",
  "OTHER REVENUE RESERVES": "Divers capitaux propres",
  "OTHER TOTAL AREA": "Autre - Surface totale (m²)",
  "OTHER VACANT AREA": "Autre - Surface vacante (m²)",
  "OTHER_REVENUE_RESERVES": "Divers capitaux propres",
  "OUT COT AT": "Impayés au",
  "OUT COT RAW": "Impayés",
  "OUTSTAND PAY BALANCES": "Evolution des soldes locataires et des encaissements",
  "OUTSTAND PAY BALANCES RAW": "Evolution des soldes locataires et des encaissements - brut",
  "OUTSTAND PAY DAY 180": "91-180 jours (€)",
  "OUTSTAND PAY DAY 30": "< 30 jours (€)",
  "OUTSTAND PAY DAY 365": "6 mois-1 an (€)",
  "OUTSTAND PAY DAY 60": "30-60 jours (€)",
  "OUTSTAND PAY DAY 90": "61-90 jours (€)",
  "OUTSTAND PAY DAY 999": "> 1 an (€)",
  "OUTSTAND PAY DEPOSIT": "Dépôt (€)",
  "OUTSTAND PAY EVOLUTION": "Evolution trimestrielle des impayés",
  "OUTSTAND PAY INC TAX AMOUNT": "Montant impayé (€)",
  "OUTSTAND PAY INCL TAX AMOUNT": "Solde locataire débiteur",
  "OUTSTAND PAY INSURANCES": "Assurance et divers (€)",
  "OUTSTAND PAY OPERATING EXP": "Charge (€)",
  "OUTSTAND PAY OTHER": "Non affecté (€)",
  "OUTSTAND PAY RENT": "Loyer (€)",
  "OUTSTAND PAY TAXES": "Taxes (€)",
  "OUTSTAND PAY TERM EQUIV": "Nombre de termes impayés",
  "OUTSTAND PAY VAT AMOUNT": "T.V.A ou C.R.L (€)",
  "OUTSTAND PAYMENT INC TAX AMOUNT": "Montant impayé",
  "OUTSTAND RATE": "Taux d'impayé",
  "OUTSTAND_PAY_DAY_1": "<= x jours",
  "OUTSTAND_PAY_DAY_180": "91-180 jours",
  "OUTSTAND_PAY_DAY_180_SHORT": "91-180 jours",
  "OUTSTAND_PAY_DAY_2": "x-y jours",
  "OUTSTAND_PAY_DAY_3": "x-y jours",
  "OUTSTAND_PAY_DAY_30": "< 30 jours",
  "OUTSTAND_PAY_DAY_30_SHORT": "< 30 jours",
  "OUTSTAND_PAY_DAY_365": "6 mois-1 an",
  "OUTSTAND_PAY_DAY_365_SHORT": "6 mois-1 an",
  "OUTSTAND_PAY_DAY_4": "x-y jours",
  "OUTSTAND_PAY_DAY_5": "x-y jours",
  "OUTSTAND_PAY_DAY_6": "> x jours",
  "OUTSTAND_PAY_DAY_60": "30-60 jours",
  "OUTSTAND_PAY_DAY_60_SHORT": "30-60 jours",
  "OUTSTAND_PAY_DAY_90": "61-90 jours",
  "OUTSTAND_PAY_DAY_90_SHORT": "61-90 jours",
  "OUTSTAND_PAY_DAY_999": "> 1 an",
  "OUTSTAND_PAY_DAY_999_SHORT": "> 1 an",
  "OUTSTAND_PAY_DEPOSIT": "Dépôt (€)",
  "OUTSTAND_PAY_EVOLUTION": "Evolution trimestrielle des impayés",
  "OUTSTAND_PAY_INC_TAX_AMOUNT": "Montant impayé (€)",
  "OUTSTAND_PAY_INSURANCES": "Assurance et divers (€)",
  "OUTSTAND_PAY_OPERATING_EXP": "Charge (€)",
  "OUTSTAND_PAY_OTHER": "Non affecté (€)",
  "OUTSTAND_PAY_RENT": "Loyer (€)",
  "OUTSTAND_PAY_TAXES": "Taxes (€)",
  "OUTSTAND_PAY_TERM_EQUIV": "Nombre de termes impayés",
  "OUTSTAND_PAY_VAT_AMOUNT": "T.V.A ou C.R.L (€)",
  "OUTSTAND_PAYMENT_INC_TAX_AMOUNT": "Montant impayé",
  "OUTSTAND_RATE": "Taux d'impayé",
  "OUTSTANDING AMOUNT EVOLUTION": "Evolution des encaissements locataires",
  "OUTSTANDING BALANCE EVOLUTION": "Evolution du solde locataires",
  "OUTSTANDING PAY PROVIDER": "Balance agée immeuble par fournisseur",
  "OUTSTANDING PAY PROVIDER BUILDING": "Balance agée immeuble par fournisseur et par facture",
  "OUTSTANDING PAY PROVIDER PROVIDER": "Balance agée fournisseur par immeuble et par facture",
  "OUTSTANDING PAYMENT": "Impayés",
  "OUTSTANDING PAYMENTS": "Suivi des impayés",
  "OUTSTANDING PAYMENTS AMOUNT": "Montant des impayés (€)",
  "OUTSTANDING PAYMENTS AS AT": "Suivi des impayés au",
  "OUTSTANDING PAYMENTS BY PERIOD": "Répartition des impayés par période",
  "OUTSTANDING PAYMENTS DETAILLED BY TENANT": "Top 10 des locataires avec impayés",
  "OUTSTANDING RATE AMOUNT BALANCE": "Evolution du ratio encaissements / solde locataires",
  "OUTSTANDING RENT": "Impayés (€)",
  "OUTSTANDING RENT WOU": "Suivi des impayés",
  "OUTSTANDING_PAYMENTS": "Suivi des impayés",
  "OUTSTANDING_PAYMENTS_BY_PERIOD": "Répartition des impayés par période",
  "OUTSTANDING_PAYMENTS_DETAILLED_BY_TENANT": "Top 10 des locataires avec impayés",
  "OVERPAYMENTS INC TAX AMNT": "Trop perçu ou encaissement non affecté (€)",
  "OVERPAYMENTS INC TAX AMOUNT": "Trop perçu (€)",
  "OVERPAYMENTS RATE": "Taux trop perçu",
  "OVERPAYMENTS_INC_TAX_AMNT": "Trop perçu ou encaissement non affecté (€)",
  "OVERPAYMENTS_INC_TAX_AMOUNT": "Trop perçu (€)",
  "OWNED BUILDINGS": "Immeubles détenus directement et indirectement (100 %)",
  "OWNER": "Propriétaire",
  "OWNER CODE": "Code propriétaire",
  "OWNER EQUITY": "Capitaux propres (€)",
  "OWNER FINANCIAL DATA": "Données financières propriétaire",
  "OWNER NAME": "Propriétaire",
  "OWNER TOTAL": "Total",
  "OWNER_CODE": "Code propriétaire",
  "OWNER_CODE1": "Code de la société",
  "OWNER_EQUITY": "Capitaux propres (€)",
  "OWNER_FINANCIAL_DATA": "Données financières propriétaire",
  "OWNER_NAME": "Propriétaire",
  "OWNER_NAME_ARBITRAGE": "Nom de l'acquéreur",
  "OWNER_NAME_required": "Nom de l'acquéreur obligatoire",
  "OWNER_NAME1": "Nom de la société",
  "OWNERSHIP DATA END DATE": "Date de sortie",
  "OWNERSHIP DATA START DATE": "Date d'entrée",
  "OWNERSHIP RATE": "Pourcentage de détention",
  "OWNERSHIP_DATA_END_DATE": "Date de sortie",
  "OWNERSHIP_DATA_START_DATE": "Date d'entrée",
  "OWNERSHIP_RATE": "Pourcentage de détention",
  "pageBuildingTitle": "Fiche immeuble",
  "pageExportBuildingTitle": "Export fiche immeuble",
  "pageExportFundTitle": "Export fiche société",
  "pageFundTitle": "Fiche société",
  "PageManageAdditionalFieldListTitle": "GESTION DES THEMES",
  "PageManageInterfaceListTitle": "Gestion des interfaces",
  "PageManageSpecificInterfaceListTitle": "Gestion des interfaces spécifiques",
  "pageManageTitle": "Mon compte",
  "pageRepositoryBuildingTitle": "Référentiel immeubles",
  "PAR": "Paramètres",
  "PARTIAL PAYMENT INCL TAX AMOUNT": "Paiements partiels (€)",
  "PARTIAL_PAYMENT_INCL_TAX_AMOUNT": "Paiements partiels (€)",
  "PASSING RENT": "Montants quittancés (€)",
  "PAY INCL TAX AMOUNT SUM PERCENT": "% du montant des encaissements",
  "PAY INCL TAX AMOUNT SUM RATE": "Montant des encaissements (€)",
  "PAYMENT 11 TO 20 DAYS": "11-20 jours (€)",
  "PAYMENT 11 TO 20 RATE": "11-20 jours (%)",
  "PAYMENT 21 TO 31 DAYS": "21-31 jours (€)",
  "PAYMENT 21 TO 31 RATE": "21-31 jours (%)",
  "PAYMENT 5 TO 10 DAYS": "5-10 jours (€)",
  "PAYMENT 5 TO 10 RATE": "5-10 jours (%)",
  "PAYMENT AMOUNT": "Montant payé",
  "PAYMENT BEFORE PERIOD INC TAX AMNT": "Encaissements sur factures dues avant la période (€)",
  "PAYMENT DATE": "Date de paiement",
  "PAYMENT DAYS": "jours (€)",
  "PAYMENT DURING PERIOD INC TAX AMNT": "Encaissements sur la période (€)",
  "PAYMENT GREATER 31 DAYS": "> 31 jours (€)",
  "PAYMENT GREATER 31 RATE": "> 31 jours (%)",
  "PAYMENT INCL TAX AMOUNT": "Encaissements exigibles de la période (€)",
  "PAYMENT INCL TAX AMOUNT PERCENT": "% du montant des encaissements",
  "PAYMENT INCL TAX AMOUNT RATE": "Montant des encaissements (€)",
  "PAYMENT LESS 5 DAYS": "< 5 jours (€)",
  "PAYMENT LESS 5 RATE": "< 5 jours (%)",
  "PAYMENT MODE": "Mode de paiement",
  "PAYMENT PERIOD 1": "< 30 jours (€)",
  "PAYMENT PERIOD 1 RATE": "< 30 jours (%)",
  "PAYMENT PERIOD 2": "30-60 jours (€)",
  "PAYMENT PERIOD 2 RATE": "30-60 jours (%)",
  "PAYMENT PERIOD 2 SUM": "30-60 jours cumulé (€)",
  "PAYMENT PERIOD 2 SUM RATE": "30-60 jours cumulé (%)",
  "PAYMENT PERIOD 3": "60-90 jours (€)",
  "PAYMENT PERIOD 3 RATE": "60-90 jours (%)",
  "PAYMENT PERIOD 3 SUM": "60-90 jours cumulé (€)",
  "PAYMENT PERIOD 3 SUM RATE": "60-90 jours cumulé (%)",
  "PAYMENT PERIOD 4": "90-180 jours (€)",
  "PAYMENT PERIOD 4 RATE": "90-180 jours (%)",
  "PAYMENT PERIOD 4 SUM": "90-180 jours cumulé (€)",
  "PAYMENT PERIOD 4 SUM RATE": "90-180 jours cumulé (%)",
  "PAYMENT PERIOD 5": "180-365 jours (€)",
  "PAYMENT PERIOD 5 RATE": "180-365 jours (%)",
  "PAYMENT PERIOD 5 SUM": "180-365 jours cumulé (€)",
  "PAYMENT PERIOD 5 SUM RATE": "180-365 jours cumulé (%)",
  "PAYMENT PERIOD 6": "> 365 jours (€)",
  "PAYMENT PERIOD 6 RATE": "> 365 jours (%)",
  "PAYMENT PERIOD 6 SUM": "> 365 jours cumulé (€)",
  "PAYMENT PERIOD 6 SUM RATE": "> 365 jours cumulé (%)",
  "PAYMENT PERIOD LESS 0": "< 0 jours (€)",
  "PAYMENT RATE": "jours (%)",
  "PAYMENT SUM": "jours cumulé (€)",
  "PAYMENT SUM RATE": "jours cumulé (%)",
  "PAYMENT_11_20_DAYS": "11-20 jours",
  "PAYMENT_21_31_DAYS": "21-31 jours",
  "PAYMENT_5_10_DAYS": "5-10 jours",
  "PAYMENT_AMOUNT": "Montant payé",
  "PAYMENT_BEFORE_PERIOD_INC_TAX_AMNT": "Encaissements sur factures dues avant la période (€)",
  "PAYMENT_DATE": "Date de paiement",
  "PAYMENT_DURING_PERIOD_INC_TAX_AMNT": "Encaissements sur la période (€)",
  "PAYMENT_INCL_TAX_AMNT": "Encaissements exigibles de la période (€)",
  "PAYMENT_INCL_TAX_AMOUNT": "Montant encaissé (€)",
  "PAYMENT_INCL_TAX_AMOUNT_RATE": "\\% du montant des encaissements",
  "PAYMENT_INCL_TAX_AMOUNT_SUM": "Montant encaissé cumulé (€)",
  "PAYMENT_INCL_TAX_AMOUNT_SUM_RATE": "\\% du montant des encaissements",
  "PAYMENT_LESS_5_DAYS": "< 5 jours",
  "PAYMENT_METHOD_CODE": "Code méthode de paiement",
  "PAYMENT_MODE": "Mode de paiement",
  "PAYMENT_MORE_31_DAYS": "> 31 jours",
  "PAYMENT_PERIOD_1": "< {0} jours (€)",
  "PAYMENT_PERIOD_1_RATE": "< {0} jours (%)",
  "PAYMENT_PERIOD_2": "{0}-{1} jours (€)",
  "PAYMENT_PERIOD_2_RATE": "{0}-{1} jours (%)",
  "PAYMENT_PERIOD_2_SUM": "{0}-{1} jours cumulé (€)",
  "PAYMENT_PERIOD_2_SUM_RATE": "{0}-{1} jours cumulé (%)",
  "PAYMENT_PERIOD_3": "{0}-{1} jours (€)",
  "PAYMENT_PERIOD_3_RATE": "{0}-{1} jours (%)",
  "PAYMENT_PERIOD_3_SUM": "{0}-{1} jours cumulé (€)",
  "PAYMENT_PERIOD_3_SUM_RATE": "{0}-{1} jours cumulé (%)",
  "PAYMENT_PERIOD_4": "{0}-{1} jours (€)",
  "PAYMENT_PERIOD_4_RATE": "{0}-{1} jours (%)",
  "PAYMENT_PERIOD_4_SUM": "{0}-{1} jours cumulé (€)",
  "PAYMENT_PERIOD_4_SUM_RATE": "{0}-{1} jours cumulé (%)",
  "PAYMENT_PERIOD_5": "{0}-{1} jours (€)",
  "PAYMENT_PERIOD_5_RATE": "{0}-{1} jours (%)",
  "PAYMENT_PERIOD_5_SUM": "{0}-{1} jours cumulé (€)",
  "PAYMENT_PERIOD_5_SUM_RATE": "{0}-{1} jours cumulé (%)",
  "PAYMENT_PERIOD_6": "> {0} jours (€)",
  "PAYMENT_PERIOD_6_RATE": "> {0} jours (%)",
  "PAYMENT_PERIOD_6_SUM": "> {0} jours cumulé (€)",
  "PAYMENT_PERIOD_6_SUM_RATE": "> {0} jours cumulé (%)",
  "PAYMENT_PERIOD_LESS_0": "< 0 jours (€)",
  "PAYS": "Pays",
  "PC EXPECTED YIELD": "Taux de rendement attendu",
  "PC MGMT FEES GARB COLLECT TAX": "% de frais de gestion de TOM",
  "PC PORTFOLIO NET MARKET VALUE": "Poids dans le portefeuille",
  "PC PORTFOLIO TOTAL RETURN": "Poids dans le rendement global",
  "PC PROPERTY TAX MGMT FEES": "% de frais de gestion de taxe foncière",
  "PC RECOV PROP MANAGT FEES ADM": "% récupérable - Honoraires de gestion administrative",
  "PC RECOV PROP MANAGT FEES TEN": "% récupérable - Honoraires de gestion",
  "PC RECOVERABLE 606": "% récupérable - Travaux de grosses réparations",
  "PC RECOVERABLE INSURANCE": "% récupérable - Primes d'assurance",
  "PC RECOVERABLE OFFICE TAX": "% récupérable - Taxe bureau",
  "PC RECOVERABLE PROPERTY TAX": "% récupérable - Taxe foncière",
  "PC RECOVERABLE REFURB EXCL 606": "% récupérable - Gros travaux (hors article 606)",
  "PC STEP RENT 1 OF GHL RENT": "Loyer palier 1 en % du loyer facial",
  "PC STEP RENT 2 OF GHL RENT": "Loyer palier 2 en % du loyer facial",
  "PC STEP RENT 3 OF GHL RENT": "Loyer palier 3 en % du loyer facial",
  "PC STEP RENT 4 OF GHL RENT": "Loyer palier 4 en % du loyer facial",
  "PC STEP RENT 5 OF GHL RENT": "Loyer palier 5 en % du loyer facial",
  "PC STEP RENT 6 OF GHL RENT": "Loyer palier 6 en % du loyer facial",
  "PC_EXPECTED_YIELD": "Taux de rendement attendu",
  "PC_INDEX_LEVERAGE": "Pourcentage de révision",
  "PC_MGMT_FEES_GARB_COLLECT_TAX": "% de frais de gestion de TOM",
  "PC_PORTFOLIO_NET_MARKET_VALUE": "Poids dans le portefeuille",
  "PC_PORTFOLIO_TOTAL_RETURN": "Poids dans le rendement global",
  "PC_PROPERTY_TAX_MGMT_FEES": "% de frais de gestion de taxe foncière",
  "PC_RECOV_PROP_MANAGT_FEES_ADM": "% récupérable - Honoraires de gestion administrative",
  "PC_RECOV_PROP_MANAGT_FEES_TEN": "% récupérable - Honoraires de gestion",
  "PC_RECOVERABLE_606": "% récupérable - Travaux de grosses réparations",
  "PC_RECOVERABLE_INSURANCE": "% récupérable - Primes d assurance",
  "PC_RECOVERABLE_OFFICE_TAX": "% récupérable - Taxe sur les bureaux",
  "PC_RECOVERABLE_PROPERTY_TAX": "% récupérable - Taxe foncière",
  "PC_RECOVERABLE_REFURB_EXCL_606": "% récupérable - Gros travaux (hors article 606)",
  "PC_STEP_RENT_1_OF_GHL_RENT": "Loyer palier 1 en % du loyer facial",
  "PC_STEP_RENT_2_OF_GHL_RENT": "Loyer palier 2 en % du loyer facial",
  "PC_STEP_RENT_3_OF_GHL_RENT": "Loyer palier 3 en % du loyer facial",
  "PC_STEP_RENT_4_OF_GHL_RENT": "Loyer palier 4 en % du loyer facial",
  "PC_STEP_RENT_5_OF_GHL_RENT": "Loyer palier 5 en % du loyer facial",
  "PC_STEP_RENT_6_OF_GHL_RENT": "Loyer palier 6 en % du loyer facial",
  "PCT RENT AMOUNT": "% du loyer facial de l'immeuble",
  "PCT RENTED AREA": "% de la surface totale de l'immeuble",
  "PCT_RENT_AMOUNT": "% du loyer facial de l'immeuble",
  "PCT_RENTED_AREA": "% de la surface totale de l'immeuble",
  "PDDI": "PDD immeuble (€)",
  "PDF": "PDF",
  "PENDING RE DISPOSITIONS": "Cessions en cours",
  "PENDING RE PURCHASES": "Acquisitions en cours",
  "PER SHARE ACQUISITION PRICE": "Prix de revient (€)",
  "PER SHARE UNREALIZED GAINS AND LOSSES": "Plus-ou-moins-value latente unitaire sur les titres (€)",
  "PER_SHARE_ACQUISITION_PRICE": "Prix de revient (€)",
  "PER_SHARE_UNREAL_GAINS_LOSSES": "Plus-ou-moins-value latente unitaire sur les titres (€)",
  "PER_SHARE_UNREALIZED_GAINS_AND_LOSSES": "Plus-ou-moins-value latente unitaire sur les titres (€)",
  "PERCENT TOTAL PROPERTY AREA": "% de la surface totale de l'immeuble",
  "PERCENT TOTAL RENT AMOUNT": "% du loyer facial de l'immeuble",
  "PERCENT_OF_TOTAL_AREA": "% de la surface totale",
  "PERCENT_TOTAL_PROPERTY_AREA": "% de la surface totale de l'immeuble",
  "PERCENT_TOTAL_RENT_AMOUNT": "% du loyer facial de l'immeuble",
  "PERCENTAGE RENT": "Loyer variable (€/an)",
  "PERCENTAGE RENT Y N": "Loyer variable (oui/non) ?",
  "PERCENTAGE_RENT": "Loyer variable (€/an)",
  "PERCENTAGE_RENT Y N": "Loyer variable (oui/non) ?",
  "PERCENTAGE_RENT_Y_N": "Loyer variable (oui/non) ?",
  "PERFORMANCE INDICATORS": "Indicateurs de performance",
  "PERIOD": "Période",
  "PERIOD AMOUNT": "Mouvement de la période",
  "PERIOD INVOICES DUE INC TAX AMNT": "Factures exigibles de la période (€)",
  "PERIOD_AMOUNT": "Mouvement de la période",
  "PERIOD_INVOICES_DUE_INC_TAX_AMNT": "Factures exigibles de la période (€)",
  "PHYSICAL OCCUPANCY RATE": "Taux d’occupation physique",
  "PHYSICAL OCCUPANCY SUMMARY": "Etat d'occupation physique",
  "PHYSICAL OCCUPATION RATE": "Taux d'occupation physique",
  "PHYSICAL VACANCY M2": "Vacance physique (m²)",
  "PHYSICAL VACANCY PC": "Vacance physique (%)",
  "PHYSICAL VACANCY RATE": "Taux de vacance physique",
  "PHYSICAL_OCCUPANCY_RATE": "Taux d'occupation physique",
  "PHYSICAL_OCCUPATION_RATE": "Taux d'occupation physique",
  "PHYSICAL_VACANCY_RATE": "Taux de vacance physique",
  "PM BUILDING CODE": "Code immeuble du Property Manager",
  "PORTFOLIO": "Portefeuille",
  "PORTFOLIO BREAKDOWN ASSET NATURE AREAS": "Répartition de la surface par type d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET NATURE BUILDINGS": "Répartition des immeubles par type d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET NATURE GROSS HEADLINE RENT": "Répartition du loyer facial brut par type d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET NATURE LEASES": "Répartition du nombre de baux par type d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET NATURE UNITS": "Répartition du nombre de lots par type d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET NATURE VALUES": "Répartition de la valeur vénale par type d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET TYPE AREAS": "Répartition de la surface par typologie d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET TYPE BUILDINGS": "Répartition des immeubles par typologie d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET TYPE GROSS HEADLINE RENT": "Répartition du loyer facial brut par typologie d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET TYPE LEASES": "Répartition du nombre de baux par typologie d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET TYPE UNITS": "Répartition du nombre de lots par typologie d'immeuble",
  "PORTFOLIO BREAKDOWN ASSET TYPE VALUES": "Répartition de la valeur vénale par typologie d'immeuble",
  "PORTFOLIO CODE": "Code portfolio",
  "PORTFOLIO DETAIL": "Détail du portefeuille",
  "PORTFOLIO EVOLUTION €": "Evolution du patrimoine en valeur vénale nette",
  "PORTFOLIO EVOLUTION ASSET NATURE €": "Evolution du patrimoine en valeur vénale nette par type d'immeuble",
  "PORTFOLIO EVOLUTION ASSET NATURE M2": "Evolution du patrimoine en surface par type d'immeuble",
  "PORTFOLIO EVOLUTION GROSS HEADLINE RENT": "Evolution du patrimoine en loyer facial brut",
  "PORTFOLIO EVOLUTION GROSS HEADLINE RENT BY NATURE": "Evolution du patrimoine en loyer facial brut par type d'immeuble",
  "PORTFOLIO EVOLUTION M2": "Evolution du patrimoine en surface",
  "PORTFOLIO EVOLUTION NET EFFECTIVE RENT": "Evolution du patrimoine en loyer effectif net",
  "PORTFOLIO EVOLUTION NET EFFECTIVE RENT BY NATURE": "Evolution du patrimoine en loyer effectif net par type d'immeuble",
  "PORTFOLIO LABEL": "Portefeuille",
  "PORTFOLIO SIZE": "Volumétrie du portefeuille (société-mère et société-filles)",
  "PORTFOLIO YIELD AND RETURN": "Rendements portefeuille",
  "PORTFOLIO_CODE": "Code portfolio",
  "PORTFOLIO_CODE_required": "Code du portefeuille obligatoire",
  "PORTFOLIO_LABEL": "Portefeuille",
  "POSTAL CODE": "Code postal",
  "POSTAL_CODE": "Code postal",
  "POTENTIAL GROSS CAPITAL RATE": "Taux de capitalisation effectif potentiel brut",
  "POTENTIAL GROSS INITIAL YIELD": "Taux de rendement effectif potentiel brut",
  "POTENTIAL INCOME INCREASE": "Potentiel de réversion (€)",
  "POTENTIAL INCOME INCREASE WOU": "Potentiel de réversion",
  "POTENTIAL NET CAPITAL RATE": "Taux de capitalisation effectif potentiel net",
  "POTENTIAL NET INITIAL YIELD": "Taux de rendement effectif potentiel net",
  "POTENTIAL NET RENTAL INCOME": "Loyer potentiel net (€)",
  "POTENTIAL NET RENTAL INCOME WOU": "Loyer potentiel net",
  "POTENTIAL_GROSS_CAPITAL_RATE": "Taux de capitalisation effectif potentiel brut",
  "POTENTIAL_GROSS_INITIAL_YIELD": "Taux de rendement effectif potentiel brut",
  "POTENTIAL_NET_CAPITAL_RATE": "Taux de capitalisation effectif potentiel net",
  "POTENTIAL_NET_INITIAL_YIELD": "Taux de rendement effectif potentiel net",
  "POURCENTAGE_D_AVANCEMENT_DU_CHANTIER": "Avancement du chantier (%)",
  "POURCENTAGE_RECUPERATION": "Récupération (%)",
  "PRESENT": "Présent",
  "PREV ANNUAL M2 AMOUNT": "Bail précédent - Loyer facial par m² (€/m²/an)",
  "PREV GROSS HEADLINE RENT": "Bail précédent - Loyer facial (€/an)",
  "PREV LEASE ANNUAL RENT AMOUNT": "Bail précédent - Loyer facial (€/an)",
  "PREV RENTED AREA": "Bail précédent - Surface occupée (m²)",
  "PREV_ANNUAL_RENT_AMOUNT_SQM": "Loyer facial avant révision par m² (€/m²/an)",
  "PREV_LEASE_ANNUAL_RENT_AMOUNT": "Bail précédent - Loyer facial (€/an)",
  "PREV_VALUE_EXCL_DUTIES_VAT": "Valeur vénale nette précédente (€)",
  "PREV_VALUE_EXCL_DUTIES_VAT_SQM": "Valeur vénale nette par m² précédente (€/m²)",
  "Preview": "Prévisualisation",
  "PREVIOUS LEASE AREA SQM": "Bail précédent - Surface occupée (m²)",
  "PREVIOUS LEASE HEADLINE RENT": "Loyer facial du bail précédent (€/an)",
  "PREVIOUS LEASE HEADLINE RENT M2": "Loyer facial du bail précédent (€/an/m²)",
  "PREVIOUS LEASE SUMMARY": "Résumé du bail précédent",
  "PREVIOUS LEASE TENANT NAME": "Bail précédent - Nom du locataire",
  "PREVIOUS LEASE TRADE NAME": "Bail précédent - Enseigne",
  "PREVIOUS RENT AMOUNT PER SQM": "Bail précédent - Loyer facial par m² (€/m²/an)",
  "PREVIOUS TENANT CODE": "Code du locataire précédent",
  "PREVIOUS TENANT MOVE OUT DATE": "Date de sortie du dernier locataire",
  "PREVIOUS TENANT NAME": "Nom du locataire précédent",
  "PREVIOUS_ANNUAL_RENT_AMOUNT": "Loyer facial avant révision (€/an)",
  "PREVIOUS_LAST_VALUATION_DATE": "Date de valeur YYYY-2",
  "PREVIOUS_LEASE_AREA_SQM": "Bail précédent - Surface occupée (m²)",
  "PREVIOUS_LEASE_HEADLINE_RENT": "Loyer facial du bail précédent (€/an)",
  "PREVIOUS_LEASE_HEADLINE_RENT_M2": "Loyer facial du bail précédent (€/an/m²)",
  "PREVIOUS_LEASE_TENANT_NAME": "Nom du locataire précédent",
  "PREVIOUS_LEASE_TRADE_NAME": "Enseigne du locataire précédent",
  "PREVIOUS_NET_MARKET_VALUE": "Valeur vénale nette YYYY-2 (€)",
  "PREVIOUS_RENT_AMOUNT_PER_SQM": "Bail précédent - Loyer facial par m² (€/m²/an)",
  "PREVIOUS_REVISION_DATE": "Date de la précédente indexation",
  "PREVIOUS_TENANT_CODE": "Code du locataire précédent",
  "PREVIOUS_TENANT_MOVE_OUT_DATE": "Date de sortie du dernier locataire",
  "PREVIOUS_TENANT_NAME": "Nom du locataire précédent",
  "PRIMARY UNIT": "Lot principal (oui/non) ?",
  "PRIVATE UNIT AREA": "Surface privative du lot (m²)",
  "PRIVATE_UNIT_AREA": "Surface privative du lot (m²)",
  "PRO RATED FINANCIAL VACANCY": "Vacance financière au prorata temporis (€)",
  "PRO_RATED_FINANCIAL_VACANCY": "Vacance financière au prorata temporis (€)",
  "PROPERTY": "Immeuble",
  "PROPERTY BALANCE": "Balance Immeuble",
  "PROPERTY BALANCE SUMMARY": "Balance immeuble agrégée",
  "PROPERTY DISTRIBUTION": "Répartition des immeubles par revenu locatif brut, rendement global, valeur vénale nette et type d'immeuble",
  "PROPERTY MANAGER": "Property Manager",
  "PROPERTY MANAGER CODE": "Code Property Manager",
  "PROPERTY MANAGER REPORT": "Tableau de bord ADB",
  "PROPERTY MANAGER REPORT TRIMESTER": "Tableau de bord ADB - trimestre",
  "PROPERTY SUMMARY": "Résumé immeuble",
  "PROPERTY TAX AMOUNT": "Montant de la taxe foncière (€)",
  "PROPERTY TAX MANAGEMENT FEES": "Frais de gestion de taxe foncière (€)",
  "PROPERTY TOTAL": "Total immeuble",
  "PROPERTY TYPE BREAKDOWN": "Répartition sectorielle (en valeur d'expertise HD)",
  "PROPERTY VALUATION": "Valorisation des immeubles au",
  "PROPERTY VALUATION SHEET": "Valorisation des immeubles",
  "PROPERTY_MANAGER": "Property Manager",
  "PROPERTY_MANAGER_CODE": "Code Property Manager",
  "PROPERTY_MANAGER_NAME": "Property Manager",
  "PROPERTY_TAX_AMOUNT": "Montant de la taxe foncière (€)",
  "PROPERTY_TAX_MANAGEMENT_FEES": "Frais de gestion de taxe foncière (€)",
  "PROVIDER": "Fournisseur",
  "PROVIDER CODE": "Code fournisseur",
  "PROVIDER INVOICES ACCOUNTED": "Factures fournisseurs comptabilisées",
  "PROVIDER INVOICES PAID": "Factures fournisseurs payées",
  "PROVIDER NAME": "Nom du fournisseur",
  "PROVIDER TOTAL": "Total Fournisseur",
  "PROVIDER TYPE CODE": "Code type de fournisseur",
  "PROVIDER TYPE NAME": "Type de fournisseur",
  "PROVIDER_CODE": "Code fournisseur",
  "PROVIDER_NAME": "Nom du fournisseur",
  "PROVIDER_TOTAL": "Total Fournisseur",
  "PROVIDER_TYPE_CODE": "Code Type de fournisseur",
  "PROVIDER_TYPE_NAME": "Type de fournisseur",
  "PROVISIONS FOR EXPENSES": "Provisions pour charges (€)",
  "PROVISIONS FOR EXPENSES WOU": "Provisions pour charges",
  "PURCH WORK PROGRES STATE": "Etat d'avancement des travaux",
  "PURCHASE": "Gestion des acquisitions",
  "PURCHASE ACT SIGNING DATE": "Date de signature de l'acte de vente",
  "PURCHASE ACT SIGNING DT": "Date de la signature de l'acte d'achat",
  "PURCHASE AGREEMENT DATE": "Date d'engagement d'achat",
  "PURCHASE FEES AMOUNT": "Montant des frais d'acquisition (€)",
  "PURCHASE GOAL": "Objectif de l'acquisition",
  "PURCHASE HISTORY €": "Historique des acquisitions - Nombre d'immeubles et valeur vénale nette (€)",
  "PURCHASE HISTORY M2": "Historique des acquisitions - Nombre d'immeubles et surface (m²)",
  "PURCHASE HISTORY RECORD": "Historique des acquisitions",
  "PURCHASE PROGRES STATE": "Etat d'avancement de l'acquisition",
  "PURCHASE STASUS": "Statut de la vente",
  "PURCHASE TOT VALUE": "Prix total de l'immeuble",
  "PURCHASE TOTAL VALUE": "Valeur d'acquisition (€)",
  "PURCHASE TOTAL VALUE SQM": "Valeur d'acquisition par m² (€/m²)",
  "PURCHASE TYPE": "Type d'acquisition",
  "PURCHASE_ACT_SIGNING_DATE": "Date de signature de l'acte de vente",
  "PURCHASE_ACT_SIGNING_DATE_mismatch": "Date de la signature de l'acte d'achat format incorrect",
  "PURCHASE_AGREEMENT_DATE": "Date d'engagement d'achat",
  "PURCHASE_AGREEMENT_DATE_mismatch": "Date d'engagement d'achat format incorrect",
  "PURCHASE_AGREEMENT_DATE_required": "Date d'engagement d'achat obligatoire",
  "PURCHASE_FEES_AMOUNT": "Montant des frais d'acquisition (€)",
  "PURCHASE_FEES_AMOUNT_mismatch": "Montant des frais d'acquisition format incorrect",
  "PURCHASE_FEES_AMOUNT_WOU": "Montant des frais d'acquisition",
  "PURCHASE_STATUS": "Etat d'avancement de l'acquisition",
  "PURCHASE_TYPE": "Type d'acquisition",
  "PURCHASER NAME": "Nom de l'acquéreur",
  "PURCHASER_NAME": "Nom de l'acquéreur",
  "PwdNotContaintFollowSpecialChar": "Le mot de passe ne peut pas contenir les caractères spéciaux \">\" et \"<",
  "QUARTER": "T",
  "QUARTERLY OCCUPANCY EVOLUTION": "Evolution trimestrielle de l'état d'occupation",
  "QuoteColumns": "Encadrement des colonnes par des guillemets",
  "RATE BUDGET N REAL N MINUS 1": "Ecart Budget HT/Réel",
  "RATE REALISED N BUDGETED N": "Taux de réalisation",
  "RATE REALISED N N MINUS 1": "Taux de variation Réel HT",
  "RATE_BUDGET_N_REAL_N_MINUS_1": "Ecart Budget HT YYYY vs Réel YYYY-1",
  "RATE_REALISED_N_BUDGETED_N": "Taux de réalisation YYYY",
  "RATE_REALISED_N_N_MINUS_1": "Taux de variation Réel HT YYYY vs YYYY-1",
  "RE LETTING PERIOD": "Délai de relocation (jours)",
  "RE_LETTING_PERIOD": "Délai de relocation (jours)",
  "REAL ESTATE CASH FLOW": "Cash flow immobilier (€/an)",
  "REAL ESTATE PORTFOLIO": "Sous-jacent immobilier (Société-mère et société-filles)",
  "REAL ESTATE PORTFOLIO BREAKDOWN": "Répartition du patrimoine immobilier au",
  "REAL ESTATE PORTFOLIO EVOLUTION": "Evolution du patrimoine immobilier au",
  "REAL ESTATE PORTFOLIO EVOLUTION SHEET": "Evol. du patrimoine immobilier",
  "REAL_ESTATE_CASH_FLOW": "Cash flow immobilier YYYY-1 (€/an)",
  "REALISED 01 N": "Réalisé Janvier",
  "REALISED 02 N": "Réalisé Février",
  "REALISED 03 N": "Réalisé Mars",
  "REALISED 04 N": "Réalisé Avril",
  "REALISED 05 N": "Réalisé Mai",
  "REALISED 06 N": "Réalisé Juin",
  "REALISED 07 N": "Réalisé Juillet",
  "REALISED 08 N": "Réalisé Aout",
  "REALISED 09 N": "Réalisé Septembre",
  "REALISED 10 N": "Réalisé Octobre",
  "REALISED 11 N": "Réalisé Novembre",
  "REALISED 12 N": "Réalisé Décembre",
  "REALISED ACCOUNTING N M1": "Réalisé comptable",
  "REALISED AMOUNT BT": "Montants réalisés HT",
  "REALISED N": "Réalisé comptable",
  "REALISED N M1": "Réalisé",
  "REALISED N MINUS 1": "Réel HT (€/an)",
  "REALISED N MINUS 2": "Réel HT (€/an)",
  "REALISED N TODATE M1": "Réalisé au",
  "REALISED T1 N": "Réalisé 1T",
  "REALISED T2 N": "Réalisé 2T",
  "REALISED T3 N": "Réalisé 3T",
  "REALISED T4 N": "Réalisé 4T",
  "REALISED TODATE N": "Réalisé au",
  "REALISED_01_N": "Réalisé Janvier YYYY",
  "REALISED_02_N": "Réalisé Février YYYY",
  "REALISED_03_N": "Réalisé Mars YYYY",
  "REALISED_04_N": "Réalisé Avril YYYY",
  "REALISED_05_N": "Réalisé Mai YYYY",
  "REALISED_06_N": "Réalisé Juin YYYY",
  "REALISED_07_N": "Réalisé Juillet YYYY",
  "REALISED_08_N": "Réalisé Aout YYYY",
  "REALISED_09_N": "Réalisé Septembre YYYY",
  "REALISED_10_N": "Réalisé Octobre YYYY",
  "REALISED_11_N": "Réalisé Novembre YYYY",
  "REALISED_12_N": "Réalisé Décembre YYYY",
  "REALISED_ACCOUNTING_N_M1": "Réalisé comptable DDMMYYYY-1",
  "REALISED_N": "Réalisé comptable YYYY",
  "REALISED_N_M1": "Réalisé YYYY-1",
  "REALISED_N_MINUS_1": "Réel HT YYYY-1 (€/an)",
  "REALISED_N_MINUS_2": "Réel HT YYYY-2 (€/an)",
  "REALISED_N_TODATE_M1": "Réalisé au DDMMYYYY-1",
  "REALISED_T1_N": "Réalisé 1T YYYY",
  "REALISED_T2_N": "Réalisé 2T YYYY",
  "REALISED_T3_N": "Réalisé 3T YYYY",
  "REALISED_T4_N": "Réalisé 4T YYYY",
  "REALISED_TODATE_N": "Réalisé au DDMMYYYY",
  "RECONSTITUTION VALUE": "Valeur de reconstitution (€)",
  "RECONSTITUTION_VALUE": "Valeur de reconstitution (€)",
  "RECOV OPERATING EXPENSES": "Charges récupérables (€/an)",
  "RECOV_OPERATING_EXPENSES": "Charges récupérables YYYY-1 (€/an)",
  "RECOVERABLE": "Récupérable",
  "RECOVERABLE 1": "Récupérable / Non récupérable",
  "RECOVERABLE AMOUNT": "Valeur de référence (€)",
  "RECOVERABLE EXPENSES": "Charges récupérables (€)",
  "RECOVERABLE EXPENSES WOU": "Charges récupérables",
  "RECOVERABLE GARB COLLECT TAX": "% récupérable - Taxe d'enlèvement des ordures ménagères",
  "RECOVERABLE MARKETING FEES": "% récupérable - Frais de marketing",
  "RECOVERABLE NOT RECOVERABLE": "Récupérable / Non récupérable",
  "RECOVERABLE_1": "Récupérable / Non récupérable",
  "RECOVERABLE_AMOUNT": "Valeur de référence (€)",
  "RECOVERABLE_EXPENSES": "Charges récupérables (€/an)",
  "RECOVERABLE_EXPENSES_WOU": "Charges récupérables",
  "RECOVERABLE_GARB_COLLECT_TAX": "% récupérable - Taxe d'enlèvement des ordures ménagères",
  "RECOVERABLE_MARKETING_FEES": "% récupérable - Frais de marketing",
  "RECOVERED EXPENSES": "Charges récupérées (€)",
  "RECOVERED EXPENSES WOU": "Charges récupérées",
  "RECUPERABLE": "Récupérable ?",
  "REFERENCE VALUE": "Valeur de référence (€)",
  "REFERENCE_NUMBER": "N° de la pièce",
  "REFURBISHMENT AND MAINTENANCE MONITORING": "Suivi des travaux",
  "REFURBISHMENT CLAUSE": "Clause de travaux",
  "REFURBISHMENT_CLAUSE": "Clause de travaux",
  "REGISTRATION FEES AMOUNT": "Montant des droits d'enregistrement (€)",
  "REGISTRATION_FEES_AMOUNT": "Montant des droits d'enregistrement (€)",
  "REGISTRATION_FEES_AMOUNT_mismatch": "Montant des droits d'enregistrement format incorrect",
  "REGISTRATION_FEES_AMOUNT_WOU": "Montant des droits d'enregistrement",
  "REGUL_CHARGE_SUB_ITEM": "Sous-rubrique de facturation",
  "REGULAR CHARGES ITEM CODE": "Code rubrique facturation",
  "REGULAR CHARGES ITEM LABEL": "Rubrique de facturation",
  "REGULAR_CHARGES_ITEM_CODE": "Code de la rubrique de facturation",
  "REGULAR_CHARGES_ITEM_LABEL": "Rubrique de facturation",
  "RELETTING REPORT": "Etat des relocations",
  "RELETTING REPORT RAW": "Etat des relocations brut",
  "REMAINING TERM TO THE LEASE TERMINATION": "Durée résiduelle jursqu'à la date de sortie (a-m-j)",
  "REMAINING_TERM_TO_THE_LEASE_TERMINATION": "Durée résiduelle jursqu'à la date de sortie (a-m-j)",
  "REMAINING TERM TO THE TERMINATION": "Durée résiduelle jursqu'à la date de sortie (a-m-j)",
  "REMAINING_TERM_TO_THE_TERMINATION": "Durée résiduelle jursqu'à la date de sortie (a-m-j)",
  "RENT ADJUSTMENTS": "Mesures d'accompagnement (€)",
  "RENT ADJUSTMENTS WOU": "Mesures d'accompagnement",
  "RENT FREE 1 END DATE": "Date de fin de la franchise 1",
  "RENT FREE 1 PERIOD": "Durée de la franchise 1 (mois)",
  "RENT FREE 1 START DATE": "Date de début de la franchise 1",
  "RENT FREE 2 END DATE": "Date de fin de la franchise 2",
  "RENT FREE 2 PERIOD": "Durée de la franchise 2 (mois)",
  "RENT FREE 2 START DATE": "Date de début de la franchise 2",
  "RENT FREE 3 END DATE": "Date de fin de la franchise 3",
  "RENT FREE 3 PERIOD": "Durée de la franchise 3 (mois)",
  "RENT FREE 3 START DATE": "Date de début de la franchise 3",
  "RENT FREE AMOUNT 1": "Montant concerné par la franchise 1",
  "RENT FREE AMOUNT 2": "Montant concerné par la franchise 2",
  "RENT FREE AMOUNT 3": "Montant concerné par la franchise 3",
  "RENT FREE END DATE": "Date de fin de la franchise restante",
  "RENT FREE LEFT AMOUNT": "Franchise restante du bail en montant à la date de début du rapport (€)",
  "RENT FREE LEFT NB MONTH": "Franchise restante en nombre de mois à la date de début du rapport",
  "RENT FREE NB MONTH": "Franchise totale en nombre de mois depuis le début du bail",
  "RENT FREE PERIOD": "Durée de la franchise (a-m-j)",
  "RENT FREE TOTAL AMOUNT": "Franchise totale du bail en montant depuis le début du bail (€)",
  "RENT MARKET VALUE": "Valeur locative de marché (€/m²/an)",
  "RENT RECEIVABLE CUL BIL TERM": "Loyer effectif quittancé du terme en cours (€)",
  "RENT RECEIVABLE CUR BIL TERM": "Loyer effectif quittancé du terme en cours (€)",
  "RENT RECEIVABLE YEAR TO DATE": "Loyer effectif quittancé Year-to-Date (€)",
  "RENT REVIEW FREQUENCY": "Fréquence de révision",
  "RENT REVIEW LEVERAGE": "Pourcentage de révision",
  "RENT TO SALES RATIO": "Taux d'effort sur le loyer minimum garanti TTC",
  "RENT UPLIFT": "% d'augmentation depuis le début du bail",
  "RENT VARIATION": "Revalorisation",
  "RENT VARIATION AND REVERSIONARY POTENTIAL": "Revalorisation des loyers et potentiel de réversion",
  "RENT VARIATION RATE": "Taux de revalorisation du loyer",
  "RENT_FREE 1 END DATE": "Date de fin de la franchise 1",
  "RENT_FREE 1 PERIOD": "Durée de la franchise 1 (mois)",
  "RENT_FREE 1 START DATE": "Date de début de la franchise 1",
  "RENT_FREE 2 END DATE": "Date de fin de la franchise 2",
  "RENT_FREE 2 PERIOD": "Durée de la franchise 2 (mois)",
  "RENT_FREE 2 START DATE": "Date de début de la franchise 2",
  "RENT_FREE 3 END DATE": "Date de fin de la franchise 3",
  "RENT_FREE 3 PERIOD": "Durée de la franchise 3 (mois)",
  "RENT_FREE 3 START DATE": "Date de début de la franchise 3",
  "RENT_FREE AMOUNT": "Franchise en cours (€)",
  "RENT_FREE AMOUNT 1": "Franchise de loyer en année 1 (€)",
  "RENT_FREE AMOUNT 2": "Franchise de loyer en année 2 (€)",
  "RENT_FREE AMOUNT 3": "Franchise de loyer en année 3 (€)",
  "RENT_FREE END DATE": "Date de fin de la franchise",
  "RENT_FREE PERIOD": "Durée de la franchise 2 (a-m-j)",
  "RENT_FREE START DATE": "Date de début de la franchise",
  "RENT_FREE_1_END_DATE": "Date de fin de la franchise 1",
  "RENT_FREE_1_PERIOD": "Durée de la franchise 1 (mois)",
  "RENT_FREE_1_START_DATE": "Date de début de la franchise 1",
  "RENT_FREE_2_END_DATE": "Date de fin de la franchise 2",
  "RENT_FREE_2_PERIOD": "Durée de la franchise 2 (mois)",
  "RENT_FREE_2_START_DATE": "Date de début de la franchise 2",
  "RENT_FREE_3_END_DATE": "Date de fin de la franchise 3",
  "RENT_FREE_3_PERIOD": "Durée de la franchise 3 (mois)",
  "RENT_FREE_3_START_DATE": "Date de début de la franchise 3",
  "RENT_FREE_AMOUNT": "Franchise en cours (€)",
  "RENT_FREE_AMOUNT_1": "Montant concerné par la franchise 1",
  "RENT_FREE_AMOUNT_2": "Montant concerné par la franchise 2",
  "RENT_FREE_AMOUNT_3": "Montant concerné par la franchise 3",
  "RENT_FREE_END_DATE": "Date de fin de la franchise restante",
  "RENT_FREE_LEFT_AMOUNT": "Franchise restante du bail en montant à la date de début du rapport (€)",
  "RENT_FREE_LEFT_NB_MONTH": "Franchise restante en nombre de mois à la date de début du rapport",
  "RENT_FREE_NB_MONTH": "Franchise totale en nombre de mois depuis le début du bail",
  "RENT_FREE_PERIOD": "Durée de la franchise (a-m-j)",
  "RENT_FREE_START_DATE": "Date de début de la franchise",
  "RENT_FREE_TOTAL_AMOUNT": "Franchise totale du bail en montant depuis le début du bail (€)",
  "RENT_INIT_VARIATION_RATE": "Taux de revalorisation du loyer par rapport au loyer initial",
  "RENT_MARKET_VALUE": "Valeur locative de marché (€/m²/an)",
  "RENT_RECEIVABLE_CUL_BIL_TERM": "Loyer effectif quittancé du terme en cours (€)",
  "RENT_RECEIVABLE_CUR_BIL_TERM": "Loyer effectif quittancé du terme en cours (€)",
  "RENT_RECEIVABLE_YEAR_TO_DATE": "Loyer effectif quittancé Year-to-Date (€)",
  "RENT_REVIEW_FREQUENCY": "Fréquence de révision",
  "RENT_REVIEW_LEVERAGE": "Pourcentage de révision",
  "RENT_TO_SALES_RATIO": "Taux d'effort sur le loyer minimum garanti TTC",
  "RENT_UPLIFT": "% d'augmentation depuis le début du bail",
  "RENT_VARIATION": "Taux de revalorisation du loyer",
  "RENT_VARIATION_RATE": "Taux de revalorisation du loyer",
  "RENTAL EXPENSES": "Charges d'exploitation (OPEX) (€)",
  "RENTAL EXPENSES WOU": "Charges d'exploitation (OPEX)",
  "RENTAL INCOME": "Revenus locatifs (€)",
  "RENTAL INCOME MONITORING": "Suivi du quittancement au",
  "RENTAL INCOME MONITORING COMPARE": "Comparaison du quittancement au",
  "RENTAL INCOME MONITORING COMPARE RAW": "Comparaison du quittancement brut au",
  "RENTAL INCOME MONITORING RAW": "Suivi du quittancement brut au",
  "RENTAL INCOME MONITORING SHEET": "Suivi du quittancement brut",
  "RENTAL INCOME WOU": "Revenus locatifs",
  "RENTAL SITUATION": "Situation locative",
  "RENTAL TERM LABEL": "Terme du bail",
  "RENTAL VACANCY": "Vacance locative",
  "RENTAL VACANT AREA": "Surface vacante disponible (m²)",
  "RENTAL VACANT AREA RATE": "% de la surface vacante totale",
  "RENTAL_TERM_LABEL": "Terme du bail",
  "RENTAL_VACANT_AREA": "Surface vacante disponible (m²)",
  "RENTAL_VACANT_AREA_RATE": "% de la surface vacante totale",
  "RENTED AREA": "Surface occupée (m²)",
  "RENTED_AREA": "Surface occupée (m²)",
  "REPAIR CLAUSE": "Clause de réparation",
  "REPAIR_CLAUSE": "Clause de réparation",
  "REPARTITION CODE": "Code répartition",
  "REPARTITION LABEL": "Libellé répartition",
  "REPARTITION_CODE": "Code répartition",
  "REPARTITION_LABEL": "Libellé répartition",
  "REPORT COMMENTS": "Commentaires",
  "REPORT DATE": "Date de situation",
  "REPORT_COMMENTS": "Commentaires",
  "REPORT_EXPORT_ABE": "Reporting Mouvements bancaires",
  "REPORT_EXPORT_ALE": "Reporting Gestion des alertes",
  "REPORT_EXPORT_AUT": "Reporting Piste d'audit",
  "REPORT_EXPORT_BUI": "Reporting Export fiche immeuble",
  "REPORT_EXPORT_BUM": "Reporting Fiche budgétaire",
  "REPORT_EXPORT_CBP": "Reporting Gestion des périodes de ventilation",
  "REPORT_EXPORT_CMO": "Reporting Suivi des encaissements",
  "REPORT_EXPORT_CVA": "Reporting Valorisation des sociétés",
  "REPORT_EXPORT_DEK": "Reporting Reporting Deka",
  "REPORT_EXPORT_DOP": "Reporting Impayés",
  "REPORT_EXPORT_DTZ": "Reporting Situation locative",
  "REPORT_EXPORT_EVP": "Reporting Evolution du patrimoine immobilier",
  "REPORT_EXPORT_FOS": "Reporting Gestion des données détenteurs",
  "REPORT_EXPORT_FUN": "Reporting Export fiche société",
  "REPORT_EXPORT_Glo": "Reporting Paramètres globaux",
  "REPORT_EXPORT_HOT": "Reporting Suivi des baux non renouvelés",
  "REPORT_EXPORT_LEM": "Reporting Suivi des baux",
  "REPORT_EXPORT_LES": "Reporting Echéancier des baux commerciaux",
  "REPORT_EXPORT_MDM": "Reporting Paramétrage des données",
  "REPORT_EXPORT_MIT": "Reporting Gestion des interfaces",
  "REPORT_EXPORT_OPB": "Reporting Analyse des impayés",
  "REPORT_EXPORT_OPM": "Reporting Suivi des impayés",
  "REPORT_EXPORT_OUT": "Reporting Balance agée",
  "REPORT_EXPORT_PAT": "Reporting Cessions en cours",
  "REPORT_EXPORT_PBA": "Reporting Balance immeuble",
  "REPORT_EXPORT_POB": "Reporting Répartition du patrimoine",
  "REPORT_EXPORT_PPT": "Reporting Acquisitions en cours",
  "REPORT_EXPORT_PVA": "Reporting Valorisation des immeubles",
  "REPORT_EXPORT_RES": "Reporting Situation locative détaillée",
  "REPORT_EXPORT_RIM": "Reporting Suivi du quittancement",
  "REPORT_EXPORT_RLE": "Reporting Gestion des données du bail",
  "REPORT_EXPORT_RPA": "Reporting Gestion des cessions",
  "REPORT_EXPORT_RPB": "Reporting Référentiel immeubles",
  "REPORT_EXPORT_RPF": "Reporting Référentiel fonds / sociétés",
  "REPORT_EXPORT_RPM": "Reporting Gestion des VLM",
  "REPORT_EXPORT_RPN": "Reporting Gestion des VNC",
  "REPORT_EXPORT_RPP": "Reporting Gestion des acquisitions",
  "REPORT_EXPORT_RPT": "Reporting Gestion des modèles de rapport",
  "REPORT_EXPORT_RPV": "Reporting Gestion des données d'expertise",
  "REPORT_EXPORT_RPY": "Reporting Gestion des taux de rendement",
  "REPORT_EXPORT_SDG": "Etat des DG et des cautions",
  "REPORT_EXPORT_TEM": "Reporting Etat des mouvements locataires",
  "REPORT_EXPORT_TET": "Reporting Suivi du taux de rotation",
  "REPORT_EXPORT_TRH": "Reporting Historique des transactions",
  "REPORT_EXPORT_TS": "Reporting Etat locatif",
  "REPORT_EXPORT_USL": "Reporting Gestion des utilisateurs",
  "REPORT_EXPORT_VAC": "Reporting Analyse de la vacance",
  "REPORT_EXPORT_VAU": "Reporting Etat des lots vacants",
  "REPORT_EXPORT_YIM": "Reporting Suivi des rendements",
  "REPORT_INTERFACE": "Etat locatif",
  "REPOSITORY ARBITRAGE": "Référentiel des arbitrages",
  "REPOSITORY BUILDING": "Référentiel des immeubles",
  "REPOSITORY FUND": "Référentiel société",
  "REPOSITORY PORTFOLIO": "Référentiel des portefeuilles",
  "REPOSITORY PURCHASE": "Référentiel des acquisitions",
  "REPOSITORY SECTOR": "Référentiel des secteurs",
  "REPOSITORY_ARBITRAGE": "Référentiel des arbitrages",
  "REPOSITORY_BUILDING": "Référentiel des immeubles",
  "REPOSITORY_FUND": "Référentiel des sociétés",
  "REPOSITORY_PORTFOLIO": "Référentiel des portefeuilles",
  "REPOSITORY_PURCHASE": "Référentiel des acquisitions",
  "REPOSITORY_SECTOR": "Référentiel des secteurs",
  "RESERVE FOR DEPRECIATION": "Provisions pour dépréciation (€)",
  "RESERVE_FOR_DEPRECIATION": "Provisions pour dépréciation (€)",
  "RESIDENTIAL ABATEMENT": "Franchise en cours (€)",
  "RESIDENTIAL ANNUAL AMOUNT": "Montant annuel (€/an)",
  "RESIDENTIAL AREA": "Surface à usage d'habitation (m²)",
  "RESIDENTIAL GROSS HEADLINE RENT": "Loyer facial brut (€/an)",
  "RESIDENTIAL GROSS HEADLINE RENT M2": "Loyer facial brut par m² (€/m²/an)",
  "RESIDENTIAL REAL ESTATE": "Immobilier residentiel",
  "RESIDENTIAL RENTED AREA": "Surface occupée (m²)",
  "RESIDENTIAL TOTAL AREA": "Surface totale (m²)",
  "RESIDENTIAL VACANT AREA": "Surface vacante (m²)",
  "RESIDENTIAL_AREA": "Surface à usage d'habitation (m²)",
  "RESULT PENDING ASSIGN BENEFIT": "Résultat bénéficiaire en attente d'affectation (€)",
  "RESULT PENDING ASSIGN DEFICIT": "Résultat déficitaire en attente d'affectation (€)",
  "RESULT_PENDING_ASSIGN_BENEFIT": "Résultat bénéficiaire en attente d'affectation (€)",
  "RESULT_PENDING_ASSIGN_DEFICIT": "Résultat déficitaire en attente d'affectation (€)",
  "RETAIL AREA": "Surface à usage de commerce (m²)",
  "RETAIL_AREA": "Surface à usage de commerce (m²)",
  "RETAINED EARNINGS": "Report à nouveau (€)",
  "RETAINED_EARNINGS": "Report à nouveau (€)",
  "RETURN ON EQUITY LEVERED": "Retour sur capitaux employés (ROCE)",
  "RETURN ON EQUITY UNLEVERED": "Retour sur fonds propres (ROE)",
  "REVERSION TO RENTAL MARKET VALUE": "Réversion à la valeur locative de marché",
  "REVERSIONARY POTENTIAL": "Potentiel de réversion",
  "REVERSIONARY YIELD": "Taux théorique net",
  "REVERSIONARY_POTENTIAL": "Potentiel de réversion",
  "REVISION PERIODICITY CODE": "Fréquence de révision",
  "REVISION_PERIODICITY_CODE": "Code fréquence de révision",
  "RISK NEXT DATE": "Prochaine date de break",
  "ROW LABEL": "Libellé de la ligne",
  "ROW_CODE": "Code de la ligne",
  "ROW_LABEL": "Libellé de la ligne",
  "RRE ABATEMENT": "Immobilier résidentiel - Franchise en cours (€)",
  "RRE GROSS HEADLINE RENT": "Immobilier résidentiel - Loyer facial brut (€/an)",
  "RRE GROSS HEADLINE RENT M2": "Immobilier résidentiel - Loyer facial brut par m² (€/m²/an)",
  "RRE RENTED AREA": "Immobilier résidentiel - Surface occupée (m²)",
  "RRE TOTAL AREA": "Immobilier résidentiel - Surface totale (m²)",
  "RRE VACANT AREA": "Immobilier résidentiel - Surface vacante (m²)",
  "RRE_ABATEMENT": "Immobilier résidentiel - Franchise en cours (€)",
  "RRE_GROSS_HEADLINE_RENT": "Immobilier résidentiel - Loyer facial brut (€/an)",
  "RRE_GROSS_HEADLINE_RENT_M2": "Immobilier résidentiel - Loyer facial brut par m² (€/m²/an)",
  "RRE_OCCUPIED_AREA_SQM": "Immobilier résidentiel - Surface occupée (m²)",
  "RRE_TOTAL_AREA_SQM": "Immobilier résidentiel - Surface totale (m²)",
  "RRE_VACANT_AREA_SQM": "Immobilier résidentiel - Surface vacante (m²)",
  "SALE ACT SIGNING DATE": "Date de signature de l'acte de vente",
  "SALE AGREEMENT DATE": "Date de la promesse de vente",
  "SALE DECISION DATE": "Date de décision de vente",
  "SALE EXPECTED DATE": "Date de vente prévisionnelle",
  "SALE EXPECTED PRICE": "Prix de vente estimé (€)",
  "SALE PRICE AMOUNT": "Montant du prix de vente (€)",
  "SALE PROGRES STATE": "Etat d'avancement de la cession",
  "SALE VACANT AREA": "Surface vacante pour vente (m²)",
  "SALE VACANT AREA RATE": "% de la surface vacante totale",
  "SALE_ACT_SIGNING_DATE": "Date de signature de l'acte de vente",
  "SALE_ACT_SIGNING_DATE_mismatch": "Date de la signature de l'acte de vente format incorrect",
  "SALE_ACT_SIGNING_DATE_required": "Date de la signature de l'acte de vente obligatoire",
  "SALE_AGREEMENT_DATE": "Date de la promesse de vente",
  "SALE_AGREEMENT_DATE_mismatch": "Date de la promesse de vente format incorrect",
  "SALE_AGREEMENT_DATE_required": "Date de la promesse de vente obligatoire",
  "SALE_COMMITMENT_DATE": "Date de vente prévisionnelle",
  "SALE_COMMITMENT_DATE_mismatch": "Date de vente prévisionnelle format incorrect",
  "SALE_DECISION_DATE": "Date de décision de vente",
  "SALE_DECISION_DATE_mismatch": "Date de décision de vente format incorrect",
  "SALE_EXPECTED_DATE": "Date de vente prévisionnelle",
  "SALE_EXPECTED_PRICE": "Prix de vente estimé (€)",
  "SALE_EXPECTED_PRICE_mismatch": "Prix de vente estimé format incorrect",
  "SALE_EXPECTED_PRICE_WOU": "Prix de vente estimé",
  "SALE_PRICE_AMOUNT": "Montant du prix de vente (€)",
  "SALE_PRICE_AMOUNT_mismatch": "Montant du prix de vente format incorrect",
  "SALE_PRICE_AMOUNT_WOU": "Montant du prix de vente",
  "SALE_VACANT_AREA": "Surface vacante pour vente (m²)",
  "SALE_VACANT_AREA_RATE": "% de la surface vacante totale",
  "SEARCH": "Rechercher",
  "SEARCH CRITERIA": "Critères de recherche",
  "SECTOR CODE": "Code secteur",
  "SECTOR LABEL": "Marché immobilier",
  "SECTOR_CODE": "Code secteur",
  "SECURIT DEPOSIT IN MONTH": "Dépôt de garantie en mois",
  "SECURIT_DEPOSIT_IN_MONTH": "Dépôt de garantie en mois",
  "SECURITY DEPOSIT BANK ACCOUNT": "Compte bancaire Dépôt de garantie",
  "SECURITY DEPOSIT CALLS": "Dépôt de garantie appelé (€)",
  "SECURITY DEPOSIT RECEIVABLE": "Dépôt de garantie à encaisser (€)",
  "SECURITY DEPOSIT REFUNDS": "Dépôt de garantie reversé (€)",
  "SECURITY DEPOSITS GUARANTEES": "Etat des DG et des cautions",
  "SECURITY_DEPOSIT_CALLS": "Dépôt de garantie appelé (€)",
  "SECURITY_DEPOSIT_RECEIVABLE": "Dépôt de garantie à encaisser (€)",
  "SECURITY_DEPOSIT_REFUNDS": "Dépôt de garantie reversé (€)",
  "SelectLaunchDate": "Sélectionner une date de lancement pour l’interface spécifique",
  "SelectReportModel": "Sélectionner un modèle de rapport standard",
  "SELLER CHARGED NET PRICE": "Prix net demandé par le vendeur (€)",
  "SELLER NAME": "Nom du vendeur",
  "SELLER_CHARGED_NET_PRICE": "Prix net demandé par le vendeur (€)",
  "SELLER_CHARGED_NET_PRICE_mismatch": "Prix net demandé par le vendeur format incorrect",
  "SELLER_CHARGED_NET_PRICE_WOU": "Prix net demandé par le vendeur",
  "SELLER_NAME": "Nom du vendeur",
  "SEPTEMBER_AMOUNT": "Septembre (€)",
  "SERVICE CHARGES": "Provisions pour charges (€/an)",
  "SERVICE CHARGES CAPS": "Forfait de charges (€/an)",
  "SERVICE CHARGES CAPS M2": "Forfait de charges par m² (€/m²/an)",
  "SERVICE CHARGES M2": "Provisions pour charges par m² (€/m²/an)",
  "SERVICE LABEL": "Nom du Service",
  "SERVICE_CHARGES": "Provisions pour charges (€/an)",
  "SERVICE_CHARGES_CAPS": "Forfait de charges (€/an)",
  "SERVICE_CHARGES_CAPS_M2": "Forfait de charges par m² (€/m²/an)",
  "SERVICE_CHARGES_M2": "Provisions pour charges par m² (€/m²/an)",
  "SERVICE_CODE": "Code service",
  "SERVICE_LABEL": "Nom du Service",
  "SHARE ACQUISITION PRICE": "Prix de revient des titres (€)",
  "SHARE CAPITAL": "Capital (€)",
  "SHARE PREMIUM": "Prime d'émission (€)",
  "SHARE PREMIUM DISTRIBUTED": "Distribution de prime d'émission (€)",
  "SHARE_PREMIUM": "Prime d'émission (€)",
  "SHARE_WITH_OTHER_USERS": "Partagé avec les autres utilisateurs",
  "SHAREHOLDER CURRENT ACCOUNT": "Avances en compte courant (€)",
  "SHAREHOLDER CURRENT ACNT": "N° du compte courant (si détenteur)",
  "SHAREHOLDER LOANS CONTRIBUT": "N° du compte du prêt d'associé(si détenteur)",
  "SHAREHOLDER LOANS CONTRIBUTED": "Prêts d'associés (€)",
  "SHAREHOLDER_CURRENT_ACCOUNT": "Avances en compte courant (€)",
  "SHAREHOLDER_LOAN_ACCOUNT": "Prêts d'associés (€)",
  "SHAREHOLDER_LOANS_CONTRIBUTE": "Prêts d'associés du détenteur (€)",
  "SHAREHOLDER_LOANS_CONTRIBUTED": "Prêts d'associés du détenteur (€)",
  "SHOW_ALL": "Tout",
  "SIMPLIFIED P AND L": "Compte de résultat simplifié",
  "SIRET NUMBER": "N° de SIREN",
  "SIRET_NUMBER": "N° de SIREN",
  "SITE NAME": "Site de gestion",
  "SITE_CODE": "Code site de gestion",
  "SITE_NAME": "Site de gestion",
  "SOCIAL DATA": "Données sociales",
  "SOCIAL_DATA": "Données sociales",
  "SOLD_OWNER_CODE": "Code propriétaire vendeur",
  "SOLD_OWNER_NAME": "Nom propriétaire vendeur",
  "SPACE PRODUCTIVITY": "Rendement du chiffre d'affaires par m² (€/m²/an)",
  "SPACE_PRODUCTIVITY": "Rendement du chiffre d'affaires YYYY-1 par m² (€/m²/an)",
  "SQM": "m²",
  "ST1": "Sous Total 1",
  "ST2": "Sous Total 2",
  "ST3": "Sous Total 3",
  "STAIRCASE": "N° de l'escalier",
  "START MANAGEMENT DATE": "Date de début de gestion",
  "START_DATE": "Date de début",
  "START_MANAGEMENT_DATE": "Date de début de gestion",
  "START_MANAGEMENT_DATE_mismatch": "Date de début de gestion format incorrect",
  "STATUORY RESERVES": "Réserves indisponibles (€)",
  "STATUORY_RESERVES": "Réserves indisponibles (€)",
  "STATUS LEASE": "Statut du bail",
  "STATUS PURCHASE": "Statut de l'acquisition",
  "STATUS SALE": "Statut de la cession",
  "STATUS_LEASE": "Statut du bail",
  "STATUT": "Etat d'avancement de l'acquisition",
  "STATUTORY INTERESTS": "Intérêts statutaires (€)",
  "STEP 1 START DATE": "Date de début du palier 1",
  "STEP 2 START DATE": "Date de début du palier 2",
  "STEP 3 START DATE": "Date de début du palier 3",
  "STEP 4 START DATE": "Date de début du palier 4",
  "STEP 5 START DATE": "Date de début du palier 5",
  "STEP 6 START DATE": "Date de début du palier 6",
  "STEP_1_START_DATE": "Date de début du palier 1",
  "STEP_2_START_DATE": "Date de début du palier 2",
  "STEP_3_START_DATE": "Date de début du palier 3",
  "STEP_4_START_DATE": "Date de début du palier 4",
  "STEP_5_START_DATE": "Date de début du palier 5",
  "STEP_6_START_DATE": "Date de début du palier 6",
  "STEPPED RENT": "Loyer palier (oui/non) ?",
  "STEPPED RENT 1": "Loyer palier 1 (€)",
  "STEPPED RENT 2": "Loyer palier 2 (€)",
  "STEPPED RENT 3": "Loyer palier 3 (€)",
  "STEPPED RENT 4": "Loyer palier 4 (€)",
  "STEPPED RENT 5": "Loyer palier 5 (€)",
  "STEPPED RENT 6": "Loyer palier 6 (€)",
  "STEPPED_RENT": "Loyer palier (oui/non) ?",
  "STEPPED_RENT_1": "Loyer palier 1 (€)",
  "STEPPED_RENT_2": "Loyer palier 2 (€)",
  "STEPPED_RENT_3": "Loyer palier 3 (€)",
  "STEPPED_RENT_4": "Loyer palier 4 (€)",
  "STEPPED_RENT_5": "Loyer palier 5 (€)",
  "STEPPED_RENT_6": "Loyer palier 6 (€)",
  "STORAGE AREA": "Surface logistique (m²)",
  "STORAGE_AREA": "Surface logistique (m²)",
  "STRUCTURE CAPITAL": "Structure du capital",
  "SUB_BUDGET_CODE": "Code sous-budget",
  "SUB_BUDGET_LABEL": "Libellé sous-budget",
  "SUB_DESTINATION_CODE": "Code sous-destination",
  "SUB_DESTINATION_LABEL": "Libellé sous-destination",
  "SUBMISSION_DATE": "Date d'envoi",
  "SubmissionDate": "Date d'envoi",
  "SubmissionDate1": "Date d'envoi 1",
  "SubmissionDate2": "Date d'envoi 2",
  "SUBSIDIARY OF GROUP": "Filiale du groupe",
  "SUBSIDIARY_OF_GROUP": "Filiale du groupe",
  "SUSPENSE ACCOUNT": "Compte d'ordre (€)",
  "SUSPENSE_ACCOUNT": "Compte d'ordre (€)",
  "tabCollectionMonitoringBuilding": "Par immeuble",
  "tabCollectionMonitoringBuildingNature": "Par immeuble",
  "tabCollectionMonitoringBuildingNatureSum": "Par type d'immeuble cumulé",
  "tabCollectionMonitoringBuildingSum": "Par immeuble cumulé",
  "tabCollectionMonitoringFund": "Par société",
  "tabCollectionMonitoringFundSum": "Par société cumulé",
  "tabCollectionMonitoringInvoice": "Par facture",
  "tabCollectionMonitoringInvoiceSum": "Par facture cumulé",
  "tabCollectionMonitoringInvoicingItem": "Par rubrique de facturation",
  "tabCollectionMonitoringInvoicingItemSum": "Par rubrique de facturation cumulé",
  "tabCollectionMonitoringLease": "Par bail",
  "tabCollectionMonitoringLeaseSum": "Par bail cumulé",
  "tabCollectionMonitoringOwner": "Par propriétaire",
  "tabCollectionMonitoringOwnerSum": "Par propriétaire cumulé",
  "tabCollectionMonitoringPortfolio": "Par portefeuille",
  "tabCollectionMonitoringPortfolioSum": "Par portefeuille cumulé",
  "tabHoldoverTenancyBuildingNature": "Par type d'immeuble",
  "tabHoldoverTenancyLeaseNature": "Par type de bail",
  "TABLE BY BUILDING NATURE": "Tableau par type d'immeuble",
  "TABLE BY PROPERTY TYPE": "Tableau par typologie",
  "TABLE NAME": "Nom de la table",
  "TABLE_NAME": "Nom de la table",
  "Table1Attr1": "Nom de l'immeuble",
  "Table1Attr2": "Adresse",
  "Table1Attr3": "Portefeuille",
  "Table1Attr4": "Données du",
  "Table1Attr5": "Code de l'immeuble",
  "Table2Attr1": "Type d'immeuble",
  "Table2Attr10": "Surface totale",
  "Table2Attr11": "Valeur vénale brute",
  "Table2Attr12": "Surface habitable",
  "Table2Attr13": "Nombre de locataires",
  "Table2Attr14": "Surface utile",
  "Table2Attr15": "Nombre de baux",
  "Table2Attr16": "Surface utile pondérée",
  "Table2Attr17": "Nombre de lots",
  "Table2Attr18": "Surface commerciale locative",
  "Table2Attr19": "Nombre de parkings",
  "Table2Attr2": "Date de construction",
  "Table2Attr3": "Usage(s) secondaire(s)",
  "Table2Attr4": "Date d'acquisition",
  "Table2Attr5": "Nombre de bâtiments",
  "Table2Attr6": "Situation juridique",
  "Table2Attr7": "Nombre de niveaux",
  "Table2Attr8": "Valeur vénale nette",
  "Table2Attr9": "Au",
  "Table3Attr2": "Mesures d'accompagnement",
  "Table3Attr4": "Impayés",
  "Table3Attr5": "Loyer encaissé",
  "Table3Attr8": "Loyer double net",
  "Table4Attr1": "Charges non récupérables",
  "Table4Attr2": "Charges récupérables",
  "Table4Attr3": "Dont : Charges récupérées",
  "Table4Attr4": "Dont : Charges non récupérées",
  "Table4Attr5": "Charges d'exploitation totales",
  "Table4Attr6": "Provisions pour charges",
  "tabLeaseMonitoringBuildingNature": "Par type d'immeuble",
  "tabLeaseMonitoringLeaseNature": "Par type de bail",
  "tabPhysicalVacancyByM2Financial": "Vacance financière",
  "tabPhysicalVacancyByM2M2": "Surface vacante",
  "tabRealEstatePortfolioBreakdownByNature": "Tableau par type d'immeuble",
  "tabRealEstatePortfolioBreakdownByType": "Tableau par typologie",
  "tabRealEstatePortfolioEvolutionBuildingNature": "Par type d'immeuble",
  "tabRealEstatePortfolioEvolutionYear": "Par année",
  "tabRegularChargesMonitoringBuilding": "Par immeuble",
  "tabRegularChargesMonitoringBuildingNature": "Par type d'immeuble",
  "tabRegularChargesMonitoringInvoicingItem": "Par rubrique de facturationé",
  "tabRegularChargesMonitoringLease": "Par bail",
  "tabRentalIncomeMonitoringBuildingNature": "Par type d'immeuble",
  "tabRentalIncomeMonitoringYear": "Par année",
  "tabYieldMonitoring": "Suivi des rendements",
  "tabYieldMonitoringCapiGross": "Taux de capitalisation bruts",
  "tabYieldMonitoringGlobalReturns": "Rendements périodiques",
  "tabYieldMonitoringGross": "Taux de rendement bruts",
  "tabYieldMonitoringNet": "Taux de rendement nets",
  "TARGET YIELD": "Taux de rendement attendu",
  "TAX": "Fiscalité",
  "TAX EXEMPTION": "Exonération de taxe (oui/non) ?",
  "TAX_EXEMPTION": "Exonération de taxe (oui/non) ?",
  "tbFundDataStartDate_mismatch": "Date de mise à jour format incorrect",
  "tbFundDataStartDate_required": "Date de mise à jour obligatoire",
  "tbInceptionDate_mismatch": "Date de création format incorrect",
  "tbInceptionDate_required": "Date de création obligatoire",
  "TEN GONE NO LIT OUT PAY RATE": "Taux d'impayés par locataires partis autres",
  "TEN PRES NO LIT OUT PAY RATE": "Taux d'impayés par les locataires présents autres",
  "TEN PRESENT LIT OUT PAY RATE": "Taux d'impayés par les locataires présents et en contentieux",
  "TEN_GONE_NO_LIT_OUT_PAY_RATE": "Taux d'impayés par les locataires partis autres",
  "TEN_PRES_NO_LIT_OUT_PAY_RATE": "Taux d'impayés par les locataire présents autres",
  "TEN_PRESENT_LIT_OUT_PAY_RATE": "Taux d'impayés par les locataire présents en contentieux",
  "TENANCY MONITORING": "Etat des mouvements locataires",
  "TENANT": "Locataire",
  "TENANT ARRIVAL DATE": "Date d'entrée du locataire",
  "TENANT CODE": "Code locataire",
  "TENANT EXIT DATE": "Date de sortie du locataire",
  "TENANT GONE LIT OUT PAY RATE": "Taux d'impayés par locataires partis et en contentieux",
  "TENANT GONE OUT PAY RATE": "Taux d'impayés par locataires partis",
  "TENANT INDUSTRY": "Secteur d'activité",
  "TENANT NAME": "Nom du locataire",
  "TENANT PRESENCE": "Présence du locataire",
  "TENANT PRESENT OUT PAY RATE": "Taux d'impayés par les locataires présents débiteurs",
  "TENANT TOTAL": "Total",
  "TENANT TURNOVER": "Suivi du taux de rotation",
  "TENANT_ADDRESS1": "Adresse du locataire",
  "TENANT_ADDRESS2": "Autre adresse du locataire",
  "TENANT_ARRIVAL_DATE": "Date d'entrée du locataire",
  "TENANT_CITY": "Ville du locataire",
  "TENANT_CODE": "Code locataire",
  "TENANT_COUNTRY": "Pays du locataire",
  "TENANT_EXIT_DATE": "Date de sortie du locataire",
  "TENANT_GONE_LIT_OUT_PAY_RATE": "Taux d'impayés par les locataires partis et en contentieux",
  "TENANT_GONE_OUT_PAY_RATE": "Taux d'impayés par les locataires partis",
  "TENANT_HOLDING_COMPANY": "Groupe de rattachement du locataire",
  "TENANT_IMPROVEMENTS": "Montant des agencements",
  "TENANT_IMPROVEMENTS_ACCUMULATED_DEPRECIATION": "Amortissement cumulé des agencements",
  "TENANT_INDUSTRY": "Secteur d'activité",
  "TENANT_INDUSTRY_CODE": "Code secteur d'activité",
  "TENANT_NAME": "Nom du locataire",
  "TENANT_POSTAL_CODE": "Code postal du locataire",
  "TENANT_PRESENCE": "Présence du locataire",
  "TENANT_PRESENT_OUT_PAY_RATE": "Taux d'impayés par les locataire présents débiteurs",
  "TENANTS": "Locataires",
  "TENURE": "Situation juridique",
  "TERM": "Terme quittancé",
  "TERM DATE": "Date de terme",
  "TERM_DATE": "Date de terme",
  "TERMINATION DATE": "Date de dissolution ou de cession",
  "TERMINATION_DATE": "Date de dissolution ou de cession",
  "tfColumnLabel": "Libellé de colonne",
  "tfColumnName": "Nom de colonne",
  "tfExpressionVal": "Expression de base",
  "tfMappingVal": "Mapping",
  "THIRD PARTY FEES AMOUNT": "Montant des honoraires des tiers (€)",
  "THIRD_PARTY_FEES_AMOUNT": "Montant des honoraires des tiers (€)",
  "THIRD_PARTY_FEES_AMOUNT_mismatch": "Montant des honoraires des tiers format incorrect",
  "THIRD_PARTY_FEES_AMOUNT_WOU": "Montant des honoraires des tiers",
  "THOUSANDS": "Tantièmes",
  "titleCreatUpdateReportExcel": "CREER/MODIFIER L’ENVOI D’UN REPORTING EXCEL/PDF",
  "titleInterfaceAccounting": "creer/modifier l’interface – comptable",
  "titleInterfaceLowCode": "Créer/Modifier l'interface",
  "titleInterfaceSpecific": "Modifier l'interface spécifique",
  "titleInterfaceTenancySchedule": "Créer/Modifier l'interface - Etat locatif",
  "TOP ADVANCE PAY": "Classement trop perçu (K€) >",
  "TOP LEVEL BUDGET SECTION": "Catégorie budgétaire",
  "TOP OUTSTAND PAY": "Classement impayés (K€) >",
  "TOP_ADVANCE_PAY": "Classement trop perçu > {0} K€",
  "TOP_LEVEL_BUDGET_SECTION": "Catégorie budgétaire",
  "TOP_OUTSTAND_PAY": "Classement impayés > {0} K€",
  "TOTAL": "Total",
  "TOTAL ACCUMULATED DEPRECIATION": "Montant total des amortissements cumulés (€)",
  "TOTAL AREA PERCENT": "% de la surface totale",
  "TOTAL CASH DISTRIBUTED": "Total du cash distribué (€)",
  "TOTAL EQUITY CONTRIBUTED": "Total fonds propres investis (€)",
  "TOTAL EQUITY FINANCING": "Total des capitaux employés (€)",
  "TOTAL GROSS DEBT": "Total capital restant dû (€)",
  "TOTAL INVESTMENT PRICE": "Fonds propres investis (€)",
  "TOTAL INVESTMENT VALUE": "Fonds propres réévalués (€)",
  "TOTAL INVESTOR DISTRIBUTIONS": "Total des revenus (€)",
  "TOTAL LEASES NUMBER PERCENT": "% du nombre total de baux",
  "TOTAL NET FLOOR AREA": "Surface totale (m²)",
  "TOTAL NET FLOOR AREA WOU": "Surface totale",
  "TOTAL PORTFOLIO": "Total portefeuille",
  "TOTAL RENT": "Loyer total (€/an)",
  "TOTAL RENT M2": "Loyer total par m² (€/m²/an)",
  "TOTAL RENTAL EXPENSES": "Charges d'exploitation totales (€)",
  "TOTAL RENTAL EXPENSES WOU": "Charges d'exploitation totales",
  "TOTAL RETURN": "Rendement global",
  "TOTAL RETURN BREAKDOWN": "Décomposition du rendement global",
  "TOTAL RETURN FUND": "Rendement global",
  "TOTAL UNITS NUMBER PERCENT": "% du nombre total de lots",
  "TOTAL UNREALIZED GAINS AND LOSSES": "Plus-ou-moins-value de la société (€)",
  "TOTAL VACANCY": "Vacance totale",
  "TOTAL_ACCUMULATED_DEPRECIATION": "Montant total des amortissements cumulés (€)",
  "TOTAL_AMOUNT": "Total (€)",
  "TOTAL_AREA_PERCENT": "% de la surface totale",
  "TOTAL_INVESTMENT_VALUE": "Valeur de réalisation des investissements (€)",
  "TOTAL_LEASES_NUMBER_PERCENT": "% du nombre total de baux",
  "TOTAL_RENT": "Loyer total (€/an)",
  "TOTAL_RENT_M2": "Loyer total par m² (€/m²/an)",
  "TOTAL_RENTAL_EXPENSES": "Charges d'exploitation totales (€/an)",
  "TOTAL_RENTAL_EXPENSES_WOU": "Charges d'exploitation totales",
  "TOTAL_RETURN": "Rendement global",
  "TOTAL_SQM": "Total (m²)",
  "TOTAL_UNITS_NUMBER_PERCENT": "% du nombre total de lots",
  "TOTAL_UNREAL_GAINS_LOSSES": "Plus-ou-moins-value de la société (€)",
  "TOTAL_UNREALIZED_GAINS_AND_LOSSES": "Plus-ou-moins-value de la société (€)",
  "TOTAL_USABLE_FLOOR_AREA_SQM": "Surface utile totale (m²)",
  "TRADE NAME": "Enseigne",
  "TRADE REGISTER NUMBER": "N° de RCS",
  "TRADE_NAME": "Enseigne",
  "TRADE_REGISTER_NUMBER": "N° de RCS",
  "TRANSFERED SHARES NUMBER": "Nombre de parts cédées",
  "TRANSFERED SHARES PERCENTAGE": "Pourcentage cédé",
  "TRANSFERED_SHARES_NUMBER": "Nombre de parts cédées",
  "TRANSFERED_SHARES_NUMBER_mismatch": "Nombre de parts cédées format incorrect",
  "TRANSFERED_SHARES_PERCENTAGE": "Pourcentage cédé",
  "TRANSFERED_SHARES_PERCENTAGE_mismatch": "Pourcentage cédé format incorrect",
  "trend": "Tendance",
  "tt_DB_BUILDING_TOTAL_AREA_SQM": "Surface totale des immeubles du portefeuille mesurée à la date de situation",
  "tt_DB_BUILDINGSNUMBER": "Nombre total d'immeubles du portefeuille",
  "tt_DB_DURATION_TO_LEASE_END": "Durée théorique résiduelle moyenne pondérée par le poids du loyer facial de chaque bail par rapport au loyer facial total, exprimée en années-mois-jours",
  "tt_DB_DURATION_TO_NEXT_BREAK": "Durée ferme résiduelle moyenne pondérée par le poids du loyer facial de chaque bail par rapport au loyer facial total, exprimée en années-mois-jours",
  "tt_DB_EFFECTIVE_GROSS_INITIAL_YIELD": "Ratio du loyer effectif brut par la valeur vénale brute (droits inclus) à la date de situation",
  "tt_DB_EFFECTIVE_NET_INITIAL_YIELD": "Ratio du loyer effectif net par la valeur vénale brute (droits inclus) à la date de situation",
  "tt_DB_FAIR_MARKET_RENT": "Loyer théorique du portefeuille si tous les lots étaient loués à leur valeur locative de marché",
  "tt_DB_FINANCIAL_OCCUPANCY_RATE": "Rapport entre le loyer effectif net des charges non récupérables du portefeuille et le loyer potentiel net qui pourrait être obtenu si les lots vacants à la date de situation étaient loués à leur VLM",
  "tt_DB_FINANCIAL_VACANCY": "Valorisation des lots vacants à la date de situation par leur VLM",
  "tt_DB_FINANCIAL_VACANCY_RATE": "Rapport entre la vacance financière du portefeuille et le loyer potentiel net qui pourrait être obtenu si les lots vacants à la date de situation étaient loués à leur VLM",
  "tt_DB_GROSS_EFFECTIVE_RENT": "Somme des loyers effectifs (après déduction des mesures d'accompagnement) des baux actifs applicables à la date de situation",
  "tt_DB_GROSS_HEADLINE_RENT": "Somme des loyers faciaux des baux actifs avant déduction des mesures d'accompagnement applicables à la date de situation",
  "tt_DB_GROSS_MARKET_VALUE": "Somme des valeurs vénales droits inclus des immeubles du portefeuille à la date de situation",
  "tt_DB_GROSS_POTENTIAL_RENT": "Somme du loyer effectif brut et de la vacance financière du portefeuille à la date de situation",
  "tt_DB_GROSS_REVERS_POTENT_OCCUP_AREA": "Différence entre la valeur locative de marché de la surface occupée et le loyer facial brut des immeubles du portefeuille, exprimée en % du loyer facial brut",
  "tt_DB_GROSS_REVERS_POTENT_TOTAL_PROP": "Différence entre la valeur locative de marché de la surface totale et le loyer facial brut des immeubles du portefeuille, exprimée en % du loyer facial brut",
  "tt_DB_GROSS_REVERSIONARY_YIELD": "Ratio du loyer brut de marché par la valeur vénale brute (droits inclus) à la date de situation",
  "tt_DB_HEADLINE_GROSS_INITIAL_YIELD": "Ratio du loyer facial brut par la valeur vénale brute (droits inclus) à la date de situation",
  "tt_DB_HEADLINE_NET_INITIAL_YIELD": "Ratio du loyer facial net par la valeur vénale brute (droits inclus) à la date de situation",
  "tt_DB_MARKET_VALUE_EXCL_DUTIES_VAT": "Somme des valeurs vénales hors droits des immeubles du portefeuille à la date de situation",
  "tt_DB_NET_EFFECTIVE_RENT": "Loyer effectif total du portefeuille après déduction des charges d’exploitation non récupérables",
  "tt_DB_NET_HEADLINE_RENT": "Loyer facial total du portefeuille après déduction des charges d’exploitation non récupérables",
  "tt_DB_NET_MARKET_RENT": "Loyer de marché total du portefeuille après déduction des charges d’exploitation non récupérables",
  "tt_DB_NET_POTENTIAL_RENT": "Loyer potentiel total du portefeuille après déduction des charges d’exploitation non récupérables",
  "tt_DB_NET_REVERSIONARY_YIELD": "Ratio du loyer net de marché par la valeur vénale brute (droits inclus) à la date de situation",
  "tt_DB_NUMBER_OF_VACANT_UNITS": "Nombre total de lots vacants des immeubles du portefeuille à la date de situation",
  "tt_DB_PHYSICAL_OCCUPANCY_RATE": "Rapport entre la surface occupée et la surface totale des immeubles du portefeuille mesuré à la date de situation",
  "tt_DB_POTENTIAL_GROSS_INITIAL_YIELD": "Ratio du loyer potentiel brut par la valeur vénale brute (droits inclus) à la date de situation",
  "tt_DB_POTENTIAL_NET_INITIAL_YIELD": "Ratio du loyer potentiel net par la valeur vénale brute (droits inclus) à la date de situation",
  "tt_DB_RENTED_AREA": "Somme des surfaces occupées des immeubles du portefeuille à la date de situation",
  "tt_DB_VACANCYRATE": "Rapport entre la surface vacante et la surface totale des immeubles du portefeuille mesuré à la date de situation",
  "tt_DB_VACANT_AREA_SQM": "Somme des surfaces vacantes des immeubles du portefeuille à la date de situation",
  "tt_FUND_GROSS_HEADLINE_RENT": "Loyer facial brut total des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient (au prorata)",
  "tt_OUT_INVOICING_INCL_TAX_AMOUNT": "Tous les quittancement dont la date comptable est inférieure ou égale à la date de l’état et dont la date d’exigibilité est inférieure ou égale à la date de l’état",
  "tt_OUT_OUTSTAND_PAY_INC_TAX_AMOUNT": "différence entre le montant quittancé et le montant encaissé",
  "tt_OUT_OVERPAYMENTS_INC_TAX_AMOUNT": "Encaissements pour lesquels la date d'exigibilité est postérieure à la date de fin de période, et pour lesquels la date comptable est inférieure à la date de l’état",
  "tt_OUT_PAYMENT_INCL_TAX_AMOUNT": "Tous les encaissements dont la date comptable est inférieure ou égale à la date de l’état",
  "tt_PVA_GROSS_BOOK_VALUE": "Valeur d'acquisition de l'immeuble augmentée du montant total des dépenses immobilisées cumulées à la date d'arrêté",
  "tt_TEMEND_CURRENT_ANNUAL_RENT_AMOUNT": "Loyer annuel contractuel du dernier bail mesuré à la date de sortie du locataire",
  "tt_TEMEND_RENTED_AREA": "Surface du dernier bail mesurée à la date de sortie du locataire",
  "tt_TEMRELETTING_PREV_LEASE_ANNUAL_RENT_AMOUNT": "Loyer annuel contractuel du bail précédent mesuré à la date de sortie du locataire",
  "tt_TEMRELETTING_PREVIOUS_LEASE_AREA_SQM": "Surface du bail précédent mesurée à la date de sortie du locataire",
  "tt_TEMRENEWAL_PREV_LEASE_ANNUAL_RENT_AMOUNT": "Loyer annuel contractuel du bail mesuré à la date de fin du bail précédent",
  "tt_TEMRENEWAL_PREVIOUS_LEASE_AREA_SQM": "Surface du bail précédent mesurée à la date de fin du bail",
  "tt_YIM_FAIR_MARKET_RENT": "Loyer théorique de l'immeuble si tous les lots étaient loués à leur valeur locative de marché",
  "ttADJUSTED_NET_BOOK_VALUE": "Valeur nette comptable avant provisions diminuée de la provision pour dépréciation durable validée",
  "ttANNUAL_FINANCIAL_VACANCY": "Surface du ou des lots vacants à la date de situation multipliée par la VLM de chacun des lots",
  "ttANNUAL_RENT_AMOUNT": "Loyer annuel contractuel du bail avant déduction des mesures d’accompagnement applicables à la date de situation",
  "ttAVERAGE_VACANCY_RATE": "Taux de vacance physique moyen sur les 12 derniers mois glissants à compter de la date de situation",
  "ttBreakdownByPropertyType": "Répartition par type d'immeuble de la valeur vénale hors droits des immeubles détenus par la société sélectionnée et, par transparisation, par les sociétés qu'elle détient",
  "ttBUILDING_TOTAL_AREA": "Surface totale des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient (au prorata)",
  "ttBUILDING_TOTAL_AREA_SQM": "Surface totale de l'immeuble mesurée à la date de début de la période sélectionnée",
  "ttBUILDINGS_COUNT": "Nombre total d'immeubles détenus par la société sélectionnée et les sociétés qu'elle détient",
  "ttCAPITAL_RETURN": "Rapport entre (1) la croissance de la valeur vénale nette diminuée des dépenses d'investissement et augmentée des recettes en capital et (2) la valeur vénale nette en début de période et les dépenses d'investissement de la période",
  "ttCashOnCashReturn": "Ratio entre le total du cash distribué par la société sélectionnée par ses fonds propres investis",
  "ttCOLLECTION_RATE": "Ratio du montant encaissé sur le montant quittancé",
  "ttCURRENT_ANNUAL_RENT_AMOUNT": "Loyer annuel contractuel du bail avant déduction des mesures d’accompagnement applicables à la date de situation",
  "ttDividendProfitDistributions": "Résultat comptable après IS de la société sélectionnée (si transparente) ou dividende distribué (si non transparente)",
  "ttDURATION": "Différence entre la prochaine date de break du bail et la date de situation, exprimée en années-mois-jours",
  "ttDURATION_TO_BREAK_DATE": "Différence entre la prochaine date de break du bail et la date de situation, exprimée en années-mois-jours",
  "ttDURATION_TO_LEASE_END": "Différence entre la date de fin du bail et la date de situation, exprimée en années-mois-jours",
  "ttECONOMIC_RENT": "Loyer effectif annuel moyen lissé sur la durée ferme du bail en tenant compte de l'ensemble des mesures d'accompagnement applicables durant cette période",
  "ttECONOMIC_RENT_BY_HEADLINE_RENT": "Rapport entre le loyer économique et le loyer facial du bail",
  "ttEFFECTIVE_GROSS_CAPITAL_RATE": "Ratio du loyer effectif brut par la valeur vénale nette (hors droits)",
  "ttEFFECTIVE_GROSS_INITIAL_YIELD": "Ratio du loyer effectif brut par la valeur vénale brute (droits inclus) à la date de situation",
  "ttEFFECTIVE_NET_CAPITAL_RATE": "Ratio du loyer effectif net par la valeur vénale nette (hors droits)",
  "ttEFFECTIVE_NET_INITIAL_YIELD": "Ratio du loyer effectif net par la valeur vénale brute (droits inclus) à la date de situation",
  "ttEFFECTIVE_RENT": "Loyer annuel du bail après déduction des mesures d’accompagnement et ajout du surloyer applicables à la date de situation",
  "ttFAIR_MARKET_RENT": "Loyer théorique du bail si tous les lots étaient loués à leur valeur locative de marché",
  "ttFINANCIAL_OCCUPANCY_RATE": "Rapport entre le loyer effectif net des charges non récupérables de l'immeuble et le loyer potentiel net qui pourrait être obtenu si les lots vacants à la date de situation étaient loués à leur VLM",
  "ttFINANCIAL_VACANCY": "Surface du ou des lots vacants à la date de situation multipliée par la VLM de chacun des lots",
  "ttFINANCIAL_VACANCY_RATE": "Rapport entre la vacance financière de l'immeuble et le loyer potentiel net qui pourrait être obtenu si les lots vacants à la date de situation étaient loués à leur VLM",
  "ttFinancialOccupancySummary": "Etat d'occupation financier des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient (au prorata)",
  "ttFREED_LEASES_RATE": "Rapport entre le nombre de baux résiliés au cours de la période sélectionnée et le nombre total de baux en début de période",
  "ttFREED_UNITS_AREA_RATE": "Rapport entre la surface totale des lots libérés au cours de la période sélectionnée et la surface totale de l'immeuble en début de période",
  "ttFREED_UNITS_RATE": "Rapport entre le nombre de lots libérés au cours de la période sélectionnée et le nombre total de lots en début de période",
  "ttFundPhysicalOccRate": "Rapport entre la surface occupée et la surface totale des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient (au prorata)",
  "ttGearingRatio": "Ratio du capital restant dû par le total des fonds propres investis de la société sélectionnée",
  "ttGeographicalBreakdown": "Répartition par zone géographique de la valeur vénale hors droits des immeubles détenus par la société sélectionnée et, par transparisation, par les sociétés qu'elle détient",
  "ttGR_HEAD_RENT_BY_GR_BOOK_VAL": "Ratio du loyer comptabilisé de la société sélectionnée par l'actif brut immobilisé des immeubles qu'elle détient",
  "ttGROSS_BOOK_VALUE": "Actif brut immobilisé total des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient (au prorata)",
  "ttGROSS_EFFECTIVE_RENT": "Somme des loyers effectifs (après déduction des mesures d'accompagnement) des baux actifs applicables à la date de situation",
  "ttGROSS_HEADLINE_RENT": "Somme des loyers faciaux des baux actifs avant déduction des mesures d'accompagnement applicables à la date de situation",
  "ttGROSS_POTENTIAL_RENT": "Somme du loyer effectif brut et de la vacance financière de l'immeuble à la date de situation",
  "ttGROSS_REVERS_CAPITAL_RATE": "Ratio du loyer brut de marché par la valeur vénale nette (hors droits)",
  "ttGROSS_REVERS_POTENT_OCCUP_AREA": "Différence entre la valeur locative de marché de la surface occupée et le loyer facial brut de l'immeuble, exprimée en % du loyer facial brut",
  "ttGROSS_REVERS_POTENT_TOTAL_PROP": "Différence entre la valeur locative de marché de la surface totale et le loyer facial brut de l'immeuble, exprimée en % du loyer facial brut",
  "ttGROSS_REVERSIONARY_YIELD": "Ratio du loyer brut de marché par la valeur vénale brute (droits inclus) à la date de situation",
  "ttGrossDebt": "Capital restant dû de la société sélectionnée",
  "ttGrossDebtUnderlyingVehicles": "Capital restant dû des sociétés détenues par la société sélectionnée",
  "ttGrossReceiptedRent": "Loyer comptabilisé depuis le début de l'année des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient (au prorata)",
  "ttGrossReceiptedRentByGrossBookValue": "Ratio du loyer comptabilisé de la société sélectionnée par l'actif brut immobilisé des immeubles qu'elle détient",
  "ttGrossReceiptedRentByNetMarketValue": "Ratio du loyer comptabilisé de la société sélectionnée par la valeur vénale hors droits des immeubles qu'elle détient",
  "ttHEADLINE_GROSS_CAPITAL_RATE": "Ratio du loyer facial brut par la valeur vénale nette (hors droits)",
  "ttHEADLINE_GROSS_INITIAL_YIELD": "Ratio du loyer facial brut par la valeur vénale brute (droits inclus) à la date de situation",
  "ttHEADLINE_NET_CAPITAL_RATE": "Ratio du loyer facial net par la valeur vénale nette (hors droits)",
  "ttHEADLINE_NET_INITIAL_YIELD": "Ratio du loyer facial net par la valeur vénale brute (droits inclus) à la date de situation",
  "ttHeadlineGrossInitialYield": "Ratio du loyer facial brut par la valeur vénale nette (hors droits) des immeubles détenus par la société sélectionnée",
  "ttIMPAIRMENT_CHARGE": "Actif brut immobilisé de l'immeuble diminué du montant total des amortissements cumulés et des provisions pour dépréciation comptabilisées à la date d'arrêté",
  "ttIMPAIRMENT_RATIO": "Rapport entre les moins-values latentes avant provision pour dépréciation et la valeur nette comptable",
  "ttINCENTIVE_RATE": "Rapport entre les mesures d'accompagnement applicables sur la durée ferme du bail et le loyer facial projeté sur la durée ferme du bail",
  "ttINCOME_RETURN": "Rapport entre (1) le résultat d'exploitation N et (2) la valeur vénale nette en début de période et les dépenses d'investissement de la période",
  "ttIncomeReturn": "Ratio du total des revenus distribués par la société sélectionnée par ses fonds propres investis",
  "ttINITIAL_ANNUAL_RENT_AMOUNT": "Loyer annuel contractuel du bail avant déduction des mesures d’accompagnement mesuré à la date de début du bail",
  "ttINVESTOR_SHARE_OF_NAV": "Valeur liquidative par part de la société que multiplie le nombre de parts détenues par le détenteur spécifié",
  "ttInvestorShareOfNAV": "Valeur liquidative par part de la société que multiplie le nombre total de parts ou le nombre de parts détenues par le détenteur spécifié",
  "ttINVOICING_INCL_TAX_AMOUNT": "Tous les quittancements dont la date comptable est comprise entre la date de début de période et la date de fin de période",
  "ttLEASE_DURATION": "Différence entre la date de fin et la date de début du bail, exprimée en années-mois-jours",
  "ttLEASE_TURNOVER_RATE": "Somme du taux d'entrée sur les baux et du taux de sortie sur les baux divisée par 2",
  "ttLEASES_NUMBER": "Nombre total de baux de l'immeuble mesuré à la date de début de la période sélectionnée",
  "ttlGenerateInterfaceACS": "Gestion de l'interface Comptable",
  "ttlGenerateInterfaceLRC": "Gestion de l'interface éléments de facturation",
  "ttlGenerateInterfaceTS": "Gestion de l'interface Etat Locatif",
  "ttLoanToValue": "Ratio du capital restant dû par la valeur vénale hors droits des immeubles de la société sélectionnée et, par transparisation, des sociétés qu'elle détient",
  "ttLOSS_BEFORE_RESERVE": "Différence entre la valeur vénale hors droits et la valeur nette comptable avant provisions de l'immeuble",
  "ttNET_ASSET_VALUE": "Somme des capitaux propres et des plus-ou-moins-values latentes sur les immeubles détenus en direct et les titres de participation",
  "ttNET_ASSET_VALUE_ON_SPV": "Valeur de marché des titres de participation de la société dans d'autres sociétés, correspond à la somme des ANR des sociétés détenues proratisés par le pourcentage de détention",
  "ttNET_ASSET_VALUE_PER_SHARE": "Actif net réévalué de la société divisé par le nombre total de parts de la société",
  "ttNET_BOOK_VALUE_AFTER_IMPAIR": "Différence entre la valeur vénale hors droits et la valeur nette comptable après provisions de chaque immeuble",
  "ttNET_BOOK_VALUE_BEFORE_RESERVE": "Actif brut immobilisé de l'immeuble diminué du montant total des amortissements cumulés à la date d'arrêté",
  "ttNET_BOOK_VALUE_ON_SPV": "Valeur comptables des titres de participation de la société dans d'autres sociétés",
  "ttNET_EFFECTIVE_RENT": "Loyer effectif total de l’immeuble après déduction des charges d’exploitation non récupérables",
  "ttNET_HEADLINE_RENT": "Loyer facial total de l’immeuble après déduction des charges d’exploitation non récupérables",
  "ttNET_MARKET_RENT": "Loyer de marché total de l’immeuble après déduction des charges d’exploitation non récupérables",
  "ttNET_POTENTIAL_RENT": "Loyer potentiel total de l’immeuble après déduction des charges d’exploitation non récupérables",
  "ttNET_REVERS_CAPITAL_RATE": "Ratio du loyer net de marché par la valeur vénale nette (hors droits)",
  "ttNET_REVERSIONARY_YIELD": "Ratio du loyer net de marché par la valeur vénale brute (droits inclus) à la date de situation",
  "ttNetAssetValuePerShare": "Actif net réévalué de la société divisé par le nombre total de parts de la société",
  "ttNetMarketValue": "Valeur vénale hors droits totale des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient (au prorata)",
  "ttNetResultReturnOnGrossBookValue": "Ratio du résultat comptable après IS de la société sélectionnée par l'actif brut immobilisé des immeubles qu'elle détient",
  "ttNEW_LEASES_RATE": "Rapport entre le nombre de baux de relocation ayant pris effet au cours de la période sélectionnée et le nombre total de baux en début de période",
  "ttNEW_UNITS_AREA_RATE": "Rapport entre la surface totale des lots reloués au cours de la période sélectionnée et la surface totale de l'immeuble en début de période",
  "ttNEW_UNITS_RATE": "Rapport entre le nombre de lots reloués au cours de la période sélectionnée et le nombre total de lots en début de période",
  "ttNUMBER_OF_LEASES": "Nombre total de baux des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient",
  "ttNUMBER_OF_TENANTS": "Nombre total de locataires des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient",
  "ttNUMBER_OF_UNITS": "Nombre total de lots des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient",
  "ttNUMBER_PARKING_SPACES": "Nombre total de parkings des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient",
  "ttOperatingReturnOnGrossBookValue": "Ratio du résultat d'exploitation de la société sélectionnée par l'actif brut immobilisé des immeubles qu'elle détient",
  "ttOUTSTAND_PAY_TERM_EQUIV": "Exprime le montant des impayés en nombre de mois ou de trimestres impayés.",
  "ttOUTSTAND_RATE": "Ratio du montant impayé sur le montant quittancé au cours de la période définie par l’utilisateu",
  "ttOVERPAYMENTS_INC_TAX_AMOUNT": "Encaissements pour lesquels la date d'exigibilité n’est pas comprise dans la période, et pour lesquels la date comptable est comprise entre le début et la fin de période",
  "ttOwnedBuildings": "Les données et indicateurs ci-dessous portent sur l'ensemble des immeubles détenus par la société sélectionnée et, par transparisation, par les sociétés qu'elle détient, non proratisés",
  "ttOWNER_EQUITY": "Somme du capital, de la prime d'émission, des réserves (réserve légale, réserves indisponibles, autres réserves), du report à nouveau, du capital souscrit non appelé, du compte d'ordre, des plus-values réinvesties, de l'amortissement dérogatoire et (si la société est non transparente) du résultat comptable",
  "ttPAYMENT_INCL_TAX_AMOUNT": "Tous les encaissements dont la date comptable est comprise entre la date de début de période et la date de fin de période",
  "ttPC_PORTFOLIO_NET_MARKET_VALUE": "Valeur vénale hors droits de l'immeuble exprimée en % de la valeur vénale hors droits totale des immeubles sélectionnés",
  "ttPC_PORTFOLIO_TOTAL_RETURN": "Contribution de l'immeuble au rendement global des immeubles sélectionnés exprimée en % du rendement global des immeubles sélectionnés",
  "ttPER_SHARE_UNREAL_GAINS_LOSSES": "Différence entre la valeur liquidative par part de la société et le prix de revient unitaire du détenteur spécifié",
  "ttPHYSICAL_OCCUPANCY_RATE": "Rapport entre la surface occupée et la surface totale de l'immeuble mesuré à la date de situation",
  "ttPHYSICAL_OCCUPATION_RATE": "Rapport entre la surface occupée et la surface totale de l'immeuble mesuré à la date de situation",
  "ttPHYSICAL_VACANCY_RATE": "Rapport entre la surface vacante et la surface totale de l'immeuble mesuré à la date de situation",
  "ttPhysicalOccRate": "Rapport entre la surface occupée et la surface totale de l'immeuble mesuré à la date de situation",
  "ttPhysicalOccupancySummary": "Etat d'occupation physique des immeubles détenus par la société sélectionnée et les sociétés qu'elle détient (au prorata)",
  "ttPortfolioSize": "Les données et indicateurs ci-dessous portent sur l'ensemble des immeubles détenus par la société sélectionnée et, par transparisation, par les sociétés qu'elle détient et sont proratisés si un détenteur spécifique est sélectionné",
  "ttPortfolioYieldsAndReturns": "Les indicateurs ci-dessous portent uniquement sur la société sélectionnée uniquement",
  "ttPOTENTIAL_GROSS_CAPITAL_RATE": "Ratio du loyer potentiel brut par la valeur vénale nette (hors droits)",
  "ttPOTENTIAL_GROSS_INITIAL_YIELD": "Ratio du loyer potentiel brut par la valeur vénale brute (droits inclus) à la date de situation",
  "ttPOTENTIAL_NET_CAPITAL_RATE": "Ratio du loyer potentiel net par la valeur vénale nette (hors droits)",
  "ttPOTENTIAL_NET_INITIAL_YIELD": "Ratio du loyer potentiel net par la valeur vénale brute (droits inclus) à la date de situation",
  "ttPRO_RATED_FINANCIAL_VACANCY": "Surface du ou des lots vacants à la date de situation multipliée par la VLM de chacun des lots, proratisée par la durée de la vacance à la date de situation",
  "ttRE_LETTING_PERIOD": "Différence entre la date de début du nouveau bail et la date de début de vacance du lot reloué, exprimée en nombre de jours",
  "ttRealEstatePortfolio": "Les données et indicateurs ci-dessous portent sur l'ensemble des immeubles détenus par la société sélectionnée et, par transparisation, par les sociétés qu'elle détient, proratisés si un détenteur spécifique est sélectionné",
  "ttRECOVERABLE_AMOUNT": "Valeur vénale hors droits ou plus grande des deux valeurs entre la valeur vénale hors droits et la valeur de reconstitution si le taux de dépréciation excède 20%",
  "ttRENT_VARIATION": "Différence entre le loyer facial par m² du bail et le loyer facial par m² du bail précédent, exprimée en % du loyer facial par m² du bail précédent",
  "ttRENTED_AREA": "Somme des surfaces des lots occupés à la date de situation",
  "ttReturnOnEquityLevered": "Ratio du résultat comptable après IS de la société sélectionnée par le total de son financement (fonds propres et dette)",
  "ttReturnOnEquityUnlevered": "Ratio du résultat comptable après IS de la société sélectionnée par le total de ses fonds propres investis",
  "ttREVERSIONARY_POTENTIAL": "Différence entre la valeur locative de marché et le loyer facial du bail, exprimée en % du loyer facial",
  "ttShareAcquisitionPrice": "Prix de revient des titres de l'ensemble des détenteurs ou du détenteur spécifié",
  "ttSimplifiedPAndL": "Compte de résultat simplifié de la société sélectionnée et des sociétés qu'elle détient (au prorata), proratisés si un détenteur spécifique est sélectionné",
  "ttTOTAL_INVESTMENT_VALUE": "Somme de la valeur liquidative des parts détenues, des avances en compte courant et des prêts d'associés",
  "ttTOTAL_RETURN": "Somme du rendement locative et du rendement en capital",
  "ttTOTAL_UNREAL_GAINS_LOSSES": "Somme des plus-ou-moins-values latentes sur les immeubles et sur les titres de participation détenus par la société",
  "ttTotalInvestmentValue": "Somme de la valeur liquidative des titres, des avances en compte courant et des prêts d'associés des détenteurs ou du détenteur spécifié",
  "ttUNIT_TURNOVER_RATE": "Somme du taux d'entrée sur les lots et du taux de sortie sur les lots divisée par 2",
  "ttUNITS_AREA_TURNOVER_RATE": "Somme du taux d'entrée sur les surfaces et du taux de sortie sur les surfaces divisée par 2",
  "ttUNITS_NUMBER": "Nombre total de lots de l'immeuble mesuré à la date de début de la période sélectionnée",
  "ttUNREAL_GAINS_LOSSES_ON_PROP": "Différence entre la valeur de référence et la valeur nette comptable après PDD des immeubles détenus en direct par la société calculée dans Valorisation des immeubles",
  "ttUNREAL_GAINS_LOSSES_ON_SHARES": "Plus-ou-moins-value latente unitaire sur les titres que multiplie le nombre total de parts",
  "ttUNREAL_GAINS_LOSSES_ON_SPV": "Différence entre l'actif net réévalué et la valeur comptable des titres de participation de la société",
  "ttUNREALIZED_GAINS_AND_LOSSES": "Différence entre la valeur de référence et la valeur nette comptable après PDD",
  "ttUnrealizedGainsLossesOnShares": "Différence entre l'actif net réévalué et la valeur comptable des titres de participation de la société",
  "ttUnrealizedInvestmentPropertyGainLoss": "Différence entre la valeur vénale hors droits et la valeur nette comptable des immeubles détenus en direct par la société",
  "ttVACANCY_DURATION": "Différence entre la date de situation et la date de début de vacance du lot vacant, exprimée en années-mois-jours",
  "ttVACANT_AREA": "Somme des surfaces des lots vacants à la date de situation",
  "ttVALUE_IN_USE": "Valeur hors droits obtenue par capitalisation du loyer de marché issue de l'expertise par le taux de rendement attendu pour ce type d'immeuble et ce segment de marché",
  "ttWEIGHTED_CONTRIB_TOTAL_RETURN": "Rendement global de l'immeuble multiplié par son poids dans le portefeuille",
  "txbAnalysisDate_mismatch": "Date de situation incorrecte",
  "txbAnalysisDate_required": "Date de situation obligatoire",
  "txbDateEnd_mismatch": "Date de fin incorrecte",
  "txbDateEnd_required": "Date de fin obligatoire",
  "txbDateErv": "Saisir la date de valeur",
  "txbDateErv_mismatch": "Date de valeur incorrecte",
  "txbDateErv_required": "Date de valeur obligatoire",
  "txbDateFundOwnership": "Saisir la date de mise à jour",
  "txbDateFundOwnership_mismatch": "Date de mise à jour incorrecte",
  "txbDateFundOwnership_required": "Date de mise à jour obligatoire",
  "txbDateNetBookValue": "Saisir la date d'arrêté comptable",
  "txbDateNetBookValue_mismatch": "Date d'arrêté incorrecte",
  "txbDateNetBookValue_required": "Date d'arrêté obligatoire",
  "txbDateStart_mismatch": "Date de début incorrecte",
  "txbDateStart_required": "Date de début obligatoire",
  "txbDateStartEnd_mismatch": "La date de fin doit être supérieure à la date de début",
  "txbDateValuation": "Saisir la date de valeur",
  "txbDateValuation_mismatch": "Date de valeur incorrecte",
  "txbDateValuation_required": "Date de valeur obligatoire",
  "txbDateYield": "Saisir la date de valeur",
  "txbDateYield_mismatch": "Date de valeur incorrecte",
  "txbDateYield_required": "Date de valeur obligatoire",
  "txtCacheCleared": "Le cache du navigateur a été vidé",
  "txtCacheNotCleared": "Le cache du navigateur n'est pas vide",
  "txtChooseReportModel": "Choisir un rapport personnalisé",
  "txtMenuAdditionalField": "Ce menu permet de charger et de mettre à jour dans Alhambra des données, de niveau immeuble, bâtiment, lot ou bail. Les thèmes de données peuvent être définis librement dans le menu \"gestion des thèmes\".",
  "txtMenuErv": "Alhambra permet d'importer les VLM à différents niveaux en fonction de la granularité des données disponibles. Les VLM importées sont ensuite automatiquement répercutées sur le niveau le plus fin (le lot unitaire).<BR/>  <BR/>  Les VLM peuvent être importées :<BR/>  - Par immeuble et par lot unitaire<BR/>  - Par immeuble, par type de lot et par nombre de pièces (pour les lots résidentiels)<BR/>  - Par immeuble et par type de lot<BR/>  - Par segment de marché immobilier et par type de lot<BR/>",
  "txtMenuFundOwnership": "Ce menu permet de charger et de mettre à jour dans Alhambra les données relatives aux détenteurs. Un détenteur correspond dans Alhambra à n’importe quelle société détenant des titres de participation dans au moins une autre société.",
  "txtMenuLeaseRepository": "Ce menu permet de charger et de mettre à jour dans Alhambra les contentieux des baux.<BR/>  ATTENTION : Les informations des contentieux sont limitées à 2000 caractères.",
  "txtMenuNetBookValue": "Ce menu permet de charger et de mettre à jour dans Alhambra les éléments nécessaires au calcul de la valeur nette comptable (VNC) de chaque immeuble à une date d'arrêté.",
  "txtMenuValuation": "Ce menu permet de charger et de mettre à jour dans Alhambra les valeurs vénales (HD HFA et DI FAI) et les loyers bruts de marché issus de l'expertise immobilière des immeubles.",
  "txtMenuYield": "Ce menu permet de charger et de mettre à jour dans Alhambra les taux de rendement attendus par les investisseurs. Les taux de rendement attendus sont utilisés dans le calcul de la valeur de reconstitution dans le rapport Valorisation des immeubles. Il est recommandé de les importer et de les mettre à jour une fois par an dans la base de données.",
  "txtModelDateImportFile": "À partir de laquelle les données chargées sont valides.",
  "txtModelFileAdditionalField": "Saisir les valeurs et leurs dates de début ; en l'absence de valeurs, laisser vide ou supprimer la ligne.",
  "txtModelFileErvBuildingUnit": "Saisir les valeurs ; en l'absence de valeurs, saisir \"0\" ou supprimer la ligne.",
  "txtModelFileErvBuildingUnitType": "Saisir les valeurs ; en l'absence de valeurs, saisir \"0\" ou supprimer la ligne.",
  "txtModelFileErvBuildingUnitTypeRoom": "Saisir les valeurs ; en l'absence de valeurs, saisir \"0\" ou supprimer la ligne.",
  "txtModelFileErvSectorUnitType": "Saisir les valeurs ; en l'absence de valeurs, saisir \"0\" ou supprimer la ligne.",
  "txtModelFileFundOwnership": "Le fichier modèle ci-dessous permet de saisir, mettre à jour ou corriger les données suivantes relatives aux détenteurs :<BR/>  - Prix de revient des parts détenues<BR/>  - Engagement<BR/>  - Capitaux appelés<BR/>  <BR/>  Il comprend également à titre d'information et de vérification les données suivantes (non modifiables par import) :<BR/>  - Code ISIN de la société détenue<BR/>  - Nombre de parts détenues par le détenteur<BR/>  - Date d'entrée du détenteur<BR/>  - Date de sortie du détenteur<BR/>  <BR/>  Attention : seules les données relatives aux sociétés actives et aux détenteurs non sortis à la date d'arrêté sélectionnée seront mises à jour. Les lignes correspondants à des sociétés inactives et/ou à des détenteurs sortis seront ignorées.",
  "txtModelFileLeaseRepository": "Saisir les valeurs ; en l'absence de valeurs, saisir \"0\" ou supprimer la ligne.",
  "txtModelFileNetBookValue": "Saisir les valeurs ; en l'absence de valeurs, saisir \"0\" ou supprimer la ligne.",
  "txtModelFileValuation": "Saisir les valeurs ; en l'absence de valeurs, saisir \"0\" ou supprimer la ligne.",
  "txtModelFileYield": "Saisir les valeurs ; en l'absence de valeurs, saisir \"0\" ou supprimer la ligne.",
  "txtModelImportFile": "Pour que ces données soient prises en compte : </br> • Aller dans Paramètres / Rechargement des fichiers </br>  • Cliquer sur <i>Rechargement des VNC, données d’expertise, VLM et taux de rendement </i>.",
  "txtNextInterface": "Aucune modification ne sera sauvegardée, êtes-vous sûr ?",
  "txtNotWithCents": "Sans centimes",
  "txtSearch": "Rechercher un immeuble",
  "txtSearchBuildingOrFund": "Immeuble ou société ?",
  "txtSearchCodeOrLabel": "Rechercher un code ou un libellé",
  "txtSearchFunds": "Rechercher une société",
  "txtSearchProvider": "Rechercher un fournisseur",
  "txtSearchReportTemplate": "Rechercher un modèle ou un rapport",
  "txtServerCacheCleared": "Le cache serveur a été vidé",
  "txtServerCacheNotCleared": "Le cache serveur n'a pas été vidé",
  "txtUpdateAdditionalFieldOk": "Données des champs additionnels mises à jour avec succès.",
  "txtUpdateErvOk": "VLM mises à jour avec succès.",
  "txtUpdateFundOwnershipOk": "Données relatives aux détenteurs mises à jour avec succès.",
  "txtUpdateLeaseRepositoryOk": "Données relatives aux baux mises à jour avec succès.",
  "txtUpdateNetBookValueOk": "Données relatives aux VNC mises à jour avec succès.",
  "txtUpdateValuationOk": "Données d'expertise mises à jour avec succès.",
  "txtUpdateYieldOk": "Taux de rendement attendus mis à jour avec succès.",
  "txtVacancyUnit": "Suivi mensuel de la vacance en :",
  "txtWithCents": "Avec centimes",
  "TYPOLOGIE_VENTILATION_COMPTABLE": "Typologie ventilation comptable",
  "UNALLOCATED SHARES": "Nombre de parts non attribuées (calculé)",
  "UNAVAILABLE_FOR_LETTING_AREA": "Surface non louable",
  "UNCALLED CAPITAL": "Capital souscrit non appelé (€)",
  "UNCALLED_CAPITAL": "Capital souscrit non appelé (€)",
  "UNDEFINED": "Indéfini",
  "UNDER_LITIGATION": "En contentieux",
  "UNDERLYING IMMOBILIER": "Sous-jacent immobilier",
  "UNDERLYING VEHICLES": "Société(s)-fille(s)",
  "unhandledErrorMsg": "L'erreur n'a pas été gérée par le code de l application.",
  "UNIT": "Lot",
  "UNIT AREA SQM": "Surface du lot (m²)",
  "UNIT DETAILED TENANCY SCHEDULE": "Etat locatif ventilé par lot au",
  "UNIT DETAILED TENANCY SCHEDULE SHEET": "Etat locatif ventilé par lot",
  "UNIT NATURE LABEL": "Nature du lot",
  "UNIT NUMBER": "N° de lot",
  "UNIT TENANCY SCHEDULE": "Etat locatif par lot au",
  "UNIT TENANCY SCHEDULE SHEET": "Etat locatif par lot",
  "UNIT TURNOVER RATE": "Taux de rotation (sur les lots)",
  "UNIT TYPE CODE": "Code type lot",
  "UNIT TYPE LABEL": "Type de lot",
  "UNIT TYPE TENANCY SCHEDULE": "Etat locatif par type de lot au",
  "UNIT TYPE TENANCY SCHEDULE SHEET": "Etat locatif par type de lot",
  "UNIT_AREA_SQM": "Surface du lot (m²)",
  "UNIT_CODE": "Lot",
  "UNIT_END_MANAGEMENT_DATE": "Date de fin de gestion du lot",
  "UNIT_LABEL": "Lot",
  "UNIT_LEASE": "Lien lot bail",
  "UNIT_NATURE_LABEL": "Nature du lot",
  "UNIT_NUMBER": "N° de lot",
  "UNIT_START_MANAGEMENT_DATE": "Date de début de gestion du lot",
  "UNIT_TURNOVER_RATE": "Taux de rotation (sur les lots)",
  "UNIT_TYPE_CODE": "Code type de lot",
  "UNIT_TYPE_LABEL": "Type de lot",
  "UNIT_UTILISATION_END_DATE": "Date de fin d'utilisation du lot",
  "UNIT_UTILISATION_START_DATE": "Date de début d'utilisation du lot",
  "Unit1": "€/an",
  "Unit2": "m²/an",
  "Unit3": "€/m²/an",
  "Unit4": "€",
  "Unit5": "m²",
  "UNITS AREA TURNOVER RATE": "Taux de rotation (sur les surfaces)",
  "UNITS COUNT": "Nombre de lots",
  "UNITS NUMBER AT DATE": "Nombre de lots au",
  "UNITS PERCENT": "% du nombre total de lots",
  "UNITS TOTAL AREA SQM": "Surface totale des lots (m²)",
  "UNITS_AREA_TURNOVER_RATE": "Taux de rotation (sur les surfaces)",
  "UNITS_COUNT": "Nombre de lots",
  "UNITS_NUMBER": "Nombre de lots",
  "UNITS_NUMBER_AT_DATE": "Nombre de lots au DD/MM/YYYY",
  "UNITS_PERCENT": "% du nombre total de lots",
  "UNITS_TOTAL_AREA_SQM": "Surface totale des lots (m²)",
  "UNPAID INVOICED INCL TAX AMOUNT": "Montant facturé en impayé (€)",
  "UNPAID_INVOICED_INCL_TAX_AMOUNT": "Montant facturé en impayé (€)",
  "UNREAL_GAINS_LOSSES_ON_PROP": "Plus-ou-moins-value sur les immeubles (€)",
  "UNREAL_GAINS_LOSSES_ON_SHARES": "Plus-ou-moins-value latente sur les titres (€)",
  "UNREAL_GAINS_LOSSES_ON_SPV": "Plus-ou-moins-value sur les titres (€)",
  "UNREALIZED GAINS AND LOSSES": "PMV après PDD (€)",
  "UNREALIZED GAINS AND LOSSES ON PROPERTIES": "Plus-ou-moins-value sur les immeubles (€)",
  "UNREALIZED GAINS AND LOSSES ON SHARES": "Plus-ou-moins-value latente sur les titres (€)",
  "UNREALIZED GAINS AND LOSSES ON SPV AND HOLDCO": "Plus-ou-moins-value sur les titres (€)",
  "UNREALIZED GAINS LOSSES ON SHARES": "PMV latentes sur les titres (€)",
  "UNREALIZED INVESTMENT PROPERTY GAIN LOSS": "PMV latentes sur les immeubles (€)",
  "UNREALIZED NON PROPERTY GAIN LOSS": "PMV latentes sur les titres (€)",
  "UNREALIZED_GAINS_AND_LOSSES": "PMV après PDD (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_PROPERTIES": "Plus-ou-moins-value sur les immeubles (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_SHARES": "Plus-ou-moins-value latente sur les titres (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_SPV_AND_HOLDCO": "Plus-ou-moins-value sur les titres (€)",
  "UNRECOVERABLE": "Non Récupérable",
  "UNRECOVERED EXPENSES": "Charges non récupérées (€)",
  "UNRECOVERED EXPENSES WOU": "Charges non récupérées",
  "UPCOMING LEASE EVENTS": "Baux à échéance",
  "UploadStatusError": "Statut du téléchargement : Le fichier n'a pas pu être téléchargé. L'erreur suivante s'est produite :",
  "UploadStatusFileTooBig": "Statut du téléchargement : La taille du fichier doit être < 1 Mo !",
  "UploadStatusOnlyJPEG": "Statut du téléchargement : Seuls les fichiers JPEG sont acceptés !",
  "UploadStatusPropertyNotFound": "Statut du téléchargement : Immeuble introuvable !",
  "USABLE FLOOR AREA": "Surface utile (m²)",
  "USABLE FLOOR AREA WOU": "Surface utile",
  "VACANCY": "Vacance",
  "VACANCY AREA": "Surface vacante (m²)",
  "VACANCY AREA SHEET": "Surface vacante",
  "VACANCY BREAKDOWN": "Répartition de la vacance par période",
  "VACANCY BREAKDOWN BY NATURE": "Répartition de la vacance par type au",
  "VACANCY BREAKDOWN BY NATURE SHEET": "Répart. de la vacance par type",
  "VACANCY DURATION": "Durée de la vacance (a-m-j)",
  "VACANCY FOR SALE": "Vacance pour vente",
  "VACANCY FOR WORKS": "Vacance pour travaux",
  "VACANCY START DATE": "Date de début vacance",
  "VACANCY SUMMARY": "Etat d'occupation au",
  "VACANCY TYPE": "Type de vacance",
  "VACANCY_DURATION": "Durée de la vacance (a-m-j)",
  "VACANCY_RATE_CURRENT_MONTH": "Taux de vacance physique mois courant",
  "VACANCY_RATE_MINUS_01_MONTHS": "Taux de vacance physique 1 mois plus tôt",
  "VACANCY_RATE_MINUS_02_MONTHS": "Taux de vacance physique 2 mois plus tôt",
  "VACANCY_RATE_MINUS_03_MONTHS": "Taux de vacance physique 3 mois plus tôt",
  "VACANCY_RATE_MINUS_04_MONTHS": "Taux de vacance physique 4 mois plus tôt",
  "VACANCY_RATE_MINUS_05_MONTHS": "Taux de vacance physique 5 mois plus tôt",
  "VACANCY_RATE_MINUS_06_MONTHS": "Taux de vacance physique 6 mois plus tôt",
  "VACANCY_RATE_MINUS_07_MONTHS": "Taux de vacance physique 7 mois plus tôt",
  "VACANCY_RATE_MINUS_08_MONTHS": "Taux de vacance physique 8 mois plus tôt",
  "VACANCY_RATE_MINUS_09_MONTHS": "Taux de vacance physique 9 mois plus tôt",
  "VACANCY_RATE_MINUS_10_MONTHS": "Taux de vacance physique 10 mois plus tôt",
  "VACANCY_RATE_MINUS_11_MONTHS": "Taux de vacance physique 11 mois plus tôt",
  "VACANCY_START_DATE": "Date de début de vacance",
  "VACANCY_TYPE": "Type de vacance",
  "VACANT": "--VACANT--",
  "VACANT AREA": "Surface vacante (m²)",
  "VACANT AREA 2 4 MONTHS": "Surface vacante 2 à 4 mois (m²)",
  "VACANT AREA INF 2MONTHS": "Surface vacante inf 2 mois (m²)",
  "VACANT AREA SUP 4MONTHS": "Surface vacante sup à 4 mois (m²)",
  "VACANT UNITS": "Etat des lots vacants au",
  "VACANT UNITS BY UNIT TYPE": "Répartition des lots vacants par type de lot",
  "VACANT UNITS BY VACANCY TYPE": "Répartition des lots vacants par type de vacance",
  "VACANT UNITS SHEET": "Etat des lots vacants",
  "VACANT_AREA": "Surface vacante (m²)",
  "VACANT_AREA_1_3_MONTHS": "1-3 mois",
  "VACANT_AREA_2_4_MONTHS": "Surface vacante 2-4 mois (m²)",
  "VACANT_AREA_3_6_MONTHS": "3-6 mois",
  "VACANT_AREA_CURRENT_MONTH": "Surface vacante mois courant",
  "VACANT_AREA_INF_1_MONTH": "< 1 mois",
  "VACANT_AREA_INF_2MONTHS": "Surface vacante < 2 mois (m²)",
  "VACANT_AREA_MINUS_01_MONTHS": "Surface vacante 1 mois plus tôt",
  "VACANT_AREA_MINUS_02_MONTHS": "Surface vacante 2 mois plus tôt",
  "VACANT_AREA_MINUS_03_MONTHS": "Surface vacante 3 mois plus tôt",
  "VACANT_AREA_MINUS_04_MONTHS": "Surface vacante 4 mois plus tôt",
  "VACANT_AREA_MINUS_05_MONTHS": "Surface vacante 5 mois plus tôt",
  "VACANT_AREA_MINUS_06_MONTHS": "Surface vacante 6 mois plus tôt",
  "VACANT_AREA_MINUS_07_MONTHS": "Surface vacante 7 mois plus tôt",
  "VACANT_AREA_MINUS_08_MONTHS": "Surface vacante 8 mois plus tôt",
  "VACANT_AREA_MINUS_09_MONTHS": "Surface vacante 9 mois plus tôt",
  "VACANT_AREA_MINUS_10_MONTHS": "Surface vacante 10 mois plus tôt",
  "VACANT_AREA_MINUS_11_MONTHS": "Surface vacante 11 mois plus tôt",
  "VACANT_AREA_SQM": "Surface vacante (m²)",
  "VACANT_AREA_SUP_4MONTHS": "Surface vacante > 4 mois (m²)",
  "VACANT_AREA_SUP_6_MONTHS": "> 6 mois",
  "VACANT_DISPOSITION_AREA": "Vacant pour vente",
  "VACANT_INACTIVE_AREA": "Infactif",
  "VACANT_LETTING_AREA": "Vacant (vacance conjoncturelle)",
  "VACANT_UNAVAILABLE_AREA": "Non louable (parties communes)",
  "VACANT_UNDEFINED_AREA": "Vacant",
  "VACANT_WORKS_AREA": "Vacant pour travaux",
  "vacatedTenants": "Locataires sortis",
  "VALIDATION_DU_CHANTIER": "Validation du chantier",
  "VALUATION": "Expertise",
  "VALUATION MARKET RENT": "Loyer de marché expertise (€)",
  "VALUATION_MARKET_RENT": "Loyer de marché expertise (€)",
  "VALUE IN USE": "Valeur de reconstitution estimée (€)",
  "VALUE_IN_USE": "Valeur de reconstitution estimée (€)",
  "VALUES PERCENT": "% de la valeur vénale nette",
  "VALUES_PERCENT": "% de la valeur vénale nette totale",
  "VARIATION 01 N": "Variation € budget au réalisé en Janvier",
  "VARIATION 02 N": "Variation € budget au réalisé en Février",
  "VARIATION 03 N": "Variation € budget au réalisé en Mars",
  "VARIATION 04 N": "Variation € budget au réalisé en Avril",
  "VARIATION 05 N": "Variation € budget au réalisé en Mai",
  "VARIATION 06 N": "Variation € budget au réalisé en Juin",
  "VARIATION 07 N": "Variation € budget au réalisé en Juillet",
  "VARIATION 08 N": "Variation € budget au réalisé en Aout",
  "VARIATION 09 N": "Variation € budget au réalisé en Septembre",
  "VARIATION 10 N": "Variation € budget au réalisé en Octobre",
  "VARIATION 11 N": "Variation € budget au réalisé en Novembre",
  "VARIATION 12 N": "Variation € budget au réalisé en Décembre",
  "VARIATION BUDGET": "Variation € budget exercice N/Budget exercice N-1",
  "VARIATION BUDGET RATE": "Taux de Variation budget exercice N/Budget exercice N-1",
  "VARIATION N": "Variation € budget à date au réalisé à date",
  "VARIATION RATE 01 N": "Rapport réalisé / budget en Janvier",
  "VARIATION RATE 02 N": "Rapport réalisé / budget en Février",
  "VARIATION RATE 03 N": "Rapport réalisé / budget en Mars",
  "VARIATION RATE 04 N": "Rapport réalisé / budget en Avril",
  "VARIATION RATE 05 N": "Rapport réalisé / budget en Mai",
  "VARIATION RATE 06 N": "Rapport réalisé / budget en Juin",
  "VARIATION RATE 07 N": "Rapport réalisé / budget en Juillet",
  "VARIATION RATE 08 N": "Rapport réalisé / budget en Aout",
  "VARIATION RATE 09 N": "Rapport réalisé / budget en Septembre",
  "VARIATION RATE 10 N": "Rapport réalisé / budget en Octobre",
  "VARIATION RATE 11 N": "Rapport réalisé / budget en Novembre",
  "VARIATION RATE 12 N": "Rapport réalisé / budget en Décembre",
  "VARIATION RATE N": "Rapport réalisé / budget",
  "VARIATION RATE T1 N": "Rapport réalisé / budget au 1T",
  "VARIATION RATE T2 N": "Rapport réalisé / budget au 2T",
  "VARIATION RATE T3 N": "Rapport réalisé / budget au 3T",
  "VARIATION RATE T4 N": "Rapport réalisé / budget au 4T",
  "VARIATION RATE TODATE N": "Taux de Variation Réalisé exerciceN/Réalisé exercice N-1 au",
  "VARIATION T1 N": "Variation € budget au réalisé au 1T",
  "VARIATION T2 N": "Variation € budget au réalisé au 2T",
  "VARIATION T3 N": "Variation € budget au réalisé au 3T",
  "VARIATION T4 N": "Variation € budget au réalisé au 4T",
  "VARIATION TODATE N": "Variation Réalisé exerciceN/Réalisé exercice N-1 au",
  "VARIATION_01_N": "Variation € budget au réalisé en Janvier YYYY",
  "VARIATION_02_N": "Variation € budget au réalisé en Février YYYY",
  "VARIATION_03_N": "Variation € budget au réalisé en Mars YYYY",
  "VARIATION_04_N": "Variation € budget au réalisé en Avril YYYY",
  "VARIATION_05_N": "Variation € budget au réalisé en Mai YYYY",
  "VARIATION_06_N": "Variation € budget au réalisé en Juin YYYY",
  "VARIATION_07_N": "Variation € budget au réalisé en Juillet YYYY",
  "VARIATION_08_N": "Variation € budget au réalisé en Aout YYYY",
  "VARIATION_09_N": "Variation € budget au réalisé en Septembre YYYY",
  "VARIATION_10_N": "Variation € budget au réalisé en Octobre YYYY",
  "VARIATION_11_N": "Variation € budget au réalisé en Novembre YYYY",
  "VARIATION_12_N": "Variation € budget au réalisé en Décembre YYYY",
  "VARIATION_BUDGET": "Variation € budget exercice N/Budget exercice N-1",
  "VARIATION_BUDGET_RATE": "Taux de Variation budget exercice N/Budget exercice N-1",
  "VARIATION_N": "Variation € budget à date au réalisé à date",
  "VARIATION_RATE_01_N": "Rapport réalisé / budget en Janvier YYYY",
  "VARIATION_RATE_02_N": "Rapport réalisé / budget en Février YYYY",
  "VARIATION_RATE_03_N": "Rapport réalisé / budget en Mars YYYY",
  "VARIATION_RATE_04_N": "Rapport réalisé / budget en Avril YYYY",
  "VARIATION_RATE_05_N": "Rapport réalisé / budget en Mai YYYY",
  "VARIATION_RATE_06_N": "Rapport réalisé / budget en Juin YYYY",
  "VARIATION_RATE_07_N": "Rapport réalisé / budget en Juillet YYYY",
  "VARIATION_RATE_08_N": "Rapport réalisé / budget en Aout YYYY",
  "VARIATION_RATE_09_N": "Rapport réalisé / budget en Septembre YYYY",
  "VARIATION_RATE_10_N": "Rapport réalisé / budget en Octobre YYYY",
  "VARIATION_RATE_11_N": "Rapport réalisé / budget en Novembre YYYY",
  "VARIATION_RATE_12_N": "Rapport réalisé / budget en Décembre YYYY",
  "VARIATION_RATE_N": "Rapport réalisé / budget",
  "VARIATION_RATE_T1_N": "Rapport réalisé / budget au 1T YYYY",
  "VARIATION_RATE_T2_N": "Rapport réalisé / budget au 2T YYYY",
  "VARIATION_RATE_T3_N": "Rapport réalisé / budget au 3T YYYY",
  "VARIATION_RATE_T4_N": "Rapport réalisé / budget au 4T YYYY",
  "VARIATION_RATE_TODATE_N": "Taux de Variation Réalisé exerciceN/Réalisé exercice N-1 Au DDMMYYYY",
  "VARIATION_T1_N": "Variation € budget au réalisé au 1T YYYY",
  "VARIATION_T2_N": "Variation € budget au réalisé au 2T YYYY",
  "VARIATION_T3_N": "Variation € budget au réalisé au 3T YYYY",
  "VARIATION_T4_N": "Variation € budget au réalisé au 4T YYYY",
  "VARIATION_TODATE_N": "Variation Réalisé exercice N / Réalisé exercice N-1 Au DDMMYYYY",
  "VAT": "TVA - CRL",
  "VAT AMOUNT": "Montant de TVA",
  "VAT AMOUNT TOTAL": "Montant de la TVA total",
  "VAT_AMOUNT": "Montant de TVA",
  "VAT_AMOUNT_TOTAL": "Montant de la TVA total",
  "VAT_RATE_CODE": "Code taux de TVA",
  "VEHICLE FORMATION DATE": "Date du pacte d'associés",
  "VEHICLE NET ASSET VALUE": "Valorisation de la société (100%)",
  "VEHICLE TERM": "Durée de la société",
  "VEHICLE_FORMATION_DATE": "Date du pacte d'associés",
  "VEHICLE_TERM": "Durée de la société",
  "VERSIONS_DU_PPAT": "Versions du PPAT",
  "VILLE": "Ville",
  "WEIGHTED CONTRIB TOTAL RETURN": "Contribution pondérée au rendement global du portefeuille",
  "WEIGHTED USABLE AREA": "Surface utile pondérée (m²)",
  "WEIGHTED USABLE AREA WOU": "Surface utile pondérée",
  "WEIGHTED_CONTRIB_TOTAL_RETURN": "Contribution pondérée au rendement global du portefeuille",
  "WEIGHTED_USABLE_AREA_SQM": "Surface utile pondérée (m²)",
  "WORK CAMPAIGN CODE": "Code campagne travaux",
  "WORK CAMPAIGN LABEL": "Libellé campagne travaux",
  "WORK_CAMPAIGN_CODE": "Code campagne travaux",
  "WORK_CAMPAIGN_LABEL": "Libellé campagne travaux",
  "WORKING CAPITAL CALLS": "Fond roulement appelé (€)",
  "WORKING CAPITAL RECEIVABLE": "Fond roulement à encaisser (€)",
  "WORKING CAPITAL REFUNDS": "Fond roulement reversé (€)",
  "WORKING_CAPITAL_CALLS": "Fond roulement appelé (€)",
  "WORKING_CAPITAL_RECEIVABLE": "Fond roulement à encaisser (€)",
  "WORKING_CAPITAL_REFUNDS": "Fond roulement reversé (€)",
  "WORKS END DATE": "Date d'achèvement des travaux",
  "WORKS VACANT AREA": "Surface vacante pour travaux (m²)",
  "WORKS VACANT AREA RATE": "% de la surface vacante totale",
  "WORKS_END_DATE": "Date d'achèvement des travaux",
  "WORKS_END_DATE_mismatch": "Date d'achèvement des travaux format incorrect",
  "WORKS_VACANT_AREA": "Surface vacante pour travaux (m²)",
  "WORKS_VACANT_AREA_RATE": "% de la surface vacante totale",
  "WORKSHOP AREA": "Surface à usage d'activités (m²)",
  "WORKSHOP_AREA": "Surface à usage d'activités (m²)",
  "YEAR MONTH": "Année / Mois",
  "YEAR TO DATE SALES VOLUME": "Chiffre d'affaires Year-to-Date (€)",
  "YEAR_MONTH": "Année / Mois",
  "YEAR_NUMBER": "Année",
  "YEAR_TO_DATE_SALES_VOLUME": "Chiffre d'affaires Year-to-Date (€)",
  "YEARS": "Années",
  "YES": "Oui",
  "YIELD MONITORING": "Suivi des rendements au",
  "YIELD MONITORING REPORT": "Suivi des rendements",
  "YOY ANNUAL SALES GROWTH": "Croissance du chiffre d'affaires annuel certifié",
  "YOY MONTHLY SALES GROWTH": "Croissance du chiffre d'affaires mensuel",
  "YOY_ANNUAL_SALES_GROWTH": "Croissance du chiffre d'affaires annuel certifié YYYY-1 vs YYYY-2",
  "YOY_MONTHLY_SALES_GROWTH": "Croissance du chiffre d'affaires mensuel MM/YYYY vs MM/YYYY-1",
  "lbl-connection": "Connexion",
  "lbl-language": "Language",
  "lbl-username": "Nom d'utilisateur",
  "lbl-password": "Mot de passe",
  "lbl-login": "Se connecter",
  "en-GB": "Anglais",
  "fr-FR": "Français",
  "de-DE": "Allemand",
  "lblFixedLeftColumns": "Nombre de colonnes fixes à gauche",
  "lblFixedRightColumns": "Nombre de colonnes fixes à droite",
  "id_report_template": "identifiant du modèle",
  "report_code": "Code du rapport",
  "report_name": "Rapport standard correspondant",
  "template_name": "Nom du modèle",
  "share_with_other_users": "Partagé avec les autres utilisateurs",
  "updated_at": "Modifié le",
  "nb_interfaces": "Nombre d'interfaces liées",
  "is_access_managed_by_building" : "Appliquer à toutes les propriétés",
  "Actions": "Actions",
  "lblNoDataAvailable": "Il n'y a pas de données disponibles",
  "lblViewReport": "Consulter le rapport standard",
  "lblPreferenceUpdated": "Toutes les préférences ont été traitées. {{params1}} préférences mises à jour avec succès.",
  "lblNoPreferenceUpdated": "Aucune préférence n'a été modifiée.",
  "lblMsqErrorPassword2": "Le mot de passe doit contenir a minima {{params1}} caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial !*@#$%^+=",
  "lblDatePasswordLeastDays": "Vous ne pouvez changer votre mot de passe que {{params1}} jours après sa dernière modification",
  "lblCurrentPasswordError": "Impossible de changer le mot de passe. l'ancien mot de passe est incorrect.",
  "ITEMS_PER_PAGE": "Éléments par page :",
  "NEXT_PAGE": "Suivant",
  "PREVIOUS_PAGE": "Précédent",
  "FIRST_PAGE": "Première page",
  "LAST_PAGE": "Dernière page",
  "RANGE_PAGE_LABEL": "{{startIndex}} - {{endIndex}} sur {{length}}",
  "RANGE_PAGE_LABEL_ZERO": "0 sur {{length}}",
  "menuAssetMonitoring": "Suivi du patrimoine",
  "NO_FILTER": "Aucun filtre",
  "TENANCY_SCHEDULES": "Etats locatifs",
  "RENTAL_MONITORING": "Suivi des locations",
  "EXPENSES_MONITORING": "Suivi des dépenses",
  "lblReportLaunch": "Le rapport a été lancé",
  "DETAILED": "Mis en forme",
  "CONNECTION_FAILED": "Échec de la connexion. Veuillez vérifier vos identifiants.",
  "CONNECTION_ERROR": "Erreur lors de la connexion",
  "UNKNOWN_ERROR": "Erreur inconnue",
  "SESSION_EXPIRED": "Votre session a expiré. Veuillez vous reconnecter.",
  "OTHER_OCCUPIED_AREA_SQM": "Autre - Surface occupée (m²)",
  "OTHER_TOTAL_AREA_SQM": "Autre - Surface totale (m²)",
  "OTHER_VACANT_AREA_SQM": "Autre - Surface vacante (m²)"
}
