import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject } from '@angular/core';
import { SidenavService } from './sidenav.service';
import { environment } from '../../../environments/environment';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';
import { ChartOccupancy, ChartOccupancyEvolution, ChartOccupancyEvolutionResponse, ChartOccupancyResponse, ChartOutstandingPayments, ChartOutstandingPaymentsResponse, ChartPortfolioByNature, ChartPortfolioByNatureResponse, DynamicData, OutstandingPaymentsTop10Tenants, OutstandingPaymentsTop10TenantsResponse, Result } from '../model/types';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { HttpErrorService } from './http-error.service';
import { AuthService } from './auth.service';
import { AppService } from './app.service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  private http = inject(HttpClient);
  private sidenavService = inject(SidenavService);
  private errorService = inject(HttpErrorService);
  private authSvc = inject(AuthService);
  private appService = inject(AppService);

  private loggedUser = this.authSvc.loggedUser;

  private chartsColors = {
    green: ['#82DACC', '#1B998B', '#AAFCB8', '#8CD790', '#C8FFBE', '#E1F0C4', '#7DAF9C', '#A9FFCB', '#B6EEA6', '#8FC0A9', '#8DDDD0', '#6CBDB4', '#B1FCBE', '#96DA9A', '#CDFFC3', '#7FB7B4', '#44ABA0', '#B9FCC4', '#A0DEA4', '#D2FFC9', '#77C7BA', '#198C7F', '#9BE6A8', '#80C483', '#B6E8AD', '#E3F1C9', '#88B6A5', '#B0FFCF', '#99C5B0', '#E6F2CE', '#94BDAE', '#B8FFD4', '#C3F1B6', '#A3CBB8', '#CDDBB3', '#72A08E', '#9AE8B9', '#A6D997', '#82AF9A', '#12381B', '#130E0E', '#315C8A', '#0B0C0E', '#183220', '#169E70', '#1FCC10', '#BFFF00', '#034732', '#008148', '#7ABE42', '#245906', '#0A2A04', '#DADCB4', '#1C4651', '#1F663F', '#497C3E', '#9ECC8C', '#79AF94', '#018E42', '#00D6BA', '#1CD600', '#0DAB76', '#0B5D1E', '#1C9613', '#053B06', '#56FFDA', '#23FFA7', '#46F2C1', '#23961F', '#26BF00', '#6AB344', '#D8F793', '#A0CA92', '#75B09C', '#7AB961', '#47916D', '#1C939A', '#52B4B7', '#289B98', '#1A6659', '#0D4B40', '#C9E9A8', '#9DD861', '#488728', '#2F6F0E', '#00826E', '#00B7A2', '#009B72', '#002E2C'], //green
    blue: ['#93BADA', '#E2F7F6', '#B9F0E9', '#1C6E8C', '#274156', '#8DA7BE', '#8AF3FF', '#E1EFF6', '#A4B8C4', '#A9BCD0', '#58A4B0', '#E0F2E9', '#ECF8F8'],//blue
    purple: ['#A0ABED', '#E3E2F7', '#81717A', '#9D8CA1', '#BCABAE', '#ECB0E1', '#8F3985', '#EDE3E4', '#A93F55', '#C97B84'],//purple
    red: ['#E26D5A', '#FF9C8B', '#F9E1D5', '#EFA8B8', '#BB4430', '#FF6B6B', '#DE6C83', '#A30B37', '#BD6B73', '#F7F0F5', '#F2545B', '#9C0D38'],//red
    redDate: ['#D3887B', '#C76655', '#C15542', '#AA3E2C'],//redDate
    yellow: ['#FBDD73', '#F3DFA2', '#F2F6D0', '#FFF7AE', '#FFEDE1', '#EDFFAB'], //yellow
    grey: ['#CFCDCD', '#87919E'], //grey
    brown: ['#D7BBA8', '#E4BE9E', '#A18276', '#E4BE9E', '#ECC8AF', '#A85751', '#CEB5A7', '#CA907E'],    //brown
    all: ['#82DACC', '#1B998B', '#AAFCB8', '#8CD790', '#C8FFBE', '#E1F0C4', '#7DAF9C', '#A9FFCB', '#B6EEA6', '#8FC0A9', '#8DDDD0', '#6CBDB4', '#B1FCBE', '#96DA9A', '#CDFFC3', '#7FB7B4', '#44ABA0', '#B9FCC4', '#A0DEA4', '#D2FFC9', '#77C7BA', '#198C7F', '#9BE6A8', '#80C483', '#B6E8AD', '#E3F1C9', '#88B6A5', '#B0FFCF', '#99C5B0', '#E6F2CE', '#94BDAE', '#B8FFD4', '#C3F1B6', '#A3CBB8', '#CDDBB3', '#72A08E', '#9AE8B9', '#A6D997', '#82AF9A', '#12381B', '#130E0E', '#315C8A', '#0B0C0E', '#183220', '#169E70', '#1FCC10', '#BFFF00', '#034732', '#008148', '#7ABE42', '#245906', '#0A2A04', '#DADCB4', '#1C4651', '#1F663F', '#497C3E', '#9ECC8C', '#79AF94', '#018E42', '#00D6BA', '#1CD600', '#0DAB76', '#0B5D1E', '#1C9613', '#053B06', '#56FFDA', '#23FFA7', '#46F2C1', '#23961F', '#26BF00', '#6AB344', '#D8F793', '#A0CA92', '#75B09C', '#7AB961', '#47916D', '#1C939A', '#52B4B7', '#289B98', '#1A6659', '#0D4B40', '#C9E9A8', '#9DD861', '#488728', '#2F6F0E', '#00826E', '#00B7A2', '#009B72', '#002E2C', '#93BADA', '#E2F7F6', '#B9F0E9', '#1C6E8C', '#274156', '#8DA7BE', '#8AF3FF', '#E1EFF6', '#A4B8C4', '#A9BCD0', '#58A4B0', '#E0F2E9', '#ECF8F8', '#A0ABED', '#E3E2F7', '#81717A', '#9D8CA1', '#BCABAE', '#ECB0E1', '#8F3985', '#EDE3E4', '#A93F55', '#C97B84', '#FF9C8B', '#F9E1D5', '#E26D5A', '#EFA8B8', '#BB4430', '#FF6B6B', '#DE6C83', '#A30B37', '#BD6B73', '#F7F0F5', '#F2545B', '#9C0D38', '#FBDD73', '#F3DFA2', '#F2F6D0', '#FFF7AE', '#FFEDE1', '#EDFFAB', '#CFCDCD', '#87919E', '#D7BBA8', '#E4BE9E', '#A18276', '#E4BE9E', '#ECC8AF', '#A85751', '#CEB5A7', '#CA907E'],//all
    //           green , red      , blue     , yellow   , purple   , grey     ,light blue, light red, light purple, light green
    default: ['#82DACC', '#FF9C8B', '#93BADA', '#FBDD73', '#A0ABED', '#CFCDCD', '#E2F7F6', '#F9E1D5', '#E3E2F7', '#B9F0E9'],
    collectMonitBreakdown: ['#FF9C8B', '#F9E1D5', '#FBDD73', '#93BADA', '#E2F7F6', '#82DACC', '#B9F0E9'],
    waterFall: ['#000000', '#FF9C8B', '#82DACC', '#CFCDCD'],
    gauge: ['rgba(0, 177, 106, .8)', 'rgba(46, 204, 113, .8)', 'rgba(123, 239, 178, .8)', 'rgba(254, 241, 96, .8)', 'rgba(252, 185, 65, .8)', 'rgba(242, 38, 19, .8)', 'rgba(255, 255, 255, 0)'],
  };

  selectedImportDate$ = this.sidenavService.selectedImportDate$;
  selectedPortfolio$ = toObservable(this.sidenavService.selectedPortfolio);
  selectedOwners$ = toObservable(this.sidenavService.selectedOwners);
  selectedGeographicalZones$ = toObservable(this.sidenavService.selectedGeographicalZones);
  selectedBuildingNatures$ = toObservable(this.sidenavService.selectedBuildingNatures);
  selecteIdBuildingsList$ = toObservable(this.sidenavService.selectedIdBuildingList);

  startDate: Date = new Date();

  private filters$ = combineLatest([
    this.selectedImportDate$,
    this.selectedPortfolio$,
    this.selectedOwners$,
    this.selectedGeographicalZones$,
    this.selectedBuildingNatures$,
    this.selecteIdBuildingsList$
  ]).pipe(
    filter(([importDate]) => importDate !== null)
  );

  generateRequestParams(importDate: Date) {
    this.startDate = this.appService.dateToUTCDate(importDate.getFullYear() - 1, importDate.getMonth(), 1);
    return {
      Columns: [],
      Request: {
        User: this.loggedUser()?.UserName,
        LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        ImportDate: importDate,
        AnalysisDate: importDate,
        PortfolioCode: this.sidenavService.selectedPortfolio(),
        IdBuildingList: this.sidenavService.selectedIdBuildingList(),
        NeutralizationType: 'ALL_BUILDINGS',
        IdOwnerList: this.sidenavService.selectedOwners().length === 0 ? '-1' : this.sidenavService.selectedOwners().join(','),
        GeographicalZoneList: this.sidenavService.selectedGeographicalZones().length === 0 ? "'00000000000000000000'" : this.appService.arrayToString(this.sidenavService.selectedGeographicalZones()),
        IdBuildingNatureList: this.sidenavService.selectedBuildingNatures().length === 0 ? "'00000000000000000000'" : this.appService.arrayToString(this.sidenavService.selectedBuildingNatures()),
        TargetAreaUnitCode: 'SQM',
        TargetCurrency: 'EUR',
        CurrencyRateDate: importDate,
        StartDate: this.startDate,
        EndDate: importDate,
        ReportType: 'BY-RCI',
        Active: 0,
        LeaseTypeCodeList: ["'00000'"],
        FilterPeriods: '0030_0060_0090_0180_0365',
        ConstantPatrimony: 0,
        filter: {
          User: this.loggedUser()?.UserName,
          LanguageCode: localStorage.getItem('userLocale') || navigator.language,
          ImportDate: importDate,
          AnalysisDate: importDate,
          PortfolioCode: this.sidenavService.selectedPortfolio(),
          IdBuildingList: this.sidenavService.selectedIdBuildingList(),
          NeutralizationType: 'ALL_BUILDINGS',
          IdOwnerList: this.sidenavService.selectedOwners().length === 0 ? '-1' : this.sidenavService.selectedOwners().join(','),
          GeographicalZoneList: this.sidenavService.selectedGeographicalZones().length === 0 ? "'00000000000000000000'" : this.appService.arrayToString(this.sidenavService.selectedGeographicalZones()),
          IdBuildingNatureList: this.sidenavService.selectedBuildingNatures().length === 0 ? "'00000000000000000000'" : this.appService.arrayToString(this.sidenavService.selectedBuildingNatures()),
          TargetAreaUnitCode: 'SQM',
          TargetCurrency: 'EUR',
          CurrencyRateDate: importDate,
          StartDate: this.startDate,
          EndDate: importDate,
          ReportType: 'BY-RCI',
          Active: 0,
          LeaseTypeCodeList: ["'00000'"],
          FilterPeriods: '0030_0060_0090_0180_0365',
          ConstantPatrimony: 0,
        }
      }
    };
  }

  chartPortfolioByNatureData$ = this.filters$.pipe(
    switchMap(([importDate]) => {
      const params = this.generateRequestParams(importDate as Date);

      return this.http.post<ChartPortfolioByNatureResponse>(`${environment.apiUrl}/api/ChartPortfolioByNature/ColumnsAsArray`, params).pipe(
        map(response => ({
          assetsArea: [
            {
              values: response.Result.ASSETS_AREA,
              labels: response.Result.BUILDING_NATURE_LABEL,
              type: 'pie',
              marker: {
                colors: this.chartsColors.green
              }
            }
          ],
          buildingsCount: [
            {
              values: response.Result.BUILDINGS_COUNT,
              labels: response.Result.BUILDING_NATURE_LABEL,
              type: 'pie',
              marker: {
                colors: this.chartsColors.green
              }
            }
          ],
          leasesCount: [
            {
              values: response.Result.LEASES_COUNT,
              labels: response.Result.BUILDING_NATURE_LABEL,
              type: 'pie',
              marker: {
                colors: this.chartsColors.green
              }
            }
          ],
          unitsCount: [
            {
              values: response.Result.UNITS_COUNT,
              labels: response.Result.BUILDING_NATURE_LABEL,
              type: 'pie',
              marker: {
                colors: this.chartsColors.green
              }
            }
          ],
          grossHeadlineRent: [
            {
              values: response.Result.GROSS_HEADLINE_RENT,
              labels: response.Result.BUILDING_NATURE_LABEL,
              type: 'pie',
              marker: {
                colors: this.chartsColors.green
              }
            }
          ]
        } as ChartPortfolioByNature<DynamicData[]>)),
        catchError(err => of({
          assetsArea: [],
          buildingsCount: [],
          leasesCount: [],
          unitsCount: [],
          grossHeadlineRent: [],
          error: this.errorService.formatError(err)
        } as ChartPortfolioByNature<DynamicData[]>))
      );
    })
  );

  private chartPortfolioByNatureResult = toSignal(this.chartPortfolioByNatureData$, {
    initialValue: ({
      assetsArea: [],
      buildingsCount: [],
      leasesCount: [],
      unitsCount: [],
      grossHeadlineRent: []
    } as ChartPortfolioByNature<DynamicData[]>)
  });

  assetsAreaData = computed(() => this.chartPortfolioByNatureResult().assetsArea);
  buildingsCountData = computed(() => this.chartPortfolioByNatureResult().buildingsCount);
  leasesCountData = computed(() => this.chartPortfolioByNatureResult().leasesCount);
  unitsCountData = computed(() => this.chartPortfolioByNatureResult().unitsCount);
  grossHeadlineRentData = computed(() => this.chartPortfolioByNatureResult().grossHeadlineRent);
  chartPortfolioByNatureError = computed(() => this.chartPortfolioByNatureResult().error);

  occupancyData$ = this.filters$.pipe(
    filter(([importDate]) => importDate !== null),
    switchMap(([importDate]) => {
      const params = this.generateRequestParams(importDate as Date);

      return this.http.post<ChartOccupancyResponse>(`${environment.apiUrl}/api/ChartOccupancy/ColumnsAsArray`, params).pipe(
        map(response => ({
          rentedArea: [
            {
              values: [response.Result.RENTED_AREA[0], response.Result.VACANT_AREA[0]],
              labels: response.Result.LABELS[0],
              type: 'pie',
              marker: {
                colors: this.chartsColors.default
              }
            }
          ],
          durationToNextBreak: [
            {
              value: response.Result.DURATION_TO_NEXT_BREAK[0],
              type: 'indicator',
              mode: "gauge+number",
              gauge: {
                axis: { range: [0, 12] },
                steps: this.generateSteps(["10 - 12", "10 - 8", "6 - 8", "4 - 6", "2 - 4", "0 - 2"], 0, 12)
              }
            }
          ],
          durationToLeaseEnd: [
            {
              value: response.Result.DURATION_TO_LEASE_END[0],
              type: 'indicator',
              mode: "gauge+number",
              gauge: {
                axis: { range: [0, 12] },
                steps: this.generateSteps(["10 - 12", "10 - 8", "6 - 8", "4 - 6", "2 - 4", "0 - 2"], 0, 12)
              }
            }
          ],
        } as ChartOccupancy<DynamicData[]>)),
        catchError(err => of({
          rentedArea: [],
          durationToNextBreak: [],
          durationToLeaseEnd: [],
          error: this.errorService.formatError(err)
        } as ChartOccupancy<DynamicData[]>))
      );
    })
  );

  private occupancyResult = toSignal(this.occupancyData$, {
    initialValue: ({
      rentedArea: [],
      durationToNextBreak: [],
      durationToLeaseEnd: []
    } as ChartOccupancy<DynamicData[]>)
  });

  occupancyAreaData = computed(() => this.occupancyResult().rentedArea);
  durationToNextBreakData = computed(() => this.occupancyResult().durationToNextBreak);
  durationToLeaseEndData = computed(() => this.occupancyResult().durationToLeaseEnd);
  occupancyError = computed(() => this.occupancyResult().error);

  occupancyEvolutionData$ = this.filters$.pipe(
    filter(([importDate]) => importDate !== null),
    switchMap(([importDate]) => {
      const params = this.generateRequestParams(importDate as Date);

      return this.http.post<ChartOccupancyEvolutionResponse>(
        `${environment.apiUrl}/api/ChartOccupancyEvolution/ColumnsAsArray`,
        params
      ).pipe(
        map(response => ({
          areaEvolution: [
            {
              x: response.Result.REFERENCE_DATE.map(date => date.substring(0, 10)),
              y: response.Result.RENTED_AREA_PERCENT,
              name: this.appService.getTranslation('lblPhysicalOccRate'),
              type: 'bar',
              marker: {
                color: this.chartsColors.default[0]
              }
            },
            {
              x: response.Result.REFERENCE_DATE.map(date => date.substring(0, 10)),
              y: response.Result.VACANT_AREA_PERCENT,
              name: this.appService.getTranslation('lblPhysicalVacRate'),
              type: 'bar',
              marker: {
                color: this.chartsColors.default[1]
              }
            }
          ],
          rentalIncomeEvolution: [
            {
              x: response.Result.REFERENCE_DATE.map(date => date.substring(0, 10)),
              y: response.Result.GROSS_HEADLINE_RENT,
              name: this.appService.getTranslation('lblGrossRent'),
              type: 'line',
              marker: {
                color: this.chartsColors.default[0]
              }
            },
            {
              x: response.Result.REFERENCE_DATE.map(date => date.substring(0, 10)),
              y: response.Result.GROSS_EFFECTIVE_RENT,
              name: this.appService.getTranslation('lblGrossEffectiveRent'),
              type: 'line',
              marker: {
                color: this.chartsColors.default[1]
              }
            },
            {
              x: response.Result.REFERENCE_DATE.map(date => date.substring(0, 10)),
              y: response.Result.GROSS_POTENTIAL_RENT,
              name: this.appService.getTranslation('GROSS_POTENTIAL_RENT'),
              type: 'line',
              marker: {
                color: this.chartsColors.default[2]
              }
            },
            {
              x: response.Result.REFERENCE_DATE.map(date => date.substring(0, 10)),
              y: response.Result.FAIR_MARKET_RENT,
              name: this.appService.getTranslation('FAIR_MARKET_RENT'),
              type: 'line',
              marker: {
                color: this.chartsColors.default[3]
              }
            }
          ]
        } as ChartOccupancyEvolution<DynamicData[]>)),
        catchError(err => of({
          areaEvolution: [],
          rentalIncomeEvolution: [],
          error: this.errorService.formatError(err)
        } as ChartOccupancyEvolution<DynamicData[]>))
      );
    })
  );

  private occupancyEvolutionResult = toSignal(this.occupancyEvolutionData$, {
    initialValue: ({
      areaEvolution: [],
      rentalIncomeEvolution: []
    } as ChartOccupancyEvolution<DynamicData[]>)
  });

  areaEvolutionData = computed(() => this.occupancyEvolutionResult().areaEvolution);
  rentalIncomeEvolutionData = computed(() => this.occupancyEvolutionResult().rentalIncomeEvolution);
  occupancyEvolutionError = computed(() => this.occupancyEvolutionResult().error);

  outstandingPaymentsData$ = this.filters$.pipe(
    filter(([importDate]) => importDate !== null),
    switchMap(([importDate]) => {
      const params = this.generateRequestParams(importDate as Date);

      return this.http.post<ChartOutstandingPaymentsResponse>(
        `${environment.apiUrl}/api/ChartOutstandingPayments/ColumnsAsArray`,
        params
      ).pipe(
        map(response => ({
          waterfall: [
            {
              type: "waterfall",
              x: [
                this.appService.getTranslation("BALANCE_START_AMOUNT")
                  .replace('DD/MM/YYYY', this.startDate!.toISOString().substring(0, 10)),
                this.appService.getTranslation("BEFORE_PAYMENTS_INC_TAX_AMOUNT"),
                this.appService.getTranslation("INVOICING"),
                this.appService.getTranslation("PAYMENT_INCL_TAX_AMNT"),
                this.appService.getTranslation("OVERPAYMENTS_INC_TAX_AMOUNT"),
                this.appService.getTranslation("BALANCE_END_AMOUNT")
                  .replace('DD/MM/YYYY', (importDate as Date).toISOString().substring(0, 10))
              ],
              measure: ["absolute", "relative", "relative", "relative", "relative", "total"],
              y: [
                response.Result.BALANCE_START_AMOUNT[0],
                -response.Result.BEFORE_PAYMENTS_INC_TAX_AMNT[0],
                response.Result.INVOICING_INCL_TAX_AMOUNT[0],
                -response.Result.PAYMENT_INCL_TAX_AMOUNT[0],
                -response.Result.ADVANCE_PAYMENTS_INC_TAX_AMNT[0],
                response.Result.BALANCE_END_AMOUNT[0]
              ],
              decreasing: { marker: { color: '#82DACC' } },
              increasing: { marker: { color: '#FF9C8B' } },
              totals: { marker: { color: '#93BADA' } }
            }
          ]
        } as ChartOutstandingPayments<DynamicData[]>)),
        catchError(err => of({
          waterfall: [],
          error: this.errorService.formatError(err)
        } as ChartOutstandingPayments<DynamicData[]>))
      );
    })
  );

  private outstandingPaymentsResult = toSignal(this.outstandingPaymentsData$, {
    initialValue: ({
      waterfall: []
    } as ChartOutstandingPayments<DynamicData[]>)
  });

  waterfallOutstandingPaymentsData = computed(() => this.outstandingPaymentsResult().waterfall);
  outstandingPaymentsError = computed(() => this.outstandingPaymentsResult().error);


  outstandingPaymentsTop10TenantsData$ = this.filters$.pipe(
    filter(([importDate, portfolio, owners, geographicalZones, buildingNatures, IdBuildings]) => importDate !== null && IdBuildings !== null && IdBuildings.length > 0),
    switchMap(([importDate]) => {
      const params = this.generateRequestParams(importDate as Date);

      return this.http.post<OutstandingPaymentsTop10TenantsResponse>(
        `${environment.apiUrl}/api/OutstandingPayments/GetOutPayByTenant1ColumnsAsArray`,
        params
      ).pipe(
        map(response => ({
          top10Tenants: [
            {
              x: response.Result.VALUES[0][0][0][0],
              y: response.Result.YLABELS,
              name: response.Result.LABELS[0],
              type: 'bar',
              orientation: 'h',
              marker: {
                color: this.chartsColors.default[0]
              }
            },
            {
              x: response.Result.VALUES[0][0][1][0],
              y: response.Result.YLABELS,
              name: response.Result.LABELS[1],
              type: 'bar',
              orientation: 'h',
              marker: {
                color: this.chartsColors.default[1]
              }
            },
            {
              x: response.Result.VALUES[0][0][2][0],
              y: response.Result.YLABELS,
              name: response.Result.LABELS[2],
              type: 'bar',
              orientation: 'h',
              marker: {
                color: this.chartsColors.default[2]
              }
            },
            {
              x: response.Result.VALUES[0][0][3][0],
              y: response.Result.YLABELS,
              name: response.Result.LABELS[3],
              type: 'bar',
              orientation: 'h',
              marker: {
                color: this.chartsColors.default[3]
              }
            },
            {
              x: response.Result.VALUES[0][0][4][0],
              y: response.Result.YLABELS,
              name: response.Result.LABELS[4],
              type: 'bar',
              orientation: 'h',
              marker: {
                color: this.chartsColors.default[4]
              }
            },
            {
              x: response.Result.VALUES[0][0][5][0],
              y: response.Result.YLABELS,
              name: response.Result.LABELS[5],
              type: 'bar',
              orientation: 'h',
              marker: {
                color: this.chartsColors.default[5]
              }
            }
          ],
        } as OutstandingPaymentsTop10Tenants<DynamicData[]>)),
        catchError(err => of({
          top10Tenants: [],
          error: this.errorService.formatError(err)
        } as OutstandingPaymentsTop10Tenants<DynamicData[]>))
      );
    })
  );

  private outstandingPaymentsTop10TenantsResult = toSignal(this.outstandingPaymentsTop10TenantsData$, {
    initialValue: ({
      top10Tenants: []
    } as OutstandingPaymentsTop10Tenants<DynamicData[]>)
  });

  outstandingPaymentsTop10TenantsData = computed(() => this.outstandingPaymentsTop10TenantsResult().top10Tenants);
  outstandingPaymentsTop10TenantsError = computed(() => this.outstandingPaymentsTop10TenantsResult().error);


  generateSteps(labels: string[], minValue: number, maxValue: number): any[] {
    let steps = [];
    let range = maxValue - minValue;
    let stepSize = range / labels.length;

    for (let i = 0; i < labels.length; i++) {
      steps.push({
        range: [minValue + i * stepSize, minValue + (i + 1) * stepSize],
        color: this.getStepColor(i, labels.length),
      });
    }

    return steps;
  }

  getStepColor(index: number, total: number): string {
    const colors = this.chartsColors.gauge;
    return colors[(total - index - 1) % colors.length];
  }

}




