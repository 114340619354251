import { Component, inject } from '@angular/core';
import { GraphService } from '../../shared/service/graph.service';
import { PlotlyModule } from 'angular-plotly.js';
import { CardGraphComponent } from '../card-graph/card-graph.component';
import { SidenavService } from '../../shared/service/sidenav.service';

@Component({
    selector: 'app-risk',
    imports: [PlotlyModule, CardGraphComponent],
    templateUrl: './risk.component.html',
    styleUrl: './risk.component.scss'
})
export class RiskComponent {
  private grahService = inject(GraphService);
  private sidenavService = inject(SidenavService);

  private plotlyGraphDimensions = {
    width: 700,
    height: 400
  };

  plotlyConfig = { displaylogo: false };

  private commonLayout = {
    ...this.plotlyGraphDimensions,
    font: {
      family: 'Roboto',
      size: 16
    }
  };

  // Duration to Next Break
  durationToNextBreakLayout = { ...this.commonLayout };
  durationToNextBreakData = this.grahService.durationToNextBreakData;

  // Duration to Lease End
  durationToLeaseEndLayout = { ...this.commonLayout };
  durationToLeaseEndData = this.grahService.durationToLeaseEndData;

  occupancyError = this.grahService.occupancyError;

  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.sidenavService.showBuildingsList.set(false);
  }

}
