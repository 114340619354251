<div class="container">
  <mat-card class="card-style">
    <mat-card-content>
      <!-- Personal Details Form -->
      <form [formGroup]="personalDetailsForm" (ngSubmit)="onSavePersonalDetails()" >
        <h3>{{"lblGeneralSettings" | translate | uppercase}}</h3>

        <div class="form-columns">
          <div class="left-column">
            <mat-form-field appearance="outline">
              <mat-label>{{"lbllastName" | translate}}</mat-label>
              <input matInput id="name" formControlName="name">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{"lblFirstName" | translate}}</mat-label>
              <input matInput id="firstName" formControlName="firstName">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{"lblCompany" | translate}}</mat-label>
              <input matInput id="company" formControlName="company">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{"lblEmail" | translate}}</mat-label>
              <input matInput id="email" formControlName="email" type="email">
            </mat-form-field>
          </div>

          <div class="right-column">
            <mat-form-field appearance="outline">
              <mat-label>{{"lblCSVSeparator" | translate}}</mat-label>
              <input matInput id="csvSeparator" formControlName="csvSeparator" maxlength="2">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{"lblAreaUnit" | translate}}</mat-label>
              <mat-select id="surfaceUnit" formControlName="surfaceUnit">
                <mat-option *ngFor="let unit of surfaceUnits" [value]="unit['area_unit_code']">{{
                  unit['area_unit_label'] }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{"lblCurrency" | translate}}</mat-label>
              <mat-select id="currency" formControlName="currency">
                <mat-option *ngFor="let currency of currencies" [value]="currency['currency']">{{
                  currency['currency_label'] }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="buttons">
          <button mat-raised-button color="primary" type="submit" [disabled]="personalDetailsForm.invalid">{{"btnSubmit"
            | translate}}</button>
          <button mat-raised-button color="warn" type="button" (click)="onCancelPersonalDetails()">{{"btnCancel" | translate}}</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card class="card-style">
    <mat-card-content>
      <!-- Change Password Form -->
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSavePassword()">
        <h3>{{"lblChangePwdLegend" | translate}}</h3>

        <mat-form-field appearance="outline">
          <mat-label>{{"lblCurrentPassword" | translate}}</mat-label>
          <input matInput [type]="hideCurrentPassword ? 'password' : 'text'" id="currentPassword"
            formControlName="currentPassword">
          <button type="button" mat-icon-button matSuffix (click)="hideCurrentPassword = !hideCurrentPassword"
            [attr.aria-label]="'Hide current password'" [attr.aria-pressed]="hideCurrentPassword" >
            <mat-icon>{{hideCurrentPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="changePasswordForm.get('currentPassword')?.hasError('required')">
            {{"lblCurrentPasswordRequired" | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{"lblNewPassword" | translate}}</mat-label>
          <input matInput [type]="hideNewPassword ? 'password' : 'text'" id="newPassword" formControlName="newPassword">
          <button type="button" mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword"
            [attr.aria-label]="'Hide new password'" [attr.aria-pressed]="hideNewPassword" >
            <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="changePasswordForm.get('newPassword')?.hasError('required')">
            {{"lblPasswordRequired" | translate}}
          </mat-error>
          <mat-error *ngIf="changePasswordForm.get('newPassword')?.hasError('passwordStrength')">
            {{"lblMsqErrorPassword2" | translate : {params1:12} }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{"lblConfirmPassword" | translate}}</mat-label>
          <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" id="confirmPassword"
            formControlName="confirmPassword">
          <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
            [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmPassword" >
            <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="changePasswordForm.get('confirmPassword')?.hasError('required')">
            {{"lblConfirmPasswordRequired" | translate}}
          </mat-error>
          <mat-error *ngIf="changePasswordForm.get('confirmPassword')?.hasError('passwordMatch')">
            {{"lblConfirmNewPasswordMismatch" | translate}}
          </mat-error>
        </mat-form-field>

        <div class="buttons">
          <button mat-raised-button color="primary" type="submit" [disabled]="changePasswordForm.invalid">{{"btnSubmit"
            | translate}}</button>
          <button mat-raised-button color="warn" type="button" (click)="onCancelPassword()">{{"btnCancel" | translate}}</button>
        </div>
      </form>

    </mat-card-content>
  </mat-card>
</div>
