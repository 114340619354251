import { ApplicationConfig, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { tokenInterceptor } from './shared/interceptor/token.interceptor';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenService } from './shared/service/token.service';
import { appInitializer } from './shared/interceptor/appInitializer';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './shared/paginator/custom-paginator-intl.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateFormatService } from './shared/service/date-format-service.service';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([tokenInterceptor])
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        }
      })
    ),
    provideAppInitializer(() => {
      const tokenService = inject(TokenService);
      appInitializer(tokenService);
    }),
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (translate: TranslateService) => {
        return translate.currentLang; // Dynamically get the current language for the locale
      },
      deps: [TranslateService],
    },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (dateFormatSvc: DateFormatService) => {
        return dateFormatSvc.getFormats();
      },
      deps: [DateFormatService],
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter, // Use MomentDateAdapter for consistency
      deps: [MAT_DATE_LOCALE], // Locale will be dynamically set
    }
  ]
};
