import { Component, OnInit, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { SidenavService } from '../../shared/service/sidenav.service';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppService } from '../../shared/service/app.service';
import { ParametersService } from '../../shared/service/parameters.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DynamicTableComponent } from '../../dynamic-table/dynamic-table.component';
import { ReportDatesService } from '../../shared/service/report-dates.service';
import { Observable } from 'rxjs';
import { InsertFileImportRequestModel, RepositoryLease, ServerSideResponse } from '../../shared/model/types';
import { AuthService } from '../../shared/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../dialog/success-dialog/success-dialog.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';

@Component({
    selector: 'app-lease-repository',
    imports: [DynamicTableComponent, MatButtonModule, MatCardModule, MatCheckboxModule, MatDividerModule, MatFormFieldModule, MatSidenavModule, TranslateModule, ReactiveFormsModule],
    templateUrl: './lease-repository.component.html',
    styleUrl: './lease-repository.component.scss'
})
export class LeaseRepositoryComponent implements OnInit {

  private sidenavService = inject(SidenavService);
  private appService = inject(AppService);
  private parametersService = inject(ParametersService);
  private reportDatesService = inject(ReportDatesService);
  private authService = inject(AuthService);
  private dialog = inject(MatDialog);

  cbLeaseRepositoryControl = new FormControl(false);

  private txtMenuLeaseRepository: string = this.appService.getTranslation("txtMenuLeaseRepository");
  txtMenuLeaseRepositoryPart1: string = this.txtMenuLeaseRepository.substring(0, this.txtMenuLeaseRepository.indexOf("<BR/>")).replace("Alhambra", "ProperLake");
  txtMenuLeaseRepositoryPart2: string = this.txtMenuLeaseRepository.substring(this.txtMenuLeaseRepository.indexOf("<BR/>") + 7);

  private txtModelImportFile: string = this.appService.getTranslation("txtModelImportFile");
  txtModelImportFilePart1: string = this.txtModelImportFile.substring(0, this.txtModelImportFile.indexOf("</br>"));
  txtModelImportFilePart2: string = this.txtModelImportFile.substring(this.txtModelImportFile.indexOf("</br>") + 6, this.txtModelImportFile.indexOf("</br>", this.txtModelImportFile.indexOf("</br>") + 6));
  txtModelImportFilePart3: string = this.txtModelImportFile.substring(this.txtModelImportFile.indexOf("</br>", this.txtModelImportFile.indexOf("</br>") + 7) + 7).replace('<i>', '"').replace('</i>', '"');

  analysisDate = this.reportDatesService.analysisDate;
  startDate = this.reportDatesService.startDate;
  stickyLeftColumnsCount: number = 0;
  columnsToDisplay = signal<string[]>([
    // "id_repository_file_import",
    "date_import",
    "filename",
    // "date_value",
    // "revision_date",
    "imported_by"
    //"is_locked"
  ]);

  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
  }

  downloadTemplate() {
    this.parametersService.downloadLeaseRepositoryTemplate(this.cbLeaseRepositoryControl.value);
  }

  downloadFile(data: Blob) {
    const blob = new Blob([data], { type: 'text/csv' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = "LeaseRepository.csv";
    link.click();
  }

  upload(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    this.parametersService.csvSeparator$.subscribe(csvSeparatorData => {
      const csvSeparator: string = csvSeparatorData.data && csvSeparatorData.data.length > 0 ? csvSeparatorData.data[0]['preference_value'] : ";";
      let leaseRepositoryList: RepositoryLease[] = [];
      if (file) {
        let reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          let csv: string = reader.result as string;
          //this.appService.log("LeaseRepositoryComponent - Upload csv :", csv);

          let index = 0;
          let lineNumber = 0;
          let skipLine = false;
          let firstline = true;
          for (const line of csv.split(/[\r\n]+/)) {
            this.appService.log("LeaseRepositoryComponent - Line of the uploaded csv :", line);

            if (firstline) {
              firstline = false;
            }
            else {
              index = 0;
              lineNumber++;
              skipLine = false;
              let val: RepositoryLease = {
                BuildingCode: "",
                BuildingName: "",
                LeaseCode: "",
                TenantName: "",
                IsInLitigation: "",
                LitigationInformation: "",
                LitigationAmount: 0,
                Currency: ""
              }
              for (const data of line.split(csvSeparator)) {
                switch (index) {
                  case 0:
                    val.BuildingCode = csvSeparator == "\t" ? data.trim() : data.trim().replace('\t', ''); // Remove the tab that was add because of Excel
                    if (val.BuildingCode === null || val.BuildingCode === "") {
                      skipLine = true;
                    }
                    break;
                  case 1:
                    val.BuildingName = data;
                    break;
                  case 2:
                    val.LeaseCode = csvSeparator == "\t" ? data.trim() : data.trim().replace('\t', ''); // Remove the tab that was add because of Excel
                    if (val.LeaseCode === null || val.LeaseCode === "") {
                      skipLine = true;
                    }
                    break;
                  case 3:
                    val.TenantName = data;
                    break;
                  case 4:
                    if (data == "1") {
                      val.IsInLitigation = "1";
                    }
                    else {
                      val.IsInLitigation = "0";
                    }
                    break;
                  case 5:
                    val.LitigationInformation = data.length > 2000 ? data.substring(0, 1999) : data;
                    break;
                  case 6:
                    val.LitigationAmount = +data.replace(',', '.').replace(" ", "");
                    break;
                  case 7:
                    val.Currency = data === null || data === "" || data === " " ? "EUR" : data.trim();
                    break;
                }
                if (skipLine) {
                  break;
                }
                index++;

              }
              if (!skipLine) {
                leaseRepositoryList.push(val);
              }
            }
          }
          if (leaseRepositoryList.length > 0) {
            const requestParams: InsertFileImportRequestModel = {
              ImportType: "REPOSITORY_LEASE",
              File: File.name,
              ValueDate: new Date(),
              ImportedBy: this.authService.loggedUser()?.UserName,
              IdRepositoryFileImport: undefined
            };

            let nbInsert = 0, nbRejected = 0;
            this.parametersService.insertFileImport(requestParams).subscribe({
              next: (idRepositoryFileImport) => {
                this.appService.log("LeaseRepositoryComponent - FileImport new id :", idRepositoryFileImport);

                let lineNumber = 0;
                if (idRepositoryFileImport > 0) {
                  leaseRepositoryList.forEach((val) => {
                    lineNumber++;
                    if (val.IsInLitigation !== null && val.IsInLitigation !== "") {
                      const insertRepositoryLeaseParams = {
                        ...val,
                        IdRepositoryFileImport: idRepositoryFileImport,
                        User: this.authService.loggedUser()?.UserName,
                        IdRepositoryLease: undefined
                      };
                      this.parametersService.insertRepositoryLease(insertRepositoryLeaseParams).subscribe({
                        next: (idRepositoryLease) => {
                          if (idRepositoryLease > 0) {
                            nbInsert++;
                          }
                          else {
                            nbRejected++;
                            this.appService.log("LeaseRepositoryComponent - Rejected record :", "Line : " + lineNumber.toString()
                              + " - Building code : " + val.BuildingCode + " - Lease code : " + val.LeaseCode
                              + " - Is in litigation : " + val.IsInLitigation + " - LitigationInformation : " + val.LitigationInformation);
                          }
                        }
                      });
                    }
                  });
                }
              },
              error: (error) => {
                const dialogRef = this.dialog.open(ErrorDialogComponent, {
                  data: {
                    message: this.appService.getTranslation('lblUpdateLeaseRepositoryError'),
                  },
                });
              },
              complete: () => {
                const dialogRef = this.dialog.open(SuccessDialogComponent, {
                  data: {
                    message:
                      this.appService.getTranslation("lblXLinesInserted").replace("{0}", nbInsert.toString())
                      + "\r\n" + this.appService.getTranslation("lblXLinesRejected").replace("{0}", nbRejected.toString()),
                  },
                });

              }
            });
          }
        }
      }
    }
    );
  }

  getFileImportData = (): Observable<ServerSideResponse> => {
    return this.parametersService.getFileImportData();
  }

}
