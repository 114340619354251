import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacePeriod',
  standalone: true
})
export class ReplacePeriodPipe implements PipeTransform {

  transform(value: string, code: string, filterPeriod: String | undefined): string {
    let result = value;
    if (filterPeriod) {
      switch (code.substring(0, 16)) {
        case "PAYMENT_PERIOD_1":
          result = value.replace('{0}', (+filterPeriod.substring(0, 4)).toString());
          break;
        case "PAYMENT_PERIOD_2":
          result = value.replace('{0}', (+filterPeriod.substring(0, 4)).toString()).replace('{1}', (+filterPeriod.substring(5, 9)).toString());
          break;
        case "PAYMENT_PERIOD_3":
          result = value.replace('{0}', (+filterPeriod.substring(5, 9)).toString()).replace('{1}', (+filterPeriod.substring(10, 14)).toString());
          break;
        case "PAYMENT_PERIOD_4":
          result = value.replace('{0}', (+filterPeriod.substring(10, 14)).toString()).replace('{1}', (+filterPeriod.substring(15, 19)).toString());
          break;
        case "PAYMENT_PERIOD_5":
          result = value.replace('{0}', (+filterPeriod.substring(15, 19)).toString()).replace('{1}', (+filterPeriod.substring(20, 24)).toString());
          break;
        case "PAYMENT_PERIOD_6":
          result = value.replace('{0}', (+filterPeriod.substring(20, 24)).toString());
          break;
      }
    }

    return result;
  }

}
