<mat-form-field appearance="outline" [style.width.px]="width">
  <mat-label>{{label | translate}}</mat-label>
  <mat-select [formControl]="selectedControl" multiple #select (closed)="onClosed()"  [compareWith]="compareFn">
    <mat-select-trigger>
      {{ getSelectedItemsLabel() | translate }}
      <span *ngIf="(selectedControl.value?.length || 0) > 1" class="additional-selection">
        +{{ (selectedControl.value?.length || 0) - 1 }}
        {{ (selectedControl.value?.length === 1 ? 'lblOther' : 'lblOther') | translate }}
      </span>
    </mat-select-trigger>
    <mat-option disabled>
      <ngx-mat-select-search placeholderLabel="{{ 'SEARCH' | translate }}" noEntriesFoundLabel="{{ 'NO DATA' | translate}}" [formControl]="filterControl">
      </ngx-mat-select-search>
    </mat-option>
    <div class="select-all">
      <mat-checkbox [(ngModel)]="allSelected"
        [ngModelOptions]="{standalone: true}"
        (change)="toggleAllSelection()">
        <span class="label">{{ 'lkbSelectAll' | translate }}</span>
      </mat-checkbox>
    </div>
      @for (item of itemsFormatted() | async; track item) {
        <mat-option [value]="item" (click)="updateSelectAllState()">{{item['value']}}</mat-option>
      }
  </mat-select>
</mat-form-field>
