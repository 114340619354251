import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../../shared/service/auth.service';
import { AppService } from '../../../shared/service/app.service';

@Component({
    selector: 'app-password-recovery',
    imports: [ReactiveFormsModule, MatInputModule, CommonModule, MatButtonModule, MatCardModule, TranslateModule],
    templateUrl: './password-recovery.component.html',
    styleUrl: './password-recovery.component.scss'
})
export class PasswordRecoveryComponent {
  recoveryForm!: FormGroup;
  private fb = inject(FormBuilder);
  private authSvc = inject(AuthService);
  private appService = inject(AppService);

  constructor() {}

  ngOnInit(): void {
    this.recoveryForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  async onSubmit() {
    if (this.recoveryForm.valid) {
      const email = this.recoveryForm.value.email;
      if(await this.authSvc.recoverPassword(email)){
        // TO DO : Notify the user !!!
        this.appService.log("PasswordRecoveryComponent - Send mail :", "Notify the mail was sent for the user");
      }
    }
  }
}
