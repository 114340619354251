import { Component, OnInit, computed, effect, inject, signal } from '@angular/core';
import { DynamicTableComponent } from '../../dynamic-table/dynamic-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ActivatedRoute, ParamMap, Router, RouterModule } from '@angular/router';
import { ReportService } from '../../shared/service/report.service';
import { distinctUntilChanged, map, Observable, of, ReplaySubject, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { DynamicData, ServerSideResponse } from '../../shared/model/types';
import { SidenavService } from '../../shared/service/sidenav.service';
import { MatCardModule } from '@angular/material/card';
import { SpecificCriteriaComponent } from '../specific-critera/specific-criteria.component';
import { ReportDatesComponent } from '../report-dates/report-dates.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportDatesService } from '../../shared/service/report-dates.service';
import { CommonModule } from '@angular/common';
import { AppService } from '../../shared/service/app.service';
import { ModelReportService } from '../../shared/service/model-report.service';
import { AuthService } from '../../shared/service/auth.service';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../dialog/success-dialog/success-dialog.component';

@Component({
    selector: 'app-report',
    imports: [CommonModule, MatButtonModule, MatButtonToggleModule, MatDialogModule, FormsModule, ReactiveFormsModule, TranslateModule, DynamicTableComponent, MatCardModule, SpecificCriteriaComponent, ReportDatesComponent, MatSelectModule, NgxMatSelectSearchModule, MatIconModule, RouterModule],
    templateUrl: './report.component.html',
    styleUrl: './report.component.scss'
})
export class ReportComponent implements OnInit {

  private sidenavService = inject(SidenavService);
  private route = inject(ActivatedRoute);
  private reportService = inject(ReportService);
  private reportDatesService = inject(ReportDatesService);
  private appService = inject(AppService);
  private modelReportService = inject(ModelReportService);
  private authService = inject(AuthService);
  private dialog = inject(MatDialog);
  private router = inject(Router);

  private reportColumns = this.reportService.reportColumns;
  private reportController = this.reportService.reportController;
  reportCode = this.reportService.reportCode;
  private reportType = this.reportService.reportType;

  columnsToDisplay = signal<string[]>([]);

  stickyLeftColumnsCount: number = 0;
  stickyRightColumnsCount: number = 0;

  showSpecificCriteria: boolean = false;

  // ReportCode: string | null = "";
  ReportName: string | null = "";
  subReportCode: string | null = "";
  idReportTemplateUrl: string | null = "";

  exportTypeControl = new FormControl('RAW');
  exportFormatControl = new FormControl('EXCELOPENXML');
  reportModelControl = new FormControl<DynamicData>({});
  reportModel = signal<DynamicData[]>([]);

  public filterControl: FormControl = new FormControl();
  public filteredList: ReplaySubject<DynamicData[]> = new ReplaySubject<DynamicData[]>(1);
  private _onDestroy = new Subject<void>();
  private stopPolling = new Subject<void>();

  items = computed(() => {
    this.filteredList.next(this.reportModel());

    this.filterControl.valueChanges.pipe(
      takeUntil(this._onDestroy),
      map(search => this.filterItems(this.reportModel(), search))
    ).subscribe(filtered => {
      console.log(filtered)
      this.filteredList.next(filtered);
    });
    return this.filteredList;
  });

  analysisDate = computed(() => {
    if (this.reportDatesService.showDateRange) {
      return this.reportDatesService.endDate();
    }
    else {
      return this.reportDatesService.analysisDate();
    }
  });

  startDate = computed(() => {
    if (this.reportDatesService.showDateRange) {
      return this.reportDatesService.startDate();
    }
    else {
      return this.reportDatesService.analysisDate();
    }
  });

  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.sidenavService.showBuildingsList.set(true);

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.reportModelControl.reset();
      const paramReportCode = params.get('reportcode'); // Report code with or without _RAW
      this.reportCode.set(paramReportCode?.includes('RAW') ? paramReportCode?.substring(0, paramReportCode.length - 4) : paramReportCode); // Get the report code without RAW
      this.subReportCode = params.get('subreportcode');
      this.idReportTemplateUrl = params.get('idreporttemplate');

      //Get report model based on report code
      this.modelReportService.getReportTemplates(paramReportCode, this.authService.getLoggedUser()?.UserName!).subscribe(result => {
        // Filtrer les modèles avec "TS_UNIT" ou "TS_UNIT_RAW"
        const filteredData = result.data.filter((model: DynamicData) =>
          model["report_code"] === paramReportCode || model["report_code"] === paramReportCode + "_RAW"
        );
        this.reportModel.set(filteredData);
        if (this.idReportTemplateUrl) {
          const model = this.reportModel().find(el => el["id_report_template"] === Number(this.idReportTemplateUrl))
          this.reportModelControl.patchValue(model!);
        }
      });

      this.initReport();

      //Change export type status according to selected model
      this.reportModelControl.valueChanges.subscribe(selectedModel => {
        if (selectedModel && selectedModel["report_code"]) {
          if (selectedModel["report_code"].includes('RAW')) {
            this.exportTypeControl.setValue('RAW');
            this.exportTypeControl.disable();
          } else {
            this.exportTypeControl.setValue('DETAILED');
            this.exportTypeControl.disable();
          }
          //Get the columns of the report model
          this.modelReportService
            .getReportTemplateData(selectedModel["report_code"], selectedModel["id_report_template"], '1')
            .subscribe((result) => {
              this.columnsToDisplay.set(result.data.map(col => col["COLUMN_NAME"]));
            });
        } else {
          this.exportTypeControl.enable();
          //this.initReport();
          return;
        }
      });
    }
    );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stopPolling.next();
    this.stopPolling.complete();
  }

  private filterItems(items: DynamicData[], search: string): DynamicData[] {
    if (!search) {
      return items;
    }
    // Normalize the search string
    const normalizedSearch = this.appService.normalizeString(search);
    // Filter and normalize the items
    return items.filter(item =>
      this.appService.normalizeString(item["template_name"]).includes(normalizedSearch)
    );
  }

  modifyTemplate() {
    const url = `model/${this.reportCode()}/${this.reportModelControl.value?.['id_report_template']}`;
    this.router.navigateByUrl(url);
  }

  getReportData = (): Observable<ServerSideResponse> => {
    this.reportService.reportController = this.reportController;
    this.reportService.reportCode = this.reportCode;
    this.reportService.reportColumns = this.reportColumns;
    this.reportService.reportType = this.reportType;

    if (this.reportController()?.trim()) {
      return timer(0, 50000).pipe(
        switchMap(() => this.reportService.reportData$),
        // Use `distinctUntilChanged` to avoid emitting duplicate values
        // This operator compares each emitted value with the previous one
        // Here, we provide a custom comparator using `JSON.stringify` to handle objects
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.stopPolling)
      );
    }
    else {
      const response: ServerSideResponse = {
        draw: 0,
        recordsTotal: 0,
        recordsFiltered: 0,
        data: [],
        ColSums: {},
        dataType: ""
      };
      return of(response);
    }

  }

  exportReport() {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      data: {
        message:
          'lblReportLaunch',
      }
    });
    const reportTemplateId = Number(this.reportModelControl.value?.['id_report_template']) || null;
    this.reportService.getReportExport(this.reportCode(), this.ReportName, this.reportController(), this.exportTypeControl.value, this.exportFormatControl.value, reportTemplateId)
      .subscribe(
        (data: Blob) => this.downloadFile(data)
      );
  }

  downloadFile(data: Blob) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = (this.reportModelControl.value?.['template_name'] ? this.reportModelControl.value?.['template_name'] : this.ReportName) + (this.exportFormatControl.value === "EXCELOPENXML" ? '.xlsx' : '.pdf');
    link.click();
  }


  initReport(): void {
    switch (this.reportCode()) {
      case "TS_BUILDING": {
        this.reportController.set("TenancyScheduleBuilding");
        this.ReportName = this.appService.getTranslation("lblTSBuildingReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            }
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "BUILDING_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "NUMBER_OF_BUILDINGS",
          "MAX_NUMBER_OF_LEVELS",
          "NUMBER_OF_TENANTS",
          "NUMBER_OF_LEASES",
          "NUMBER_OF_UNITS",
          "NUMBER_OF_PARKING_SPACES",
          "NUMBER_OF_OCCUPIED_UNITS",
          "NUMBER_OF_VACANT_UNITS",
          "UNITS_TOTAL_AREA_SQM",
          "OCCUPIED_AREA_SQM",
          "VACANT_AREA_SQM",
          "GROSS_HEADLINE_RENT",
          "GROSS_HEADLINE_RENT_M2",
          "RRE_TOTAL_AREA_SQM",
          "RRE_OCCUPIED_AREA_SQM",
          "RRE_VACANT_AREA_SQM",
          "RRE_GROSS_HEADLINE_RENT",
          "RRE_GROSS_HEADLINE_RENT_M2",
          "RRE_ABATEMENT",
          "ERE_TOTAL_AREA_SQM",
          "ERE_OCCUPIED_AREA_SQM",
          "ERE_VACANT_AREA_SQM",
          "ERE_GROSS_HEADLINE_RENT",
          "ERE_GROSS_HEADLINE_RENT_M2",
          "ERE_ABATEMENT",
          "OTHER_TOTAL_AREA_SQM",
          "OTHER_OCCUPIED_AREA_SQM",
          "OTHER_VACANT_AREA_SQM"
        ]);
        this.stickyLeftColumnsCount = 1;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_LEASE": {
        this.reportController.set("TenancySchedule");
        this.ReportName = this.appService.getTranslation("lblTenancySchedule");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "LEASE_CODE",
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "INSURANCE_POLICY_NUMBER",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_TERMINATION",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_VALUE",
          "INITIAL_INDEX_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "LEASE_UNITS",
          "LEASE_FLOORS",
          "MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "MAIN_UNIT_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "MAIN_UNIT_BUILDING",
          "MAIN_UNIT_STAIRCASE",
          "MAIN_UNIT_FLOOR",
          "DOOR_NUMBER",
          "RENTED_AREA",
          "VACANT_AREA",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "OFFICE_AREA",
          "RESIDENTIAL_AREA",
          "RETAIL_AREA",
          "WORKSHOP_AREA",
          "ARCHIVE_AREA",
          "STORAGE_AREA",
          "INDUSTRIAL_AREA",
          "MISCELLANEOUS_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "NUMBER_OF_SIMPLE_CAR_PARKS",
          "NUMBER_OF_DOUBLE_CAR_PARKS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUL_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "MGMT_FEES_OF_GARB_COLLECT_TAX",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "BREAKPOINT_AMOUNT_1",
          "ENTRANCE_FEES",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 3;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_MIXED": {
        this.reportController.set("TenancyScheduleMixed");
        this.ReportName = this.appService.getTranslation("lblTSMixedReport");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "LEASE_CODE",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "INSURANCE_POLICY_NUMBER",
          "TENANT_CODE",
          "TENANT_NAME",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_LEASE_TERMINATION",
          "VACANCY_START_DATE",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_VALUE",
          "INITIAL_INDEX_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "LEASE_UNITS",
          "LEASE_FLOORS",
          "MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "MAIN_UNIT_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "MAIN_UNIT_BUILDING",
          "MAIN_UNIT_STAIRCASE",
          "MAIN_UNIT_FLOOR",
          "DOOR_NUMBER",
          "RENTED_AREA",
          "VACANT_AREA",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "OFFICE_AREA",
          "RESIDENTIAL_AREA",
          "RETAIL_AREA",
          "WORKSHOP_AREA",
          "ARCHIVE_AREA",
          "STORAGE_AREA",
          "INDUSTRIAL_AREA",
          "MISCELLANEOUS_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "PRIVATE_UNIT_AREA",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "NUMBER_OF_SIMPLE_CAR_PARKS",
          "NUMBER_OF_DOUBLE_CAR_PARKS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "CURRENT_MARKET_VALUE",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUR_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "MGMT_FEES_OF_GARB_COLLECT_TAX",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "ENTRANCE_FEES",
          "BREAKPOINT_AMOUNT_1",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 3;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_UNITTYPE": {
        this.reportController.set("TenancyScheduleUnitType");
        this.ReportName = this.appService.getTranslation("lblTSUnitTypeReport");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "LEASE_CODE",
          "UNIT_TYPE_LABEL",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TENANT_CODE",
          "TENANT_NAME",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "INSURANCE_POLICY_NUMBER",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_LEASE_TERMINATION",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_VALUE",
          "INITIAL_INDEX_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "LEASE_UNITS",
          "LEASE_FLOORS",
          "MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "NUMBER_OF_ROOMS",
          "MAIN_UNIT_BUILDING",
          "MAIN_UNIT_STAIRCASE",
          "MAIN_UNIT_FLOOR",
          "DOOR_NUMBER",
          "RENTED_AREA",
          "VACANT_AREA",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "PRIVATE_UNIT_AREA",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "NUMBER_OF_SIMPLE_CAR_PARKS",
          "NUMBER_OF_DOUBLE_CAR_PARKS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUR_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "ENTRANCE_FEES",
          "BREAKPOINT_AMOUNT_1",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 3;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_UNIT": {
        this.reportController.set("TenancyScheduleUnit");
        this.ReportName = this.appService.getTranslation("lblTSUnitReport");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "UNIT_NUMBER",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "MAIN_UNIT",
          "IS_MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "UNIT_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "BUILDING",
          "STAIRCASE",
          "FLOOR",
          "DOOR_NUMBER",
          "TENANT_CODE",
          "TENANT_NAME",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "INSURANCE_POLICY_NUMBER",
          "LEASE_CODE",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_LEASE_TERMINATION",
          "VACANCY_START_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "INITIAL_INDEX_VALUE",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "UNIT_AREA_SQM",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "PRIVATE_UNIT_AREA",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "CURRENT_MARKET_VALUE",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUR_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "MGMT_FEES_OF_GARB_COLLECT_TAX",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "ENTRANCE_FEES",
          "BREAKPOINT_AMOUNT_1",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_UNIT_DETAILED": {
        this.reportController.set("TenancyScheduleUnitDetailed");
        this.ReportName = this.appService.getTranslation("lblTSUnitDetailedReport");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "UNIT_NUMBER",
          "EXTERNAL_FUND_NAME",
          "OWNER_CODE",
          "OWNER_NAME",
          "BUILDING_NAME",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "MAIN_MANAGER_NAME",
          "MAIN_UNIT",
          "IS_MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "UNIT_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "BUILDING",
          "STAIRCASE",
          "FLOOR",
          "DOOR_NUMBER",
          "TENANT_CODE",
          "TENANT_NAME",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "INSURANCE_POLICY_NUMBER",
          "LEASE_CODE",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_LEASE_TERMINATION",
          "VACANCY_START_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "INITIAL_INDEX_VALUE",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "UNIT_AREA_SQM",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "PRIVATE_UNIT_AREA",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "CURRENT_MARKET_VALUE",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUR_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "MGMT_FEES_OF_GARB_COLLECT_TAX",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "ENTRANCE_FEES",
          "BREAKPOINT_AMOUNT_1",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "OUT_LEA_REPORT": {
        this.reportController.set("OutstandingPayments");
        this.ReportName = this.appService.getTranslation("lblOUTReport");
        this.reportType.set("BY_LEASE");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TRADE_NAME",
          "LEASE_CODE",
          "LEASE_TYPE_LABEL",
          "INVOICING_FREQUENCY_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "UNPAID_INVOICED_INCL_TAX_AMOUNT",
          "INVOICES_NOT_DUE_INC_TAX_AMOUNT",
          "PARTIAL_PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "BALANCE_INCL_TAX_AMOUNT",
          "PAYMENT_PERIOD_LESS_0",
          "PAYMENT_PERIOD_1",
          "PAYMENT_PERIOD_2",
          "PAYMENT_PERIOD_3",
          "PAYMENT_PERIOD_4",
          "PAYMENT_PERIOD_5",
          "PAYMENT_PERIOD_6",
          "OVERPAYMENTS_INC_TAX_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_SITUATION_LABEL",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "OUT_LEA_RCI_REP": {
        this.reportController.set("OutstandingPayments");
        this.ReportName = this.appService.getTranslation("lblOUTLEARCIReport");
        this.reportType.set("BY_RCI");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TRADE_NAME",
          "LEASE_CODE",
          "LEASE_TYPE_LABEL",
          "INVOICING_FREQUENCY_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "REGULAR_CHARGES_ITEM_CODE",
          "REGULAR_CHARGES_ITEM_LABEL",
          "UNPAID_INVOICED_INCL_TAX_AMOUNT",
          "INVOICES_NOT_DUE_INC_TAX_AMOUNT",
          "PARTIAL_PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "BALANCE_INCL_TAX_AMOUNT",
          "PAYMENT_PERIOD_LESS_0",
          "PAYMENT_PERIOD_1",
          "PAYMENT_PERIOD_2",
          "PAYMENT_PERIOD_3",
          "PAYMENT_PERIOD_4",
          "PAYMENT_PERIOD_5",
          "PAYMENT_PERIOD_6",
          "OVERPAYMENTS_INC_TAX_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "OUT_INV_REPORT": {
        this.reportController.set("OutstandPayInvoice");
        this.ReportName = this.appService.getTranslation("lblOUTINVReport");
        this.reportType.set("BY_INVOICE");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TRADE_NAME",
          "LEASE_CODE",
          "LEASE_TYPE_LABEL",
          "INVOICING_FREQUENCY_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "DOCUMENT_NUMBER",
          "ACCOUNTING_DATE_INVOICE",
          "DUE_DATE",
          "ACCOUNTING_DATE_PAYMENT",
          "UNPAID_INVOICED_INCL_TAX_AMOUNT",
          "INVOICES_NOT_DUE_INC_TAX_AMOUNT",
          "PARTIAL_PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "BALANCE_INCL_TAX_AMOUNT",
          "PAYMENT_PERIOD_LESS_0",
          "PAYMENT_PERIOD_1",
          "PAYMENT_PERIOD_2",
          "PAYMENT_PERIOD_3",
          "PAYMENT_PERIOD_4",
          "PAYMENT_PERIOD_5",
          "PAYMENT_PERIOD_6",
          "OVERPAYMENTS_INC_TAX_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "OUT_INV_RCI_REP": {
        this.reportController.set("OutstandPayInvoiveBillItem");
        this.ReportName = this.appService.getTranslation("lblOUTINVRCIReport");
        this.reportType.set("BY_INVOICE_RCI");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TRADE_NAME",
          "LEASE_CODE",
          "LEASE_TYPE_LABEL",
          "INVOICING_FREQUENCY_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "DOCUMENT_NUMBER",
          "ACCOUNTING_DATE_INVOICE",
          "DUE_DATE",
          "ACCOUNTING_DATE_PAYMENT",
          "REGULAR_CHARGES_ITEM_CODE",
          "REGULAR_CHARGES_ITEM_LABEL",
          "UNPAID_INVOICED_INCL_TAX_AMOUNT",
          "INVOICES_NOT_DUE_INC_TAX_AMOUNT",
          "PARTIAL_PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "BALANCE_INCL_TAX_AMOUNT",
          "PAYMENT_PERIOD_LESS_0",
          "PAYMENT_PERIOD_1",
          "PAYMENT_PERIOD_2",
          "PAYMENT_PERIOD_3",
          "PAYMENT_PERIOD_4",
          "PAYMENT_PERIOD_5",
          "PAYMENT_PERIOD_6",
          "OVERPAYMENTS_INC_TAX_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "BUM_REPORT": {
        this.reportController.set("BudgetMonitoring");
        this.ReportName = this.appService.getTranslation("lblBUMReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "BUILDING_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "LEVEL4_BUDGET_SECTION",
          "RECOVERABLE",
          "LEVEL3_BUDGET_SECTION",
          "LEVEL2_BUDGET_SECTION",
          "LEVEL1_BUDGET_SECTION",
          "LEVEL1_BUDGET_SECTION_CODE",
          "BUDGET_INITIAL_N_M1",
          "LANDED_N_M1",
          "REALISED_N_M1",
          "REALISED_N_TODATE_M1",
          "REALISED_ACCOUNTING_N_M1",
          "DIFF_N_M1",
          "COMMENT_N_M1",
          "BUDGET_INITIAL_N",
          "LANDED_N",
          "BUDGET_TODATE_N",
          "REALISED_TODATE_N",
          "VARIATION_N",
          "VARIATION_RATE_N",
          "VARIATION_TODATE_N",
          "VARIATION_RATE_TODATE_N",
          "VARIATION_BUDGET",
          "VARIATION_BUDGET_RATE",
          "REALISED_N",
          "COMMENT_N",
          "BUDGET_INITIAL_N_P1",
          "COMMENT_N_P1",
          "BUDGET_T1_N",
          "REALISED_T1_N",
          "VARIATION_T1_N",
          "VARIATION_RATE_T1_N",
          "BUDGET_T2_N",
          "REALISED_T2_N",
          "VARIATION_T2_N",
          "VARIATION_RATE_T2_N",
          "BUDGET_T3_N",
          "REALISED_T3_N",
          "VARIATION_T3_N",
          "VARIATION_RATE_T3_N",
          "BUDGET_T4_N",
          "REALISED_T4_N",
          "VARIATION_T4_N",
          "VARIATION_RATE_T4_N",
          "BUDGET_01_N",
          "REALISED_01_N",
          "VARIATION_01_N",
          "VARIATION_RATE_01_N",
          "BUDGET_02_N",
          "REALISED_02_N",
          "VARIATION_02_N",
          "VARIATION_RATE_02_N",
          "BUDGET_03_N",
          "REALISED_03_N",
          "VARIATION_03_N",
          "VARIATION_RATE_03_N",
          "BUDGET_04_N",
          "REALISED_04_N",
          "VARIATION_04_N",
          "VARIATION_RATE_04_N",
          "BUDGET_05_N",
          "REALISED_05_N",
          "VARIATION_05_N",
          "VARIATION_RATE_05_N",
          "BUDGET_06_N",
          "REALISED_06_N",
          "VARIATION_06_N",
          "VARIATION_RATE_06_N",
          "BUDGET_07_N",
          "REALISED_07_N",
          "VARIATION_07_N",
          "VARIATION_RATE_07_N",
          "BUDGET_08_N",
          "REALISED_08_N",
          "VARIATION_08_N",
          "VARIATION_RATE_08_N",
          "BUDGET_09_N",
          "REALISED_09_N",
          "VARIATION_09_N",
          "VARIATION_RATE_09_N",
          "BUDGET_10_N",
          "REALISED_10_N",
          "VARIATION_10_N",
          "VARIATION_RATE_10_N",
          "BUDGET_11_N",
          "REALISED_11_N",
          "VARIATION_11_N",
          "VARIATION_RATE_11_N",
          "BUDGET_12_N",
          "REALISED_12_N",
          "VARIATION_12_N",
          "VARIATION_RATE_12_N",
          "COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "GEC_REPORT": {
        this.reportController.set("GeneralExpenses");
        this.ReportName = this.appService.getTranslation("lblGECReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "PROVIDER_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_CODE",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "GEOGRAPHICAL_ZONE",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "BUILDING_TYPE_LABEL",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_SURFACE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "PROVIDER_CODE",
          "PROVIDER_TYPE_CODE",
          "PROVIDER_TYPE_NAME",
          "FEE_TYPE_CODE",
          "FEE_TYPE_NAME",
          "CONTRACT_COMMAND_CODE",
          "CONTRACT_COMMAND_NAME",
          "CONTRACT_TYPE_CODE",
          "PERIOD",
          "INVOICING_DATE",
          "ACCOUNTING_DATE",
          "INVOICE_NUMBER",
          "INVOICE",
          "CURRENCY",
          "BEFORE_TAX_AMOUNT_TOTAL",
          "VAT_AMOUNT_TOTAL",
          "INCLUDING_TAX_AMOUNT_TOTAL",
          "PAYMENT_AMOUNT",
          "OUTSTAND_PAYMENT_INC_TAX_AMOUNT",
          "PAYMENT_MODE",
          "PAYMENT_DATE",
          "BANK_JOURNAL",
          "BUDGET_CODE",
          "BUDGET_TYPE",
          "BUDGET_YEAR",
          "BUDGET_BEGIN_DATE",
          "BUDGET_END_DATE",
          "BUDGET_NAME",
          "WORK_CAMPAIGN_CODE",
          "WORK_CAMPAIGN_LABEL",
          "JOURNAL_CODE",
          "ENTRY",
          "EXTRANET_REF",
          "CHRONO_GED",
          "RECOVERABLE",
          "BUDGET_LINE_CODE",
          "BUDGET_LINE",
          "LEVEL2_BUDGET_SECTION_CODE",
          "LEVEL2_BUDGET_SECTION",
          "LEVEL3_BUDGET_SECTION_CODE",
          "LEVEL3_BUDGET_SECTION",
          "OPERATION_NATURE_CODE",
          "OPERATION_NATURE_LABEL",
          "REPARTITION_CODE",
          "REPARTITION_LABEL",
          "ACCOUNT_NUMBER",
          "ACCOUNT_NUMBER_LABEL",
          "BEFORE_TAX_AMOUNT",
          "VAT_AMOUNT",
          "INCLUDING_TAX_AMOUNT",
          "COMMENT"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = true;
        this.showSpecificCriteria = true;

        break;
      }
      case "GEP_REPORT": {
        this.reportController.set("GeneralExpenses");
        this.ReportName = this.appService.getTranslation("lblGEPReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "PROVIDER_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_CODE",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "GEOGRAPHICAL_ZONE",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "BUILDING_TYPE_LABEL",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_SURFACE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "PROVIDER_CODE",
          "PROVIDER_TYPE_CODE",
          "PROVIDER_TYPE_NAME",
          "FEE_TYPE_CODE",
          "FEE_TYPE_NAME",
          "CONTRACT_COMMAND_CODE",
          "CONTRACT_COMMAND_NAME",
          "CONTRACT_TYPE_CODE",
          "PERIOD",
          "INVOICING_DATE",
          "ACCOUNTING_DATE",
          "INVOICE_NUMBER",
          "INVOICE",
          "CURRENCY",
          "BEFORE_TAX_AMOUNT_TOTAL",
          "VAT_AMOUNT_TOTAL",
          "INCLUDING_TAX_AMOUNT_TOTAL",
          "PAYMENT_AMOUNT",
          "OUTSTAND_PAYMENT_INC_TAX_AMOUNT",
          "PAYMENT_MODE",
          "PAYMENT_DATE",
          "BANK_JOURNAL",
          "BUDGET_CODE",
          "BUDGET_TYPE",
          "BUDGET_YEAR",
          "BUDGET_BEGIN_DATE",
          "BUDGET_END_DATE",
          "BUDGET_NAME",
          "WORK_CAMPAIGN_CODE",
          "WORK_CAMPAIGN_LABEL",
          "JOURNAL_CODE",
          "ENTRY",
          "EXTRANET_REF",
          "CHRONO_GED",
          "RECOVERABLE",
          "BUDGET_LINE_CODE",
          "BUDGET_LINE",
          "LEVEL2_BUDGET_SECTION_CODE",
          "LEVEL2_BUDGET_SECTION",
          "LEVEL3_BUDGET_SECTION_CODE",
          "LEVEL3_BUDGET_SECTION",
          "OPERATION_NATURE_CODE",
          "OPERATION_NATURE_LABEL",
          "REPARTITION_CODE",
          "REPARTITION_LABEL",
          "ACCOUNT_NUMBER",
          "ACCOUNT_NUMBER_LABEL",
          "BEFORE_TAX_AMOUNT",
          "VAT_AMOUNT",
          "INCLUDING_TAX_AMOUNT",
          "COMMENT"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = true;
        this.showSpecificCriteria = true;

        break;
      }
      case "SDG_REPORT": {
        this.reportController.set("SecurityDepositsGuarantees");
        this.ReportName = this.appService.getTranslation("lblSDGReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "LEASE_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TENANT_ARRIVAL_DATE",
          "TENANT_EXIT_DATE",
          "IS_IN_LITIGATION",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "SECURIT_DEPOSIT_IN_MONTH",
          "INITIAL_DEPOSIT_VALUE",
          "ANNUAL_RENT_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "SECURITY_DEPOSIT_CALLS",
          "SECURITY_DEPOSIT_REFUNDS",
          "SECURITY_DEPOSIT_RECEIVABLE",
          "LAST_INDEXATION_DATE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "EQUIPMENT_GUARANTEE_CALLS",
          "EQUIPMENT_GUARANTEE_REFUNDS",
          "EQUIPMENT_GUARANTEE_RECEIVABLE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "WORKING_CAPITAL_CALLS",
          "WORKING_CAPITAL_REFUNDS",
          "WORKING_CAPITAL_RECEIVABLE",
          "COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "OPM_REPORT": {
        this.reportController.set("OutstandPayMonitoring");
        this.ReportName = this.appService.getTranslation("lblOPMReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "LEASE_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TENANT_CODE",
          "TRADE_NAME",
          "LEASE_TYPE_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "LEASE_PAYMENT_MODE_LABEL",
          "IS_DIRECT_DEBIT",
          "INVOICING_FREQUENCY_LABEL",
          "ACCOUNTING_BALANCE_START_AMOUNT",
          "PAYMENT_DURING_PERIOD_INC_TAX_AMNT",
          "BEFORE_PAYMENTS_INC_TAX_AMNT",
          "INVOICE_DURING_PERIOD_INC_TAX_AMNT",
          "PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "OUTSTAND_RATE",
          "OUTSTAND_PAY_TERM_EQUIV",
          "ADVANCE_PAYMENTS_INC_TAX_AMNT",
          "ACCOUNTING_BALANCE_END_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_SITUATION_LABEL",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = true;
        this.showSpecificCriteria = true;

        break;
      }
      case "VAU_REPORT": {
        this.reportController.set("VacantUnits");
        this.ReportName = this.appService.getTranslation("lblVAUReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "UNIT_NUMBER",
          "UNIT_TYPE_LABEL",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "BUILDING",
          "STAIRCASE",
          "FLOOR",
          "VACANCY_TYPE",
          "VACANCY_START_DATE",
          "VACANCY_DURATION",
          "UNIT_AREA_SQM",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "CURRENT_MARKET_VALUE",
          "FINANCIAL_VACANCY",
          "PRO_RATED_FINANCIAL_VACANCY",
          "LAST_TENANT_NAME",
          "LAST_TENANT_TRADE_NAME",
          "LAST_LEASE_RENT_AMOUNT",
          "LAST_LEASE_RENT_AMOUNT_M2"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "VAC_PHY_REPORT": {
        this.ReportName = this.appService.getTranslation("lblVACPhyReport");
        this.route.paramMap.subscribe((params: ParamMap) => {
          switch (this.subReportCode) {
            case "SQM": {
              this.reportController.set("VacancyPhysical");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "PORTFOLIO_LABEL",
                "EXTERNAL_FUND_NAME",
                "OWNER_NAME",
                "ASSET_MANAGER",
                "SITE_NAME",
                "FEE_NAME",
                "AGENCY",
                "PROPERTY_MANAGER",
                "BU_MANAGER",
                "START_MANAGEMENT_DATE",
                "END_MANAGEMENT_DATE",
                "SERVICE_LABEL",
                "BUILDING_TOTAL_AREA_SQM",
                "BUILDING_NATURE",
                "GEOGRAPHICAL_ZONE",
                "BUILDING_ADDRESS",
                "POSTAL_CODE",
                "CITY",
                "COUNTRY",
                "BUILDING_TYPE_LABEL",
                "BUILDING_TOTAL_AREA",
                "VACANT_AREA_MINUS_11_MONTHS",
                "VACANT_AREA_MINUS_10_MONTHS",
                "VACANT_AREA_MINUS_09_MONTHS",
                "VACANT_AREA_MINUS_08_MONTHS",
                "VACANT_AREA_MINUS_07_MONTHS",
                "VACANT_AREA_MINUS_06_MONTHS",
                "VACANT_AREA_MINUS_05_MONTHS",
                "VACANT_AREA_MINUS_04_MONTHS",
                "VACANT_AREA_MINUS_03_MONTHS",
                "VACANT_AREA_MINUS_02_MONTHS",
                "VACANT_AREA_MINUS_01_MONTHS",
                "VACANT_AREA_CURRENT_MONTH",
                "AVERAGE_VACANCY_RATE"
              ]);
              this.stickyLeftColumnsCount = 2;
              break;
            }
            case "PERCENT": {
              this.reportController.set("VacancyPhysicalPercent");
              this.ReportName = this.appService.getTranslation("lblVACPhyReport");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TYPE_LABEL",
                "BUILDING_TOTAL_AREA",
                "VACANCY_RATE_MINUS_11_MONTHS",
                "VACANCY_RATE_MINUS_10_MONTHS",
                "VACANCY_RATE_MINUS_09_MONTHS",
                "VACANCY_RATE_MINUS_08_MONTHS",
                "VACANCY_RATE_MINUS_07_MONTHS",
                "VACANCY_RATE_MINUS_06_MONTHS",
                "VACANCY_RATE_MINUS_05_MONTHS",
                "VACANCY_RATE_MINUS_04_MONTHS",
                "VACANCY_RATE_MINUS_03_MONTHS",
                "VACANCY_RATE_MINUS_02_MONTHS",
                "VACANCY_RATE_MINUS_01_MONTHS",
                "VACANCY_RATE_CURRENT_MONTH",
                "AVERAGE_VACANCY_RATE"
              ]);
              this.stickyLeftColumnsCount = 2;
              break;
            }
            case "FINANCIAL": {
              this.reportController.set("VacancyPhysicalFin");
              this.ReportName = this.appService.getTranslation("lblVACPhyReport");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TYPE_LABEL",
                "BUILDING_TOTAL_AREA",
                "FIN_VAC_MINUS_11_MONTH",
                "FIN_VAC_MINUS_10_MONTH",
                "FIN_VAC_MINUS_09_MONTH",
                "FIN_VAC_MINUS_08_MONTH",
                "FIN_VAC_MINUS_07_MONTH",
                "FIN_VAC_MINUS_06_MONTH",
                "FIN_VAC_MINUS_05_MONTH",
                "FIN_VAC_MINUS_04_MONTH",
                "FIN_VAC_MINUS_03_MONTH",
                "FIN_VAC_MINUS_02_MONTH",
                "FIN_VAC_MINUS_01_MONTH",
                "FIN_VAC_CURRENT_MONTH",
                "ANNUAL_FINANCIAL_VACANCY",
              ]);
              this.stickyLeftColumnsCount = 2;
              break;
            }

          }
          this.reportDatesService.showDateRange = false;
          this.showSpecificCriteria = true;
        });
        break;
      }
      case "VAC_ANA_REPORT": {
        this.reportController.set("VacancyAnalysis");
        this.ReportName = this.appService.getTranslation("lblVACAnaReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "BUILDING_TOTAL_AREA",
          "RENTED_AREA",
          "PHYSICAL_OCCUPATION_RATE",
          "VACANT_AREA",
          "PHYSICAL_VACANCY_RATE",
          "VACANT_AREA_INF_2MONTHS",
          "VACANT_AREA_2_4_MONTHS",
          "VACANT_AREA_SUP_4MONTHS",
          "FINANCIAL_VACANCY",
          "FINANCIAL_VACANCY_PER_SQM"
        ]);
        this.stickyLeftColumnsCount = 0;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "EVP_REPORT": {
        this.reportController.set("PortfolioEvolution");
        this.ReportName = this.appService.getTranslation("lblEVPReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_TYPE_LABEL',
            name: 'BUILDING_TYPE_LABEL',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'BUILDING_NATURE_LABEL',
            name: 'BUILDING_NATURE_LABEL',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'EVOLUTION_YEAR',
            name: 'EVOLUTION_YEAR',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'BUILDINGS_COUNT',
            name: 'BUILDINGS_COUNT',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'UNITS_COUNT',
            name: 'UNITS_COUNT',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'ASSETS_AREA',
            name: 'ASSETS_AREA',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'ASSETS_VALUE',
            name: 'ASSETS_VALUE',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'GROSS_HEADLINE_RENT',
            name: 'GROSS_HEADLINE_RENT',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'NET_EFFECTIVE_RENT',
            name: 'NET_EFFECTIVE_RENT',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
        ]);
        this.columnsToDisplay.set([
          "BUILDING_TYPE_LABEL",
          "BUILDING_NATURE_LABEL",
          "EVOLUTION_YEAR",
          "BUILDINGS_COUNT",
          "UNITS_COUNT",
          "ASSETS_AREA",
          "ASSETS_VALUE",
          "GROSS_HEADLINE_RENT",
          "NET_EFFECTIVE_RENT"
        ]);
        this.stickyLeftColumnsCount = 3;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "YIM_REPORT": {
        this.route.paramMap.subscribe((params: ParamMap) => {
          switch (this.subReportCode) {
            case "YMG": {
              this.ReportName = this.appService.getTranslation("lblGrossYieldsMonitoring");
              this.reportController.set("YieldMonitoringGross");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TOTAL_AREA_SQM",
                "NET_MARKET_VALUE",
                "GROSS_MARKET_VALUE",
                "LAST_VALUATION_DATE",
                "GROSS_HEADLINE_RENT",
                "GROSS_EFFECTIVE_RENT",
                "GROSS_POTENTIAL_RENT",
                "FAIR_MARKET_RENT",
                "HEADLINE_GROSS_INITIAL_YIELD",
                "EFFECTIVE_GROSS_INITIAL_YIELD",
                "POTENTIAL_GROSS_INITIAL_YIELD",
                "GROSS_REVERSIONARY_YIELD",
                "GROSS_REVERS_POTENT_OCCUP_AREA",
                "GROSS_REVERS_POTENT_TOTAL_PROP"
              ]);
              this.stickyLeftColumnsCount = 2;
              break;
            }
            case "YMN": {
              this.ReportName = this.appService.getTranslation("lblNetYieldsMonitoring");
              this.reportController.set("YieldMonitoringNet");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TOTAL_AREA_SQM",
                "NET_MARKET_VALUE",
                "GROSS_MARKET_VALUE",
                "LAST_VALUATION_DATE",
                "NET_HEADLINE_RENT",
                "NET_EFFECTIVE_RENT",
                "NET_POTENTIAL_RENT",
                "NET_MARKET_RENT",
                "HEADLINE_NET_INITIAL_YIELD",
                "EFFECTIVE_NET_INITIAL_YIELD",
                "POTENTIAL_NET_INITIAL_YIELD",
                "NET_REVERSIONARY_YIELD",
                "GROSS_REVERS_POTENT_OCCUP_AREA",
                "GROSS_REVERS_POTENT_TOTAL_PROP"

              ]);
              this.stickyLeftColumnsCount = 2;
              break;
            }
            case "YMCG": {
              this.ReportName = this.appService.getTranslation("lblGrossCapitalizationMonitoring");
              this.reportController.set("YieldMonitoringCapiGross");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TOTAL_AREA_SQM",
                "NET_MARKET_VALUE",
                "GROSS_MARKET_VALUE",
                "LAST_VALUATION_DATE",
                "GROSS_HEADLINE_RENT",
                "GROSS_EFFECTIVE_RENT",
                "GROSS_POTENTIAL_RENT",
                "FAIR_MARKET_RENT",
                "HEADLINE_GROSS_CAPITAL_RATE",
                "EFFECTIVE_GROSS_CAPITAL_RATE",
                "POTENTIAL_GROSS_CAPITAL_RATE",
                "GROSS_REVERS_CAPITAL_RATE",
                "GROSS_REVERS_POTENT_OCCUP_AREA",
                "GROSS_REVERS_POTENT_TOTAL_PROP"
              ]);
              this.stickyLeftColumnsCount = 2;
              break;
            }

            case "YMCN": {
              this.ReportName = this.appService.getTranslation("lblNetCapitalizationMonitoring");
              this.reportController.set("YieldMonitoringCapiNet");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TOTAL_AREA_SQM",
                "NET_MARKET_VALUE",
                "GROSS_MARKET_VALUE",
                "LAST_VALUATION_DATE",
                "GROSS_HEADLINE_RENT",
                "GROSS_EFFECTIVE_RENT",
                "GROSS_POTENTIAL_RENT",
                "FAIR_MARKET_RENT",
                "HEADLINE_GROSS_CAPITAL_RATE",
                "EFFECTIVE_GROSS_CAPITAL_RATE",
                "POTENTIAL_GROSS_CAPITAL_RATE",
                "GROSS_REVERS_CAPITAL_RATE",
                "GROSS_REVERS_POTENT_OCCUP_AREA",
                "GROSS_REVERS_POTENT_TOTAL_PROP"
              ]);
              this.stickyLeftColumnsCount = 2;
              break;
            }
          }
        });

        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;
        break;
      }
      case "PVA_REPORT": {
        this.reportController.set("PropertyValuation");
        this.ReportName = this.appService.getTranslation("lblPVAReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "OWNER_CODE",
          "OWNER_NAME",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_NATURE_LABEL",
          "MARKET_VALUE_EXCL_DUTIES_VAT",
          "LAST_VALUATION_DATE",
          "GROSS_BOOK_VALUE",
          "TOTAL_ACCUMULATED_DEPRECIATION",
          "NET_BOOK_VALUE_BEFORE_RESERVE",
          "LOSS_BEFORE_RESERVE",
          "IMPAIRMENT_RATIO",
          "DEPRECIATION_PROVISION_AMOUNT",
          "IMPAIRMENT_CHARGE",
          "NET_BOOK_VALUE_AFTER_IMPAIR",
          "VALUATION_MARKET_RENT",
          "PC_EXPECTED_YIELD",
          "VALUE_IN_USE",
          "RECOVERABLE_AMOUNT",
          "IMPAIR_LOSS_CALC",
          "ALLOWANCE_IMPAIR_LOSS_CALC",
          "IMPAIRMENT_LOSS",
          "ALLOWANCE_IMPAIR_LOSS_USER",
          "ADJUSTED_NET_BOOK_VALUE",
          "UNREALIZED_GAINS_AND_LOSSES"
        ]);
        this.stickyLeftColumnsCount = 4;
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
    }
  }

}
