import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatOption,
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModelReportService } from '../shared/service/model-report.service';
import {
  CreateUpdateReportTemplateRequestModel,
  DynamicData,
} from '../shared/model/types';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, CommonModule } from '@angular/common';
import { forkJoin, map, ReplaySubject, retry, Subject, takeUntil, tap } from 'rxjs';
import { AuthService } from '../shared/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../dialog/success-dialog/success-dialog.component';
import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import { SidenavService } from '../shared/service/sidenav.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../shared/service/app.service';
import { MatCardModule } from '@angular/material/card';
import { ReportService } from '../shared/service/report.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-add-model-report',
    imports: [
        MatCardModule,
        TranslateModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatTabsModule,
        DragDropModule,
        FormsModule,
        MatIconModule,
        AsyncPipe,
        CommonModule,
        NgxMatSelectSearchModule,
        MatCheckboxModule,
        MatCardModule,
        NgxMatSelectSearchModule
    ],
    templateUrl: './add-model-report.component.html',
    styleUrls: ['./add-model-report.component.scss'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AddModelReportComponent implements OnInit, AfterViewInit {
  @ViewChild('select') select!: MatSelect;

  standardReports: DynamicData[] = [];
  reportColumns: DynamicData[] = [];
  isReportCodeUndefined = true;
  allSelected = false;

  isEditMode: boolean = false;
  idReportTemplate!: string | null;
  reportCode!: string | null;

  ngForm: FormGroup;

  private modelReportService = inject(ModelReportService);
  private fb = inject(FormBuilder);
  private cdRef = inject(ChangeDetectorRef);
  public translate = inject(TranslateService);
  private authService = inject(AuthService);
  private dialog = inject(MatDialog);
  private sidenavService = inject(SidenavService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private reportService = inject(ReportService);
  private location = inject(Location);
  appService = inject(AppService);

  defaultLocale = localStorage.getItem('userLocale') || navigator.language;

  // For columns
  public filteredItem: ReplaySubject<DynamicData[]> = new ReplaySubject<DynamicData[]>(1);
  // For Reports
  public filteredReports: ReplaySubject<DynamicData[]> = new ReplaySubject<DynamicData[]>(1);

  private _onDestroy = new Subject<void>();

  constructor() {
    this.ngForm = this.fb.group({
      selectedReport: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      selectedCols: new FormControl<string[]>([], Validators.required),
      searchColsControl: new FormControl(''),
      searchReportControl: new FormControl('')
    });
  }

  ngAfterViewInit(): void {
    this.toggleSelect();
    // Refresh the view to reflect changes
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.reportCode = params.get('report_code');
      this.idReportTemplate = params.get('id_report_template');
    });
    this.isEditMode = this.idReportTemplate !== null && this.reportCode !== null;

    if (this.isEditMode) {
      this.loadTemplateData();
    }

    //Hide the sidenav for this page
    this.sidenavService.showSidenav = false;

    this.modelReportService.getReportData()
      .pipe(
        // Show only reports available in Properlake
        map(response => {
          const filteredData = response.data.filter(data =>
            (String(data['report_code']).substring(0, 3) === 'TS_' ||
              String(data['report_code']).substring(0, 3) === 'OUT' ||
              String(data['report_code']).substring(0, 3) === 'BUM' ||
              String(data['report_code']).substring(0, 3) === 'GEC' ||
              String(data['report_code']).substring(0, 3) === 'GEP' ||
              String(data['report_code']).substring(0, 3) === 'SDG' ||
              String(data['report_code']).substring(0, 3) === 'OPM' ||
              String(data['report_code']).substring(0, 3) === 'VAC' ||
              String(data['report_code']).substring(0, 3) === 'EVP' ||
              //String(data['report_code']).substring(0, 3) === 'PVA' ||
              String(data['report_code']).substring(0, 3) === 'VAU' ||
              String(data['report_code']).substring(0, 3) === 'YIM')
            && String(data['report_code']).substring(0, 7) !== 'OUT_COT'
            && String(data['report_code']).substring(0, 7) !== 'VAC_NAT'
          );
          response.data = filteredData;
          return response;
        })
      ).subscribe((result) => {
        this.standardReports = result.data;
        //Initialise the list for the template
        this.filteredReports.next(this.standardReports.slice());
      });

    //Filter reports when the form control entry is modified
    this.searchReportControl.valueChanges.pipe(
      takeUntil(this._onDestroy),
    ).subscribe(() => {
      this.filterReports();
    });

    // Filter the columns in a selected report
    this.searchColsControl.valueChanges.pipe(
      takeUntil(this._onDestroy),
    ).subscribe(() => {
      this.filterItems();
    });
  }

  loadTemplateData(): void {
    if (this.idReportTemplate && this.reportCode) {
      this.modelReportService.getReportTemplateData(this.reportCode, Number(this.idReportTemplate), "0").subscribe({
        next: (response) => {
          // Fill in the form with data from the existing model
          this.ngForm.patchValue({
            selectedReport: this.reportCode,
            name: response.data[0]["TEMPLATE_NAME"],
            selectedCols: response.data
              .filter((col: DynamicData) => col["ID_TEMPLATE_COLUMN"] > 0)
              .map((col: DynamicData) => col["COLUMN_NAME"]),
          });

          // Activate MatSelect
          this.isReportCodeUndefined = false;
          this.toggleSelect();

          // Update translations
          this.reportColumns = response.data;
          this.reportColumns.forEach((col) => this.addTranslation(col));

          // Update the list of selected columns
          this.filteredItem.next(this.reportColumns.slice());

          // Add listeners for column search
          this.searchColsControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterItems();
            });

          // Update display
          this.cdRef.detectChanges();
        },
        error: (error) => {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              // TODO: pass a translation key instead
              message: '(fr) Une erreur est survenue lors du chargement du modèle de rapport.',
            },
          });
        },
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get selectedReport(): FormControl<string> {
    return this.ngForm.get('selectedReport') as FormControl<string>;
  }

  get selectedCols(): FormControl<string[]> {
    return this.ngForm.get('selectedCols') as FormControl<string[]>;
  }

  get searchColsControl(): FormControl<string> {
    return this.ngForm.get('searchColsControl') as FormControl<string>;
  }

  get searchReportControl(): FormControl<string> {
    return this.ngForm.get('searchReportControl') as FormControl<string>;
  }

  get controlName(): FormControl<string> {
    return this.ngForm.get('name') as FormControl<string>;
  }

  toggleAllSelection(): void {
    // Single loop to select or deselect all options based on the checkbox state
    this.select.options.forEach((item: MatOption) =>
      this.allSelected ? item.select() : item.deselect()
    );
  }

  deselectCheckbox(): void {
    // Programmatically deselect the checkbox
    this.allSelected = false;
    this.select.options.forEach((item: MatOption) => item.deselect());
  }

  optionClick(): void {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  onSelectionChange(event: MatSelectChange): void {
    const selectedValues = event.value as (string | undefined)[];

    const filteredValues = selectedValues.filter(value => value !== undefined);
    this.ngForm.get('selectedCols')?.setValue(filteredValues);
  }

  onReportChange(event: MatSelectChange): void {
    this.resetCols();
    this.reportColumns.splice(0);
    const selectedReportCode = event.value;

    this.isReportCodeUndefined = selectedReportCode === undefined;
    this.toggleSelect();

    this.modelReportService
      .getReportTemplateData(selectedReportCode, 0, '0')
      .subscribe((result) => {
        this.reportColumns = result.data;
        this.reportColumns.forEach((col) => this.addTranslation(col));
        this.resetSelectedCols();

        this.filteredItem.next(this.reportColumns.slice());
        this.searchColsControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItems();
          });
      });
    this.cdRef.detectChanges();
  }

  filterItems(): void {
    if (!this.reportColumns) return;

    // Normalize the search value
    const search = this.appService.normalizeString(this.searchColsControl.value || '');

    // Detect the current language
    const currentLang = this.translate.currentLang || 'fr-FR';

    // Select translations based on the current language
    const translations = this.appService.translations[currentLang] || {};

    // Filter columns by normalizing values
    const filteredColumns = this.reportColumns.filter(col => {
      const columnName = col['COLUMN_NAME'];
      const translation = translations[columnName]
        ? this.appService.normalizeString(translations[columnName])
        : '';
      return translation.includes(search);
    });

    // Update the filtered items
    this.filteredItem.next(filteredColumns);

    // Trigger change detection
    this.cdRef.detectChanges();
  }

  filterReports(): void {
    if (!this.standardReports) return;

    // Normalize the search value
    const search = this.appService.normalizeString(this.searchReportControl.value || '');

    // Filter the reports based on normalized names
    const filteredReports = this.standardReports.filter(report => {
      const reportName = this.appService.normalizeString(report["report_name"]);
      return reportName.includes(search);
    });

    // Update the filtered reports and trigger change detection
    this.filteredReports.next(filteredReports);
    this.cdRef.detectChanges();
  }

  toggleSelect(): void {
    if (this.isReportCodeUndefined) {
      this.select.disabled = true;
    } else {
      this.select.disabled = false;
    }
  }

  drop(event: CdkDragDrop<DynamicData[]>): void {
    // Get the list of selected columns
    const selectedColumns = this.getSelectedColumns();

    // Reorganise selected columns
    moveItemInArray(selectedColumns, event.previousIndex, event.currentIndex);

    // Update the order of the columns in reportColumns
    this.updateColumns(selectedColumns);

    this.cdRef.detectChanges();
  }

  updateColumns(selectedOrder: DynamicData[]): void {
    // Create a map of selected columns for quick access
    const selectedMap = new Map(selectedOrder.map(col => [col['COLUMN_NAME'], col]));
    // Reorganise reportColumns by first placing the selected column in their original order
    // followed by the unselected columns in the new order
    this.reportColumns = [
      ...selectedOrder,
      ...this.reportColumns.filter(col => !selectedMap.has(col['COLUMN_NAME']))
    ];
    this.filteredItem.next(this.reportColumns.slice());

    this.cdRef.detectChanges();
  }

  getSelectedColumns(): DynamicData[] {
    const filteredColumns = this.reportColumns.filter((col) =>
      this.selectedCols?.value.includes(col['COLUMN_NAME'])
    );
    return filteredColumns;
  }

  resetSelectedCols(): void {
    const selectedCols = this.selectedCols.value;
    const validSelectedCols = this.reportColumns
      .filter((col) => selectedCols.includes(col['COLUMN_NAME']))
      .map((col) => col['COLUMN_NAME']);
    this.selectedCols.setValue(validSelectedCols);

    this.cdRef.detectChanges();
  }

  deleteColumn(columnName: string): void {
    // Check if the column is in the selectedCols array and remove it
    const index = this.selectedCols.value.indexOf(columnName);
    if (index !== -1) {
      // Create a new array without the deleted column name
      const updatedSelectedCols = this.selectedCols.value.filter(
        (name) => name !== columnName
      );
      // Update the FormControl with the new array of column names
      this.selectedCols.setValue(updatedSelectedCols);
    }
    this.cdRef.detectChanges();
  }

  addTranslation(col: DynamicData) {
    const fr$ = this.appService.getTranslationData("fr-FR", col["REPORT_LABEL_CODE"], Number(this.idReportTemplate), col["TABLIX_NAME"]);
    const en$ = this.appService.getTranslationData("en-GB", col["REPORT_LABEL_CODE"], Number(this.idReportTemplate), col["TABLIX_NAME"]);
    const de$ = this.appService.getTranslationData("de-DE", col["REPORT_LABEL_CODE"], Number(this.idReportTemplate), col["TABLIX_NAME"]);

    // Added 2 retries because randomely the request fails
    forkJoin([fr$, en$, de$]).pipe(retry(2)).subscribe(results => {
      if (results[0] && results[0].data && results[0].data[0]) {
        col['FR_CUSTOM_LABEL'] = results[0].data[0]["label"];
      } else {
        col['FR_CUSTOM_LABEL'] = col["REPORT_LABEL_CODE"];
      }

      if (results[1] && results[1].data && results[1].data[0]) {
        col['EN_CUSTOM_LABEL'] = results[1].data[0]["label"];
      } else {
        col['EN_CUSTOM_LABEL'] = col["REPORT_LABEL_CODE"];
      }

      if (results[2] && results[2].data && results[2].data[0]) {
        col['DE_CUSTOM_LABEL'] = results[2].data[0]["label"];
      } else {
        col['DE_CUSTOM_LABEL'] = col["REPORT_LABEL_CODE"];
      }
    });
  }

  onSubmit(): void {
    if (this.ngForm.valid && this.getSelectedColumns().length > 1) {
      const formValues = this.ngForm.value;
      let selectedColumn: any = [];

      this.getSelectedColumns().forEach((col, index) => {
        selectedColumn.push({
          COLUMN_NAME: col['COLUMN_NAME'],
          PRINT_ORDER: `${index + 1}`,
          FR_CUSTOM_LABEL: col['FR_CUSTOM_LABEL'],
          EN_CUSTOM_LABEL: col['EN_CUSTOM_LABEL'],
          DE_CUSTOM_LABEL: col['DE_CUSTOM_LABEL'],
          TABLIX: col['TABLIX_NAME'],
          MAPPING: '',
        });
      });

      const request: CreateUpdateReportTemplateRequestModel = {
        ReportCode: formValues.selectedReport,
        User: this.authService.getLoggedUser()!.UserName,
        TemplateName: formValues.name.toLowerCase().trim(),
        SelectedColumns: JSON.stringify(selectedColumn),
        ShareReport: '1',
        AccessManagedByBuilding: '0',
        BuildingsList: '',
        ColorHea: this.getSelectedColumns()[0]['COLOR_HEA'],
        ColorPar: this.getSelectedColumns()[0]['COLOR_PAR'],
        ColorBor: this.getSelectedColumns()[0]['COLOR_BOR'],
        ColorHd1: this.getSelectedColumns()[0]['COLOR_HD1'],
        ColorHd2: this.getSelectedColumns()[0]['COLOR_HD2'],
        ColorHd3: this.getSelectedColumns()[0]['COLOR_HD3'],
        ColorDe1: this.getSelectedColumns()[0]['COLOR_DE1'],
        ColorSt1: this.getSelectedColumns()[0]['COLOR_ST1'],
        ColorSt2: this.getSelectedColumns()[0]['COLOR_ST2'],
        ColorSt3: this.getSelectedColumns()[0]['COLOR_ST3'],
        ColorFoo: this.getSelectedColumns()[0]['COLOR_FOO'],
        Font: this.getSelectedColumns()[0]['FONT'],
        Logo: this.getSelectedColumns()[0]['LOGO'],
        ExcelFixedColumnNumber:
          this.getSelectedColumns()[0]['EXCEL_FIXED_COLUMN_NUMBER'],
        ExcelFixedRowNumber: this.getSelectedColumns()[0]['EXCEL_FIXED_ROW_NUMBER'],
        ExcelFilterRowNumber: this.getSelectedColumns()[0]['EXCEL_FILTER_ROW_NUMBER'],
      };
      this.modelReportService.createUpdateReportTemplate(request).subscribe({
        next: (response) => {
          const dialogRef = this.dialog.open(SuccessDialogComponent, {
            data: {
              message:
                '(fr) Le modèle de rapport a été créé/mis à jour avec succès!',
            },
          });
          dialogRef.afterClosed().subscribe(() => {
            if (!this.idReportTemplate || this.idReportTemplate === "0") {
              // New template => Find the id of the last template that was just created
              this.modelReportService.getReportTemplates(null, this.authService.getLoggedUser()?.UserName!)
                .pipe(
                  tap(response => {
                    const template = response.data.reduce((prev, current) =>
                      (prev && prev['id_report_template'] > current['id_report_template']) ? prev : current
                    );
                    const reportCode = formValues.selectedReport;
                    const subReportCode = this.reportService.getSubReportCode(reportCode);
                    const url = `reports/report/${reportCode}/${subReportCode}/${template['id_report_template']}`;
                    this.router.navigateByUrl(url);

                  })
                ).subscribe();

            } else {
              // Already existing template
              const reportCode = formValues.selectedReport;
              const subReportCode = this.reportService.getSubReportCode(reportCode);
              const url = `reports/report/${reportCode}/${subReportCode}/${this.idReportTemplate}`;
              this.router.navigateByUrl(url);
            }
          });
        },
        error: (error) => {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
              message:
                // TODO: pass a translation key instead
                '(fr) Une erreur est survenue lors de la création/mise à jour du modèle de rapport.',
            },
          });
          dialogRef.afterClosed().subscribe(() => {
            this.ngForm.reset();
          });
        },
      });
    } else {
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: {
          // TODO: pass a translation key instead
          message: '(fr) Des erreurs de validation existent dans le formulaire.',
        },
      });
    }
  }

  resetCols(): void {
    this.deselectCheckbox();
  }

  navigateBack(): void {
    this.location.back();
  }
}
